import React, { useEffect, useState } from 'react';
import style from './Table.module.css';

import filterIcon from './filter.png';
import activeFilterIcon from './filter-active.png';
import Checkbox from './CheckBox';
import Pagination from './Pagination';
import InfoIcon from './InfoIcon';
import FilterPopup from './FilterPopup';
import CustomSpinner from 'components/CustomSpinner';

/**
 * @param {array} columns - array of objects representing the columns of the table.
 * Each object has properties such as title,
 * accessor (the key in the data object), format (optional formatting function), and alignment.
 * @param {array} data - Each object represents a row and includes properties that correspond
 * to the accessor keys defined in the columns array.
 * @param {string} actionIcon - is passed to the img element as the src attribute.
 * @param {function} details - a function that given a row produces a react element to render details of that row
 */

const sortRecords = (a, b, column, direction, sortFunc = (a, b) => (a < b ? -1 : 1)) => {
  if (column === null) {
    return 0;
  }
  const res = sortFunc(a[column], b[column]);
  if (direction === 'asc') return res;
  else return -res;
};
const alignmentToFlex = (alignment) => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};
export function Table(props) {
  const {
    /**
     * @prop {string} title - title of the column,
     * @prop {string} accessor - accessor (the key in the data object),
     * @prop {function} format - optional formatting function,
     * @prop {string} alignment - 'right' | 'left' | 'center',
     */
    columns = [],
    data = [],
    actionIcon = '#',
    actionIconSelected = '*',
    scrollable = false,
    rowsPerPage = 10,
    visibilePages = 3,
    sortable = false,
    handler = () => {},
    styles = {},
    selectedRow,
    setSelectedRow,
    isLoading,
  } = props;
  const [sort, setSort] = useState({ column: null, direction: 'asc' });
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState();
  const [filterColumn, setFilterColumn] = useState();
  // const [rowIndex, setRowIndex] = useState(0);

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.key === 'Escape') {
        setFilterColumn(undefined);
      }
    }
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setPage(0);
    setSort({ column: null, direction: 'asc' });
    setFilterColumn(undefined);
    const col = columns.find((c) => c.defaultFilter && c.filterable);
    if (col && !filter) setFilter({ accessor: col?.accessor, values: col?.defaultFilter });
  }, [columns, data, filter]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSort({ column, direction: 'asc' });
    }
  };
  let filteredData = filter
    ? data.filter((v) => filter?.values?.includes(v[filter.accessor]))
    : data;

  let sortedData = sort.column ? filteredData.slice() : filteredData;
  if (sort.column) {
    const column = columns.find((c) => c.accessor === sort.column);
    sortedData.sort((a, b) => sortRecords(a, b, sort.column, sort.direction, column.sort));
  }
  const pageSize = scrollable ? data.length : rowsPerPage;
  const totalPages = Math.ceil(sortedData.length / pageSize);

  const paginatedData = sortedData.slice(page * pageSize, (page + 1) * pageSize);

  const columnsCount = actionIcon ? columns.length + 1 : columns.length;

  const sortBtn = (accessor) => {
    return sort.column === accessor ? (sort.direction === 'asc' ? ' ▲' : ' ▼') : '';
  };
  const visibilePagesCount = totalPages > visibilePages ? visibilePages : totalPages;

  const groupedItems = data.reduce((prev, next) => ({ ...prev, [next[filterColumn]]: true }), {});
  const filterHandler = (value, accessor) => {
    if (accessor === filter?.accessor && filter?.values?.includes(value)) {
      if (filter?.values?.length === 1) setFilter(undefined);
      else setFilter({ ...filter, values: filter?.values?.filter((v) => v !== value) });
    } else setFilter({ ...filter, accessor, values: [...(filter?.values || []), value] });
  };
  return (
    <div className={style.styledContainer} style={{ overflowY: scrollable ? 'auto' : 'hidden' }}>
      {isLoading && <CustomSpinner />}
      <table
        className={style.styledTable}
        style={{ height: paginatedData.length === 0 ? '100%' : 'auto' }}>
        <thead>
          <tr className={style.styledHeaderCell}>
            {columns.map(({ title, accessor, alignment = 'left', filterable, width }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <th
                key={`title${title}${i}${accessor}`}
                onClick={sortable ? () => handleSort(accessor) : undefined}
                width={width}
                className={style.styledHeaderCell}
                style={{ cursor: sortable ? 'pointer' : 'inherit', width: width || '' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: alignmentToFlex(alignment),
                  }}>
                  {filterable ? (
                    <img
                      className={style.styledFilterIcon}
                      src={filter?.accessor === accessor ? filterIcon : filterIcon}
                      alt='filter icon'
                      onClick={() => {
                        if (filterColumn === accessor) setFilterColumn(undefined);
                        else setFilterColumn(accessor);
                      }}
                    />
                  ) : null}

                  {`${title}${sortable ? sortBtn(accessor) : ''}`}
                  {filterColumn === accessor ? (
                    <FilterPopup
                      onClickOutside={() =>
                        setFilterColumn((filterColumn) =>
                          filterColumn !== accessor ? filterColumn : undefined
                        )
                      }>
                      {Object.keys(groupedItems).map((v, i) => (
                        <Checkbox
                          key={`${filter?.accessor}${v}${i}`}
                          checked={filter?.values?.includes(v) && filterColumn === filter?.accessor}
                          onChange={() => filterHandler(v, filterColumn)}
                          label={v}
                        />
                      ))}
                    </FilterPopup>
                  ) : null}
                  <div style={{ marginLeft: '4px' }}>
                    <InfoIcon />
                  </div>
                </div>
              </th>
            ))}
            {/* {actionIcon && (
              <th className={`${style.styledHeaderCell} ${style.styledActionHeader}`}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                  }}>
                  <img src={actionIcon} alt='chart' style={{ width: '13px' }} />
                  <div style={{ position: 'absolute', right: '-4px' }}>
                    <InfoIcon />
                  </div>
                </div>
              </th>
            )} */}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`row${Object.keys(row)[0]}${i}`}>
              <tr
                onClick={() => {
                  setSelectedRow(i);
                  handler(row);
                }}
                style={{ cursor: 'pointer', backgroundColor: selectedRow === i && '#f1f2d1' }}>
                {columns.map(
                  ({ accessor, format = (v) => v, alignment = 'left', description }, idx) =>
                    // eslint-disable-next-line react/no-array-index-key
                    {
                      console.log('colll', accessor, idx, row);
                      return (
                        <td
                          key={`${accessor}${idx}`}
                          style={{ ...styles, textAlign: alignment }}
                          content={format(row[accessor])}
                          className={`${style.styledTableCell} ${
                            !description && selectedRow !== i
                              ? style.expanded
                              : !description && selectedRow === i
                              ? style.expandedActive
                              : null
                          }`}>
                          {description && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '8px',
                                top: 'calc(50% - 7px)',
                              }}>
                              <InfoIcon description={description(row)} />
                            </div>
                          )}
                          <span>{format(row[accessor])}</span>
                        </td>
                      );
                    }
                )}

                {/* {actionIcon && (
                  <td className={`${style.styledTableCell} ${style.styledCellAction}`}>
                    <button
                      className={style.styledTableButton}
                      type='button'
                      onClick={() => {
                        setRowIndex(i);
                        handler(row);
                      }}>
                      <img
                        src={i === rowIndex ? actionIconSelected : actionIcon}
                        alt='chart'
                        style={{ width: '13px' }}
                      />
                    </button>
                  </td>
                )} */}
              </tr>
            </React.Fragment>
          ))}
          {paginatedData.length === 0 && (
            <tr>
              <td colSpan={columnsCount} style={{ textAlign: 'center', color: '#c7c7c7' }}>
                N.D.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {totalPages > 1 && (
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          visibilePagesCount={visibilePagesCount}
        />
      )}
    </div>
  );
}

// const StyledTable = styled.table`
//   width: 100%;
//   border-spacing: 0;
//   display: table;
//   background-color: #fcfcfc;
//   box-sizing: border-box;
//   font-family: 'Barlow', Arial, Helvetica, sans-serif;
//   font-size: 10px;
//   color: #1c2743;
//   table-layout: fixed;
//   tr {
//     width: 100%;
//   }
// `;

// const StyledHeaderCell = styled.th`
//   box-sizing: border-box;
//   border-color: inherit;
//   text-transform: uppercase;
//   font-weight: 700;
//   font-style: normal;
//   line-height: 13px;
//   border-left: 1px solid #dbdbdb;
//   padding: 8px 5px;
//   cursor: ${({ $sortable }) => ($sortable ? 'pointer' : 'inherit')};
//   white-space: nowrap;
//   &:first-of-type {
//     border-left: none;
//   }
//   border-bottom: 2px solid #49a0a8;
//   position: relative;
//   width: ${(props) => props.width || ''};
//   font-size: 11px;
//   color: #1d2842;
// `;

// const StyledHeader = styled.tr`
//   position: sticky;
//   top: 0;
//   background-color: white;
//   z-index: 1;
// `;

// const StyledContainer = styled.div`
//   width: 100%;
//   height: calc(36.5px * 8);
//   overflow-y: ${({ $scrollable }) => ($scrollable ? 'auto' : 'hidden')};
//   overflow-x: visible;
//   &::-webkit-scrollbar {
//     width: 4px;
//     height: 4px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: #d6d6d6;
//     border-radius: 2px;
//   }
// `;

// const StyledButton = styled.button`
//   background-color: transparent;
//   border: none;
//   color: white;
//   padding: 0;
//   cursor: pointer;
// `;

// const StyledCell = styled.td`
//   display: table-cell;
//   padding: 9px 5px;
//   padding-right: 14px;
//   border-left: 1px solid #dbdbdb;
//   text-align: left;
//   vertical-align: middle;
//   position: relative;
//   &:first-of-type {
//     border-left: none;
//   }
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
//   ${(props) =>
//     props.$expandEllipsis &&
//     `&:hover {
//     overflow: visible;
//     z-index: 1;
//   }
//   &:hover span {
//     background-color: #fcfcfc;
//     padding-right: 10px;
//     z-index: 1;
//   } `}
//   height: 36.5px;
//   color: #222;
//   font-family: Barlow;
//   font-size: 10.467px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
// `;
// const StyledActionHeader = styled(StyledHeaderCell)`
//   width: 38px;
//   text-align: center;
// `;

// const StyledCellAction = styled(StyledCell)`
//   text-align: center;
//   border-bottom: ${(props) => (props.$isHeader ? '2px solid #49a0a8' : '')};
//   padding: 0px;
// `;

// const StyledFilterIcon = styled.img`
//   height: 13px;
//   width: 13px;
//   margin-right: 4px;
// `;

/* const FilterItemButton = styled.button`
  background: transparent;
  text-align: left;
  font-size: 10px;

  color: #1c2743;
  width: 100%;
  border: none;
  margin: 0;
  cursor: pointer;
  padding: 5px 10px;
  display: block;
  font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};

  &:hover {
    background-color: #f2f2f2;
  }
`; */
