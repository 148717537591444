import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
// import Select from 'react-select';
export default forwardRef((props, ref) => {
  const [filterState, setFilterState] = useState('off');

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterState !== 'off';
      },
      doesFilterPass(params) {
        // console.log('params', params);
        const field = props.colDef.field;
        const typeOfFilter = props?.colDef?.typeOfFilter;
        if (typeOfFilter === 'equalNotEqual') {
          if (filterState === 0) {
            return params.data[field] === filterState;
          } else return params.data[field] !== 0;
        }
        return params.data[field] === filterState;
      },
      getModel() {
        if (filterState === 'off') {
          return undefined;
        }
        return {
          state: filterState,
        };
      },
      setModel(model) {
        if (model === null) {
          setFilterState('off');
        } else {
          setFilterState(model.state);
        }
      },
    };
  });

  useEffect(() => props.filterChangedCallback(), [filterState]);

  return (
    <div className='m-4'>
      <div className='d-flex justify-content-center align-items-center'>
        <h6>
          {props.title}:{' '}
          <span>
            {filterState === 'off' ? (
              <Trans code='A0708' />
            ) : (
              props.values.filter((value) => value.value === filterState)[0].label
            )}
          </span>
        </h6>
      </div>

      <div style={{ minWidth: '300px', minHeight: '100px', maxHeight: '300px' }}>
        {/* <Select
          name='id'
          options={[{ value: '0', label: <Trans code='A0708' /> }, ...props.values]}
          onChange={(e) => setFilterState(e.value)}
          defaultValue={{ value: '0', label: <Trans code='A0708' /> }}
          // {...(expenseType === 'opex' && expenseItems.length === 1
          //   ? { isClearable: true }
          //   : null)}
        /> */}
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <Button
            style={{ fontSize: '.8rem' }}
            className='w-75 mb-2'
            variant='link'
            key={'off'}
            onClick={() => setFilterState('off')}>
            {<Trans code='A0708' />}
          </Button>
          {props.values.map((value) => {
            console.log(value);
            return (
              <div
                key={`${value.value}${value.label}`}
                className='filter-entry mb-2 w-100 d-flex flex-column justify-content-center align-items-center'>
                <Button
                  style={{ fontSize: '.8rem' }}
                  className='w-75'
                  variant='success'
                  onClick={() => setFilterState(value.value)}>
                  {value.label}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});
