const normalizeShadowPrices = (json) => {
  const newArray = [];
  for (const region in json.data) {
    const newObject = { region };
    console.log('NEWOBJECT', newObject);
    for (const value of json.data[region].values) {
      newObject[value.sectorCode] = value.value;
      newObject[`nameOf-${value.sectorCode}`] = value.account;
    }
    newArray.push(newObject);
  }
  return newArray;
};

export default normalizeShadowPrices;
