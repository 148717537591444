import React, { useState, useEffect, useMemo } from 'react';
import { Tabs, Tab, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { useHistory, useParams } from 'react-router-dom';
import { propTrans } from '@lib/i18n/index';

// const steps = [
//   { key: 'general', label: <Trans code='A0041' />, position: 1 },
//   { key: 'kpis', label: 'KPI', position: 2 },
//   { key: 'questionnaire', label: <Trans code='A0859' />, position: 3 },
//   { key: 'results', label: <Trans code='A0641' />, position: 4 },
// ];

const CorporateEsgHeader = ({ stepName, kpis, canSeeResults }) => {
  const [currentStep, setCurrentStep] = useState('');
  const { companyId, analysisId } = useParams();
  const history = useHistory();
  const [kpisIs0, setKpisIs0] = useState([]);

  useEffect(() => {
    if (!kpis.length) return;
    setKpisIs0(kpis.some((kpi) => kpi.value === 0));
  }, [kpis]);

  const steps = useMemo(
    () => [
      { key: 'general', label: <Trans code='A0041' />, position: 1, shouldBeVisible: true },
      { key: 'kpis', label: 'KPI', position: 2, shouldBeVisible: true },
      { key: 'questionnaire', label: <Trans code='A0859' />, position: 3, shouldBeVisible: true },
      {
        key: 'results',
        label: <Trans code='A0641' />,
        position: 4,
        shouldBeVisible: canSeeResults,
      },
    ],
    [canSeeResults]
  );
  // const buildDisabled = shouldDisable || idScenario === 'NaN';
  // const executeDisabled = shouldDisable || !scenarioData?.dataset?.id;

  useEffect(() => {
    setCurrentStep(
      steps
        .filter((step) => step.key === stepName)
        .map((step) => step.key)
        .pop()
    );
  }, [stepName]);
  // const warningTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'A0863' })}</Tooltip>;
  return (
    <Tabs
      activeKey={currentStep}
      onSelect={(k) => {
        setCurrentStep(k);
        history.push(`/corporateesg/company/${companyId}/analysis/${analysisId}/${k}`);
      }}
      className='mb-3 folder'
      justify>
      {steps.map((step) => {
        if (!step?.shouldBeVisible) {
          return null;
        }
        return (
          <Tab
            eventKey={step.key}
            key={step.key}
            // disabled={(step.key === 'questionnaire' || step.key === 'results') && kpisIs0}
            id='tab-corporateesg'
            title={
              // (step.key === 'questionnaire' || step.key === 'results') && kpisIs0 ? (
              //   <OverlayTrigger placement='top' overlay={warningTooltip}>
              //     <Row
              //       className='d-flex align-items-center flex-column justify-content-center'
              //       style={{ minHeight: '58px' }}
              //       // onClick={() => history.push(`/corporateesg/${analysisId}/${step.key}`)}
              //     >
              //       <Col className='tabname mb-0 d-flex align-items-center flex-column justify-content-center'>
              //         {step.label}
              //       </Col>
              //     </Row>
              //   </OverlayTrigger>
              // ) : (
              <Row
                className='d-flex align-items-center flex-column justify-content-center'
                style={{ minHeight: '58px' }}
                // onClick={() => history.push(`/corporateesg/${analysisId}/${step.key}`)}
              >
                <Col className='tabname mb-0 d-flex align-items-center flex-column justify-content-center'>
                  {step.label}
                </Col>
              </Row>
              // )
            }>
            {/* Tab content goes here */}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default CorporateEsgHeader;
