import React, { Component } from "react"

class Ico35 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-35" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(28.8,48)">
          <path d="M3.33333 6.66667C5.17428 6.66667 6.66667 5.17428 6.66667 3.33333C6.66667 1.49238 5.17428 0 3.33333 0C1.49238 0 0 1.49238 0 3.33333C0 5.17428 1.49238 6.66667 3.33333 6.66667Z" className="ico" />
          <path d="M3.33333 23.3337C5.17428 23.3337 6.66667 21.8413 6.66667 20.0003C6.66667 18.1594 5.17428 16.667 3.33333 16.667C1.49238 16.667 0 18.1594 0 20.0003C0 21.8413 1.49238 23.3337 3.33333 23.3337Z" className="ico" />
          <path d="M3.33333 39.9997C5.17428 39.9997 6.66667 38.5073 6.66667 36.6663C6.66667 34.8254 5.17428 33.333 3.33333 33.333C1.49238 33.333 0 34.8254 0 36.6663C0 38.5073 1.49238 39.9997 3.33333 39.9997Z" className="ico" />
          <path d="M56.8667 16.667H16.4667C14.7362 16.667 13.3334 18.0698 13.3334 19.8003V20.2003C13.3334 21.9308 14.7362 23.3337 16.4667 23.3337H56.8667C58.5972 23.3337 60 21.9308 60 20.2003V19.8003C60 18.0698 58.5972 16.667 56.8667 16.667Z" className="ico" />
          <path d="M56.8667 33.333H16.4667C14.7362 33.333 13.3334 34.7359 13.3334 36.4663V36.8663C13.3334 38.5968 14.7362 39.9997 16.4667 39.9997H56.8667C58.5972 39.9997 60 38.5968 60 36.8663V36.4663C60 34.7359 58.5972 33.333 56.8667 33.333Z" className="ico" />
          <path d="M56.8667 0H16.4667C14.7362 0 13.3334 1.40284 13.3334 3.13333V3.53333C13.3334 5.26383 14.7362 6.66667 16.4667 6.66667H56.8667C58.5972 6.66667 60 5.26383 60 3.53333V3.13333C60 1.40284 58.5972 0 56.8667 0Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico35