import { Trans } from '@lib/i18n/index';
import React from 'react';

const CounterComponent = ({ answeredNumber, toAnswerNumber }) => {
  const counters = [
    { number: answeredNumber, transCode: 'A0845' },
    { number: toAnswerNumber, transCode: 'A0846' },
  ];

  return (
    <div className='d-flex align-items-center'>
      {counters.map(({ number, transCode }, index) => (
        <React.Fragment key={index}>
          <span>
            <Trans code={transCode} />
          </span>
          <div className='px-2 py-1 counter-answers-wrapper mx-1'>
            <p className='p-0 m-0'>{number}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CounterComponent;
