import React from 'react';
import { Card, ListGroup} from 'react-bootstrap';
import { Ico36 } from "@icons/new";
import { percent, 
  date, 
  //rounder 
} from '@lib/helpers'
import { Trans } from '@lib/i18n'


const DashboardAssumptions = ({ dashboard: { principaliAssunzioni } }) => {
  return (
    <Card className="cards-utente d-none d-lg-block">
      <Card.Body>
        <Ico36 className="logo-card ico-box-blue"/>            
        <Card.Title><Trans code="F0066" /></Card.Title>
        <ListGroup variant="flush">
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="F0067" /></strong>
            <span>{principaliAssunzioni.periodoSimulazione}&nbsp;{principaliAssunzioni.periodoSimulazione === 1 ? <Trans code="A0385" /> : <Trans code="A0402" />}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="F0068" /></strong>
            <span>{principaliAssunzioni.durataInvestimento}&nbsp;{principaliAssunzioni.durataInvestimento === 1 ? <Trans code="A0385" /> : <Trans code="A0402" />}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="F0069" /></strong>
            <span>{principaliAssunzioni.durataRegime}&nbsp;{principaliAssunzioni.durataRegime === 1 ? <Trans code="A0385" /> : <Trans code="A0402" />}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="A0073" /></strong>
            <span>{new Date(date(principaliAssunzioni.avvioLavori)).getFullYear()}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="A0112" /></strong>
            <span>{percent(principaliAssunzioni.tassoAttualizzazione)}</span>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default DashboardAssumptions;