import React from 'react';

import style from './TableHeader.module.css';

function TableHeader({ title, handler, active, feature }) {
  return (
    <div className={style.wrapper}>
      {/* <MapContainer>
        <ChoroplethMap geodata={feature} fillColor={() => '#49A0A8'} borderColor={() => '#49A0A8'} />
      </MapContainer> */}
      <h2 className={style.subtitle}>{title}</h2>
      {/* <button
        className={style.button}
        onClick={handler}
        style={{
          backgroundColor: active ? '#C3CA23' : '#c7c7c7',
          color: active ? '#1C2743' : '#ebebeb',
        }}>
        <div className={style.buttonText}>Economy's insight</div>
      </button> */}
    </div>
  );
}

export default TableHeader;
