import React from 'react';
import IncomesImpact from '@components/scenariodashboard/incomes/IncomesImpact';
import IncomesDonutChart from '@components/scenariodashboard/incomes/IncomesDonutCard';
import IncomesTypologiesTable from '@components/scenariodashboard/incomes/IncomesTypologiesTable';
import { Ico45 } from '@icons/new';
const Incomes = ({ incomes, filterForRegion }) => {
  return (
    <>
      <IncomesImpact incomes={incomes} filterForRegion={filterForRegion} />
      <div className='row'>
        <IncomesDonutChart
          incomes={incomes.hhByIncome}
          filterForRegion={filterForRegion}
          chartId={'chart-by-empMacrosector'}
          langTitleCode={'A0204'}
          icon={<Ico45 className='logo-card ico-box-blue' />}
        />
        <IncomesTypologiesTable incomes={incomes.hhByIncome} filterForRegion={filterForRegion} />
      </div>
    </>
  );
};

export default Incomes;
