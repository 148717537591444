import React, { useEffect } from 'react';
import * as d3 from 'd3';
//import { propTrans, propTransTooltip } from '@lib/i18n';
import { generateColors } from '@lib/helpers/index';
const colorsArray = [
  '#C2C923',
  '#08A2AA',
  '#CB1B4A',
  '#FFB317',
  '#053D58',
  '#378288',
  '#5BCFD4',
  '#D4AFCD',
  '#F5D0C5',
  '#D69F7E',
];

const AnimatedDonutChartDash = ({
  chartId,
  data,
  codeLangTag,
  translations,
  currentLang,
  valueInDonut = true,
  labelOnHover = false,
  maximumFractionDigits = 0,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colors = generateColors(colorsArray, data?.length);

  const colorbrewer = (length) => colors.slice(0, length);
  const plotData = [];
  let valueInCircle = 0;

  console.log('translations', translations);
  console.log('currentLang', currentLang);
  console.log(
    'test',
    translations.filter((trans) => trans.code === 'A0659').map((trans) => trans[currentLang].text)
  );
  if (data.length === 0 || data.every((num) => Number(num.propertyValue) === 0)) {
    plotData.push(1);
    data = [{ propertyName: 'empty', propertyValue: 1 }];
  } else {
    //if (chartId === 'investment') { data.total = data.CAPEX };
    if (data.filter((el) => el.propertyName === 'total').length === 0) {
      data.push({
        propertyName: 'total',
        propertyValue: data.reduce((tot, elem) => {
          return tot + Number(elem.propertyValue);
        }, 0),
      });
    }

    data.forEach((el) => {
      if (el.propertyName !== 'total') {
        plotData.push(el.propertyValue);
      }
    });
  }

  // if (Array.isArray(data)) data = data.filter((el) => el.propertyValue !== 0);

  valueInCircle =
    data.filter((el) => el.propertyName === 'empty').length > 0
      ? translations
          .filter((trans) => trans.code === 'A0659')
          .map((trans) => trans[currentLang].text)
      : plotData
          .reduce((tot, elem) => {
            return tot + Number(elem);
          }, 0)
          .toLocaleString('it-IT', { maximumFractionDigits: maximumFractionDigits });

  //console.log('data', chartId, data);

  useEffect(() => {
    let colors =
      data.filter((el) => el.propertyName === 'empty').length > 0
        ? ['#e5e5e5']
        : colorbrewer(plotData.length);

    let sizes = {
      innerRadius: 75,
      outerRadius: 98,
      // outerRadius: 100,
    };

    let durations = {
      entryAnimation: 2000,
    };

    d3.select(`#${chartId}`).html('');

    let generator = d3.pie().padAngle(0.04).sort(null);

    let chart = generator(plotData);

    let arcs = d3
      .select(`#${chartId}`)
      .append('g')
      .attr('transform', 'translate(100, 100)')
      .selectAll('path')
      .data(chart)
      .enter()
      .append('path')
      .attr('class', 'arcs')
      .style('fill', (d, i) => colors[i])
      .attr('data-value', (d, i) => d.data)
      .attr('data-label', (d, i) => {
        const dataFiltered = data.filter((el) => el.propertyName !== 'total');
        const label = dataFiltered[i].propertyName;

        return label;
      });

    const parseHTML = (html) => {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(html, 'text/html');
      const textContent = parsedDocument.body.textContent;

      return textContent;
    };

    let g = d3.select(`#${chartId}`).selectAll('g');

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr(
        'font-size',
        data.filter((el) => el.propertyName === 'empty').length > 0 ? '1.375em' : '1.7em'
      )
      .attr('y', data.filter((el) => el.propertyName === 'empty').length > 0 ? '-1em' : '-0.4em')
      .attr('x', '0')
      .attr(
        'font-weight',
        data.filter((el) => el.propertyName === 'empty').length > 0 ? '400' : 'bold'
      )
      .attr('dy', data.filter((el) => el.propertyName === 'empty').length > 0 ? '1.1em' : '0em')
      .text(valueInDonut ? valueInCircle : null); //number in circle
    //.call(wrap, 50)

    if (data.filter((el) => el.propertyName === 'empty').length === 0) {
      g.append('foreignObject')
        .attr('width', '8rem')
        .attr('text-anchor', 'middle')
        .attr('height', 50)
        .attr('y', '0em')
        .attr('x', -63)
        .append('xhtml:div')
        .style('text-align', 'center')
        .style('padding-top', '0.5rem')
        //.style('white-space', 'nowrap')
        .html(
          valueInDonut
            ? `<text>${translations
                .filter((trans) => trans.code === codeLangTag)
                .map(
                  (trans) => trans[currentLang].text
                )}</text>&nbsp;<svg id="info-tooltip" width="14" height="14" viewBox="0 0 14 14" fill="none" style="vertical-align: inherit" xmlns="http://www.w3.org/2000/svg"><path d="M7.00036 0C3.13379 0 3.79829e-08 3.1338 3.79829e-08 6.99964C-9.57303e-05 7.91896 0.180909 8.82931 0.532676 9.67867C0.884443 10.528 1.40008 11.2998 2.05015 11.9499C2.70021 12.5999 3.47196 13.1156 4.32133 13.4673C5.17069 13.8191 6.08104 14.0001 7.00036 14C10.8655 14 14 10.8662 14 6.99964C14 3.1338 10.8655 0 7.00036 0ZM7.65366 2.52716C8.33613 2.52716 8.53664 2.92308 8.53664 3.37587C8.53664 3.94094 8.08458 4.46373 7.31243 4.46373C6.66642 4.46373 6.35873 4.13926 6.37769 3.6019C6.37769 3.14911 6.75611 2.52716 7.65366 2.52716ZM5.90521 11.1921C5.43857 11.1921 5.09807 10.9085 5.42399 9.66533L5.95844 7.45972C6.05104 7.10682 6.06635 6.96537 5.95844 6.96537C5.81918 6.96537 5.21327 7.2089 4.856 7.44951L4.6234 7.06817C5.7572 6.12104 7.06088 5.56544 7.61866 5.56544C8.08531 5.56544 8.16259 6.11666 7.93 6.96537L7.31754 9.284C7.2089 9.69377 7.25556 9.83522 7.3642 9.83522C7.50419 9.83522 7.96209 9.66606 8.41269 9.31097L8.67663 9.66533C7.57346 10.7678 6.37113 11.1921 5.90521 11.1921Z" fill="#C4C4C4"/></svg>`
            : null
        );
      //i icon in circle
    }

    let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());

    let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
    let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);

    let arc = d3.arc();

    arcs
      .transition()
      .duration(durations.entryAnimation)
      .attrTween('d', (d) => {
        let originalEnd = d.endAngle;
        return (t) => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return '';
          }

          d.endAngle = Math.min(currentAngle, originalEnd);

          return arc(d);
        };
      });

    d3.select(`#${chartId}`)
      .transition()
      .duration(durations.entryAnimation)
      .tween('arcRadii', () => {
        return (t) =>
          arc.innerRadius(innerRadiusInterpolation(t)).outerRadius(outerRadiusInterpolation(t));
      })
      .on('end', () => {
        //console.log(InvestmentChartIcon)
        //d3.select(`#${chartId}`).node().append()
      });

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'svg-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden');

    const mouseEnterCallback = (d) => {
      //console.log('d.target.attributes', d.target.attributes)
      d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

      tooltip.style('visibility', 'visible').text(
        data.filter((el) => el.propertyName === 'empty').length > 0
          ? `${translations
              .filter((trans) => trans.code === 'A0659')
              .map((trans) => trans[currentLang].text)}`
          : `${
              !valueInDonut || labelOnHover ? d.target.attributes['data-label'].value : ''
            } ${translations
              .filter((trans) => trans.code === d.target.attributes['data-label'].value)
              .map((trans) => trans[currentLang].text)} ${(
              (d.target.attributes['data-value'].value /
                data
                  .filter((el) => el.propertyName === 'total')
                  .map((el) => el.propertyValue)
                  .pop()) *
              100
            ).toLocaleString('it-IT', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}%`
      );
    };

    const mouseLeaveCallback = (d) => {
      d3.select(d.target).attr('stroke-width', '0');
      tooltip.style('visibility', 'hidden');
    };

    d3.select(`#${chartId}`)
      .selectAll('.arcs')
      .on('mouseenter', mouseEnterCallback)
      .on('mousemove', (event) => {
        tooltip.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', mouseLeaveCallback);

    //tooltip info icon
    const tooltipInfo = d3
      .select('body')
      .append('div')
      .attr('class', 'svg-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden');

    const mouseEnterCallbackInfo = (d) => {
      d3.select(d.target).attr('stroke-width', '2').attr('stroke', d.target.style.fill);

      tooltipInfo
        .style('visibility', 'visible')
        .text(
          parseHTML(
            translations
              .filter((trans) => trans.code === codeLangTag)
              .map((trans) => trans[currentLang].hover)
          )
        ); //text tooltip
      //OLD propTransTooltip({ prop: codeLangTag })
    };

    const mouseLeaveCallbackInfo = (d) => {
      d3.select(d.target).attr('stroke-width', '0');
      tooltipInfo.style('visibility', 'hidden');
    };

    d3.select(`#${chartId}`)
      .select('#info-tooltip')
      .on('mouseenter', mouseEnterCallbackInfo)
      .on('mousemove', (event) => {
        tooltipInfo.style('top', `${event.pageY}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', mouseLeaveCallbackInfo);

    //Wraps SVG text
    /* function wrap(text, width) {
         text.each(function () {
            let text = d3.select(this),
               words = text.text().split(/\s+/).reverse(),
               word,
               line = [],
               lineNumber = 0,
               lineHeight = 1.4, // ems
               y = text.attr('y'),
               x = text.attr('x'),
               dy = parseFloat(text.attr('dy')),
               tspan = text
                  .text(null)
                  .append('tspan')
                  .attr('x', x)
                  .attr('y', y)
                  .attr('dy', dy + 'em');

            while ((word = words.pop())) {
               line.push(word);
               tspan.text(line.join(' '));
               if (tspan.node().getComputedTextLength() > width) {
                  line.pop();
                  tspan.text(line.join(' '));
                  line = [word];
                  tspan = text
                     .append('tspan')
                     .attr('x', x)
                     .attr('y', y)
                     .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                     .text(word);
               }
            }
         });
      } //wrap */
  }, [chartId, data, codeLangTag, plotData, valueInCircle, translations, currentLang]);

  return (
    <div>
      {data.length && (
        <svg id={chartId} preserveAspectRatio='xMinYMin meet' viewBox='0 0 200 200'></svg>
      )}
    </div>
  );
};
export default AnimatedDonutChartDash;
