import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { StepExecute, StepConfigure, StepBuild } from '@components/wizard';
import WizardHeaderCompact from 'components/wizard/WizardHeaderCompact';
import ScenarioHeaderCompact from '@components/scenariodashboard/ScenarioHeaderCompact';
import VariantSelector from 'components/wizard/ScenarioVariants/variantSelector';
import { getScenarioVariant, getOEScenario } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';

const WizardStepCompact = () => {
  const { idScenario = null, idVariante = null } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const stepName = urlParams.get('step') ?? 'configure';
  const subStepName = urlParams.get('sub') ?? 'general';
  const [scenario, setScenario] = useState({});
  const [scenarioVariant, setScenarioVariant] = useState();
  const [shouldDisable, setShouldDisable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [variantsRefresh, setVariantsRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [variant, setVariant] = useState(null);
  // console.log('idScenario:', idScenario);

  useEffect(() => {
    if (idScenario) {
      fetchData(getOEScenario, setScenario, null, [idScenario], null, null, setIsLoading);
    } else {
      setScenario({});
    }
  }, [idScenario]);

  useEffect(() => {
    if (idVariante && idScenario) {
      fetchData(
        getScenarioVariant,
        setScenarioVariant,
        null,
        [idScenario, idVariante],
        null,
        null,
        setIsLoading
      );
      if (refresh) {
        setRefresh(false);
      }
    } else {
      setScenarioVariant({});
    }
  }, [idScenario, idVariante]);

  useEffect(() => {
    if (refresh) {
      getOEScenario(idScenario).then((retrievedScenario) => {
        setScenario(retrievedScenario.data);
      });
    }
  }, [refresh]);

  if (!scenario) {
    return (
      <div className='scenari megaContent'>
        <div className='container-fluid text-center pt-5' style={{ minHeight: '50vh' }}>
          <Spinner animation='border' role='status' />
        </div>
      </div>
    );
  }

  if (scenario) {
    return (
      <>
        <div id='wizard' className='scenari megaContent'>
          <div className='container-fluid'>
            {Object.keys(scenario).length > 0 ? (
              <ScenarioHeaderCompact scenario={scenario} visible={false} />
            ) : null}

            <WizardHeaderCompact
              stepName={stepName}
              scenarioData={scenario}
              idScenario={parseInt(idScenario)}
              shouldDisable={shouldDisable}
            />

            {stepName === 'configure' && (
              <StepConfigure
                scen={scenario}
                setScen={setScenario}
                setDisable={setShouldDisable}
                setRefresh={setRefresh}
              />
            )}
            {stepName !== 'configure' && subStepName && (
              <VariantSelector
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                variantsRefresh={variantsRefresh}
                setVariantsRefresh={setVariantsRefresh}
              />
            )}
            {stepName === 'build' && subStepName && (
              <StepBuild
                scen={scenarioVariant}
                setScen={setScenarioVariant}
                originScenario={scenario}
                isLoading={isLoading}
                setVariantsRefresh={setVariantsRefresh}
              />
            )}
            {/* {stepName === 'execute' && (
              <StepExecute
                scen={scenario}
                setScen={setScenarioVariant}
                variant={scenarioVariant}
                setScenarioVariant={setScenarioVariant}
              />
            )} */}
          </div>
        </div>
      </>
    );
  }
};
export default WizardStepCompact;
