import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { rounder } from '@lib/helpers'
import InvestmentTable from '@components/scenario/investment/InvestmentTable'
import InvestmentChart from '@components/scenario/investment/InvestmentChart'
import { Trans } from '@lib/i18n'
import {Ico42 } from "@icons/new"

const Investment = ({ investment, scenario }) =>  {
  console.log('scenario.vettorespesa', scenario.vettorespesa);
  console.log('investment.scenario_type', typeof investment.scenario_type, investment.scenario_type);
  //console.log('scenario.vettorespesa', scenario.vettorespesa);
  return (
  <>
    <div className="simple-box">
      <div className="row">
        {scenario.vettorespesa && parseInt(scenario.vettorespesa) === 1 && 
          <div className='investment-infobox-capex va-number col'>
            <Row className='justify-content-center align-items-center'>
              <Col className='p-0'>
                <Row className='p-0'>
                  <Col>
                    CAPEX:<h2>{investment.row && rounder(investment.row.TOTALCAPEX)}</h2> 
                  </Col>
                  
                  <Col style={{flexGrow: 3}}>
                    LOCAL:<h2>{investment.row && rounder(investment.row.LOCALCAPEX.va)}</h2>
                  </Col>
                </Row>
              </Col>
              
              <Col style={{flexGrow: 2}} className='p-0'>
                OPEX:<h2>{investment.row && rounder(investment.row.OPEX.va)}</h2> 
              </Col>   
            </Row>
            <span className="va-desc d-block" style={{marginBottom: '1.5rem'}}><Trans code="A0053" /></span>
          </div>
        }
        {scenario.vettorespesa && parseInt(scenario.vettorespesa) === 0 && 
          <> 
            <div className="col-3 investment-infobox va-number">
              <div>
                <h2>{rounder(investment.row.total.va)}</h2>
                <span className="va-desc"><Trans code="A0053" /></span>
              </div>
            </div>
          </>
        }
        {investment.description ? 
        <>
        <div className="col-4 investment-infobox va-number"></div>
          <div className="col-9 investment-infobox">
            <div dangerouslySetInnerHTML={{ __html: investment.description }} />
          </div>
        </> : null
        }
      </div>
    </div>

    <Row>
      <Col className="col-6">
        <InvestmentChart investment={investment} scenario={scenario}/>
      </Col>
      <Col className="col-6">
        <InvestmentTable investment={investment} scenario={scenario}/>
      </Col>
    </Row>

    { console.log(investment) && investment.image_inv_1 && investment.image_inv_1.indexOf("empty") < 0 && investment.image_inv_2 && investment.image_inv_2.indexOf("empty") < 0 && 
    <div className='row'>
      <div className="col-12 col-xl-6 d-flex container-card">
        <div className="simple-box">
          <div className="row">
            <div className="col">
              <Ico42 className="logo-card ico-box-blue" />
              <h5 className="title-simple-box margin-title-box">{investment.image_inv_text_1}</h5>
            </div>
          </div>
          <div className="row">
            <div className="text-center" />
              <img className="m-auto img-card" src={`${investment.base_url}${investment.image_inv_1}`} alt="" onerror="this.style.display='none'"/>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 d-flex container-card">
        <div className="simple-box">
          <div className="row">
            <div className="col">
              <Ico42 className="logo-card ico-box-blue" />
              <h5 className="title-simple-box margin-title-box">{investment.image_inv_text_2}</h5>
            </div>
          </div>
          <div className="row">
            <div className="text-center" />
            <img className="m-auto img-card" src={`${investment.base_url}${investment.image_inv_2}`} alt="" onerror="this.style.display='none'"/>
          </div>
        </div>
      </div>
    </div>
    }
  </>
)};
export default Investment