import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  axisBottom,
  axisLeft,
  curveMonotoneX,
  extent,
  line,
  scaleBand,
  scaleLinear,
  select,
} from 'd3';
import style from './LineChart.module.css';
import CustomSpinner from 'components/CustomSpinner';
export function LineChart(props) {
  const {
    /*     axisXCaption,
    axisYCaption, */
    onHover,
    renderTooltip,
    colors,
    formatYTick = (v) => v,
    formatXTick = (v) => v,
    title,
    series = [],
    padding = { top: 50, right: 20, bottom: 30, left: 30 },
    titleSize,
    ticksCount = 6,
    isLoading,
  } = props;

  const svgRef = useRef(null);

  const [tooltip, setTooltip] = useState();
  const [svgWidth, setSvgWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState();
  const [tooltipHeight, setTooltipHeight] = useState();

  const containerRef = useRef();

  const timeoutIdRef = useRef(null); // Ref to persist the timeoutId

  const measure = useCallback(() => {
    if (containerRef.current !== null) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      if (width !== 0 || height !== 0) {
        setSvgWidth(width);
        setSvgHeight(height);
      } else {
        // Retry after a short delay
        timeoutIdRef.current = setTimeout(measure, 50);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     // Trigger the measurement on component mount
  //     measure();
  //     window.addEventListener('resize', measure);

  //     return () => {
  //       window.removeEventListener('resize', measure);
  //       clearTimeout(timeoutIdRef.current);
  //     };
  //   }
  // }, [measure]);

  useEffect(() => {
    if (containerRef.current) {
      measure(containerRef.current);
      window.addEventListener('resize', measure);
      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, [containerRef, measure]);

  const tooltipRef = useCallback((node) => {
    if (node !== null) {
      const { width, height } = node.getBoundingClientRect();
      setTooltipWidth(width);
      setTooltipHeight(height);
    }
  }, []);

  const handleMouseover = useCallback(
    (e, data) => {
      const { width, height } = tooltipWidth
        ? { width: tooltipWidth, height: tooltipHeight }
        : { width: 40, height: 50 }; // measureElement(renderTooltip(feature));

      const overflowX = Math.min(svgWidth - e.offsetX - width, 0);
      const overflowY = Math.min(svgHeight - e.offsetY - height, 0);

      setTooltip({
        x: e.offsetX + overflowX,
        y: e.offsetY + overflowY,
        feature: data,
      });
      setSvgWidth((prev) => prev);
      onHover?.(data);
    },
    [tooltipWidth, tooltipHeight, svgWidth, svgHeight, onHover]
  );

  useEffect(() => {
    const svg = select(svgRef.current);

    if (svg && svgWidth && svgHeight && series?.length > 0) {
      const xScale = scaleBand()
        .domain(series[0]?.data?.map((d) => d.year))
        .range([0, svgWidth - padding.left - padding.right])
        .padding(1);
      const concatenatedData = series.reduce((acc, serie) => [...acc, ...serie.data], []);

      let yScale = scaleLinear()
        .domain(extent(concatenatedData, (d) => d.value))
        .range([svgHeight - (padding.top + padding.bottom), 0])
        .nice();

      let yAxis = axisLeft(yScale)
        .ticks(ticksCount)
        .tickSize(-svgWidth + padding.left + padding.right, 0, 0)
        .tickFormat((d, i) => (i % 2 ? '' : formatYTick(d)));

      let xAxis = axisBottom(xScale)
        .tickSize(-svgHeight + padding.top + padding.bottom, 0, 0)
        .tickFormat((d) => formatXTick(d));

      const lineGenerator = line()
        .x((d) => xScale(d.year))
        .y((d) => yScale(d.value))
        .curve(curveMonotoneX);

      svg
        .attr('width', svgWidth)
        .attr('height', svgHeight)
        .select('#chart')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
        .attr('font-size', '9px')
        .attr('color', '#4F4F4F')
        .selectAll('.lines')
        .data(series)
        // .data(series.filter((s) => s.serie === 'World Bank'))
        .join('path')
        .attr('class', 'lines', true)
        .datum((d) => d.data)
        .attr('d', lineGenerator)
        .attr('stroke', (_d, i) => colors[i])
        .attr('stroke-width', 3)
        .attr('fill', 'none')
        .exit()
        .remove();

      svg
        .selectAll('.y-axis')
        .attr('class', 'y-axis')
        .call(yAxis)
        .selectAll('text')
        .attr('color', '#7f7f7f')
        .attr('transform', 'translate(-5 0)');

      svg
        .selectAll('.x-axis')
        .attr('class', 'x-axis')
        .attr('transform', 'translate(0,' + (svgHeight - padding.top - padding.bottom) + ')')
        .call(xAxis)
        .selectAll('text')
        .attr('color', '#7f7f7f')
        .attr('transform', 'translate(0 4)');
      // .attr('transform', 'translate(0 5)');
      // .call((g) => g.selectAll('line').attr('opacity', 0));

      svg
        .selectAll('g.tick')
        .select('line')
        .attr('stroke', '#BDBDBD')
        .attr('stroke-dasharray', '1,2')
        .attr('opacity', 0.5);

      svg
        .selectAll('.domain')
        .attr('stroke', '#BDBDBD')
        .attr('stroke-dasharray', '1,2')
        .attr('opacity', 0.4);

      svg
        .select('.end-line')
        .select('line')
        .attr('y1', 0)
        .attr('y2', svgHeight - padding.top - padding.bottom)
        .attr('x1', svgWidth - padding.left - padding.right)
        .attr('x2', svgWidth - padding.left - padding.right)
        .attr('stroke', '#BDBDBD')
        .attr('stroke-dasharray', '1,2')
        .attr('opacity', 0.5);

      if (renderTooltip) {
        svg
          .selectAll('rect')
          .on('mouseover', handleMouseover)
          .on('mouseout', () => setTooltip(undefined));
      }
    }
  }, [
    colors,
    series,
    formatYTick,
    handleMouseover,
    padding,
    renderTooltip,
    svgHeight,
    svgWidth,
    title,
    formatXTick,
  ]);

  return (
    <div className={style.wrapper} ref={containerRef}>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          <div className={style.legend} style={{ marginRight: padding.right, height: padding.top }}>
            <div className={style.chartTitle} style={{ fontSize: `${titleSize}px` }}>
              {title}
            </div>

            {series?.map((s, i) => (
              <div className={style.legendItem} key={s.serie}>
                <svg height='20px' width='20px'>
                  {/* <ellipse
                cx='0'
                cy='0'
                rx='3'
                ry='3'
                transform={`translate(12, 8) `}
                fill={colors[i]}
              /> */}
                  <ellipse
                    cx='0'
                    cy='0'
                    rx='3.5'
                    ry='3.5'
                    transform={`translate(14, 9) `}
                    fill={colors[i]}
                  />
                </svg>{' '}
                <span className={style.legendItemTitle}>{s.serie}</span>
              </div>
            ))}
          </div>
          <svg
            preserveAspectRatio='none'
            ref={svgRef}
            width={'100%'}
            height={svgHeight}
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              boxSizing: 'border-box',
            }}>
            <g id='chart'>
              <g className='x-axis' />
              <g className='y-axis' />
              <g className='end-line'>
                <line />
              </g>
            </g>
          </svg>
          {tooltip && (
            <div
              ref={tooltipRef}
              style={{
                top: tooltip.y,
                left: tooltip.x,
                position: 'absolute',
                boxSizing: 'border-box',
                pointerEvents: 'none',
              }}>
              {renderTooltip(tooltip.feature)}
            </div>
          )}
        </>
      )}
    </div>
  );
}
