import { useEffect, useState } from 'react';
import GdpImpact from '@components/scenariodashboard/gdp/GdpImpact';
import GdpCard from '@components/scenariodashboard/gdp/GdpCard';
// import GdpTable from '@components/scenariodashboard/gdp/GdpTable';
import SectorTable from '@components/scenariodashboard/SectorTable';
import { Ico94, Ico95, Ico96, Ico99 } from '@icons/new';
import { getOEMacrosectors } from '@lib/api/index';
const Gdp = ({ gdp, filterForRegion }) => {
  const [macrosector, setMacrosectors] = useState([]);
  const [gdpMacrosectors, setGdpMacrosectors] = useState([]);
  useEffect(() => {
    getOEMacrosectors().then((result) =>
      setMacrosectors(result.data.filter((el) => el.type === 'macroaccount'))
    );
  }, []);
  useEffect(() => {
    let tempArray = [];
    let tempRegion;
    let tempPropertyName;
    let tempPropertyValue;
    gdp.gdpByMacrosector?.forEach((scenarioMacro) => {
      macrosector.forEach((macro) => {
        if (scenarioMacro.propertyName === macro.code) {
          tempRegion = scenarioMacro.region;
          tempPropertyName = macro.description;

          tempPropertyValue = scenarioMacro.propertyValue;
        }
      });
      tempArray.push({
        region: tempRegion,

        propertyName: tempPropertyName,

        propertyValue: tempPropertyValue,
      });
      // tempRegion = undefined;
      // tempPropertyName = undefined;
      // tempPropertyName = undefined;
    });

    setGdpMacrosectors(tempArray);
  }, [macrosector, gdp.gdpByMacrosector]);

  console.log('MACRO, ', macrosector, gdpMacrosectors);

  return (
    <>
      <GdpImpact filterForRegion={filterForRegion} gdp={gdp} />

      <div className='row'>
        <GdpCard
          gdp={gdp.gdpByFactor}
          filterForRegion={filterForRegion}
          chartId={'chart-by-factor'}
          langTitleCode={'A0460'}
          icon={<Ico94 className='logo-card ico-box-blue' />}
        />

        <GdpCard
          gdp={gdp.gdpByPropagation}
          filterForRegion={filterForRegion}
          chartId={'chart-by-propagation'}
          langTitleCode={'A0478'}
          icon={<Ico99 className='logo-card ico-box-blue' />}
        />

        <GdpCard
          gdp={gdp.gdpByPhase}
          filterForRegion={filterForRegion}
          chartId={'chart-by-phase'}
          langTitleCode={'A0479'}
          icon={<Ico95 className='logo-card ico-box-blue' />}
        />

        <GdpCard
          gdp={gdpMacrosectors}
          filterForRegion={filterForRegion}
          chartId={'chart-by-macrosector'}
          langTitleCode={'A0461'}
          icon={<Ico96 className='logo-card ico-box-blue' />}
        />
      </div>

      <SectorTable
        data={gdp.gdpBySector}
        filterForRegion={filterForRegion}
        shareBarVisible={true}
      />

      {/* <GdpCard gdp={gdp.gdpByPropagation} filterForRegion={filterForRegion} /> */}
    </>
  );
};

export default Gdp;
