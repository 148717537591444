import React, { useState } from 'react';
import style from './Table.module.css';

export default function InfoIcon({
  description = 'Inventore fusce, ultricies, eu anim laoreet minima',
}) {
  const [isShown, setIsShown] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width='9'
        height='9'
        viewBox='0 0 9 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
        <path
          d='M4.8811 0.284912C5.94197 0.284912 6.95938 0.70634 7.70953 1.45649C8.45968 2.20663 8.8811 3.22405 8.8811 4.28491C8.8811 5.34578 8.45968 6.36319 7.70953 7.11334C6.95938 7.86348 5.94197 8.28491 4.8811 8.28491C3.82024 8.28491 2.80282 7.86348 2.05268 7.11334C1.30253 6.36319 0.881104 5.34578 0.881104 4.28491C0.881104 3.22405 1.30253 2.20663 2.05268 1.45649C2.80282 0.70634 3.82024 0.284912 4.8811 0.284912ZM5.48106 2.74074C5.77818 2.74074 6.01931 2.53447 6.01931 2.22877C6.01931 1.92308 5.77761 1.71681 5.48106 1.71681C5.18394 1.71681 4.94396 1.92308 4.94396 2.22877C4.94396 2.53447 5.18394 2.74074 5.48106 2.74074ZM5.58562 5.95594C5.58562 5.8948 5.60677 5.73595 5.59477 5.64567L5.12509 6.1862C5.02795 6.28848 4.90624 6.35934 4.84911 6.34048C4.82318 6.33094 4.80152 6.31246 4.78801 6.28837C4.77449 6.26428 4.77002 6.23615 4.7754 6.20906L5.5582 3.73609C5.62219 3.4224 5.44621 3.13614 5.07309 3.09957C4.6794 3.09957 4.10002 3.49897 3.74747 4.00579C3.74747 4.06636 3.73604 4.2172 3.74804 4.30748L4.21715 3.76638C4.31429 3.66524 4.42742 3.59382 4.48456 3.61325C4.51271 3.62335 4.53578 3.64409 4.54881 3.67101C4.56185 3.69793 4.5638 3.72889 4.55427 3.75724L3.77833 6.2182C3.68862 6.50618 3.85832 6.78845 4.26972 6.85244C4.87539 6.85244 5.23308 6.46276 5.5862 5.95594H5.58562Z'
          fill='#C9C9C9'
        />
      </svg>
      {isShown && (
        <div style={{ position: 'absolute', left: 'calc(100% + 2px)', top: '-7px', zIndex: 1 }}>
          <div style={{ position: 'fixed' }}>
            <div className={style.styledInfoTooltip}>{description}</div>
          </div>
        </div>
      )}
    </div>
  );
}
