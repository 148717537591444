import React from 'react';
import { ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import TruncatedValue from 'components/RenderTruncatedValue';

const ListItem = ({
  listTitle,
  listValue,
  sign = null,
  conditionalPlural = null,
  total = null,
}) => {
  return (
    <>
      <ListGroup.Item className='d-flex justify-content-between align-items-center px-3'>
        <div>
          <strong style={{ minWidth: '120px' }}>
            <Trans code={listTitle} />
          </strong>
        </div>
        <div>
          {total ? (
            <strong>
              <span className='text-right'>
                {listValue && (
                  <TruncatedValue value={listValue} checkingValue={21} substringValues={[0, 18]} />
                )}
                {sign}
                {conditionalPlural}
              </span>{' '}
            </strong>
          ) : (
            <span className='text-right'>
              {listValue && (
                <TruncatedValue value={listValue} checkingValue={21} substringValues={[0, 18]} />
              )}
              {sign}
              {conditionalPlural}
            </span>
          )}
        </div>
      </ListGroup.Item>
    </>
  );
};

export default ListItem;
