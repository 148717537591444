import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getCorporateAnalysesResults,
  getESGSubCategories,
  getCorporateMaterialIssuesResults,
} from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import CustomSpinner from 'components/CustomSpinner';
import EsgMainComponent from 'components/scenariodashboard/esgRating/esgMainComponent';
import StatusBar from 'components/wizard/StatusBar';
import { Trans } from '@lib/i18n/index';
import MaterialityDetails from './CorporateEsgMateriality';
const CorporateEsgResults = () => {
  const { companyId, analysisId } = useParams();
  const [showCells, setShowCells] = useState(false);
  // const userCompanyId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [esgResults, setEsgResults] = useState([]);
  const [esgMaterialResults, setEsgMaterialResults] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (!companyId || !analysisId) return;
    fetchData(
      getCorporateAnalysesResults,
      setEsgResults,
      null,
      [companyId, analysisId],
      null,
      setError,
      setIsLoading
    );
    fetchData(getESGSubCategories, setSubcategories, null, [], null, setError, setIsLoading);
  }, [companyId, analysisId]);
  useEffect(() => {
    if (!companyId || !analysisId) return;
    fetchData(
      getCorporateMaterialIssuesResults,
      setEsgMaterialResults,
      null,
      [companyId, analysisId],
      null,
      setError,
      setIsLoading
    );
  }, [companyId, analysisId]);

  return (
    <div className='summaryDashboard'>
      <h1 className='title pb-0 mt-5'>
        <Trans code='A0851' />
      </h1>

      <div>
        {isLoading && <CustomSpinner style={{ height: '400px' }} />}

        {!esgResults.length && !isLoading ? (
          <h1>Nessun Dato da visualizzare</h1>
        ) : (
          <>
            {subcategories.length > 0 && (
              <EsgMainComponent esgResults={esgResults} subcategories={subcategories} />
            )}

            {esgMaterialResults && subcategories.length > 0 && (
              <MaterialityDetails
                esgMaterialResults={esgMaterialResults}
                showCells={showCells}
                setShowCells={setShowCells}
              />
            )}
          </>
        )}

        {error && <StatusBar status='error' message={error} timeout={false} />}
      </div>
    </div>
  );
};

export default CorporateEsgResults;
