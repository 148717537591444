import { Trans, propTrans } from '@lib/i18n/index';
import { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import OESimpleTable from 'components/OETables/OESimpleTable';
import normalizeShadowPrices from 'utils/normalizeShadowPrices';
import { getShadowPrices } from '@lib/api/index';
import { rounder } from '@lib/helpers/index';

const ShowDetailsModal = ({ showModal, setShowModal, regionsDatasetTypes, datasetId }) => {
  const handleCloseModal = (setShowModal) => setShowModal(false);
  const [data, setData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (showModal === true && datasetId) {
      setIsLoading(true);
      getShadowPrices(datasetId).then((retrieveData) => {
        setData(normalizeShadowPrices(retrieveData));
      });
      setIsLoading(false);
    }
  }, [showModal, datasetId]);

  useEffect(() => {
    const getNameKeys = () =>
      Array.from(
        new Set(data.flatMap((obj) => Object.keys(obj).filter((key) => key.includes('nameOf-'))))
      );

    const getUniqueKeys = () =>
      sortKeys(
        Array.from(
          new Set(
            data.flatMap((obj) =>
              Object.keys(obj).filter((key) => key !== 'region' && !key.includes('nameOf-'))
            )
          )
        )
      );

    const sortKeys = (keys) =>
      keys.sort((a, b) => {
        const numericA = parseFloat(a);
        const numericB = parseFloat(b);
        return numericA - numericB;
      });

    const createDynamicColumns = (uniqueKeys, uniqueNameKeys) =>
      uniqueKeys.map((sectorCode) => ({
        headerName: sectorCode,
        headerValueGetter: () =>
          Array.from(
            new Set(
              data
                .map((el) => el[uniqueNameKeys.find((el) => el.includes(sectorCode))])
                .filter(Boolean)
            )
          ),
        field: sectorCode,
        filter: 'agTextColumnFilter',
        valueGetter: (params) =>
          !params.data[sectorCode] ? 'N/A' : rounder(params.data[sectorCode]),
      }));

    const createStaticColumns = () => [
      {
        headerValueGetter: () => propTrans({ prop: 'A0668' }),
        field: 'region',
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0528' }),
        field: 'regionCode',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          const region = params.data.region;
          const matchingRegion = regionsDatasetTypes.find((el) => el.value === region);
          return matchingRegion ? matchingRegion.label : '';
        },
      },
    ];

    const uniqueNameKeys = getNameKeys();
    const uniqueKeys = getUniqueKeys();

    const dynamicColumns = createDynamicColumns(uniqueKeys, uniqueNameKeys);
    const staticColumns = createStaticColumns();

    const columnDefs = [...staticColumns, ...dynamicColumns];
    setColumnDefs(columnDefs);
  }, [regionsDatasetTypes, data]);

  return (
    <Modal
      dialogClassName='modal-fullscreen'
      show={showModal}
      onHide={() => handleCloseModal(setShowModal)}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans code='A0775' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5'>
        {isLoading ? (
          <div className='text-center my-4'>
            <Spinner animation='border' role='status'>
              <span style={{ visibility: 'hidden' }}>Caricamento...</span>
            </Spinner>
          </div>
        ) : (
          <OESimpleTable columnDefs={columnDefs} rowData={data} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => handleCloseModal(setShowModal)}>
          <Trans code='A0529' />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ShowDetailsModal;
