import { Trans } from '@lib/i18n/index';
import { Row, Col } from 'react-bootstrap';

const DocumentItemsWrapper = ({
  title,
  subtitleTransCode,
  sectionTitleTransCode,
  children,
  isUpload,
  permittedFiles,
}) => {
  return (
    <Row className='documents-item mb-5'>
      <Col className='col-4 d-flex flex-column'>
        <h3 className='mb-1'>{title}</h3>
        <span className='sub-color'>
          <Trans code={subtitleTransCode} />
        </span>
        {isUpload && (
          <span className='sub-color' style={{ fontSize: '.7rem' }}>
            <Trans code='A0824' />: {permittedFiles}
          </span>
        )}
      </Col>
      <Col className='col-8'>
        <p className='sub-color'>
          <Trans code={sectionTitleTransCode} />
        </p>
        <hr />
        {children}
      </Col>
    </Row>
  );
};

export default DocumentItemsWrapper;
