import React from 'react';
import { Card, ListGroup} from 'react-bootstrap';
import { Ico42 } from "@icons/new";
import {
  // currency,
  rounder
} from '@lib/helpers'
import { Trans } from '@lib/i18n'

const DashboardKpi = ({dashboard: { kpi }}) => {

  return (
    <Card className="cards-utente d-none d-lg-block">
      <Card.Body>
        <Ico42 className="logo-card ico-box-blue"/>
        <Card.Title><Trans code="C00501" /></Card.Title>
        <ListGroup variant="flush">
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="F0062" /></strong>
            <span>{rounder(kpi.pilLordo)}</span>
            {/* <span>{currency(kpi.pilLordo)} <Trans code="A0117" /></span> */}
          </ListGroup.Item>
          <ListGroup.Item className="justify-content-between align-items-center d-none">
            <strong><Trans code="F0063" /></strong>
            <span>{rounder(kpi.pilNetto)}</span>
            {/* <span>{currency(kpi.pilNetto)} <Trans code="A0117" /></span> */}
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="A0161" /></strong>
            <span>{rounder(kpi.moltiplicatoreCantiere)}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="A0448" /></strong>
            <span>{kpi.investimentiTotali}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <strong><Trans code="F0065" /></strong>
            <span>{kpi.effettoRidistributivo === 0 ? 'N.S.' :  rounder(kpi.effettoRidistributivo*100 )+"%" }</span>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default DashboardKpi;
