import Countdown from 'components/OtpComponents/Countdown';
import OtpInput from 'components/OtpComponents/OtpInput';
import { ArrowDownUp } from 'icone/index';
import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
const PasswordRecoveryOtp = ({
  setShowLogin,
  setIsOtp,
  loginForm,
  setLoginForm,
  isLoading,
  error,
  handleLogin,
}) => {
  return (
    <Form className='w-100'>
      <Col style={{ position: 'relative' }}>
        <div>
          <ArrowDownUp
            onClick={() => {
              setShowLogin(true);

              setIsOtp(false);
            }}
            className='px-2 color-icon login-backbutton'
          />
        </div>

        <Countdown counterSeconds={300} setState={setIsOtp} />
        <h5 className='login-header-main'>
          <div className='d-flex justify-content-center align-items-center'>
            <p>Inserisci il codice OTP</p>
          </div>
        </h5>
      </Col>
      <OtpInput
        onInputChange={(otp) => {
          setLoginForm({
            ...loginForm,
            otp: otp,
          });
        }}
      />

      <Button disabled={isLoading} variant='info' type='submit' onClick={handleLogin}>
        Submit
      </Button>

      <Row>
        <Col>
          <p
            style={{
              fontSize: '18px',
              fontWeight: '400',
            }}
            className='pt-3'>
            Non hai ricevuto il codice?{' '}
            <Button
              variant='link'
              disabled={isLoading}
              onClick={(e) => {
                handleLogin(e, true);
              }}
              style={{
                fontSize: '18px',
              }}>
              Richiedi un nuovo codice
            </Button>
          </p>
        </Col>
      </Row>

      {error && (
        <Form.Text className='text-muted'>
          <h5>{error}</h5>
        </Form.Text>
      )}
    </Form>
  );
};

export default PasswordRecoveryOtp;
