import React, { Fragment, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import RendererSwitch from './../datasetEconomicData.js/RendererSwitch';
import StatusBar from 'components/wizard/StatusBar';

const EconomicDataSummary = ({
  scenario,
  datasetRegions,
  totalPerColumn,
  sectors,
  expenseItems,
  setScenario,
  idScenario,
  idVariante,
}) => {
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [statusBarMessage, setStatusBarMessage] = useState('');
  const [statusBarType, setStatusBarType] = useState('');

  const filteredEconData = scenario[
    'economicData' +
      scenario.inputTypeCode.charAt(0).toUpperCase() +
      scenario.inputTypeCode.slice(1).toLowerCase()
  ]?.filter((econData) => {
    return datasetRegions.some((region) => {
      return econData.regionCode === region.code;
    });
  });

  // Translations
  const columnCodes = {
    totalCapex: 'A0589',
    totalOpex: 'A0590',
    totalRevenues: 'A0591',
  };

  const colHeaderNames = ['totalCapex', 'totalOpex', 'totalRevenues'];

  return (
    <div id='expenses-localization-summary' className='simple-box mb-5 fade-in-div'>
      <Row className='mb-1'>
        <Col lg={12} className='form-login'>
          <h5 className='title-simple-box margin-title-box'>
            <Trans code='A0766' />
          </h5>
        </Col>
        <Col>
          {showStatusBar && <StatusBar status={statusBarType} message={statusBarMessage} />}
        </Col>
      </Row>
      <table className='economic-data-summary mb-4'>
        <thead>
          <tr>
            <th key='th-empty'></th>
            {colHeaderNames.map((colName) => {
              return (
                <th key={colName} className='text-right cell-width-economic-data'>
                  <Trans code={columnCodes[colName] || ''} />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className='text-right'>
          <tr className='totals-row'>
            <td className='text-left'>
              <strong>
                <Trans code='A0111' />
              </strong>
            </td>
            {colHeaderNames.map((colName) => (
              <td key={colName + '-total'} className='text-right pr-0'>
                <strong>
                  {Object.keys(filteredEconData).length > 0 &&
                    filteredEconData.length > 0 &&
                    totalPerColumn(filteredEconData, colName, false)}
                </strong>
              </td>
            ))}
          </tr>

          {filteredEconData.map((econData) => {
            return (
              <tr key={econData.regionCode}>
                <td className='pl-2'>
                  {datasetRegions
                    .filter((region) => region.code === econData.regionCode)
                    .map((region, i) => {
                      return (
                        <div
                          key={region.code}
                          style={{ minWidth: '250px' }}
                          className='gap-3 cell-display-options'>
                          {region.name}
                          <Fragment>
                            <RendererSwitch
                              type='economicData'
                              index={i}
                              analysisMode={scenario.inputTypeCode}
                              scenarioId={scenario.id}
                              regionCode={region.code}
                              regionName={region.name}
                              sectors={sectors}
                              expenseItems={expenseItems}
                              isVisible={
                                scenario[
                                  'economicData' +
                                    scenario.inputTypeCode.charAt(0).toUpperCase() +
                                    scenario.inputTypeCode.slice(1).toLowerCase()
                                ] &&
                                scenario[
                                  'economicData' +
                                    scenario.inputTypeCode.charAt(0).toUpperCase() +
                                    scenario.inputTypeCode.slice(1).toLowerCase()
                                ].some((el) => el.regionCode === econData.regionCode)
                              }
                              setShowStatusBar={setShowStatusBar}
                              setStatusBarType={setStatusBarType}
                              setStatusBarMessage={setStatusBarMessage}
                              setScenario={setScenario}
                              idScenario={idScenario}
                              idVariante={idVariante}
                            />
                          </Fragment>
                        </div>
                      );
                    })}
                </td>

                {colHeaderNames.map((colName) => (
                  <td className='pr-0' key={colName + '-td'}>
                    {filteredEconData
                      .filter((el) => el.regionCode === econData.regionCode)
                      .map((el, i) => {
                        return (
                          <Fragment key={el.regionCode}>
                            {Number(el[colName]).toLocaleString('it-IT', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Fragment>
                        );
                      })}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EconomicDataSummary;
