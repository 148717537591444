import React, { useState, useEffect, useCallback, useRef, Fragment, useMemo } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
} from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { IcoPlus, Lens, IcoMinusCircled } from '@icons';
import { Trans, propTrans } from '@lib/i18n';
import {
  getClasses,
  getOESecDisags,
  getScenari,
  getOEScenarios,
  getDatasets,
  getNaceSectors,
  getInterventTypes,
  getOrganizations,
  getExpenseItems,
  getTags,
  getKpis,
  getOEKpis,
  postCreateClass,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererClass from '@components/adminManagement/classesManagement/ActionBtnRendererClass';
import ShowEmployable from 'components/adminManagement/classesManagement/showEmployable';
import StatusBar from 'components/wizard/StatusBar';
import { getOEDatasets } from '@lib/api/index';

import TableFilter from 'components/adminManagement/tableFilters/tableFilter';

const initialState = {
  sectorCode: '',
  investmentType: '',
  companyId: '',
  description: '',
  tags: [],
  localization: false,
  expenseitemslist: [
    {
      capexToOpexShare: null,
      expenseitems: [],
      bm: [],
    },
  ],
  kpi: [],
};

const Classes = () => {
  const [classes, setClasses] = useState([]);
  // const [scenari, setScenari] = useState([]);
  const [datasetsBM, setDatasetsBM] = useState([]);
  const [newClass, setNewClass] = useState(initialState);
  const [optionsNaceSectors, setOptionsNaceSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [optionsInterventTypes, setOptionsInterventTypes] = useState([]);
  const [selectedInterventType, setSelectedInterventType] = useState([]);
  const [optionsCompanies, setOptionsCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [optionsKpi, setOptionsKpi] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [optionsExpenseItems, setOptionsExpenseItems] = useState([]);
  const [expenseItemCapex, setExpenseItemCapex] = useState([]);
  const [expenseItemOpex, setExpenseItemOpex] = useState([]);
  const [expenseItemRevenues, setExpenseItemRevenues] = useState([]);
  const [selectedRevenues, setSelectedRevenues] = useState([]);
  const [sumOfCapex, setSumOfCapex] = useState(0);
  const [sumOfOpex, setSumOfOpex] = useState(0);
  //const [newAttachment, setNewAttachment] = useState({name: '', expirationDate: '', file: ''});
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [tableSbShow, setTableSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const [columnDefs, setColumnDefs] = useState([]);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  const [oeSecDisags, setOeSecDisags] = useState([]);

  console.log('classes', classes);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const resetFormState = useCallback(() => {
    setSbShow(<></>);
    setSelectedSector([]);
    setSelectedInterventType([]);
    setSelectedCompany([]);
    setSelectedKpi([]);
    setSelectedTags([]);
    setExpenseItemCapex([]);
    setExpenseItemOpex([]);
    setSelectedRevenues([]);
    setNewClass(initialState);
  }, []);

  useEffect(() => {
    const myAbortController = new AbortController();

    const fetchData = async () => {
      // const secDisagsResponse = await getOESecDisags();
      // const datasetsResponse = await getOEDatasets();
      // const naceSecResponse = await getNaceSectors();
      // const interventTypeResponse = await getInterventTypes();
      // const organizationResponse = await getOrganizations();
      // const expenseItemsResponse = await getExpenseItems();
      // const kpiResponse = await getOEKpis();
      // const tagResponse = await getTags();

      const [
        secDisagsResponse,
        datasetsResponse,
        naceSecResponse,
        interventTypeResponse,
        organizationResponse,
        expenseItemsResponse,
        kpiResponse,
        tagResponse,
      ] = await Promise.all([
        getOESecDisags(),
        getOEDatasets(),
        getNaceSectors(),
        getInterventTypes(),
        getOrganizations(),
        getExpenseItems(),
        getOEKpis(),
        getTags(),
      ]);

      if (myAbortController.signal.aborted === false) {
        setOeSecDisags(
          secDisagsResponse.data.map((secDisag) => ({ value: secDisag.code, label: secDisag.name }))
        );

        setDatasetsBM(datasetsResponse);

        setOptionsNaceSectors(
          naceSecResponse.data.map((naceSector) => ({
            value: naceSector.code,
            label: naceSector.name,
          }))
        );
        setOptionsInterventTypes(
          interventTypeResponse.data.map((interventType) => ({
            value: interventType.code,
            label: interventType.name,
            oeSectorCode: interventType.oeSectorCode,
          }))
        );

        setOptionsCompanies(
          organizationResponse.data.map((organization) => ({
            value: organization.id,
            label: organization.name,
            // value: organization.idOrganization,
            // label: organization.organization,
          }))
        );

        setOptionsExpenseItems(
          expenseItemsResponse.data.map((expItem) => ({
            value: expItem.id,
            label: expItem.name,
            type: expItem.expenseType,
            isDisabled: false,
          }))
        );

        setOptionsKpi(
          kpiResponse.data.map((kpi) => ({
            value: kpi.id,
            label: `${kpi.id} - ${kpi.name}`,
            isDisabled: kpi?.indicatorsCount < 1,
          }))
        );

        setOptionsTags(
          tagResponse.data.map((tag) => ({
            value: tag.id,
            label: tag.title,
          }))
        );
      }
    };
    fetchData();
    return () => {
      myAbortController.abort();
    };
    // getOESecDisags().then((secDisags) => {
    //   console.log('GeoPrecision:', secDisags);
    //   setOeSecDisags(
    //     secDisags.data.map((secDisag) => ({ value: secDisag.code, label: secDisag.name }))
    //   );
    // });
    // // getOEScenarios().then((retrievedScenari) => {
    // //   //get scenari con classe selezionata
    // //   setScenari(retrievedScenari.data);
    // // });

    // getDatasets().then((retrievedSam) => {
    //   //costruire oggetto options per modal edit
    //   setDatasetsBM(retrievedSam);
    // });

    // getNaceSectors().then((retrievedNaceSectors) => {
    //   setOptionsNaceSectors(
    //     retrievedNaceSectors.data.map((naceSector) => ({
    //       value: naceSector.code,
    //       label: naceSector.name,
    //     }))
    //   );
    // });

    // getInterventTypes().then((retrievedInterventTypes) => {
    //   setOptionsInterventTypes(
    //     retrievedInterventTypes.data.map((interventType) => ({
    //       value: interventType.code,
    //       label: interventType.name,
    //       oesectorCode: interventType.oesectorCode,
    //     }))
    //   );
    // });

    // getOrganizations().then((retrievedOrganizations) => {
    //   setOptionsOrganizations(
    //     retrievedOrganizations.map((organization) => ({
    //       value: organization.idOrganization,
    //       label: organization.organization,
    //     }))
    //   );
    // });

    // getExpenseItems().then((retrievedExpenseItems) => {
    //   console.log('EXP', retrievedExpenseItems.data);
    //   setOptionsExpenseItems(
    //     retrievedExpenseItems.data.map((expItem) => ({
    //       value: expItem.id,
    //       label: expItem.name,
    //       type: expItem.expenseType,
    //       isDisabled: false,
    //     }))
    //   );
    // });

    // // getKpis().then((retrievedKpis) => {
    // //   setOptionsKpi(
    // //     retrievedKpis.map((kpi) => ({
    // //       value: kpi.id,
    // //       label: kpi.name,
    // //     }))
    // //   );
    // // });

    // getOEKpis().then((retrievedKpis) => {
    //   setOptionsKpi(
    //     retrievedKpis.data.map((kpi) => ({
    //       value: kpi.id,
    //       label: kpi.name,
    //     }))
    //   );
    // });

    // getTags().then((retrievedTags) => {
    //   setOptionsTags(
    //     retrievedTags.map((tag) => ({
    //       value: tag.id,
    //       label: tag.title,
    //     }))
    //   );
    // });
  }, []);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      getClasses().then((retrievedClasses) => {
        setClasses(retrievedClasses.data);
        console.log(retrievedClasses);
      });
      // onGridReady();
      getTags().then((retrievedTags) => {
        setOptionsTags(
          retrievedTags.data.map((tag) => ({
            value: tag.id,
            label: tag.title,
          }))
        );
      });
      setRefresh(false);
    }
    // Do I need this?
    // gridRef?.current?.api?.setColumnDefs(columnDefs);
  }, [refresh, columnDefs]);

  // useEffect(() => {
  //   if (gridRef.current.api) {
  //     gridRef.current.api.refreshHeader();
  //   }
  // }, [columnDefs]);

  useEffect(() => {
    let allExpenseItemsSelected = [];
    allExpenseItemsSelected = allExpenseItemsSelected.concat(expenseItemCapex, expenseItemOpex);

    const expItemsDisabled = optionsExpenseItems
      .filter((option) => {
        return allExpenseItemsSelected.some((itemSelected) => {
          return option.value === itemSelected.id;
        });
      })
      .map((expItem) => ({
        value: expItem.value,
        label: expItem.label,
        type: expItem.type,
        isDisabled: true,
      }));

    const expItemsNotDisabled = optionsExpenseItems
      .filter((option) => {
        return !allExpenseItemsSelected.some((itemSelected) => {
          return option.value === itemSelected.id;
        });
      })
      .map((expItem) => ({
        value: expItem.value,
        label: expItem.label,
        type: expItem.type,
        isDisabled: false,
      }));

    setOptionsExpenseItems(expItemsDisabled.concat(expItemsNotDisabled));

    setSumOfCapex(
      expenseItemCapex.reduce((tot, elem) => {
        return tot + Number(elem.percentage);
      }, 0)
    );
    setSumOfOpex(
      expenseItemOpex.reduce((tot, elem) => {
        return tot + Number(elem.percentage);
      }, 0)
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseItemCapex, expenseItemOpex]);

  useEffect(() => {
    expenseItemsRows();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseItemCapex, expenseItemOpex]);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'ID',
        field: 'id',
        maxWidth: 100,
        filter: 'agTextColumnFilter',
        sortable: true,
        valueGetter: (params) => {
          return params.data.id;
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0079' }),
        field: 'sectorCode',
        filter: TableFilter,
        filterParams: {
          title: <Trans code='A0079' />,
          values: optionsNaceSectors?.filter((sector) =>
            classes?.some((cls) => cls.sectorCode === sector.value)
          ),
        },
        sortable: true,
        valueGetter: (params) => {
          return optionsNaceSectors
            .filter((sector) => sector.value === params.data.sectorCode)
            .map((sector) => sector.label);
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0500' }),
        field: 'investmentType',
        filter: TableFilter,
        filterParams: {
          title: <Trans code='A0500' />,
          values: optionsInterventTypes?.filter((interventType) =>
            classes?.some((cls) => cls.investmentType === interventType.value)
          ),
        },
        sortable: true,
        valueGetter: (params) => {
          return optionsInterventTypes
            .filter((type) => type.value === params.data.investmentType)
            .map((type) => type.label);
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0058' }),
        field: 'companyId',
        filter: TableFilter,
        filterParams: {
          title: <Trans code='A0058' />,
          values: optionsCompanies?.filter((organization) =>
            classes?.some((cls) => cls.companyId === organization.value)
          ),
        },
        sortable: true,
        valueGetter: (params) => {
          return optionsCompanies
            .filter((org) => org.value === params.data.companyId)
            .map((org) => org.label);
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0731' }),
        field: 'employable',
        maxWidth: 130,
        filter: 'agTextColumnFilter',
        cellStyle: { /*textAlign: 'left'*/ marginLeft: '2.4em' },
        sortable: true,
        cellRenderer: ShowEmployable,
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0502' }),
        field: 'version',
        maxWidth: 140,
        filter: 'agTextColumnFilter',
        sortable: true,
        cellStyle: { /* textAlign: 'center', */ paddingLeft: '2.5rem' },
        valueGetter: (params) => {
          return Number(params.data.version);
        },
      },

      /* {
            headerValueGetter: () => propTrans({ prop: 'A0138'}),
            field: 'description',
            filter: 'agTextColumnFilter',
         }, */
      {
        pinned: 'right',
        minWidth: 130,
        maxWidth: 130,
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionBtnRendererClass,
        cellRendererParams: {
          setRefresh: setRefresh,
          optionsNaceSectors: optionsNaceSectors,
          optionsInterventTypes: optionsInterventTypes,
          optionsCompanies: optionsCompanies,
          optionsKpi: optionsKpi,
          optionsExpenseItemsProp: optionsExpenseItems.map((opt) => ({
            ...opt,
            isDisabled: false,
          })),
          optionsTags: optionsTags,
          datasetsBM: datasetsBM,
          oeSecDisags: oeSecDisags,
          setTableSbShow: setTableSbShow,
          // scenari: scenari,
        },
      },
    ]);
  }, [
    datasetsBM,
    optionsExpenseItems,
    optionsInterventTypes,
    optionsKpi,
    optionsCompanies,
    optionsNaceSectors,
    optionsTags,
    oeSecDisags,
    classes,

    // scenari,
  ]);

  const addExpenseItem = (typeArray, setTypeArray, expenseType) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.push({ id: '', percentage: 0, expenseType: expenseType });

    setTypeArray(tempTypeArray);
  };

  const removeExpenseItem = (typeArray, setTypeArray, index) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.splice(index, 1);

    setTypeArray(tempTypeArray);
  };

  const onChange = (e, fieldName, setState, state, expItemIndex, expenseType) => {
    // console.log('onchange e:', e.target.checked)
    console.table('onchange:', e, fieldName, setState, state, expItemIndex, expenseType);

    if (fieldName === 'expenseItems') {
      if (expenseType === 'revenues') {
        setSelectedRevenues(e ?? []);

        let tempRevenues = e
          ? e.map((el) => ({
              id: el.value,
              expenseType: expenseType,
              percentage: 0,
            }))
          : [];

        setState(tempRevenues);
      } else {
        if (e && e.target && e.target.name === 'percentage') {
          //campo percentage
          let tempExpenseItems = [...state];

          let tempElement = {
            ...tempExpenseItems[expItemIndex],
            percentage: e.target.value === '' ? null : Number(e.target.value),
          };

          tempExpenseItems[expItemIndex] = tempElement;

          setState(tempExpenseItems);
        } else if (e && e.target && e.target.name === 'capexToOpexShare') {
          //campo capexToOpexShare
          setNewClass(() => ({
            ...newClass,
            expenseitemslist: [
              {
                ...newClass.expenseitemslist[0],
                capexToOpexShare: e.target.value ? Number(e.target.value) : null,
              },
            ],
          }));
        } else {
          //campo select expenseItems
          let tempExpenseItems = [...state];

          if (e) {
            let tempElement = {
              ...tempExpenseItems[expItemIndex],
              id: e.value,
              expenseType: expenseType,
            };

            tempExpenseItems[expItemIndex] = tempElement;

            setState(tempExpenseItems);
          } else {
            //campo opex null
            tempExpenseItems.splice(expItemIndex, 1);

            newClass.expenseitemslist[0].capexToOpexShare = null;

            setNewClass(newClass);

            setState(
              tempExpenseItems.length > 0
                ? tempExpenseItems
                : [{ id: '', percentage: 0, expenseType: 'opex' }]
            );
          }
        }
      }
    } else {
      if (setState) {
        setState(e ?? []);
      }

      let tempNewClass = { ...newClass };
      let value;

      switch (fieldName) {
        case 'sectorCode':
          value = e.value;

          setSelectedInterventType([]);

          tempNewClass.investmentType = '';
          break;
        case 'investmentType':
        case 'companyId':
          value = e.value;
          break;
        case 'tags':
        case 'kpi':
          value = e;
          break;
        case 'localization':
          value = e.target.checked;
          break;
        default:
          value = e.target.value;
          break;
      }

      tempNewClass[fieldName] = value;
      console.log('TEMP NEW CLASS', tempNewClass);
      setNewClass(tempNewClass);
    }
  };

  const onCreateOption = (inputValue) => {
    //si attiva solo in creazione
    const newOption = { value: inputValue, label: inputValue, newOption: true };

    setOptionsTags((prev) => [...prev, newOption]);
    setSelectedTags((prev) => [...prev, newOption]);

    let tempTags = [...newClass.tags];
    tempTags.push(newOption);

    setNewClass(() => ({
      ...newClass,
      tags: tempTags,
    }));
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onFirstDataRendered = useCallback(() => {
    if (!gridRef) return;
    setTimeout(() => {
      gridRef?.current?.api?.sizeColumnsToFit();
    }, 200);
    //gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onGridReady = useCallback(() => {
    getClasses().then((retrievedClasses) => {
      setClasses(retrievedClasses.data);
      console.log('CLASSES:', retrievedClasses.data);
    });
    console.log('GRIRE', gridRef.current);
    //sort the column 'position' in ascending order
    gridRef.current.columnApi.applyColumnState({
      state: [
        {
          colId: 'id',
          sort: 'desc',
        },
        // {
        //   colId: 'sectorCode',
        //   sort: 'asc',
        // },
        // {
        //   colId: 'investmentType',
        //   sort: 'asc',
        // },
        // {
        //   colId: 'organization',
        //   sort: 'asc',
        // },
        // {
        //   colId: 'version',
        //   sort: 'desc',
        // },
      ],
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onGridSizeChanged = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();

    /* if (window.screen.width >= 3840) { //&& window.screen.height >= 768
         // Resolution is 3840 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      } */
  }, []);

  /* const onVirtualRowRemoved = useCallback(e => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  /* const detailCellRenderer = useMemo(() => {
      return ShowKpiRenderer;
   }, []); */

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const defaultColDef = {
    resizable: false,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  const handleNewClass = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { active, description, kpi, tags, localization, ...restClass } = newClass;

    const isClassFilled = Object.values(restClass).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    const isCapexFilled =
      expenseItemCapex.length > 0 &&
      expenseItemCapex.every((prop) => prop.id !== '' && prop.id != null)
        ? true
        : false;
    const isOpexFilled =
      expenseItemOpex.length > 0 &&
      expenseItemOpex.every((prop) => prop.id !== '' && prop.id != null)
        ? true
        : false;
    const isRevenuesFilled =
      expenseItemRevenues.length > 0 &&
      expenseItemRevenues.every((prop) => prop.id !== '' && prop.id != null)
        ? true
        : false;
    const isExpenseItemsFilled = isCapexFilled && isOpexFilled && isRevenuesFilled;

    if (!isClassFilled || !isExpenseItemsFilled) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      return;
    }

    if ((sumOfCapex !== 0 && sumOfCapex !== 100) || (sumOfOpex !== 0 && sumOfOpex !== 100)) {
      handleStatusBar('error', propTrans({ prop: 'A0523' }), false);
      return;
    }

    newClass.kpi = selectedKpi.length > 0 ? selectedKpi.map((kpi) => kpi.value) : [];

    newClass.tags =
      selectedTags.length > 0
        ? selectedTags.map((selectedTag) => ({
            id: selectedTag.newOption ? null : selectedTag.value,
            title: selectedTag.label,
          }))
        : [];

    let tempExpenseItems = [];

    tempExpenseItems = tempExpenseItems.concat(
      expenseItemCapex,
      expenseItemOpex,
      expenseItemRevenues
    );

    newClass.expenseitemslist[0].expenseitems =
      tempExpenseItems.length > 0
        ? tempExpenseItems
            .filter((expItem) => expItem.id)
            .map((expItem) => ({
              id: expItem.id,
              percentage: expItem.percentage ?? 0,
              expenseType: expItem.expenseType,
            }))
        : [];

    //console.log('newClass create:', newClass);
    handleStatusBar('elaborating', false, false);
    const createResult = await postCreateClass(newClass);

    if (createResult.status) {
      handleStatusBar('success', propTrans({ prop: 'A0525' }), true);

      setTimeout(() => {
        setShowModalNew(false);
        resetFormState();
        setRefresh(true);
      }, 2000);
    } else if (!createResult.status || createResult.error) {
      handleStatusBar('error', createResult.message ?? propTrans({ prop: 'A0521' }), false);
      //handleStatusBar('error', createResult.message, false);
    }
  };

  /* const addAttachment = () => {
      let tempArray = [...newClass.attachments];
      tempArray.push(newAttachment);

      setNewClass(() => ({
         ...newClass,
         attachments: tempArray,
      }));

      setNewAttachment({name: '', expirationDate: '', file: ''});
   }; */

  /* const LinkSource = ({ nameSource, dataSource, fileSource }) => {
      if (nameSource) {
         return <Row className="link-source align-self-stretch align-items-center h-100">
            <div className="col-8 pl-4"><a href={getBaseUrl() + "/fileimg/" + fileSource} target="_blank" rel="noreferrer">{nameSource}</a> </div>
            <div className="col-2 pr-4 pl-0 text-right">{dataSource}</div>
            // WIP
            <div className="col-2 pr-4 h-100 d-flex align-items-center justify-content-end" style={{minWidth: '2rem'}}>
               <Button 
                  variant="link" 
                  className="text-right pb-0 pt-0" 
                  onClick={() => { if (window.confirm('are you sure you want to delete this item?')) console.log('nameSource', nameSource) }}
               >
                  <IcoCanc className="second-color ico-small cancel" />
               </Button>
            </div>
         </Row>
      }
   }; */

  const expenseItemsRows = (expenseItems, setExpenseItems, expenseType) => {
    let rows = [];

    if (expenseItems) {
      if (expenseItems.length === 0) {
        expenseItems = [{ id: '', expenseType: '', percentage: 0 }];
      }

      //console.log('expenseItems', expenseItems);
      let lastIndex = expenseItems.length - 1;

      rows = expenseItems.map((el, i) => {
        const { percentage, ...restExpenseItems } = expenseItems[i];
        //console.log('restExpenseItems', restExpenseItems);
        const isThisIndicatorFilled = Object.values(restExpenseItems).every((prop) => prop !== '')
          ? true
          : false;

        return (
          <Row className='align-items-center mb-3' key={i}>
            <Col className='col-6'>
              <Select
                name='id'
                value={el.id === '' ? [] : optionsExpenseItems.filter((opt) => opt.value === el.id)}
                options={optionsExpenseItems.filter((opt) => opt.type === expenseType)}
                onChange={(e) =>
                  onChange(e, 'expenseItems', setExpenseItems, expenseItems, i, expenseType)
                }
                {...(expenseType === 'opex' && expenseItems.length === 1
                  ? { isClearable: true }
                  : null)}
              />
            </Col>
            {expenseType !== 'revenues' ? (
              <Col className='pl-5'>
                <Row className='align-items-center'>
                  <Col>
                    <InputGroup style={{ width: '12rem' }}>
                      <Form.Control
                        type='number'
                        name='percentage'
                        placeholder='percentage'
                        min={0}
                        max={100}
                        step={10}
                        value={el.percentage ?? ''}
                        onChange={(e) =>
                          onChange(e, 'expenseItems', setExpenseItems, expenseItems, i, expenseType)
                        }
                      />
                      <InputGroup.Text className='addon-for-number'>&#37;</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col>
                    {expenseItems.length !== 1 ? (
                      <Button
                        variant='link'
                        className='mr-3'
                        onClick={() => removeExpenseItem(expenseItems, setExpenseItems, i)}>
                        <IcoMinusCircled className='red-color ico-small indicators' />
                      </Button>
                    ) : null}
                    {isThisIndicatorFilled &&
                    optionsExpenseItems.filter(
                      (opt) => opt.type === expenseType && opt.isDisabled === false
                    ).length !== 0 &&
                    i === lastIndex ? (
                      <Button
                        variant='link'
                        onClick={() => addExpenseItem(expenseItems, setExpenseItems, expenseType)}>
                        <IcoPlus className='second-color ico-small indicators' />
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
        );
      });
    }

    return rows;
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
            {/* USER NOT AUTHORIZED */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='classes' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-5'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0376' />
            </h5>
          </div>
          <div className='col-7 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    <Trans code='A0510' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>
        {tableSbShow}

        <div className='row'>
          <div style={containerStyle} className='col-12' id='grid-wrapper'>
            <div style={gridStyle} className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={classes}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
                //masterDetail={true}
                //detailCellRenderer={detailCellRenderer}
              />
              <div className='example-header'>
                <Trans code='A0602' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size={activeKey === 'bridgeMatrix' ? 'xl' : 'xl'}
        id='class-modal'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className='px-2'>
            <Trans code='A0499' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 px-0'>
          <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} justify>
            {/* Generale */}
            <Tab eventKey='general' title={propTrans({ prop: 'A0041' })} className='px-5'>
              <Form onSubmit={handleNewClass} id='form-admin-class'>
                <Row className='mt-5 mb-3'>
                  <Col>
                    <Form.Group as={Col} className='pl-4 pr-2' controlId='sectorCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0079' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='sectorCode'
                        value={selectedSector}
                        options={optionsNaceSectors}
                        onChange={(e) => onChange(e, 'sectorCode', setSelectedSector)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className='px-2' controlId='investmentType'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0500' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='investmentType'
                        value={selectedInterventType}
                        options={optionsInterventTypes.filter(
                          (optIntType) => optIntType.oeSectorCode === selectedSector.value
                        )}
                        onChange={(e) => onChange(e, 'investmentType', setSelectedInterventType)}
                        isDisabled={selectedSector.length === 0}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className='pr-4 pl-2' controlId='companyId'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0058' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='companyId'
                        value={selectedCompany}
                        options={optionsCompanies}
                        onChange={(e) => onChange(e, 'companyId', setSelectedCompany)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} className='px-4' controlId='description'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0138' />
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='description'
                        defaultValue={newClass.description ?? ''}
                        onChange={(e) => onChange(e, 'description')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} className='px-4' controlId='tags'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0501' />
                      </Form.Label>
                      <CreatableSelect
                        className='multiselect-no-flex'
                        value={selectedTags}
                        closeMenuOnSelect={false}
                        options={optionsTags}
                        isMulti
                        isClearable
                        onChange={(e) => onChange(e, 'tags', setSelectedTags)}
                        onCreateOption={onCreateOption}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3 mt-4'>
                  <Col>
                    <Form.Group as={Row} className='px-4' controlId='localization'>
                      <Form.Label className='mb-0 pl-3'>
                        <Trans code='A0520' />
                      </Form.Label>
                      <Form.Check
                        inline
                        style={{ marginTop: '0.2rem' }}
                        className='ml-3'
                        type='checkbox'
                        name='localization'
                        defaultChecked={newClass.localization}
                        onClick={(e) => onChange(e, 'localization')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {!newClass.version ? (
                  <Form.Group as={Row} className='my-4 px-4 align-content-center' controlId='name'>
                    <Col className='col-1'>
                      <Form.Label className='mb-0'>
                        <Trans code='A0502' />
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={newClass.version ?? 'n.d.'}
                        className='p-0'
                      />
                    </Col>
                  </Form.Group>
                ) : null}

                {/* <Row className="mb-3">
                           <Form.Group as={Col} className="px-4 col-12" controlId="attachments">
                              <Row className="px-3"><Form.Label className="mb-3">Add Attachments</Form.Label></Row>

                              <Row className="mx-0 py-3 border">
                                 <Col className="col-9">
                                    <Row>
                                       <Form.Group as={Col} className="px-4" controlId="attachments">
                                          <Form.Label className="mb-3 font-weight-normal">Name</Form.Label>
                                          <Form.Control 
                                             type="text" 
                                             placeholder="Attachment name" 
                                             name="name"
                                             value={newAttachment.name}
                                             onChange={e => onChange(e, 'attachments', setNewAttachment)}
                                          />
                                       </Form.Group>
                                       <Form.Group as={Col} className="col-4 px-4" controlId="attachments">
                                          <Form.Label className="mb-3 font-weight-normal">Expiration Date</Form.Label>
                                          <Form.Control 
                                             type="number" 
                                             placeholder="Year" 
                                             name="expirationDate" 
                                             value={newAttachment.expirationDate}
                                             onChange={e => onChange(e, 'attachments', setNewAttachment)}
                                          />
                                       </Form.Group>
                                    </Row>
                                    <Row>
                                       <Form.Group as={Col} className="px-4 align-self-center" controlId="attachments">
                                          <Form.Label className="mb-3 font-weight-normal">File</Form.Label>
                                          <Form.Control 
                                             type="file" 
                                             placeholder="File" 
                                             name="file"
                                             value={newAttachment.file}
                                             onChange={e => onChange(e, 'attachments', setNewAttachment)}
                                          />
                                       </Form.Group>
                                    </Row>
                                 </Col>

                                 <Col className="align-self-center">
                                    {Object.values(newAttachment).every(prop => prop !== '' && prop != null) ? 
                                       <Button variant="link" className="pl-2" onClick={() => addAttachment()}>
                                          <IcoPlus className="second-color ico-small indicators" />
                                       </Button> : null
                                    }
                                 </Col> 
                              </Row>
                           </Form.Group>
                        </Row>

                        <Row className="mb-3 px-4 align-items-center flex-nowrap">  
                           <Col className="px-0">
                              {newClass.attachments && newClass.attachments.map((fileElement, index) => {
                                 return <LinkSource key={index} nameSource={fileElement.name} dataSource={fileElement.expirationDate} fileSource={fileElement.file} />
                              })} 
                           </Col>
                        </Row> */}
              </Form>
              {sbShow}

              <Row>
                <Col className='px-4'>
                  <span className='mt-4 px-4'>
                    <span className='required-field'>*</span>&nbsp;
                    <Trans code='A0503' />
                  </span>
                </Col>
              </Row>
            </Tab>

            {/* KPI */}
            <Tab eventKey='kpi' title={propTrans({ prop: 'A0381' })} className='px-5'>
              <Form onSubmit={handleNewClass} id='form-admin-class'>
                <Row className='mb-3 mt-5 col-9'>
                  <Form.Group as={Col} className='px-4' controlId='kpi'>
                    <Form.Label className='mb-3 mr-4'>
                      <Trans code='A0504' />
                    </Form.Label>
                    <Select
                      id='multiselect-kpi'
                      value={selectedKpi}
                      closeMenuOnSelect={false}
                      options={optionsKpi}
                      isMulti
                      isClearable
                      onChange={(e) => onChange(e, 'kpi', setSelectedKpi)}
                    />
                  </Form.Group>
                </Row>
              </Form>
              {sbShow}

              <span className='mt-4 px-3'>
                <span className='required-field'>*</span>&nbsp;
                <Trans code='A0503' />
              </span>
            </Tab>

            {/* Voci di spesa */}
            <Tab eventKey='expenseItems' title={propTrans({ prop: 'A0705' })} className='px-5'>
              <Row className='mb-3 mt-5'>
                <Form.Group as={Col} className='px-4' controlId='expenseItems'>
                  <Form.Label className='mb-3 mr-4'>
                    <Trans code='A0505' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  {expenseItemsRows(expenseItemCapex, setExpenseItemCapex, 'capex')}
                  <Row>
                    <Col className='col-6'></Col>
                    <Col className='col-3 pl-5 pr-4'>
                      <div
                        style={{ width: '12rem' }}
                        className={`total-capex d-flex justify-content-between alert ${
                          sumOfCapex === 0
                            ? 'alert-warning'
                            : sumOfCapex === 100
                            ? 'alert-success'
                            : 'alert-danger'
                        }`}>
                        <span className='font-weight-bold'>
                          <Trans code='A0111' />
                        </span>
                        <span className='font-weight-bold text-right'>{sumOfCapex}/100</span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} className='px-4' controlId='expenseItems'>
                  <div style={{ marginBottom: '4rem' }}>
                    <Form.Label className='mb-0 mr-4'>
                      <Trans code='A0506' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Text
                      id='capexToOpexShare'
                      muted
                      className='mb-3 percTotParent position-absolute'>
                      <span className='mr-2'>
                        <Trans code='A0507C' />
                      </span>
                      <Form.Control
                        className='capexToOpexShare'
                        type='number'
                        name='capexToOpexShare'
                        min={0}
                        max={100}
                        step={1}
                        value={newClass.expenseitemslist?.[0].capexToOpexShare ?? ''}
                        onChange={(e) => onChange(e, 'expenseItems')}
                      />
                      <span>
                        <Trans code='A0507B' />
                      </span>
                    </Form.Text>
                  </div>
                  {expenseItemsRows(expenseItemOpex, setExpenseItemOpex, 'opex')}
                  <Row>
                    <Col className='col-6'></Col>
                    <Col className='col-3 pl-5 pr-4'>
                      <div
                        style={{ width: '12rem' }}
                        className={`total-capex d-flex justify-content-between alert ${
                          sumOfOpex === 0
                            ? 'alert-warning'
                            : sumOfOpex === 100
                            ? 'alert-success'
                            : 'alert-danger'
                        }`}>
                        <span className='font-weight-bold'>
                          <Trans code='A0111' />
                        </span>
                        <span className='font-weight-bold text-right'>{sumOfOpex}/100</span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row className='mb-3 mt-4'>
                <Form.Group as={Col} className='px-4' controlId='expenseItems'>
                  <Form.Label className='mb-4 mr-4'>
                    <Trans code='A0508' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  {/* {expenseItemsRows(expenseItemRevenues, setExpenseItemRevenues, 'revenues')} */}
                  <Row>
                    <Col className='col-9 pr-4'>
                      <Select
                        className='multiselect-no-flex'
                        name='expenseItems'
                        value={selectedRevenues}
                        options={optionsExpenseItems.filter((opt) => opt.type === 'revenues')}
                        closeMenuOnSelect={false}
                        isMulti
                        isClearable
                        onChange={(e) =>
                          onChange(
                            e,
                            'expenseItems',
                            setExpenseItemRevenues,
                            expenseItemRevenues,
                            undefined,
                            'revenues'
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              {sbShow}
              <span className='mt-4 px-3'>
                <span className='required-field'>*</span>&nbsp;
                <Trans code='A0503' />
              </span>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={(e) => handleNewClass(e)}>
            <Trans code='A0509' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Classes;
