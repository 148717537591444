import { IcoClosedEye, IcoEye } from 'icone/index';
import React from 'react';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
const PasswordRecoveryPassword = ({ loginForm, setLoginForm, isLoading, error, handleLogin }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <Form className='w-100'>
      <Col style={{ position: 'relative' }}>
        <h5 className='login-header-main'>
          <p>Inserisci la nuova password</p>
        </h5>
      </Col>

      <>
        <Form.Group controlId='passwordRecoveryPassword' style={{ position: 'relative' }}>
          <Form.Control
            name='passwordRecoveryPassword'
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            value={loginForm.password}
            onChange={({ target: { value } }) =>
              setLoginForm({
                ...loginForm,
                password: value,
              })
            }
          />

          <div
            className='pass-eye-ico pass-recovery-icon'
            onClick={(e) => {
              e.stopPropagation();
              setShowPassword(!showPassword);
            }}>
            {showPassword ? <IcoClosedEye /> : <IcoEye />}
          </div>
        </Form.Group>
        <Form.Group controlId='passwordRecoveryConfirmPassword' style={{ position: 'relative' }}>
          <Form.Control
            name='passwordRecoveryConfirmPassword'
            type={showPassword ? 'text' : 'password'}
            placeholder='Conferma Password'
            value={loginForm.passwordConfirmation}
            onChange={({ target: { value } }) =>
              setLoginForm({
                ...loginForm,
                passwordConfirmation: value,
              })
            }
          />
          <div
            className='pass-eye-ico pass-recovery-icon'
            onClick={(e) => {
              e.stopPropagation();
              setShowPassword(!showPassword);
            }}>
            {showPassword ? <IcoClosedEye /> : <IcoEye />}
          </div>
        </Form.Group>
      </>

      <Button disabled={isLoading} variant='info' type='submit' onClick={handleLogin}>
        Submit
      </Button>

      {error && (
        <Form.Text className='text-muted'>
          <h5>{error}</h5>
        </Form.Text>
      )}
    </Form>
  );
};

export default PasswordRecoveryPassword;
