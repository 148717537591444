import mapping  from './italyRegionsMapping.json'

export function mapData(data) {
    const res = {};
    let max = 0, min = 0;
    let total = 0;
    data.economicData.map(next => {
        if (next.regionCode !== 'ROW') total += next.totalCapex + next.totalOpex;
    });
    let map = data.economicData.reduce(
        (acc, next) => {
            if (next.regionCode === 'ROW') {
                return acc
            }
            if (max > next.totalCapex) max = next.totalCapex;
            if (min < next.totalCapex) min = next.totalCapex;
            return {
                ...acc,
                [regionsCodes[next.regionCode]["reg_istat_code"]]: {
                    ...next,
                    numValue: next.totalCapex + next.totalOpex || 0,
                    textValues: [
                        ['CAPEX', new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(next.totalCapex)],
                        ['OPEX', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(next.totalOpex)],
                        ['REGION TOTAL', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(next.totalCapex + next.totalOpex)],
                        ['TOTAL', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(total)],
                        ['SHARE ON TOTAL', new Intl.NumberFormat('it-IT', {style: 'percent', maximumFractionDigits: 0}).format(((next.totalCapex + next.totalOpex) / total))]
                    ]
                }
            }
        }, {});
    // Null value for empty elements
    map['none'] = {
            textValues: [
                ['CAPEX', new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)],
                ['OPEX', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)],
                ['REGION TOTAL', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(0)],
                ['TOTAL', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR',minimumFractionDigits: 2, maximumFractionDigits: 2}).format(total)],
                ['SHARE ON TOTAL', new Intl.NumberFormat('it-IT', {style: 'percent', maximumFractionDigits: 0}).format(0)]
            ]
        };

    res['Investimento'] = [map, max, min];
    res['PIL'] = getByRegionsMap(data.analysisResults.IA.gdp.gdpByRegion);
    res['Occupazione'] = getByRegionsMap(data.analysisResults.IA.employment.empByRegion);
    res['Redditi'] = getByRegionsMap(data.analysisResults.IA.incomes.hhByRegion);
    res['Tasse'] = getByRegionsMap(data.analysisResults.IA.taxes.taxesByRegion);

    return res;
}

const regionsCodes = mapping.data.reduce((acc, next) => ({...acc, [next.code]: next}), {});

function getByRegionsMap(arr) {
    let max = 0, min = 0;
    let total = 0;
    arr.map(next => {
        if (next.region !== 'ROW') total += next.propertyValue;
    });
    let res;
    res = arr.reduce(
        (acc, {propertyValue, region, ...rest}) => {
            if (region === 'ROW') return acc;
            if (max > propertyValue) max = propertyValue;
            if (min < propertyValue) min = propertyValue;
            return {
                ...acc,
                [regionsCodes[region]["reg_istat_code"]]: {
                    ...rest,
                    numValue: propertyValue || 0,
                    textValues: [
                        ['REGIONAL VALUE', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(propertyValue)],
                        ['TOTAL VALUE', new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(total)],
                        ['SHARE ON TOTAL', new Intl.NumberFormat('it-IT', {style: 'percent', maximumFractionDigits: 0}).format(propertyValue / total)]
                    ]
                }
            }
        }, {})
    return [res, max, min];
}