import React from 'react'
import { rounder } from '@lib/helpers'
import JobsChart from '@components/scenario/jobs/JobsChart'
import JobsTable from '@components/scenario/jobs/JobsTable'
import JobsSectorsTable from '@components/scenario/jobs/JobsSectorsTable'
import { Trans } from '@lib/i18n'
import {Ico42 } from "@icons/new"

const Jobs = ({ jobs: { agricolture, industry, services, totaleJobs, settori, description, base_url, image_occ_text_1, image_occ_text_2,image_occ_1, image_occ_2 } }) => (
  <>
    <div className="simple-box">
      <div className="row">
        <div className="col-3 investment-infobox va-number">
          <div>
            <h2>{rounder(totaleJobs)}</h2>
            <span className="va-desc"><Trans code="A0054" /></span>
          </div>
        </div>
        <div className="col-9 investment-infobox">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-6 d-flex container-card">
        <JobsChart jobsData={{ agricolture, industry, services }} />
      </div>
      <div className="col-6 d-flex container-card">
        <JobsTable jobsData={{ agricolture, industry, services, total: totaleJobs }} />
      </div>
    </div>
    { image_occ_1 && image_occ_1.indexOf("empty") < 0 && image_occ_text_2 && image_occ_text_2.indexOf("empty") < 0 && 
    <div className='row'>
      <div className="col-12 col-xl-6 d-flex container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{image_occ_text_1}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
               <img className="m-auto img-card" src={`${base_url}${image_occ_1}`} alt="" onerror="this.style.display='none'"/>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 d-flex container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{image_occ_text_2}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
              <img className="m-auto img-card" src={`${base_url}${image_occ_2}`} alt="" onerror="this.style.display='none'"/>
            </div>
          </div>
        </div>
        </div>
        }
    <div className="row">
      <div className="col">
        <JobsSectorsTable settori={settori} />
      </div>
    </div>
  </>
)

export default Jobs
