import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const WaterfallChartGraph = ({ data, height, width, valueFontSize = 30, sign }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && chartRef.current) {
      const chart = chartRef.current;
      d3.select(chart).select('svg').remove();
      const categoryTextHeight = 30; // Height reserved for the category text
      const totalValue = data[0].propertyValue; // Get the total value
      const yMax = d3.max(data, (d) => d.propertyValue + 0.25 * d.propertyValue);
      const yScale = d3
        .scaleLinear()
        .domain([0, yMax]) // Adjusted domain to start from 0
        .range([height - categoryTextHeight, 0])
        .nice(3); // Set number of ticks on the y-axis to 4

      const xScale = d3
        .scaleBand()
        .domain(data.map((d) => d.propertyName))
        .range([0, width - 80])
        .padding(0.1);

      const colorScale = ['#c2c923', '#c00600', '#08a2aa'];

      const xAxis = d3.axisBottom(xScale);
      const formatValue = (value) => {
        const formattedNumber = d3.format('.4s')(value);
        const suffix = formattedNumber.slice(-1); // Get the last character (suffix)
        const number = formattedNumber.slice(0, -1); // Get the number part without the suffix

        return number.replace('.', ',') + suffix;
      };

      const svg = d3.select(chart).append('svg').attr('width', width).attr('height', height);
      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${height - categoryTextHeight})`)
        .call(xAxis.tickFormat('').tickSizeOuter(0))
        .selectAll('.tick text')
        .style('text-anchor', 'middle')
        .style('font-size', '.9em')
        .attr('dy', '1em') // Adjust the vertical offset as desired
        .attr('dx', (d) => xScale(d.propertyName) + xScale.bandwidth() / 2) // Adjust the horizontal offset to center the text
        .text((_, i) => data[i].propertyName.toUpperCase());

      var div = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip-waterfall')
        .style('opacity', 0);

      const bars = svg
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .on('mouseover', function (event, d, i) {
          d3.select(this).transition().duration('50').attr('opacity', '.85');
          div.transition().duration(50).style('opacity', 1);
          div
            .html(
              `${d.propertyName.toUpperCase()} <br/>Value: ${formatValue(
                d.propertyValue
              ).toString()}${sign}`
            )
            .style('left', event.pageX + 10 + 'px')
            .style('top', event.pageY - 15 + 'px');
        })
        .on('mouseout', function (d, i) {
          d3.select(this).transition().duration('50').attr('opacity', '1');
          div.transition().duration('50').style('opacity', 0);
        });

      bars
        .append('rect')
        .attr('x', (d) => xScale(d.propertyName))
        .attr('y', (d, i) => {
          if (i === 0) {
            return yScale(d.propertyValue);
          }
          if (i === 1) {
            return yScale(totalValue);
          }
          if (i === 2) {
            return yScale(totalValue - data[1].propertyValue);
          }
          return 0;
        })
        .attr('width', xScale.bandwidth())
        .attr('height', (d) => Math.abs(yScale(0) - yScale(d.propertyValue))) // Adjusted height calculation
        .attr('fill', (d, i) => colorScale[i]);

      const heightsOfBars = [];
      bars.each(function (d, i) {
        const barHeight = +d3.select(this).select('rect').attr('height');
        heightsOfBars.push(barHeight);
      });

      bars
        .append('text')
        .attr('class', 'bar-value')
        .attr('x', (d) => xScale(d.propertyName) + xScale.bandwidth() / 2)
        .attr('y', (d, i) => {
          if (i === 0) {
            return yScale(d.propertyValue) - 5;
          }
          if (i === 1) {
            return yScale(totalValue) - 5;
          }
          if (i === 2) {
            return yScale(totalValue - data[1].propertyValue) - 5;
          }
          return 0;
        })
        .attr('text-anchor', 'middle')
        .style('font-size', `${valueFontSize}px`)
        .style('font-weight', 'bold')
        .text((d) => `${formatValue(d.propertyValue)}${sign}`);
    }
  }, [data]);

  return <div ref={chartRef} />;
};

export default WaterfallChartGraph;
