import React, { createContext, useState } from 'react';

// Create a context
export const LogoContext = createContext();

// Create a provider component
export const LogoProvider = ({ children }) => {
  const [logo, setLogo] = useState('default_logo.png');
  return <LogoContext.Provider value={{ logo, setLogo }}>{children}</LogoContext.Provider>;
};
