import React from 'react';
import { Form } from 'react-bootstrap';
import QuestionText from './QuestionText';
import GenericDownloadUploadItems from 'components/DownloadUploadView/GenericDownloadUploadView';
import TooltipComponent from './TooltipComponent';
const InputRadio = ({ question, handleAssessmentChange, handleChecked, isPremium }) => {
  return (
    <QuestionText question={question}>
      <div key={`default-radio-${question.id}`} className='mb-3'>
        {question.answers.map((answer) => (
          <div key={answer.id} className='d-flex align-items-center mb-3 row'>
            <div className='col d-flex align-items-center'>
              <Form.Check
                type='radio'
                // key={answer.id}
                id={`${answer.id}`}
                name={`${question.id}`}
                label={answer.text}
                className='specificyColor'
                onChange={(e) => handleAssessmentChange(e, question.id, answer.id, question.type)}
                checked={handleChecked(question.id, answer.id)}
                disabled={isPremium}
              />
              {answer.description ? <TooltipComponent data={answer.description} /> : null}
            </div>
            {!!answer.attachment && (
              <div className='col'>
                <GenericDownloadUploadItems
                  title={'Allegato'}
                  disabled={!handleChecked(question.id, answer.id) || isPremium}
                  corporatequestionnaireId={question?.corporatequestionnaireId || null}
                  answerId={answer.id}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </QuestionText>
  );
};

export default InputRadio;
