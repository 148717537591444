import React from "react";
import { Trans } from '@lib/i18n'

function Home() {
  return (
    <div className="home">
      <h1 className="font-weight-light">Home</h1>

      <h3>Test traduzioni</h3>
      <p>Traduzione A0039</p>
      <Trans code="A0039" />
      <br />
      <br />
      <p>Traduzione A0010</p>
      <Trans code="A0010" />

      <br />
      <br />

      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text
        ever since the 1500s, when an unknown printer took a galley of
        type and scrambled it to make a type specimen book.
      </p>
    </div>
  );
}

export default Home;