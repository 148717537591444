import React from 'react'
import { gql } from '@apollo/client'
import GenericMap from '@components/map/generic';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Trans } from '@lib/i18n'


const getQueryBook = gql`
  query publication{
    publications(sort: "date_it:DESC" limit:5  where: { category_it:"libri" }) {
      title_it
      date_it
      abstract_it
      link_it
      category_it
      slug_it
    	author_it
    	editor_it
      image_it{
        url
        name
      }
    }
  }
`;

const getQueryAdditionals = gql`
  query publication{
    publications(sort: "date_it:DESC" limit:5  where: { category_it:"pubblicazioni_ulteriori" }) {
      title_it
      date_it
      category_it
      abstract_it
      slug_it
    	author_it
    	editor_it
      link_it
      image_it{
        url
        name
      }
    }
  }
`;

const getQueryArticlesInt = gql`
  query publication{
    publications(sort: "date_it:DESC" limit:5 where: { category_it:"articoli_in_riviste_internazionali" }) {
      title_it
      date_it
      category_it
      abstract_it
      link_it
      slug_it
    	author_it
    	editor_it
      image_it{
        url
        name
      }
    }
  }
`;

const getQueryArticlesIta = gql`
  query publication{
    publications(sort: "date_it:DESC" limit:5 where: { category_it:"articoli_in_riviste_e_giornali_italiani" }) {
      title_it
      date_it
      category_it
      slug_it
      link_it
    	author_it
    	editor_it
      abstract_it
      image_it{
        url
        name
      }
    }
  }
`;

const getQueryMonographs = gql`
  query publication{
    publications(sort: "date_it:DESC" limit:5 where: { category_it:"monografie" }) {
      title_it
      date_it
      category_it
      slug_it
    	author_it
      link_it
    	editor_it
      abstract_it
      image_it{
        url
        name
      }
    }
  }
`;

const getQueryChapters = gql`
  query publication{
    publications(sort: "date_it:DESC" limit:5 where: { category_it:"capitoli_in_libri_e_articoli_di_enciclopedia" }) {
      title_it
      date_it
      category_it
      slug_it
      link_it
    	author_it
    	editor_it
      abstract_it
      image_it{
        url
        name
      }
    }
  }
`;

const PubblicazioniOE = () => {

  return <div id="pubblicazioni-oe" className="megaContent feed-oe">
    <Container fluid className="books-index">
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0072" /></h2>
          </Col>
        </Row>
        <GenericMap type="publications" query={getQueryBook} path="/oe-publications" />
        <Row>
          <Col xs={12}>
            <Button variant="success" href="https://www.openeconomics.eu/oe-publications/archive/libri" target="_blank"><Trans code="F0072" /></Button>
          </Col>
        </Row>
      </div>
    </Container>
    <Container fluid className="articles-int-index">
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0073" /></h2>
          </Col>
        </Row>
        <GenericMap type="publications" query={getQueryArticlesInt} path="/oe-publications" />
        <Row>
          <Col xs={12}>
            <Button variant="success" href="https://www.openeconomics.eu/oe-publications/archive/articoli_in_riviste_internazionali" target="_blank"><Trans code="F0073" /></Button>
          </Col>
        </Row>
      </div>
    </Container>
    <Container fluid className="articles-ita-index">
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0077" /></h2>
          </Col>
        </Row>
        <GenericMap type="publications" query={getQueryArticlesIta} path="/oe-publications" />
        <Row>
          <Col xs={12}>
            <Button variant="success" href="https://www.openeconomics.eu/oe-publications/archive/articoli_in_riviste_e_giornali_italiani" target="_blank"><Trans code="F0077" /></Button>
          </Col>
        </Row>
      </div>
    </Container>
    <Container fluid className="monographs-index">
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0076" /></h2>
          </Col>
        </Row>
        <GenericMap type="publications" query={getQueryMonographs} path="/oe-publications" />
        <Row>
          <Col xs={12}>
            <Button variant="success" href="https://www.openeconomics.eu/oe-publications/archive/monografie" target="_blank"><Trans code="F0076" /></Button>
          </Col>
        </Row>
      </div>
    </Container>
    <Container fluid className="index-additional">
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0075" /></h2>
          </Col>
        </Row>
        <GenericMap type="publications" query={getQueryAdditionals} path="/oe-publications" />
        <Row>
          <Col xs={12}>
            <Button variant="success" href="https://www.openeconomics.eu/oe-publications/archive/pubblicazioni_ulteriori" target="_blank"><Trans code="F0075" /></Button>
          </Col>
        </Row>
      </div>
    </Container >
    <Container fluid className="index-chapters">
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0074" /></h2>
          </Col>
        </Row>
        <GenericMap type="publications" query={getQueryChapters} path="/oe-publications" />
        <Row>
          <Col xs={12}>
            <Button variant="success" href="https://www.openeconomics.eu/oe-publications/archive/capitoli_in_libri_e_articoli_di_enciclopedia" target="_blank"><Trans code="F0074" /></Button>
          </Col>
        </Row>
      </div>
    </Container>
  </div>
}

export default PubblicazioniOE