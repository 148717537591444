import React from 'react';
import { Button } from 'react-bootstrap';
import { IcoCanc, IcoEdit } from 'icone/index';
import TruncatedValue from 'components/RenderTruncatedValue';

const CompaniesCard = ({
  data,
  selected,
  setSelected,
  setShow,
  setNewOrganization,
  isChild,
  isSelectable,
  setShowModalDelete,
  setDeleteId,
  isUserCompany,
  isOe,
  shouldBeAbleToDomain,
}) => {
  return (
    <>
      <div
        className={`d-flex flex-column justify-content-center align-items-center p-3 company-card ${
          selected > 0 && selected !== data.id && !isChild ? 'deactivated' : ''
        }`}
        onClick={() => {
          if (isSelectable) setSelected((prevState) => (prevState !== data.id ? data.id : 0));
        }}>
        <div className='d-flex justify-content-center align-items-center image-container'>
          {data?.image?.includes('data') ? (
            <img className='logo' src={data?.image} alt={'logo'} />
          ) : (
            <span className='placeholder' style={{ borderRadius: '5px' }}>
              Placeholder
            </span>
          )}
        </div>
        <div className='text-container'>
          {/* <p className='mb-0 text-center'>{data?.name}</p> */}
          <TruncatedValue value={data?.name} checkingValue={28} substringValues={[0, 25]} />
        </div>
        <div className='button-container d-flex' style={{ gap: '10px' }}>
          {(isOe || !isUserCompany) && (
            <Button
              variant='link'
              onClick={(e) => {
                e.stopPropagation();
                setNewOrganization(data);
                setShow(true);
              }}>
              <IcoEdit className='second-color ico-small edit' />
            </Button>
          )}
          {!isUserCompany && (
            <Button
              variant='link'
              className='mr-0'
              onClick={(e) => {
                e.stopPropagation();
                setDeleteId(data.id);
                setShowModalDelete(true);
              }}>
              <IcoCanc className='second-color ico-small cancel' />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CompaniesCard;
