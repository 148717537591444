import React, { useMemo } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { Trans } from '@lib/i18n/index';
const CustomSpinner = ({ style, withText = true }) => {
  const getStyles = useMemo(() => {
    return style ? style : { backgroundColor: 'white', height: '100%', width: '100%' };
  }, [style]);

  return (
    <Row className='justify-content-center align-items-center' style={{ ...getStyles }}>
      <Spinner animation='border' role='status' />
      {withText && <span className='ml-2'>{<Trans code='A0028' />}</span>}
    </Row>
  );
};

export default CustomSpinner;
