import { Trans } from '@lib/i18n/index';
import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import DocumentItemsWrapper from './DocumentItemsWrapper';
import { uploadEntityDocument } from '@lib/api/index';
import CustomSpinner from 'components/CustomSpinner';
import UploadItem from './UploadItem';

const DocumentsUploadItem = ({
  title,
  subtitleTransCode,
  entityType = 'OeClass',
  entityId,
  handleStatusBar,
  setRefresh,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (selectedFileChange) => {
    if (!selectedFileChange) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    try {
      const response = await uploadEntityDocument(entityType, entityId, false, formData);
      if (response.status) {
        setIsLoading(false);
        handleStatusBar('success', <Trans code='A0654' />, true);
        setRefresh(true);
      } else {
        setIsLoading(false);
        handleStatusBar('error', response.message, true);
      }
    } catch (error) {
      console.error('Error uploading file', error);
    }
    setSelectedFile(null);
  };

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload(selectedFile);
    }
  }, [selectedFile]);

  return (
    <DocumentItemsWrapper
      title={title}
      subtitleTransCode={subtitleTransCode}
      sectionTitleTransCode={'A0828'}
      isUpload={true}
      permittedFiles={'pdf, docx, jpg, png, xlsx'}>
      <UploadItem setSelectedFile={setSelectedFile} isLoading={isLoading} />
    </DocumentItemsWrapper>
  );
};

export default DocumentsUploadItem;
