import React, { useState, useEffect } from 'react';
import { rounder } from '@lib/helpers';
import GdpFactorsChart from '@components/scenario/gdp/GdpFactorsChart';
import GdpTipologiesChart from '@components/scenario/gdp/GdpTipologiesChart';
import GdpSectorsChart from '@components/scenario/gdp/GdpSectorsChart';
import GdpTypesChart from '@components/scenario/gdp/GdpTypesChart';
import GdpPhasesChart from '@components/scenario/gdp/GdpPhasesChart';
import GdpVATable from '@components/scenario/gdp/GdpVATable';
import { Trans } from '@lib/i18n';
import { Ico42 } from '@icons/new';

const GDP = ({ gdp }) => {
  const [numberOfData, setNumberOfData] = useState(0);
  console.log('gdp', gdp);

  useEffect(() => {
    const number = Object.keys(gdp).filter(
      (key) =>
        (key === 'factors' && gdp[key].total !== 0) ||
        (key === 'phase' && gdp[key].total !== 0) ||
        (key === 'sector' && gdp[key].total !== 0) ||
        (key === 'tipologies' && gdp[key].total !== 0) ||
        (key === 'type' && gdp[key].total !== 0)
    ).length;

    setNumberOfData(number);
  }, [gdp]);

  useEffect(() => {
    if (numberOfData && numberOfData === 3) {
      [...document.getElementsByClassName('layout-charts')].forEach((element) => {
        element.classList.add(window.innerWidth < 1280 ? 'col-12' : 'col-4');
      });
    } else if (numberOfData && numberOfData > 3) {
      [...document.getElementsByClassName('layout-charts')].forEach((element) => {
        element.classList.add(
          window.innerWidth >= 1920
            ? 'col-3'
            : window.innerWidth >= 1600
            ? 'col-6'
            : window.innerWidth < 1280
            ? 'col-12'
            : 'col-6'
        );
      });
    }
  }, [numberOfData]);

  return (
    <>
      <div className='simple-box'>
        <div className='row'>
          <div className='col-3 investment-infobox va-number'>
            <div>
              <h2>{rounder(gdp.factors.total)}</h2>
              <span className='va-desc'>
                <Trans code='A0053' />
              </span>
            </div>
          </div>
          <div className='col-9 investment-infobox'>
            <div dangerouslySetInnerHTML={{ __html: gdp.description }} />
          </div>
        </div>
      </div>
      <div className='row'>
        {numberOfData ? (
          <>
            <div className='layout-charts d-flex container-card mb-5'>
              <GdpFactorsChart gdp={gdp} />
            </div>

            {gdp.type.total !== 0 || gdp.phase.total !== 0 ? null : (
              <div className='layout-charts d-flex container-card mb-5'>
                <GdpTipologiesChart gdp={gdp} />
              </div>
            )}

            {gdp.type.total === 0 ? null : (
              <div className='layout-charts d-flex container-card mb-5'>
                <GdpTypesChart gdp={gdp} />
              </div>
            )}

            {gdp.phase.total === 0 ? null : (
              <div className='layout-charts d-flex container-card mb-5'>
                <GdpPhasesChart gdp={gdp} />
              </div>
            )}

            <div className='layout-charts d-flex container-card mb-5'>
              <GdpSectorsChart gdp={gdp} />
            </div>
          </>
        ) : null}
      </div>

      {gdp.image_gdp_1 &&
        gdp.image_gdp_1.indexOf('empty') < 0 &&
        gdp.image_gdp_1 &&
        gdp.image_gdp_1.indexOf('empty') < 0 && (
          <div className='row'>
            <div className='col-12 col-xl-6 d-flex container-card'>
              <div className='simple-box'>
                <div className='row'>
                  <div className='col'>
                    <Ico42 className='logo-card ico-box-blue' />
                    <h5 className='title-simple-box margin-title-box'>{gdp.image_gdp_text_1}</h5>
                  </div>
                </div>
                <div className='row'>
                  <div className='text-center' />
                  <img
                    className='m-auto img-card'
                    src={`${gdp.base_url}${gdp.image_gdp_1}`}
                    alt=''
                    onerror="this.style.display='none'"
                  />
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-6 d-flex container-card'>
              <div className='simple-box'>
                <div className='row'>
                  <div className='col'>
                    <Ico42 className='logo-card ico-box-blue' />
                    <h5 className='title-simple-box margin-title-box'>{gdp.image_gdp_text_2}</h5>
                  </div>
                </div>
                <div className='row'>
                  <div className='text-center' />
                  <img
                    className='m-auto img-card'
                    src={`${gdp.base_url}${gdp.image_gdp_2}`}
                    alt=''
                    onerror="this.style.display='none'"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      <div className='row'>
        <div className='col'>
          <GdpVATable gdp={gdp} />
        </div>
      </div>
    </>
  );
};
export default GDP;
