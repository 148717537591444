import { useState } from 'react';
import { Trans } from '@lib/i18n/index';
import DashboardHeatMap from '../dashboardSummary/dashboardHeatmap';

const SummaryHeatmap = ({ scenario }) => {
  const data = [
    { value: 'Investimento', label: 'Investment', translationCode: 'A0723' },
    // { value: 'Tasse', label: 'Taxes' },
    { value: 'PIL', label: 'GDP', translationCode: 'A0263' },
    { value: 'Redditi', label: 'Income', translationCode: 'A0051' },
    { value: 'Occupazione', label: 'Employment', translationCode: 'A0052' },
  ];
  return (
    <div className='simple-box impact'>
      <div className='row'>
        <div className='col'>
          <h5 className='title-simple-box margin-title-box'>
            <Trans code='A0773' />
          </h5>
        </div>
      </div>
      <div
        className={`row d-flex flex-wrap dashboardBreaksjustify-content-center align-items-center`}>
        {data.map((el) => {
          return (
            <div key={el.label} style={{ minWidth: '400px' }} className='col d-flex flex-column'>
              <div className='row d-flex justify-content-center'>
                <DashboardHeatMap syntheticResults={scenario} dataSource={el.value} />
              </div>
              <div className='row d-flex justify-content-center'>
                <h5>{<Trans code={el.translationCode} />}</h5>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SummaryHeatmap;
