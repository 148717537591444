import React from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { Ico3, Ico33, Ico21, Ico47, Ico92, Ico93, Ico90 } from "@icons/new";
import { Trans } from '@lib/i18n'

const Submenu = ({ callback, active }) => {
  return (
    <ButtonToolbar aria-label="Submenu dashboard" className="row d-flex justify-content-start align-items-center">
      <Button variant="link" className={active === 'general' ? 'active' : ''} onClick={() => callback('general')}>
         <div className='d-flex align-items-center'>
            <Ico33 className="submenu-ico" />
            <span className="submenu-i"><Trans code="F0060"/></span>
         </div>
      </Button>
      <Button variant="link" className={active === 'investment' ? 'active' : ''} onClick={() => callback('investment')}>
         <div className='d-flex align-items-center'>
            <Ico21 className="submenu-ico" />
            {/*<Trans code="A0261" />*/}
            <span className="submenu-i"><Trans code="A0462"/></span>
         </div>
      </Button>
      <Button variant="link" className={active === 'gdp' ? 'active' : ''} onClick={() => callback('gdp')}>
         <div className='d-flex align-items-center'>
            <Ico47 className="submenu-ico" />
            <span className="submenu-i"><Trans code="A0263"/></span>
         </div>
      </Button>
      <Button variant="link" className={active === 'jobs' ? 'active' : ''} onClick={() => callback('jobs')}>
         <div className='d-flex align-items-center'>
            <Ico3 className="submenu-ico" />
            <span className="submenu-i"><Trans code="A0328"/></span>
         </div>
      </Button>
      {/*<Button variant="link" className={active === 'production' ? 'active' : ''} onClick={() => callback('production')}>
        <Ico42 className="submenu-ico" />
        <Trans code="F0078" className="submenu-i" />    
        <span className="submenu-i"><Trans code="F0060"/></span>
      </Button>*/}
      <Button variant="link" className={active === 'incomes' ? 'active' : ''} onClick={() => callback('incomes')}>
         <div className='d-flex align-items-center'>
            <Ico93 className="submenu-ico" />
            <span className="submenu-i"><Trans code="A0051"/></span>
         </div>
      </Button>
      <Button variant="link" className={active === 'taxes' ? 'active' : ''} onClick={() => callback('taxes')}>
         <div className='d-flex align-items-center'>
            <Ico92 className="submenu-ico" />
            <span className="submenu-i"><Trans code="F0061"/></span>
         </div>
      </Button>
      <Button variant="link" className={active === 'sdg' ? 'active' : ''} onClick={() => callback('sdg')}>
         <div className='d-flex align-items-center'>
            <Ico90 className="submenu-ico" />
            <span className="submenu-i"><Trans code="A0267"/></span>
         </div>
      </Button>
      
    </ButtonToolbar>
  );
}

export default Submenu;