import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Ico91, Ico92, Ico42, Ico8, Ico33, Ico3 } from '@icons/new';
import { IcoCalculator, IcoArrowRight } from '@icons';
import { Trans } from '@lib/i18n';

const Modelling = () => {
  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
            {/* USER NOT AUTHORIZED */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='scenari' className='megaContent'>
      <h5 className='title-simple-box margin-title-box pl-3 mb-5'>
        <Trans code='A0603' />
      </h5>

      <div className='container-fluid'>
        <Row className='simple-box '>
          <Col>
            <Row className=''>
              <Col className='form-login'>
                <h5 className='mb-3'>
                  <Trans code='A0492' />
                </h5>
                <p>
                  <Trans code='A0605' />
                </p>{' '}
                {/* Sottosezioni necessarie alla costruzione delle entità di alto livello */}
              </Col>
            </Row>

            <Row /* className="justify-content-around" */>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/expenseitems'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <IcoCalculator />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='A0484' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>

              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/kpi'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico91 className='ico-card-kpi' />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='C00501' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/interventiontypes'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico92 className='ico-card-interventiontypes' />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='A0710' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/income-taxes'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico42 className='ico-card-scenarios' />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='A0771' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/unemployment'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico3 className='ico-card-scenarios' />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='A0772' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row className=''>
              <Col className='form-login'>
                <h5 className='mb-3'>
                  <Trans code='A0604' />
                </h5>
                <p>
                  <Trans code='A0608' />
                </p>{' '}
                {/* Entità di alto livello */}
              </Col>
            </Row>

            <Row /* className="justify-content-around" */>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/classes'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico8 className='ico-card-classes' />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='A0382' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/dataset'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico33 className='ico-card-dataset' />
                      </div>
                      <Card.Title className='mb-2'>
                        <Trans code='A0483' />
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            </Row>
            {/** Classi d'azienda, rimane qui? */}
            <Row className=''>
              <Col className='form-login'>
                <h5 className='mb-3'>Classi D'Azienda</h5>
                <p>
                  <Trans code='A0608' />
                </p>{' '}
                {/* Entità di alto livello */}
              </Col>
            </Row>

            <Row /* className="justify-content-around" */>
              <Col className='form-login col-card col-6'>
                <Card>
                  <Link to='/modelling/corporateclasses'>
                    <Card.Body className='text-center'>
                      <div className='ico-card-header'>
                        <Ico8 className='ico-card-classes' />
                      </div>
                      <Card.Title className='mb-2'>
                        {/* <Trans code='A0382' /> */}
                        Classi D'Azienda
                      </Card.Title>
                      <IcoArrowRight className='ico-card-button' />
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Modelling;
