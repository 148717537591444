import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';
import { Trans } from '@lib/i18n';

const EV = ({
  data,
  showModalData,
  setShowModalData,
  sectors,
  regionName,
  type = 'financialData',
}) => {
  const [allEvData, setEvData] = useState({});

  useEffect(() => {
    setEvData(data);
  }, [data]);

  const handleCloseModal = (setShowModal) => setShowModal(false);

  const totalPerColumn = (evData, colH) => {
    const dataFilteredByCol = evData.filter((el) => el.year === colH).map((el) => el.value);

    const total = dataFilteredByCol.reduce((prev, curr) => Number(prev) + Number(curr));

    return (
      <span>
        <span>
          {total.toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
        {/*<span> % </span>*/}
      </span>
    );
  };

  const buildEV = (typeCode) => {
    const evData = allEvData.filter((data) => data.type === typeCode);
    const colHeader = [
      <React.Fragment key='initial-headers'>
        <th key='th-empty'>
          <Trans code='A0592' />
        </th>
        {type !== 'financialData' && (
          <th className='text-right' key='th-conv'>
            <Trans code='A0764' />
          </th>
        )}
      </React.Fragment>,
    ];
    let colHeaderX = evData.map((el) => el.year);
    colHeaderX = colHeaderX.filter((item, index) => colHeaderX.indexOf(item) === index);

    colHeader.push(
      colHeaderX.map((colH) => (
        <th key={colH} className='text-right cell-width-economic-data'>
          {colH}
        </th>
      ))
    );

    let rowHeaderX = evData.map((el) => el.sectorCode);
    rowHeaderX = rowHeaderX.filter((item, index) => rowHeaderX.indexOf(item) === index);

    return (
      <table className='show-economic-data'>
        <thead>
          <tr>{colHeader}</tr>
        </thead>
        <tbody className='text-right'>
          <tr className='totals-row'>
            <td className='text-left'>
              <strong>
                <Trans code='A0111' />
              </strong>
            </td>
            {type !== 'financialData' && <td className='text-right'>-</td>}
            {colHeaderX.map((colH) => (
              <td key={colH + '-total'} style={{ minWidth: '120px' }} className='text-right'>
                <strong>
                  {Object.keys(evData).length > 0 &&
                    evData.length > 0 &&
                    totalPerColumn(evData, colH)}
                </strong>
              </td>
            ))}
          </tr>

          {rowHeaderX.map((rowH) => {
            return (
              <tr key={rowH}>
                <td className='pl-2'>
                  {sectors
                    .filter((sector) => sector.code === Number(rowH))
                    .map((sector) => sector.account)}
                </td>
                {type !== 'financialData' && (
                  <td>
                    {Number(
                      evData.find((el) => el.sectorCode === Number(rowH))?.conversionFactor
                    ).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                )}
                {colHeaderX.map((colH) => (
                  <td key={colH + '-td'}>
                    {evData
                      .filter((el) => el.year === colH && el.sectorCode === rowH)
                      .map((el) => (
                        <Fragment key={el.id}>
                          {Number(el.value).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Fragment>
                      ))}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        {/* <tfoot>
            <tr>
               <td className='text-left'><strong>Totale</strong></td>
               {colHeaderX.map(colH => 
                  <td key={colH + '-total'} className='text-right'>
                     <strong>
                        {showModalData && Object.keys(evData).length > 0 && evData.length > 0 && totalPerColumn(colH)} 
                     </strong>
                  </td>
               )}
            </tr>
         </tfoot> */}
      </table>
    );
  };

  return (
    <Modal
      dialogClassName='modal-fullscreen'
      show={showModalData}
      onHide={() => handleCloseModal(setShowModalData)}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans code={type === 'financialData' ? 'A0586' : 'A0768'} /> - {regionName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5'>
        <Tabs defaultActiveKey='capex' id='tab-expense-vector' className='mb-3 tab-economic-data'>
          <Tab eventKey='capex' title='CAPEX'>
            {Object.keys(allEvData).length > 0 ? buildEV('capex') : null}
          </Tab>
          <Tab eventKey='opex' title='OPEX'>
            {Object.keys(allEvData).length > 0 ? buildEV('opex') : null}
          </Tab>
          <Tab eventKey='revenues' title='REVENUES'>
            {Object.keys(allEvData).length > 0 ? buildEV('revenues') : null}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleCloseModal(setShowModalData)}>
          <Trans code='A0529' />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EV;
