import React from 'react';

const SelectorComponentWrapper = ({ title, children }) => {
  return (
    <div className='flex-grow-1 simple-box mt-0' style={{ minWidth: '500px' }}>
      <h3 className='title-simple-box margin-title-box'>{title}</h3>
      {children}
    </div>
  );
};

export default SelectorComponentWrapper;
