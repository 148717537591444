// import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import { Externalyticslogo, OpeneconomicsLogo } from '../loghi';
// import { checkLogin, checkLoginOtp, getAuthUser } from '@lib/api';
// import { useHistory } from 'react-router-dom';
// import ImpactFinance from '../pages/tab-impact-finance';
// import Platform from '../pages/piattaforma';
// import Method from '../pages/metodologia';
// import OtpInput from './OtpComponents/OtpInput';
// import Countdown from './OtpComponents/Countdown';
// import { validatePassword, validateEmail } from '@lib/helpers/validation';
// import { ArrowDownUp } from 'icone/index';

// const LoginForm = ({ referrer, setShowLogin, error, setError, isLoading, setIsLoading }) => {
//   const [loginForm, setLoginForm] = useState({
//     email: '',
//     password: '',
//     otp: '',
//   });
//   const [isOtp, setIsOtp] = useState(false);

//   const history = useHistory();

//   const handleLogin = async (e, getNewCode = false) => {
//     e.preventDefault();
//     setError(null);
//     setIsLoading(true);

//     const { email, password, otp } = loginForm;

//     if (!validateEmail(email) || !validatePassword(password)) {
//       setError('Email or password not valid');
//       setIsLoading(false);
//       return;
//     }

//     try {
//       if (otp && !getNewCode) {
//         await handleOtpLogin(loginForm);
//       } else {
//         await handleStandardLogin(loginForm);
//       }
//     } catch (error) {
//       setError('An unexpected error occurred');
//       console.error('An unexpected error occurred', error);
//     }

//     setIsLoading(false);
//   };

//   const handleOtpLogin = async (loginForm) => {
//     try {
//       const response = await checkLoginOtp(loginForm);

//       if (response.status) {
//         storeUserData(response.data);
//         redirectToHome();
//       } else {
//         setError(response.message || 'ERROR');
//         console.error('codeerror', response.message || 'ERROR');
//       }
//     } catch (error) {
//       setError('An unexpected error occurred during OTP login');
//       console.error('An unexpected error occurred during OTP login', error);
//     }
//   };

//   const handleStandardLogin = async (loginForm) => {
//     try {
//       const response = await checkLogin(loginForm);

//       if (response.status && !response.data) {
//         setIsOtp(true);
//       } else if (response.status && response.data) {
//         storeUserData(response.data);
//         redirectToHome();
//       } else {
//         setError(response.message || 'ERROR');
//         console.error('codeerror', response.message || 'ERROR');
//       }
//     } catch (error) {
//       setError('An unexpected error occurred during standard login');
//       console.error('An unexpected error occurred during standard login', error);
//     }
//   };

//   const storeUserData = (data) => {
//     const { companies, roles, ...rest } = data.user;

//     localStorage.setItem('token', data.token);
//     const userData = {
//       ...rest,
//       role: roles?.[0]?.id,
//       idOrganization: companies?.[0]?.id,
//     };
//     localStorage.setItem('user', JSON.stringify(userData));
//     localStorage.setItem('external_user', rest?.username);
//     localStorage.setItem('external_password', loginForm.password);
//   };

//   const redirectToHome = () => {
//     if (referrer) {
//       history.push(referrer);
//     } else {
//       history.push('/');
//     }
//   };

//   return (
//     <Container>
//       <Row>
//         <Col lg={3} className='text-login'></Col>
//         <Col
//           lg={5}
//           className='simple-box form-login d-flex flex-column justify-content-center align-items-center'
//           style={{ minHeight: '450px' }}>
//           <Form className='w-100'>
//             <Col style={{ position: 'relative' }}>
//               {isOtp && (
//                 <div>
//                   <ArrowDownUp
//                     onClick={() => {
//                       setIsOtp(false);
//                     }}
//                     className='px-2 color-icon'
//                     style={{
//                       position: 'absolute',
//                       cursor: 'pointer',
//                       transform: 'rotate(90deg)',
//                       marginBottom: '15px',
//                       top: '-10px',
//                       left: '-30px',
//                     }}
//                   />
//                 </div>
//               )}
//               {isOtp && <Countdown counterSeconds={300} setState={setIsOtp} />}
//               <h5
//                 style={{
//                   fontSize: '24px',
//                   fontWeight: '600',
//                   lineHeight: '28.8px',
//                   fontFamily: 'Barlow',
//                   color: 'rgba(8, 162, 170, 1)',
//                 }}>
//                 {isOtp ? (
//                   <div className='d-flex justify-content-center align-items-center'>
//                     <p>Inserisci il codice OTP</p>
//                   </div>
//                 ) : (
//                   <p>Accesso</p>
//                 )}
//               </h5>
//             </Col>
//             {!isOtp ? (
//               <>
//                 <Form.Group controlId='formBasicText'>
//                   <Form.Control
//                     type='text'
//                     placeholder='Email'
//                     // placeholder='Username'
//                     value={loginForm.email}
//                     onChange={({ target: { value } }) =>
//                       setLoginForm({
//                         ...loginForm,
//                         email: value,
//                       })
//                     }
//                     // onChange={({ target: { value } }) => setUsername(value)}
//                   />
//                 </Form.Group>
//                 <Form.Group controlId='formBasicPassword'>
//                   <Form.Control
//                     type='password'
//                     placeholder='Password'
//                     value={loginForm.password}
//                     onChange={({ target: { value } }) =>
//                       setLoginForm({
//                         ...loginForm,
//                         password: value,
//                       })
//                     }
//                     // onChange={({ target: { value } }) => setPassword(value)}
//                   />
//                 </Form.Group>
//               </>
//             ) : (
//               <OtpInput
//                 onInputChange={(otp) => {
//                   setLoginForm({
//                     ...loginForm,
//                     otp: otp,
//                   });
//                 }}
//               />
//             )}

//             <Button disabled={isLoading} variant='info' type='submit' onClick={handleLogin}>
//               Submit
//             </Button>
//             {isOtp && (
//               <Row>
//                 <Col>
//                   <p
//                     style={{
//                       fontSize: '18px',
//                       fontWeight: '400',
//                     }}
//                     className='pt-3'>
//                     Non hai ricevuto il codice?{' '}
//                     <Button
//                       variant='link'
//                       disabled={isLoading}
//                       onClick={(e) => {
//                         handleLogin(e, true);
//                       }}
//                       style={{
//                         fontSize: '18px',
//                       }}>
//                       Richiedi un nuovo codice
//                     </Button>
//                   </p>
//                 </Col>
//               </Row>
//             )}

//             {error && (
//               <Form.Text className='text-muted'>
//                 <h5>{error}</h5>
//               </Form.Text>
//             )}
//             {/* <Form.Text className="text-muted">
//                 <a href="/">Forgot Password</a>
//               </Form.Text> */}
//           </Form>
//           <hr className='w-100' />
//           <Row>
//             {!isOtp && (
//               <Col>
//                 <p className='pt-3 login-bottom-paragraph-style'>
//                   Hai dimenticato la password?{' '}
//                   <Button
//                     variant='link'
//                     onClick={() => setShowLogin(false)}
//                     disabled={isLoading}
//                     className='login-bottom-paragraph-style login-bottom-button-style'>
//                     Recupera qui
//                   </Button>
//                 </p>
//               </Col>
//             )}
//           </Row>
//           <div className='trial-text'>
//             {/* <a href="/">Sign up</a> now for a 30 days free trial */}
//             <a href='mailto:info@openeconomics.eu?subject=Externalytics - Demo requested'>
//               Write us for a demo
//             </a>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default LoginForm;

import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { checkLogin, checkLoginOtp } from '@lib/api';
import { useHistory } from 'react-router-dom';
import { validatePassword, validateEmail } from '@lib/helpers/validation';
import LoginFormComponent from './loginComponents/loginFormComponent';
import PasswordRecoveryOtp from './loginComponents/passwordRecoveryOtp';

const LoginForm = ({ referrer, setShowLogin, error, setError, isLoading, setIsLoading }) => {
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    otp: '',
  });
  const [isOtp, setIsOtp] = useState(false);

  const history = useHistory();

  const handleLogin = async (e, getNewCode = false) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    const { email, password, otp } = loginForm;

    if (!validateEmail(email) || !validatePassword(password)) {
      setError('Email or password not valid');
      setIsLoading(false);
      return;
    }

    try {
      if (otp && !getNewCode) {
        await handleOtpLogin(loginForm);
      } else {
        await handleStandardLogin(loginForm);
      }
    } catch (error) {
      setError('An unexpected error occurred');
      console.error('An unexpected error occurred', error);
    }

    setIsLoading(false);
  };

  const handleOtpLogin = async (loginForm) => {
    try {
      const response = await checkLoginOtp(loginForm);

      if (response.status) {
        storeUserData(response.data);
        redirectToHome();
      } else {
        setError(response.message || 'ERROR');
        console.error('codeerror', response.message || 'ERROR');
      }
    } catch (error) {
      setError('An unexpected error occurred during OTP login');
      console.error('An unexpected error occurred during OTP login', error);
    }
  };

  const handleStandardLogin = async (loginForm) => {
    try {
      const response = await checkLogin(loginForm);

      if (response.status && !response.data) {
        setIsOtp(true);
      } else if (response.status && response.data) {
        storeUserData(response.data);
        redirectToHome();
      } else {
        setError(response.message || 'ERROR');
        console.error('codeerror', response.message || 'ERROR');
      }
    } catch (error) {
      setError('An unexpected error occurred during standard login');
      console.error('An unexpected error occurred during standard login', error);
    }
  };

  const storeUserData = (data) => {
    const { companies, roles, ...rest } = data.user;

    localStorage.setItem('token', data.token);
    const userData = {
      ...rest,
      role: roles?.[0]?.id,
      idOrganization: companies?.[0]?.id,
    };
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('external_user', rest?.username);
    localStorage.setItem('external_password', loginForm.password);
  };

  const redirectToHome = () => {
    if (referrer) {
      history.push(referrer);
    } else {
      history.push('/');
    }
  };

  return (
    <Container>
      <Row>
        <Col lg={3} className='text-login'></Col>
        <Col
          lg={5}
          className='simple-box form-login d-flex flex-column justify-content-center align-items-center'
          style={{ minHeight: '450px' }}>
          {!isOtp && (
            <LoginFormComponent
              setShowLogin={setShowLogin}
              loginForm={loginForm}
              setLoginForm={setLoginForm}
              isLoading={isLoading}
              error={error}
              handleLogin={handleLogin}
            />
          )}
          {isOtp && (
            <PasswordRecoveryOtp
              setShowLogin={setShowLogin}
              setIsOtp={setIsOtp}
              loginForm={loginForm}
              setLoginForm={setLoginForm}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              error={error}
              setError={setError}
              handleLogin={handleLogin}
            />
          )}
          <hr className='w-100' />
          <Row>
            {!isOtp && (
              <Col>
                <p className='pt-3 login-bottom-paragraph-style'>
                  Hai dimenticato la password?{' '}
                  <Button
                    variant='link'
                    onClick={() => {
                      setError(null);
                      setShowLogin(false);
                    }}
                    disabled={isLoading}
                    className='login-bottom-paragraph-style login-bottom-button-style'>
                    Recupera qui
                  </Button>
                </p>
              </Col>
            )}
          </Row>
          <div className='trial-text'>
            {/* <a href="/">Sign up</a> now for a 30 days free trial */}
            <a href='mailto:info@openeconomics.eu?subject=Externalytics - Demo requested'>
              Write us for a demo
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
