import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CustomModal = ({
  show,
  handleClose,
  title,
  content,
  modalSize = 'md',
  buttonText = 'Close',
  actionButton = false,
  actionButtonVariant,
  actionButtonText = 'Action',
  handleAction,
}) => {
  return (
    <Modal size={modalSize} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {buttonText}
        </Button>
        {actionButton && (
          <Button variant={actionButtonVariant} onClick={handleAction}>
            {actionButtonText}
          </Button>
        )}
        {/* You can add additional buttons or actions here */}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
