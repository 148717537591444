import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation, Route, Switch } from 'react-router-dom';
import { propTrans } from '@lib/i18n';

// import i18n from '@lib/i18n';
// import Currency from '@lib/currency';

// import { getDatasetRegions } from '@lib/api';

import Submenu from '@components/scenariodashboard/Submenu';
import Dashboard from './dashboard';
import SelectRegion from 'components/scenariodashboard/SelectRegion';
import Gdp from './gdp';
import Employment from './employment';
import Incomes from './incomesNew';
import Taxes from './taxes';
import Investment from './investment';

const ScenarioImpactAnalysis = ({ scenario, regions, variant }) => {
  const { idScenario = null, idVariante = null } = useParams();
  // const [scenario, setScenario] = useState(null);
  const [optionsDatasetRegions, setOptionsDatasetRegions] = useState([]);
  const [filterForRegion, setFilterForRegion] = useState('');

  // const [activeTab, setActiveTab] = useState('general');

  const { pathname } = useLocation();
  // console.log('filterForRegion dash:', filterForRegion);

  // useEffect(() => {
  //   getOEScenario(idScenario).then((retrievedScenario) => {
  //     let tempScenario = retrievedScenario.data;
  //     setScenario(tempScenario);
  //   });
  // }, [idScenario]);

  // useEffect(() => {
  //   if (scenario && scenario.id) {
  //     getDatasetRegions(scenario.datasetId).then((retrievedRegions) => {
  //       setOptionsDatasetRegions(
  //         retrievedRegions.data
  //           .filter((filteredRegion) => filteredRegion.code !== 'ROW')
  //           .map((region) => ({
  //             value: region.code,
  //             label: region.name,
  //             position: region.position,
  //           }))
  //       );
  //     });
  //   }
  // }, [scenario]);

  useEffect(() => {
    if (regions) {
      setOptionsDatasetRegions(
        regions
          .filter((filteredRegion) => filteredRegion.code !== 'ROW')
          .map((region) => ({
            value: region.code,
            label: region.name,
            position: region.position,
          }))
      );
    }
  }, [regions]);

  useEffect(() => {
    if (
      !optionsDatasetRegions.includes((option) => option.value === '') &&
      optionsDatasetRegions.length > 0
    ) {
      optionsDatasetRegions.push({
        value: 'total',
        label: propTrans({ prop: 'A0689' }),
        position: -1,
      });
      setOptionsDatasetRegions(
        optionsDatasetRegions.sort((a, b) => Number(a.position) - Number(b.position))
      );
    }
  }, [optionsDatasetRegions]);

  useEffect(() => {
    const isPathTotal = ['gdp', 'jobs', 'incomes', 'taxes'];
    switch (true) {
      case filterForRegion === '':
        setFilterForRegion('total');
        return;
      case isPathTotal.some((tab) => pathname.includes(tab)):
        return;
      default:
        setFilterForRegion('total');
        break;
    }
  }, [pathname]);
  //OLD
  /*const setScenarioOld = () => {
       getScenario(idScenario)
         .then((retrievedScenario) => {
            setAdditionalInfo(retrievedScenario.additionalInfo)
            setDashboard(retrievedScenario.dashboard)
            setInvestment(retrievedScenario.investment)
            setGdp(retrievedScenario.pil)
            setProduction(retrievedScenario.production)
            setTaxes(retrievedScenario.taxes)
            setIncomes(retrievedScenario.incomes)
            setJobs(retrievedScenario.jobs)
            setGeneral(retrievedScenario.general)
         })

      getScenarioMeta(idScenario)
         .then((retrievedScenarioMeta) => {
            setScenarioMeta(retrievedScenarioMeta);
         }); 
   }*/

  //OLD
  /* useEffect(() => {
       if (idScenario) {
         setScenarioOld()
         i18n.on('languageChanged', setScenarioOld)
         Currency.on('currencyChanged', setScenarioOld)
         return function clenup() {
            i18n.off('languageChanged', setScenarioOld)
            Currency.off('currencyChanged', setScenarioOld)
         }
      } 
      //eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])*/

  if (!idScenario || !idVariante) return <Redirect to='/scenari/newscenariosimpact' />;

  if (!scenario /* || !dashboard */) return null;

  return (
    <div id='dashboard' className='pt-0'>
      <div className='container-fluid'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <Submenu />

          <SelectRegion
            activeTab={pathname}
            optionsDatasetRegions={optionsDatasetRegions}
            filterForRegion={filterForRegion}
            setFilterForRegion={setFilterForRegion}
          />
        </div>
        <Switch>
          <Route exact path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis`}>
            <Dashboard
              scenario={scenario}
              filterForRegion={filterForRegion}
              // navigateCallback={setActiveTab}
              regions={regions}
              variant={variant}
            />
          </Route>
          <Route
            path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis/investment`}>
            <Investment invest={variant?.analysisResults?.IA.expenditures} />
          </Route>
          <Route path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis/gdp`}>
            <Gdp gdp={variant?.analysisResults?.IA.gdp} filterForRegion={filterForRegion} />
          </Route>
          <Route path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis/jobs`}>
            <Employment
              jobs={variant?.analysisResults?.IA.employment}
              filterForRegion={filterForRegion}
            />
          </Route>
          <Route
            path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis/incomes`}>
            <Incomes
              incomes={variant?.analysisResults?.IA.incomes}
              filterForRegion={filterForRegion}
            />
          </Route>
          <Route path={`/scenario/:idScenario/dashboard/variant/:idVariante/impactanalysis/taxes`}>
            <Taxes taxes={variant?.analysisResults?.IA.taxes} filterForRegion={filterForRegion} />
          </Route>

          <Redirect
            from={`/scenario/:idScenario/dashboard/*`}
            to={`/scenario/:idScenario/dashboard/variant/:idVariante`}
          />
        </Switch>
        {/* {activeTab === 'general' && (
          <Dashboard
            scenario={scenario}
            filterForRegion={filterForRegion}
            navigateCallback={setActiveTab}
            regions={regions}
          />
        )}
        {activeTab === 'investment' && (
          <Investment invest={scenario?.analysisResults?.IA.expenditures} />
        )}
        {activeTab === 'gdp' && (
          <Gdp gdp={scenario?.analysisResults?.IA.gdp} filterForRegion={filterForRegion} />
        )}
        {activeTab === 'jobs' && (
          <Employment
            jobs={scenario?.analysisResults?.IA.employment}
            filterForRegion={filterForRegion}
          />
        )}
        {activeTab === 'incomes' && (
          <Incomes
            incomes={scenario?.analysisResults?.IA.incomes}
            filterForRegion={filterForRegion}
          />
        )}
        {activeTab === 'taxes' && (
          <Taxes taxes={scenario?.analysisResults?.IA.taxes} filterForRegion={filterForRegion} />
        )} */}
        {/* {activeTab === 'investment' && <Investment investment={investment} scenario={scenarioMeta} />}
            {activeTab === 'production' && <Production production={production} />}
            
            */}
      </div>
    </div>
  );
};

export default ScenarioImpactAnalysis;
