import React, { useRef } from 'react';
import { Trans } from '@lib/i18n';
import { ListGroup } from 'react-bootstrap';
import ListItem from '@components/scenariodashboard/DashboardListItem';
import WaterfallChartGraph from '@components/scenariodashboard/WaterfallChart';
import useResizeListener from '@lib/hooks/useResizeListener';
import { formatNumberWithLocale } from '@lib/helpers/index';
const DashboardSummarySroi = ({
  syntheticResults: { benefici, costi, bC, vane, tire, sroi, avgSroi },
  currencyToUse,
}) => {
  const waterfallRef = useRef(null);

  const width = useResizeListener(waterfallRef);

  const data = [
    { propertyName: 'Benefici Generati', propertyValue: benefici },
    { propertyName: 'Spesa Totale', propertyValue: costi },
    { propertyName: 'Valore Economico Netto', propertyValue: bC },
  ];
  return (
    <div className='simple-box impact h-100'>
      <div className='row'>
        <div className='col'>
          <h5 className='title-simple-box margin-title-box'>
            <Trans code='A0756' />
          </h5>
        </div>
      </div>
      <div className='d-flex justify-content-around flex-wrap align-items-center h-100'>
        <div className='chartBox impactChartTab pt-0 flex-grow-1'>
          <ListGroup variant='flush' className='pt-0'>
            <ListItem listTitle={'A0754'} listValue={formatNumberWithLocale(benefici)} sign={''} />
            <ListItem listTitle={'A0755'} listValue={formatNumberWithLocale(costi)} sign={''} />
            <ListItem listTitle={'A0900'} listValue={formatNumberWithLocale(vane)} sign={''} />
            <ListItem
              listTitle={'A0901'}
              listValue={formatNumberWithLocale(tire * 100, false, true)}
              sign={tire ? '%' : ''}
            />
            <ListItem
              total={true}
              listTitle={'SROI'}
              // listValue={formatNumberWithLocale(avgSroi, true)}
              listValue={formatNumberWithLocale(sroi, true)}
            />
          </ListGroup>
        </div>

        <div ref={waterfallRef} className='chartBox impactChartTab flex-grow-1 waterfallBox'>
          <WaterfallChartGraph
            data={data}
            valueFontSize={20}
            height={200}
            width={width}
            sign={''}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardSummarySroi;
