import React, { useState, useEffect, memo } from 'react';
import { Trans } from '@lib/i18n';
import { Button, Row, Col } from 'react-bootstrap';
import AnimatedDonutChartDash from '@components/scenariodashboard/AnimatedDonutChartDash';

const DashboardImpact = memo(({ macroImpact: { investment, pil, occupation } }) => {
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);

  return (
    <>
      <Row className='d-flex flex-wrap justify-content-around'>
        <div className='chartBox impactChart'>
          {translations && translations.length && currentLang && investment && (
            <AnimatedDonutChartDash
              chartId='investment'
              data={investment}
              codeLangTag='A0053'
              translations={translations}
              currentLang={currentLang}
              labelOnHover={true}
            />
          )}
          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Trans code='A0047' />
            </h5>
          </div>
        </div>
        <div className='chartBox impactChart'>
          {translations && translations.length && currentLang && pil && (
            <AnimatedDonutChartDash
              chartId='pil'
              data={pil}
              codeLangTag='A0053'
              translations={translations}
              currentLang={currentLang}
              labelOnHover={true}
            />
          )}

          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Trans code='A0263' />
            </h5>
          </div>
        </div>
        <div className='chartBox impactChart'>
          {translations && translations.length && currentLang && occupation && (
            <AnimatedDonutChartDash
              chartId='employment'
              data={occupation}
              codeLangTag='A0054'
              translations={translations}
              currentLang={currentLang}
              labelOnHover={true}
            />
          )}

          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Trans code='A0328' />
            </h5>
          </div>
        </div>
      </Row>
    </>
  );
});

export default DashboardImpact;
