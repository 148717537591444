
import React, { useEffect } from "react";
import * as d3 from 'd3';


let data = [
  { name: "GOMEZ IGUAL, ANNA", value_x: 4.0, value_y: 20.0 },
  { name: "PARKS, ROBYN LASHAE", value_x: 11.0, value_y: 12.0 },
  { name: "CASAS CARRERAS, QUERALT", value_x: 18.0, value_y: 20.0 },
  { name: "ABALDE DIAZ, TAMARA", value_x: 7.0, value_y: 6.0 },
  { name: "TIRERA, MEIYA", value_x: 15.0, value_y: 5.0 },
  { name: "BUCH ROSELL, ROSO", value_x: 5.0, value_y: 15.0 },
  { name: "BROWN, JOY ALEXIS", value_x: 4.0, value_y: 20.0 },
  { name: "RAMAN, JANA GEORGES", value_x: 7.0, value_y: 10.0 },
  { name: "REISINGEROVA, JULIA", value_x: 15.0, value_y: 8.0 }
];

const HEIGHT = 360;
const WIDTH = 565;

const OFFSET_TOP = 20;
const OFFSET_BOTTOM = 30;
const OFFSET_LEFT = 40;
const OFFSET_RIGHT = 20;

function setCanvas() {
  d3.selectAll("#container > svg").remove();
  let svg = d3
    .select("#container")
    .append("svg")
    .style("background-color", "#FFFFFF")
    .style("color", "#000000") //With this we've got the color of the axis too
    .attr("height", HEIGHT)
    .attr("width", WIDTH);

  return svg;
}

function setScales(data) {
  let xRange = [OFFSET_LEFT, WIDTH - OFFSET_RIGHT];
  let yRange = [OFFSET_TOP, HEIGHT - OFFSET_BOTTOM]; // flip order because y-axis origin is upper LEFT

  let xScale = d3
    .scaleLinear()
    .domain([
      d3.min(data, d => parseFloat(d.value_x)),
      d3.max(data, d => parseFloat(d.value_x))
    ])
    .range(xRange);

  let yScale = d3
    .scaleLinear()
    .domain([
      d3.max(data, d => parseFloat(d.value_y)),
      d3.min(data, d => parseFloat(d.value_y))
    ])
    .range(yRange);

  return { xScale: xScale, yScale: yScale, xRange: xRange, yRange: yRange };
}

function setAxesToCanvas(canvas, scales) {
  let y2 = 0;
  let y1 = HEIGHT - OFFSET_BOTTOM;
  console.log("y1: " + y1 + " y2: " + y2);

  //Add scale to X axis
  let xAxis = d3.axisBottom().scale(scales.xScale);

  //Add scale to Y axis
  let yAxis = d3.axisLeft().scale(scales.yScale);

  //Add x-axis to the canvas
  canvas
    .append("g")
    .attr("transform", "translate(0, " + y1 + ")")
    .call(xAxis);

  //Add y-axis to the canvas
  canvas
    .append("g")
    .attr("transform", "translate(" + OFFSET_LEFT + "," + y2 + ")")
    .call(yAxis);
}

function setPointsToCanvas(canvas, data, scales) {
  canvas
    .selectAll("circle")
    .data(data)
    .enter()
    .append("circle")
    .attr("class", "dot")
    .attr("r", 5.5) //Radius size, could map to another dimension
    .attr("cx", function(d) {
      return scales.xScale(parseFloat(d.value_x));
    }) //x position
    .attr("cy", function(d) {
      return scales.yScale(parseFloat(d.value_y));
    }) //y position
    .style("fill", "#000000");
}

export default function ScatterChart1() {
  useEffect(() => {
    let canvas = setCanvas();
    let scales = setScales(data);
    setAxesToCanvas(canvas, scales);
    setPointsToCanvas(canvas, data, scales);
  }, []);
  return (
    <div className="ScatterChart1 text-center">
      <div id="container" />
    </div>
  );
}

