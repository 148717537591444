import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { PickList } from 'primereact/picklist';

export default function FilterDemo({
  targets,
  id,
  allFilters = ['name'],
  sourceFilterPlaceholder = 'Search by name',
  targetFilterPlaceholder = 'Search by name',
  sources,
  setElements,
  sourceHeader,
  targetHeader,
}) {
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [filter, setFilter] = useState(allFilters[0]);
  // useEffect(() => {
  //   ProductService.getProductsSmall().then((data) => setSource(data));
  // }, []);

  useEffect(() => {
    setTarget(targets);
  }, []);

  useEffect(() => {
    if (targets) {
      setSource(sources());
    }
  }, [targets]);

  const onChange = (event) => {
    // setSource(event.source);
    setElements(event.target);
  };

  const itemTemplate = (item) => {
    return (
      <div className='d-flex flex-wrap p-2 align-items-center gap-3'>
        <div className='flex-1 d-flex flex-column gap-2'>
          <span className='font-bold'>{item.name}</span>
          {/* <div className='d-flex align-items-center gap-2'>
            <i className='pi pi-tag text-sm'></i>
            <span>{item.sectorCode}</span>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div className='card' style={{ border: '0px', boxShadow: 'none' }}>
      <div className='d-flex mr-3'>
        <span className='mr-3'>Search by: </span>
        {allFilters.map((filterArr) => {
          return (
            <Form.Check
              type='radio'
              key={`${filterArr}_${id}`}
              id={`${filterArr}_${id}`}
              name={`${filterArr}_${id}`}
              label={filterArr.toUpperCase()}
              className='specificyColor mb-3 mr-3'
              onChange={(e) => setFilter(filterArr)}
              defaultChecked={filter === filterArr || true}
            />
          );
        })}
      </div>

      <PickList
        source={source}
        target={targets}
        onChange={onChange}
        itemTemplate={itemTemplate}
        filter
        filterBy={filter}
        breakpoint='1400px'
        sourceHeader={sourceHeader}
        targetHeader={targetHeader}
        sourceStyle={{ height: '30rem' }}
        targetStyle={{ height: '30rem' }}
        sourceFilterPlaceholder={sourceFilterPlaceholder}
        targetFilterPlaceholder={targetFilterPlaceholder}
      />
    </div>
  );
}
