import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Container } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { LayerStepCompact } from 'pages/index';

const steps = [
  { key: 'general', isDone: false, position: 1 },
  { key: 'esg', isDone: false, position: 2 },
  { key: 'dataset', isDone: false, position: 3 },
  { key: 'kpi', isDone: false, position: 4 },
  { key: 'execute', isDone: false, position: 5 },
  { key: 'financing', isDone: false, position: 6 },
  { key: 'parameters', isDone: false, position: 7 },
  { key: 'esgOld', isDone: false, position: 8 },
  { key: 'dnsh', isDone: false, position: 9 },
];

const StepBuild = ({ scen, setScen, originScenario, isLoading, setVariantsRefresh }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const subStepName = urlParams.get('sub') ?? 'general';
  const [scenario, setScenario] = useState();
  const [currentStep, setCurrentStep] = useState('');
  const [isGeneralFilled, setIsGeneralFilled] = useState(false);
  const { idVariante = null } = useParams();
  const history = useHistory();
  const changeStep = (step) => {
    history.push(
      scenario.id
        ? `/scenariowizard/${scenario.scenarioId}/${idVariante}/?step=build&sub=${step}`
        : '#'
    );
  };

  useEffect(() => {
    setScenario(scen);
  }, [scen]);

  useEffect(() => {
    if (scenario) {
      const {
        address,
        createdAt,
        investment,
        socialDiscountRate,
        updatedAt,
        verified,

        analyses,
        //class, not allowed as name variable
        dataset,
        datasetId,
        ...restDataset
      } = scenario;

      setIsGeneralFilled(
        Object.values(restDataset).every((prop) => prop !== '' && prop != null) &&
          analyses?.length > 0
          ? true
          : false
      );
    }
  }, [scenario]);

  useEffect(() => {
    setCurrentStep(
      steps
        .filter((step) => step.key === subStepName)
        .map((step) => step.key)
        .pop()
    );
  }, [subStepName]);

  if (!scenario || !idVariante) return null;
  return (
    <>
      <Tabs
        activeKey={currentStep}
        onSelect={(k) => {
          setCurrentStep(k);
          changeStep(k);
        }}
        className='container-fluid sub-folder pr-0'
        justify>
        <Tab
          eventKey='general'
          title={
            <Row className='align-items-center flex-column'>
              <Col className='tabname'>
                {/* {steps.filter((step) => step.key === 'general').map((step) => step.position)}
                .&nbsp; */}
                <Trans code='A0542' />
              </Col>{' '}
            </Row>
          }></Tab>
        {scen &&
        scen.analyses &&
        scen.analyses.length > 0 &&
        scen.analyses.find((an) => an.type === 'ESG' && an.status !== 'Deleted') ? (
          <Tab
            eventKey='esg'
            disabled={scenario?.investment > 0 ? false : true}
            title={
              <Row className='align-items-center flex-column'>
                <Col className='tabname'>
                  {/* {steps.filter((step) => step.key === 'esg').map((step) => step.position)}
                  .&nbsp; */}
                  {/* <Trans code='A0543' /> */}
                  ESG
                </Col>{' '}
              </Row>
            }></Tab>
        ) : null}

        {scen &&
        scen.analyses &&
        scen.analyses.length > 0 &&
        (scen.analyses.some((an) => an.type === 'IA' && an.status !== 'Deleted') ||
          scen.analyses.some((an) => an.type === 'SROI' && an.status !== 'Deleted')) ? (
          <Tab
            eventKey='dataset'
            disabled={isGeneralFilled ? false : true}
            title={
              <Row className='align-items-center flex-column'>
                <Col className='tabname'>
                  {/* {steps.filter((step) => step.key === 'dataset').map((step) => step.position)}
                  .&nbsp; */}
                  <Trans code='A0543' />
                </Col>{' '}
              </Row>
            }></Tab>
        ) : null}
        {scen &&
        scen.analyses &&
        scen.analyses.length > 0 &&
        scen.analyses.find((an) => an.type === 'SROI' && an.status !== 'Deleted') ? (
          <Tab
            eventKey='kpi'
            disabled={isGeneralFilled ? false : true}
            title={
              <Row className='align-items-center flex-column'>
                <Col className='tabname'>
                  {/* {steps.filter((step) => step.key === 'kpi').map((step) => step.position)}
                  .&nbsp; */}
                  <Trans code='A0675' />
                </Col>{' '}
              </Row>
            }></Tab>
        ) : null}
        <Tab
          eventKey='execute'
          disabled={isGeneralFilled ? false : true}
          title={
            <Row className='align-items-center flex-column'>
              <Col className='tabname'>
                {/* {steps
                  .filter((step) => step.key === 'execute')
                  .map((step) =>
                    scen &&
                    scen.analyses &&
                    scen.analyses.length > 0 &&
                    scen.analyses.find((an) => an.type === 'SROI' && an.status !== 'Deleted')
                      ? step.position
                      : step.position - 1
                  )}
                .&nbsp; */}
                {/* <Trans code='A0543' /> */}
                Execute
              </Col>{' '}
            </Row>
          }></Tab>
        {false && (
          <>
            <Tab
              eventKey='financing'
              title={
                <Row
                  className='align-items-center flex-column'
                  onClick={() =>
                    history.push(
                      scenario.id ? `/scenariowizard/${scenario.id}?step=build&sub=financing` : '#'
                    )
                  }>
                  <Col className='tabname'>
                    {/* {steps.filter((step) => step.key === 'financing').map((step) => step.position)}
                    .&nbsp; */}
                    <Trans code='A0544' />
                  </Col>{' '}
                </Row>
              }></Tab>
            <Tab
              eventKey='parameters'
              title={
                <Row
                  className='align-items-center flex-column'
                  onClick={() =>
                    history.push(
                      scenario.id ? `/scenariowizard/${scenario.id}?step=build&sub=parameters` : '#'
                    )
                  }>
                  <Col className='tabname'>
                    {/* {steps.filter((step) => step.key === 'parameters').map((step) => step.position)}
                    .&nbsp; */}
                    <Trans code='A0545' />
                  </Col>{' '}
                </Row>
              }></Tab>
            <Tab
              eventKey='esg'
              title={
                <Row
                  className='align-items-center flex-column'
                  onClick={() =>
                    history.push(
                      scenario.id ? `/scenariowizard/${scenario.id}?step=build&sub=esg` : '#'
                    )
                  }>
                  <Col className='tabname text-uppercase'>
                    {/* {steps.filter((step) => step.key === 'esg').map((step) => step.position)}
                    .&nbsp; */}
                    <Trans code='A0546' />
                  </Col>{' '}
                </Row>
              }></Tab>
            <Tab
              eventKey='dnsh'
              title={
                <Row
                  className='align-items-center flex-column'
                  onClick={() =>
                    history.push(
                      scenario.id ? `/scenariowizard/${scenario.id}?step=build&sub=dnsh` : '#'
                    )
                  }>
                  <Col className='tabname text-uppercase'>
                    {/* {steps.filter((step) => step.key === 'dnsh').map((step) => step.position)}
                    .&nbsp; */}
                    <Trans code='A0547' />
                  </Col>{' '}
                </Row>
              }></Tab>
          </>
        )}
      </Tabs>
      {scenario && idVariante && (
        <LayerStepCompact
          scen={scenario}
          setScen={setScen}
          originScenario={originScenario}
          isLoading={isLoading}
          setVariantsRefresh={setVariantsRefresh}
        />
      )}
    </>
  );
};
export default StepBuild;
