import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Ico42 } from "@icons/new";
import { rounder} from '@lib/helpers';
import { Trans } from '@lib/i18n';

const DashboardKpi = ({ syntheticResults: { kpis } }) => {
   
   return (
      <Card className="cards-utente d-none d-lg-block">
         <Card.Body>
            <Ico42 className="logo-card ico-box-blue" />
            <Card.Title><Trans code="C00501"/></Card.Title>
            
            <ListGroup variant="flush">
               {kpis.map((kpi, i) => {
                  return <ListGroup.Item key={'kpi' + i} className="d-flex justify-content-between align-items-center px-3">
                     <strong><Trans code={kpi.propertyName} replacementText={kpi.propertyName}/></strong>
                     <span className='text-right'>{kpi.propertyName === 'Redistribution' ? (Number(kpi.propertyValue) === 0 ? 'N.S.' : rounder(kpi.propertyValue * 100) + '%') : rounder(kpi.propertyValue)}</span>
                  </ListGroup.Item>
               })}
            </ListGroup>
         </Card.Body>
      </Card>
   );
}

export default DashboardKpi;
