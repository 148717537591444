import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import VariantButton from './dashboardSroiTotalsButtons';
import { Trans } from '@lib/i18n/index';
const DashboardSroiTotalsSelector = ({ dataVariant, variant, setVariant, setDataKpis }) => {
  return (
    <>
      <ButtonToolbar
        aria-label='Submenu dashboard'
        className='row d-flex justify-content-around align-items-center submenu-dashboard btn-toolbar mt-0 mb-0'>
        <VariantButton
          id={dataVariant.id}
          isSelected={variant.id === dataVariant.id}
          label={dataVariant.name}
          onClick={() => {
            setVariant(dataVariant);
            setDataKpis(dataVariant.analysisResults.SROI.kpis);
          }}
        />
        <VariantButton
          id={dataVariant.totals.name}
          isSelected={!variant.id}
          label={dataVariant.totals.name}
          onClick={() => {
            setVariant(dataVariant.totals);
            setDataKpis(dataVariant?.totals?.analysisResults?.SROI?.kpis);
          }}
        />
      </ButtonToolbar>
      {/* <div className='d-flex justify-content-around'>
        <VariantButton
            id={dataVariant.id}
            isSelected={variant.id === dataVariant.id}
            label={dataVariant.name}
            onClick={() => {
              setVariant(dataVariant);
              setDataKpis(dataVariant.analysisResults.SROI.kpis);
            }}
          />
        <VariantButton
            id={dataVariant.totals.name}
            isSelected={!variant.id}
            label={dataVariant.totals.name}
            onClick={() => {
              setVariant(dataVariant.totals);
              setDataKpis(dataVariant?.totals?.analysisResults?.SROI?.kpis);
            }}
          />
      </div> */}

      <div className='my-5'>
        <h1 style={{ fontSize: '2rem' }}>
          <Trans code='A0906' />: <span style={{ fontWeight: 'bold' }}>{variant.name}</span>
        </h1>
      </div>
    </>
  );
};

export default DashboardSroiTotalsSelector;
