import React, { Component } from "react"

class Ico92 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-92" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(24.8,39.5)">
          <path d="M60.781 50.1854V46.214C60.781 44.942 59.7261 43.8871 58.454 43.8871H54.1724V18.1971H60.781L35.091 0.418858C34.2843 -0.139619 33.2294 -0.139619 32.4538 0.418858L6.73276 18.1971H13.3414V43.856H9.05975C7.78766 43.856 6.73276 44.9109 6.73276 46.183V50.1544H2.32699C1.0549 50.1544 0 51.2093 0 52.4814V54.343C0 55.6151 1.0549 56.67 2.32699 56.67H65.1868C66.4589 56.67 67.5138 55.6151 67.5138 54.343V52.4814C67.5138 51.2093 66.4589 50.1544 65.1868 50.1544H60.781V50.1854ZM47.6568 43.856H42.7236V18.1971H47.6568V43.856ZM36.208 18.1971V43.856H31.2748V18.1971H36.208ZM19.857 18.1971H24.7902V43.856H19.857V18.1971Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico92