import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Trans, ApiTrans } from '@lib/i18n';
import { rounder } from '@lib/helpers/index';

const legendStyles = {
  bullet: {
    display: 'inline-block',
    width: '1em',
    height: '1em',
    borderRadius: '0.5em',
    top: '0.15em',
    position: 'relative',
  },
  label: {
    display: 'inline-block',
    width: '50%',
    lineHeight: '2.5em',
  },
};

const LegendBullet = ({ label, color, scope, className }) => (
  <span className={className}>
    <i style={{ ...legendStyles.bullet, backgroundColor: color }} />{' '}
    <ApiTrans scope={scope} prop={label} />
  </span>
);

const Legend = ({ data, scope, colors, className, values, allData }) => {
  console.log('allData', allData);

  return (
    <>
      {data &&
        data.map((d, index) => (
          <Row className='justify-content-between align-items-center' key={index}>
            <Col style={{ flexBasis: 'min-content' }}>
              <LegendBullet
                className={`span-legend ${className}`}
                label={d}
                color={colors[index]}
                scope={scope}
              />
            </Col>
            {values ? (
              <>
                <Col className='text-right'>{values[index]}</Col>
              </>
            ) : null}
          </Row>
        ))}

      <table>
        <tbody>
          {allData &&
            Object.keys(allData)
              .filter((k) => k === 'total')
              .map((k) => {
                const value = allData[k];
                return (
                  <tr
                    key={k}
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      display: 'inline-table',
                      marginBottom: '0',
                    }}>
                    <th className='va-desc udm text-right font-weight-normal'>
                      <Trans code='A0053' />
                    </th>
                    <th className='value-label total-left'>
                      <ApiTrans scope='gdp' prop={k} />
                    </th>
                    <th className='total-right'>{rounder(value)}</th>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </>
  );
};

export default Legend;
