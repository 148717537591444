import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col, Tab, Tabs } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Trans, propTrans } from '@lib/i18n';
import { IcoCanc, IcoEdit } from '@icons';
import { putExpenseItem, deleteExpenseItem } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import AssociatedScenarioTable from '../associatedScenario/associatedScenarioTable';

const ActionBtnRenderer = ({ data, setRefresh, optionsTypes }) => {
  const id = data.id;
  const [expenseItem, setExpenseItem] = useState({});
  const [selectedExpenseType, setSelectedExpenseType] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [associatedScenarios, setAssociatedScenarios] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  //console.log('selected expenseItem', expenseItem);
  console.log('expenseItem', expenseItem);

  useEffect(() => {
    setExpenseItem(data);
    setAssociatedScenarios(data?.associatedScenarios?.length > 0 ? true : false);

    setSelectedExpenseType(
      optionsTypes.filter((optionType) => optionType.value === data.expenseType)
    );
  }, [data, optionsTypes]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const onChange = (e, setSelect) => {
    if (setSelect) {
      setSelect(e ?? []);

      setExpenseItem(() => ({
        ...expenseItem,
        expenseType: e.value ?? [],
      }));
    } else {
      setExpenseItem(() => ({
        ...expenseItem,
        [e.target.name]:
          e.target.type === 'number'
            ? e.target.value
              ? Number(e.target.value)
              : ''
            : e.target.value,
      }));
    }
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    //quando ci sarà solo il langTag e codice ripulito, eliminare destrutturazione
    const { langTag, langTagDesc, listId, ...restExpenseItem } = expenseItem;
    const isFilled = Object.values(restExpenseItem).every((prop) => prop !== '' && prop != null)
      ? true
      : false;
    console.log('isFilled', isFilled);

    if (!isFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }

    //quando ci sarà solo il langTag e codice ripulito, eliminare
    const clearObj = {
      id: expenseItem.id,
      name: expenseItem.name,
      langTag: expenseItem.langTag,
      expenseType: expenseItem.expenseType,
      position: expenseItem.position,
      langTagDesc: expenseItem.langTagDesc,
    };
    handleStatusBar('elaborating', false, false);
    const editResult = await putExpenseItem(clearObj, id);

    if (editResult.status) {
      handleStatusBar('success', <Trans code='A0524' />, true);

      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', editResult.message ?? <Trans code='A0521' />, false);
    }
  };

  const handleDelete = async () => {
    const deleteResult = await deleteExpenseItem(id);
    //console.log('deleteResult', deleteResult);

    if (deleteResult.status) {
      handleStatusBar('success', <Trans code='A0526' />, true);

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', deleteResult.message ?? <Trans code='A0527' />, false);
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00055' />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00056' />
    </Tooltip>
  );

  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button
          disabled={associatedScenarios}
          variant='link'
          className='mr-0'
          onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      <Modal
        size={activeKey === 'general' ? 'md' : 'xl'}
        id='expenseitems-modal'
        show={showModalEdit}
        onHide={() => handleCloseModal(setShowModalEdit)}
        centered>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className='px-2'>
            <Trans code='A0662' />
          </Modal.Title>
        </Modal.Header>
        <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} justify>
          {/* <Tab eventKey='general' title={associatedScenarios ? propTrans({ prop: 'A0041' }) : null}> */}
          <Tab eventKey='general' title={propTrans({ prop: 'A0041' })}>
            <Modal.Body>
              <Form onSubmit={handleEdit} id='form-admin-expense-items'>
                <Row className='mb-3'>
                  <Form.Group className='px-4 col-12' controlId='name'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0636' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Control
                      className='custom-disabled'
                      disabled={associatedScenarios}
                      type='text'
                      name='name'
                      value={expenseItem.name}
                      onChange={(e) => onChange(e)}
                    />
                    <Link
                      to={`/administration/texts/${expenseItem.langTagName}`}
                      className='link-langtag'>
                      <Trans code='A0473' replacementText='Manage translation' />
                    </Link>
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} className='px-4' controlId='expenseType'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0660' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Select
                      isDisabled={associatedScenarios}
                      name='expenseType'
                      value={selectedExpenseType}
                      options={optionsTypes}
                      onChange={(e) => onChange(e, setSelectedExpenseType)}
                    />
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} className='px-4' controlId='position'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0661' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Control
                      className='custom-disabled'
                      disabled={associatedScenarios}
                      type='number'
                      name='position'
                      step={1}
                      value={expenseItem.position}
                      onChange={(e) => onChange(e)}
                    />
                  </Form.Group>
                </Row>
              </Form>
              {sbShow}

              <span className='px-3'>
                <span className='required-field'>*</span>&nbsp;
                <Trans code='A0503' />
              </span>
            </Modal.Body>
            <Modal.Footer>
              {/* <Row className='px-3'>
                <span>
                  <Trans code='A0663' />
                </span>
              </Row> */}
              <Row className='px-3 text-right'>
                <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                  <Trans code='F0057' />
                </Button>
                <Button variant='primary' onClick={(e) => handleEdit(e)} className='ml-3'>
                  <Trans code='F00046' />
                </Button>
              </Row>
            </Modal.Footer>
          </Tab>
          {/* associatedScenarios && (
            <Tab eventKey='scenariByClass' title={propTrans({ prop: 'A0468' })}>
              <Modal.Body className='mb-4 px-5'>
                {<AssociatedScenarioTable associatedScenarios={data?.associatedScenarios} />}
              </Modal.Body>
            </Tab>
          ) */}
          <Tab
            disabled={!associatedScenarios}
            eventKey='associatedScenarios'
            title={propTrans({ prop: 'A0468' })}>
            <Modal.Body className='mb-4 px-5'>
              {<AssociatedScenarioTable associatedScenarios={data.associatedScenarios} />}
            </Modal.Body>
          </Tab>
        </Tabs>
      </Modal>
      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0664' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRenderer;
