import React, { Component } from "react"

class Ico33 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-33" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(28.75, 37.88)">
          <path d="M1.80017 15.3L30.2002 30.7334C30.4464 30.8647 30.7211 30.9334 31.0002 30.9334C31.2792 30.9334 31.554 30.8647 31.8002 30.7334L60.2002 15.3C60.4593 15.1721 60.6748 14.9704 60.8197 14.7204C60.9646 14.4703 61.0324 14.183 61.0146 13.8946C60.9968 13.6062 60.8942 13.3294 60.7197 13.0991C60.5451 12.8688 60.3064 12.6951 60.0335 12.6L31.6335 1.00002C31.2277 0.833326 30.7726 0.833326 30.3668 1.00002L1.96683 12.6C1.69394 12.6951 1.45523 12.8688 1.28068 13.0991C1.10612 13.3294 1.0035 13.6062 0.985697 13.8946C0.967892 14.183 1.0357 14.4703 1.1806 14.7204C1.3255 14.9704 1.54105 15.1721 1.80017 15.3Z" className="ico" />
          <path d="M60.0335 26.5336L53.9335 23.9336L31.8001 35.9669C31.5539 36.0982 31.2792 36.1669 31.0001 36.1669C30.7211 36.1669 30.4463 36.0982 30.2001 35.9669L8.0668 23.9336L1.9668 26.5336C1.70846 26.6692 1.49212 26.8729 1.34119 27.1226C1.19026 27.3723 1.11047 27.6585 1.11047 27.9503C1.11047 28.242 1.19026 28.5282 1.34119 28.7779C1.49212 29.0276 1.70846 29.2313 1.9668 29.3669L30.3668 45.7003C30.6076 45.8476 30.8845 45.9255 31.1668 45.9255C31.4491 45.9255 31.726 45.8476 31.9668 45.7003L60.3668 29.3669C60.6127 29.2033 60.8085 28.9747 60.9325 28.7066C61.0565 28.4385 61.1039 28.1414 61.0694 27.848C61.0349 27.5546 60.9198 27.2766 60.737 27.0446C60.5541 26.8126 60.3106 26.6357 60.0335 26.5336Z" className="ico" />
          <path d="M60.0335 41.3331L54.8335 39.0664L31.8001 51.5997C31.5539 51.7311 31.2792 51.7997 31.0001 51.7997C30.7211 51.7997 30.4464 51.7311 30.2001 51.5997L7.16681 39.0664L1.96681 41.3331C1.70115 41.4708 1.47844 41.6789 1.32299 41.9346C1.16754 42.1903 1.08533 42.4838 1.08533 42.7831C1.08533 43.0823 1.16754 43.3758 1.32299 43.6315C1.47844 43.8872 1.70115 44.0953 1.96681 44.2331L30.3668 60.8997C30.613 61.031 30.8878 61.0997 31.1668 61.0997C31.4459 61.0997 31.7206 61.031 31.9668 60.8997L60.3668 44.2331C60.6201 44.0671 60.8223 43.8341 60.9508 43.5598C61.0793 43.2856 61.129 42.9811 61.0944 42.6803C61.0599 42.3794 60.9423 42.0941 60.755 41.8562C60.5676 41.6183 60.3178 41.4372 60.0335 41.3331Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico33