import { useState, useEffect } from 'react';

const useResizeListener = (elementRef) => {
  const [width, setWidth] = useState(0);

  const handleResize = () => {
    let elapsedTime = 0;

    if (!elementRef.current) return;
    const { clientWidth } = elementRef.current;

    if (clientWidth === 0) {
      const interval = setInterval(() => {
        elapsedTime += 100; // Increase elapsed time by 100 milliseconds
        if (elapsedTime >= 10000) {
          // 10 seconds (10000 milliseconds)
          clearInterval(interval); // Abort the function
          return;
        }

        if (elementRef.current) {
          const { clientWidth: newClientWidth } = elementRef.current;

          if (newClientWidth !== 0) {
            clearInterval(interval); // Stop the interval if newClientWidth is greater than 0
            setWidth(newClientWidth);
          }
        }
      }, 100);
    } else {
      setWidth(clientWidth);
    }
  };

  useEffect(() => {
    const resizeListener = () => handleResize();
    window.addEventListener('resize', resizeListener);

    handleResize(); // Call the function initially to get the initial width

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [elementRef]);

  useEffect(() => {
    if (elementRef.current) {
      handleResize(); // Call the function initially to get the initial width
    }
  }, [elementRef]);

  return width;
};

export default useResizeListener;
