import { Form } from 'react-bootstrap';
import Select from 'react-select';
import React from 'react';
import { Trans } from '@lib/i18n/index';
import SelectorComponentWrapper from './selectorComponentWrapper';

// const SelectorComponent = ({
//   selectedYear,
//   setSelectedYear,
//   years,
//   selectedKpis,
//   setSelectedKpis,
//   kpiList,
// }) => {
//   return (
//     <div className='container-fluid'>
//       <Row className='d-flex flex-wrap my-4' style={{ gap: '20px' }}>
//         <Col className='flex-grow-1'>
//           <div>
//             <h3 className='title-simple-box margin-title-box'>Seleziona anno</h3>
//             <Select
//               name='year'
//               value={selectedYear}
//               options={years}
//               onChange={(e) => setSelectedYear(e)}
//             />
//           </div>
//         </Col>
//         <Col className='flex-grow-1'>
//           <div>
//             <h3 className='title-simple-box margin-title-box'>Seleziona KPIs</h3>
//             <Select
//               id='multiselect-kpismonitoring'
//               value={selectedKpis}
//               closeMenuOnSelect={false}
//               options={kpiList}
//               isMulti
//               isClearable
//               onChange={(e) => setSelectedKpis(e)}
//             />
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default SelectorComponent;

const SelectorComponent = ({
  selectedYear,
  setSelectedYear,
  years,
  selectedKpis,
  setSelectedKpis,
  kpiList,
  setData,
}) => {
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedKpis((prevSelectedKpis) => {
      if (checked) {
        return [...prevSelectedKpis, +id]; // Add KPI to the state
      } else {
        if (prevSelectedKpis.length === 1) {
          prevSelectedKpis.filter((kpiId) => kpiId !== +id);
          setData(null);
        }
        return prevSelectedKpis.filter((kpiId) => kpiId !== +id); // Remove KPI from the state
      }
    });
  };
  console.log('selectedKpis', selectedKpis);
  return (
    <div className='d-flex flex-wrap mb-4 w-100 container-fluid' style={{ gap: '20px' }}>
      <SelectorComponentWrapper title={<Trans code='A0860' />}>
        <Select
          name='year'
          value={selectedYear}
          options={years}
          onChange={(e) => setSelectedYear(e)}
        />
      </SelectorComponentWrapper>
      <SelectorComponentWrapper title={<Trans code='A0861' />}>
        <div className='d-flex flex-wrap' style={{ gap: '5px 20px' }}>
          {kpiList.map((kpi) => (
            <Form.Check
              type='checkbox'
              key={kpi.value}
              id={`${kpi.value}`}
              name={`${kpi.label}`}
              label={kpi.label}
              onChange={handleCheckboxChange}
              checked={selectedKpis.includes(kpi.value)}
              className='mb-3'
              disabled={!selectedYear}
            />
          ))}
        </div>
      </SelectorComponentWrapper>
    </div>
  );
};

export default SelectorComponent;
