import React, { useEffect, useState } from 'react';
import {
  getOEScenario,
  getScenarioMonitoring,
  getScenarioMonitoringKpisList,
  getScenarioMonitoringSroi,
  getScenarioMonitoringYears,
} from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import ScenarioHeaderCompact from 'components/scenariodashboard/ScenarioHeaderCompact';
import { useParams } from 'react-router-dom';
import StatusBar from 'components/wizard/StatusBar';
import { Row, Col } from 'react-bootstrap';
import MonitoringSection from 'components/monitoring/sroiMonitoring';
import { Trans } from '@lib/i18n/index';
import IndicatorsMonitoring from 'components/monitoring/indicatorsMonitoring';
import EmptyDataDisplay from 'components/EmptyDataDisplay';
import SelectorComponent from 'components/monitoring/selectorComponent';
import Select from 'react-select';
const Monitoring = () => {
  const [scenario, setScenario] = useState(null);
  const [monitoringData, setMonitoringData] = useState(null);
  const [sroiData, setSroiData] = useState(null);
  const [kpiList, setKpiList] = useState([]);
  const [selectedKpis, setSelectedKpis] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [sbShow, setSbShow] = useState(null);
  const [number, setNumber] = useState(0);
  const { idScenario = null } = useParams();

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  const transformScenarioMonitoringYears = (years, setState) => {
    setState(
      years.map((year) => {
        return {
          value: year,
          label: year,
        };
      })
    );
  };
  const transformScenarioMonitoringKpis = (data, setState) => {
    setState(
      data.map((el) => {
        return {
          value: el.id,
          label: el.name,
        };
      })
    );
  };

  useEffect(() => {
    if (idScenario) {
      fetchData(getOEScenario, setScenario, null, [idScenario], handleStatusBar, null);

      fetchData(
        getScenarioMonitoringYears,
        setYears,
        transformScenarioMonitoringYears,
        [idScenario],
        handleStatusBar,
        null
      );
      fetchData(getScenarioMonitoringSroi, setSroiData, null, [idScenario], handleStatusBar, null);
      fetchData(
        getScenarioMonitoringKpisList,
        setKpiList,
        transformScenarioMonitoringKpis,
        [idScenario],
        handleStatusBar,
        null
      );
    }
  }, [idScenario]);
  useEffect(() => {
    if (!selectedKpis.length || !selectedYear) return;
    async function orchestrateApiCalls() {
      const promises = selectedKpis.map((el) =>
        // if multiselect
        // getScenarioMonitoring(idScenario, selectedYear.value, el.value)
        getScenarioMonitoring(idScenario, selectedYear.value, el)
      );
      try {
        const results = await Promise.all(promises);
        const aggregatedKpis = results.reduce((acc, result) => {
          const kpis = result.data.kpis;
          return { ...acc, ...kpis };
        }, {});

        setMonitoringData({ kpis: aggregatedKpis });
      } catch (error) {
        console.error('Error in orchestrating API calls:', error);
      }
    }

    orchestrateApiCalls();
  }, [selectedKpis, selectedYear, idScenario]);

  return (
    <div id='monitoring' className='megaContent'>
      {scenario && <ScenarioHeaderCompact scenario={scenario} />}
      {!!years.length && (
        <SelectorComponent
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          years={years}
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          kpiList={kpiList}
          setData={setMonitoringData}
        />
      )}
      <div className='container-fluid'>
        {monitoringData === null && sroiData?.sroi?.length < 2 && (
          <EmptyDataDisplay isCard transCode='A0811' />
        )}
        {monitoringData &&
          Object.keys(monitoringData?.kpis)
            .map((key) => monitoringData.kpis[key])
            .map((el, i) => (
              <IndicatorsMonitoring
                key={`${Object.keys(monitoringData?.kpis)[i]}_main`}
                title={Object.keys(monitoringData?.kpis)[i].replace(
                  /[^a-zA-Z0-9àáèéìíòóúùÀÁÈÉÌÍÒÓÙÚ]/g,
                  ''
                )}
                data={el}
              />
            ))}
        {sroiData?.sroi.length > 1 && (
          <MonitoringSection title={<Trans code='SROI' />} data={sroiData?.sroi} />
        )}

        {sbShow}
      </div>
    </div>
  );
};

export default Monitoring;
