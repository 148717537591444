import React, { useState,/* useRef, */useEffect } from "react";
import { /* Container, */ Row, Col, Form, Button, Card, CardDeck, /*Alert,Spinner, ListGroup, */ InputGroup } from 'react-bootstrap';
// import { /* useHistory, */ useLocation, /* Link */ } from "react-router-dom";
//import Select from 'react-select'
import { Trans } from '@lib/i18n'
import { /* getAdminOrganization,getAdminRegion,getAdminSam,getAdminClasses, */getBaseUrl,getDefaultHeaders } from '@lib/api'
import StatusBar from "components/wizard/StatusBar";
//import { Ico35,Ico36 } from "@icons/new";

const StepExecution = ({props,scen}) => {

  const scenarioData=(scen)
  //const [valoriForm, setValoriForm] = useState(scenarioData.vettoriSpesa)
  // const location = useLocation();
//   const { scenario = null } = location?.state || {}
//   const form = useRef(null)

   const [isSaved, setIsSaved] = useState(false)
   const [isElaborating, setIsElaborating] = useState(false)
   const [error, setError] = useState(null)
//   const history = useHistory()
  const [idScenario, setIdScenario] = useState(null)
  

  const[sbShowSuccess, setSbShowSuccess] = useState(false);
  const[sbShowError, setSbShowError] = useState(false);
  const[sbShowElaborating, setSbShowElaborating] = useState(false);

  //MODEL CONFIGURATION
  const [calibrated, setCalibrated] = useState(0);
  const [inflation, setInflation] = useState(0.02);
  const [factorElasticities, setFactorElasticities] = useState(0.5);
  const [priceElasticities, setPriceElasticities] = useState(-1);
  const [startingFactor, setStartingFactor] = useState(1);

  /* const refreshValue = (value,oecode,y,val) => {

    valoriForm[val][oecode][y]=value
    setValoriForm({...valoriForm})
  } */

  const setSuccess = (bool) => {

    setSbShowSuccess(bool);
    if(bool) {
      const timer = setTimeout(() => {setSbShowSuccess(false) }, 5000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    }

  }  

  const elaborazione = event => {
    event.preventDefault()
    
    setIsElaborating(true)
    setError(false)
    setIsSaved(false)
    setSbShowElaborating(true);
    fetch(`${getBaseUrl()}/elaborazione/${idScenario}?calibrated=${calibrated}&inflation=${inflation}&factorElasticities=${factorElasticities}&priceElasticities=${priceElasticities}&startingFactor=${startingFactor}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders()
      },
    })
    .then(response => response.json())
    .then(data => {
      intervalHandler = startIntervalElaborazioneStatus()      
      if(data=='Error'){
        setError(true)
        setIsElaborating(false)
        setSbShowError(true);
        setSbShowElaborating(false);

      }
      if(data=='Executed'){
        setIsSaved(true)
        setIsElaborating(false)
        setSuccess(true);
        setSbShowElaborating(false);
      }

    })
    .catch(error => {
      setError(true)
      setIsElaborating(false)
      setSbShowError(true);
      setSbShowElaborating(false);
      console.error(error)
    })
  }

  const handleModelChange = e => {
    //console.log("handleModelChange",e);
    
    //console.log(e.target.id,e.target.value, e.target.checked );

    switch(e.target.id)
    {
      case "model-calibrated-radio":
        e.target.checked === false ? setCalibrated(0) : setCalibrated(1);
        break;
      case "inflation":
        setInflation(e.target.value)
        break;
      case "factorElasticities": 
        setFactorElasticities(e.target.value)
        break;
      case "priceElasticities": 
        setPriceElasticities(e.target.value)
        break;
      case "startingFactor": 
        setStartingFactor(e.target.value)
        break;                    
      default:             
          break;
    }

  }

  const getValueMeta=(id,arr=[])=>{

    return(arr.filter(s => (s.value) === (id)).pop())
    //return (r => r.filter(s => parseInt(s.idScenario) === parseInt(value)).pop())
    //return r
  }
  
  const TotalRow = () => {
    let totals = {};
    let totArr = [];
    if(scenarioData.idScenario){
      
      var constructionStartY=new Date(scenarioData.tech.construction_start).getFullYear()
      var operationEndY=new Date(scenarioData.tech.operation_end).getFullYear()
      let capex=scenarioData.vettoriSpesa['CAPEX']
      Object.keys(capex)
            .map((oecode)=>{

              for (var y = constructionStartY; y <= operationEndY; y++) {
                // note: we are adding a key prop here to allow react to uniquely identify each
                // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                var valNameCapex=scenarioData.vettoriSpesa['CAPEX'][oecode]?scenarioData.vettoriSpesa['CAPEX'][oecode][y]:0
                var valNameOpex=scenarioData.vettoriSpesa['OPEX'][oecode]?scenarioData.vettoriSpesa['OPEX'][oecode][y]:0
                var valNameLocalshare=scenarioData.vettoriSpesa['LOCALSHARE'][oecode]?scenarioData.vettoriSpesa['LOCALSHARE'][oecode][y]:1
  
  
                var valore=(parseFloat(valNameCapex)*parseFloat(valNameLocalshare))+parseFloat(valNameOpex)
  
                let n;
    
              
                totals[y] === undefined ? totals[y] = 0.00 : n=false;
                totals[y] = parseFloat(totals[y]) + parseFloat(valore)
            };

          }
          )

  };

  console.log("totals",totals, Object.keys(totals))
  totArr = [];
  Object.keys(totals).map( y => {
    totArr.push(
      <td className="text-right" key={y}><strong>{totals[y] && totals[y].toFixed(2)}</strong></td>
     
    )
    
  })

  return totArr;
  }

  const CreaTabella = ()=>{
    
     let totals = {};
     //let totArr = [];

      if(scenarioData.idScenario){
      var constructionStartY=new Date(scenarioData.tech.construction_start).getFullYear()
      var operationEndY=new Date(scenarioData.tech.operation_end).getFullYear()


      var colsHeader = [];
     
      for (var y = constructionStartY; y <= operationEndY; y++) {
        
          // note: we are adding a key prop here to allow react to uniquely identify each
          // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
          const stileHeaderTabella = {
            width: "0px",
            maxWidth: "100px"
          };
          colsHeader.push(<th style={stileHeaderTabella} key={y}>{y}</th>);
      }

      
      const  ColsBody  = ({oecode})=>{
        var colsBody=[];
        //console.log(scenarioData.vettoriSpesa.CAPEX[oecode]['2021'])
        for (var y = constructionStartY; y <= operationEndY; y++) {
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            var valNameCapex=scenarioData.vettoriSpesa['CAPEX'][oecode]?scenarioData.vettoriSpesa['CAPEX'][oecode][y]:0
            var valNameOpex=scenarioData.vettoriSpesa['OPEX'][oecode]?scenarioData.vettoriSpesa['OPEX'][oecode][y]:0
            var valNameLocalshare=scenarioData.vettoriSpesa['LOCALSHARE'][oecode]?scenarioData.vettoriSpesa['LOCALSHARE'][oecode][y]:1


            var valore=(parseFloat(valNameCapex)*parseFloat(valNameLocalshare))+parseFloat(valNameOpex)

            let n;
  
            
            totals[y] === undefined ? totals[y] = 0 : n=false;
            totals[y] = totals[y] + parseFloat(valore)
         
            
          
            valore = valore.toFixed(2)
            
            colsBody.push(
              <td key={y} className="text-right" style={{maxWidth:80}}>
                    {valore}
              </td>);

        }
       
        return colsBody;
      }


         
          
        
      
        var rowsTable=[]
        var capex=scenarioData.vettoriSpesa['OPEX']

        Object.keys(capex)
          .map((oecode, y)=>(
              rowsTable.push(<tr key={y}>
                        <td className="value-label">{getValueMeta(oecode.replace("_",""),props.oecodes).label}</td>
                        <ColsBody oecode={oecode}/>
                      </tr>)
            )
          )

         

          
              
        

          
                
      return (
          <table className="investment-table">
           {/*<thead>
              <tr>
                <th className="value-label value-capex" ></th>
                {colsHeader}

              </tr> 
            </thead>*/}
            <tbody>
            <tr>
                <th className="value-label value-capex" ><Trans code="WS008" /></th>
                {colsHeader}
              </tr>
              
              <tr>
          <td className="value-label"><strong>Totals</strong> </td>
          <TotalRow />
          </tr> 
            
              {rowsTable}
            </tbody>
          </table>
      )
    }
  }

  const getElaborazioneStatus = (idScenario) => {    
    
    return fetch(`${getBaseUrl()}/elaborazioneStatus/${idScenario}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders()
      },
    })
    .then(response => response.json())
    .then(data => {
      //console.log(data)
      return data

    })
    .catch(error => {
      setError(true)
      setIsElaborating(false)
      setSbShowError(true);
      setSbShowElaborating(false);
      console.error(error)
    })
  }

  const startIntervalElaborazioneStatus = function() {
    intervalHandler = setInterval(() => {
      getElaborazioneStatus(scenarioData.idScenario).then(
        function(response) {
        console.log(response)
        switch (response) {     
          case 'Elaborating':                  
            //setIsSaved(false)
            //setIsElaborating(true)
            //setSuccess();
            setSbShowElaborating(true);
            break           
          case 'Executed':
            //if (!executedFlag) {
              setIsSaved(true)
              setIsElaborating(false)
              setSuccess(true);
              setSbShowElaborating(false);
              clearInterval(intervalHandler)
              //executedFlag = true
            //}                  
            break
          case 'Error':
            setError(true)
            setIsElaborating(false)
            setSbShowError(true);
            setSbShowElaborating(false);
            clearInterval(intervalHandler)
            break
          default:                  
            //setIsSaved(false)
            //setIsElaborating(true)
            //setSuccess();
            //setSbShowElaborating(true);
            break
        }             
      })
    }, 5000)
    return intervalHandler
  }

  var intervalHandler = null

  useEffect(()=>{
    if(props.sector){
        if(scenarioData.idScenario) {
          setIdScenario(scenarioData.idScenario)

          if (scenarioData.calibration && scenarioData.calibration.calibrated !== undefined) {
            
            
              setCalibrated(parseInt(scenarioData.calibration.calibrated));
              setFactorElasticities(parseFloat(scenarioData.calibration.FactorPriceDemandElasticities));
              setInflation(parseFloat(scenarioData.calibration.Inflation))
              setPriceElasticities(parseFloat(scenarioData.calibration.PriceDemandElasticities))
              setStartingFactor(parseFloat(scenarioData.calibration.StartingFactorPrices));

          }
             
          getElaborazioneStatus(scenarioData.idScenario).then(
            function(response) {              
            console.log(response)
            if (response === 'Elaborating') {
              intervalHandler = startIntervalElaborazioneStatus()          
            }                        
          })
          
        }
      }

  },[scenarioData.idScenario,props.sector])

  if (!scenarioData) return null

  return ( <>
   {sbShowSuccess 
   ? ( <StatusBar status="success" timeout={true} /> )
   :  sbShowError ? <StatusBar status="error" timeout={false}/>  
   : sbShowElaborating ? <StatusBar status="elaborating" timeout={false}/> : null
   }

   <div className="row">
      <CardDeck>
         <div className="col-12">
            <Card className="cards-utente d-none d-lg-block">
               <Form onChange={handleModelChange} onSubmit={elaborazione} id="form-execution">
               <Card.Body>
                  {/*<Ico36 className="logo-card ico-box-blue"/>*/}            
                  <Card.Title> </Card.Title>
                  <Row >
                  <Col>
                     <Form.Check
                     inline
                     label={ <Trans code="WS006" /> }
                     name="group1"
                     type="switch"
                     id="model-calibrated-radio"
                     value="calibrated"
                     defaultChecked={calibrated === 1} //prima solo checked
                     />
                  </Col>
               
               <Col >
               <InputGroup className="mb-3">
                  <InputGroup.Text id="inflation"> <Trans code="WS009" /></InputGroup.Text>
                     <Form.Control
                     placeholder={inflation}
                     aria-label="inflation"
                     aria-describedby="inflation"
                     id="inflation"
                     value={inflation}
                     disabled={calibrated === 0}
                     />
               </InputGroup>
               </Col>
               
               <Col >
               <InputGroup className="mb-3">
                  <InputGroup.Text id="factorElasticities"> <Trans code="WS010" /></InputGroup.Text>
                     <Form.Control
                     placeholder={factorElasticities}
                     aria-label="factorElasticities"
                     aria-describedby="factorElasticities"
                     id="factorElasticities"
                     disabled={calibrated === 0}
                     />
               </InputGroup>
               </Col>
               
               <Col >
               <InputGroup className="mb-3">
                  <InputGroup.Text id="priceElasticities"> <Trans code="WS011" /></InputGroup.Text>
                     <Form.Control
                     placeholder={priceElasticities}
                     aria-label="priceElasticities"
                     aria-describedby="priceElasticities"
                     id="priceElasticities"
                     disabled={calibrated === 0}
                     />
               </InputGroup>
               </Col>
               
               <Col>
               <InputGroup className="mb-3">
                  <InputGroup.Text id="startingFactor"> <Trans code="WS012" /></InputGroup.Text>
                     <Form.Control
                     placeholder={startingFactor}
                     aria-label="startingFactor"
                     aria-describedby="startingFactor"
                     id="startingFactor"
                     disabled={calibrated === 0}
                     />
               </InputGroup>
               </Col>
            </Row>
               </Card.Body>
               </Form>
            </Card>
         </div>

         <div className="col-12">
            <Card className="cards-utente d-none d-md-block card-table">
               <Card.Body>
                  <Card.Title><Trans code="WS007" /></Card.Title>
                  <div className="main-wrapper">
                     <CreaTabella />
                  </div>
               </Card.Body>
            </Card>
         </div>
      </CardDeck>     
   </div>
               
   <Button form="form-execution" type="submit" className="submit-button sticky-footer-button"><Trans code="A0532"/></Button>
</>
  );
}

export default StepExecution;
