import { Card } from 'react-bootstrap'
import { Ico35 } from "@icons/new"
import { Trans } from '@lib/i18n'
import { getCountryfm } from '@lib/api'
import React, {useState, useEffect} from 'react';

const KeyindicatorsTable = () => {

  const [countryfm, setCountryfm] = useState([])
//  const [page, setPage] = useState(1)

  //setPage(10)


  
   useEffect(()=>{
    const getCountryfmMeta=()=>{
      getCountryfm()
  
         .then(function(myJson) {
           // console.lo
           setCountryfm(myJson.indicesRating.dati)
  
         });
     };
     getCountryfmMeta()
   },[])




  return (
  <Card className="cards-utente d-none d-md-block card-table w-100">
    <Card.Body>
      <Ico35 className="logo-card ico-box-blue" />
      {/* <Card.Title><Trans code="A0183" /></Card.Title>*/}
      <div style={{ overflowX: 'scroll'}} className="w-100">
        <table className="investment-table mt-3">
          <thead>
            <tr>
              <th className=""><Trans code="A0268"/></th>
              <th></th>
              <th className=""><Trans code="A0269"/></th>
              <th className="">R</th>
              <th><Trans code="A0270"/></th>
              <th className=""><Trans code="A0271"/></th>
              <th className=""><Trans code="A0071"/></th>
            </tr>
          </thead>
          <tbody>
             {countryfm.map(country =>  {

                  return (

                    <tr>
                      <td><Trans code={country.code}/></td>
                      <td>{country.colonna1}</td>
                      <td>{country.colonna2}</td>
                      <td>{country.colonna3}</td>
                      <td>{country.colonna4}</td>
                      <td>{country.colonna5}</td>
                      <td>{country.colonna6}</td>
                    </tr>
                  )
                })}
          </tbody>
        </table>
      </div>
      <p className="va-desc"></p>
    </Card.Body>
  </Card>
  );
}

export default KeyindicatorsTable;
