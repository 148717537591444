import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Trans, propTrans } from '@lib/i18n';
import {
  getAuthUser,
  getOEScenario,
  getUsers,
  getVisibilities,
  getScenarioTypologies,
  getOECurrencies,
  getClassesVersions,
  postScenario,
  putScenario,
  postScenAnalysis,
  putScenAnalysis,
  putScenAdditionalInfo,
  delScenAnalysis,
  deleteScenAdditionalInfo,
} from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import AnalysisSection from './analysisSection';
import AdditionalInfoSection from './additionalInfoSection';
import ConfirmContextProvider from 'components/customHook/useConfirm/ConfirmContextProvider';
import ConfirmModal from 'components/customHook/useConfirm/ConfirmModal';
import { fetchData } from '@lib/helpers/index';
function updateStateWithVersionId(
  dataArray,
  versionId,
  setFirstState,
  setSecondState,
  setThirdState
) {
  const foundObject = dataArray.find((item) => item.versions.find((v) => v.id === versionId));
  if (foundObject) {
    const classValue = foundObject.className;
    const versionValue = foundObject.versions.find((v) => v.id === versionId);
    const classLabel = classValue;

    setFirstState({ value: classValue, label: classLabel });
    setSecondState({ value: versionValue.id, label: versionValue.version });
    setThirdState(
      foundObject.versions
        .filter((el) => el.employable)
        .map((item) => ({
          label: item.version,
          value: item.id,
        }))
    );
  }
}

const initialState = {
  name: '',
  userId: null,
  userCompanyId: null,
  visibilityCode: 'ORG',
  typologyCode: 'INV',
  currencyCode: 'EUR',
  classId: null,
  verified: false,

  investment: null, //0 da postman
  socialDiscountRate: 3,
  inputTypeCode: 'PEF',
  investmentStart: null, //currentYear //constructionStart //Investimento - Da anno
  investmentEnd: null, //currentYear ad anno
  operationStart: null, //currentYear Esercizio - Da anno
  operationEnd: null, //currentYear ad anno
  datasetId: null,
  address: '',
  status: 'Draft',
  analyses: [],
  updatedAt: null,
  createdAt: null,
};

const StepConfigure = ({ scen, setScen, setDisable, setRefresh }) => {
  const [scenario, setScenario] = useState({});
  const [scenAnalyses, setScenAnalyses] = useState([]);
  const [scenAdditionalInfo, setScenAdditionalInfo] = useState([]);
  const [scenLinks, setScenLinks] = useState([]);
  const [scenFiles, setScenFiles] = useState([]);
  const [optionsOwners, setOptionsOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [optionsVisibilities, setOptionsVisibilities] = useState([]);
  const [selectedVisibility, setSelectedVisibility] = useState([]);
  const [optionsTypologies, setOptionsTypologies] = useState([]);
  const [selectedTypology, setSelectedTypology] = useState([]);
  const [optionsCurrencies, setOptionsCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [classesToFilter, setClassesToFilter] = useState([]);
  const [optionsClasses, setOptionsClasses] = useState([]);

  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedClassVersion, setSelectedClassVersion] = useState([]);
  const [filteredVersions, setFilteredVersions] = useState([]);
  const [versionOptions, setVersionOptions] = useState([]);
  const [userLogged, setUserLogged] = useState(null);
  const [showModalChangeInputType, setShowModalChangeInputType] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  console.log('tempArrayInfo', scenFiles, scenario);

  const handleCloseModal = (setShowModal) => setShowModal(false);

  const handleObjectChange = (selectedOption) => {
    const newScenObj = { ...scenario, classId: 0 };
    setSelectedClass(selectedOption);
    const filteredObject = classesToFilter.filter(
      (item) => item.className === selectedOption.label
    )[0];
    setFilteredVersions(filteredObject ? filteredObject.versions : []);
    setScenario(newScenObj);
    setSelectedClassVersion([]);
  };

  useEffect(() => {
    setVersionOptions(
      filteredVersions
        .filter((versions) => versions.employable)
        .map((item) => ({
          label: item.version,
          value: item.id,
          // isDisabled: !item.employable,
        }))
    );
  }, [filteredVersions]);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserLogged(user);

    // Fetching other necessary data
    if (Object.keys(scen).length > 0) {
      fetchData(getOEScenario, setScenario, null, [scen.id], handleStatusBar);
    } else {
      initialState.userId = user?.id;
      initialState.userCompanyId = user?.idOrganization;
      setScenario(initialState);
    }
  }, [scen]);
  useEffect(() => {
    const transformData = (type, data) => {
      switch (type) {
        case 'users':
          setOptionsOwners(
            data.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName} - ${user.companies[0]?.name}`,
              organization: user.companies[0]?.companyId,
            }))
          );
          break;
        case 'visibilities':
          setOptionsVisibilities(
            data.map((item) => ({
              value: item.code,
              label: item.name,
            }))
          );
          break;
        case 'typologies':
          setOptionsTypologies(
            data.map((typology) => ({
              value: typology.code,
              label: typology.name,
            }))
          );
          break;
        case 'currencies':
          setOptionsCurrencies(
            data.map((currency) => ({
              value: currency.code,
              label: `${currency.symbol} - ${currency.name}`,
            }))
          );
          break;
        case 'classes':
          setOptionsClasses(
            data
              .filter((filteredClass) => filteredClass.employable)
              .map((retrievedClass) => ({
                value: retrievedClass.className,
                label: retrievedClass.className,
              }))
          );
          setClassesToFilter(data);
          break;
        default:
          break;
      }
    };
    fetchData(getUsers, null, (data) => transformData('users', data), [], handleStatusBar);
    fetchData(
      getVisibilities,
      null,
      (data) => transformData('visibilities', data),
      [],
      handleStatusBar
    );
    fetchData(
      getScenarioTypologies,
      null,
      (data) => transformData('typologies', data),
      [],
      handleStatusBar
    );
    fetchData(
      getOECurrencies,
      null,
      (data) => transformData('currencies', data),
      [],
      handleStatusBar
    );
    fetchData(
      getClassesVersions,
      null,
      (data) => transformData('classes', data),
      [],
      handleStatusBar
    );
  }, [scen]);

  useEffect(() => {
    if (scenario.additionalInfo) {
      setScenAdditionalInfo(
        scenario.additionalInfo.filter(
          (info) => info.topic !== 'link' && info.topic !== 'file' && info.topic !== 'image'
        )
      );
      setScenLinks(scenario.additionalInfo.filter((info) => info.topic === 'link'));
      setScenFiles(
        scenario.additionalInfo.filter((info) => info.topic === 'file' || info.topic === 'image')
      );
    }

    setSelectedOwner(
      optionsOwners.filter((optionOwner) => Number(optionOwner.value) === scenario.userId)
    );
    setSelectedVisibility(
      optionsVisibilities.filter(
        (optionVisibility) => optionVisibility.value === scenario.visibilityCode
      )
    );
    setSelectedTypology(
      optionsTypologies.filter((optionTypology) => optionTypology.value === scenario.typologyCode)
    );
    setSelectedCurrency(
      optionsCurrencies.filter((optionCurrency) => optionCurrency.value === scenario.currencyCode)
    );

    updateStateWithVersionId(
      classesToFilter,
      scenario?.classId,
      setSelectedClass,
      setSelectedClassVersion,
      setVersionOptions
    );
  }, [
    scenario,
    optionsOwners,
    optionsVisibilities,
    optionsTypologies,
    optionsCurrencies,
    optionsClasses,
    classesToFilter,
  ]);

  const onChange = (e, fieldName, setSelect) => {
    switch (fieldName) {
      case 'name':
      case 'verified':
        setScenario(() => ({
          ...scenario,
          [fieldName]: fieldName === 'name' ? e.target.value : e.target.checked,
        }));
        break;
      case 'userId':
      case 'visibilityCode':
      case 'typologyCode':
      case 'currencyCode':
      case 'classId':
        setSelect(e ?? []);

        let tempScenario = { ...scenario };
        tempScenario[fieldName] =
          fieldName === 'userId' || fieldName === 'classId' ? Number(e.value) : e.value;
        if (fieldName === 'userId') {
          tempScenario.userCompanyId = e.organization;
        }

        setScenario(tempScenario);
        break;
      default:
        break;
    }

    if (scenario.id) {
      const saveButton = document.getElementById('save-button');
      saveButton.classList.add('pulse-button');
    }
  };

  const checkConfirmation = async (boolean, setShowModal) => {
    if (boolean) {
      setIsConfirmed(boolean);
      submitPut(scenario);
    } else {
      setIsConfirmed(boolean);
    }

    setShowModal(false);
  };

  const submit = async (e) => {
    e.preventDefault();

    setSbShow(<></>);

    const {
      verified,
      investment,
      socialDiscountRate,
      investmentStart,
      investmentEnd,
      operationStart,
      operationEnd,
      datasetId,
      address,
      updatedAt,
      createdAt,

      additionalInfo,
      analyses,
      class: classNoKeyword,
      dataset,
      evaluationYear,
      inputTypeCode,
      ...restScenario
    } = scenario;

    const isScenarioFilled = Object.values(restScenario).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;

    if (!isScenarioFilled) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      return;
    }

    if (scenario.id) {
      if (scen.classId !== scenario.classId) {
        setShowModalChangeInputType(true);
        return;
      }
      submitPut(scenario);
    } else {
      //POST
      setIsLoading(true);
      const createResult = await postScenario(scenario);

      if (createResult.status === true) {
        let resultOfPosts = [];
        console.log('test', scenAnalyses);
        //"for of" is good for push result async
        for (const scenAnalysis of scenAnalyses) {
          const createAnalysisResult = await postScenAnalysis(scenAnalysis, createResult.data.id);
          resultOfPosts.push(createAnalysisResult.status);
        }
        // const allAdditionalInfo = scenAdditionalInfo.concat(scenLinks, scenFiles);
        // const editAdditionalInfoResult = await putScenAdditionalInfo(
        //   allAdditionalInfo,
        //   createResult.data.id
        // );

        if (resultOfPosts.length > 0 && resultOfPosts.every((el) => el === true)) {
          handleStatusBar('success', propTrans({ prop: 'A0559' }), true);
          setIsLoading(false);
          setTimeout(() => {
            //setScenario(createResult.data);
            history.push(`/scenariowizard/${createResult.data.id}?step=build&sub=general`);
          }, 2000);
        } else {
          handleStatusBar('error', propTrans({ prop: 'A0521' }), false);
          setIsLoading(false);
        }
      } else {
        handleStatusBar('error', propTrans({ prop: 'A0521' }), false);
        setIsLoading(false);
      }
    }
  };

  const submitPut = async (scenario) => {
    const { analysisResults, ...scenarioToPut } = scenario;
    setIsLoading(true);
    const editResult = await putScenario(scenarioToPut);

    const isAnalysesFilledDeleted = scenAnalyses.every((an) => an.status === 'Deleted');

    // check length of analyses
    if (scenAnalyses.length === 0) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      setIsLoading(false);
      return;
    }

    if (isAnalysesFilledDeleted) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      setIsLoading(false);
      return;
    }

    if (editResult.status === true) {
      let resultOfActions = [];

      for (const scenAnalysis of scenAnalyses) {
        let typeExists = scen && scen.analyses.some((an) => an.type === scenAnalysis.type);

        if (!typeExists) {
          //non esiste
          const createAnalysisResult = await postScenAnalysis(scenAnalysis, scenario.id);
          resultOfActions.push(createAnalysisResult.status);
        } else {
          //esiste
          let isSame =
            scen &&
            scen.analyses.filter(
              (an) => an.type === scenAnalysis.type && an.status === scenAnalysis.status
            ).length > 0
              ? true
              : false;

          const idAnalysis =
            scen &&
            scen.analyses
              .filter((an) => an.type === scenAnalysis.type)
              .map((el) => el.id)
              .join();
          if (!isSame) {
            if (scenAnalysis.status === 'Deleted') {
              const deleteResult = await delScenAnalysis(scenAnalysis, scenario.id, idAnalysis);
              resultOfActions.push(true);
            } else {
              const editAnalysisResult = await putScenAnalysis(
                scenAnalysis,
                scenario.id,
                idAnalysis
              );
              resultOfActions.push(editAnalysisResult.status);
            }
          } else {
            resultOfActions.push(true);
          }
        }
      }

      // const originAttach = scenario.additionalInfo.filter(
      //   (info) => info.topic === 'link' || info.topic === 'file' || info.topic === 'image'
      // );
      // const nowAttach = scenLinks.concat(scenFiles);

      // const oldAttachsDeleted = originAttach.filter((object1) => {
      //   return !nowAttach.some((object2) => {
      //     return object1.id === object2.id;
      //   });
      // });

      // if (oldAttachsDeleted.length > 0) {
      //   oldAttachsDeleted.forEach(async (attach) => {
      //     const deleteResult = await deleteScenAdditionalInfo(scenario.id, attach.id);
      //     resultOfActions.push(deleteResult.status);
      //   });
      // }

      // const allAdditionalInfo = scenAdditionalInfo.concat(scenLinks, scenFiles);
      // console.log('allAdditionalInfo2', allAdditionalInfo);
      // const editAdditionalInfoResult = await putScenAdditionalInfo(allAdditionalInfo, scenario.id);

      const resultsOfActionsExists =
        resultOfActions.length > 0 ? resultOfActions.every((el) => el === true) : true;
      if (resultsOfActionsExists) {
        handleStatusBar('success', propTrans({ prop: 'A0560' }), true);
        setIsLoading(false);
        setRefresh(true);
        setTimeout(() => {
          //setScenario(editResult.data);
          // setScen(editResult.data);
          history.push(`/scenariowizard/${editResult.data.id}?step=build&sub=general`);
        }, 2000);
        setRefresh(false);
      } else {
        handleStatusBar('error', propTrans({ prop: 'A0521' }), false);
        setIsLoading(false);
      }
    } else {
      handleStatusBar('error', propTrans({ prop: 'A0521' }), false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (scen) {
      scen.classId === scenario.classId ? setDisable(false) : setDisable(true);
    }
  }, [scen.classId, scenario.classId]);

  if (!scen) return null;

  return (
    <>
      {sbShow}

      <Form onSubmit={submit} id='form-configure'>
        {/* ANAGRAFICA */}
        <div className='simple-box' style={{ marginTop: '40px' }}>
          <Row>
            <Col lg={12} className='form-login'>
              <h5 className='title-simple-box margin-title-box'>
                <Trans code='A0493' />
              </h5>
              <Row className='mb-3'>
                <Form.Group as={Col} controlId='name'>
                  <Form.Label>
                    <Trans code='A0080' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='name'
                    value={scenario.name ?? ''}
                    onChange={(e) => onChange(e, 'name')}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='userId'>
                  <Form.Label>
                    <Trans code='A0081' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    name='userId'
                    value={selectedOwner}
                    options={optionsOwners}
                    onChange={(e) => onChange(e, 'userId', setSelectedOwner)}
                    isDisabled={userLogged && Number(userLogged.role) !== 1}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='visibilityCode'>
                  <Form.Label>
                    <Trans code='A0373' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    name='visibilityCode'
                    value={selectedVisibility}
                    options={
                      userLogged && Number(userLogged.role) !== 1
                        ? optionsVisibilities.filter((el) => Number(el.value) !== 1)
                        : optionsVisibilities
                    } //se non sei admin l'accesso Open non deve essere visualizzato
                    onChange={(e) => onChange(e, 'visibilityCode', setSelectedVisibility)}
                    //isDisabled={userLogged && Number(userLogged.role) !== 1}
                  />
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} controlId='typologyCode'>
                  <Form.Label>
                    <Trans code='A0333' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    name='typologyCode'
                    value={selectedTypology}
                    options={optionsTypologies}
                    onChange={(e) => onChange(e, 'typologyCode', setSelectedTypology)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='currencyCode'>
                  <Form.Label>
                    <Trans code='GEN001' replacementText='Currency' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    name='currencyCode'
                    value={selectedCurrency}
                    options={optionsCurrencies}
                    onChange={(e) => onChange(e, 'currencyCode', setSelectedCurrency)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='classId'>
                  <Form.Label>
                    <Trans code='A0498' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    name='class'
                    value={selectedClass}
                    options={optionsClasses}
                    onChange={(e) => handleObjectChange(e)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='versionId'>
                  <Form.Label>
                    <Trans code='A0722' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Select
                    isDisabled={selectedClass.length === 0}
                    name='classVersionId'
                    value={selectedClassVersion}
                    options={versionOptions}
                    onChange={(e) => onChange(e, 'classId', setSelectedClassVersion)}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </div>

        <AnalysisSection
          scenOriginAnalyses={scenario.analyses}
          setScenAnalyses={setScenAnalyses}
          idScenario={scenario.id}
        />

        {/* <ConfirmContextProvider>
          <AdditionalInfoSection
            originAdditionalInfo={scenario.additionalInfo}
            setScenAdditionalInfo={setScenAdditionalInfo}
            setScenLinks1={setScenLinks}
            setScenFiles1={setScenFiles}
            scenTest={scenFiles}
            idScenario={scenario.id}
          />
          <ConfirmModal></ConfirmModal>
        </ConfirmContextProvider> */}
        {sbShow}

        {userLogged && Number(userLogged.idOrganization) === 1 && Number(userLogged.role) === 1 && (
          <Row>
            <Col lg={12} className='form-login'>
              <Row>
                <Col>
                  <div className='simple-box'>
                    <Form.Group as={Col} className='text-center' controlId='verified'>
                      <Row>
                        <Form.Check
                          type='checkbox'
                          className='mt-2 text-center'
                          name='verified'
                          label={propTrans({ prop: 'A0457' })}
                          defaultChecked={scenario?.verified}
                          onClick={(e) => onChange(e, 'verified')}
                        />
                      </Row>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <span className='mt-4 px-3'>
          <span className='required-field'>*</span>&nbsp;
          <Trans code='A0503' />
        </span>

        <Button
          id='save-button'
          form='form-configure'
          type='submit'
          className='submit-button sticky-footer-button'
          disabled={isLoading}>
          {!isLoading ? (
            <Trans code='F00046' />
          ) : (
            <span className='d-flex align-items-center ml-2'>
              <Trans code='A0782' />
              <Spinner animation='border' className='ml-2' size='sm' />
            </span>
          )}
        </Button>
      </Form>

      <Modal
        show={showModalChangeInputType}
        onHide={() => handleCloseModal(setShowModalChangeInputType)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <Trans code='A0726' />
            <span className='d-block'>
              <Trans code='A0572' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => checkConfirmation(false, setShowModalChangeInputType)}>
            <Trans code='F0057' />
          </Button>
          <Button
            variant='success'
            onClick={() => checkConfirmation(true, setShowModalChangeInputType)}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default StepConfigure;
