import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { getAnalyses } from '@lib/api';

const AnalysisSection = ({ scenOriginAnalyses, setScenAnalyses, idScenario }) => {
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [internalCheckedAnalyses, setInternalCheckedAnalyses] = useState([]);

  useEffect(() => {
    const fetchDatas = async () => {
      try {
        const retrievedVisibilities = await getAnalyses();
        setAnalyses(retrievedVisibilities.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchDatas();
  }, []);

  useEffect(() => {
    if (scenOriginAnalyses) {
      setInternalCheckedAnalyses(scenOriginAnalyses);
    }
  }, [scenOriginAnalyses]);

  useEffect(() => {
    setScenAnalyses(internalCheckedAnalyses);
  }, [internalCheckedAnalyses]);

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Spinner animation='border' />
      </div>
    );
  }

  const onChange = (e) => {
    const currentType = e.target.id;
    const isChecked = e.target.checked;

    setInternalCheckedAnalyses((prevState) => {
      let updatedAnalyses = [...prevState];
      const existingAnalysisIndex = updatedAnalyses.findIndex((an) => an.type === currentType);

      if (isChecked) {
        if (existingAnalysisIndex === -1) {
          updatedAnalyses.push({ type: currentType, status: 'New' });
        } else {
          updatedAnalyses[existingAnalysisIndex].status = 'New';
        }
      } else {
        if (existingAnalysisIndex !== -1) {
          updatedAnalyses[existingAnalysisIndex].status = 'Deleted';
        }
      }
      return updatedAnalyses;
    });
  };

  return (
    <>
      <div
        className='simple-box'
        // style={{ marginTop: '40px' }}
      >
        <Row>
          <Col lg={12} className='form-login'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0564' />*
            </h5>
            <Row className='mb-3 px-3'>
              {analyses
                ?.filter((an) => Number(an.active))
                .sort((a, b) => Number(a.position) - Number(b.position))
                .map((analysis) => (
                  <Form.Check
                    inline
                    key={analysis.code}
                    type='switch'
                    className='custom-switch-md'
                    id={analysis.code}
                    label={<Trans code={analysis.code} />}
                    name='analyses'
                    checked={internalCheckedAnalyses.some(
                      (an) => an.type === analysis.code && an.status !== 'Deleted'
                    )}
                    onChange={onChange}
                  />
                ))}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AnalysisSection;
