import { Trans } from '@lib/i18n/index';
import React, { useState } from 'react';
import { Row, Form, Col, Button, Spinner } from 'react-bootstrap';
import { postCheckKpiFormula } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
const FormulaTester = () => {
  const [formulaTest, setFormulaTest] = useState({ formula: '' });
  const [formulaResult, setFormulaResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const onChange = (e) => {
    const inputValue = e.target.value;
    // Ensure the formula starts with '='
    const updatedValue = inputValue.startsWith('=') ? inputValue : `=${inputValue}`;
    setFormulaTest({ formula: updatedValue });
  };

  const onSubmit = async (e) => {
    e?.preventDefault();

    setError(null);
    if (!formulaTest.formula || formulaTest.formula === '=') {
      setError('Inserire una formula');
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setFormulaResult('');
    const createResult = await postCheckKpiFormula(formulaTest);

    if (createResult.status === true) {
      setFormulaResult(createResult.value);
    } else if (createResult.message || createResult.error) {
      createResult?.message
        ? setError(createResult.message)
        : setError('Errore nel calcolo della formula');
    }
    setIsLoading(false);
  };

  return (
    <div
      className='py-3 mb-3'
      style={{ border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
      <h3 className='mb-4 ml-2' style={{ fontSize: '1.5rem' }}>
        <Trans code='A0873' />
      </h3>
      <Form onSubmit={onSubmit} id='form-formula-tester'>
        <Row className='mb-3 d-flex'>
          <Form.Group as={Col} className='col-6 px-4' controlId='formulaTest'>
            <Form.Control
              type='text'
              name='formulaTest'
              placeholder='es. = (2+2)*4'
              value={formulaTest.formula}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Col className='mb-3'>
            <Button type='submit' form='form-formula-tester' disabled={isLoading}>
              <span>
                <Trans code='A0872' />
                {isLoading ? (
                  <span className='ml-1'>
                    <Spinner animation='border' variant='light' size='sm' />
                  </span>
                ) : (
                  ''
                )}
              </span>
              {}
            </Button>
          </Col>
        </Row>
      </Form>
      {formulaResult !== '' && (
        <div className='d-flex justify-content-center align-items-center'>
          <div>
            <h3 className='m-0'>{formulaResult}</h3>
            <p className='m-0'>
              <Trans code='A0871' />
            </p>
          </div>
        </div>
      )}
      {error && (
        <StatusBar
          status='error'
          message={error}
          timeout={false}
          style={{
            marginLeft: '20px',
            marginRight: '20px',
          }}
        />
      )}
    </div>
  );
};

export default FormulaTester;
