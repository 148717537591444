import React, { useState } from 'react'
import { rounder, percent } from '@lib/helpers'
import { Card } from 'react-bootstrap';
import { Trans } from '@lib/i18n'
import ShareBar from '@components/scenario/ShareBar'
import { Ico35 } from "@icons/new";


const ProductionSectorsTable = ({ settori }) => {
  const [orderBy, setOrderBy] = useState(null)
  const [order, setOrder] = useState(1)
  const max = parseFloat(Math.max.apply(Math, settori.map(o => o.share)))

  const handleOrderBy = key => () => {
    if (orderBy === key) setOrder(order * -1)
    else {
      setOrder(-1)
      setOrderBy(key)
    }
  }

  return <Card className="cards-utente d-none d-md-block card-table">
    <Card.Body>
      <Ico35 className="logo-card ico-box-blue" />
      <Card.Title><Trans code="A0196" /></Card.Title>
      <table className="investment-table">
        <thead>
          <tr>
            <th className="value-label" onClick={handleOrderBy('code')}><Trans code="A0191" /></th>
            <th className="value-label" onClick={handleOrderBy('industry')}><Trans code="A0068" /></th>
            <th onClick={handleOrderBy('value')}><Trans code="A0137" /></th>
            <th onClick={handleOrderBy('share')}><Trans code="A0193" /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {settori.sort((a, b) => {
            if (!orderBy) return 0
            if (order === -1) {
              if (a[orderBy] < b[orderBy]) return -1
              if (a[orderBy] > b[orderBy]) return 1
              return 0
            } else {
              if (a[orderBy] < b[orderBy]) return 1
              if (a[orderBy] > b[orderBy]) return -1
              return 0
            }
          }).map((row, index) => (
            <tr key={index}>
              <td className="value-label">{row.code}</td>
              <td className="value-label">{row.industry}</td>
              <td>{rounder(row.value)}</td>
              <td>{percent(row.share)}</td>
              <td className="value-label"><ShareBar max={max} value={row.share} width={50} /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="va-desc"><Trans code="A0053" /></p>
    </Card.Body>
  </Card>
}

export default ProductionSectorsTable