import React, { useState, useEffect, Fragment } from 'react';
import { Row } from 'react-bootstrap';

export const ShowIndicatorsNameRenderer = ({ data }) => {
  const [dataList, setDataList] = useState([]);
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    setDataList(data.indicators);
    setDataLength(data.indicators.length);
  }, [data]);

  return (
    <div>
      {dataList.map((ind, i) => {
        return (
          <Fragment key={data.id + i}>
            <Row className='align-items-center mx-1'>
              {ind.var}: {ind.indicator}
            </Row>
            {i !== dataLength - 1 ? <hr className='show-hr-renderer' /> : null}
          </Fragment>
        );
      })}
    </div>
  );
};

export const ShowIndicatorsUdmRenderer = ({ data }) => {
  const [dataList, setDataList] = useState([]);
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    setDataList(data.indicators);
    setDataLength(data.indicators.length);
  }, [data]);

  return (
    <div>
      {dataList.map((ind, i) => {
        return (
          <Fragment key={data.id + i}>
            <Row className='align-items-center mx-1'>{ind.udm}</Row>
            {i !== dataLength - 1 ? <hr className='show-hr-renderer' /> : null}
          </Fragment>
        );
      })}
    </div>
  );
};
export const ShowInvestmentRenderer = ({ data }) => {
  const [dataList, setDataList] = useState([]);
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    setDataList(data.invTypes);
    setDataLength(data.invTypes.length);
  }, [data]);

  return (
    <div>
      {dataList.map((inv, i) => {
        return (
          <Fragment key={data.code + i}>
            <Row className='align-items-center mx-1'>
              {i + 1}: {inv.name}
            </Row>
            {i !== dataLength - 1 ? <hr className='show-hr-renderer' /> : null}
          </Fragment>
        );
      })}
    </div>
  );

  //   const [dataList, setDataList] = useState([]);
  //   const [dataLength, setDataLength] = useState(0);

  //   useEffect(() => {
  //     setDataList(data.invTypes);
  //     setDataLength(data.invTypes.length);
  //   }, [data]);

  //   return (
  //     <div>
  //       {dataList.map((ind, i) => {
  //         return (
  //           <Fragment key={data.id + i}>
  //             <Row className='align-items-center mx-1'>{ind.name}</Row>
  //             {i !== dataLength - 1 ? <hr className='show-hr-renderer' /> : null}
  //           </Fragment>
  //         );
  //       })}
  //     </div>
  //   );
};

export const ShowKpiRenderer = ({ data }) => {
  const [dataList, setDataList] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const boxHeight = '4.5rem';

  useEffect(() => {
    setDataList(data.kpi);
    setDataLength(data.kpi.length);
  }, [data]);

  const showHideBox = (boxId, buttonId) => {
    const element = document.getElementById(boxId);
    const button = document.getElementById(buttonId);

    //show
    if (element.style.height === boxHeight) {
      element.style.height = 'inherit';
      button.innerHTML = 'Show Less...';
      element.classList.add('fade-in-div');
      element.classList.remove('fade-out-div');
      element.classList.remove('showhide-box');
    } else {
      //hide
      element.style.height = boxHeight;
      button.innerHTML = 'Show More...';
      element.classList.remove('fade-in-div');
      element.classList.add('fade-out-div');
      element.classList.add('showhide-box');
    }
  };

  return (
    <div className='showhide-box' id={data.id}>
      {dataList.map((el, i) => {
        return (
          <Fragment key={data.id + i}>
            <Row className='align-items-center mx-1'>{el.name}</Row>
            {i !== dataLength - 1 ? <hr className='show-hr-renderer' /> : null}
          </Fragment>
        );
      })}
      {dataLength > 3 ? (
        <div className='row'>
          <div className='col mt-2'>
            <a
              className='showhide-button'
              id={data.id + '-contextButton'}
              onClick={() => showHideBox(data.id, data.id + '-contextButton')}>
              Show More...
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
