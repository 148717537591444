import React, { Component } from "react"

class Ico98 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-98" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
         <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
         <g transform="scale(2.5) translate(10,10)">
            <path classNAme="ico" d="M23,9h6a2,2,0,0,0,2-2V3a2,2,0,0,0-2-2H23a2,2,0,0,0-2,2V4H11V3A2,2,0,0,0,9,1H3A2,2,0,0,0,1,3V7A2,2,0,0,0,3,9H9a2,2,0,0,0,2-2V6h4V26a2.0023,2.0023,0,0,0,2,2h4v1a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V25a2,2,0,0,0-2-2H23a2,2,0,0,0-2,2v1H17V17h4v1a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2H23a2,2,0,0,0-2,2v1H17V6h4V7A2,2,0,0,0,23,9Zm0-6h6V7H23ZM9,7H3V3H9ZM23,25h6v4H23Zm0-11h6v4H23Z" transform="translate(0 0.0049)"/>
         </g>
      </svg>
    );
  }
}
export default Ico98