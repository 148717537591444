import React, { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from '@lib/i18n';
import { Ico91, Ico92, Ico8, Ico97, Ico33, Ico42, Ico3 } from '@icons/new';
import { IcoCalculator, ArrowDownUp } from '@icons';

const Modelling = () => {
  const [subactiveId, subSetActiveId] = useState('ia-0');

  const subToggleActive = (id) => {
    if (subactiveId === id) {
      subSetActiveId(null);
    } else {
      subSetActiveId(id);
    }
  };

  return (
    <ul className='menu-container admin-page'>
      <li className='menu-item'>
        <Accordion className='sub-accordion' defaultActiveKey='ia-1'>
          <Card className={subactiveId === 'ia-1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant='link'
                eventKey='ia-1'
                onClick={() => subToggleActive('ia-1')}
                className='panel-toggle'
                block>
                <div className='nav-ico'>
                  <Ico97 />
                </div>
                <div className='nav-text'>
                  <Trans code='A0492' />
                </div>
                <div className='arrow-ico'>
                  <ArrowDownUp />
                </div>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey='ia-1'>
              <Card.Body>
                <ul className='menu-container'>
                  <li className='menu-item'>
                    {/* <Link to="/modelling/datasets">
                                 <span className={(window.location.pathname===('/modelling/datasets'))? 'menu-link active' : 'menu-link'}>
                                    <div className="nav-ico"><Ico42 /></div>
                                    <div className="nav-text"><Trans code="A0483"/></div> {/* Datasets /*}
                                 </span>
                           </Link> */}
                    <Link to='/modelling/expenseitems'>
                      <span
                        className={
                          window.location.pathname === '/modelling/expenseitems'
                            ? 'menu-link active'
                            : 'menu-link'
                        }>
                        <div className='nav-ico'>
                          <IcoCalculator />
                        </div>
                        <div className='nav-text' style={{ whiteSpace: 'inherit' }}>
                          <Trans code='A0484' />
                        </div>{' '}
                        {/* Voci di spesa */}
                      </span>
                    </Link>
                    <Link to='/modelling/kpi'>
                      <span
                        className={
                          window.location.pathname === '/modelling/kpi'
                            ? 'menu-link active'
                            : 'menu-link'
                        }>
                        <div className='nav-ico'>
                          <Ico91 />
                        </div>
                        <div className='nav-text'>
                          <Trans code='C00501' />
                        </div>{' '}
                        {/* Kpi */}
                      </span>
                    </Link>
                    <Link to='/modelling/interventiontypes'>
                      <span
                        className={
                          window.location.pathname === '/modelling/interventiontypes'
                            ? 'menu-link active'
                            : 'menu-link'
                        }>
                        <div className='nav-ico'>
                          <Ico92 />
                        </div>
                        <div className='nav-text'>
                          <Trans code='A0710' />
                        </div>{' '}
                        {/* Kpi */}
                      </span>
                    </Link>
                  </li>
                  {/* <li className="menu-item">
                           <Link to="/scenari" className={window.location.pathname.match(/.+esg\/?$/gm) ? 
                              'menu-link active disabled-link' : 'menu-link disabled-link'}>
                              <div className="nav-ico"><Ico8 className="disabled-svg" /></div>
                              <div className="nav-text"><Trans code="A0469" /></div>
                           </Link>
                        </li> */}
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </li>
      <li className='menu-item'>
        <Link to='/modelling/classes'>
          <span
            className={
              window.location.pathname === '/modelling/classes' ? 'menu-link active' : 'menu-link'
            }>
            <div className='nav-ico'>
              <Ico8 />
            </div>
            <div className='nav-text'>
              <Trans code='A0382' />
            </div>{' '}
            {/* Classi */}
          </span>
        </Link>
      </li>
      <li className='menu-item'>
        <Link to='/modelling/dataset'>
          <span
            className={
              window.location.pathname === '/modelling/dataset' ? 'menu-link active' : 'menu-link'
            }>
            <div className='nav-ico'>
              <Ico33 />
            </div>
            <div className='nav-text'>
              <Trans code='A0543' />
            </div>{' '}
          </span>
        </Link>
      </li>
      <li className='menu-item'>
        <Link to='/modelling/income-taxes'>
          <span
            className={
              window.location.pathname === '/modelling/income-taxes'
                ? 'menu-link active'
                : 'menu-link'
            }>
            <div className='nav-ico'>
              <Ico42 />
            </div>
            <div className='nav-text'>
              <Trans code='A0771' />
            </div>
          </span>
        </Link>
      </li>
      <li className='menu-item'>
        <Link to='/modelling/unemployment'>
          <span
            className={
              window.location.pathname === '/modelling/unemployment'
                ? 'menu-link active'
                : 'menu-link'
            }>
            <div className='nav-ico'>
              <Ico3 />
            </div>
            <div className='nav-text' style={{ whiteSpace: 'inherit', marginLeft: '8px' }}>
              <Trans code='A0772' />
            </div>
          </span>
        </Link>
      </li>
      <li className='menu-item'>
        <Link to='/modelling/corporateclasses'>
          <span
            className={
              window.location.pathname === '/modelling/corporateclasses'
                ? 'menu-link active'
                : 'menu-link'
            }>
            <div className='nav-ico'>
              <Ico8 />
            </div>
            <div className='nav-text' style={{ whiteSpace: 'inherit' }}>
              {/* <Trans code='A0772' /> */}
              Classi D'azienda
            </div>
          </span>
        </Link>
      </li>
    </ul>
  );
};
export default Modelling;
