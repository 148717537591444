import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import {
  IcoCantiere,
  IcoAnalisys,
  IcoFatturato,
  IcoEnterprise,
  IcoTax,
  IcoHome,
  IcoBag,
  IcoHeart,
} from '@icons';
import { Ico35 } from '@icons/new';
import { getAuthUser, getScenari, getScenario, getScenarioMeta } from '@lib/api';
import i18n, { Trans } from '@lib/i18n';
import Tooltip from '@lib/tooltip';
import Currency from '@lib/currency';
import { rounder } from '@lib/helpers';

const ekpiGroups = [
  {
    label: 'A0106',
    ico: <IcoCantiere />,
    ekpi: ['EKPI-1'],
  },
  {
    label: 'A0137',
    ico: <IcoAnalisys />,
    ekpi: [
      'EKPI-0-1',
      'EKPI-0-2',
      'EKPI-0-3',
      'EKPI-0-4',
      'EKPI-0-5',
      'EKPI-2',
      'EKPI-3',
      'EKPI-4',
      'EKPI-5',
      'EKPI-6',
      'EKPI-7',
      'EKPI-8',
    ],
  },
  {
    label: 'Fatturato',
    ico: <IcoFatturato />,
    ekpi: ['EKPI-9'],
  },
  {
    label: 'A0224',
    ico: <IcoEnterprise />,
    ekpi: ['EKPI-10'],
  },
  {
    label: 'Tax',
    ico: <IcoTax />,
    ekpi: ['EKPI-11', 'EKPI-12'],
  },
  {
    label: 'A0227',
    ico: <IcoHome />,
    ekpi: ['EKPI-13'],
  },
  {
    label: 'A0052',
    ico: <IcoBag />,
    ekpi: ['EKPI-14'],
  },
  {
    label: 'A0228',
    ico: <IcoHeart />,
    ekpi: ['EKPI-15', 'EKPI-16', 'EKPI-17'],
  },
];

const EkpiGroup = ({ label, ico, ekpi, data }) => {
  //console.log('label', label);
  //console.log('ico', ico);
  //console.log('ekpi', ekpi);
  console.log('data', data);

  return (
    <>
      <tr className='sub-table-title'>
        <td className='value-label'>{ico}</td>
        <td colSpan='100%' className='value-label desc'>
          <Trans code={label} />
        </td>
      </tr>

      {ekpi.map((ekpi, i) => {
        if (data[0] && data[0][ekpi])
          return (
            <tr key={'ekpi' + i}>
              <td className='value-label'>{ekpi}</td>
              <td className='value-label desc'>
                <Trans code={data[0][ekpi].indicatore} />
              </td>
              {data.map((d, index) => {
                return (
                  <td key={'d' + index} className='value-label strong text-center'>
                    <div className='text-right'>
                      {isNaN(d[ekpi]?.val) ? '-' : rounder(d[ekpi].val)}
                    </div>
                  </td>
                );
              })}

              {data[0][ekpi].udm ? (
                <td className='value-label text-center'>{data[0][ekpi].udm}</td>
              ) : (
                <td className='value-label'>M {Currency.currency}</td>
              )}
              {/*<td ><Trans code={data[0][ekpi].descrizione} /></td>
            <td >-</td> */}
            </tr>
          );
      })}
    </>
  );
};

const EconomicKPI = () => {
  const [user, setUser] = useState(null);
  const [scenari, setScenari] = useState([]);
  const [optionsScenari, setOptionsScenari] = useState([]);
  const [mainScenario, setMainScenario] = useState([]);
  const [scenariCompare, setScenariCompare] = useState([]);
  const [optionsOtherScenari, setOptionsOtherScenari] = useState([]);
  const [ekpi, setEkpi] = useState([]);
  const [scenarioTrad, setScenarioTrad] = useState({});
  const [selectedScenari, setSelectedScenari] = useState([]);
  //console.log("selectedScenari:", selectedScenari);
  //console.log("ekpi:", ekpi);
  //console.log("scenari:", scenari);
  //console.log("scenarioTrad:", scenarioTrad);

  useEffect(() => {
    getAuthUser().then(({ user }) => {
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
    });

    getScenari().then((retrievedScenari) => {
      setScenari(retrievedScenari);
    });
  }, []);

  useEffect(() => {
    if (user) {
      //solo scenari che hanno girato almeno una volta (publish) o che appartengono alla stessa organizzazione dell'utente
      //se OE (id "1") tutti i publish, se altra org solo suoi publish (1) e pubblici (access = 1)
      const filtredScenari = scenari
        .filter((scen) =>
          parseInt(user.idOrganization) === 1
            ? parseInt(scen.publish) === 1
            : parseInt(scen.publish) === 1 &&
              (scen.company === user.organization || parseInt(scen.access) === 1)
        )
        .map((scen) => ({
          value: scen.idScenario,
          label: scen.scenario_name,
        }));

      setOptionsScenari(filtredScenari);

      //setOptionsOtherScenari(filtredScenari);
    }
  }, [user, scenari]);

  useEffect(() => {
    if (Object.values(mainScenario).length > 0) {
      setOptionsOtherScenari(optionsScenari.filter((scen) => scen.value !== mainScenario.value));
    }
  }, [mainScenario, optionsScenari]);

  useEffect(() => {
    setEkpi([]);

    if (selectedScenari.length > 0) {
      selectedScenari.forEach((scen) => {
        getScenario(scen.value).then((retrievedScenario) => {
          setEkpi((prevState) => [...prevState, ...[retrievedScenario.economicKpi]]);
        });
      });
    }
  }, [selectedScenari]);

  /* useEffect(() => {
      getEkpi();
   }, [scenariCompare]);

   const getEkpi = async (arrayOfScenari) => {
      console.log('arrayOfScenari', arrayOfScenari);

      const arrayOfValues = await arrayOfScenari.map(scen => 
         getScenario(scen.value).then(retrievedScenario => retrievedScenario.economicKpi)
      );

      console.log('arrayOfValues', arrayOfValues);
   }; */

  const onChange = (e, fieldName, setSelect) => {
    //console.log('onChange e', e);

    if (fieldName === 'compareWith') {
      setMainScenario(e ?? []);
      setScenariCompare([]);
      setSelectedScenari([e] ?? []);
    } else {
      setScenariCompare(e ?? []);

      if (e) {
        const tempSelectedScenari = [].concat(mainScenario).concat(e);
        setSelectedScenari(tempSelectedScenari);
      } else {
        setSelectedScenari([mainScenario]);
      }
    }
  };

  /* const setScenario = () => {
      getScenario(idScenario)
         .then( s => {
            let trad = {}; 
            trad.label=s.idScenario; 
            trad.tooltip=s.general.scenario_name;
            setScenarioTrad(trad); 
            console.log('this s',s)
            return s   
         })
         .then(({ economicKpi }) => {
            let ekpiArr = [...ekpi];
            ekpiArr[0] = economicKpi
            setEkpi(ekpiArr);   
         })

      getScenarioMeta(idScenario)
         .then(setMainScenario)
   } */

  /* const compareWith = (sToCompare) => { 
   
      console.log("e",sToCompare)
      setScenariCompare(sToCompare)
      //CODICE NON OTTIMIZZATO
      let ekpiArr = [...ekpi];
      let newEkpiArr= []; 
      newEkpiArr[0] =  ekpiArr[0] 
      setEkpi(newEkpiArr);

      if (sToCompare && sToCompare.length>0 ) {
      sToCompare.map( s => {
         getScenario(s.value)
         .then( s => {console.log('s s',s); return s;})
         .then(({ economicKpi }) => {
            if(Object.keys(economicKpi).length > 1 ) {
            
               setEkpi(ekpi => [...ekpi,economicKpi]); 
            } else {
            //Dobbiamo costruire un oggetto uguale ma con i valori impostati a a n/a
               let newEkpi = {}
               Object.keys(ekpi[0]).forEach( k => {
               newEkpi[k]={...ekpi[0][k]}; //spread ... shallow copy per evitare referenze 
               
               newEkpi[k].val = "n/a";

               })
               
               setEkpi(ekpi => [...ekpi,newEkpi]); 
            }
            
         })
   
      })
   }
   console.log("ekpi",ekpi)
   
   }; */

  /* useEffect(() => {
      if (idScenario) {
         setScenario()
         i18n.on('languageChanged', setScenario)
         Currency.on('currencyChanged', setScenario)
         return function clenup() {
         i18n.off('languageChanged', setScenario)
         Currency.off('currencyChanged', setScenario)
         }
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps

      
   }, []) */

  if (!ekpi) return null;

  return (
    <div id='economic-kpi' className='megaContent'>
      <div className='container-fluid'>
        <div className='simple-box'>
          <div className='row align-items-center'>
            <div className='col-4'>
              <Select
                name='compareWith'
                options={optionsScenari}
                placeholder='Select scenario...'
                value={mainScenario}
                onChange={(e) => onChange(e, 'compareWith', setMainScenario)}
              />
            </div>
            <div className='col-2'>
              <Trans code='A0480' />
            </div>
            <div className='col-6'>
              <Select
                isMulti
                name='comparedWith'
                options={optionsOtherScenari}
                placeholder='Select some scenarios...'
                value={scenariCompare}
                isDisabled={Object.values(mainScenario).length === 0 ? true : false}
                onChange={(e) => onChange(e, 'comparedWith', setScenariCompare)}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <Card>
              <Card.Body>
                <Ico35 className='logo-card ico-box-blue' />
                <Card.Title>
                  <Trans code='A0205' />
                </Card.Title>
                <table className='investment-table kpi-table'>
                  <thead>
                    <tr>
                      <th className='value-label'>Codice</th>
                      <th className='value-label'>
                        <Trans code='A0135' />
                      </th>
                      {/* <th className="value-label text-right"> */}
                      {/* <Trans code="A0137" /> */}
                      {/* <Tooltip trad={JSON.stringify(scenarioTrad)}></Tooltip></th> */}

                      {selectedScenari.length > 0 &&
                        selectedScenari.map((s) => {
                          let trad = {};
                          trad.label = s.value;
                          trad.tooltip = s.label;

                          return (
                            <th key={s.value} className='value-label text-right'>
                              <Tooltip trad={JSON.stringify(trad)}></Tooltip>
                            </th>
                          );
                        })}

                      <th className='value-label text-center'>
                        <Trans code='A0170' />
                      </th>
                      {/*<th className="value-label"><Trans code="A0345" /></th>
                                 <th><Trans code="A0223" /></th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {ekpiGroups.map((group, index) => (
                      <EkpiGroup key={index} {...group} data={ekpi} />
                    ))}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomicKPI;
