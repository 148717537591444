import React, { useState, useEffect } from 'react';
import DashboardSroiImpact from 'components/scenariodashboard/sroiAnalysis/dashboardSroiImpact';
import DashboardSroiOutcomes from 'components/scenariodashboard/sroiAnalysis/dashboardSroiOutcomes';
import SroiKpiTable from 'components/scenariodashboard/sroiAnalysis/SroiKpiTable';
import RegionDropdown from '../sroiAnalyses/RegionDropdown';
import { getDatasetRegions } from '@lib/api';
import { propTrans } from '@lib/i18n';
import { Button } from 'react-bootstrap';
import { transition } from 'd3';
import DashboardSroiTotalsSelector from 'components/scenariodashboard/sroiAnalysis/dashboardSroiTotalsSelector';
import { Trans } from '@lib/i18n/index';

const SroiAnalysesPage = ({ scenario, dataVariant, currencyToUse }) => {
  const [optionsDatasetRegions, setOptionsDatasetRegions] = useState([]);
  const [filterForRegion, setFilterForRegion] = useState('');

  const [dataKpis, setDataKpis] = useState([]);
  const [dataSyntheticResults, setDataSyntheticResults] = useState([]);
  const [variant, setVariant] = useState(dataVariant);
  useEffect(() => {
    if (filterForRegion === 'total' || filterForRegion === '') {
      setDataKpis(variant?.analysisResults?.SROI?.kpis);
      setDataSyntheticResults(variant?.analysisResults?.SROI?.syntheticResults);
    } else {
      const regionExistsKpis =
        variant?.analysisResults?.SROI?.kpisByRegion.hasOwnProperty(filterForRegion);

      const regionExistsSyntheticResults =
        variant?.analysisResults?.SROI?.syntheticResultsByRegion.hasOwnProperty(filterForRegion);

      if (!regionExistsKpis || !regionExistsSyntheticResults) {
        setFilterForRegion('total');
        return;
      }

      setDataKpis(variant?.analysisResults?.SROI?.kpisByRegion[filterForRegion]);

      setDataSyntheticResults(
        variant?.analysisResults?.SROI?.syntheticResultsByRegion[filterForRegion]
      );
    }
  }, [filterForRegion, variant]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDatasetRegions(variant.datasetId);
        if (response && response.data) {
          const regionCodes = Object.keys(variant?.analysisResults?.SROI?.syntheticResultsByRegion);

          const filteredRegions = response.data
            .filter((region) => region.code !== 'ROW' && regionCodes.includes(region.code))
            .map((region) => ({
              value: region.code,
              label: region.name,
              position: region.position,
            }));

          setOptionsDatasetRegions([
            {
              value: 'total',
              label: propTrans({ prop: 'A0689' }),
              position: -1,
            },
            ...filteredRegions,
          ]);
          setFilterForRegion('total');
        } else {
          setOptionsDatasetRegions([
            {
              value: 'total',
              label: propTrans({ prop: 'A0689' }),
              position: -1,
            },
          ]);
          setFilterForRegion('total');
        }
      } catch (error) {
        console.error('Si è verificato un errore durante il recupero delle regioni:', error);
      }
    };
    fetchData();
  }, [variant]);

  console.log('datasynthetic', dataSyntheticResults);
  return (
    <div id='summaryDashboard' className='pt-0'>
      <div className='container-fluid'>
        {dataVariant?.totals && (
          <DashboardSroiTotalsSelector
            dataVariant={dataVariant}
            variant={variant}
            setVariant={setVariant}
            setDataKpis={setDataKpis}
          />
        )}

        <DashboardSroiImpact scenario={scenario} variant={variant} />
        <div className='row'>
          <div className='col'>
            <DashboardSroiOutcomes
              syntheticResults={dataSyntheticResults}
              currencyToUse={currencyToUse}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <SroiKpiTable data={dataKpis} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SroiAnalysesPage;
