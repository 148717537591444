import { useMemo, useState, Fragment, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import { scaleThreshold } from 'd3';
import chroma from 'chroma-js';
import { LineChart } from 'components/CountryProfileComponents/LineChart/LineChart';
import Search from '../../../components/CountryProfileComponents/Search/Search';
import { useJSON } from '../../../components/CountryProfileComponents/useJSON';
import indicatorsUrl from '../../../components/CountryProfileComponents/data/oeindicators.json';
import dataUrl from '../../../components/CountryProfileComponents/data/indicators-2023-10-20.json';
import mapUrl from './geo/custom.geo.json';
// import regionsUrl from '../../../components/CountryProfileComponents/data/oeregions-2023-11-09.json';
import ModalOverlay from '../../../components/ModalOverlay/ModalOverlay';
import TableHeader from '../../../components/CountryProfileComponents/TableHeader/TableHeader';
import style from './CountryProfilePage.module.css';
import Breadcrumbs from '@components/CountryProfileComponents/Breadcrumbs/Breadcrumbs';
import Table from '../../../components/CountryProfileComponents/Table/index';
import chart from '../../../components/CountryProfileComponents/TableExample/chart.svg';
import chartSelected from '../../../components/CountryProfileComponents/TableExample/chart-selected.svg';
import CustomSelectNew from 'components/CountryProfileComponents/CustomSelect/CustomSelectNew';
import {
  getOERegions,
  getOEIndicators,
  getFullDataIndicators,
  getMapIndicators,
} from '@lib/api/index';
// import ChoroplethMap from '../../../components/CountryProfileComponents/ChoroplethMapNew/index';
import ChoroplethMap from '../../../components/CountryProfileComponents/ChoroplethMapNew/ChoroplethMap';
import tooltip from '../../../components/CountryProfileComponents/MapTooltip/index';

const world = { properties: { admin: 'World' } };
const numberFormat = (v) =>
  v
    ? new Intl.NumberFormat('it-IT', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(v /* Number(v.replace(',', '.')) */)
    : 'N.D.';

const formatKMNumber = (num, precision = 0) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
};

const columns = [
  {
    title: 'Indicators',
    accessor: 'indicator',
    /* width: '94px', */
    description: (row) => (
      <Fragment>
        <strong>{row.indicator}</strong>
        <br />
        {row.description}
      </Fragment>
    ),
  },
  {
    title: 'Value',
    accessor: 'value',
    format: numberFormat,
    alignment: 'right',
    sort: (a, b) => a - b /* Number(a.replace(',', '.')) - Number(b.replace(',', '.')) */,
    /* width: '62px', */
  },
  {
    title: 'UOM',
    accessor: 'uom',
    // width: '82px',
    alignment: 'left',
  },
  {
    title: 'Source',
    accessor: 'source',
    filterable: true,
    defaultFilter: ['World Bank'],
    /* width: '148px', */
  },
];

// const years = [].concat(new Array(13).fill(0).map((_, i) => 2022 - i));
// const years = [...new Set(dataUrl.map((item) => item.year))].sort((a, b) => b - a);

const CountryProfilePage = () => {
  const [indicator, setIndicator] = useState('exp');
  const [feature, setFeature] = useState(world);
  const [lineChartIsModal, setLineChartIsModal] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [year, setYear] = useState(2022);
  const [regionCodes, setRegionCodes] = useState([]);
  const [myTableData, setMyTableData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [clickOnMap, setClickOnMap] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapLoading, setIsMapLoading] = useState(false);
  const geodata = mapUrl;
  const region = useMemo(() => feature?.properties?.admin, [feature]);
  const boxRef = useRef(null);

  const years = useMemo(() => {
    if (!myTableData) {
      return [];
    }

    return [...new Set(myTableData.map((item) => item.year))].sort((a, b) => b - a);
  }, [myTableData]);

  useEffect(() => {
    if (years.length > 0) {
      setYear(years[0]);
    }
  }, [years]);

  const indicatorsCodes = useMemo(() => {
    return indicators?.reduce(
      (prev, { code, name, uom, description }, i) => ({
        ...prev,
        [code]: { name, uom, order: i, description },
      }),
      {}
    );
  }, [indicators]);
  console.log('indicators', indicators);
  useEffect(() => {
    Promise.all([getOERegions(), getOEIndicators()]).then(([regionsRes, indicatorsRes]) => {
      setRegionCodes(regionsRes.data);
      setIndicators(indicatorsRes.data);
    });
  }, []);

  const regionCodesMap = useMemo(
    () => regionCodes?.reduce((prev, next) => ({ ...prev, [next.name]: next.code }), {}),
    [regionCodes]
  );
  console.log('regionnss', regionCodesMap['World']);
  useEffect(() => {
    setIsLoading(true);
    getFullDataIndicators(regionCodesMap[region])
      .then((res) => {
        setMyTableData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [regionCodesMap, region]);

  useEffect(() => {
    setIsMapLoading(true);
    getMapIndicators(year)
      .then((res) => {
        setMapData(res.data);
      })
      .finally(() => {
        setIsMapLoading(false);
      });
  }, [year]);

  const tableData = useMemo(() => {
    if (!myTableData) return [];
    const filtered = myTableData.filter((item) => {
      return item.year === year && item.regionCode === regionCodesMap[region];
    });

    return filtered
      ?.map((item) => ({
        ...item,
        indicator: indicatorsCodes[item.indicatorCode]?.name || '',
        uom: indicatorsCodes[item.indicatorCode]?.uom || '',
        description: indicatorsCodes[item.indicatorCode]?.description || '',
      }))
      .sort((a, b) => {
        return indicatorsCodes[a.indicatorCode]?.order - indicatorsCodes[b.indicatorCode]?.order;
      });
  }, [myTableData, regionCodesMap, year, region, indicatorsCodes]);

  const lineChartData = useMemo(() => {
    if (!myTableData) return [];
    const filtered = myTableData?.filter((item) => {
      return item.regionCode === regionCodesMap[region] && item.indicatorCode === indicator;
    });
    const res = {};
    filtered?.forEach((item) => {
      if (!res[item.source]) res[item.source] = [{ year: item.year, value: item.value }];
      else res[item.source].push({ year: item.year, value: item.value });
    });

    return Object.keys(res).map((key) => ({ serie: key, data: res[key] }));
  }, [myTableData, regionCodesMap, region, indicator]);

  const clickMapHandler = (f) => {
    if (!f) {
      setFeature(world);
      setClickOnMap(false);
    } else if (region === f.properties.admin) {
      setFeature(world);
      setClickOnMap(false);
    } else {
      setFeature(f);
      setClickOnMap(true);
    }
  };

  const breadcrumbs = [{ title: 'World', url: '#' }].concat(
    region !== 'World' ? [{ title: region, url: '#' }] : []
  );

  // const mybins = [0, 0.1, 0.2, 0.25, 0.35, 0.4, 1];
  const mybins = [0, 0.00011, 0.00045, 0.00085, 0.0025, 0.008, 0.04, 1];

  const max = Math.max(
    ...mapData
      ?.filter((item) => item.indicatorCode === 'gdp' && item.regionCode.includes('-'))
      .map((el) => el.value)
  );

  const color = scaleThreshold()
    .domain(mybins.map((value) => value * max))
    // .range(chroma.scale(['#08a2aa', '#1c2743']).colors(20));
    .range(chroma.scale(['#27aeb5', '#2d3751']).colors(8));

  const fillColor = ({ properties: { admin } }) => {
    if (region === 'World') {
      const val = mapData?.find(
        (item) =>
          item.year === year &&
          item.regionCode === regionCodesMap[admin] &&
          item.indicatorCode === 'gdp'
      )?.value; /* ?.replace(',', '.') */
      return val ? color(val) : '#c7c7c7';
    } else if (admin === region) return '#1c2743';
    else return '#fff';
  };

  const handleSearchChange = (val) => {
    const found = geodata?.features.find(
      (item) => item.properties.admin.toLowerCase() === val.toLowerCase()
    );
    if (found) setFeature(found);
  };

  const showTooltip = ({ properties: { admin } }) => {
    const originalData = mapData?.filter(
      (item) => item.year === year && item.regionCode === regionCodesMap[admin]
    );
    console.log('originalData', originalData);
    if (!originalData || originalData.length === 0) {
      // Handle the case when originalData is empty
      return null; // or any other appropriate action
    }
    console.log('indicatorCodes', indicatorsCodes);
    const arr = originalData.map(({ indicatorCode, value }) => ({
      title: indicatorsCodes[indicatorCode]?.name || '',
      value: numberFormat(value),
    }));
    console.log('arr', arr);
    arr.push({ title: 'Source', value: originalData[0]?.source });
    arr.push({ title: 'Year', value: originalData[0]?.year });

    // return tooltip(admin, [
    //   ...arr?.slice(0, 3),
    //   arr.find((el) => el.title.toLowerCase() === 'source'),
    //   arr.find((el) => el.title.toLowerCase() === 'year'),
    // ]);
    return tooltip(admin, [
      ...arr?.slice(0, 3),
      arr.find((el) => el?.title.toLowerCase() === 'source'),
      arr.find((el) => el?.title.toLowerCase() === 'year'),
    ]);
  };
  return (
    <div id='dataset' className='megaContent p-0'>
      <div
        style={{
          position: 'relative',
          width: '100vw',
          height: '80vh',
          // maxHeight: '1000px',
          minHeight: '768px',
          top: '0px',
          left: '0px',
        }}
        ref={boxRef}>
        <div className={style.wrapper}>
          <div className={style.container}>
            {showMap && (
              <div className={`${style.searchWrapper} mr-4`}>
                <Search
                  items={geodata?.features.map((v) => v.properties?.admin)}
                  handler={handleSearchChange}
                  setClickOnMap={setClickOnMap}
                />
              </div>
            )}

            <div className={style.mapContainer}>
              <ChoroplethMap
                geodata={geodata}
                enlargeFeature={feature.properties.admin === 'World' ? undefined : feature}
                fillColor={fillColor}
                hoverFillColor={!clickOnMap ? '#1c2743' : '#c2c923'}
                onClick={clickMapHandler}
                renderTooltip={showTooltip}
                padding={{
                  top: 0,
                  left: 566,
                  bottom: 50,
                  right: 50,
                }}
                clickOnMap={clickOnMap}
                // TODO
                // centroidOffset={[500 - 28, 180]}
                centroidOffset={[500 + 50, 180]}
                // viewBox={[0, -300]}
                isLoading={isMapLoading}
              />
            </div>

            <div className={style.tableContainer} style={{ minWidth: '435px' }}>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center justify-content-between'>
                  <h1 className={style.pageHeader}>COUNTRY PROFILE</h1>
                  <CustomSelectNew
                    onChange={setYear}
                    colors={['#FDDFAA', '#F4B644']}
                    value={year}
                    dataList={years}
                  />
                </div>
                {/* ORIGINAL */}
                {/* <Breadcrumbs paths={breadcrumbs}/> */}
                {/* MODIFIED */}
                <Breadcrumbs
                  paths={breadcrumbs}
                  setState={setFeature}
                  setClickState={setClickOnMap}
                  world={world}
                />
                {/* <TableHeader
                  title={region}
                  handler={() => setShowMap(!showMap)}
                  active={!showMap}
                /> */}
              </div>
              <div>
                <ModalOverlay
                  style={{
                    maxWidth: '1200px',
                    minWidth: '435px',
                    height: 'clamp(345px, 40vh, 435px)',
                    marginTop: '30px',
                  }}>
                  <Table
                    columns={columns}
                    data={tableData}
                    actionIcon={chart}
                    actionIconSelected={chartSelected}
                    scrollable
                    handler={(v) => setIndicator(v?.indicatorCode)}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    isLoading={isLoading}
                    // details={() => <img alt='TODO' src={tableDetails} style={{ width: '100%' }} />}
                  />
                </ModalOverlay>
                <ModalOverlay
                  style={{
                    minWidth: '435px',
                    width: '793px',
                    height: 'clamp(162px, 10vh, 262px)',
                    marginTop: '20px',
                  }}
                  modalWidth='812px'
                  modalHeight='290px'
                  onModal={(v) => setLineChartIsModal(v)}
                  // forceUnmount
                >
                  <LineChart
                    title={indicatorsCodes ? indicatorsCodes[indicator]?.name : ''}
                    series={lineChartData}
                    colors={['#C3CA23', '#BA314D', '#09A2A9', '#F9CAC8']}
                    formatYTick={formatKMNumber}
                    formatXTick={(v) => (lineChartIsModal ? v : v - 2000)}
                    padding={{ top: lineChartIsModal ? 30 : 25, right: 0, bottom: 15, left: 35 }}
                    titleSize={lineChartIsModal ? 18 : 14}
                    isLoading={isLoading}
                  />
                </ModalOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryProfilePage;
