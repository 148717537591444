import { Trans } from '@lib/i18n/index';
import StatusBar from 'components/wizard/StatusBar';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteCorporateEsgAnalysis } from '@lib/api/index';
const CorporateEsgAnalysesModalDelete = ({
  showModalDelete,
  setShowModalDelete,
  companyId,
  analysisId,
  setRefresh,
}) => {
  const [sbDeleteShow, setSbDeleteShow] = useState(null);
  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleDeleteStatusBar = (status, message, timeout) => {
    setSbDeleteShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleDelete = async (companyId, analysisId) => {
    if (!companyId || !analysisId) return;
    // console.log('CANCELLO', companyId, analysisId);
    const deleteResult = await deleteCorporateEsgAnalysis(companyId, analysisId);

    if (deleteResult.status === true) {
      handleDeleteStatusBar('success', <Trans code='A0749' />, true);

      setTimeout(() => {
        setShowModalDelete(false);
        setSbDeleteShow(null);
        setRefresh(true);
      }, 2000);
    } else {
      handleDeleteStatusBar('error', <Trans code='A0750' />, false);
    }
  };

  return (
    <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans code='A0515' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='my-3'>
          <span>
            <Trans code='A0526' />
          </span>
        </div>
        {sbDeleteShow}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
          <Trans code='F0057' />
        </Button>
        <Button variant='danger' onClick={() => handleDelete(companyId, analysisId)}>
          <Trans code='F00056' />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CorporateEsgAnalysesModalDelete;
