import React, { useState, useEffect } from 'react';
import StepSubGeneral from '@components/wizard/stepBuild/stepSubGeneral';
import StepSubDataset from 'components/wizard/stepBuild/stepSubDataset';
import StepSubKpi from 'components/wizard/stepBuild/stepSubKpi';
import { StepExecute } from 'components/wizard/index';
import CustomSpinner from 'components/CustomSpinner';
import StepSubEsg from 'components/wizard/stepBuild/stepSubEsg/stepSubEsg';
const LayerStepCompact = ({ scen, setScen, originScenario, isLoading, setVariantsRefresh }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const subStepName = urlParams.get('sub') ?? 'general';
  const [scenarioData, setScenarioData] = useState({});

  const [errorDataset, setErrorDataset] = useState({ type: '', message: '' });

  useEffect(() => {
    setScenarioData(scen);
  }, [scen]);

  // useEffect(() => {
  //   const failedResult = scen.elaborationResults.find((result) => !result.status);
  //   if (failedResult) {
  //     setErrorDataset({
  //       type: failedResult.type,
  //       message: failedResult.message,
  //     });
  //   }
  // }, []);
  console.log('scenarioData', scenarioData);
  if (scenarioData) {
    return (
      <>
        <div id='wizard' className='scenari megaContent px-0'>
          {isLoading ? (
            <div className='container-fluid'>
              <CustomSpinner style={{ minHeight: '100px' }} />
            </div>
          ) : (
            <div className='container-fluid px-0'>
              {scenarioData && subStepName === 'general' && (
                <StepSubGeneral
                  scen={scenarioData}
                  setScen={setScen}
                  originScenario={originScenario}
                />
              )}

              {scenarioData && subStepName === 'esg' && <StepSubEsg />}

              {scenarioData && subStepName === 'dataset' && (
                <>
                  {errorDataset.type && (
                    <div id='statusbar'>
                      <div className='simple-box error'>
                        <h5 className='d-inline mr-2'>ERROR</h5>{' '}
                        {errorDataset.message ? (
                          <span>{errorDataset.type + ' - ' + errorDataset.message}</span>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <StepSubDataset
                    elaborationResultsHasError={errorDataset.type}
                    scen={scenarioData}
                    setScen={setScen}
                    originScenario={originScenario}
                  />
                </>
              )}

              {scenarioData && subStepName === 'kpi' && (
                <StepSubKpi scenarioId={scenarioData.scenarioId} variantId={scenarioData.id} />
              )}
              {scenarioData && subStepName === 'execute' && (
                <StepExecute
                  scen={originScenario}
                  setScen={setScen}
                  variant={scenarioData}
                  setVariantsRefresh={setVariantsRefresh}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
};
export default LayerStepCompact;
