// import React from 'react';
// import { Trans } from '@lib/i18n';
// import { PictureIcon, DocumentIcon, LinkIcon } from '@icons/index';

// import ExternalLinkButton from './AttachmentButton';

// const AttachmentsCard = ({ attachments }) => {
//   const returnCorrectIcon = (elementType) => {
//     switch (elementType) {
//       case 'image':
//         return <PictureIcon className='ico-small subMenu-ico' />;
//       case 'file':
//         return <DocumentIcon className='ico-small subMenu-ico' />;
//       case 'link':
//         return <LinkIcon className='ico-small subMenu-ico' />;
//       default:
//         break;
//     }
//   };

//   return (
//     <>
//       {attachments && (
//         <div className='simple-box impact pb-5 dashboardBreaks'>
//           <div className='row'>
//             <div className='col'>
//               <h5 className='title-simple-box margin-title-box'>
//                 <Trans code='C00465' />
//               </h5>
//             </div>
//           </div>
//           {attachments.map((el, index) => (
//             <div key={index} className='row'>
//               <div className='col'>
//                 <ExternalLinkButton
//                   url={el.ref}
//                   buttonText={el.text ? el.text : ''}
//                   icon={returnCorrectIcon(el.type)}
//                 />
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </>
//   );
// };

// export default AttachmentsCard;

import React from 'react';
import { Trans } from '@lib/i18n';
import { PictureIcon, DocumentIcon, LinkIcon } from '@icons/index';

import ExternalLinkButton from './AttachmentButton';

const AttachmentsCard = ({ attachments, attachmentType }) => {
  const returnCorrectIcon = (elementType) => {
    switch (elementType) {
      case 'image':
        return <PictureIcon className='ico-small subMenu-ico' />;
      case 'file':
        return <DocumentIcon className='ico-small subMenu-ico' />;
      case 'link':
        return <LinkIcon className='ico-small subMenu-ico' />;
      default:
        break;
    }
  };
  const returnCorrectTranslation = (elementType) => {
    switch (elementType) {
      case 'image':
        return 'A0788';
      case 'file':
        return 'A0789';
      case 'link':
        return 'A0790';
      default:
        break;
    }
  };

  return (
    <>
      {attachments && (
        <div className='simple-box impact pb-5 dashboardBreaks attachmentOuterCard'>
          <div className='row'>
            <div className='col'>
              <h5 className='title-simple-box margin-title-box'>
                <span>
                  <Trans code='C00465' />: <Trans code={returnCorrectTranslation(attachmentType)} />
                </span>
              </h5>
            </div>
          </div>
          <div className='attachmentInnerCard'>
            {attachments
              .filter((attachment) => attachment.type === attachmentType)
              .map((el, index) => (
                <div key={index} className='row'>
                  <div className='col'>
                    <ExternalLinkButton
                      url={el.ref}
                      buttonText={el.text ? el.text : ''}
                      icon={returnCorrectIcon(el.type)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentsCard;
