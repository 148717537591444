import { Trans } from '@lib/i18n/index';
import CustomSpinner from 'components/CustomSpinner';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function UploadIcon(props) {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      // stroke='currentColor'
      stroke='#08a2aa'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
      <polyline points='17 8 12 3 7 8' />
      <line x1='12' x2='12' y1='3' y2='15' />
    </svg>
  );
}

const UploadItem = ({ setSelectedFile, isLoading }) => {
  return (
    <div>
      <Form.Group>
        <InputGroup className='mb-3'>
          <div>
            <div>
              <h3>
                <Trans code='A0579' />
              </h3>
              <p className='sub-color'>
                <Trans code='A0822' />
              </p>
            </div>
            <div className='d-flex'>
              <Form.Label className='w-100' htmlFor='file'>
                <div
                  className='d-flex align-items-center justify-content-between customUploadInput px-4 py-3'
                  style={{ position: 'relative', pointerEvents: isLoading ? 'none' : 'auto' }}>
                  <span>
                    <Trans code='A0823' />
                  </span>
                  <UploadIcon className='icon-upload-new' />
                  {isLoading && (
                    <CustomSpinner
                      style={{
                        position: 'absolute',
                        width: '90%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backdropFilter: 'blur(7px)',
                      }}
                    />
                  )}
                </div>
              </Form.Label>
              <input
                className='sr-only'
                id='file'
                type='file'
                onChange={(event) => setSelectedFile(event.target.files[0])}
                disabled={isLoading}
              />
            </div>
          </div>
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export default UploadItem;
