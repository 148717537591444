import React from 'react';
import style from './DropdownList.module.css';

function DropdownList(props) {
  if (props.items.length === 0) return null;

  return (
    <div className={style.container}>
      {props.items.map((c) => (
        <button className={style.listButton} key={c} onClick={() => props.handler(c)}>
          {c}
        </button>
      ))}
    </div>
  );
}

export default DropdownList;
