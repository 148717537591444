import { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import Select from 'react-select';
import { Row, Col, Form, Button, ListGroup, Modal } from 'react-bootstrap';
import { IcoPlus, IcoUploadFile } from '@icons';
// import { InfographicIcon } from 'icone/index';
import { Trans, propTrans } from '@lib/i18n';
import { getOELanguages } from '@lib/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import useConfirm from 'components/customHook/useConfirm/useConfirm';
import MarkdownEditComponent from 'components/MarkdownComponents/MarkdownEditComponent';
import { fetchData } from '@lib/helpers/index';
import StatusBar from '../StatusBar';

const AdditionalInfoSection = ({
  originAdditionalInfo,
  setScenAdditionalInfo,
  setScenLinks1,
  setScenFiles1,
  idScenario,
  saveButtonId = 'save-button',
}) => {
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [additionalInfoForEditor, setAdditionalInfoForEditor] = useState([]);
  const [scenLinks, setScenLinks] = useState([]);
  const [scenFiles, setScenFiles] = useState([]);
  const [langCode, setLangCode] = useState(null);
  const [linksFields, setLinksFields] = useState({ descr: '', ref: '' });
  const [filesFields, setFilesFields] = useState({ descr: '', ref: '' });
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [editState, setEditState] = useState(false);
  const currentLang = localStorage.getItem('externalyticsLanguage');
  const [sbShow, setSbShow] = useState(<></>);
  const inputRef = useRef(null);
  const { isConfirmed } = useConfirm();

  const regexPattern =
    /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)|)$/gim;

  // console.log('originAdditionalInfo', originAdditionalInfo);
  // console.log('additionalInfo:', additionalInfo);
  // console.log('scenLinks:', scenLinks);
  // console.log('scenFiles:', scenFiles);
  // console.log('linksFields:', linksFields);
  // console.log('filesFields:', originAdditionalInfo);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);
  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  useEffect(() => {
    let isMounted = true;

    if (originAdditionalInfo) {
      const filteredInfo = originAdditionalInfo.filter(
        (info) => info.topic !== 'link' && info.topic !== 'file' && info.topic !== 'image'
      );
      if (isMounted) {
        setAdditionalInfo(filteredInfo);
        setAdditionalInfoForEditor(filteredInfo);
        setScenLinks(originAdditionalInfo.filter((info) => info.topic === 'link'));
        setScenFiles(
          originAdditionalInfo.filter((info) => info.topic === 'file' || info.topic === 'image')
        );
      }
    }

    const setLangCodeFunc = (data) => {
      if (isMounted) {
        const langCode = data
          .filter((lang) => lang.short === currentLang)
          .map((lang) => lang.code)
          .pop();
        setLangCode(langCode);
      }
    };

    fetchData(getOELanguages, null, setLangCodeFunc, [], handleStatusBar);

    return () => {
      isMounted = false;
    };
  }, [currentLang, originAdditionalInfo]);

  const onChange = (e, topic, section, setState, state) => {
    console.log('e onChange', e);
    //(e, 'ref', 'files', setFields, fields)}

    let tempScenAdditionalInfo = [...additionalInfo];
    const index = tempScenAdditionalInfo.findIndex(
      (info) => info.topic === topic && info.lang === langCode
    );

    switch (section) {
      case 'description':
        if (index > -1 && tempScenAdditionalInfo[index].lang === langCode) {
          //esiste
          const obj = tempScenAdditionalInfo[index];
          tempScenAdditionalInfo[index] = Object.assign(obj, {
            lang: langCode,
            text: e === '<p></p>' ? '' : e,
            // text: e.target.value,
          });
        } else {
          //non esiste
          tempScenAdditionalInfo.push({
            topic: topic,
            type: 'text',
            lang: langCode,
            text: e === '<p></p>' ? '' : e,
            // text: e.target.value,
          });
        }

        if (idScenario) {
          const saveButton = document.getElementById(saveButtonId);
          saveButton.classList.add('pulse-button');
        }
        break;
      case 'links':
        setState(() => ({
          ...state,
          [topic]: e.target.value,
        }));
        break;
      case 'files':
        setState(() => ({
          ...state,
          [topic]: e.target ? e.target.value : e.value,
        }));
        break;
      case 'files-file':
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (event) => {
          setState(() => ({
            ...state,
            [topic]: e.target ? e.target.value : e.value,
            file: `data:${e.target.files[0].type};base64,${btoa(event.target.result)}`,
          }));
        };
        // const formData = new FormData();

        // formData.append('file', e.target.files[0], e.target.files[0].name);
        // console.log('formData', formData);
        // setState(() => ({
        //   ...state,
        //   [topic]: e.target ? e.target.value : e.value,
        //   file: formData,
        // }));
        break;
      default:
        break;
    }

    setAdditionalInfo(tempScenAdditionalInfo);
    setScenAdditionalInfo(tempScenAdditionalInfo);
  };

  const addFields = (arrayInfo, setArrayInfo, setArrayInfo1, fields, setFields, topic) => {
    let tempArrayInfo = [...arrayInfo];
    console.log('tempArrayInfo test:', tempArrayInfo);

    if (topic === 'link' || topic === 'file') {
      const alreadyExists = tempArrayInfo.some(
        (info) =>
          info.topic === topic &&
          info.type === topic &&
          info.lang === langCode &&
          info.ref === fields.ref
      );
      console.log('alreadyExists test:', alreadyExists);

      if (alreadyExists) {
        handleShowModal(setShowModalWarning);
        return;
      }
    }

    if (topic === 'link') {
      if (!regexPattern.test(fields.ref)) {
        setLinkError(true);
        return;
      } else {
        setLinkError(false);
      }
    }

    tempArrayInfo.push({
      topic: topic,
      type: topic,
      lang: langCode,
      text: fields.descr,
      ref: fields.ref,
      file: fields.file ? fields.file : null,
    });

    setArrayInfo(tempArrayInfo);
    setArrayInfo1(tempArrayInfo);

    setFields({ descr: '', ref: '' });

    if (topic === 'file') {
      inputRef.current.value = null;
    }

    if (idScenario) {
      const saveButton = document.getElementById(saveButtonId);
      saveButton.classList.add('pulse-button');
    }
  };

  const removeFields = async (
    arrayInfo,
    setArrayInfo,
    setArrayInfo1,
    idInfo,
    topic,
    ref,
    index
  ) => {
    let tempArrayInfo = [...arrayInfo];

    const confirmed = await isConfirmed(propTrans({ prop: 'A0577' }));

    if (confirmed) {
      if (idScenario) {
        const saveButton = document.getElementById(saveButtonId);
        saveButton.classList.add('pulse-button');
      }

      if (!idInfo) {
        //new info
        tempArrayInfo.splice(index, 1);
      } else {
        //old info
        tempArrayInfo = tempArrayInfo.filter((info) => info.id !== idInfo);
      }
      console.log('tempArrayInfo', tempArrayInfo);
      setArrayInfo(tempArrayInfo);
      setArrayInfo1(tempArrayInfo);
    }
  };

  const openFileUpload = () => {
    inputRef.current.click();
  };

  // const infographicTooltip = (props) => (
  //   <Tooltip {...props}>
  //     <Trans code='A0785' />
  //   </Tooltip>
  // );

  const DeleteSource = ({ arrayInfo, setArrayInfo, setArrayInfo1, info, topic, index }) => {
    return (
      <Button
        variant='link'
        className='bluicon ml-2'
        style={{ width: 'max-content', marginBottom: '0.15rem' }}
        onClick={() =>
          removeFields(arrayInfo, setArrayInfo, setArrayInfo1, info.id, topic, info.ref, index)
        }>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    );
  };

  const attachmentsSection = (
    arrayInfo,
    setArrayInfo,
    setArrayInfo1,
    fields,
    setFields,
    section,
    topic
  ) => {
    //scenFiles, setScenFiles, setScenFiles1, filesFields, setFilesFields, 'files', 'file'
    return (
      <>
        <Row className='mb-3'>
          <Col className='col-4'>
            <Row>
              <Form.Group as={Col} controlId='descr' className='pr-0'>
                <Form.Label>
                  <Trans code='A0345' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='descr'
                  className={topic === 'link' && 'border-radius-right-0 border-right-0'}
                  placeholder={propTrans({ prop: 'A0345' })}
                  value={fields.descr}
                  onChange={(e) => onChange(e, 'descr', section, setFields, fields)} //(e, topic, section, setState, state)
                />
              </Form.Group>
            </Row>
            <Row>
              {arrayInfo && arrayInfo.length > 0 ? (
                <ListGroup className='p-0 ml-3'>
                  {arrayInfo.map((info, index) => (
                    <Fragment key={index}>
                      <ListGroup.Item as={Col} key={index} className='px-3 py-2'>
                        <Row>
                          <Col>
                            {/* <Link
                              to={
                                topic === 'link'
                                  ? info.ref.match(/https?/gm)
                                    ? info.ref
                                    : `//${info.ref}`
                                  : getBaseUrl() + '/fileimg/' + info.ref
                              }
                              target='_blank'
                              rel='noreferrer'
                              className='text-link'>
                              {info.text}
                            </Link> */}
                            <Link
                              to='/'
                              target='_blank'
                              rel='noopener noreferrer'
                              className='text-link'
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(info.ref, '_blank');
                              }}>
                              {info.text}
                            </Link>
                            {/* {info.type === 'image' &&
                              index === arrayInfo.findIndex((item) => item.type === 'image') && (
                                <OverlayTrigger placement='top' overlay={infographicTooltip}>
                                  <InfographicIcon
                                    className='ml-3'
                                    style={{ fill: '#08a2aa', width: '17px', height: '17px' }}
                                  />
                                </OverlayTrigger>
                              )} */}
                          </Col>

                          <Col className='col-auto'>
                            <DeleteSource
                              info={info}
                              topic={topic}
                              index={index}
                              arrayInfo={arrayInfo}
                              setArrayInfo={setArrayInfo}
                              setArrayInfo1={setArrayInfo1}
                              className='bluicon'
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </Fragment>
                  ))}
                </ListGroup>
              ) : null}
            </Row>
          </Col>
          <Col className='col-5'>
            <Row>
              <Form.Group as={Col} controlId='ref' className='pl-0'>
                <Form.Label className={topic !== 'link' && 'ml-3'}>
                  <Trans code={section === 'links' ? 'A0573' : 'A0574'} />
                </Form.Label>
                {section === 'links' ? (
                  <>
                    <Form.Control
                      type='text'
                      name='ref'
                      className='border-radius-left-0'
                      placeholder={propTrans({ prop: 'A0575' })}
                      value={fields.ref}
                      onChange={(e) => onChange(e, 'ref', section, setFields, fields)}
                    />
                    {linkError && (
                      <p className='ml-2 invalid-link-input'>{<Trans code='A0787' />}</p>
                    )}
                  </>
                ) : (
                  <Row className='align-items-center'>
                    {/* <Col className='col-6'>
                      <Select
                        name='ref'
                        classNamePrefix='select-files'
                        value={tempOptionsFiles.filter((opt) => opt.value === filesFields.ref)}
                        options={[]} //add options
                        onChange={(e) => onChange(e, 'ref', section, setFields, fields)}
                      />
                    </Col> */}
                    <Col className='overflow-hidden pr-2 ml-3'>
                      {/* <span className='mr-2'>
                        <Trans code='A0578' />
                      </span> */}
                      <Button
                        variant='link'
                        title={propTrans({ prop: 'A0781' })}
                        onClick={() => openFileUpload()}>
                        <IcoUploadFile className='second-color ico-small mr-2 download-upload' />
                      </Button>
                      <input
                        type='file'
                        id='hiddenFile'
                        ref={inputRef}
                        className='position-absolute'
                        onChange={(e) =>
                          onChange(e, 'ref', 'files-file', setFilesFields, filesFields)
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Form.Group>
            </Row>
          </Col>
          <Col className='col-auto align-self-start'>
            <Button
              disabled={!Object.values(fields).every((el) => el !== '')}
              variant='link'
              style={{ marginTop: '2.25rem', height: 'fit-content' }}
              onClick={() =>
                addFields(arrayInfo, setArrayInfo, setArrayInfo1, fields, setFields, topic)
              }>
              <IcoPlus className='second-color ico-small indicators' />
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {/* DESCRIZIONE DELLO SCENARIO */}
      <div className='simple-box'>
        <Row>
          <Col lg={12} className='form-login'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0172' />
            </h5>
            <Row className='mb-3'>
              {/* <Form.Group as={Col} controlId='purpose'>
                <Form.Label>
                  <Trans code='A0086' />
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='purpose'
                  rows={3}
                  placeholder={propTrans({ prop: 'A0537' })}
                  value={additionalInfo
                    .filter((info) => info.topic === 'purpose' && info.lang === langCode)
                    .map((info) => info.text)}
                  onChange={(e) => onChange(e, 'purpose', 'description')}
                />
              </Form.Group> */}
              <MarkdownEditComponent
                titleCode='A0086'
                placeholder={propTrans({ prop: 'A0537' })}
                onChange={onChange}
                name='purpose'
                type='description'
                existingContent={
                  additionalInfo
                    .filter((info) => info.topic === 'purpose' && info.lang === langCode)
                    .map((info) => info.text)[0]
                }
                langCode={langCode}
                editState={editState}
                setEditState={setEditState}
              />
              {/* <Form.Group as={Col} controlId='context'>
                <Form.Label>
                  <Trans code='A0350' />
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='context'
                  rows={3}
                  placeholder={propTrans({ prop: 'A0538' })}
                  value={additionalInfo
                    .filter((info) => info.topic === 'context' && info.lang === langCode)
                    .map((info) => info.text)}
                  onChange={(e) => onChange(e, 'context', 'description')}
                />
              </Form.Group> */}
              <MarkdownEditComponent
                titleCode='A0350'
                placeholder={propTrans({ prop: 'A0537' })}
                onChange={onChange}
                name='context'
                type='description'
                existingContent={
                  additionalInfo
                    .filter((info) => info.topic === 'context' && info.lang === langCode)
                    .map((info) => info.text)[0]
                }
                langCode={langCode}
                editState={editState}
                setEditState={setEditState}
              />
            </Row>

            <Row className='mb-3'>
              {/* <Form.Group as={Col} controlId='modelanalyse'>
                <Form.Label>
                  <Trans code='A0791' />
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='modelanalyse'
                  rows={3}
                  placeholder={propTrans({ prop: 'A0792' })}
                  value={additionalInfo
                    .filter((info) => info.topic === 'modelanalyse' && info.lang === langCode)
                    .map((info) => info.text)}
                  onChange={(e) => onChange(e, 'modelanalyse', 'description')}
                />
              </Form.Group> */}
              <MarkdownEditComponent
                titleCode='A0791'
                placeholder={propTrans({ prop: 'A0792' })}
                onChange={onChange}
                name='modelanalyse'
                type='description'
                existingContent={
                  additionalInfo
                    .filter((info) => info.topic === 'modelanalyse' && info.lang === langCode)
                    .map((info) => info.text)[0]
                }
                langCode={langCode}
                editState={editState}
                setEditState={setEditState}
              />
              {/* <Form.Group as={Col} controlId='institutional'>
                <Form.Label>
                  <Trans code='A0167' />
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='institutional'
                  rows={3}
                  placeholder={propTrans({ prop: 'A0540' })}
                  value={additionalInfo
                    .filter((info) => info.topic === 'institutional' && info.lang === langCode)
                    .map((info) => info.text)}
                  onChange={(e) => onChange(e, 'institutional', 'description')}
                />
              </Form.Group> */}
              <MarkdownEditComponent
                titleCode='A0167'
                placeholder={propTrans({ prop: 'A0540' })}
                onChange={onChange}
                name='institutional'
                type='description'
                existingContent={
                  additionalInfo
                    .filter((info) => info.topic === 'institutional' && info.lang === langCode)
                    .map((info) => info.text)[0]
                }
                langCode={langCode}
                editState={editState}
                setEditState={setEditState}
              />
            </Row>

            {/* <Row className='mb-3'>
              <Form.Group as={Col} className='align-self-end' controlId='model'>
                <Form.Label>
                  <Trans code='A0097' />
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='model'
                  rows={3}
                  placeholder={propTrans({ prop: 'A0535' })}
                  value={additionalInfo
                    .filter((info) => info.topic === 'model' && info.lang === langCode)
                    .map((info) => info.text)}
                  onChange={(e) => onChange(e, 'model', 'description')}
                />
              </Form.Group>
              <Form.Group as={Col} controlId='modelass'>
                <Form.Label>
                  <Trans code='A0349' />
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='modelass'
                  rows={3}
                  placeholder={propTrans({ prop: 'A0535' })}
                  value={additionalInfo
                    .filter((info) => info.topic === 'modelass' && info.lang === langCode)
                    .map((info) => info.text)}
                  onChange={(e) => onChange(e, 'modelass', 'description')}
                />
              </Form.Group>
            </Row> */}
          </Col>
        </Row>

        {/* <Row className='mt-3'>
          <Col lg={12} className='form-login'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='C00466' />
            </h5>
            {attachmentsSection(
              scenLinks,
              setScenLinks,
              setScenLinks1,
              linksFields,
              setLinksFields,
              'links',
              'link'
            )}
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col lg={12} className='form-login'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0465' />
            </h5>
            {attachmentsSection(
              scenFiles,
              setScenFiles,
              setScenFiles1,
              filesFields,
              setFilesFields,
              'files',
              'file'
            )}
          </Col>
        </Row> */}
      </div>
      <div className='simple-box' style={{ marginTop: '40px' }}>
        <Row>
          <Col lg={12} className='form-login'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='C00466' />
            </h5>
            {attachmentsSection(
              scenLinks,
              setScenLinks,
              setScenLinks1,
              linksFields,
              setLinksFields,
              'links',
              'link'
            )}
          </Col>
        </Row>
      </div>
      <div className='simple-box' style={{ marginTop: '40px', marginBottom: '20px' }}>
        <Row>
          <Col lg={12} className='form-login'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0465' />
            </h5>
            {attachmentsSection(
              scenFiles,
              setScenFiles,
              setScenFiles1,
              filesFields,
              setFilesFields,
              'files',
              'file'
            )}
          </Col>
        </Row>
      </div>

      <Modal show={showModalWarning} onHide={() => handleCloseModal(setShowModalWarning)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0580' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalWarning)}>
            <Trans code='F0057' />
          </Button>
        </Modal.Footer>
      </Modal>
      {sbShow}
    </>
  );
};
export default AdditionalInfoSection;
