import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { 
   StepGeneral, 
   StepDescription, 
   StepCBA, 
   StepEconomics, 
   //StepTechnicals, 
   StepESG, 
   //StepImpactAnalysis,
   StepExecution, 
   StepComment ,
} from '@components/wizard';
import WizardHeader from "components/wizard/WizardHeader";
import { getScenarioSelect,getScenario,getScenarioMeta } from '@lib/api';
import ScenarioHeader from '@components/scenario/ScenarioHeader';

const WizardStep = () => {
   const { idScenario = null } = useParams();
   const urlParams = new URLSearchParams(window.location.search);
   const stepName = urlParams.get('step') ?? 'general';
   const [scenarioSelect, setScenarioSelect] = useState();
   const [scenarioData, setScenarioData] = useState();
   const [scenarioMeta, setScenarioMeta] = useState();

   useEffect( () => {
      getScenarioSelect().then(function(response) { setScenarioSelect(response) })
      
      if(idScenario){
         getScenario(idScenario).then(function(response) { setScenarioData(response)})
         getScenarioMeta(idScenario).then(function(response) { setScenarioMeta(response)})
      } else {
         setScenarioData({});
         setScenarioMeta({});
      }
   }, [idScenario]);

   if ( !scenarioData || !scenarioSelect || !scenarioMeta) {
      return (
         <div className="scenari megaContent">
            <div className="container-fluid">
               <div className="sticky">
               <div className="simple-box">
                  <Row>
                     <Col>
                     LOADING
                     </Col>
                  </Row>
               </div>
               </div>
            </div>
         </div>
      );
   }
 
   if ( scenarioData && scenarioData !== undefined && scenarioSelect && scenarioSelect !== undefined && 
      scenarioMeta && scenarioMeta !== undefined) {
    
      return (
         <>      
            <div id="wizard" className="scenari megaContent">
               <div className="container-fluid">
                  {scenarioData.scenario ? 
                     <ScenarioHeader scenario={scenarioData.scenario} /> : 
                     null
                  }
                  {/**STICKY BEGINS
                  <div className="sticky">
                  */}
                     <WizardHeader stepName={stepName} scenarioData={scenarioData} idScenario={parseInt(idScenario)} />
                  {/* </div>
                  *STICKY ENDS */}

                  {stepName === 'general' &&
                  <StepGeneral props={scenarioSelect} scen={scenarioData} />}
                  {stepName === 'framework' &&
                  <StepDescription props={scenarioSelect} scen={scenarioData} />}
                  {stepName === 'economics' &&
                  <StepEconomics props={scenarioSelect} scen={scenarioData} />}
                  {stepName === 'financials' && false && !window.location.href.includes("externalytics.com") &&
                     <StepCBA props={scenarioSelect} scen={scenarioData} />
                  }
                  {/* {stepName === 'sroi' &&
                  <StepTechnicals props={scenarioSelect} scen={scenarioData} />} */}
                  {stepName === 'esg' && false && !window.location.href.includes("externalytics.com") &&
                     <StepESG props={scenarioSelect} scen={scenarioData} />
                  }
                  {/* {stepName === 'impact-analysis' &&
                  <StepImpactAnalysis props={scenarioSelect} scen={scenarioData} />} */}
                  {stepName === 'execution' &&
                  <StepExecution props={scenarioSelect} scen={scenarioData} />}
                  {stepName === 'comment' &&
                  <StepComment props={scenarioSelect} scen={scenarioData} />}
               </div>
            </div>
         </>
      );
   }
}
export default WizardStep
