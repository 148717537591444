import { useEffect, useState } from 'react';
import { rounder, formatNumberWithLocale } from '@lib/helpers';
import { Trans } from '@lib/i18n';
import { Row, Col } from 'react-bootstrap';
const InvestmentImpact = ({ invest, filterForRegion }) => {
  const [data, setData] = useState([]);
  console.log('JOBS', invest.expByType);
  useEffect(() => {
    let tempArray = [];
    let tempCat;
    let tempTot;

    invest.expByType
      .filter((el) => el.region === '')
      .filter((el) => el.propertyName === 'Total')
      .forEach((el) => {
        tempCat = el.category;
        tempTot = el.propertyValue;

        tempArray.push({
          category: tempCat,
          total: tempTot,
        });
      });
    setData(tempArray);
    console.log('TEMP', tempArray);
  }, []);

  return (
    <div className='simple-box'>
      <div className='row'>
        <div className='investment-infobox-capex va-number col'>
          <Row className='justify-content-center align-items-center'>
            <Col className='p-0'>
              <Row className='p-0'>
                {data?.map((el) => {
                  return (
                    <Col key={el.category}>
                      {el.category} <h2>{formatNumberWithLocale(el.total, true)}</h2>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <span className='va-desc d-block' style={{ marginBottom: '1.5rem' }}>
            <Trans code='A0053' />
          </span>
        </div>
      </div>
    </div>
  );
};

export default InvestmentImpact;
