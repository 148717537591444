import { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n';
import NewLegend from '@components/scenariodashboard/NewChartLegend';
import { chartColors as colors } from '@lib/helpers';

import AnimatedDonutChartDash from '@components/scenariodashboard/AnimatedDonutChartDash';

const EmploymentDonutCard = ({ jobs, filterForRegion, chartId, langTitleCode, icon }) => {
  const [jobsData, setJobsData] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  console.log('CARD:', jobs);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);

  useEffect(() => {
    let filter = filterForRegion === 'total' ? '' : filterForRegion;
    setJobsData(
      jobs.filter((job) => {
        // console.log('EMPJOB', job, filter);
        return job.region === filter;
      })
    ); //to filter
  }, [jobs, filterForRegion]);
  // console.log('FHFHFHFH', jobsData);
  return (
    <div className='col-lg-6 col-md-12 col-sm-12 layout-charts d-flex container-card mb-5'>
      <Card className='cards-utente d-md-block '>
        <Card.Body>
          {icon}
          <Card.Title style={{ marginBottom: '5rem' }}>
            <Trans code={langTitleCode} />
          </Card.Title>
          <div className='d-flex justify-content-center align-items-center'>
            <div style={{ width: '300px' }}>
              <AnimatedDonutChartDash
                valueInDonut={false}
                chartId={chartId}
                data={jobsData}
                codeLangTag='A0053'
                translations={translations}
                currentLang={currentLang}
              />
            </div>
          </div>

          <div className='my-3'>
            <NewLegend data={jobsData} colors={colors} scope='jobs' />
            <p className='va-desc'>
              <Trans code='A0054' />
            </p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EmploymentDonutCard;
