const normalizeIncomeTaxes = (json) => {
    const newArray = [];
    for (const region in json.data) {
      const newObject = { region };
      for (const value of json.data[region].values) {
        newObject[value.year] = value.value;
      }
      newArray.push(newObject);
    }
    return newArray;
};

export default normalizeIncomeTaxes;