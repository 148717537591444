import React from 'react';
import style from './MapTooltip.module.css';

const tooltip = (region, metrics = [], caption = 'REGION:') => (
  <div className={style.styledTooltip}>
    <div className={style.styledRow}>
      <span className={style.styledLabel}>{`${caption}`}</span>
      <span className={style.styledValue}>{`${region}`}</span>
    </div>
    {metrics.map(({ title, value }, i) => {
      console.log('title', title, 'value', value);
      return (
        <div key={`${i}`}>
          <hr />
          <div className={style.styledRow}>
            <span className={style.styledLabel}>{`${title}`}</span>
            <span className={style.styledValue}>{`${value}`}</span>
          </div>
        </div>
      );
    })}
  </div>
);

export default tooltip;
