import React from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionItem from './AccordionItem';
import QuestionItem from './QuestionHelperComponents/QuestionItem';

const SubCategory = ({
  subCategory,
  questions,
  handleAssessmentChange,
  questionVisibility,
  handleValue,
  handleChecked,
  userRole,
}) => (
  <div className='mx-3' key={subCategory.code}>
    <div style={{ width: '100%' }}>
      <Accordion>
        <AccordionItem eventKey={String(subCategory.code)} headerText={subCategory.text} isChild>
          {questions
            .filter(
              (question) =>
                question.subcategory === subCategory.code &&
                questionVisibility[question.id] === true
            )
            .sort((a, b) => a.order - b.order)
            .map((question) => (
              <QuestionItem
                key={question.id}
                question={question}
                handleAssessmentChange={handleAssessmentChange}
                questionVisibility={questionVisibility}
                isVisible={questionVisibility[question.id] === true}
                handleValue={handleValue}
                handleChecked={handleChecked}
                userRole={userRole}
              />
            ))}
        </AccordionItem>
      </Accordion>
    </div>
  </div>
);

const Category = ({
  category,
  subCategories,
  questions,
  handleAssessmentChange,
  questionVisibility,
  handleValue,
  handleChecked,
  userRole,
}) => (
  <div className='row' key={category.code}>
    <div className='col-lg-12'>
      <Accordion>
        <AccordionItem eventKey={String(category.code)} headerText={category.text}>
          {subCategories
            .filter((subCategory) => subCategory.category === category.code)
            .sort((a, b) => a.order - b.order)
            .map((subCategory) => (
              <SubCategory
                key={subCategory.code}
                subCategory={subCategory}
                questions={questions}
                handleAssessmentChange={handleAssessmentChange}
                questionVisibility={questionVisibility}
                handleValue={handleValue}
                handleChecked={handleChecked}
                userRole={userRole}
              />
            ))}
        </AccordionItem>
      </Accordion>
    </div>
  </div>
);

export default Category;
