// import React from 'react';
// import style from './Table.module.css';

// export default function Pagination({ page, handlePageChange, totalPages, visibilePagesCount }) {
//   const calcShift = () => {
//     if (page > totalPages - Math.round(visibilePagesCount / 2))
//       return totalPages - visibilePagesCount;
//     if (page < Math.round(visibilePagesCount / 2)) return 0;
//     return -visibilePagesCount + Math.round(visibilePagesCount / 2) + page;
//   };

//   const buttonClassDisabled = (boolean) => {
//     return boolean ? `${style.button} ${style.disabled}` : `${style.button}`;
//   };

//   return (
//     <div className={style.styledPagination}>
//       <button
//         disabled={page === 0}
//         className={buttonClassDisabled(page === 0)}
//         onClick={() => handlePageChange(page - 1)}>
//         {'<'}
//       </button>
//       {Array.from({ length: visibilePagesCount }, (_, i) => {
//         const buttonClassActive = `${style.button} ${i + calcShift() === page ? style.active : ''}`;
//         return (
//           <button
//             key={`page${i + calcShift()}`}
//             className={buttonClassActive}
//             onClick={() => handlePageChange(i + calcShift())}>
//             {i + 1 + calcShift()}
//           </button>
//         );
//       })}
//       <button
//         disabled={page === totalPages - 1}
//         className={buttonClassDisabled(page === totalPages - 1)}
//         onClick={() => handlePageChange(page + 1)}>
//         {'>'}
//       </button>
//     </div>
//   );
// }

// Pagination.js
import React from 'react';
import style from './Table.module.css';

export default function Pagination({ page, handlePageChange, totalPages, visibilePagesCount }) {
  const calcShift = () => {
    if (page > totalPages - Math.round(visibilePagesCount / 2))
      return totalPages - visibilePagesCount;
    if (page < Math.round(visibilePagesCount / 2)) return 0;
    return -visibilePagesCount + Math.round(visibilePagesCount / 2) + page;
  };

  return (
    <div className={style.pagination}>
      <button
        className={`${style.pageButton} ${page === 0 && style.pageButtonDisabled}`}
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 0}>
        {'<'}
      </button>
      {Array.from({ length: visibilePagesCount }, (_, i) => (
        <button
          key={`page${i + calcShift()}`}
          className={`${style.pageButton} ${i + calcShift() === page && style.pageButtonActive}`}
          onClick={() => handlePageChange(i + calcShift())}>
          {i + 1 + calcShift()}
        </button>
      ))}
      <button
        className={`${style.pageButton} ${page === totalPages - 1 && style.pageButtonDisabled}`}
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages - 1}>
        {'>'}
      </button>
    </div>
  );
}
