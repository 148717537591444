import React, { useState, useEffect } from 'react';
import { /* Table, */ Button, Row, Col } from 'react-bootstrap';
import { /* CloudArrowDown, */ Link } from '@icons';
import { Ico19, Ico8, Ico29 } from '@icons/new';
import { Trans } from '@lib/i18n';

const DashboardMeta = ({ additionalInfo }) => {
  const [dashboardElements, setDashboardElements] = useState({});
  const [economicDivLength, setEconomicDivLength] = useState(null);
  const [politicalDivLength, setPoliticalDivLength] = useState(null);
  const [shockDivLength, setShockDivLength] = useState(null);
  const [modelDivLength, setModelDivLength] = useState(null);
  const boxHeight = '6.25rem';
  const firstDiv = document.getElementById('context');
  const secondDiv = document.getElementById('institutional');
  const thirdDiv = document.getElementById('shock');
  const fourthDiv = document.getElementById('model');
  //console.log('dashboardElements', dashboardElements);

  useEffect(() => {
    setDashboardElements(() => ({
      context: additionalInfo
        .filter((info) => info.topic === 'context')
        .map((info) => info.text)
        .toString(),
      institutional: additionalInfo
        .filter((info) => info.topic === 'institutional')
        .map((info) => info.text)
        .toString(),
      shock: additionalInfo
        .filter((info) => info.topic === 'shock')
        .map((info) => info.text)
        .toString(),
      model: additionalInfo
        .filter((info) => info.topic === 'model')
        .map((info) => info.text)
        .toString(),
      modelass: additionalInfo
        .filter((info) => info.topic === 'modelass')
        .map((info) => info.text)
        .toString(),
      link: additionalInfo.filter((info) => info.topic === 'link'),
      file: additionalInfo.filter((info) => info.topic === 'file'),
    }));
  }, [additionalInfo]);

  useEffect(() => {
    if (firstDiv) {
      setEconomicDivLength(firstDiv.innerHTML.length);
    }
    if (secondDiv) {
      setPoliticalDivLength(secondDiv.innerHTML.length);
    }
    if (thirdDiv) {
      setShockDivLength(thirdDiv.innerHTML.length);
    }
    if (fourthDiv) {
      setModelDivLength(fourthDiv.innerHTML.length);
    }
  }, [firstDiv, secondDiv, thirdDiv, fourthDiv]);

  const hideCol = (colId, text, rowId) => {
    const parent = document.getElementById(colId + 'Parent');

    const childrenOfRowId = [...document.getElementById(rowId).children];

    const visibleChildren = childrenOfRowId.filter((el) => !el.className.includes('d-none'));

    if (!Array.isArray(text) && text.length === 0) {
      parent.style.display = 'none';

      visibleChildren.forEach((el) => {
        el.classList.remove('col-xl-6');
        el.classList.add('col-xl-12');
      });
    } else if (Array.isArray(text) && text.reduce((prev, curr) => prev + curr, 0) === 0) {
      parent.style.display = 'none';

      visibleChildren.forEach((el) => {
        el.classList.remove('col-xl-6');
        el.classList.add('col-xl-12');
      });
    }
  };

  const showHideBox = (boxId, buttonId) => {
    const element = document.getElementById(boxId);
    const button = document.getElementById(buttonId);

    //show
    if (element.style.height === boxHeight) {
      element.style.height = 'inherit';
      button.innerHTML = 'Show Less...';
      element.classList.add('fade-in-div');
      element.classList.remove('fade-out-div');
      //element.classList.remove('showhide-box');
    } else {
      //hide
      element.style.height = boxHeight;
      button.innerHTML = 'Show More...';
      element.classList.remove('fade-in-div');
      element.classList.add('fade-out-div');
      //element.classList.add('showhide-box');
    }
  };

  return (
    <>
      {/* <div className="row d-flex" id="first-row">
            <div className="col-12 col-xl-6 container-card" id="contextParent">
               <div className="simple-box min-height-box">
                  <div className="row">
                     <div className="col">
                        <Ico8 className="logo-card ico-box-blue" />
                        <h5 className="title-simple-box mb-4"><Trans code="A0350" /></h5>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col px-4" id='context' style={{ height: boxHeight, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: (dashboardElements.context ?? '').replace(/\n/g, '<br />') }} />
                  </div>

                  {economicDivLength !== null && economicDivLength > 370 ?
                     <div className="row">
                        <div className="col mt-2" >
                           <Button variant="link" className="showhide-button" id='contextButton' onClick={() => showHideBox('context', 'contextButton')}>Show More...</Button>
                        </div>
                     </div> : <></>
                  }
               </div>
               {Object.keys(dashboardElements).length && document.getElementById('context') ? hideCol('context', dashboardElements.context, "first-row") : null}
            </div>

            <div className="col-12 col-xl-6 container-card" id="institutionalParent">
               <div className="simple-box min-height-box">
                  <div className="row">
                     <div className="col">
                        <Ico8 className="logo-card ico-box-blue" />
                        <h5 className="title-simple-box mb-4"><Trans code="A0167" /></h5>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col px-4" id='institutional' style={{ height: boxHeight, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: (dashboardElements.institutional || '').replace(/\n/g, '<br />') }} />
                  </div>

                  {politicalDivLength !== null && politicalDivLength > 370 ?
                     <div className="row">
                        <div className="col mt-2" >
                           <Button variant="link" className="showhide-button" id='institutionalButton' onClick={() => showHideBox('institutional', 'institutionalButton')}>Show More...</Button>
                        </div>
                     </div> : <></>
                  }
               </div>
               {Object.keys(dashboardElements).length && document.getElementById('institutional') ? hideCol('institutional', dashboardElements.institutional, "first-row") : null}
            </div>
         </div> */}

      {/* <div className='row d-flex' id='second-row'>
        <div className='col-12 col-xl-6 container-card' id='shockParent'>
          <div className='simple-box min-height-box'>
            <div className='row'>
              <div className='col'>
                <Ico19 className='logo-card ico-box-blue' />
                <h5 className='title-simple-box mb-4'>
                  <Trans code='A0351' />
                </h5>
              </div>
            </div>

            <div className='row'>
              <div
                className='col px-4'
                id='shock'
                style={{ height: boxHeight, overflow: 'hidden' }}
                dangerouslySetInnerHTML={{
                  __html: (dashboardElements.shock || '').replace(/\n/g, '<br />'),
                }}
              />
            </div>

            {shockDivLength !== null && shockDivLength > 370 ? (
              <div className='row'>
                <div className='col mt-2'>
                  <Button
                    variant='link'
                    className='showhide-button'
                    id='shockButton'
                    onClick={() => showHideBox('shock', 'shockButton')}>
                    Show More...
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {Object.keys(dashboardElements).length && document.getElementById('shock')
            ? hideCol('shock', dashboardElements.shock, 'second-row')
            : null}
        </div>
        <div className='col-12 col-xl-6 container-card' id='modelParent'>
          <div className='simple-box min-height-box'>
            <div className='row'>
              <div className='col'>
                <Ico19 className='logo-card ico-box-blue' />
                <h5 className='title-simple-box mb-4'>
                  <Trans code='A0097' />
                </h5>
              </div>
            </div>

            <div className='row'>
              <div
                className='col px-4'
                id='model'
                style={{ height: boxHeight, overflow: 'hidden' }}>
                {dashboardElements.model ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: (dashboardElements.model || '').replace(/\n/g, '<br />'),
                    }}
                  />
                ) : null}
                <br />
                {dashboardElements.modelass ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: (dashboardElements.modelass || '').replace(/\n/g, '<br />'),
                    }}
                  />
                ) : null}
              </div>
            </div>

            {modelDivLength !== null && modelDivLength > 370 ? (
              <div className='row'>
                <div className='col mt-2'>
                  <Button
                    variant='link'
                    className='showhide-button'
                    id='modelButton'
                    onClick={() => showHideBox('model', 'modelButton')}>
                    Show More...
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {Object.keys(dashboardElements).length && document.getElementById('model')
            ? hideCol(
                'model',
                [dashboardElements.model.length, dashboardElements.modelass.length],
                'second-row'
              )
            : null}
        </div>
      </div> */}

      {Object.keys(dashboardElements).length &&
      (dashboardElements.file.length || dashboardElements.link.length) ? (
        <>
          <div className='simple-box'>
            <div className='row'>
              <div className='col'>
                <Ico29 className='logo-card ico-box-blue' />
                <h5 className='title-simple-box mb-4'>
                  <Trans code='F0071' />
                </h5>
              </div>
            </div>
            <div className='row'>
              <div className='col px-4'>
                {/* <Table>
                           <tbody>
                              {dashboardElements.file.map(a => (
                                 <tr key={a.id}>
                                    <td><a style={{ color: 'black' }} href={`${window.location.href.includes("externalytics.com") ? dashboardElements.base_url.replace('stage.', '') : dashboardElements.base_url}${a.ref}`} target="_blank" rel="noreferrer">{a.text}</a></td>
                                    <td><a style={{ color: 'black' }} href={`${window.location.href.includes("externalytics.com") ? dashboardElements.base_url.replace('stage.', '') : dashboardElements.base_url}${a.ref}`} target="_blank" rel="noreferrer"><CloudArrowDown /></a></td>
                                 </tr>
                              ))} 
                           </tbody>
                        </Table>*/}
                <Row className='attachments-dashboard mb-4'>
                  {dashboardElements.link.map((l) => (
                    <Col style={{ minWidth: '15rem' }} key={l.id}>
                      <Row
                        as='a'
                        className='mt-2 align-items-center link-additional-info'
                        href={l.ref.match(/https?/gm) ? l.ref : `//${l.ref}`}
                        rel='noreferrer'
                        target='_blank'>
                        <Col className='pl-5'>{l.text}</Col>
                        <Col className='text-center'>
                          <Link />
                        </Col>
                      </Row>
                      <hr className='mt-2' />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DashboardMeta;
