import { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationItems = ({ page, pages, maxVisiblePages = 6, setPage }) => {
  const items = useMemo(() => {
    const half = Math.floor(maxVisiblePages / 2);
    const start = Math.max(1, page - half);
    const end = Math.min(pages, start + maxVisiblePages - 1);

    const pagesItems = Array.from({ length: end - start + 1 }, (_, i) => start + i).map((p) => (
      <Pagination.Item key={p} data-page={p} onClick={() => setPage(p)} active={page === p}>
        {p}
      </Pagination.Item>
    ));

    const firstItem =
      page > half + 1 ? (
        <>
          <Pagination.First onClick={() => setPage(1)} />
          <Pagination.Ellipsis disabled />
        </>
      ) : null;

    const lastItem =
      page < pages - half ? (
        <>
          <Pagination.Ellipsis disabled />
          <Pagination.Last onClick={() => setPage(pages)} />
        </>
      ) : null;

    return (
      <Pagination>
        {firstItem}
        {pagesItems}
        {lastItem}
      </Pagination>
    );
  }, [page, pages, maxVisiblePages, setPage]);

  return items;
};

export default PaginationItems;
