import { Modal, Button } from "react-bootstrap";
import { Trans } from "@lib/i18n";

const ModalDetails = ({
  titleModal,
  children,
  show,
  onHide,
  actionButton,
  type,
}) => {
  return (
    <Modal
      {...(type
        ? { size: type, dialogClassName: "" }
        : { dialogClassName: "modal-fullscreen" })}
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{titleModal}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5">{children ?? <></>}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          <Trans code="A0529" />
        </Button>
        {actionButton ?? <></>}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetails;
