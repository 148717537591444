import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { forgotPassword, resetPassword, validateOtpForgotPassword } from '@lib/api';

import PasswordRecoveryEmail from './loginComponents/passwordRecoveryEmail';
import PasswordRecoveryOtp from './loginComponents/passwordRecoveryOtp';
import PasswordRecoveryPassword from './loginComponents/passwordRecoveryPassword';
import StatusBar from './wizard/StatusBar';
import { validatePassword, validateEmail } from '@lib/helpers/validation';

const ResetPasswordForm = ({
  referrer,
  setShowLogin,
  error,
  setError,
  isLoading,
  setIsLoading,
}) => {
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    otp: '',
  });
  const [isOtp, setIsOtp] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [message, setMessage] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const resetLoginState = () => {
    setLoginForm({
      email: '',
      password: '',
      passwordConfirmation: '',
      otp: '',
    });
  };

  const handleLogin = async (e, getNewCode = false) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    const { email, password, passwordConfirmation, otp } = loginForm;

    try {
      if ((email && !otp) || (email && isOtp && getNewCode)) {
        await handleEmailSubmission(email);
      } else if (email && otp && !password && !passwordConfirmation) {
        await handleOtpSubmission(otp);
      } else if (email && otp && password && passwordConfirmation) {
        await handlePasswordReset(password, passwordConfirmation);
      }
    } catch (error) {
      setError('An unexpected error occurred');
      console.error('Error during login process:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailSubmission = async (email) => {
    if (!validateEmail(email)) {
      setError('Email is not valid');
      return;
    }
    const response = await forgotPassword({ email });
    if (response.status) {
      setIsOtp(true);
    } else {
      setError(response.message || 'ERROR');
    }
  };

  const handleOtpSubmission = async (otp) => {
    if (otp.length !== 6) {
      setError('Inserire un codice OTP valido');
      return;
    }
    const response = await validateOtpForgotPassword({ ...loginForm, otp });
    if (response.status) {
      setIsPassword(true);
      setIsOtp(false);
    } else {
      setError(response.message || 'ERROR');
    }
  };

  const handlePasswordReset = async (password, passwordConfirmation) => {
    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long');
      return;
    }
    if (password !== passwordConfirmation) {
      setError('Passwords do not match');
      return;
    }
    const response = await resetPassword(loginForm);
    if (response.status) {
      setMessage(
        `Password modificata con successo. Sarai riporato alla pagina di login in 5 secondi`
      );
      timeoutRef.current = setTimeout(() => {
        setMessage(null);
        resetLoginState();
        setShowLogin(true);
      }, 5000);
    } else {
      setError(response.message || 'ERROR');
    }
  };

  return (
    <Container>
      <Row>
        <Col lg={3} className='text-login'></Col>
        <Col
          lg={5}
          className='simple-box form-login d-flex flex-column justify-content-center align-items-center'
          style={{ minHeight: '450px' }}>
          {!isOtp && !isPassword && (
            <PasswordRecoveryEmail
              loginForm={loginForm}
              setLoginForm={setLoginForm}
              setShowLogin={setShowLogin}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              error={error}
              handleLogin={handleLogin}
            />
          )}
          {isOtp && !isPassword && (
            <PasswordRecoveryOtp
              setShowLogin={setShowLogin}
              setIsOtp={setIsOtp}
              loginForm={loginForm}
              setLoginForm={setLoginForm}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              error={error}
              setError={setError}
              handleLogin={handleLogin}
            />
          )}
          {!isOtp && isPassword && (
            <PasswordRecoveryPassword
              loginForm={loginForm}
              setLoginForm={setLoginForm}
              setShowLogin={setShowLogin}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              error={error}
              setError={setError}
              handleLogin={handleLogin}
            />
          )}
          <hr className='w-100' />
          <Row>
            <Col className='d-flex flex-column'>
              <p className='pt-3 mr-2 mb-0 login-bottom-paragraph-style'>Hai già un account?</p>
              <Button
                variant='link'
                onClick={() => {
                  setError(null);
                  setShowLogin(true);
                }}
                disabled={isLoading}
                className='login-bottom-paragraph-style login-bottom-button-style'>
                Accedi
              </Button>
            </Col>
          </Row>
          {message && (
            <StatusBar status='success' message={message} timeout={true} showStatus={false} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordForm;
