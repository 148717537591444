import { useState, useEffect } from 'react';
import { rounder } from '@lib/helpers';
import { Card } from 'react-bootstrap';
import { Trans, ApiTrans, propTrans } from '@lib/i18n';
import { Ico35 } from '@icons/new';

const InvestmentTable = ({ invest, years }) => {
  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [keys, setKeys] = useState(['LOCALCAPEX', 'OPEX', 'Total']);
  console.log('INVES', invest);

  // const keys = ['LOCALCAPEX', 'OPEX', 'Total'];
  useEffect(() => {
    let tempArray = [];
    let tempVal = [];
    let tempCat;
    let tempTot;
    let tempPV;

    keys.forEach((key) => {
      tempCat = key;
      invest
        .filter((el) => el.category === key)
        .forEach((el) => {
          if (el.propertyName === 'Total') {
            tempTot = el.propertyValue;
          } else if (el.propertyName === 'PV') {
            tempPV = el.propertyValue;
          } else {
            tempVal.push(el.propertyValue);
          }
        });
      tempArray.push({
        category: tempCat,
        values: tempVal,
        total: tempTot,
        pv: tempPV,
      });
      tempVal = [];
    });
    // setData(tempArray);
    console.log('TEMP', tempArray);
    // setRows(arr);
    // console.log('ARR', arr);
    setData(tempArray);
  }, [years, invest]);

  useEffect(() => {
    if (data.length === 0) return;
    let opexValues = data?.filter((obj) => obj.category === 'OPEX').map((obj) => obj.values);
    let localCapexValues = data
      .filter((obj) => obj.category === 'LOCALCAPEX')
      .map((obj) => obj.values);

    let opexTotal = data?.filter((obj) => obj.category === 'OPEX').map((obj) => obj.total);
    let localCapexTotal = data
      ?.filter((obj) => obj.category === 'LOCALCAPEX')
      .map((obj) => obj.total);
    let opexPV = data?.filter((obj) => obj.category === 'OPEX').map((obj) => obj.pv);
    let localCapexPV = data?.filter((obj) => obj.category === 'LOCALCAPEX').map((obj) => obj.pv);

    let totalValues = opexValues.concat(localCapexValues).reduce((acc, arr) => {
      arr.forEach((val, i) => (acc[i] = (acc[i] || 0) + val));
      return acc;
    }, []);

    let endTotal = opexTotal.concat(localCapexTotal).reduce((acc, curr) => acc + curr, 0);
    console.log('OOOOO', opexTotal, localCapexTotal, endTotal);
    let endPV = opexPV.concat(localCapexPV).reduce((acc, curr) => acc + curr, 0);
    data.find((obj) => obj.category === 'Total').values = totalValues;
    data.find((obj) => obj.category === 'Total').total = endTotal;
    data.find((obj) => obj.category === 'Total').pv = endPV;
    setFinalData(data);
  }, [data]);

  return (
    <div className='col-lg-12 col-md-12 col-sm-12 layout-charts d-flex container-card mb-5'>
      <Card className='cards-utente d-none d-md-block card-table'>
        <Card.Body>
          <Ico35 className='logo-card ico-box-blue' />
          <Card.Title>
            <Trans code='A0183' />
          </Card.Title>
          <div style={{ overflowX: 'auto', position: 'relative', height: '240px', width: '100%' }}>
            <table
              style={{ position: 'absolute', width: '100%', height: '100%' }}
              className='investment-table'>
              <thead>
                <tr>
                  <th className='value-label'>
                    <Trans code='A0620' />
                  </th>
                  {years?.map((el) => (
                    <th key={el}>{el}</th>
                  ))}
                  <th>
                    <Trans code='A0111' />
                  </th>
                  <th className='value-vs'>
                    <Trans code='A0175' />
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalData.length > 0 &&
                  finalData?.map((el, i) => (
                    <tr key={`${el.category}${i}`} className={el.category.toLowerCase()}>
                      <td className='value-label'>{el.category}</td>
                      {el.values.map((val, i) => (
                        <td key={`${el.category}${years[i]}${val}`}>{rounder(val)}</td>
                      ))}
                      <td>{rounder(el.total)}</td>

                      <td>{rounder(el.pv)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Card.Body>
        <p className='va-desc'>
          <Trans code='A0053' />
        </p>
      </Card>
    </div>
  );
};

export default InvestmentTable;
