import React, { useState, useEffect } from 'react';
import { Table, Button, FormControl, ButtonToolbar, InputGroup, Pagination } from 'react-bootstrap';
import { Lens } from '@icons';
import { getOpenScenari } from '@lib/api';
import { Link } from 'react-router-dom';
import { Trans } from '@lib/i18n';
import { date } from '@lib/helpers';

const ScenariTabopen = () => {
  const [scenari, setScenari] = useState([]);
  const [fScenari, setFScenari] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState('');
  const boxHeight = '4.5rem';
  //console.log('scenari', scenari);

  const getPaginationItems = (page, pages, max = 6) => {
    const items = [];
    if (pages <= max) {
      for (let i = 1; i <= pages; i++) {
        items.push(
          <Pagination.Item active={page === i} key={i} onClick={() => setPage(i)}>
            {i}
          </Pagination.Item>
        );
      }
    } else {
      const half = Math.floor(max / 2);

      if (page > 1) {
        items.push(<Pagination.First key={0} onClick={() => setPage(1)} />);
      }

      let start;

      start = page - half;
      if (start < 1) start = 1;

      for (let p = start; p <= page; p++) {
        items.push(
          <Pagination.Item key={p} data-page={p} onClick={() => setPage(p)} active={page === p}>
            {p}
          </Pagination.Item>
        );
      }

      let counter;
      let last = page + half <= pages ? page + half : pages;

      for (let n = page + 1; n <= last; n++) {
        items.push(
          <Pagination.Item key={n} data-page={n} onClick={() => setPage(n)}>
            {n}
          </Pagination.Item>
        );
        counter = n;
      }

      if (counter < pages) {
        items.push(<Pagination.Last key={9999} onClick={() => setPage(pages)} />);
      }
    }

    return items;
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
    filterAndPaginate(fScenari, value);
  };

  const filterAndPaginate = (scenari, value = search) => {
    setPage(1);
    const filteredScenari = scenari.filter((s) => {
      if (value.length > 0) {
        return (
          (s.scenario_name || '').toLowerCase().search(value.toLowerCase()) > -1 ||
          (s.cod_nace_description || '').toLowerCase().search(value.toLowerCase()) > -1
        );
      }
      return true;
    });
    setPages(Math.ceil(filteredScenari.length / 10));
    setScenari(filteredScenari);
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const scenari = await getOpenScenari();

      if (abortController.signal.aborted === false) {
        filterAndPaginate(scenari);
        setFScenari(scenari);
      }
    };
    // getAuthUser().then(({ user }) => {
    //   setAuthUser(user);
    // });
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  // useEffect(() => {
  //  getOpenScenari()
  //     // Filter scenari, use only published ones
  //     .then(scenari => {
  //        //console.log('scenari', scenari);
  //        //const scenariFiltered = scenari.filter(s => parseInt(s.access) === 1)
  //        filterAndPaginate(scenari);
  //        setFScenari(scenari)
  //     })
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const ordering = {
    key: '',
    direction: 'ASC',
  };

  const sortScenari = (attribute) => {
    if (ordering.key === attribute)
      ordering.direction = ordering.direction === 'ASC' ? 'DESC' : 'ASC';
    else {
      ordering.key = attribute;
      ordering.direction = 'ASC';
    }
    const orderedScenari = scenari.sort((a, b) => {
      if (a[attribute] < b[attribute]) return ordering.direction === 'ASC' ? -1 : 1;
      if (a[attribute] > b[attribute]) return ordering.direction === 'ASC' ? 1 : -1;
      return 0;
    });

    setScenari(orderedScenari);
  };

  const showHideBox = (boxId, buttonId) => {
    const element = document.getElementById(boxId);
    const button = document.getElementById(buttonId);

    //show
    if (element.style.height === boxHeight) {
      element.style.height = 'inherit';
      button.innerHTML = 'Show Less...';
      element.classList.add('fade-in-div');
      element.classList.remove('fade-out-div');
      element.classList.remove('showhide-box');
    } else {
      //hide
      element.style.height = boxHeight;
      button.innerHTML = 'Show More...';
      element.classList.remove('fade-in-div');
      element.classList.add('fade-out-div');
      element.classList.add('showhide-box');
    }
  };

  return (
    <div className='simple-box scenaritab' /* style={{width: '40%', marginLeft: 0}} */>
      <div className='row d-flex align-items-center'>
        <div className='col-6'>
          <h5 className='title-simple-box'>
            <Trans code='F0059' />
          </h5>
        </div>
        <div className='col-6 text-right'>
          <ButtonToolbar className='justify-content-end align-items-center'>
            <InputGroup className='search-table'>
              <Button variant='link' className='search-btn'>
                <Lens />
              </Button>
              <FormControl
                type='text'
                placeholder='Search'
                className='d-none d-md-block'
                value={search}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Table className='table' responsive>
            <thead>
              <tr>
                <th onClick={() => sortScenari('scenario_name')}>
                  <Trans code='A0348' className='pl-0' />
                </th>
                {/* <th className="d-none d-md-table-cell"><Trans code="A0068"/></th> */}
                <th className='d-none d-md-table-cell pl-0'>
                  <Trans code='A0059' />
                </th>
                <th className='pl-0'>
                  <span className='d-none d-md-table-cell'>
                    <Trans code='A0071' />
                  </span>
                  <span className='d-table-cell d-md-none'>
                    <Trans code='A0059' />
                  </span>
                </th>
                <th className='d-none d-lg-table-cell pl-0'>
                  <Trans code='A0457' />
                </th>
                <th className='d-none d-lg-table-cell pl-0'>
                  <Trans code='A0489' />
                </th>
                {/* <th className="d-none d-lg-table-cell">Run</th> */}
                {/* <th className="d-none d-lg-table-cell"><Trans code="A0075"/></th>*/}
              </tr>
            </thead>
            <tbody>
              {scenari
                // .filter(s => {
                //   if(search.length > 0) {
                //     return (s.scenario_name || '').toLowerCase().search(search.toLowerCase()) > -1 || (s.cod_nace_description || '').toLowerCase().search(search.toLowerCase()) > -1
                //   }
                //   return true
                // })
                .slice(page * 10 - 10, page * 10)
                .map((scenario) => {
                  return (
                    <tr key={scenario.idScenario}>
                      <td className='pr-3' style={{ whiteSpace: 'normal', minWidth: '250px' }}>
                        {parseInt(scenario.publish) === 0 ? (
                          scenario.scenario_name
                        ) : (
                          <Link
                            className='scenario-link'
                            to={{
                              pathname: `/scenari/${scenario.idScenario}/dashboard`,
                              state: { scenario },
                            }}>
                            {scenario.scenario_name}
                          </Link>
                        )}
                      </td>

                      {/* <td className="d-none d-md-table-cell">{scenario.cod_nace_description}</td> */}
                      <td className='d-none d-md-table-cell pr-3'>{scenario.company}</td>
                      <td className='pr-3'>
                        {date(scenario.dataModifica || scenario.dataCreazione)}
                      </td>
                      <td className='pr-3'>
                        {Number(scenario.verificato) ? (
                          <Trans code='A0490' />
                        ) : (
                          <Trans code='A0491' />
                        )}
                      </td>
                      <td className='d-none d-lg-table-cell pr-3' style={{ whiteSpace: 'normal' }}>
                        <div
                          className='showhide-box'
                          id={scenario.idScenario}
                          style={{ height: boxHeight, overflow: 'hidden' }}
                          dangerouslySetInnerHTML={{
                            __html: (scenario.analysisScopeOfWork ?? '').replace(/\n/g, '<br />'),
                          }}
                        />
                        {scenario.analysisScopeOfWork?.length > 200 ? (
                          <div className='row'>
                            <div className='col mt-2'>
                              <a
                                className='showhide-button'
                                id={scenario.idScenario + '-contextButton'}
                                onClick={() =>
                                  showHideBox(
                                    scenario.idScenario,
                                    scenario.idScenario + '-contextButton'
                                  )
                                }>
                                Show More...
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      {/* <td className="d-none d-lg-table-cell">{parseInt(scenario.publish) === 0 ? 'NO' : 'SI'}</td> */}
                      {/* <td className="d-none d-lg-table-cell pr-3">
                       <IcoEdit className="second-color ico-small edit" />
                        <IcoCanc className="second-color ico-small cancel" />
                      </td>*/}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      <Pagination>{getPaginationItems(page, pages, 10)}</Pagination>
    </div>
  );
};

export default ScenariTabopen;
