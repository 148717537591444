import { Trans } from '@lib/i18n/index';
import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';

// const bm = {
//    colHeader: ['vocedispesa1', 'vocedispesa2', 'vocedispesa3'], //forse con langTag in futuro
//    rowHeader: ['settore1', 'settore2', 'settore3', 'settore4', 'settore5'],
//    table: [
//       {
//          col: "vocedispesa1",
//          id: "id1",
//          row: "settore1",
//          value: "0.60",
//       },
//       {
//          col: "vocedispesa1",
//          id: "id2",
//          row: "settore2",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa1",
//          id: "id3",
//          row: "settore3",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa1",
//          id: "id4",
//          row: "settore4",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa1",
//          id: "id5",
//          row: "settore5",
//          value: "0.10",
//       },

//       {
//          col: "vocedispesa2",
//          id: "id6",
//          row: "settore1",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa2",
//          id: "id7",
//          row: "settore2",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa2",
//          id: "id8",
//          row: "settore3",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa2",
//          id: "id9",
//          row: "settore4",
//          value: "0.10",
//       },
//       {
//          col: "vocedispesa2",
//          id: "id10",
//          row: "settore5",
//          value: "0.10",
//       },

//       {
//          col: "vocedispesa3",
//          id: "id11",
//          row: "settore1",
//          value: "0.00",
//       },
//       {
//          col: "vocedispesa3",
//          id: "id12",
//          row: "settore2",
//          value: "0.00",
//       },
//       {
//          col: "vocedispesa3",
//          id: "id13",
//          row: "settore3",
//          value: "0.00",
//       },
//       {
//          col: "vocedispesa3",
//          id: "id14",
//          row: "settore4",
//          value: "0.00",
//       },
//       {
//          col: "vocedispesa3",
//          id: "id15",
//          row: "settore5",
//          value: "0.00",
//       },
//    ],
// };

const ShowBridgeMatrix = ({ showModalBM, setShowModalBM, sectors, bmToShow }) => {
  const [bridgeMatrix, setBridgeMatrix] = useState({});
  const [tableHeaders, setTableHeaders] = useState([]);
  // console.log('bridgeMatrix', bmToShow);

  const bm = {
    colHeader: tableHeaders?.map((el) => el), //forse con langTag in futuro
    rowHeader: sectors?.map((el) => el.account),
    table: bmToShow,
  };

  //   console.log(
  //     tableHeaders.map((el) =>
  //       bmToShow.filter((bm) => bm.name === el).map((final) => console.log(final))
  //     )
  //   );

  useEffect(() => {
    const uniqueNames = [];

    bmToShow?.forEach((obj) => {
      if (!uniqueNames.includes(obj.name)) {
        uniqueNames.push(obj.name);
      }
    });
    setTableHeaders(uniqueNames);
  }, [bmToShow]);

  useEffect(() => {
    setBridgeMatrix(bm);
  }, [tableHeaders, sectors, bmToShow]);

  /* useEffect(() => {
      if (Object.keys(bridgeMatrix).length > 0) {
         totalPerColumn();
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bridgeMatrix]); */

  const handleCloseModal = (setShowModal) => setShowModal(false);

  //   const totalPerColumn = (colH) => {
  //     const bmFilteredByCol = bridgeMatrix.table
  //       .filter((el) => el.col === colH)
  //       .map((el) => el.value * 100);
  //     console.log('bmFilteredByCol', bmFilteredByCol);

  //     const total = bmFilteredByCol.reduce(
  //       (prev, curr) =>
  //         Number(
  //           prev.toLocaleString('it-IT', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  //         ) +
  //         Number(curr.toLocaleString('it-IT', { minimumFractionDigits: 0, maximumFractionDigits: 2 }))
  //     );

  //     console.log('total', typeof total, total);

  //     return (
  //       <span style={{ color: total === 100 || total === 0 ? 'inherit' : 'red' }}>
  //         <span>
  //           {total.toLocaleString('it-IT', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
  //         </span>
  //         <span>%</span>
  //       </span>
  //     );
  //   };

  const buildBM = () => {
    const colHeader = [<th key='th-empty'></th>];
    colHeader.push(
      bridgeMatrix?.colHeader?.map((colH) => (
        <th key={colH} className='pb-3 text-right cell-width-bm'>
          {colH}
        </th>
      ))
    );

    return (
      <table className='bm-table class show-bm'>
        <thead>
          <tr>{colHeader}</tr>
        </thead>
        <tbody className='text-right'>
          {bridgeMatrix?.rowHeader?.map((rowH) => {
            return (
              <tr key={rowH}>
                <td className='pl-2'>{rowH}</td>
                {bridgeMatrix?.colHeader?.map((colH) => (
                  <td key={colH + '-td'}>
                    {bridgeMatrix?.table
                      ?.filter((el) => el.name === colH && el.account === rowH)
                      .map((el) => (
                        <Fragment key={el.id}>
                          {el.value}
                          {/* {(el.value * 100).toLocaleString('it-IT', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                          % */}
                        </Fragment>
                      ))}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {/* <tr>
            <td className='pt-4 pl-2 text-left'>
              <strong>Totale</strong>
            </td>
            {bridgeMatrix.colHeader.map((colH) => (
              <td key={colH + '-total'} className='pt-4 text-right'>
                <strong>
                  {showModalBM &&
                    Object.keys(bridgeMatrix).length > 0 &&
                    bridgeMatrix.table.length > 0 &&
                    totalPerColumn(colH)}
                </strong>
              </td>
            ))}
          </tr> */}
        </tfoot>
      </table>
    );
  };

  return (
    <Modal
      dialogClassName='modal-fullscreen'
      show={showModalBM}
      onHide={() => handleCloseModal(setShowModalBM)}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans code='A0691' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5'>
        {Object.keys(bridgeMatrix).length > 0 ? buildBM() : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => handleCloseModal(setShowModalBM)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ShowBridgeMatrix;
