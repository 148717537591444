import { useState, useEffect } from 'react';
import { rounder } from '@lib/helpers';
import { Card } from 'react-bootstrap';
import { Trans, ApiTrans, propTrans } from '@lib/i18n';
import { Ico35 } from '@icons/new';

const EmploymentTypologiesTable = ({ jobs, filterForRegion }) => {
  const [jobsData, setJobsData] = useState([]);
  console.log(jobs);

  useEffect(() => {
    let filter = filterForRegion === 'total' ? '' : filterForRegion;
    setJobsData(
      jobs.filter((job) => {
        // console.log('EMPLOY', job, filter);
        return job.region === filter;
      })
    ); //to filter
  }, [jobs, filterForRegion]);

  return (
    <div className='col-lg-6 col-md-12 col-sm-12 layout-charts d-flex container-card mb-5'>
      <Card className='cards-utente d-none d-md-block card-table'>
        <Card.Body>
          <Ico35 className='logo-card ico-box-blue' />
          <Card.Title>
            <Trans code='A0183' />
          </Card.Title>
          <table className='investment-table'>
            <thead>
              <tr>
                <th className='value-label'>
                  <Trans code='A0068' />
                </th>
                <th>
                  <Trans code='A0205' />
                </th>
              </tr>
            </thead>
            <tbody>
              {jobsData
                ?.filter((k) => k.propertyName !== 'total')
                .map((el) => {
                  const value = el.propertyValue;
                  return (
                    <tr key={el.propertyName}>
                      <td className='value-label'>
                        <ApiTrans scope='jobs' prop={el.propertyName.toLowerCase()} />
                      </td>
                      <td>{rounder(value)}</td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <th className='value-label'>{propTrans({ prop: 'A0111', scope: 'jobs' })}</th>
                <th>
                  {rounder(
                    jobsData
                      .filter((el) => el.propertyName !== 'total')
                      .reduce((acc, curr) => acc + curr.propertyValue, 0)
                  )}
                </th>
              </tr>
            </tfoot>
          </table>
          <p className='va-desc'>
            <Trans code='A0054' />
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EmploymentTypologiesTable;
