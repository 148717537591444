import React from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { Ico3, Ico33, Ico21, Ico47, Ico92, Ico93 } from '@icons/new';
import { Trans } from '@lib/i18n';
import { Link, useParams, useLocation } from 'react-router-dom';
const Submenu = () => {
  const { idScenario = null, idVariante = null } = useParams();
  const { pathname } = useLocation();
  return (
    <ButtonToolbar
      aria-label='Submenu dashboard'
      className='row d-flex justify-content-start align-items-center submenu-dashboard btn-toolbar mt-0 mb-0'>
      <Link to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis`}>
        <Button
          variant='link'
          className={`${
            pathname === `/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis`
              ? 'active'
              : ''
          } dashSubMenu-buttons`}
          // onClick={() => callback('general')}
        >
          <div className='d-flex align-items-center'>
            <Ico33 className='submenu-ico' />
            <span className='submenu-i'>
              <Trans code='A0611' />
            </span>
          </div>
        </Button>
      </Link>
      <Link
        to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/investment`}>
        <Button
          variant='link'
          className={`${pathname.includes('investment') ? 'active' : ''} dashSubMenu-buttons`}
          // onClick={() => callback('investment')}
        >
          <div className='d-flex align-items-center'>
            <Ico21 className='submenu-ico ico-small' />
            {/*<Trans code="A0261" />*/}
            <span className='submenu-i'>
              <Trans code='A0462' />
            </span>
          </div>
        </Button>
      </Link>
      <Link to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/gdp`}>
        <Button
          variant='link'
          className={`${pathname.includes('gdp') ? 'active' : ''} dashSubMenu-buttons`}
          // onClick={() => callback('gdp')}
        >
          <div className='d-flex align-items-center'>
            <Ico47 className='submenu-ico' />
            <span className='submenu-i'>
              <Trans code='A0263' />
            </span>
          </div>
        </Button>
      </Link>
      <Link to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/jobs`}>
        <Button
          variant='link'
          className={`${pathname.includes('jobs') ? 'active' : ''} dashSubMenu-buttons`}
          // onClick={() => callback('jobs')}
        >
          <div className='d-flex align-items-center'>
            <Ico3 className='submenu-ico' />
            <span className='submenu-i'>
              <Trans code='A0328' />
            </span>
          </div>
        </Button>
      </Link>
      <Link to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/incomes`}>
        <Button
          variant='link'
          className={`${pathname.includes('incomes') ? 'active' : ''} dashSubMenu-buttons`}
          // onClick={() => callback('incomes')}
        >
          <div className='d-flex align-items-center'>
            <Ico93 className='submenu-ico' />
            <span className='submenu-i'>
              <Trans code='A0051' />
            </span>
          </div>
        </Button>
      </Link>
      <Link to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/taxes`}>
        <Button
          variant='link'
          className={`${pathname.includes('taxes') ? 'active' : ''} dashSubMenu-buttons`}
          // onClick={() => callback('taxes')}
        >
          <div className='d-flex align-items-center'>
            <Ico92 className='submenu-ico' />
            <span className='submenu-i'>
              <Trans code='F0061' />
            </span>
          </div>
        </Button>
      </Link>
    </ButtonToolbar>
  );
};

export default Submenu;
