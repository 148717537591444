import React, { useEffect, useRef, useState } from 'react';
import style from './Search.module.css';
import DropdownList from './DropdownList';
import closeIcon from './close.svg';
import searchIcon from './search.svg';

function Search({ items = [], handler, placeholder = 'Search for region', setClickOnMap }) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
        setSearch('');
      }
      if (event.key === 'Escape') {
        setIsOpen(false);
        setSearch('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, [ref]);

  const filteredItems = items.filter((item) => item.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className={style.wrapper} ref={ref} style={{ width: isOpen ? '250px' : '32px' }}>
      {isOpen && filteredItems.length > 0 && search && (
        <DropdownList
          items={filteredItems}
          handler={(val) => {
            handler(val);
            setIsOpen(false);
          }}
        />
      )}
      <label
        htmlFor='search'
        onClick={() => {
          setIsOpen(true);
        }}>
        <img
          className={style.styledIcon}
          src={searchIcon}
          alt='search'
          width='18px'
          height='18px'></img>
      </label>
      <input
        className={style.input}
        id='search'
        type='search'
        value={search}
        onChange={(event) => {
          setSearch(event.currentTarget.value);
          setClickOnMap(true);
        }}
        placeholder={placeholder}
        autoComplete='off'
      />
    </div>
  );
}

export default Search;
