import React from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { IcoCanc, IcoUploadFile } from '@icons';
import { Trans } from '@lib/i18n/index';
const UploadModal = ({
  title,
  handleCloseModal,
  setShowModalUpload,
  showModalUpload,
  file,
  setFile,
  handleFileChange,
  sbShowUpload,
  type,
  expenseItemListId,
  secdisagCode,
  handleUpload,
}) => {
  return (
    <Modal
      size='md'
      show={showModalUpload}
      onHide={() => handleCloseModal(setShowModalUpload)}
      centered>
      <Modal.Header closeButton>
        <Modal.Title className='px-2'>
          {/* <Trans code='A0646' /> */}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id='template-upload'>
          <Row className='mb-3'>
            <Col className='px-0'>
              <Form.Group className='pl-4 pr-4' controlId='datasetUpload'>
                <Form.Label>Upload Template</Form.Label>
                <InputGroup className='mb-3'>
                  <div className='custom-file'>
                    {file ? (
                      <Col className='d-flex justify-content-start align-items-center w-100 h-100 p-3 border rounded '>
                        <p className='mb-0 mr-1'>{file.name}</p>
                        <Button
                          variant='primary-outline'
                          onClick={() => {
                            setFile(null);
                          }}>
                          <IcoCanc className='second-color ico-small cancel' />
                        </Button>
                      </Col>
                    ) : (
                      <>
                        <input
                          type='file'
                          className='custom-file-input'
                          id='inputGroupFile02'
                          onChange={handleFileChange}
                        />
                        <label className='custom-file-label border rounded'>
                          <Trans code='A0574' />
                        </label>
                      </>
                    )}
                  </div>
                  <div className='input-group-append'>
                    <Button
                      variant='success'
                      id={`${secdisagCode}${type}`}
                      onClick={(e) => handleUpload(e, expenseItemListId, type, secdisagCode, file)}>
                      Upload{' '}
                      <span>
                        <IcoUploadFile className='light-color ico-small cancel' />
                      </span>
                    </Button>
                  </div>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {sbShowUpload}
      </Modal.Body>
      <Modal.Footer>
        <Row className='px-3'></Row>
        <Row className='px-3 text-right'>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalUpload)}>
            <Trans code='A0529' />
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadModal;
