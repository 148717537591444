import React, { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { TooltipInfoIcon } from '@icons';
import { Trans } from '@lib/i18n'
import { getScenariKpi, postScenariKpi } from '@lib/api'
import StatusBar from "components/wizard/StatusBar";

/* const testKpi = [
  {
    "id": "33",
    "name": "Riduzione emissione dirette in aria",
    "langTag": "KPI33",
    "langTagDesc": "KPI33D",
    "formula": "($B-$A)*$C",
    "udm": "Mâ‚¬",
    "visible": "1",
    "indicatori": [
        {
            "id": "59",
            "name": "Emissioni in aria pre intervento",
            "var": "A",
            "udm": "ton",
            "visible": "1"
        },
        {
            "id": "60",
            "name": "Emissioni in aria post intervento",
            "var": "B",
            "udm": "ton",
            "visible": "1"
        },
        {
            "id": "62",
            "name": "Valore emissioni in aria",
            "var": "C",
            "udm": "Euro/ton",
            "visible": "1"
        }
    ]
  },
  {
      "id": "40",
      "name": "Riduzione impatto estrazione/ produzione materie prime vergini",
      "langTag": "KPI40",
      "langTagDesc": "KPI40D",
      "formula": "$A*$B*$C",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "89",
              "name": "QuantitÃ  di materia prima seconda prodotta ",
              "var": "A",
              "udm": "ton",
              "visible": "1"
          },
          {
              "id": "90",
              "name": "Impatto estrazione/produzione materia prima vergine che puÃ² essere sostituita dalla materia prima seconda",
              "var": "B",
              "udm": "tonCO2/ton",
              "visible": "1"
          },
          {
              "id": "91",
              "name": "Valore CO2",
              "var": "C",
              "udm": "â‚¬/tonCO2",
              "visible": "1"
          }
      ]
  },
  {
      "id": "41",
      "name": "Riduzione costi di approvvigionamento di materiali",
      "langTag": "KPI41",
      "langTagDesc": "KPI41D",
      "formula": "$B-$A",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "92",
              "name": "Costo materie prime vergini",
              "var": "A",
              "udm": "â‚¬",
              "visible": "1"
          },
          {
              "id": "93",
              "name": "Costo materie prime seconde",
              "var": "B",
              "udm": "â‚¬",
              "visible": "1"
          }
      ]
  },
  {
      "id": "42",
      "name": "Riduzione costi associati ai combustibili fossili",
      "langTag": "KPI42",
      "langTagDesc": "KPI42D",
      "formula": "($A-$B)*$C",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "94",
              "name": "Costi associati alla mobilitÃ  tradizionale",
              "var": "A",
              "udm": "â‚¬/km",
              "visible": "1"
          },
          {
              "id": "95",
              "name": "Costi associati alla mobilitÃ  tradizionale",
              "var": "B",
              "udm": "â‚¬/km",
              "visible": "1"
          },
          {
              "id": "96",
              "name": "Km percorsi vita utile auto",
              "var": "C",
              "udm": "km",
              "visible": "1"
          }
      ]
  },
  {
      "id": "43",
      "name": "Riduzione emissioni associate ai combustibili fossili",
      "langTag": "KPI43",
      "langTagDesc": "KPI43D",
      "formula": "($A-$B)*$C*$D",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "97",
              "name": "Emissioni associate alla mobilitÃ  tradizionale ",
              "var": "A",
              "udm": "tonCO2/km",
              "visible": "1"
          },
          {
              "id": "98",
              "name": "Emissioni associate alla mobilitÃ  innovativa",
              "var": "B",
              "udm": "tonCO2/km",
              "visible": "1"
          },
          {
              "id": "99",
              "name": "Valore CO2",
              "var": "C",
              "udm": "â‚¬/ton",
              "visible": "1"
          },
          {
              "id": "100",
              "name": "Km percorsi vita utile auto",
              "var": "D",
              "udm": "km",
              "visible": "1"
          }
      ]
  },
  {
      "id": "44",
      "name": "Riduzione costi associati ai consumi elettrici",
      "langTag": "KPI44",
      "langTagDesc": "KPI44D",
      "formula": "($B-$A)*$C",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "101",
              "name": "Consumi elettrici pre intervento",
              "var": "A",
              "udm": "kWh",
              "visible": "1"
          },
          {
              "id": "102",
              "name": "Consumi elettrici post intervento",
              "var": "B",
              "udm": "kWh",
              "visible": "1"
          },
          {
              "id": "103",
              "name": "Costo elettricitÃ  da rete",
              "var": "C",
              "udm": "â‚¬/kWh",
              "visible": "1"
          }
      ]
  },
  {
      "id": "45",
      "name": "Riduzione impatto associato ai consumi elettrici",
      "langTag": "KPI45",
      "langTagDesc": "KPI45D",
      "formula": "($B-$A)*$C*$D",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "104",
              "name": "Consumi elettrici pre intervento",
              "var": "A",
              "udm": "kWh",
              "visible": "1"
          },
          {
              "id": "105",
              "name": "Consumi elettrici pre intervento",
              "var": "B",
              "udm": "kWh",
              "visible": "1"
          },
          {
              "id": "106",
              "name": " Impatto elettricitÃ  da rete",
              "var": "C",
              "udm": "tonCO2/kwH",
              "visible": "1"
          },
          {
              "id": "107",
              "name": "Valore CO2",
              "var": "D",
              "udm": "â‚¬/tonCO2",
              "visible": "1"
          }
      ]
  },
  {
      "id": "46",
      "name": "Riduzione costi associati ai consumi termici",
      "langTag": "KPI46",
      "langTagDesc": "KPI46D",
      "formula": "($B-$A)*$C",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "108",
              "name": "Consumi termici pre intervento",
              "var": "A",
              "udm": "MJ",
              "visible": "1"
          },
          {
              "id": "109",
              "name": "Consumi termici post intervento",
              "var": "B",
              "udm": "MJ",
              "visible": "1"
          },
          {
              "id": "110",
              "name": "Costo del combustibile fossile",
              "var": "C",
              "udm": "â‚¬/MJ",
              "visible": "1"
          }
      ]
  },
  {
      "id": "47",
      "name": "Riduzione dell'impatto associato ai consumi termici",
      "langTag": "KPI47",
      "langTagDesc": "KPI47D",
      "formula": "($B-$A)*$C*$D",
      "udm": "Mâ‚¬",
      "visible": "1",
      "indicatori": [
          {
              "id": "111",
              "name": "Consumi termici pre intervento",
              "var": "A",
              "udm": "MJ",
              "visible": "1"
          },
          {
              "id": "112",
              "name": "Consumi termici post intervento",
              "var": "B",
              "udm": "MJ",
              "visible": "1"
          },
          {
              "id": "113",
              "name": "Valore del CO2",
              "var": "C",
              "udm": "â‚¬/ton",
              "visible": "1"
          },
          {
              "id": "114",
              "name": "Impatto del combustibile",
              "var": "D",
              "udm": "ton/MJ",
              "visible": "1"
          }
      ]
  }
]; */

const StepTechnicals = ({ props, scen }) => {
  const idScenario = scen.scenario.id;
  const [scenarioKpi, setScenarioKpi] = useState([]);
  const [sbShowSuccess, setSbShowSuccess] = useState(false);
  const [sbShowError, setSbShowError] = useState(false);
  //console.log("testKpi", testKpi)
  //console.log("scen:", scen);
  console.log('scenarioKpi', scenarioKpi);

  useEffect(() => {
    getScenariKpi(idScenario).then(retrievedKpi => {
      setScenarioKpi(retrievedKpi);
    })
  }, [idScenario]);

  /* const applyFormula = (formula, variables) => {
    formula = formula.replaceAll('$', '');

    let formulaWithValues = formula;

    variables.forEach(el => {
      formulaWithValues = formulaWithValues.replace(el.var, el.value)
    });

    const result = eval(formulaWithValues);

    return ( //bg-secondary 
      <span className="d-block mx-auto text-center py-2 px-5 font-weight-bold rounded-top text-white" style={{width: 'fit-content', marginBottom: '-1px', backgroundColor: '#08A2AA'}}>
        *Risultato*<br/>
        {result}
      </span>
    )
  }; */

  const result = (formula, variables) => {
   formula = formula.replaceAll('$', '');

   let formulaWithValues = formula;

   variables.forEach(el => {
     formulaWithValues = formulaWithValues.replace(el.var, el.value)
   });

   const result = eval(formulaWithValues);

   return ( <>
      <Row className="align-items-center justify-content-end mt-3 px-3">
         <div className="d-flex align-items-center position-absolute mr-3 px-2 bg-white">
            <h5 className="p-0">
               <Trans code="A0472" replacementText="Risultato"/>:&nbsp;{result}
            </h5>
         </div>
      </Row>
   </>
   )
 };

  const onChange = (e, kpiId) => {
    //console.log('e.target.value', e.target.value);
    /* let tempStateKpi = [...formSroi.kpi];

    if (formSroi.kpi.some(kpi => kpi.id === kpiId)) {

      formSroi.kpi.forEach((kpi, index) => {  
        if (kpi.id === kpiId) {
          tempStateKpi[index].indicatori[e.target.name] = Number(e.target.value);
        }
      });

    } else {
      tempStateKpi.push({id: kpiId, indicatori: {[e.target.name]: Number(e.target.value)}});
    }

    setFormSroi(() => ({ 
      ...formSroi,
      kpi: tempStateKpi
    })); */

    let tempScenarioKpi = [...scenarioKpi];

    tempScenarioKpi.filter(kpi => kpi.id === kpiId).map(filteredKpi => {
      filteredKpi.indicatori.filter(ind => ind.id === e.target.name).map(filteredInd => {
        filteredInd.value = Number(e.target.value)
      })
    })

    setScenarioKpi(tempScenarioKpi);
  };

  const submit = async (e) => {
    e.preventDefault();

    let request = scenarioKpi.map(kpi => {
      let tempIndicatori = {};

      kpi.indicatori.forEach(ind => {
        tempIndicatori[ind.id] = ind.value;
      })

      return {id: kpi.id, indicatori: tempIndicatori}
    });

    request = {kpi: request}
    //console.log('request submit:', request);

    const result = await postScenariKpi(request, idScenario);
    //console.log('result submit:', result);

    if (result) {
      setSbShowSuccess(true);

    } else {
      setSbShowError(true);
    }
  };
  
  const renderTooltip = (props) => {
    let message = "";

    if (props.popper.state) {
      message = props.popper.state.options.testObj
    };

    return (
      <Tooltip id="button-tooltip" {...props}>
        <Trans code={message} />
      </Tooltip>
    )
  };

  if (!scen) return null

  return (
<>
    {sbShowSuccess 
      ? ( <StatusBar status="success" timeout={true} /> )
      : ( sbShowError ? <StatusBar status="error" message="*There was an error when saving information!*" timeout={false}/> : null )
    }
    
    { scenarioKpi.length > 0 ?
    <Form onSubmit={submit} id="form-sroi">
      <div className="simple-box" style={{marginTop : '40px'}}>
        <Row>
          <Col className="form-login">
            <h5 className="title-simple-box text-uppercase mb-5">*Form Parametri tecnici*</h5>

            {scenarioKpi.map((kpi, i) => {
              let variables = [];

              return <div key={"kpi-" + i} className="mb-5 rounded" style={{border: '2px solid #C2C923'}}>
              <Row className="mb-4 align-items-center px-3">
                <div className="d-flex align-items-center mt-n3 ml-3 px-2 bg-white">
                  <h5 className="p-0">
                    <Trans code={kpi.langTag} replacementText={kpi.name} />
                  </h5>
                
                  {kpi.langTagDesc ? <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={renderTooltip}
                  popperConfig={{testObj: kpi.langTagDesc}}
                  >
                    <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                  </OverlayTrigger> : null
                  }
                  {/* {kpi.formula} */}
                </div>
              </Row>
              <Form.Group as={Row} className="mb-0 align-items-end px-3" controlId={kpi.id}>
                {kpi.indicatori.map((ind, y) => {
                  variables.push({var: ind.var, value: ind.value});

                  return <Col key={"ind-" + y} lg={4} className="mb-5 px-4">
                    <Form.Label className="mb-3">
                      <Trans code={ind.langTag} replacementText={ind.name} />
                      {ind.langTagDesc ? <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{testObj: ind.langTagDesc}}
                        >
                          <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                        </OverlayTrigger> : null
                      }
                    </Form.Label>
                    <InputGroup style={{paddingRight: '5rem'}}>
                      <Form.Control
                        type="number" 
                        step={0.01}
                        name={ind.id}
                        defaultValue={ind.value}
                        style={{ textAlign: 'right'}}
                        onChange={e => onChange(e, kpi.id)}
                      />
                      {ind.udm ? <InputGroup.Text className="addon-for-number">{ind.udm}</InputGroup.Text> : null}
                    </InputGroup>
                  </Col>
                })}
              </Form.Group>

              {/* {applyFormula(kpi.formula, variables)} */}
              {result(kpi.formula, variables)}
            </div>
            }) }
        </Col>
        </Row>
      </div>

      <Button form="form-sroi" type="submit" className="submit-button sticky-footer-button">SAVE</Button>
    </Form> : <div className="simple-box" style={{marginTop : '40px'}}>
      <span>*Requisiti non soddisfatti*</span>
      </div>}
    </>
  );

}

export default StepTechnicals;
