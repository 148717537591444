import React, { Component } from 'react';

class Ico103 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id='ico-103'
        viewBox='0 0 117.62 136'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
        height={this.props.height}
        width={this.props.width}>
        <path d='M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z' className='esagono' />

        {/* New Icon */}
        <g transform='translate(22, 34) scale(.17)'>
          <svg
            fill='#ffffff'
            height='400px'
            width='400px'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='0 0 512.229 512.229'
            xmlSpace='preserve'>
            <g>
              <g>
                <g>
                  <path
                    d='M511.147,397.188c-0.533-0.96-1.173-1.813-2.027-2.773c-4.907-4.907-9.387-10.133-13.547-15.573
                    c-10.027-12.693-17.28-20.267-39.573-13.867l-93.333,26.667c0-0.747,0.213-1.493,0.213-2.24c0-18.56-14.613-37.333-42.667-37.333
                    l-31.253,0.107c-17.173,0.107-33.067,0.107-49.92,0c-0.853-0.853-1.707-1.813-2.667-2.773
                    c-10.347-10.987-27.733-29.333-72.96-29.333h-56.747v-10.667c0-5.867-4.8-10.667-10.667-10.667H10.667
                    C4.8,298.735,0,303.535,0,309.401v160c0,5.867,4.8,10.667,10.667,10.667H96c5.867,0,10.667-4.8,10.667-10.667v-5.653
                    c21.44,7.253,40.213,13.867,56.427,19.52c52.693,18.56,82.773,28.907,111.04,28.907c36.053,0,69.333-16.96,143.253-55.253
                    c23.893-12.373,53.547-27.733,88.96-45.44C511.573,408.815,513.707,402.415,511.147,397.188z M85.333,458.735h-64V320.068h64
                    V458.735z M407.467,438.041c-124.8,64.64-124.8,64.64-237.227,25.067c-18.027-6.4-39.04-13.76-63.573-21.973v-99.733H163.2
                    c35.84,0,48.213,13.013,57.28,22.613c2.24,2.56,4.693,4.907,7.253,7.04c1.813,1.493,4.16,2.24,6.507,2.347
                    c18.453,0.213,35.52,0.107,54.187,0l31.467-0.107c14.72,0,21.333,8,21.333,16s-6.613,16-21.333,16H202.987
                    c-5.333,0-10.133,3.84-10.88,9.067c-0.96,6.613,4.16,12.267,10.56,12.267H320c13.12,0,23.253-4.267,30.293-10.56
                    c1.493,0.32,3.093,0.213,4.587-0.213l106.667-30.4c9.6-2.773,9.6-2.773,16.96,6.613c1.493,1.92,3.307,4.16,5.333,6.72
                    C454.08,413.935,428.48,427.161,407.467,438.041z'
                  />
                  <path
                    d='M156.907,278.681c5.12-2.987,6.827-9.493,3.84-14.613c-44.693-76.693-19.093-175.573,57.067-220.587
                    c28.373-16.853,61.333-24.32,94.187-21.547c-18.987,7.787-36.8,19.733-35.2,36.8c1.067,10.88,6.08,19.413,14.293,24
                    c6.827,3.84,15.253,4.373,23.893,1.813c0.427,7.36,0.32,14.72-0.533,21.973c-2.987,0.533-6.187,1.28-9.387,2.027
                    c-10.667,2.453-23.573,5.44-30.4,1.387c-1.28-2.88-3.2-5.44-5.653-7.467c-8.32-6.827-22.187-8.213-42.347-4.16
                    c-44.8,8.853-45.227,66.453-45.227,68.8c0,13.76,6.827,39.893,32.64,39.893c3.947,0,7.893-0.213,11.84-0.427
                    c7.787-0.427,16.64-0.96,19.2,1.493c1.493,1.387,2.24,5.12,2.24,10.133c0,2.88,1.173,5.547,3.093,7.573
                    c5.44,5.44,10.987,12.693,11.52,14.4c0,1.067-1.493,4.16-2.56,6.187c-2.133,4.267-4.8,9.6-4.8,15.787
                    c0,8.64,15.467,47.147,32.64,47.147c31.36,0,51.2-14.827,54.293-40c7.04-3.733,13.547-8.427,19.2-14.187
                    c4.587-4.587,6.827-9.493,6.72-14.72c-0.213-7.253-5.013-11.84-8.64-14.933c1.6-1.707,3.413-3.627,4.907-5.227
                    c13.653-14.187,39.147-40.533,18.987-60.587c-3.2-3.2-8.213-4.053-12.267-2.027c-2.56,1.173-5.333,1.92-8.213,2.133
                    c-2.773-9.92-5.653-16.853-8.533-21.653c7.893-3.733,20.693-7.68,33.6-4.48c2.453,0.64,5.013,0.32,7.36-0.853
                    c18.88-9.387,29.867-27.2,35.84-41.387c2.027,3.093,4.16,6.08,6.08,9.28c44.693,76.693,19.093,175.573-57.067,220.587
                    c-5.12,2.987-6.72,9.493-3.733,14.613c2.987,5.12,9.493,6.72,14.613,3.733c86.187-50.88,115.2-162.88,64.64-249.707
                    c-5.653-9.707-12.267-18.88-19.627-27.307l-0.107-0.213c-57.067-65.813-153.28-81.6-228.373-37.227
                    c-86.187,50.88-115.2,162.88-64.64,249.707C145.28,279.855,151.787,281.561,156.907,278.681z M354.453,31.321
                    c22.933,8.747,43.733,22.613,60.48,40.533c-2.773,9.6-10.347,30.293-26.667,40.32c-25.6-4.373-51.2,8.32-58.347,17.067
                    c-4.8,5.867-2.773,11.413-2.027,12.907c1.387,2.88,3.947,5.12,7.04,5.973c2.24,3.52,5.867,13.013,8,21.653
                    c0.427,1.92,1.493,3.627,2.88,5.013c6.613,6.507,15.36,7.04,22.293,5.867c-2.88,7.573-13.973,19.093-19.52,24.747
                    c-8.533,8.853-13.227,13.653-13.227,20.267c0,7.573,5.333,12.267,8.853,15.36l0.32,0.213c-4.8,4.587-10.453,8.32-16.533,10.773
                    c-4.373,1.493-7.253,5.547-7.36,10.133c0,6.187,0,24.96-31.04,25.813c-4.587-4.693-13.12-21.227-13.653-25.813
                    c0-1.067,1.493-4.16,2.56-6.187c2.133-4.267,4.8-9.6,4.8-15.787c0-8.747-8.32-19.413-14.827-26.347
                    c-0.64-9.28-3.627-16.213-8.853-21.12c-9.28-8.853-22.4-8-35.093-7.36c-3.52,0.213-7.04,0.427-10.56,0.427
                    c-10.987,0-11.307-17.813-11.307-18.56c0-0.427,0.32-42.453,28.16-47.893c16.427-3.307,22.613-1.28,24.213-0.533
                    c0.427,1.92,1.493,3.627,2.773,4.907c14.4,14.293,36.16,9.28,52.053,5.653c5.333-1.28,10.88-2.56,14.187-2.56
                    c5.333,0,9.813-3.947,10.56-9.173c4.267-29.547,1.92-47.68-6.827-53.973c-4.587-3.307-10.347-3.627-15.893-0.853
                    c-4.267,2.133-8.213,2.667-10.453,1.28c-2.56-1.387-3.307-5.227-3.413-7.36c-0.533-5.867,25.493-18.773,55.573-25.067
                    C353.92,31.535,354.133,31.321,354.453,31.321z'
                  />
                </g>
              </g>
            </g>
          </svg>
        </g>
      </svg>
    );
  }
}

export default Ico103;
