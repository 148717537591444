import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import Flourish from '@flourish/live-api';
const FlourishKey = process.env.REACT_APP_FLOURISH_API_KEY;
const FlourishComponent = ({ visualizationId, chartId, style, data, dataToRespond }) => {
  const [chartFlourish, setChartFlourish] = useState(null);
  const containerRef = useRef(null);
  const opts = {
    api_key: FlourishKey,
    container: `#${chartId}`,
    base_visualisation_id: visualizationId,
    ...data,
    width: '100%',
    height: '450px',
  };

  useLayoutEffect(() => {
    if (containerRef.current && !chartFlourish) {
      setChartFlourish(new Flourish.Live(opts));
    }
  }, [containerRef]);

  useEffect(() => {
    if (dataToRespond?.length && chartFlourish) {
      const resultObject = {};
      const count = dataToRespond[0].length - 1;
      console.log('count', count);
      const bindingsArray = Array.from({ length: count }, (_, index) => index + 1);
      console.log('bindingsArray', bindingsArray);
      if (opts.bindings.data.value) opts.bindings.data.value = bindingsArray;
      if (opts.bindings.data.values) opts.bindings.data.values = bindingsArray;
      opts.data.data = dataToRespond;
      resultObject[0] = { ...opts.metadata.data[0] };
      bindingsArray.slice(1).forEach((_, index) => {
        resultObject[index + 1] = { ...opts.metadata.data[1] };
      });
      opts.metadata.data = resultObject;
      chartFlourish.update(opts);
    }
  }, [dataToRespond]);

  return <div id={chartId} ref={containerRef} style={style}></div>;
};

export default FlourishComponent;
