import React, { useState, useEffect, useContext } from 'react';
import { getOrganizations } from '@lib/api';
import ImageBase64Converter from './adminManagement/imageBase64Converter';
import { LogoContext } from './LogoContext';
const UserLogo = ({ className }) => {
  const { logo, setLogo } = useContext(LogoContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const orgResults = await getOrganizations();
      if (abortController.signal.aborted === false) {
        setLogo(
          orgResults?.data
            ?.filter((org) => org.id === user.idOrganization)
            ?.map((filteredOrg) => {
              return filteredOrg.image;
            })
            .join()
        );
      }
    };
    if (user && user.idOrganization) {
      fetchData();
    }
    return () => {
      abortController.abort();
    };
  }, [user, setLogo]);
  if (!user) return null;
  return <ImageBase64Converter className={className} base64Image={logo} />;
};

export default UserLogo;
