import React from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import { useQuery, gql } from '@apollo/client'

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


const TabQuery = gql`
{
  homepage{
    tab{
      text:text_it
      name:name_it
      image:image{
        name
        url
      }
      number{
        text:text_it
        number:number
      }
    }
  }
}
`

const options = {
  buttons: {
    showNextButton: false,
    showPrevButton: false,
    showAutoplayButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
  caption: {
    showCaption: false,
  }
};

const ImpactFinance = props => {

  const { loading, error, data } = useQuery(TabQuery)

  if (error) return <p>Error</p>
  if (loading) return <div>Loading</div>
  if (!data.homepage?.tab) return null
  const tabHome = data.homepage.tab
  return (
    <div id="finance" className="tab-box">
    <Container id="finance">
        {/*eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid*/}
      <a name="servizi"></a>
      <Row>
        <Col>
          <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
            {tabHome
              // Filtro "frù-frù" per rimuovere finanza sostenibile
              .filter(t => t.name !== 'FINANZA SOSTENIBILE')
              .map((item, key) => {
              return (
                <Tab eventKey={key} title={item.name} key={key}>
                  <Row>
                    <Col md={item.image.url !== null ? 6 : 12}>
                      <div className="text" dangerouslySetInnerHTML={{ __html: item.text }} />
                    </Col>
                    {item.image.url !== null && (
                      <Col md={6}>
                        <SimpleReactLightbox>
                          <SRLWrapper options={options}>
                            <a href={item.image.url} className="light-box-link">
                              <img src={item.image.url} alt={item.image.name} title={item.image.name} className="img-fluid" />
                              <img src="/img/ico-zoom.svg" alt="" className="ico-absolute" width="57px" />
                            </a>
                          </SRLWrapper>
                        </SimpleReactLightbox>
                    </Col>
                    )}
                  </Row>
                  {item.number &&
                    <Row className="number-row">
                      {item.number.map((num, key) => {
                        return (
                          <Col md={4} key={key}>
                            <Row>
                              <Col md="auto" xs={3} className="number">
                                {num.number}
                              </Col>
                              <Col className="align-self-center">
                                <div dangerouslySetInnerHTML={{ __html: num.text }} />
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                    </Row>
                  }
                </Tab>
              )
            })}   
          </Tabs>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default ImpactFinance