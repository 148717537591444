import React, { Component } from "react"

class Ico29 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-29" viewBox="0 0 117.62 136"  fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(31.8,38)">
          <path d="M50.3335 0H10.3335C7.68133 0 5.13779 1.05357 3.26243 2.92893C1.38706 4.8043 0.333496 7.34784 0.333496 10V50C0.333496 52.6522 1.38706 55.1957 3.26243 57.0711C5.13779 58.9464 7.68133 60 10.3335 60H50.3335C51.2175 60 52.0654 59.6488 52.6905 59.0237C53.3156 58.3986 53.6668 57.5507 53.6668 56.6667V3.33333C53.6668 2.44928 53.3156 1.60143 52.6905 0.976311C52.0654 0.351189 51.2175 0 50.3335 0ZM10.3335 6.66667H47.0002V40H10.3335C9.19635 40.009 8.06908 40.2119 7.00016 40.6V10C7.00016 9.11594 7.35135 8.2681 7.97647 7.64298C8.60159 7.01786 9.44944 6.66667 10.3335 6.66667ZM10.3335 53.3333C9.44944 53.3333 8.60159 52.9821 7.97647 52.357C7.35135 51.7319 7.00016 50.8841 7.00016 50C7.00016 49.1159 7.35135 48.2681 7.97647 47.643C8.60159 47.0179 9.44944 46.6667 10.3335 46.6667H47.0002V53.3333H10.3335Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico29