import React from 'react';
import { Col, Form, Button } from 'react-bootstrap';

const PasswordRecoveryEmail = ({ loginForm, setLoginForm, isLoading, error, handleLogin }) => {
  return (
    <Form className='w-100'>
      <Col style={{ position: 'relative' }}>
        <h5 className='login-header-main'>
          <p>Recupera password</p>
        </h5>
      </Col>

      <Form.Group controlId='formBasicText'>
        <Form.Control
          type='text'
          placeholder='Email'
          value={loginForm.email}
          onChange={({ target: { value } }) =>
            setLoginForm({
              ...loginForm,
              email: value,
            })
          }
        />
      </Form.Group>

      <Button disabled={isLoading} variant='info' type='submit' onClick={handleLogin}>
        Submit
      </Button>

      {error && (
        <Form.Text className='text-muted'>
          <h5>{error}</h5>
        </Form.Text>
      )}
    </Form>
  );
};

export default PasswordRecoveryEmail;
