import { date } from '@lib/helpers/index';
import { Trans } from '@lib/i18n/index';
import React, { useEffect, useRef, useState } from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import DocumentsActionBtn from './DocumentsActionBtn';
import DocumentItemsWrapper from './DocumentItemsWrapper';
import OEIframePreview from 'components/scenariodashboard/oeiframepreview/OEIframePreview';
import CustomSpinner from 'components/CustomSpinner';
import {
  downloadEntityDocuments,
  getQuestionnaireDownload,
  getCorporateClassQuestionnaireDownload,
} from '@lib/api/index';

const downloadFile = (blob, filename) => {
  const fileUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const handleDownloadQuestions = async (
  entityId,
  isDownload = true,
  setLoading = null,
  handleStatusBar,
  isCorporate = false
) => {
  if (isDownload && setLoading) setLoading(true);

  let downloadResponse;
  if (isCorporate) {
    downloadResponse = await getCorporateClassQuestionnaireDownload(entityId);
  } else {
    downloadResponse = await getQuestionnaireDownload(entityId);
  }
  if (downloadResponse.ok === true) {
    handleStatusBar('success', <Trans code='A0657' />, true);
    const blob = await downloadResponse.blob();
    const file = new File([blob], 'questions.xlsx');
    downloadFile(file, `QuestionsFile_${entityId}.xlsx`);
    if (isDownload && setLoading) setLoading(false);
  } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
    handleStatusBar('error', downloadResponse.message, false);
    setLoading(false);
  }
};

const handleDownload = async (
  entityId,
  isDownload = true,
  setLoading = null,
  handleStatusBar,
  data,
  setBlob
) => {
  if (isDownload && setLoading) setLoading(true);

  const downloadResponse = await downloadEntityDocuments(entityId);

  if (downloadResponse.ok === true) {
    const blob = await downloadResponse.blob();
    if (isDownload) {
      handleStatusBar('success', <Trans code='A0657' />, true);
      const contentType = downloadResponse.headers.get('Content-Type');
      let filename = 'downloaded_file';
      if (data.fileName) {
        filename = data.fileName;
      }
      const file = new File([blob], filename, { type: contentType });

      downloadFile(file, filename);

      setLoading(false);
      return;
    } else {
      setBlob(blob);
    }
  } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
    handleStatusBar('error', 'Error', false);
    setLoading(false);
  }
};

const DocumentDownloadItemTr = ({
  data,
  handleStatusBar,
  isQuestions,
  classId,
  isCorporate = false,
}) => {
  const [show, setShow] = useState(false);
  const [blob, setBlob] = useState(null);

  useEffect(() => {
    if (show) {
      handleDownload(data.id, false, false, handleStatusBar, data, setBlob);
    }
  }, [show]);

  return (
    <>
      <tr>
        <td>{!isQuestions ? data.fileName : 'Question File'}</td>
        {!isQuestions && <td className='text-center'>{date(data.createdAt)}</td>}

        <td style={{ minWidth: '150px' }}>
          <DocumentsActionBtn
            show={show}
            setShow={setShow}
            entityId={isQuestions ? classId : data.id}
            handleDownload={isQuestions ? handleDownloadQuestions : handleDownload}
            isQuestions={isQuestions}
            handleStatusBar={handleStatusBar}
            data={data}
            isCorporate={isCorporate}
          />
        </td>
      </tr>
      {show && !isQuestions && (
        <tr>
          <td colSpan='3'>
            <OEIframePreview blob={blob} isLoading={!show} />
          </td>
        </tr>
      )}
    </>
  );
};

const DocumentDownloadItem = ({
  title,
  subtitleTransCode,
  sectionTitleTransCode,
  documents,
  isLoading,
  handleStatusBar,
  isQuestions = false,
  classId = null,
  isCorporate = false,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const containerRef = useRef(null);
  return (
    <DocumentItemsWrapper
      title={title}
      subtitleTransCode={subtitleTransCode}
      sectionTitleTransCode={sectionTitleTransCode}>
      <div
        style={{
          maxHeight: '400px',
          minHeight: '150px',
          overflowY: 'auto',
          position: 'relative',
        }}
        ref={containerRef}>
        {isLoading && (
          <CustomSpinner
            style={{
              position: 'absolute',
              width: '100%',
              height: `${containerRef?.current?.scrollHeight}px`,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              backdropFilter: 'blur(7px)',
            }}
          />
        )}
        {!documents.length &&
          !isLoading &&
          (!isQuestions ? (
            <div>
              <h4>
                <Trans code='A0819' />
              </h4>
            </div>
          ) : (
            <Button
              className='d-flex'
              onClick={() =>
                handleDownloadQuestions(
                  classId,
                  true,
                  setButtonLoading,
                  handleStatusBar,
                  isCorporate
                )
              }
              disabled={buttonLoading}>
              <span>
                <Trans code='A0831' />
              </span>
              {buttonLoading && (
                <span style={{ color: '#ffffff' }} className='d-flex align-items-center ml-2'>
                  <Spinner animation='border' size='sm' />
                </span>
              )}
            </Button>
          ))}
        {documents.length > 0 && (
          <Table className='custom-table table-borderless'>
            <thead>
              <tr>
                <th>
                  <Trans code='A0820' />
                </th>
                {!isQuestions && (
                  <th className='text-center'>
                    <Trans code='A0821' />
                  </th>
                )}

                <th></th>
              </tr>
            </thead>
            <tbody>
              {!isQuestions &&
                documents?.map((row, index) => (
                  <DocumentDownloadItemTr
                    key={index}
                    data={row}
                    handleStatusBar={handleStatusBar}
                    isQuestions={isQuestions}
                    classId={classId}
                  />
                ))}
              {isQuestions && (
                <DocumentDownloadItemTr
                  data={documents}
                  handleStatusBar={handleStatusBar}
                  isQuestions={isQuestions}
                  classId={classId}
                  isCorporate={isCorporate}
                />
              )}
            </tbody>
          </Table>
        )}
      </div>
    </DocumentItemsWrapper>
  );
};

export default DocumentDownloadItem;
