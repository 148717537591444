import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { FormControl, ButtonToolbar, InputGroup } from 'react-bootstrap';

import { Lens } from '@icons';
import { getOEInterventionModules } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererInterventionType from '@components/adminManagement/interventionTypeManagement/ActionBtnRendererInterventionType';
import { ShowInvestmentRenderer } from '@components/adminManagement/ShowRenderer';

import TableFilter from 'components/adminManagement/tableFilters/tableFilter';

import { Trans, propTrans } from '@lib/i18n/index';

// const mock = [
//   {
//     oesectorCode: 'A01',
//     name: 'Agricolture',
//     invTypes: [
//       {
//         code: 'A01-01',
//         name: 'trattori',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-02',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-03',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-04',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-05',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//     ],
//   },
//   {
//     oesectorCode: 'A02',
//     name: 'Mining',
//     invTypes: [
//       {
//         code: 'A01-01',
//         name: 'trattori',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-02',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-03',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-04',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//       {
//         code: 'A01-05',
//         name: 'computer',
//         description: '',
//         associatedClasses: [],
//       },
//     ],
//   },
// ];

const InterventionTypes = () => {
  const [intTypes, setIntTypes] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  // console.log('intTypes', intTypes);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      getOEInterventionModules().then((retrievedIntModules) => {
        setIntTypes(retrievedIntModules.data);
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onGridReady = useCallback(() => {
    getOEInterventionModules().then((retrievedIntModules) => {
      setIntTypes(retrievedIntModules.data);
    });

    //sort the column in order
    gridRef.current.columnApi.applyColumnState({
      state: [
        {
          colId: 'id',
          sort: 'desc',
        },
      ],
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerValueGetter: () => propTrans({ prop: 'A0669' }),
        children: [
          {
            headerValueGetter: () => propTrans({ prop: 'A0712' }),
            field: 'code',
            maxWidth: 130,
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              return params.data.code;
            },
          },
          {
            headerValueGetter: () => propTrans({ prop: 'A0715' }),
            field: 'name',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              return params.data.name;
            },
          },
        ],
      },
      {
        headerValueGetter: () => propTrans({ prop: `A0710` }),
        flex: 2,
        children: [
          {
            headerValueGetter: () => propTrans({ prop: `A0713` }),
            columnGroupShow: '',
            field: 'invTypesQuantity',
            //Add the prop below if you want to check equal or not equal with custom filter
            typeOfFilter: 'equalNotEqual',
            filter: TableFilter,
            filterParams: {
              title: <Trans code='A0713' />,
              values: [
                { value: 0, label: propTrans({ prop: 'A0719' }) },
                { value: '', label: propTrans({ prop: 'A0720' }) },
              ],
            },
            valueGetter: (params) => {
              return params.data.invTypesQuantity;
              // return params.data.invTypes.length;
            },
          },
          {
            headerValueGetter: () => propTrans({ prop: `A0714` }),
            columnGroupShow: 'open',
            flex: 2,
            field: 'invTypes',
            filter: 'agTextColumnFilter',
            cellRenderer: ShowInvestmentRenderer,
          },
        ],
      },
      {
        //   headerName: 'Actions',
        pinned: 'right',
        maxWidth: 65,
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionBtnRendererInterventionType,
        cellRendererParams: {
          setRefresh: setRefresh,
        },
      },
    ]);
  }, []);

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='interventionTypes' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-6'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0710' />
            </h5>
          </div>
          <div className='col-6 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder={propTrans({ prop: 'A0035' }) + '...'}
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={intTypes}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                //onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                <Trans code='A0711' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionTypes;
