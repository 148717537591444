import React, { useEffect, useState } from 'react';
import StatusBar from 'components/wizard/StatusBar';

import QuestionsUploadWrapper from './QuestionsUploadWrapper';
import DocumentDownloadItem from 'components/Documents/DocumentDownloadItems';

import { getQuestionnaire, getCorporateClassQuestionnaire } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';

const QuestionsWrapper = ({ classId, isCorporate = false }) => {
  const [sbShow, setSbShow] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar key={Math.random()} status={status} message={message} timeout={timeout} />
    );
  };
  const transformClassData = (data, setState) => {
    const dataToStore = data;
    setState(dataToStore || []);
  };

  useEffect(() => {
    if (classId) {
      fetchData(
        !isCorporate ? getQuestionnaire : getCorporateClassQuestionnaire,
        setDocuments,
        null,
        [classId],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
  }, [classId, isCorporate]);

  useEffect(() => {
    if (refresh) {
      fetchData(
        !isCorporate ? getQuestionnaire : getCorporateClassQuestionnaire,
        setDocuments,
        null,
        [classId],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
    setRefresh(false);
  }, [refresh]);

  return (
    <div className='px-5'>
      <QuestionsUploadWrapper
        title={'Upload Questionario'}
        subtitleTransCode={'A0826'}
        classId={classId}
        handleStatusBar={handleStatusBar}
        setRefresh={setRefresh}
        isCorporate={isCorporate}
      />
      <hr className='mb-5' style={{ maxWidth: '80%' }} />
      <DocumentDownloadItem
        title='Questionari'
        subtitleTransCode={`A0829`}
        sectionTitleTransCode={`A0830`}
        documents={documents}
        isLoading={isLoading}
        handleStatusBar={handleStatusBar}
        isQuestions={true}
        classId={classId}
        isCorporate={isCorporate}
      />
      {sbShow}
    </div>
  );
};

export default QuestionsWrapper;
