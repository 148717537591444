import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Trans, propTrans, propTransTooltip, ApiTrans } from '@lib/i18n';
import { rounder } from '@lib/helpers/index';

const legendStyles = {
  bullet: {
    display: 'inline-block',
    width: '1em',
    height: '1em',
    borderRadius: '0.5em',
    top: '0.15em',
    position: 'relative',
  },
  label: {
    display: 'inline-block',
    width: '50%',
    lineHeight: '2.5em',
  },
};

const LegendBullet = ({ label, color, scope, className }) => {
  console.log('SCOPE', scope, label);
  return (
    <span className={className}>
      <i style={{ ...legendStyles.bullet, backgroundColor: color }} />{' '}
      <ApiTrans scope={scope} prop={scope === 'jobs' ? label.toLowerCase() : label} />
    </span>
  );
};

const NewLegend = ({ data, scope, colors, className, values, allData }) => {
  console.log('allData', data, scope);

  return (
    <>
      {data &&
        data
          .filter((el) => el.propertyName !== 'total')
          .map((d, index) => {
            console.log('PROPONAME', d.propertyName);
            return (
              <Row className='justify-content-between align-items-center' key={index}>
                <Col style={{ flexBasis: 'min-content' }}>
                  <LegendBullet
                    className={`span-legend ${className}`}
                    label={d.propertyName}
                    color={colors[index]}
                    scope={scope}
                  />
                </Col>
                {values ? (
                  <>
                    <Col className='text-right'>{rounder(d.propertyValue)}</Col>
                  </>
                ) : null}
              </Row>
            );
          })}

      <table>
        <tbody>
          {allData && (
            <tr
              key={`${allData}`}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'inline-table',
                marginBottom: '0',
              }}>
              <th className='va-desc udm text-right font-weight-normal'>
                <Trans code='A0053' />
              </th>
              <th className='value-label total-left'>
                {propTrans({ prop: 'A0111', scope: 'gdp' })}
              </th>
              <th className='total-right'>
                {rounder(
                  allData
                    .filter((el) => el.propertyName !== 'total')
                    .reduce((acc, curr) => acc + curr.propertyValue, 0)
                )}
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default NewLegend;
