import React from "react";
import { Ico33, Ico42, Ico8 } from "@icons/new"; //19 36 91
import { Link } from 'react-router-dom'

const Administration = () => {
 
  return (
    <ul className="menu-container admin-page">
      <li className="menu-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link to={{ pathname: `/administration/users` }}>
         <span className={(window.location.pathname===('/administration/users'))? 'menu-link active' : 'menu-link'}>
            <div className="nav-ico"><Ico42 /></div>
            <div className="nav-text">Users</div>
         </span>
        </Link>
        <Link to="/administration/organizations">
         <span className={(window.location.pathname===('/administration/organizations'))? 'menu-link active' : 'menu-link'}>
            <div className="nav-ico"><Ico8 /></div>
            <div className="nav-text">Organizations</div>
         </span>
        </Link>
        <Link to="/administration/texts">
         <span className={window.location.pathname.startsWith('/administration/texts') ? 'menu-link active' : 'menu-link'}>
            <div className="nav-ico"><Ico33 /></div>
            <div className="nav-text">Texts</div>
         </span>
        </Link>
      </li>
    </ul>
  );
}
export default Administration;