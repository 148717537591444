import React from 'react';
import { rounder } from '@lib/helpers';
import { Trans } from '@lib/i18n';
const EmploymentImpact = ({ jobs, filterForRegion }) => {
  console.log('JOBS', jobs);
  return (
    <div className='simple-box'>
      <div className='row'>
        <div className='col-3 investment-infobox va-number'>
          <div>
            <h2>
              {rounder(
                jobs.empByMacrosector
                  .filter((gdp) =>
                    filterForRegion === 'total' ? gdp.region === '' : gdp.region === filterForRegion
                  )
                  .reduce((acc, curr) => acc + curr.propertyValue, 0)
              )}
            </h2>
            <span className='va-desc'>
              <Trans code='A0054' />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentImpact;
