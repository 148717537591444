import React from 'react'
import { gql } from '@apollo/client'
import GenericMap from '../components/map/generic';
import { Container, Row, Col } from 'react-bootstrap';
import { Trans } from '@lib/i18n'


const QUERY = gql`
{
  bibliographies(sort: "date_it:DESC"){
    title_it
    date_it
    slug_it
    author_it
    link_it
    editor_it
    abstract_it
    image_it{
      url
      name
    }
  }
}
`

const BibliografiaEssenziale = () => {


  return <div id="bibliografia-essenziale" className="megaContent feed-oe">
    <Container fluid>
      <div className="simple-box">
        <Row>
          <Col xs={12}>
            <h2><Trans code="F0078" /></h2>
          </Col>
        </Row>
        <GenericMap type="bibliographies" query={QUERY} path="/essential-bibliographies" />
      </div>
    </Container>
  </div>
}

export default BibliografiaEssenziale