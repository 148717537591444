import React from 'react';

const CorporateEsgAnalysesCompanySelector = ({
  companyId,
  companyName,
  setSelectedCompany,
  selectedCompany,
  image = null,
}) => {
  return (
    <div
      className={`esg-analysis-card esg-analysis-companies-card d-flex flex-column justify-content-center align-items-center text-center ${
        selectedCompany && selectedCompany !== companyId ? 'deactivated' : ''
      }`}
      onClick={() =>
        setSelectedCompany((prevState) => (prevState === companyId ? null : companyId))
      }>
      <div className='d-flex justify-content-center align-items-start image-container'>
        {image?.includes('data') ? (
          <img className='logo esg-company-logo' src={image} alt={'logo'} />
        ) : (
          <span className='placeholder placeholder-company-esg' style={{ borderRadius: '5px' }}>
            Placeholder
          </span>
        )}
      </div>
      <div>
        <div className='p-0 m-0'>{companyName || companyId}</div>
      </div>
    </div>
  );
};

export default CorporateEsgAnalysesCompanySelector;
