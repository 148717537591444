import React from 'react';
import { Footer, Header, Menu, Sidebarmenu } from './components';
import { LogoProvider } from 'components/LogoContext';

const LayoutSideMenu = ({ children }) => (
  <LogoProvider>
    <div className='App'>
      <Sidebarmenu />
      <Header />
      <div className='main-container'>
        <div className='desktop-menu'>
          <Menu />
        </div>
        <div className='content-page'>{children}</div>
      </div>
      <Footer />
    </div>
  </LogoProvider>
);

const LayoutBase = ({ children }) => (
  <LogoProvider>
    <div className='App'>
      <Header />
      <div className='main-container'>
        <div className='content-page'>{children}</div>
      </div>
      <Footer />
    </div>
  </LogoProvider>
);

const LayoutSimple = ({ children }) => (
  <div className='App'>
    {children}
    <Footer />
  </div>
);

export { LayoutBase, LayoutSimple, LayoutSideMenu };
