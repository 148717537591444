import React from 'react';
import style from './Table.module.css';

export default function FilterPopup({ children, onClickOutside }) {
  const ref = React.useRef();
  React.useEffect(() => {
    const onClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    setTimeout(() => {
      document.addEventListener('click', onClick);
    });
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [onClickOutside]);
  return (
    <div className={style.styledFilterPopup} ref={ref}>
      {children}
    </div>
  );
}
