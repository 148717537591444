import { Card } from 'react-bootstrap';
import { IcoAnalisysBox } from '@icons';
import { Trans, propTrans } from '@lib/i18n'
import { rounder } from '@lib/helpers/index'
import BillboardChart from 'react-billboardjs'
import { bar } from 'billboard.js'
import ChartLegend from '@components/ChartLegend'
import { chartColors as colors } from '@lib/helpers'

const TaxesChart = ({ taxesData }) => {
  const keys = Object.keys(taxesData).filter(k => k !== 'total')
  const chartColors = keys.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {})
  const colorFunction = (c, d) => chartColors[d.id]

  const chartData = {
    columns: keys.map(k => [k, taxesData[k]]),
    type: bar(),
    color: colorFunction
  }

  const axis = {
    x: {
      show: false
    }
  }

  return <Card className="cards-utente d-none d-md-block">
    <Card.Body>
      <IcoAnalisysBox className="logo-card ico-box-blue" />
      <Card.Title><Trans code="A0202" /></Card.Title>
      <BillboardChart
        data={chartData}
        axis={axis}
        legend={{ show: false }}
        transition={{ duration: 800 }}
        bar={{
          width: 35,
          padding: 10
        }}
        grid={{ focus: { show: false } }}
        tooltip={{
          grouped: false,
          format: {
            title: () => '',
            value: d => rounder(d),
            name: n => propTrans({ prop: n, scope: 'taxes' })
          }
        }}
      />
      <ChartLegend data={keys} colors={colors} scope="taxes" />
      <p className="va-desc"><Trans code="A0053" /></p>
    </Card.Body>
  </Card>
}

export default TaxesChart