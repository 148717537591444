import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderTooltip = (props) => {
  let message = '';

  //Sometimes, props.popper.state is undefined.
  //It runs this function enough times that state gets a value
  if (props.popper.state) {
    message = props.popper.state.options.testObj;
  }

  return (
    <Tooltip id='button-tooltip' {...props}>
      {message}
    </Tooltip>
  );
};

const TruncatedValue = ({
  value,
  checkingValue = 30,
  substringValues = [0, 27],
  htmlTag = 'span',
  classNames = null,
  style = null,
  showDelay = 200,
  hideDelay = 300,
}) => {
  const props = {};

  if (classNames) {
    props.className = classNames;
  }

  if (style) {
    props.style = style;
  }

  if (value.length > checkingValue) {
    return (
      <OverlayTrigger
        placement='top'
        delay={{ show: showDelay, hide: hideDelay }}
        overlay={renderTooltip}
        popperConfig={{ testObj: value }}>
        {React.createElement(htmlTag, props, value.substring(...substringValues) + '...')}
      </OverlayTrigger>
    );
  } else {
    return <>{React.createElement(htmlTag, props, value)}</>;
  }
};

export default TruncatedValue;
