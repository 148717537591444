import React from 'react';

import { Trans } from '@lib/i18n/index';
import { Card } from 'react-bootstrap';
import EsgChartsComponent from './esgChartsComponent';
const titles = {
  A0842: {
    it: 'Dimensione Ambientale',
    en: 'Environmental Dimension',
    es: 'Dimensión ambiental',
  },
  A0843: { it: 'Dimensione Sociale', en: 'Social Dimension', es: 'Dimensión social' },
  A0844: {
    it: 'Dimensione di Governance',
    en: 'Governance Dimension',
    es: 'Dimensión de gobernanza',
  },
};
const EsgChartsMainCard = ({ data, category, langCode, icon, subCategories }) => {
  const language = localStorage.getItem('externalyticsLanguage');

  return (
    <Card className='w-100' style={{ flex: '1 1 0', minWidth: '350px' }}>
      <Card.Body>
        {icon}
        <Card.Title>
          <Trans code={langCode} />
        </Card.Title>
        <EsgChartsComponent
          data={data}
          category={category}
          subCategories={subCategories}
          langCode={titles?.[langCode]?.[language]}
        />
      </Card.Body>
    </Card>
  );
};

export default EsgChartsMainCard;
