import React from 'react'
import { Trans } from '@lib/i18n'

const CountryHeader = () => 
  <div className="row">
    <div className="col">
      <h2>
      {(window.location.pathname===('/countryprofile/components/key-indicators') ? 'Key Indicators' : window.location.pathname===('/countryprofile/components/footprint') ? 'Footprint' : window.location.pathname===('/countryprofile/components/structure') ? 'Structure' : '')}
      
        <span className="scenario-details no-pipe"><Trans code="A0071" /> 10/05/2021</span>
      </h2>
    </div>
  </div>

export default CountryHeader