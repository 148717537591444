import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import { IcoPlus, Lens } from '@icons';
import {
  getTextTranslations,
  getTextTranslation,
  postCreateTranslation,
  putEditTranslation,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererText from '@components/adminManagement/textsManagement/ActionBtnRendererText';
import StatusBar from 'components/wizard/StatusBar';

const initialState = {
  code: '',
  IT: { text: '', hover: '' },
  EN: { text: '', hover: '' },
  ES: { text: '', hover: '' },
};

const TextsManagement = (data) => {
  const translationCode = data.match.params.translationCode;
  const [translations, setTranslations] = useState([]);
  const [newTranslation, setNewTranslation] = useState(initialState);
  const [detailTranslation, setDetailTranslation] = useState(initialState);
  const [originaCode, setOriginalCode] = useState(null);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  console.log('translationCode', translationCode);
  console.log('translations:', translations);
  console.log('newTranslation', newTranslation);
  console.log('detailTranslation', detailTranslation);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    if (translationCode) {
      getTextTranslation(translationCode).then((retrievedTranslation) => {
        console.log('retrievedTranslation:', retrievedTranslation);

        if (retrievedTranslation) {
          setDetailTranslation(retrievedTranslation);
          setOriginalCode(retrievedTranslation.code);
          handleShowModal(setShowModalEdit);
        } else {
          handleShowModal(setShowModalWarning);
        }
      });
    }

    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      getTextTranslations().then((retrievedTranslations) => {
        setTranslations(retrievedTranslations);
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onChange = (e, fieldname, setStatus, status) => {
    //console.log('onChange e', e);

    if (fieldname) {
      setStatus(() => ({
        ...status,
        [fieldname]: {
          ...status[fieldname],
          [e.target.name]: e.target.value,
        },
      }));
    } else {
      setStatus(() => ({
        ...status,
        [e.target.name]:
          e.target.name === 'code'
            ? e.target.value.toUpperCase().replaceAll(' ', '')
            : e.target.value,
      }));
    }
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  /* const onFirstDataRendered = useCallback((params) => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onGridReady = useCallback(() => {
    getTextTranslations().then((retrievedTranslations) => {
      setTranslations(retrievedTranslations);
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  /* const onVirtualRowRemoved = useCallback(e => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: false,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  const columnDefs = [
    {
      headerName: 'Code*',
      maxWidth: 150,
      field: 'code',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.code;
      },
    },
    {
      headerName: 'IT',
      field: 'IT',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.IT.text;
      },
    },
    {
      headerName: 'EN',
      field: 'EN',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.EN.text;
      },
    },
    {
      headerName: 'ES',
      field: 'ES',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return params.data.ES.text;
      },
    },
    {
      pinned: 'right',
      maxWidth: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: ActionBtnRendererText,
      cellRendererParams: {
        setRefresh: setRefresh,
      },
      /* cellRendererSelector: (params) => {
            return {
               component: ActionBtnRendererText,
               params: {setRefresh: setRefresh}
            };
         } */
    },
  ];

  const handleNewTranslation = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const isCodeFilled = newTranslation.code ? true : false;

    const areTextsFilled = [
      newTranslation.IT.text,
      newTranslation.EN.text,
      newTranslation.ES.text,
    ].every((prop) => prop !== '' && prop !== null)
      ? true
      : false;

    if (!isCodeFilled || !areTextsFilled) {
      handleStatusBar('error', 'Compila i campi obbligatori.*', false);
      return;
    }

    const createResult = await postCreateTranslation(newTranslation);
    console.log('createResult', createResult);

    if (createResult.status === true) {
      handleStatusBar('success', 'Traduzione creata con successo*', true);

      setTimeout(() => {
        setShowModalNew(false);
        setRefresh(true);
      }, 2000);
    }
    //   else if (createResult.duplicate) {
    //    handleStatusBar('error', 'Il codice inserito è già presente.*', false);
    // }
    else {
      handleStatusBar(
        'error',
        createResult.message || 'There was an error when saving information.*',
        false
      );
    }
  };

  const handleEditStatus = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const isCodeFilled = detailTranslation.code ? true : false;

    const areTextsFilled = [
      detailTranslation.IT.text,
      detailTranslation.EN.text,
      detailTranslation.ES.text,
    ].every((prop) => prop !== '' && prop !== null)
      ? true
      : false;

    if (!isCodeFilled || !areTextsFilled) {
      handleStatusBar('error', 'Compila i campi obbligatori.*', false);
      return;
    }

    const editResult = await putEditTranslation(detailTranslation, originaCode);
    console.log('editResult', editResult);

    if (editResult.status === true) {
      handleStatusBar('success', 'Informazioni modificate con successo*', true);

      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2000);
    }
    //  else if (editResult.duplicate) {
    //    handleStatusBar('error', 'Il codice inserito è già presente.*', false);

    //  }
    else {
      handleStatusBar(
        'error',
        editResult.message || 'There was an error when saving information.*',
        false
      );
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) !== 1) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>USER NOT AUTHORIZED</div>
        </div>
      </div>
    );
  }

  return (
    <div id='translations' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-3'>
          <div className='col-6'>
            <h5 className='title-simple-box mb-0'>Testi e traduzioni</h5>
          </div>
          <div className='col-6 text-right'>
            <ButtonToolbar className='justify-content-end'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>Nuova traduzione</span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={translations}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                Traduzioni per pagina:&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size='lg'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>Crea una nuova traduzione*</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewTranslation} id='form-admin-translation'>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='code'>
                <Form.Label className='mb-3'>
                  Code&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='code'
                  maxLength='11'
                  value={newTranslation.code}
                  onChange={(e) => onChange(e, undefined, setNewTranslation, newTranslation)}
                />
              </Form.Group>
              <Col></Col>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='ita-text'>
                <Form.Label className='mb-3'>
                  Italian text:&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='text'
                  value={newTranslation.IT.text ?? ''}
                  onChange={(e) => onChange(e, 'IT', setNewTranslation, newTranslation)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='ita-hover'>
                <Form.Label className='mb-3'>Italian hover:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='hover'
                  value={newTranslation.IT.hover ?? ''}
                  onChange={(e) => onChange(e, 'IT', setNewTranslation, newTranslation)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='eng-text'>
                <Form.Label className='mb-3'>
                  English text:&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='text'
                  value={newTranslation.EN.text ?? ''}
                  onChange={(e) => onChange(e, 'EN', setNewTranslation, newTranslation)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='eng-hover'>
                <Form.Label className='mb-3'>English hover:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='hover'
                  value={newTranslation.EN.hover ?? ''}
                  onChange={(e) => onChange(e, 'EN', setNewTranslation, newTranslation)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='esp-text'>
                <Form.Label className='mb-3'>
                  Spanish text:&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='text'
                  value={newTranslation.ES.text ?? ''}
                  onChange={(e) => onChange(e, 'ES', setNewTranslation, newTranslation)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='esp-hover'>
                <Form.Label className='mb-3'>Spanish hover:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='hover'
                  value={newTranslation.ES.hover ?? ''}
                  onChange={(e) => onChange(e, 'ES', setNewTranslation, newTranslation)}
                />
              </Form.Group>
            </Row>
          </Form>
          {sbShow}

          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;Campi obbligatori
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            Annulla
          </Button>
          <Button variant='primary' onClick={(e) => handleNewTranslation(e)}>
            Crea
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size='lg'
        show={showModalEdit}
        onHide={() => handleCloseModal(setShowModalEdit)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>Modifica traduzione*</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditStatus} id='form-admin-translation'>
            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='code'>
                <Form.Label className='mb-3'>
                  Code&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='code'
                  maxLength='11'
                  value={detailTranslation.code}
                  onChange={(e) => onChange(e, undefined, setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
              <Col></Col>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='ita-text'>
                <Form.Label className='mb-3'>
                  Italian text:&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='text'
                  value={detailTranslation.IT?.text ?? ''}
                  onChange={(e) => onChange(e, 'IT', setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='ita-hover'>
                <Form.Label className='mb-3'>Italian hover:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='hover'
                  value={detailTranslation.IT?.hover ?? ''}
                  onChange={(e) => onChange(e, 'IT', setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='eng-text'>
                <Form.Label className='mb-3'>
                  English text:&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='text'
                  value={detailTranslation.EN?.text ?? ''}
                  onChange={(e) => onChange(e, 'EN', setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='eng-hover'>
                <Form.Label className='mb-3'>English hover:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='hover'
                  value={detailTranslation.EN?.hover ?? ''}
                  onChange={(e) => onChange(e, 'EN', setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='esp-text'>
                <Form.Label className='mb-3'>
                  Spanish text:&nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='text'
                  value={detailTranslation.ES?.text ?? ''}
                  onChange={(e) => onChange(e, 'ES', setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
              <Form.Group as={Col} className='px-4' controlId='esp-hover'>
                <Form.Label className='mb-3'>Spanish hover:</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='hover'
                  value={detailTranslation.ES?.hover ?? ''}
                  onChange={(e) => onChange(e, 'ES', setDetailTranslation, detailTranslation)}
                />
              </Form.Group>
            </Row>
          </Form>
          {sbShow}

          <Row>
            <Col className='col-4 px-3'>
              <span className='px-3'>
                <span className='required-field'>*</span>&nbsp;Campi obbligatori
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col className='px-3'>
            <span>Sei sicuro di voler modificare le informazioni?*</span>
          </Col>
          <Col className='px-3 text-right'>
            <Button variant='danger' onClick={() => handleCloseModal(setShowModalEdit)}>
              Annulla
            </Button>
            <Button variant='primary' onClick={(e) => handleEditStatus(e)} className='ml-3'>
              Conferma
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalWarning} onHide={() => handleCloseModal(setShowModalWarning)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>*Codice {translationCode} non trovato. Creare prima una nuova traduzione*</span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => handleCloseModal(setShowModalWarning)}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TextsManagement;
