import { geoMercator, geoPath, select } from 'd3';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import measureElement from './measure';
import style from './ChoroplethMap.module.css';
import CustomSpinner from 'components/CustomSpinner';

const emptyGeoData = { features: [] };

/**
 * @param {Object} geodata - geographic data in GeoJSON format, it must be a feature list
 * @param {function} projection - projection function of D3 library. default is d3.geoMarcator
 * @param {callback} fillColor - returns color code for the region in relation to a data,this callback will receive as argument the feature object of the region.
 * @param {[number, number, number, number]} viewBox -  [x, y, width, height].
 * @param {callback} renderTooltip - returns a ReactElement to render a tooltip on hover over region, this callback will receive as argument the feature object of the region.
 * @param {callback} onHover - a  function  that is triggered on hover over a region, this function will receive as argument the feature object of the region.
 * @param {callback} onClick - a  function  that is triggered on click on a region, this callback will receive as argument the feature object of the region that user clicked
 * @param {boolean} isNavigable - enables zooming and moving the map using the mouse
 */
const ChoroplethMap = (props) => {
  const {
    geodata = emptyGeoData,
    projection = geoMercator,
    /**
     * @callback fillColor
     * @param {object} feature -  geographic data structure in GeoJSON format
     * @returns {string} - HTML color code
     */
    fillColor = () => '#000',
    hoverFillColor = '#999',
    viewBox = [],
    /**
     * @callback onHover,
     * @param {object} feature -  geographic data structure in GeoJSON format
     * @returns {ReactNode} - returns React node
     */
    onHover,
    renderTooltip,
    onClick,
    isNavigable = false,
    borderColor = '#fff',
    enlargeFeature,
    initialZoom = 0.9,
    tooltipDelay,
    centroidOffset = [0, 0],
    clickOnMap,
    isLoading,
  } = props;
  const viewBox0 = viewBox[0];
  const viewBox1 = viewBox[1];
  const initialViewMode = React.useMemo(() => {
    return {
      position: { x: viewBox0 || 0, y: viewBox1 || 0 },
      zoom: 1,
    };
  }, [viewBox0, viewBox1]);
  const svgRef = useRef(null);
  const [zoom, setZoom] = useState(initialViewMode.zoom);
  const [position, setPosition] = useState(initialViewMode.position);
  const [toolTip, setTooltip] = useState();
  const [svgWidth, setSvgWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState();
  const [tooltipHeight, setTooltipHeight] = useState();
  const [tooltipTimer, setTooltipTimer] = useState();
  const containerRef = useRef(null);

  const timeoutIdRef = useRef(null); // Ref to persist the timeoutId

  const measure = useCallback(() => {
    if (containerRef.current !== null) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      if (width !== 0 || height !== 0) {
        setSvgWidth(width);
        setSvgHeight(height);
        // setSvgWidth(width - (width * 5) / 100);
        // setSvgHeight(height - (height * 5) / 100);
      } else {
        // Retry after a short delay
        timeoutIdRef.current = setTimeout(measure, 50);
      }
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      // Trigger the measurement on component mount
      measure();
      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
        clearTimeout(timeoutIdRef.current);
      };
    }
  }, [measure]);

  const tooltipRef = useCallback((node) => {
    if (node !== null) {
      const { width, height } = node.getBoundingClientRect();
      setTooltipWidth(width);
      setTooltipHeight(height);
    }
  }, []);

  useEffect(() => {
    if (initialZoom) {
      setPosition({
        x: (-svgWidth * (1 - initialZoom)) / 2,
        y: (-svgHeight * (1 - initialZoom)) / 2,
      });
      setZoom(initialZoom);
    } else {
      setZoom(initialViewMode.zoom);
      setPosition(initialViewMode.position);
    }
  }, [initialViewMode, initialZoom, svgHeight, svgWidth]);

  const pathGenerator = useMemo(
    () =>
      geoPath().projection(
        projection().fitSize([viewBox[2] || svgWidth, viewBox[3] || svgHeight], geodata)
      ),
    [geodata, projection, svgHeight, svgWidth, viewBox]
  );
  useEffect(() => {
    if (enlargeFeature) {
      const [x, y] = pathGenerator.centroid(enlargeFeature);
      const rect = pathGenerator.bounds(enlargeFeature);

      if (Number.isNaN(Number(x)) || Number.isNaN(y)) return;

      const percentOfSvg = 0.25; // the feature will take 25% of the map after enlargement
      const enlargementX = (svgWidth / (rect[1][0] - rect[0][0])) * percentOfSvg;
      const enlargementY = (svgHeight / (rect[1][1] - rect[0][1])) * percentOfSvg;

      let enlargement = Math.min(enlargementX, enlargementY);
      if (enlargement < 1) enlargement = 1;
      setZoom(enlargement);
      setPosition({
        x: x - svgWidth / 2 / enlargement + (svgWidth / 2 / enlargement) * 0.15,
        y: y - svgHeight / 2 / enlargement,
      });
    } else {
      setZoom(
        window.innerWidth < 1480
          ? (svgWidth - centroidOffset[0]) / svgWidth +
              ((svgWidth - centroidOffset[0]) / svgWidth) * 0.15
          : (svgWidth - centroidOffset[0]) / svgWidth
      );

      setPosition({
        x:
          initialViewMode.position.x -
          centroidOffset[0] / ((svgWidth - centroidOffset[0]) / svgWidth) -
          (initialViewMode.position.x -
            (centroidOffset[0] / ((svgWidth - centroidOffset[0]) / svgWidth)) *
              `${window.innerWidth < 1480 ? 0.35 : 0}`),
        y:
          initialViewMode.position.y -
          centroidOffset[1] / ((svgHeight - centroidOffset[1]) / svgHeight) -
          (initialViewMode.position.y -
            (centroidOffset[1] / ((svgWidth - centroidOffset[1]) / svgWidth)) *
              `${window.innerWidth < 1480 ? 0.1 : 0}`),
      });
    }
  }, [enlargeFeature, /* pathGenerator,  */ svgHeight, svgWidth]);

  const handleMouseover = useCallback(
    (e, feature) => {
      const { width, height } = tooltipWidth
        ? { width: tooltipWidth, height: tooltipHeight }
        : measureElement(renderTooltip(feature));

      const overflowX = Math.min(svgWidth - e.offsetX - width, 0);
      const overflowY = Math.min(svgHeight - e.offsetY - height, 0);

      if (tooltipTimer) {
        clearTimeout(tooltipTimer);
        setTooltipTimer(undefined);
      }

      if (tooltipDelay) {
        const timer = setTimeout(() => {
          setTooltip({
            x: e.offsetX + overflowX,
            y: e.offsetY + overflowY,
            feature,
          });
        }, tooltipDelay);
        setTooltipTimer(timer);
      } else
        setTooltip({
          x: e.offsetX + overflowX,
          y: e.offsetY + overflowY,
          feature,
        });

      onHover?.(feature);
    },
    [
      onHover,
      renderTooltip,
      svgHeight,
      svgWidth,
      tooltipDelay,
      tooltipHeight,
      tooltipTimer,
      tooltipWidth,
    ]
  );

  useEffect(() => {
    const svg = select(svgRef.current);

    if (svg) {
      svg
        .select('.map')
        .selectAll('path')
        .data(geodata.features)
        .join('path')
        .attr('fill', fillColor)
        .attr('stroke', borderColor)
        .attr('stroke-width', 1 / zoom)
        .attr('d', pathGenerator)
        .on('click', (e, feature) => {
          onClick?.(feature);
        });

      svg
        .select('.map')
        .attr(
          'transform',
          enlargeFeature
            ? `translate(${centroidOffset[0] / 2 / zoom},${centroidOffset[1] / 2 / zoom}) `
            : ''
        );

      if (renderTooltip) {
        svg
          .selectAll('path')
          .on('mouseover', handleMouseover)
          .on('mouseout', () => {
            setTooltip(undefined);
            if (tooltipTimer) {
              clearTimeout(tooltipTimer);
              setTooltipTimer(undefined);
            }
          });
      }
    }
  }, [
    borderColor,
    fillColor,
    geodata.features,
    handleMouseover,
    onClick,
    pathGenerator,
    renderTooltip,
  ]);

  const zoomHandler = (event) => {
    if (isNavigable) {
      const delta = event.deltaY > 0 ? -0.2 : 0.2;

      let increment = 1;
      if (zoom >= 5) {
        increment = 5;
      } else if (zoom >= 3) {
        increment = 2;
      }
      /*     let newZoom = zoom + delta * increment;
      if (zoom + delta >= 1) newZoom = 1;
      setPosition((prev) => ({
        x: prev.x + (viewBox[2] || svgWidth - (viewBox[2] || svgWidth) / newZoom) / 2,
        y: prev.y + (viewBox[3] || svgHeight - (viewBox[3] || svgHeight) / newZoom) / 2,
      })); */

      if (zoom + delta >= 1) setZoom((prev) => prev + delta * increment);
      else setZoom(1);
    }
  };

  const mapMoveHandler = (event) => {
    if (isNavigable) {
      if (event.buttons === 1) {
        setPosition((prev) => ({
          x: prev.x - event.movementX / zoom,
          y: prev.y - event.movementY / zoom,
        }));
      }
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
      ref={containerRef}>
      {isLoading && (
        <CustomSpinner style={{ backgroundColor: '#e5e5e5', height: '100%', width: '100%' }} />
      )}
      {!isLoading && (
        <>
          <svg
            className={style.styledSvg}
            preserveAspectRatio='xMinYMin meet'
            ref={svgRef}
            width={svgWidth}
            height={svgHeight}
            viewBox={[
              /* 
          position.x + (viewBox[2] || svgWidth - (viewBox[2] || svgWidth) / zoom) / 2,
          position.y + (viewBox[3] || svgHeight - (viewBox[3] || svgHeight) / zoom) / 2,
     */

              position.x,
              position.y,
              (viewBox[2] || svgWidth) / zoom,
              (viewBox[3] || svgHeight) / zoom,
            ]}
            onWheel={zoomHandler}
            onMouseMove={mapMoveHandler}
            onClick={(event) => {
              if (event.target === event.currentTarget) {
                onClick?.(undefined);
              }
            }}
            style={{ '--hoverFillColor': hoverFillColor }}>
            <g className='map' style={{ filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 2px 2px 2px)' }} />
          </svg>
          {toolTip && (
            <div
              ref={tooltipRef}
              style={{
                top: toolTip.y,
                left: toolTip.x,
                position: 'absolute',
                boxSizing: 'border-box',
                pointerEvents: 'none',
              }}>
              {(!clickOnMap ||
                (clickOnMap &&
                  enlargeFeature?.properties.adm0_a3 === toolTip?.feature?.properties.adm0_a3)) &&
                renderTooltip(toolTip.feature)}
            </div>
          )}
        </>
      )}
    </div>
  );
};

// const StyledSvg = styled.svg`
//   position: absolute;
//   top: 0;
//   left: 0;
//   box-sizing: border-box;
//   path:hover {
//     fill: ${(props) => props.$hoverFillColor};
//   }
// `;

export default ChoroplethMap;
