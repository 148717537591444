import React from 'react';
import { rounder } from '@lib/helpers';
import { Trans } from '@lib/i18n';
const TaxesImpact = ({ taxes, filterForRegion }) => {
  return (
    <div className='simple-box'>
      <div className='row'>
        <div className='col-3 investment-infobox va-number'>
          <div>
            <h2>
              {rounder(
                taxes.taxesByType
                  .filter((income) =>
                    filterForRegion === 'total'
                      ? income.region === ''
                      : income.region === filterForRegion
                  )
                  .reduce((acc, curr) => acc + curr.propertyValue, 0)
              )}
            </h2>
            <span className='va-desc'>
              <Trans code='A0053' />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxesImpact;
