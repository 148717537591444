import React, { useState, useEffect, useCallback, useRef, Fragment, useMemo } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
} from 'react-bootstrap';
import Select from 'react-select';
import { IcoPlus, Lens } from '@icons';
import { Trans, propTrans } from '@lib/i18n';
import {
  getCorporateClasses,
  getOEKpis,
  postCorporateClass,
  putCorporateClass,
  getOrganizations,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererCorporateClass from 'components/adminManagement/corporateClassManagement/ActionBtnRendererCorporateClass';

import StatusBar from 'components/wizard/StatusBar';
import QuestionsWrapper from 'components/adminManagement/classesManagement/QuestionTab/QuestionsWrapper';
import { fetchData } from '@lib/helpers/index';

const CorporateClassPage = () => {
  const initialState = useMemo(() => {
    return {
      name: '',
      description: '',
      companies: [],
      kpis: [],
    };
  }, []);

  const [classes, setClasses] = useState([]);
  const [newClass, setNewClass] = useState(initialState);

  const [optionsKpi, setOptionsKpi] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [optionsOrganizations, setOptionsOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [tableSbShow, setTableSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const [columnDefs, setColumnDefs] = useState([]);
  const [error, setError] = useState(null);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  const handleCloseModal = (setShowModal) => {
    setShowModal(false);
    setRefresh(true);
    resetFormState();
  };
  const handleShowModal = (setShowModal) => {
    setShowModal(true);
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const resetFormState = useCallback(() => {
    setSbShow(<></>);
    setSelectedKpi([]);
    setSelectedOrganization([]);
    setNewClass(initialState);
    setActiveKey('general');
  }, []);

  useEffect(() => {
    const myAbortController = new AbortController();

    const fetchData = async () => {
      const [kpiResponse] = await Promise.all([getOEKpis()]);

      if (myAbortController.signal.aborted === false) {
        setOptionsKpi(
          kpiResponse.data.map((kpi) => ({
            value: kpi.id,
            label: `${kpi.id} - ${kpi.name}`,
            isDisabled: kpi?.indicatorsCount < 1,
          }))
        );
      }
    };
    fetchData();
    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData(getCorporateClasses, setClasses, null, [], null, setError);
      setRefresh(false);
    }
    // Do I need this?
    // gridRef?.current?.api?.setColumnDefs(columnDefs);
  }, [refresh, columnDefs]);

  const transformOrganizations = (data) => {
    return setOptionsOrganizations(
      data.map((org) => {
        return {
          value: org.id,
          label: org.name,
        };
      })
    );
  };

  useEffect(() => {
    fetchData(getOrganizations, null, transformOrganizations, [], null, setError);
  }, []);
  console.log(optionsOrganizations, 'optionsOrganizations');
  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'ID',
        field: 'id',
        maxWidth: 100,
        filter: 'agTextColumnFilter',
        sortable: true,
        valueGetter: (params) => {
          return params.data.id;
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.name;
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.description;
        },
      },
      {
        headerName: 'Companies',
        field: 'companies',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.companies?.[0]?.name;
        },
      },
      {
        pinned: 'right',
        minWidth: 130,
        maxWidth: 130,
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionBtnRendererCorporateClass,
        cellRendererParams: {
          setRefresh: setRefresh,
          optionsKpi: optionsKpi,
          setSelectedKpi: setSelectedKpi,
          setShowModalNew: setShowModalNew,
          setNewClass: setNewClass,
          setSelectedOrganization: setSelectedOrganization,
          optionsOrganizations: optionsOrganizations,
          // scenari: scenari,
        },
      },
    ]);
  }, [optionsKpi, optionsOrganizations, classes]);
  console.log('newClass:', newClass);
  const onChange = (e, fieldName, setState, state, expItemIndex, expenseType) => {
    // console.log('onchange e:', e.target.checked)
    console.table('onchange:', e, fieldName, setState, state, expItemIndex, expenseType);

    if (setState) {
      setState(e ?? []);
    }

    let tempNewClass = { ...newClass };
    let value;

    switch (fieldName) {
      case 'kpis':
        value = !e ? [] : e.map((kpi) => ({ kpiId: kpi.value }));
        break;
      case 'companies':
        value = [{ companyId: e.value }];
        break;
      default:
        value = e.target.value;
        break;
    }

    tempNewClass[fieldName] = value;

    setNewClass(tempNewClass);
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onFirstDataRendered = useCallback(() => {
    if (!gridRef) return;
    setTimeout(() => {
      gridRef?.current?.api?.sizeColumnsToFit();
    }, 200);
    //gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onGridReady = useCallback(() => {
    fetchData(getCorporateClasses, setClasses, null, [], null, setError);
    //sort the column 'position' in ascending order
    gridRef.current.columnApi.applyColumnState({
      state: [
        {
          colId: 'id',
          sort: 'desc',
        },
      ],
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onGridSizeChanged = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const defaultColDef = {
    resizable: false,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  const handleNewClass = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { companies, kpis, ...restClass } = newClass;

    const isClassFilled = Object.values(restClass).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    if (!isClassFilled) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      return;
    }
    if (!kpis || !kpis.length || !companies || !companies.length) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      return;
    }
    handleStatusBar('elaborating', false, false);
    let createResult;
    if (newClass?.id) {
      createResult = await putCorporateClass(newClass, newClass.id);
    } else {
      createResult = await postCorporateClass(newClass);
    }

    if (createResult?.status) {
      handleStatusBar('success', propTrans({ prop: 'A0525' }), true);

      setTimeout(() => {
        setShowModalNew(false);
        resetFormState();
        setRefresh(true);
      }, 2000);
    } else if (!createResult?.status || createResult?.error) {
      handleStatusBar('error', createResult.message ?? propTrans({ prop: 'A0521' }), false);
      //handleStatusBar('error', createResult.message, false);
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
            {/* USER NOT AUTHORIZED */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='classes' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-5'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0850' />
            </h5>
          </div>
          <div className='col-7 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    <Trans code='A0510' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>
        {tableSbShow}

        <div className='row'>
          <div style={containerStyle} className='col-12' id='grid-wrapper'>
            <div style={gridStyle} className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={classes}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
                //masterDetail={true}
                //detailCellRenderer={detailCellRenderer}
              />
              <div className='example-header'>
                <Trans code='A0602' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size={'xl'}
        id='class-modal'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className='px-2'>
            {newClass?.id ? <Trans code='A0512' /> : <Trans code='A0499' />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0 px-0'>
          <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} justify>
            {/* Generale */}
            <Tab eventKey='general' title={propTrans({ prop: 'A0041' })} className='px-5'>
              <Form onSubmit={handleNewClass} id='form-admin-corporateclass'>
                <Row className='mt-5 mb-3'>
                  <Col>
                    <Form.Group as={Col} className='px-4' controlId='name'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0636' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='username'
                        defaultValue={newClass.name}
                        onChange={(e) => onChange(e, 'name')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className='px-4' controlId='description'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0621' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='description'
                        defaultValue={newClass.description}
                        onChange={(e) => onChange(e, 'description')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-5 mb-3'>
                  <Col>
                    <Form.Group as={Col} className='pr-4 pl-4' controlId='companies'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0058' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='companies'
                        value={selectedOrganization}
                        options={optionsOrganizations}
                        onChange={(e) => onChange(e, 'companies', setSelectedOrganization)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              {sbShow}

              <Row>
                <Col className='px-4'>
                  <span className='mt-4 px-4'>
                    <span className='required-field'>*</span>&nbsp;
                    <Trans code='A0503' />
                  </span>
                </Col>
              </Row>
            </Tab>

            {/* KPI */}
            <Tab eventKey='kpi' title={'Kpis'} className='px-5'>
              <Form onSubmit={handleNewClass} id='form-admin-corporateclass'>
                <Row className='mb-3 mt-5 col-9'>
                  <Form.Group as={Col} className='px-4' controlId='kpis'>
                    <Form.Label className='mb-3 mr-4'>
                      <Trans code='A0504' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Select
                      id='multiselect-kpis'
                      value={selectedKpi}
                      closeMenuOnSelect={false}
                      options={optionsKpi}
                      isMulti
                      isClearable
                      onChange={(e) => onChange(e, 'kpis', setSelectedKpi)}
                    />
                  </Form.Group>
                </Row>
              </Form>
              {sbShow}

              <span className='mt-4 px-3'>
                <span className='required-field'>*</span>&nbsp;
                <Trans code='A0503' />
              </span>
            </Tab>
            {newClass?.id && (
              <Tab eventKey='questions' title={'Questionario'}>
                {activeKey === 'questions' && (
                  <QuestionsWrapper classId={newClass.id} isCorporate={true} />
                )}
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={(e) => handleNewClass(e)}>
            <Trans code='A0509' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CorporateClassPage;
