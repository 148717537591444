import React from 'react'
import { rounder } from '@lib/helpers'
import { Trans } from '@lib/i18n'
import TaxesChart from '@components/scenario/taxes/TaxesChart'
import TaxesDonut from '@components/scenario/taxes/TaxesDonut'
import TaxesTable from '@components/scenario/taxes/TaxesTable'
import {Ico42 } from "@icons/new"

const Taxes = ({ taxes: { direct, indirect, totaleTaxes, description, base_url, image_tax_text_1, image_tax_text_2,image_tax_1, image_tax_2 } }) => (
  <>
    <div className="simple-box">
      <div className="row">
        <div className="col-3 investment-infobox va-number">
          <div>
            <h2>{rounder(totaleTaxes)}</h2>
            <span className="va-desc"><Trans code="A0053" /></span>
          </div>
        </div>
        <div className="col-9 investment-infobox">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-6 d-flex container-card">
        { direct > 0 && indirect > 0 ? <TaxesDonut taxesData={{ Direct: direct, Indirect: indirect, total: totaleTaxes }} /> : <TaxesChart taxesData={{direct, indirect}} />}
      </div>
      <div className="col-6 d-flex container-card">
        <TaxesTable taxesData={{ direct, indirect, total: totaleTaxes }} />
      </div>
    </div>
    { image_tax_1 && image_tax_1.indexOf("empty") < 0 && image_tax_2 && image_tax_2.indexOf("empty") < 0 && 
    <div className='row'>
      <div className="col-12 col-xl-6 d-flex container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{image_tax_text_1}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
               <img className="m-auto img-card" src={`${base_url}${image_tax_1}`} alt="" onerror="this.style.display='none'"/>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 d-flex container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{image_tax_text_2}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
              <img className="m-auto img-card" src={`${base_url}${image_tax_2}`} alt="" onerror="this.style.display='none'"/>
            </div>
          </div>
        </div>
        </div>
    }
  </>
)

export default Taxes