import React from 'react';
import CorporateEsgAnalysesCard from './CorporateEsgAnalysesCard';
import { Trans } from '@lib/i18n/index';

const CorporateEsgAnalysesCompanyWrapper = ({
  company,
  data,
  userCompanyId,
  dependingCompany = undefined,
  setRefresh,
  userRole,
}) => {
  return (
    <div className='simple-box mb-5'>
      <div>
        <h1>{data?.[0]?.name}</h1>
        {!!dependingCompany && (
          <p>
            <Trans code='A0870' />: <span className='font-weight-bold'>{dependingCompany}</span>
          </p>
        )}
        {userCompanyId === company && (
          <p>
            <Trans code='A0869' />
          </p>
        )}
      </div>
      <div className='analyses-container justify-content-center'>
        {data.map((el) => (
          <CorporateEsgAnalysesCard
            key={`${company}_${el.id}`}
            data={el}
            userCompanyId={userCompanyId}
            setRefresh={setRefresh}
            userRole={userRole}
          />
        ))}
      </div>
    </div>
  );
};

export default CorporateEsgAnalysesCompanyWrapper;
