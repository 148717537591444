import React, { useState } from 'react';
import { LoginForm } from '@components';
import { useLocation } from 'react-router-dom';
import ResetPasswordForm from 'components/resetPasswordForm';
import AuthenticationWrapper from 'components/authenticationWrapper';

const Login = (props) => {
  const [showLogin, setShowLogin] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  return (
    <div id='loginPage'>
      <AuthenticationWrapper>
        {showLogin ? (
          <LoginForm
            referrer={location?.state?.referrer ?? location.search.split('=')[1] ?? null}
            setShowLogin={setShowLogin}
            error={error}
            setError={setError}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ) : (
          <ResetPasswordForm
            referrer={location?.state?.referrer ?? location.search.split('=')[1] ?? null}
            setShowLogin={setShowLogin}
            error={error}
            setError={setError}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )}
      </AuthenticationWrapper>
    </div>
  );
};

export default Login;
