import React, { useEffect, useState } from 'react';
import EsgRatingComponent from './esgRatingComponent';
import { Ico104, Ico105, Ico106 } from '@icons/new';
import EsgChartsMainCard from './esgChartsMainCard';
import { getESGSubCategories, getOEESGRatings } from '@lib/api';
import { fetchData } from '@lib/helpers/index';
const EsgMainComponent = ({ esgResults, subcategories }) => {
  const esgMappingCards = [
    {
      category: 'E',
      langCode: 'A0842',
      icon: <Ico104 className='logo-card ico-box-yellow' />,
      subCategories: subcategories.filter((el) => el.category === 'E'),
    },
    {
      category: 'S',
      langCode: 'A0843',
      icon: <Ico105 className='logo-card ico-box-red' />,
      subCategories: subcategories.filter((el) => el.category === 'S'),
    },
    {
      category: 'G',
      langCode: 'A0844',
      icon: <Ico106 className='logo-card ico-box-acqua' />,
      subCategories: subcategories.filter((el) => el.category === 'G'),
    },
  ];
  console.log('backgroundColor', subcategories);
  return (
    <>
      <EsgRatingComponent data={esgResults} />
      <div className='d-flex flex-wrap' style={{ gap: '0 40px' }}>
        {esgMappingCards.map((category) => {
          return (
            <EsgChartsMainCard
              data={esgResults}
              key={category.category}
              category={category.category}
              langCode={category.langCode}
              icon={category.icon}
              subCategories={category.subCategories}
            />
          );
        })}
      </div>
    </>
  );
};

export default EsgMainComponent;
