import { Externalyticslogo, OpeneconomicsLogo } from 'loghi/index';
import Method from 'pages/metodologia';
import Platform from 'pages/piattaforma';
import ImpactFinance from 'pages/tab-impact-finance';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const AuthenticationWrapper = ({ children }) => {
  return (
    <>
      {' '}
      <div className='header-site'>
        <Container>
          <Row className='justify-content-between'>
            <Col lg={5} className='text-login'>
              <Externalyticslogo className='externalyticslogo' />
              <div>
                <small>Powered by</small> <OpeneconomicsLogo className='openeconomicslogo' />
              </div>
            </Col>
            <Col lg={5} className='claim'>
              <p className='infoLogin'>
                A framework of practical solutions to manage externalities and risks related to
                investments and policies
              </p>
              <p className='claimLogin'>THINK WIDER, LOOK CLOSER</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='login-container'>{children}</div>
      <ImpactFinance />
      <Platform />
      <Method />
    </>
  );
};

export default AuthenticationWrapper;
