import { useState, useEffect } from 'react';
import { rounder, isNumeric } from '@lib/helpers';
import { Card } from 'react-bootstrap';
import { Trans } from '@lib/i18n';

import { Ico35 } from '@icons/new';

const SroiKpiTable = ({ data, tableLegendCode = '' }) => {
  const [newData, setNewData] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState(1);

  useEffect(() => {
    setNewData(
      data.filter((el) => {
        return el.category === 'pv';
      })
    ); //to filter
  }, [data]);
  const handleOrderBy = (key) => () => {
    if (orderBy === key) setOrder(order * -1);
    else {
      setOrder(-1);
      setOrderBy(key);
    }
  };

  return (
    <Card className='cards-utente d-none d-md-block card-table'>
      <Card.Body>
        <Ico35 className='logo-card ico-box-blue' />
        <Card.Title>
          <Trans code='A0637' />
        </Card.Title>
        <table className='sroi-table'>
          <thead>
            <tr>
              <th className='value-label' onClick={handleOrderBy('propertyName')}>
                <Trans code='A0675' />
              </th>
              <th onClick={handleOrderBy('propertyValue')}>
                <Trans code='A0137' />
              </th>
            </tr>
          </thead>
          <tbody>
            {newData
              .sort((a, b) => {
                if (!orderBy) return 0;
                if (isNumeric(a[orderBy]) && isNumeric(b[orderBy])) {
                  a[orderBy] = parseFloat(a[orderBy]);
                  b[orderBy] = parseFloat(b[orderBy]);
                }
                if (order === -1) {
                  if (a[orderBy] < b[orderBy]) return -1;
                  if (a[orderBy] > b[orderBy]) return 1;
                  return 0;
                } else {
                  if (a[orderBy] < b[orderBy]) return 1;
                  if (a[orderBy] > b[orderBy]) return -1;
                  return 0;
                }
              })
              .map((row, index) => (
                <tr key={index}>
                  <td className='value-label'><Trans code={row.propertyName} /></td>
                  <td>{rounder(row.propertyValue)}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <p>
          <Trans code={tableLegendCode} className='va-desc' />
        </p>
      </Card.Body>
    </Card>
  );
};

export default SroiKpiTable;
