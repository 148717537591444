import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, InputGroup, Button, Modal, Spinner } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n';
import { getFinInputTypes, getOECurrencies } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import {
  // delScenAnalysis,
  deleteVarAdditionalInfo,
  getScenarioVariant,
  // getScenarioVariant,
  // postScenAnalysis,
  // putScenAnalysis,
  putScenarioVariant,
  putVariantAdditionalInfo,
} from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import AdditionalInfoSection from '../stepConfigure/additionalInfoSection';
import ConfirmContextProvider from 'components/customHook/useConfirm/ConfirmContextProvider';
import ConfirmModal from 'components/customHook/useConfirm/ConfirmModal';
// import AnalysisSection from '../stepConfigure/analysisSection';
const currentYear = new Date().getFullYear();

const StepSubGeneral = ({ scen, setScen, originScenario }) => {
  const { idScenario = null, idVariante = null } = useParams();
  const [scenario, setScenario] = useState({});
  const [scenAdditionalInfo, setScenAdditionalInfo] = useState([]);
  const [scenLinks, setScenLinks] = useState([]);
  const [scenFiles, setScenFiles] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [originalInputTypeCode, setOriginalInputTypeCode] = useState('');
  const [finInputTypes, setFinInputTypes] = useState([]);
  const [showModalChangeInputType, setShowModalChangeInputType] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);
  // const [scenAnalyses, setScenAnalyses] = useState([]);
  const history = useHistory();
  // console.log('scenario StepSubGeneral:', scenario, useParams());
  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  useEffect(() => {
    const transformScenarioVariant = (data) => {
      let tempScenario = { ...data };
      tempScenario.investmentStart = tempScenario.investmentStart ?? currentYear;
      tempScenario.investmentEnd = tempScenario.investmentEnd ?? currentYear + 1;
      tempScenario.operationStart = tempScenario.operationStart ?? currentYear + 1;
      tempScenario.operationEnd = tempScenario.operationEnd ?? currentYear + 2;
      tempScenario.evaluationYear = tempScenario.evaluationYear ?? currentYear;
      tempScenario.investment = tempScenario.investment ?? 0;
      tempScenario.socialDiscountRate = tempScenario.socialDiscountRate ?? 3;
      tempScenario.inputTypeCode = tempScenario.inputTypeCode ?? 'PEF';
      // setScenAnalyses(tempScenario.analyses);
      setScenario(tempScenario);
      setOriginalInputTypeCode(tempScenario.inputTypeCode);
    };
    if (scen) {
      transformScenarioVariant(scen);
    }
  }, [scen]);

  // useEffect(() => {
  //   if (idScenario && idVariante) {
  //     fetchData(
  //       getScenarioVariant,
  //       setOriginalVariant,
  //       null,
  //       [idScenario, idVariante],
  //       handleStatusBar
  //     );
  //   }
  // }, [idScenario, idVariante]);

  useEffect(() => {
    let isMounted = true;

    const transformFinInputTypes = (data) => {
      if (isMounted) {
        setFinInputTypes(data.sort((a, b) => b.code.localeCompare(a.code)));
      }
    };

    const transformCurrencies = (data) => {
      if (isMounted) {
        setCurrencies(
          data.map((currency) => ({
            value: currency.code,
            symbol: currency.symbol,
          }))
        );
      }
    };

    fetchData(getFinInputTypes, null, transformFinInputTypes, [], handleStatusBar);
    fetchData(getOECurrencies, null, transformCurrencies, [], handleStatusBar);

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (scenario.additionalInfo) {
      setScenAdditionalInfo(
        scenario.additionalInfo.filter(
          (info) => info.topic !== 'link' && info.topic !== 'file' && info.topic !== 'image'
        )
      );
      setScenLinks(scenario.additionalInfo.filter((info) => info.topic === 'link'));
      setScenFiles(
        scenario.additionalInfo.filter((info) => info.topic === 'file' || info.topic === 'image')
      );
    }
  }, [scenario]);

  const handleCloseModal = (setShowModal) => setShowModal(false);

  const toTruncTwo = (number) => {
    let string = Number.isInteger(number)
      ? number.toString()
      : number.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 2 });

    string = parseFloat(string.replace(/,/g, ''));
    const convertedToNumber = isNaN(Number(string)) ? 0 : Number(string);
    //console.log('convertedToNumber', typeof convertedToNumber, convertedToNumber);

    return convertedToNumber;
  };

  const onChange = (e, fieldName, comparedField) => {
    //console.log('e onChange', e.target.value);

    let tempScenario = { ...scenario };

    switch (fieldName) {
      case 'investmentStart':
      case 'investmentEnd':
      case 'operationStart':
      case 'operationEnd':
      case 'evaluationYear':
        e.target.value = !e.target.value ? 0 : e.target.value;
        if (e.target.value.length > 4) return;

        tempScenario[fieldName] = Number.parseInt(e.target.value);

        // if (
        //   comparedField &&
        //   Number(tempScenario[fieldName]) < Number(tempScenario[comparedField])
        // ) {
        //   tempScenario[fieldName] = tempScenario[comparedField];
        // }

        setScenario(tempScenario);
        break;
      case 'investment':
      case 'socialDiscountRate':
        //tempScenario[fieldName] = Number.parseFloat(e.target.value);
        tempScenario[fieldName] = toTruncTwo(Number(e.target.value));

        setScenario(tempScenario);
        break;
      case 'inputTypeCode':
        // ORIGINAL CASE FOR CHECKS
        // tempScenario[fieldName] = e.target.id;

        tempScenario[fieldName] = e.currentTarget.value;
        // console.log('tempSCen', tempScenario, e.currentTarget.value);
        setScenario(tempScenario);
        break;
      default:
        break;
    }
  };

  const checkConfirmation = async (boolean, setShowModal) => {
    if (boolean) {
      setIsConfirmed(boolean);
      const newSc = { ...scenario, datasetId: null };
      submit(newSc);
    } else {
      setIsConfirmed(boolean);
    }

    setShowModal(false);
  };

  const handleEditScenario = async (e) => {
    e.preventDefault();

    setSbShow(<></>);

    const {
      address,
      createdAt,
      investment,
      socialDiscountRate,
      updatedAt,
      verified,

      additionalInfo,
      analyses,
      class: classNoKeyword,
      dataset,
      datasetId,
      ...restScenario
    } = scenario;

    const isScenarioFilled = Object.values(restScenario).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;

    if (!isScenarioFilled) {
      handleStatusBar('error', propTrans({ prop: 'A0568' }), false);
      return;
    }
    if (scenario.investmentEnd < scenario.investmentStart) {
      handleStatusBar('error', propTrans({ prop: 'A0557' }), false);
      //L'anno di investimento finale deve essere successivo a quello iniziale.
      return;
    }
    if (scenario.operationEnd < scenario.operationStart) {
      handleStatusBar('error', propTrans({ prop: 'A0558' }), false);
      //L'anno di esercizio finale deve essere successivo a quello iniziale.
      return;
    }
    if (scenario.operationStart < scenario.investmentStart) {
      handleStatusBar('error', propTrans({ prop: 'A0562' }), false);
      //L'anno di esercizio iniziale deve essere successivo all'anno di investimento iniziale.
      return;
    }

    if (!isConfirmed && originalInputTypeCode === 'EV' && scenario.inputTypeCode === 'PEF') {
      setShowModalChangeInputType(true);
      return;
    }
    submit(scenario);
  };

  const submit = async (scenario) => {
    const { analysisResults, ...scenarioToPut } = scenario;
    setIsLoading(true);

    const resultOfActions = [];
    const originAttach = scenario.additionalInfo.filter(
      (info) => info.topic === 'link' || info.topic === 'file' || info.topic === 'image'
    );
    const nowAttach = scenLinks.concat(scenFiles);

    const oldAttachsDeleted = originAttach.filter((object1) => {
      return !nowAttach.some((object2) => {
        return object1.id === object2.id;
      });
    });

    if (oldAttachsDeleted.length > 0) {
      oldAttachsDeleted.forEach(async (attach) => {
        const deleteResult = await deleteVarAdditionalInfo(idScenario, idVariante, attach.id);
        resultOfActions.push(deleteResult.status);
      });
    }
    const allAdditionalInfo = scenAdditionalInfo.concat(scenLinks, scenFiles);
    let editAdditionalInfoResult = { status: true };
    if (allAdditionalInfo.length !== 0) {
      editAdditionalInfoResult = await putVariantAdditionalInfo(
        allAdditionalInfo,
        idScenario,
        idVariante
      );
    }
    if (
      (editAdditionalInfoResult.status === true &&
        oldAttachsDeleted.length > 0 &&
        resultOfActions.length > 0 &&
        resultOfActions.every((el) => el === true)) ||
      editAdditionalInfoResult.status === true
    ) {
      const editResult = await putScenarioVariant(idScenario, idVariante, scenarioToPut);
      if (editResult.status === true) {
        //"for of" is good for push result async
        handleStatusBar('success', propTrans({ prop: 'A0657' }), true);
        setIsLoading(false);
        fetchData(getScenarioVariant, setScen, null, [idScenario, idVariante], handleStatusBar);
        setTimeout(() => {
          //setScenario(createResult.data);
          setIsConfirmed(false);
          // setScen(editResult.data); //per subtabs
          history.push(
            editResult?.data?.investment > 0 &&
              editResult?.data?.analyses.some((el) => el.type === 'ESG') > 0
              ? `/scenariowizard/${editResult.data.scenarioId}/${editResult.data.id}?step=build&sub=esg`
              : `/scenariowizard/${editResult.data.scenarioId}/${editResult.data.id}?step=build&sub=dataset`
          );
        }, 2000);
      } else {
        handleStatusBar('error', editResult?.message || propTrans({ prop: 'A0521' }), false);
        setIsLoading(false);
      }
    } else {
      handleStatusBar(
        'error',
        editAdditionalInfoResult?.message || propTrans({ prop: 'A0521' }),
        false
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      {sbShow}
      {/* TIMING */}
      <Form onSubmit={handleEditScenario} id='form-subgeneral'>
        {/* <AnalysisSection
          scenOriginAnalyses={scenario.analyses}
          setScenAnalyses={setScenAnalyses}
          idScenario={scenario.id}
        /> */}

        <div className='simple-box mt-2'>
          <Row>
            <Col lg={12} className='form-login'>
              <h5 className='title-simple-box margin-title-box'>
                <Trans code='A0393' />
              </h5>
              <Row className='mb-3 gap-2'>
                <Form.Group as={Col} controlId='investment'>
                  <Form.Label>
                    <Trans code='A0548' />
                  </Form.Label>
                  <InputGroup className='inputgroup timing mb-3'>
                    <InputGroup.Text>
                      <Trans code='A0551' />
                    </InputGroup.Text>
                    <Form.Control
                      type='number'
                      name='investmentStart'
                      min={1990}
                      max={2100}
                      value={scenario?.investmentStart ?? ''}
                      onChange={(e) => onChange(e, 'investmentStart')}
                    />
                    <InputGroup.Text>
                      <Trans code='A0552' />
                    </InputGroup.Text>
                    <Form.Control
                      type='number'
                      name='investmentEnd'
                      min={scenario?.investmentStart ?? 1990}
                      max={2100}
                      value={scenario?.investmentEnd ?? ''}
                      onChange={(e) => onChange(e, 'investmentEnd', 'investmentStart')}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId='operation'>
                  <Form.Label>
                    <Trans code='A0549' />
                  </Form.Label>
                  <InputGroup className='inputgroup timing mb-3'>
                    <InputGroup.Text>
                      <Trans code='A0551' />
                    </InputGroup.Text>
                    <Form.Control
                      type='number'
                      name='operationStart'
                      min={0}
                      max={2100}
                      value={scenario?.operationStart ?? ''}
                      onChange={(e) => onChange(e, 'operationStart', 'investmentStart')}
                    />
                    <InputGroup.Text>
                      <Trans code='A0552' />
                    </InputGroup.Text>
                    <Form.Control
                      type='number'
                      name='operationEnd'
                      min={scenario?.operationStart ?? 1990}
                      max={2100}
                      value={scenario.operationEnd ?? ''}
                      onChange={(e) => onChange(e, 'operationEnd', 'operationStart')}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId='evaluationYear'>
                  <Form.Label>
                    <Trans code='A0550' />
                  </Form.Label>
                  <Form.Control
                    type='number'
                    name='evaluationYear'
                    min={1990}
                    max={2100}
                    value={scenario.evaluationYear ?? ''}
                    onChange={(e) => onChange(e, 'evaluationYear')}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='simple-box' style={{ marginTop: '40px' }}>
          <Row>
            <Col lg={12} className='form-login'>
              <h5 className='title-simple-box margin-title-box'>
                <Trans code='A0553' />
              </h5>
              <Row className='mb-3 gap-2'>
                <Form.Group
                  as={Col}
                  className='align-self-stretch d-flex pr-0'
                  controlId='investment'>
                  <Row className='flex-column w-100'>
                    <Col>
                      <Form.Label>
                        <Trans code='A0554' />
                      </Form.Label>
                    </Col>
                    <Col className='pr-0'>
                      <InputGroup className='inputgroup investment'>
                        <Form.Control
                          className='text-right'
                          type='number'
                          name='investment'
                          min={0}
                          step={0.01}
                          value={scenario.investment !== undefined ? scenario.investment : ''}
                          onChange={(e) => onChange(e, 'investment')}
                        />
                        <InputGroup.Text className='text-uppercase'>
                          {currencies
                            .filter((curr) => curr.value === originScenario.currencyCode)
                            .map((curr) => curr.symbol)}
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  className='align-self-stretch d-flex pr-0'
                  controlId='socialDiscountRate'>
                  <Row className='flex-column w-100'>
                    <Col>
                      <Form.Label>
                        <Trans code='A0555' />
                      </Form.Label>
                    </Col>
                    <Col className='pr-0'>
                      <InputGroup className='inputgroup discountrate'>
                        <Form.Control
                          className='text-right'
                          type='number'
                          name='socialDiscountRate'
                          min={0}
                          max={100}
                          value={
                            scenario.socialDiscountRate !== undefined
                              ? scenario.socialDiscountRate
                              : ''
                          }
                          onChange={(e) => onChange(e, 'socialDiscountRate')}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} controlId='inputTypeCode'>
                  <Row className='flex-column w-100 '>
                    <Col>
                      <Form.Label as={Row} className='pl-4' style={{ marginBottom: '0.9rem' }}>
                        <Trans code='A0556' />
                      </Form.Label>
                    </Col>
                    <Col className='pl-4 d-flex flex-column'>
                      {finInputTypes.map((finInputType) => (
                        <Button
                          id={finInputType.code}
                          key={finInputType.code}
                          className={`mb-1 ${
                            scenario.inputTypeCode === finInputType.code
                              ? 'selection-button'
                              : 'selection-button-outlined'
                          }`}
                          value={finInputType.code}
                          name='inputTypeCode'
                          onClick={(e) => onChange(e, 'inputTypeCode')}>
                          {<Trans code={finInputType.code} />}
                        </Button>
                      ))}
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '40px' }}>
          <ConfirmContextProvider>
            <AdditionalInfoSection
              originAdditionalInfo={scenario.additionalInfo}
              setScenAdditionalInfo={setScenAdditionalInfo}
              setScenLinks1={setScenLinks}
              setScenFiles1={setScenFiles}
              scenTest={scenFiles}
              idScenario={scenario.id}
              saveButtonId='save-general-button'
            />
            <ConfirmModal></ConfirmModal>
          </ConfirmContextProvider>
        </div>

        <Button
          form='form-subgeneral'
          id='save-general-button'
          type='submit'
          className='submit-button sticky-footer-button'
          disabled={isLoading}>
          {!isLoading ? (
            <Trans code='F00046' />
          ) : (
            <span className='d-flex align-items-center ml-2'>
              <Trans code='A0782' />
              <Spinner animation='border' className='ml-2' size='sm' />
            </span>
          )}
        </Button>
      </Form>

      <Modal
        show={showModalChangeInputType}
        onHide={() => handleCloseModal(setShowModalChangeInputType)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <Trans code='A0588' />
            <span className='d-block'>
              <Trans code='A0572' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => checkConfirmation(false, setShowModalChangeInputType)}>
            <Trans code='F0057' />
          </Button>
          <Button
            variant='success'
            onClick={() => checkConfirmation(true, setShowModalChangeInputType)}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default StepSubGeneral;
