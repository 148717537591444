import React, { Component } from 'react';

class Ico8 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id='ico-8'
        viewBox='0 0 117.62 136'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
        height={this.props.height}
        width={this.props.width}>
        <path d='M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z' className='esagono' />
        <g transform='translate(28.8,41.5)'>
          <path
            d='M58.7334 1.0662C58.3461 0.752941 57.8936 0.530449 57.409 0.41508C56.9245 0.299712 56.4202 0.2944 55.9334 0.399537L30 6.23287L4.0667 0.33287C3.57211 0.221168 3.05856 0.223769 2.56513 0.340473C2.07171 0.457177 1.61141 0.684908 1.21926 1.00634C0.827122 1.32776 0.513475 1.73442 0.302196 2.19534C0.0909164 2.65627 -0.0124274 3.15932 2.97073e-05 3.6662V44.3329C-0.0188024 45.1034 0.229979 45.8567 0.704027 46.4644C1.17808 47.0722 1.84809 47.4969 2.60003 47.6662L29.2667 53.6662H30.7334L57.4 47.6662C58.152 47.4969 58.822 47.0722 59.296 46.4644C59.7701 45.8567 60.0189 45.1034 60 44.3329V3.6662C59.9977 3.16552 59.8826 2.67179 59.6634 2.22167C59.4441 1.77156 59.1262 1.37663 58.7334 1.0662ZM6.6667 7.83287L26.6667 12.3329V46.1662L6.6667 41.6662V7.83287ZM53.3334 41.6662L33.3334 46.1662V12.3329L53.3334 7.83287V41.6662Z'
            className='ico'
          />
        </g>
      </svg>
    );
  }
}
export default Ico8;
