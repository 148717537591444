import React from "react";

import {
  Ico33, Ico42, Ico8,
} from "@icons/new";
import { Link } from 'react-router-dom'


const Country = () => {
 
  return (
    <ul className="menu-container">
      <li className="menu-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <span className={(window.location.pathname===('/countryprofile/components/key-indicators'))? 'menu-link active' : 'menu-link'}>
          <div className="nav-ico"><Link to="/countryprofile/components/key-indicators"><Ico42 /></Link></div>
          <div className="nav-text"><Link to="/countryprofile/components/key-indicators">Key indicators</Link></div>
        </span>
        <span className={(window.location.pathname===('/countryprofile/components/footprint'))? 'menu-link active' : 'menu-link'}>
          <div className="nav-ico"><Link to="/countryprofile/components/footprint"><Ico8 /></Link></div>
          <div className="nav-text"><Link to="/countryprofile/components/footprint">Footprint</Link></div>
        </span>
        <span className={(window.location.pathname===('/countryprofile/components/structure'))? 'menu-link active' : 'menu-link'}>
          <div className="nav-ico"><Link to="/countryprofile/components/structure"><Ico33 /></Link></div>
          <div className="nav-text"><Link to="/countryprofile/components/structure">Structure</Link></div>
        </span>
      </li>
    </ul>
  );

}

export default Country;