import React , { useState } from "react";
import { ImpactAnalysis, Country, Modelling, Administration } from "./nav"
import { useLocation } from "react-router-dom";

import { MaxMinimize } from  "@icons";


function Menu() {
  const location = useLocation();

  const [activeId, setActiveId] = useState('0');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  if (location.pathname.indexOf('country') > 0 ){
    return (
      <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
        <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0')}}><MaxMinimize className="max-minimize"/></a></div>
        <Country/>
      </div>
    );
  } else if (location.pathname.indexOf('modelling') > 0){
    return (
      <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
        <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0')}}><MaxMinimize className="max-minimize"/></a></div>
        <Modelling/>
      </div>
    );
  } else if (location.pathname.indexOf('administration') > 0){
    return (
      <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
        <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0')}}><MaxMinimize className="max-minimize"/></a></div>
        <Administration/>
      </div>
    );
  } 
  else {
    return (
      <div className={activeId === '0' ? 'panel-menu expanded' : 'panel-menu'}>
        <div className="header-nav"><a href="/" onClick={e => { e.preventDefault(); toggleActive('0')}}><MaxMinimize className="max-minimize"/></a></div>
        <ImpactAnalysis/>
      </div>
    );
  }
}

export default Menu;