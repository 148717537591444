import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { IcoCheck, IcoCircled, IcoBattery0, IcoBattery1, IcoBattery2, IcoBattery3 } from '@icons';
import { useHistory } from 'react-router-dom';

const steps = [
  { key: 'configure', label: <Trans code='A0495' />, isDone: true, position: 1 },
  { key: 'build', label: <Trans code='A0496' />, isDone: false, position: 4 },
  { key: 'execute', label: <Trans code='A0497' />, isDone: false, position: 8 },
];

const progressCalculator = (scenarioData) => {
  let progress = {};

  progress['general'] = {};
  progress['framework'] = {};
  progress['financials'] = {};
  progress['economics'] = {};
  progress['sroi'] = {};
  progress['esg'] = {};
  progress['execution'] = {};
  progress['comment'] = {};
  progress['configure'] = {};
  progress['build'] = {};
  progress['execute'] = {};

  progress['general']['threshold'] = 80;
  progress['framework']['threshold'] = 40;
  progress['financials']['threshold'] = 100;
  progress['economics']['threshold'] = 100;
  progress['sroi']['threshold'] = 100;
  progress['esg']['threshold'] = 100;
  progress['execution']['threshold'] = 100;
  progress['comment']['threshold'] = 40;

  if (scenarioData === null) {
    progress['general']['total'] = 100;
    progress['general']['done'] = 0;
    progress['general']['notdone'] = progress['general']['total'] - progress['general']['done'];

    progress['framework']['total'] = 100;
    progress['framework']['done'] = 0;
    progress['framework']['notdone'] =
      progress['framework']['total'] - progress['framework']['done'];

    progress['financials']['total'] = 100;
    progress['financials']['done'] = 0;
    progress['financials']['notdone'] =
      progress['financials']['total'] - progress['financials']['done'];

    progress['economics']['total'] = 100;
    progress['economics']['done'] = 0;
    progress['economics']['notdone'] =
      progress['economics']['total'] - progress['economics']['done'];

    progress['sroi']['total'] = 100;
    progress['sroi']['done'] = 0;
    progress['sroi']['notdone'] = progress['sroi']['total'] - progress['sroi']['done'];

    progress['esg']['total'] = 100;
    progress['esg']['done'] = 0;
    progress['esg']['notdone'] = progress['esg']['total'] - progress['esg']['done'];

    progress['execution']['total'] = 100;
    progress['execution']['done'] = 0;
    progress['execution']['notdone'] =
      progress['execution']['total'] - progress['execution']['done'];

    progress['comment']['total'] = 100;
    progress['comment']['done'] = 0;
    progress['comment']['notdone'] = progress['execution']['total'] - progress['execution']['done'];

    return progress;
  }

  //GENERAL
  let GFields = ['scenario_name', 'projectOwner', 'idOrganization', 'idSam', 'subsectorId']; //General
  let TFields = ['operation_start', 'operation_end', 'construction_start', 'construction_end']; //Tech
  let totG = GFields.length + TFields.length;

  let lGT = 0;

  GFields.forEach((f) => {
    //console.log('scenarioData[',scenarioData['general'][f]);

    if (scenarioData['general'][f] !== undefined && scenarioData['general'][f] !== null) {
      lGT++;
    }
  });

  TFields.forEach((f) => {
    if (scenarioData['tech'][f] !== undefined && scenarioData['tech'][f] !== null) {
      lGT++;
    }
  });

  progress['general']['total'] = 100;
  progress['general']['done'] = Math.ceil((lGT * 100) / totG);
  progress['general']['notdone'] = progress['general']['total'] - progress['general']['done'];

  //FRAMEWORK
  //let aiFields = ['note','purpose','shock','institutionale','model','modelass']
  let aiFields = ['context', 'institutional', 'model', 'modelass', 'purpose', 'shock'];
  let aiArr = ['files', /* 'immagine', */ 'link_attach'];
  let aiTot = aiArr.length + aiFields.length;

  let aiL = 0;

  aiFields.forEach((f) => {
    if (
      scenarioData['additionalInfo'][f] !== undefined &&
      scenarioData['additionalInfo'][f] !== ''
    ) {
      aiL++;
    }
  });

  aiArr.forEach((f) => {
    if (
      scenarioData['additionalInfo'][f] !== undefined &&
      scenarioData['additionalInfo'][f].length !== 0
    ) {
      aiL++;
    }
  });

  progress['framework']['total'] = 100;
  progress['framework']['done'] = Math.ceil((aiL * 100) / aiTot);
  progress['framework']['notdone'] = progress['framework']['total'] - progress['framework']['done'];

  progress['financials']['total'] = 100;
  progress['financials']['done'] = 0; //modificare
  progress['financials']['notdone'] =
    progress['financials']['total'] - progress['financials']['done'];

  //ECONOMICS
  let atLeastOneValue = 0;

  Object.keys(scenarioData.vettoriSpesa['CAPEX']).forEach((oecode) => {
    Object.keys(scenarioData.vettoriSpesa['CAPEX'][oecode]).forEach((year) => {
      if (parseFloat(scenarioData.vettoriSpesa['CAPEX'][oecode][year]) > 0) {
        atLeastOneValue++;
      }
    });
  });

  Object.keys(scenarioData.vettoriSpesa['OPEX']).forEach((oecode) => {
    Object.keys(scenarioData.vettoriSpesa['OPEX'][oecode]).forEach((year) => {
      if (parseFloat(scenarioData.vettoriSpesa['OPEX'][oecode][year]) > 0) {
        atLeastOneValue++;
      }
    });
  });

  Object.keys(scenarioData.vettoriSpesa['REVENUES']).forEach((oecode) => {
    Object.keys(scenarioData.vettoriSpesa['REVENUES'][oecode]).forEach((year) => {
      if (parseFloat(scenarioData.vettoriSpesa['REVENUES'][oecode][year]) > 0) {
        atLeastOneValue++;
      }
    });
  });

  progress['economics']['total'] = 100;
  progress['economics']['done'] = atLeastOneValue > 0 ? 100 : 0;
  progress['economics']['notdone'] = progress['economics']['total'] - progress['economics']['done'];

  //TECHNICALS
  progress['sroi']['total'] = 100;
  progress['sroi']['done'] = 0; //MODIFICARE
  progress['sroi']['notdone'] = progress['sroi']['total'] - progress['sroi']['done'];

  //ESG
  progress['esg']['total'] = 100;
  progress['esg']['done'] = 0; //MODIFICARE
  progress['esg']['notdone'] = progress['esg']['total'] - progress['esg']['done'];

  //EXECUTION
  progress['execution']['total'] = 100;
  progress['execution']['done'] = scenarioData.scenario.status === 'Executed' ? 100 : 0;
  progress['execution']['notdone'] = progress['execution']['total'] - progress['execution']['done'];

  //COMMENT
  let cFields = [
    'main_conclusion',
    'economic_analysis',
    'financial_analysis',
    'comment_results',
    'investment',
    'gdp',
    'production',
    'taxes',
    'incomes',
    'employment',
    'sdg',
    'kpi',
  ];
  let cArr = [];
  let cTot = cArr.length + cFields.length;

  let cL = 0;

  //console.log('scenarioData.additionalInfo', scenarioData);

  cFields.forEach((f) => {
    if (
      scenarioData['additionalInfo'][f] !== undefined &&
      scenarioData['additionalInfo'][f] !== ''
    ) {
      cL++;
    }
  });

  cArr.forEach((f) => {
    if (
      scenarioData['additionalInfo'][f] !== undefined &&
      scenarioData['additionalInfo'][f].length !== 0
    ) {
      cL++;
    }
  });

  progress['comment']['total'] = 100;
  progress['comment']['done'] = Math.ceil((cL * 100) / cTot);
  progress['comment']['notdone'] = progress['comment']['total'] - progress['comment']['done'];
  //console.log('progress.comment', progress.comment);

  return progress;
};

const WizardHeaderCompact = ({
  stepName,
  scenarioData,
  idScenario,
  shouldDisable,
  navigateCallback = null,
}) => {
  const [currentStep, setCurrentStep] = useState('');
  const history = useHistory();

  const buildDisabled = shouldDisable || idScenario === 'NaN';
  const executeDisabled = shouldDisable || !scenarioData?.dataset?.id;
  let progress;
  // let scenario_type;
  //console.log('progress', progress)
  console.log('SCENARIODATA', scenarioData?.dataset?.id, shouldDisable, buildDisabled, idScenario);
  useEffect(() => {
    setCurrentStep(
      steps
        .filter((step) => step.key === stepName)
        .map((step) => step.key)
        .pop()
    );
  }, [stepName]);

  progress = progressCalculator(null); //elimianre dopo modifiche id
  //riabilitare
  /* if(idScenario) {
      progress = progressCalculator(scenarioData);
      scenario_type = scenarioData.dashboard.scenario_type;
   } else {
      progress = progressCalculator(null);
   } */

  const progressIcon = (progress) => {
    if (progress === 0) {
      return <IcoBattery0 className='ico-header battery' />;
    } else if (progress > 0 && progress <= 40) {
      return <IcoBattery1 className='ico-header battery' />;
    } else if (progress > 40 && progress < 100) {
      return <IcoBattery2 className='ico-header battery' />;
    } else if (progress === 100) {
      return <IcoBattery3 className='ico-header battery' />;
    }
  };

  return (
    <Tabs
      activeKey={currentStep}
      onSelect={(k) => setCurrentStep(k)}
      className='mb-3 folder'
      justify>
      {' '}
      {/*o fill per label tab con fit-content*/}
      <Tab
        eventKey='configure'
        title={
          <Row
            className='align-items-center flex-column'
            onClick={() =>
              history.push(idScenario ? `/scenariowizard/${idScenario}?step=configure` : '#')
            }>
            <Col className='tabname'>
              <Trans code='A0495' />
            </Col>{' '}
            {/* CONFIGURE */}
            <Col>
              {progress.general.done === 100 ? (
                <IcoCheck className='ico-header' />
              ) : (
                <IcoCircled className='ico-header' />
              )}
            </Col>
          </Row>
        }></Tab>
      <Tab
        eventKey='build'
        // disabled={idScenario ? false : true}
        disabled={buildDisabled}
        // disabled={shouldDisable}
        title={
          <Row
            className='align-items-center flex-column'
            onClick={() =>
              history.push(idScenario ? `/scenariowizard/${idScenario}?step=build` : '#')
            }>
            <Col className='tabname'>
              <Trans code='A0496' />
            </Col>{' '}
            {/* BUILD */}
            <Col>
              {progress.economics.done === 100 ? (
                <IcoCheck className='ico-header' />
              ) : (
                <IcoCircled className='ico-header' />
              )}
            </Col>
          </Row>
        }></Tab>
      {/* <Tab
        eventKey='execute'
        // disabled={idScenario && scenarioData?.dataset?.id ? false : true}
        // disabled={shouldDisable}
        disabled={executeDisabled}
        title={
          <Row
            className='align-items-center flex-column'
            onClick={() =>
              history.push(idScenario ? `/scenariowizard/${idScenario}?step=execute` : '#')
            }>
            <Col className='tabname'>
              <Trans code='A0497' />
            </Col>
          
            <Col>
              {progress.execution.done === 100 ? (
                <IcoCheck className='ico-header' />
              ) : (
                <IcoCircled className='ico-header' />
              )}
            </Col>
          </Row>
        }></Tab> */}
    </Tabs>
  );
};

export default WizardHeaderCompact;
