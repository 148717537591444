import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { date } from '@lib/helpers';
const AssociatedScenarioTable = ({ associatedScenarios }) => {
  return (
    <table className='bm-table class'>
      <thead>
        <tr>
          <th>
            <Trans code='A0036' />
          </th>
          <th>
            <Trans code='A0639' />
          </th>
          <th>
            <Trans code='A0687' />
          </th>
          <th>
            {' '}
            <Trans code='A0081' />
          </th>
          <th>
            {' '}
            <Trans code='A0059' />
          </th>
          <th>
            {' '}
            <Trans code='A0071' />
          </th>
        </tr>
      </thead>
      <tbody>
        {associatedScenarios?.map((el) => {
          return (
            <tr key={el.id + el.name}>
              <td>
                <Link to={`/scenario/${el.id}/dashboard`}>
                  {/* <Link to={`/scenariowizard/${el.id}`}> */}
                  <Button className='text-left' variant='link'>
                    {el.name}
                  </Button>
                </Link>
              </td>
              {/* <td className='d-lg-table-cell text-center'></td> */}
              <td>{el.type}</td>
              <td>{el.status}</td>
              <td>{el.projectOwner}</td>
              <td>{el.company}</td>
              <td>{date(el.updatedAt)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AssociatedScenarioTable;
