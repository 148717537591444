import { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n';
import NewLegend from '@components/scenariodashboard/NewChartLegend';
import { chartColors as colors } from '@lib/helpers';

import AnimatedDonutChartDash from '@components/scenariodashboard/AnimatedDonutChartDash';

const TaxesDonutCard = ({ taxes, filterForRegion, chartId, langTitleCode, icon }) => {
  const [taxesData, setTaxesData] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  console.log('CARD:', taxes);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);

  useEffect(() => {
    let filter = filterForRegion === 'total' ? '' : filterForRegion;
    setTaxesData(
      taxes.filter((tax) => {
        // console.log('JOBTAX', job, filter);
        return tax.region === filter;
      })
    ); //to filter
  }, [taxes, filterForRegion]);
  // console.log('FHFHFHFH', taxesData);
  return (
    <div className='col-lg-6 col-md-12 col-sm-12 layout-charts d-flex container-card mb-5'>
      <Card className='cards-utente d-md-block '>
        <Card.Body>
          {icon}
          <Card.Title style={{ marginBottom: '5rem' }}>
            <Trans code={langTitleCode} />
          </Card.Title>
          <div className='d-flex justify-content-center align-items-center'>
            <div style={{ width: '300px' }}>
              <AnimatedDonutChartDash
                valueInDonut={false}
                chartId={chartId}
                data={taxesData}
                codeLangTag='A0053'
                translations={translations}
                currentLang={currentLang}
              />
            </div>
          </div>

          <div className='my-3'>
            <NewLegend data={taxesData} colors={colors} scope='taxes' />
            <p className='va-desc'>
              <Trans code='A0053' />
            </p>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TaxesDonutCard;

// import { useEffect, useState } from 'react';
// import { Card } from 'react-bootstrap';
// import { Ico45 } from '@icons/new';
// import { Trans, propTrans } from '@lib/i18n';
// import { rounder } from '@lib/helpers/index';
// import BillboardChart from 'react-billboardjs';
// import { donut } from 'billboard.js';
// import ChartLegend from '@components/ChartLegend';
// import { chartColors as colors } from '@lib/helpers';

// const TaxesDonutCard = ({ taxes, filterForRegion }) => {
//   const [taxesData, setTaxesData] = useState([]);
//   // const keys = taxesData?.map((el) => Object.values(el)).filter((k) => k !== 'Indirect Tax');
//   const [keys, setKeys] = useState([]);
//   const [chartColors, setChartColors] = useState([]);
//   // const chartColors = keys?.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {});
//   const colorFunction = (c, d) => {
//     console.log('NON', c, d);
//     return chartColors[d.id];
//   };
//   const [chartData, setChartData] = useState({
//     columns: keys.map((k) => [k.propertyName, 0]),
//     type: donut(),
//     color: colorFunction,
//   });
//   console.log('CHART', taxesData, keys, chartData, chartColors);

//   useEffect(() => {
//     let filter = filterForRegion === 'total' ? '' : filterForRegion;
//     setTaxesData(
//       taxes.filter((job) => {
//         return job.region === filter;
//       })
//     ); //to filter
//   }, [taxes, filterForRegion]);

//   useEffect(() => {
//     let tempArray = [];

//     taxesData?.forEach((tax) => {
//       tempArray.push(tax.propertyName.split(' ')[0]);
//     });

//     setKeys(tempArray);
//   }, [taxesData]);

//   useEffect(() => {
//     let total = taxesData
//       ?.filter((el) => el.propertyName !== 'total')
//       .reduce((acc, curr) => acc + curr.propertyValue, 0);
//     // if (total === undefined) {
//     //   total = Object.values(taxesData).reduce((prev, cur) => parseFloat(prev) + parseFloat(cur));
//     // }
//     console.log('TOTAL', total);
//     setChartData({
//       columns: taxesData?.map((k) => [k.propertyName, (k.propertyValue / total) * 100]),
//     });
//     // eslint-disable-next-line
//   }, [taxesData]);

//   useEffect(() => {
//     setChartColors(keys?.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {}));
//   }, [keys]);

//   return (
//     <Card className='cards-utente d-none d-md-block'>
//       <Card.Body>
//         <Ico45 className='logo-card ico-box-blue' />
//         <Card.Title>
//           <Trans code='A0202' />
//         </Card.Title>
//         <BillboardChart
//           data={chartData}
//           legend={{ show: false }}
//           donut={{ padAngle: 0.015, width: 35, label: { show: false } }}
//           tooltip={{
//             format: {
//               value: (d) => rounder(d) + ' %',
//               name: (n) => propTrans({ prop: n, scope: 'taxes' }),
//             },
//           }}
//           transition={{ duration: 800 }}
//         />
//         <ChartLegend data={keys} colors={colors} scope='taxes' />
//         <p className='va-desc'>
//           <Trans code='A0053' />
//         </p>
//       </Card.Body>
//     </Card>
//   );
// };

// export default TaxesDonutCard;
