import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IcoArrowUp, IcoArrowDown } from '@icons/index';
import { Trans } from '@lib/i18n';

import { downloadAsBlob } from '@lib/api/index';

import OEIframePreview from '../oeiframepreview/OEIframePreview';
const AttachmentsViewCard = ({ data }) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [previewBlob, setPreviewBlob] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!!data.length) {
      setCurrentFile(data[0]);
      setSelectOptions(
        data?.map((el, idx) => {
          return { value: el.ref, label: el.text, id: el.id };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const fetchBlob = async () => {
      setIsLoading(true);
      const response = await downloadAsBlob(currentFile.ref);
      setPreviewBlob(response);
      setIsLoading(false);
    };
    if (currentFile?.ref) {
      fetchBlob();
    }
  }, [currentFile]);

  const toggleSize = () => {
    setExpanded(!expanded);
  };

  const iframeStyle = {
    width: '100%',
    height:
      expanded && windowWidth > 1200
        ? '1320px'
        : expanded && windowWidth < 1200
        ? '762px'
        : '500px',
  };
  const fullScreenTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code={expanded ? 'A0784' : 'A0783'} />
    </Tooltip>
  );

  return (
    <>
      <div className='simple-box impact pb-5'>
        <div className='row'>
          <div className='col'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0786' />
            </h5>
          </div>
          <div className='col d-flex justify-content-end align-items-start'>
            <div className='col d-flex justify-content-end pr-2'>
              <div className='d-flex justify-content-center align-items-center mt-1 pr-2'>
                <label htmlFor='fileSelect' style={{ display: 'flex', alignItems: 'center' }}>
                  File:
                </label>
              </div>
              <div style={{ minWidth: '125px' }} className='col-5 pr-0 pl-0'>
                <Select
                  id='fileSelect'
                  name='fileSelect'
                  value={selectOptions?.find((el) => el.id === currentFile?.id)}
                  options={selectOptions}
                  onChange={(e) => setCurrentFile(data?.find((el) => el.id === e.id))}
                />
              </div>
            </div>
            <OverlayTrigger placement='top' overlay={fullScreenTooltip}>
              <Button
                style={{ border: 'none', minWidth: '38px' }}
                variant='primary'
                className='mr-3 p-0'
                onClick={() => toggleSize()}>
                <IcoArrowUp
                  style={{
                    transition: 'transform .3s',
                    transform: `rotate(${expanded ? 0 : 180}deg)`,
                  }}
                  className='ico-small  customSmallIcon'
                />
                {/* {expanded ? (
                <IcoArrowUp
                  className='ico-small customSmallIcon'
                />
                ) : (
                  <IcoArrowDown className='ico-small customSmallIcon' />
                )} */}
              </Button>
            </OverlayTrigger>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <OEIframePreview blob={previewBlob} style={iframeStyle} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AttachmentsViewCard;
