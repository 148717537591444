import React from 'react';

const Administration = () => {

    return (
        <div id="scenari" className="megaContent">
            <div className="container-fluid">
                <div className="row">
                    DEVELOPMENT ONGOING
                </div>
            </div>
        </div>
    );
  }
  
  export default Administration;