import { getCorporateAnalysesClasses } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import React, { useEffect, useState } from 'react';
import CorporateEsgAnalysesClassSelector from './CorporateEsgAnalysesClassSelector';
import StatusBar from 'components/wizard/StatusBar';
import CustomSpinner from 'components/CustomSpinner';

const CorporateEsgAnalysesClassWrapper = ({ companyId }) => {
  const [classes, setClasses] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!companyId) return;
    fetchData(
      getCorporateAnalysesClasses,
      setClasses,
      null,
      [companyId],
      null,
      setError,
      setIsLoading
    );
  }, [companyId]);

  if (isLoading) {
    return (
      <div className='container-fluid'>
        <CustomSpinner style={{ minHeight: '100px' }} />
      </div>
    );
  }
  return (
    <div>
      <div className='d-flex flex-wrap' style={{ gap: '15px' }}>
        {!!classes.length &&
          classes.map((classe) => (
            <CorporateEsgAnalysesClassSelector
              key={classe.id}
              companyId={companyId}
              classe={classe}
              setError={setError}
              setIsLoading={setIsLoading}
            />
          ))}
      </div>

      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default CorporateEsgAnalysesClassWrapper;
