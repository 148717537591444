import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { IcoPlus, Lens } from '@icons';
import { getExpenseItems, postExpenseItem } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/main';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererExpanseItems from '@components/adminManagement/expenseItemsManagement/ActionBtnRendererExpenseItems';
import StatusBar from 'components/wizard/StatusBar';
import { Trans, propTrans } from '@lib/i18n';

const initialState = {
  name: '',
  position: null,
  expenseType: '', // capex/opex/revenues/residualValue
  // langTag: '',
  // langTagDesc: '',
};

const optionsTypes = [
  {
    value: 'capex',
    label: 'Capex',
  },
  {
    value: 'opex',
    label: 'Opex',
  },
  {
    value: 'revenues',
    label: 'Revenues',
  },
  {
    value: 'residualValue',
    label: 'Residual value',
  },
];

const ExpenseItems = () => {
  const [expenseItems, setExpenseItems] = useState([]);
  const [newExpenseItem, setNewExpenseItem] = useState(initialState);
  const [selectedExpenseType, setSelectedExpenseType] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  console.log('expenseItems', expenseItems);
  console.log('newExpenseItem', newExpenseItem);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const resetForm = () => {
    setNewExpenseItem(initialState);
    setSelectedExpenseType([]);
    setSbShow(<></>);
  };

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      getExpenseItems().then((retrievedExpenseItems) => {
        setExpenseItems(retrievedExpenseItems.data);
      });

      setRefresh(false);
    }
  }, [refresh]);

  const onChange = (e, setSelect) => {
    if (setSelect) {
      setSelect(e ?? []);

      setNewExpenseItem(() => ({
        ...newExpenseItem,
        expenseType: e.value ?? [],
      }));
    } else {
      setNewExpenseItem(() => ({
        ...newExpenseItem,
        [e.target.name]:
          e.target.type === 'number'
            ? e.target.value
              ? Number(e.target.value)
              : ''
            : e.target.value,
      }));
    }
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  /* const onFirstDataRendered = useCallback((params) => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  /* const onFirstDataRendered = useCallback((params) => {
      gridRef.current.api.sizeColumnsToFit();
   }, []); */

  const onGridReady = useCallback(() => {
    getExpenseItems().then((retrievedExpenseItems) => {
      setExpenseItems(retrievedExpenseItems.data);
    });

    //sort the column 'position' in ascending order
    gridRef.current.columnApi.applyColumnState({
      state: [
        {
          colId: 'expenseType',
          sort: 'asc',
        },
        {
          colId: 'position',
          sort: 'desc',
        },
      ],
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  /* const onVirtualRowRemoved = useCallback(e => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'ID',
        field: 'id',
        filter: 'agTextColumnFilter',
        maxWidth: 100,
        valueGetter: (params) => {
          return params.data.id;
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0636' }),
        field: 'name',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.name; //langTag with propTrans?
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0660' }),
        field: 'expenseType',
        sortable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          const type = optionsTypes
            .filter((optionType) => optionType.value === params.data.expenseType)
            .map((opt) => opt.label);

          return type;
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0661' }),
        field: 'position',
        sortable: true,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.position;
        },
      },
      {
        pinned: 'right',
        maxWidth: 130,
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionBtnRendererExpanseItems,
        cellRendererParams: {
          setRefresh: setRefresh,
          optionsTypes: optionsTypes,
        },
      },
    ]);
  }, [expenseItems]);

  const handleNewExpenseItem = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { langTag, langTagDesc, ...restExpenseItem } = newExpenseItem;
    const isFilled = Object.values(restExpenseItem).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    if (!isFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }
    handleStatusBar('elaborating', false, false);
    const createResult = await postExpenseItem(newExpenseItem);
    //console.log('createResult', createResult);

    if (createResult.status) {
      handleStatusBar('success', <Trans code='A0673' />, true);

      setTimeout(() => {
        resetForm();
        setShowModalNew(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', createResult.message ?? <Trans code='A0521' />, false);
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='expense-items' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-5'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0620' />
            </h5>
          </div>
          <div className='col-7 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    <Trans code='A0665' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder={propTrans({ prop: 'A0035' }) + '...'}
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={expenseItems}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                //onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                <Trans code='A0667' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModalNew} onHide={() => handleCloseModal(setShowModalNew)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>
            <Trans code='A0666' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewExpenseItem} id='form-admin-expense-items'>
            <Row className='mb-3'>
              <Form.Group className='px-4 col-12' controlId='name'>
                <Form.Label className='mb-3'>
                  <Trans code='A0636' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  value={newExpenseItem.name}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='expenseType'>
                <Form.Label className='mb-3'>
                  <Trans code='A0660' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Select
                  name='expenseType'
                  value={selectedExpenseType}
                  options={optionsTypes}
                  onChange={(e) => onChange(e, setSelectedExpenseType)}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='position'>
                <Form.Label className='mb-3'>
                  <Trans code='A0661' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='number'
                  name='position'
                  step={1}
                  value={newExpenseItem.position ?? ''}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>
          </Form>
          {sbShow}

          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;
            <Trans code='A0503' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={(e) => handleNewExpenseItem(e)}>
            <Trans code='A0644' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExpenseItems;
