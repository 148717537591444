import React, { useState, useEffect } from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n';
import MarkdownReaderComponent from 'components/MarkdownComponents/MarkdownReaderComponent';
import CustomModal from 'components/CustomModal/CustomModal';

const ModalListItem = ({ listTitle, modalContent, disabled }) => {
  const [showModalResource, setShowModalResource] = useState(false);

  const handleCloseModal = (setShowModal) => {
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => {
    setShowModal(true);
  };

  return (
    <>
      <ListGroup.Item className='d-flex align-items-center' disabled={disabled}>
        <strong
          onClick={() => handleShowModal(setShowModalResource)}
          className='listGroup-link px-3'
          style={{ cursor: 'pointer', width: '100%' }}>
          <Trans code={listTitle} />
        </strong>
      </ListGroup.Item>
      {!disabled && (
        <CustomModal
          show={showModalResource}
          handleClose={() => handleCloseModal(setShowModalResource)}
          title={propTrans({ prop: listTitle })}
          content={
            <div className='my-3'>
              {/* <span>{modalContent}</span> */}
              <MarkdownReaderComponent text={modalContent} />
            </div>
          }
          modalSize='xl'
          buttonText={propTrans({ prop: 'A0529' })}
        />
      )}
    </>
  );
};

export default ModalListItem;
