import React, { useState, useEffect } from 'react';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Link } from 'react-router-dom';
import { Trans, propTrans } from '@lib/i18n';
// import { date } from '@lib/helpers';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStop, faThLarge, faFileAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import {
  IcoCanc,
  IcoEdit,
  IcoCopy,
  IcoPlus,
  IcoMinusCircled,
  IcoDownloadFile,
  IcoUploadFile,
  Lens,
} from '@icons';
import {
  checkBridgeMatrices,
  /* getInterventTypes, */ putEditClass,
  copyClass,
  copyFullClass,
  deleteClass,
  postBridgeMatricesFromClass,
  downloadBridgeMatrix,
  uploadBridgeMatrix,
  showBridgeMatrices,
  getSectors,
  getClass,
} from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import ShowBridgeMatrix from './ShowBridgeMatrix';
import AssociatedScenarioTable from '../associatedScenario/associatedScenarioTable';
import UploadModal from '../uploadModal/uploadModal';
import { Fragment } from 'react';
import DocumentsWrapper from 'components/Documents/DocumentsWrapper';
import QuestionsWrapper from './QuestionTab/QuestionsWrapper';

// const tempDatasetsBM = [
//   {
//     idSam: null, //se standard null, se no id
//     title: 'Standard',
//     type: 'standard',
//     country: '',
//     region: '',
//     year: '',
//     checkCapex: {
//       status: 'green',
//       message: '',
//     },
//     checkOpex: {
//       status: 'green',
//       message: '',
//     },
//     bmCapex: 'idMatrice', //se esiste bm allora id, se no null
//     bmOpex: 'idMatrice',
//     expenseItemsListId: '',
//   },
//   {
//     idSam: 1,
//     title: 'Russia',
//     type: 'noStandard',
//     country: 'Russia',
//     region: '',
//     year: '2022',
//     checkCapex: {
//       status: 'red',
//       message: '',
//     },
//     checkOpex: {
//       status: 'green',
//       message: '',
//     },
//     bmCapex: 'idMatrice',
//     bmOpex: 'idMatrice',
//   },
//   {
//     idSam: 2,
//     title: 'Brazil',
//     type: 'noStandard',
//     country: 'Brazil',
//     region: 'Bahia',
//     year: '2021',
//     checkCapex: {
//       status: 'red',
//       message: '',
//     },
//     checkOpex: {
//       status: 'red',
//       message: '',
//     },
//     bmCapex: 'idMatrice',
//     bmOpex: 'idMatrice',
//   },
//   {
//     idSam: 3,
//     title: 'Congo',
//     type: 'noStandard',
//     country: 'Congo',
//     region: '',
//     year: '2020',
//     checkCapex: {
//       status: '',
//       message: '',
//     },
//     checkOpex: {
//       status: '',
//       message: '',
//     },
//     bmCapex: null,
//     bmOpex: null,
//   },
// ];
function compareArrays(array1, array2) {
  // Check if arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Check if all ids in array2 exist in array1
  const array1Ids = array1.map((obj) => obj.id);
  const array2Ids = array2.map((obj) => obj.id);

  for (const id of array2Ids) {
    if (!array1Ids.includes(id)) {
      return false;
    }
  }

  return true;
}

const tempDatasetsBM = [
  {
    secdisagCode: '',

    capexStatus: '',

    opexStatus: '',

    expenseItemsListId: '',
  },
  {
    idSam: 1,
    title: 'Russia',
    type: 'noStandard',
    country: 'Russia',
    region: '',
    year: '2022',
    checkCapex: {
      status: 'red',
      message: '',
    },
    checkOpex: {
      status: 'green',
      message: '',
    },
    bmCapex: 'idMatrice',
    bmOpex: 'idMatrice',
  },
  {
    idSam: 2,
    title: 'Brazil',
    type: 'noStandard',
    country: 'Brazil',
    region: 'Bahia',
    year: '2021',
    checkCapex: {
      status: 'red',
      message: '',
    },
    checkOpex: {
      status: 'red',
      message: '',
    },
    bmCapex: 'idMatrice',
    bmOpex: 'idMatrice',
  },
  {
    idSam: 3,
    title: 'Congo',
    type: 'noStandard',
    country: 'Congo',
    region: '',
    year: '2020',
    checkCapex: {
      status: '',
      message: '',
    },
    checkOpex: {
      status: '',
      message: '',
    },
    bmCapex: null,
    bmOpex: null,
  },
];

const ActionBtnRenderer = ({
  data,
  setRefresh,
  optionsNaceSectors,
  optionsInterventTypes,
  optionsCompanies,
  optionsKpi,
  optionsExpenseItemsProp,
  optionsTags,
  datasetsBM,
  oeSecDisags,
  scenari,
  setTableSbShow,
}) => {
  const id = data.id;
  const [thisClass, setThisClass] = useState({});
  const [scenariByClass, setScenariByClass] = useState([]);
  const [selectedSector, setSelectedSector] = useState({});
  const [selectedInterventType, setSelectedInterventType] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState([]);
  const [optionsTagsEdit, setOptionsTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [optionsExpenseItems, setOptionsExpenseItems] = useState(optionsExpenseItemsProp);
  const [expenseItemCapex, setExpenseItemCapex] = useState([]);
  const [expenseItemOpex, setExpenseItemOpex] = useState([]);
  const [expenseItemRevenues, setExpenseItemRevenues] = useState([]);

  const [selectedRevenues, setSelectedRevenues] = useState([]);
  const [sumOfCapex, setSumOfCapex] = useState(0);
  const [sumOfOpex, setSumOfOpex] = useState(0);

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalBM, setShowModalBM] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const [sbShow, setSbShow] = useState(<></>);
  const [bmSbShow, setBmSbShow] = useState(<></>);
  const [associatedScenarios, setAssociatedScenarios] = useState(false);
  const [checkedBridgeMatrices, setCheckedBridgeMatrices] = useState([]);
  // const [arrayBm, setArrayBm] = useState([]);
  const [showCapexModalUpload, setShowCapexModalUpload] = useState(false);
  const [showOpexModalUpload, setShowOpexModalUpload] = useState(false);
  const [capexFile, setCapexFile] = useState(null);
  const [opexFile, setOpexFile] = useState(null);
  const [sbShowUpload, setSbShowUpload] = useState(<></>);

  const [bmToShow, setBmToShow] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [sectorToUse, setSectorToUse] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  // const [checkedBmOe, setCheckedBmOe] = useState([]);
  // const [checkedBmWiot, setCheckedBmWiot] = useState([]);
  //console.log('selected thisClass', thisClass);
  //console.log('scenariByClass', scenariByClass);
  //console.log('expenseItemCapex', expenseItemCapex);
  //console.log('expenseItemOpex', expenseItemOpex);
  //console.log('expenseItemRevenues', expenseItemRevenues);
  // console.log('SecDisag', oeSecDisags);
  // console.log('selectedector', selectedSector, sectorToUse);
  const handleCapexFileChange = (event) => {
    console.log(event.target.files[0]);
    setCapexFile(event.target.files[0]);
  };
  const handleOpexFileChange = (event) => {
    console.log(event.target.files[0]);
    setOpexFile(event.target.files[0]);
  };

  // useEffect(() => {
  //   let tempArray = [];
  //   let tempCapexStatus;
  //   let tempOpexStatus;
  //   let tempExpenseItemsListId;
  //   oeSecDisags.forEach((secDisag) => {
  //     // EUREGIO, WIOT, OE
  //     checkedBridgeMatrices.forEach((checked) => {
  //       if (checked.secdisagCode === secDisag.value) {
  //         if (checked.type === 'capex') {
  //           tempCapexStatus = checked.status;
  //         } else if (checked.type === 'opex') {
  //           tempOpexStatus = checked.status;
  //         }
  //         tempExpenseItemsListId = checked.expenseitemslistsId;
  //       }
  //     });
  //     tempArray.push({
  //       secdisagCode: secDisag.value,

  //       capexStatus: tempCapexStatus,

  //       opexStatus: tempOpexStatus,

  //       expenseItemsListId: tempExpenseItemsListId,
  //     });
  //     tempCapexStatus = undefined;
  //     tempOpexStatus = undefined;
  //     tempExpenseItemsListId = undefined;
  //   });

  //   setArrayBm(tempArray);
  // }, [oeSecDisags, checkedBridgeMatrices]);

  useEffect(() => {
    //  setScenariByClass(
    //    scenari.filter((el) => {
    //      return data.associatedScenarios.some((f) => {
    //        return f.id === el.id;
    //      });
    //    })
    //  );
    //  console.log(
    //    'SCENARI BY CLASS:',
    //    scenari.filter((el) => {
    //      return data.associatedScenarios.some((f) => {
    //        return f.id === el.id;
    //      });
    //    })
    //  );
    //  //  console.log('OLD SCENARI:', oldScenari.slice(-5));
    setThisClass(data);

    setAssociatedScenarios(data.associatedScenarios.length > 0 ? true : false);
    //forse va in if di riga 140
    /* getInterventTypes(data.investmentType).then(retrievedInterventTypes => {
         console.log('retrievedInterventTypes', retrievedInterventTypes);
         setOptionsInterventTypesEdit(retrievedInterventTypes.map(interventType => ({
            value: interventType.code,
            label: interventType.name,
         })));
      }); */

    setOptionsTags(optionsTags);

    if (Object.keys(data).length > 0) {
      setSelectedSector(
        ...optionsNaceSectors.filter((optionSector) => data.sectorCode === optionSector.value)
      );
      setSelectedInterventType(
        optionsInterventTypes.filter(
          (optionIntervent) => data.investmentType === optionIntervent.value
        )
      );
      setSelectedCompany(
        optionsCompanies.filter((optionOrganization) => data.companyId === optionOrganization.value)
      );

      setSelectedKpi(
        optionsKpi.filter((optionKpi) => {
          return data.kpi.some((kpi) => {
            return Number(optionKpi.value) === Number(kpi.id);
          });
        })
      );

      setSelectedTags(
        optionsTags.filter((optionTag) => {
          return data.tags.some((tag) => {
            return Number(optionTag.value) === Number(tag.id);
          });
        })
      );

      if (data.expenseitemslist?.[0]) {
        let tempExpenseItemCapex = data.expenseitemslist?.[0].expenseitems.filter(
          (expItem) => expItem.expenseType === 'capex'
        );
        let tempExpenseItemOpex = data.expenseitemslist?.[0].expenseitems.filter(
          (expItem) => expItem.expenseType === 'opex'
        );
        let tempExpenseItemRevenues = data.expenseitemslist?.[0].expenseitems.filter(
          (expItem) => expItem.expenseType === 'revenues'
        );

        setExpenseItemCapex(
          tempExpenseItemCapex.length > 0
            ? tempExpenseItemCapex
            : [{ id: '', expenseType: '', percentage: 0 }]
        );
        setExpenseItemOpex(
          tempExpenseItemOpex.length > 0
            ? tempExpenseItemOpex
            : [{ id: '', expenseType: '', percentage: 0 }]
        );
        setExpenseItemRevenues(
          tempExpenseItemRevenues.length > 0
            ? tempExpenseItemRevenues
            : [{ id: '', expenseType: '', percentage: 0 }]
        );

        setSelectedRevenues(
          optionsExpenseItems.filter((optionExpenseItem) => {
            return data.expenseitemslist?.[0].expenseitems.some((expItem) => {
              return (
                expItem.expenseType === 'revenues' &&
                Number(optionExpenseItem.value) === Number(expItem.id)
              );
            });
          })
        );
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let allExpenseItemsSelected = [];
    allExpenseItemsSelected = allExpenseItemsSelected.concat(expenseItemCapex, expenseItemOpex);

    const expItemsDisabled = optionsExpenseItems
      .filter((option) => {
        return allExpenseItemsSelected.some((itemSelected) => {
          return Number(option.value) === Number(itemSelected.id);
        });
      })
      .map((expItem) => ({
        value: expItem.value,
        label: expItem.label,
        type: expItem.type,
        isDisabled: true,
      }));

    const expItemsNotDisabled = optionsExpenseItems
      .filter((option) => {
        return !allExpenseItemsSelected.some((itemSelected) => {
          return Number(option.value) === Number(itemSelected.id);
        });
      })
      .map((expItem) => ({
        value: expItem.value,
        label: expItem.label,
        type: expItem.type,
        isDisabled: false,
      }));

    setOptionsExpenseItems(expItemsDisabled.concat(expItemsNotDisabled));

    setSumOfCapex(
      expenseItemCapex.reduce((tot, elem) => {
        return tot + Number(elem.percentage);
      }, 0)
    );
    setSumOfOpex(
      expenseItemOpex.reduce((tot, elem) => {
        return tot + Number(elem.percentage);
      }, 0)
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseItemCapex, expenseItemOpex]);

  useEffect(() => {
    expenseItemsRows();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseItemCapex, expenseItemOpex]);

  useEffect(() => {
    buildTableBM();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetsBM]);

  //   useEffect(() => {
  //     buildTableScenariByClass();
  //     //eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [scenari]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setSbShowUpload(<></>);
    setBmSbShow(<></>);
    setRefresh(true);
    setShowModal(false);
  };

  const handleShowModal = (setShowModal) => {
    checkBridgeMatrices(data.id).then((res) => {
      if (res?.status) {
        setCheckedBridgeMatrices(res.data);
      } else {
        handleStatusBar('error', res.message ?? propTrans({ prop: 'A0692' }), false);
      }
    });

    getSectors().then((res) => setSectors(res.data));

    setShowModal(true);
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  const handleTableStatusBar = (status, message, timeout) => {
    setTableSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };
  const handleBmStatusBar = (status, message, timeout) => {
    setBmSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleModalStatusBar = (status, message, timeout) => {
    setSbShowUpload(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const addExpenseItem = (typeArray, setTypeArray, expenseType) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.push({ id: '', percentage: 0, expenseType: expenseType });

    setTypeArray(tempTypeArray);
  };

  const removeExpenseItem = (typeArray, setTypeArray, index) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.splice(index, 1);

    setTypeArray(tempTypeArray);
  };

  const onChange = (e, fieldName, setState, state, expItemIndex, expenseType) => {
    //(e, 'expenseItems', setExpenseItems, expenseItems, i, expenseType)
    //(e, 'tags', setSelectedTags)
    //(e, 'kpi', setSelectedKpi)
    if (fieldName === 'expenseItems') {
      if (expenseType === 'revenues') {
        setSelectedRevenues(e ?? []);

        let tempRevenues = e
          ? e.map((el) => ({
              id: el.value,
              expenseType: expenseType,
              percentage: 0,
            }))
          : [];

        setState(tempRevenues);
      } else {
        if (e && e.target && e.target.name === 'percentage') {
          //campo percentage
          let tempExpenseItems = [...state];

          let tempElement = {
            ...tempExpenseItems[expItemIndex],
            percentage: e.target.value === '' ? null : Number(e.target.value),
          };

          tempExpenseItems[expItemIndex] = tempElement;
          setState(tempExpenseItems);
        }
        // else if (e && e.target && e.target.name === 'capexToOpexShare') {
        //   //campo capexToOpexShare

        //   setThisClass(() => ({
        //     ...thisClass,
        //     expenseitemslist: {
        //       ...thisClass.expenseitemslist,
        //       [fieldName]: e.target.value ? Number(e.target.value) : null,
        //     },
        //   }));
        // }
        else {
          //campo select expenseItems
          let tempExpenseItems = [...state];

          if (e) {
            let tempElement = {
              ...tempExpenseItems[expItemIndex],
              id: e.value,
              expenseType: expenseType,
            };

            tempExpenseItems[expItemIndex] = tempElement;

            setState(tempExpenseItems);
          } else {
            //campo opex null
            tempExpenseItems.splice(expItemIndex, 1);

            thisClass.expenseitemslist[0].capexToOpexShare = null;

            setThisClass(thisClass);

            setState(
              tempExpenseItems.length > 0
                ? tempExpenseItems
                : [{ id: '', percentage: 0, expenseType: 'opex' }]
            );
          }
        }
      }
    } else {
      if (setState) {
        setState(e ?? []);
      }

      let tempThisClass = { ...thisClass };
      let value;

      switch (fieldName) {
        case 'sectorCode':
          value = e.value;

          //ritorna i tipi di intervento collegati al settore e fa il set delle opzioni del dropdown
          /* getInterventTypes(value).then(filteredInterventTypes => {
                  //console.log('filteredInterventTypes', filteredInterventTypes);
                  setOptionsInterventTypes(filteredInterventTypes.map(interventType => ({
                     value: interventType.code,
                     label: interventType.name,
                  })));
               }); */

          setSelectedInterventType([]);

          tempThisClass.investmentType = '';
          break;
        case 'investmentType':
        case 'companyId':
          value = e.value;
          break;
        case 'tags':
        case 'kpi':
          value = e;
          break;
        case 'localization':
          value = e.target.checked;
          break;
        case 'capexToOpexShare':
          console.log('TEST');
          tempThisClass.expenseitemslist[0].capexToOpexShare = e.target.value
            ? Number(e.target.value)
            : null;
          break;
        default:
          value = e.target.value;
          tempThisClass[fieldName] = value;
      }

      setThisClass(tempThisClass);
    }
  };

  const onCreateOption = (inputValue) => {
    //si attiva solo in creazione
    const newOption = { value: inputValue, label: inputValue, newOption: true };

    setOptionsTags((prev) => [...prev, newOption]);
    setSelectedTags((prev) => [...prev, newOption]);

    let tempTags = [...thisClass.tags];
    tempTags.push(newOption);

    setThisClass(() => ({
      ...thisClass,
      tags: tempTags,
    }));
  };

  useEffect(() => {
    if (checkedBridgeMatrices.length === 0) {
      setIsConfirmed(true);
    } else {
      const newOpexCapexToCheck = [...expenseItemCapex, ...expenseItemOpex];
      const opexCapexToCheck = data?.expenseitemslist?.[0]?.expenseitems.filter(
        (expenseItem) => expenseItem.expenseType === 'capex' || expenseItem.expenseType === 'opex'
      );

      const areTheSame = compareArrays(opexCapexToCheck, newOpexCapexToCheck);

      if (areTheSame) setIsConfirmed(true);
      else setIsConfirmed(false);
    }
  }, [data, expenseItemCapex, expenseItemOpex, checkedBridgeMatrices]);

  const handleEditStatus = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { description, tags, localization, active, kpi, langTagDesc, ...restClass } = thisClass;
    console.log('restClass', restClass, expenseItemCapex, expenseItemOpex, expenseItemRevenues);

    const isClassFilled = Object.values(restClass).every((prop) => prop !== '' && prop != null)
      ? true
      : false;
    // const isKpiFilled = Object.values(selectedKpi).length > 0 ? true : false;
    const isCapexFilled =
      expenseItemCapex.length > 0 &&
      expenseItemCapex.every((prop) => prop.id !== '' && prop.id !== null)
        ? true
        : false;
    const isOpexFilled =
      expenseItemOpex.length > 0 &&
      expenseItemOpex.every((prop) => prop.id !== '' && prop.id !== null)
        ? true
        : false;
    const isRevenuesFilled =
      expenseItemRevenues.length > 0 &&
      expenseItemRevenues.every((prop) => prop.id !== '' && prop.id != null)
        ? true
        : false;
    const isExpenseItemsFilled = isCapexFilled && isOpexFilled && isRevenuesFilled;
    //console.log('isExpenseItemsFilled:', isExpenseItemsFilled);

    // if (!isClassFilled || !isKpiFilled || !isExpenseItemsFilled) {
    if (!isClassFilled || !isExpenseItemsFilled) {
      handleStatusBar('error', propTrans({ prop: 'A0522' }), false);
      return;
    }

    if ((sumOfCapex !== 0 && sumOfCapex !== 100) || (sumOfOpex !== 0 && sumOfOpex !== 100)) {
      handleStatusBar('error', propTrans({ prop: 'A0523' }), false);
      return;
    }

    thisClass.kpi = selectedKpi.length > 0 ? selectedKpi.map((kpi) => kpi.value) : [];

    thisClass.tags =
      selectedTags.length > 0
        ? selectedTags.map((selectedTag) => ({
            id: selectedTag.newOption ? null : selectedTag.value,
            title: selectedTag.label,
          }))
        : [];

    let tempExpenseItems = [];

    tempExpenseItems = tempExpenseItems.concat(
      expenseItemCapex,
      expenseItemOpex,
      expenseItemRevenues
    );

    thisClass.expenseitemslist[0].expenseitems =
      tempExpenseItems.length > 0
        ? tempExpenseItems
            .filter((expItem) => expItem.id)
            .map((expItem) => ({
              id: expItem.id,
              percentage: expItem.percentage ?? 0,
              expenseType: expItem.expenseType,
            }))
        : [];

    //console.log('thisClass edit:', thisClass);
    handleStatusBar('elaborating', false, false);
    const editResult = await putEditClass(thisClass, id);

    if (editResult.status) {
      handleStatusBar('success', propTrans({ prop: 'A0524' }), true);

      //codice per BM qui. manca edit.Result.data?

      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', editResult.message ?? propTrans({ prop: 'A0521' }), false);
    }
  };

  const handleDelete = async () => {
    const deleteResult = await deleteClass(id);

    if (deleteResult.status) {
      handleStatusBar('success', propTrans({ prop: 'A0526' }), true);

      setTimeout(() => {
        setShowModalDelete(false);

        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', deleteResult.message ?? propTrans({ prop: 'A0527' }), false);
    }
  };

  const handleCopy = async () => {
    console.log('KPITEST', thisClass);
    const copyResult = await copyFullClass(thisClass, id);
    // const copyResult = withKPI
    //   ? await copyFullClass(thisClass, id)
    //   : await copyClass(thisClass, id);
    //console.log('copyResult', copyResult);

    if (copyResult.status === true) {
      handleTableStatusBar('success', propTrans({ prop: 'A0695' }), true);

      setTimeout(() => {
        setRefresh(true);
      }, 2000);
    } else {
      handleTableStatusBar('error', copyResult.message ?? propTrans({ prop: 'A0694' }), false);
    }
  };

  const createBridgeMatrices = async (secDisag) => {
    const result = await postBridgeMatricesFromClass(id, undefined, secDisag);
    console.log('result', result);

    if (result.status) {
      handleBmStatusBar('success', propTrans({ prop: 'A0698' }), true);

      setTimeout(() => {
        getClass(id).then((classResult) => setThisClass(classResult.data));
        checkBridgeMatrices(data.id).then((res) => {
          console.log('INFO:', res.data);
          setCheckedBridgeMatrices(res.data);
        });
        // setRefresh(true);
      }, 2000);
    } else {
      handleBmStatusBar('error', result.message ?? propTrans({ prop: 'A0692' }), false);
    }
  };

  const editTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'F00055' })}</Tooltip>;
  const cloneTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'F00057' })}</Tooltip>;
  const deleteTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'F00056' })}</Tooltip>;

  const singleTooltip = (props) => <Tooltip {...props}>Single</Tooltip>;

  const investimentoTooltip = (props) => <Tooltip {...props}>Investimento</Tooltip>;

  const expenseItemsRows = (expenseItems, setExpenseItems, expenseType) => {
    let rows = [];

    if (expenseItems) {
      //console.log('expenseItems', expenseItems);
      let lastIndex = expenseItems.length - 1;

      rows = expenseItems.map((el, i) => {
        const restExpenseItems = {
          id: expenseItems[i].id,
          expenseType: expenseItems[i].expenseType,
        };

        const isThisIndicatorFilled = Object.values(restExpenseItems).every((prop) => prop !== '')
          ? true
          : false;

        return (
          <Row className='align-items-center mb-3' key={i}>
            <Col className='col-6'>
              <Select
                isDisabled={associatedScenarios}
                name='id'
                value={
                  el.id === ''
                    ? []
                    : optionsExpenseItems.filter((opt) => Number(opt.value) === Number(el.id))
                }
                options={optionsExpenseItems.filter((opt) => opt.type === expenseType)}
                onChange={(e) =>
                  onChange(e, 'expenseItems', setExpenseItems, expenseItems, i, expenseType)
                }
                /* {...(expenseType === 'opex' && expenseItems.length === 1 ? 
                     {isClearable: true} : null
                     )} */
              />
            </Col>
            {expenseType !== 'revenues' ? (
              <Col className='pl-5'>
                <Row className='align-items-center'>
                  <Col>
                    <InputGroup style={{ width: '12rem' }}>
                      <Form.Control
                        className='custom-disabled'
                        disabled={associatedScenarios}
                        type='number'
                        name='percentage'
                        placeholder='percentage'
                        min={0}
                        max={100}
                        step={10}
                        value={el.percentage ?? ''}
                        onChange={(e) =>
                          onChange(e, 'expenseItems', setExpenseItems, expenseItems, i, expenseType)
                        }
                      />
                      <InputGroup.Text
                        disabled={associatedScenarios}
                        className='addon-for-number custom-disabled'>
                        &#37;
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col>
                    {expenseItems.length !== 1 ? (
                      <Button
                        disabled={associatedScenarios}
                        variant='link'
                        className='mr-3'
                        onClick={() => removeExpenseItem(expenseItems, setExpenseItems, i)}>
                        <IcoMinusCircled className='red-color ico-small indicators' />
                      </Button>
                    ) : null}
                    {isThisIndicatorFilled &&
                    optionsExpenseItems.filter(
                      (opt) => opt.type === expenseType && opt.isDisabled === false
                    ).length !== 0 &&
                    i === lastIndex ? (
                      <Button
                        disabled={associatedScenarios}
                        variant='link'
                        onClick={() => addExpenseItem(expenseItems, setExpenseItems, expenseType)}>
                        <IcoPlus className='second-color ico-small indicators' />
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
        );
      });
    }

    return rows;
  };

  const handleDownload = async (expenseItemListId, type, secDisagCode) => {
    handleBmStatusBar('elaborating', false, true);
    const downloadResponse = await downloadBridgeMatrix(expenseItemListId, type, secDisagCode);
    console.log('DOWNLOAD:', downloadResponse);
    if (downloadResponse.ok === true) {
      handleBmStatusBar('success', <Trans code='A0657' />, true);
      const blob = await downloadResponse.blob();

      const file = new File([blob], id);

      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `${secDisagCode}-${type}.xlsx`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      setTimeout(() => {
        setBmSbShow(<></>);
      }, 5000);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      const res = await downloadResponse.json();
      console.log(res);
      handleBmStatusBar('error', `${res.message}`, false);
    }
  };

  const handleUpload = async (e, expenseItemListId, type, secDisagCode, file) => {
    e.preventDefault();
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file, file.name);
    handleModalStatusBar('elaborating', true);
    const uploadResult = await uploadBridgeMatrix(expenseItemListId, type, secDisagCode, formData);

    if (uploadResult.status === true) {
      checkBridgeMatrices(data.id).then((res) => {
        setCheckedBridgeMatrices(res.data);
      });
      handleModalStatusBar('success', <Trans code='A0654' />, true);
      if (type === 'capex') {
        setCapexFile(null);
      } else if (type === 'opex') {
        setOpexFile(null);
      }
      setTimeout(() => {
        setShowCapexModalUpload(false);
        setShowOpexModalUpload(false);
        setSbShowUpload(<></>);
      }, 2000);
    } else if (uploadResult.message || uploadResult.error) {
      handleModalStatusBar('error', uploadResult.message, false);
    }
  };

  const handleViewBridgeMatrix = (expenseItemListId, type, secDisagCode) => {
    showBridgeMatrices(expenseItemListId, type, secDisagCode).then((res) => setBmToShow(res.data));
  };

  const buildTableBM = () => {
    const legendStyles = {
      bullet: {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        borderRadius: '0.5em',
        top: '0.15em',
        position: 'relative',
      },
    };

    return (
      <table className='bm-table class'>
        <thead>
          <tr>
            <th>
              <Trans code='A0640' />
            </th>
            <th>
              <Trans code='A0633' />
            </th>
            <th>
              <Trans code='A0634' />
            </th>
          </tr>
        </thead>
        <tbody>
          {oeSecDisags?.map((disags) => {
            return (
              <tr key={disags.value}>
                <td>{disags.value}</td>
                {checkedBridgeMatrices
                  ?.filter((bmFirst) => bmFirst.secDisagCode === disags.value)
                  ?.map((bm) => {
                    console.log(disags.value, bm.opexStatus, bm.capexStatus, bm);
                    return bm.capexStatus === null && bm.opexStatus === null ? (
                      <Fragment key={bm.secDisagCode}>
                        <td colSpan='2' className='text-center'>
                          <Button
                            variant='primary'
                            onClick={() => createBridgeMatrices(bm.secDisagCode)}
                            className=''>
                            <Trans code='A0482' />
                          </Button>
                        </td>
                      </Fragment>
                    ) : (
                      <Fragment key={bm.secDisagCode}>
                        <td>
                          <Button
                            variant='link'
                            title='Download Excel file'
                            onClick={() =>
                              handleDownload(bm.expenseItemsListId, 'capex', bm.secDisagCode)
                            }>
                            <IcoDownloadFile className='second-color ico-small mr-4 download-upload' />
                          </Button>
                          <Button
                            variant='link'
                            title='Upload Excel file'
                            onClick={() => {
                              setSectorToUse(bm.secDisagCode);
                              handleShowModal(setShowCapexModalUpload);
                            }}>
                            <IcoUploadFile className='second-color ico-small mr-4 download-upload' />
                          </Button>
                          <Button
                            variant='link'
                            title={propTrans({ prop: 'A0700' })}
                            onClick={() => {
                              // handleShowModal(setShowModalBM)
                              setSectorToUse(bm.secDisagCode);
                              handleViewBridgeMatrix(
                                bm.expenseItemsListId,
                                'capex',
                                bm.secDisagCode
                              );

                              handleShowModal(setShowModalBM);
                            }}>
                            <Lens className='ico-small lens-bm green-lens' />
                          </Button>
                          <span className='ml-4 position-absolute'>
                            <i
                              style={{
                                ...legendStyles.bullet,
                                backgroundColor:
                                  bm.capexStatus === 1
                                    ? '#689689'
                                    : bm.capexStatus === 0
                                    ? '#C94723'
                                    : bm.capexStatus === 2
                                    ? '#C4C4C4'
                                    : '',
                              }}
                            />
                          </span>
                        </td>
                        <td>
                          <Button
                            variant='link'
                            title='Download Excel file'
                            onClick={() =>
                              handleDownload(bm.expenseItemsListId, 'opex', bm.secDisagCode)
                            }>
                            <IcoDownloadFile className='second-color ico-small mr-4 download-upload' />
                          </Button>
                          <Button
                            variant='link'
                            title='Upload Excel file'
                            onClick={() => {
                              setSectorToUse(bm.secDisagCode);
                              handleShowModal(setShowOpexModalUpload);
                            }}>
                            <IcoUploadFile className='second-color ico-small mr-4 download-upload' />
                          </Button>
                          <Button
                            title={propTrans({ prop: 'A0700' })}
                            variant='link'
                            onClick={() => {
                              setSectorToUse(bm.secDisagCode);
                              handleViewBridgeMatrix(
                                bm.expenseItemsListId,
                                'opex',
                                bm.secDisagCode
                              );
                              handleShowModal(setShowModalBM);
                            }}>
                            <Lens className='ico-small lens-bm green-lens' />
                          </Button>
                          <span className='ml-4 position-absolute'>
                            <i
                              style={{
                                ...legendStyles.bullet,
                                backgroundColor:
                                  bm.opexStatus === 1
                                    ? '#689689'
                                    : bm.opexStatus === 0
                                    ? '#C94723'
                                    : bm.opexStatus === 2
                                    ? '#C4C4C4'
                                    : '',
                              }}
                            />
                          </span>
                        </td>
                        {/* CAPEX */}
                        <UploadModal
                          title={'Capex Upload'}
                          handleCloseModal={handleCloseModal}
                          setShowModalUpload={setShowCapexModalUpload}
                          showModalUpload={showCapexModalUpload}
                          file={capexFile}
                          setFile={setCapexFile}
                          handleFileChange={handleCapexFileChange}
                          sbShowUpload={sbShowUpload}
                          type={'capex'}
                          expenseItemListId={bm.expenseItemsListId}
                          secdisagCode={sectorToUse}
                          handleUpload={handleUpload}
                        />
                        {/* OPEX */}
                        <UploadModal
                          title={'Opex Upload'}
                          handleCloseModal={handleCloseModal}
                          setShowModalUpload={setShowOpexModalUpload}
                          showModalUpload={showOpexModalUpload}
                          file={opexFile}
                          setFile={setOpexFile}
                          handleFileChange={handleOpexFileChange}
                          sbShowUpload={sbShowUpload}
                          type={'opex'}
                          expenseItemListId={bm.expenseItemsListId}
                          secdisagCode={sectorToUse}
                          handleUpload={handleUpload}
                        />
                        <ShowBridgeMatrix
                          showModalBM={showModalBM}
                          setShowModalBM={setShowModalBM}
                          sectors={sectors.filter((el) => el.secDisagCode === sectorToUse)}
                          bmToShow={bmToShow}
                        />
                      </Fragment>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // console.log('CAPTOOP:', sectors);

  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={cloneTooltip}>
        {/* <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalCopy)}>
          <IcoCopy className='second-color ico-small copy' />
        </Button> */}
        <Button variant='link' className='mr-3' onClick={() => handleCopy()}>
          <IcoCopy className='second-color ico-small copy' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button
          disabled={associatedScenarios}
          variant='link'
          className='mr-0'
          onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      <Modal
        size={activeKey === 'bridgeMatrix' || activeKey === 'scenariByClass' ? 'xl' : 'xl'}
        id='class-modal'
        show={showModalEdit}
        onHide={() => handleCloseModal(setShowModalEdit)}
        centered>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className='pt-0 px-2'>
            <Trans code='A0512' />
          </Modal.Title>
        </Modal.Header>
        <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} justify>
          {/* Generale */}
          <Tab eventKey='general' title={propTrans({ prop: 'A0041' })}>
            <Modal.Body className='px-5'>
              <Form onSubmit={handleEditStatus} id='form-admin-class'>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} className='pl-4 pr-2' controlId='sectorCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0079' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        isDisabled={associatedScenarios}
                        name='sectorCode'
                        value={selectedSector}
                        options={optionsNaceSectors}
                        onChange={(e) => onChange(e, 'sectorCode', setSelectedSector)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className='px-2' controlId='investmentType'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0500' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        isDisabled={associatedScenarios}
                        name='investmentType'
                        value={selectedInterventType}
                        options={optionsInterventTypes.filter(
                          (optIntType) => optIntType.oeSectorCode === selectedSector?.value
                        )}
                        onChange={(e) => onChange(e, 'investmentType', setSelectedInterventType)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className='pr-4 pl-2' controlId='companyId'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0058' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        isDisabled={associatedScenarios}
                        name='companyId'
                        value={selectedCompany}
                        options={optionsCompanies}
                        onChange={(e) => onChange(e, 'companyId', setSelectedCompany)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} className='px-4' controlId='description'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0138' />
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='description'
                        defaultValue={thisClass.description ?? ''}
                        onChange={(e) => onChange(e, 'description')}
                      />
                      <Link
                        to={`/administration/texts/${thisClass.langTag}`}
                        className='link-langtag'>
                        <Trans code='A0473' replacementText='Manage translation' />
                      </Link>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} className='px-4' controlId='tags'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0501' />
                      </Form.Label>
                      <CreatableSelect
                        className='multiselect-no-flex'
                        value={selectedTags}
                        closeMenuOnSelect={false}
                        options={optionsTagsEdit}
                        isMulti
                        isClearable
                        onChange={(e) => onChange(e, 'tags', setSelectedTags)}
                        onCreateOption={onCreateOption}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3 mt-4'>
                  <Col>
                    <Form.Group as={Row} className='px-4' controlId='localization'>
                      <Form.Label className='mb-0 pl-3'>
                        <Trans code='A0520' />
                      </Form.Label>
                      <Form.Check
                        inline
                        style={{ marginTop: '0.2rem' }}
                        className='ml-3'
                        type='checkbox'
                        name='localization'
                        defaultChecked={thisClass.localization}
                        onClick={(e) => onChange(e, 'localization')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group as={Row} className='my-4 px-4 align-content-center' controlId='name'>
                  <Col className='col-1'>
                    <Form.Label className='mb-0'>
                      <Trans code='A0502' />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={thisClass.version}
                      className='p-0'
                    />
                  </Col>
                </Form.Group>
              </Form>
              {sbShow}

              <Row>
                <Col className='px-4'>
                  <span className='mt-4 px-3'>
                    <span className='required-field'>*</span>&nbsp;
                    <Trans code='A0503' />
                  </span>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Col></Col>
              <Col className='px-3 text-right'>
                <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                  <Trans code='F0057' />
                </Button>
                <Button variant='primary' onClick={(e) => handleEditStatus(e)} className='ml-3'>
                  <Trans code='F00046' />
                </Button>
              </Col>
            </Modal.Footer>
          </Tab>

          {/* KPI */}
          <Tab eventKey='kpi' title={propTrans({ prop: 'A0381' })}>
            <Modal.Body className='px-5'>
              <Row className='mb-3 col-8'>
                <Form.Group as={Col} className='px-4' controlId='kpi'>
                  <Form.Label className='mb-3 mr-4'>
                    <Trans code='A0504' />
                    {/* &nbsp;<span className='required-field'>*</span> */}
                  </Form.Label>
                  <Select
                    isDisabled={associatedScenarios}
                    id='multiselect-kpi'
                    value={selectedKpi}
                    closeMenuOnSelect={false}
                    options={optionsKpi}
                    isMulti
                    isClearable
                    onChange={(e) => onChange(e, 'kpi', setSelectedKpi)}
                  />
                </Form.Group>
              </Row>
              {sbShow}
              <span className='mt-4 px-3'>
                <span className='required-field'>*</span>&nbsp;
                <Trans code='A0503' />
              </span>
            </Modal.Body>

            <Modal.Footer>
              <Col></Col>
              <Col className='px-3 text-right'>
                <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                  <Trans code='F0057' />
                </Button>
                <Button variant='primary' onClick={(e) => handleEditStatus(e)} className='ml-3'>
                  <Trans code='F00046' />
                </Button>
              </Col>
            </Modal.Footer>
          </Tab>

          {/* Voci di spesa/ Cashflow Items */}
          <Tab eventKey='expenseItems' title={propTrans({ prop: 'A0705' })}>
            <Modal.Body className='px-5'>
              <Row className='mb-3'>
                <Form.Group as={Col} className='px-4' controlId='expenseItems'>
                  <Form.Label className='mb-3 mr-4'>
                    <Trans code='A0505' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  {expenseItemsRows(expenseItemCapex, setExpenseItemCapex, 'capex')}
                  <Row>
                    <Col className='col-6'></Col>
                    <Col className='col-3 pl-5 pr-4'>
                      <div
                        className={`total-capex d-flex justify-content-between alert ${
                          sumOfCapex === 0
                            ? 'alert-warning'
                            : sumOfCapex === 100
                            ? 'alert-success'
                            : 'alert-danger'
                        }`}>
                        <span className='font-weight-bold'>
                          <Trans code='A0111' />
                        </span>
                        <span className='font-weight-bold text-right'>{sumOfCapex}/100</span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} className='px-4' controlId='expenseItems'>
                  <div style={{ marginBottom: '4rem' }}>
                    <Form.Label className='mb-0 mr-4'>
                      <Trans code='A0506' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Text
                      id='capexToOpexShare'
                      muted
                      className='mb-3 percTotParent position-absolute'>
                      <span className='mr-2'>
                        <Trans code='A0507C' />
                      </span>
                      <Form.Control
                        disabled={associatedScenarios}
                        className='capexToOpexShare custom-disabled'
                        type='number'
                        name='capexToOpexShare'
                        min={0}
                        max={100}
                        step={1}
                        value={thisClass.expenseitemslist?.[0]?.capexToOpexShare}
                        onChange={(e) => onChange(e, 'capexToOpexShare')}
                      />
                      <span>
                        <Trans code='A0507B' />
                      </span>
                    </Form.Text>
                  </div>
                  {expenseItemsRows(expenseItemOpex, setExpenseItemOpex, 'opex')}
                  <Row>
                    <Col className='col-6'></Col>
                    <Col className='col-3 pl-5 pr-4'>
                      <div
                        className={`total-capex d-flex justify-content-between alert ${
                          sumOfOpex === 0
                            ? 'alert-warning'
                            : sumOfOpex === 100
                            ? 'alert-success'
                            : 'alert-danger'
                        }`}>
                        <span className='font-weight-bold'>
                          <Trans code='A0111' />
                        </span>
                        <span className='font-weight-bold text-right'>{sumOfOpex}/100</span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row className='mb-3 mt-4'>
                <Form.Group as={Col} className='px-4' controlId='expenseItems'>
                  <Form.Label className='mb-4 mr-4'>
                    <Trans code='A0508' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Row>
                    <Col className='col-9 pr-4'>
                      <Select
                        //className="multiselect-no-flex"
                        isDisabled={associatedScenarios}
                        name='expenseItems'
                        value={selectedRevenues}
                        options={optionsExpenseItems.filter((opt) => opt.type === 'revenues')}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={(e) =>
                          onChange(
                            e,
                            'expenseItems',
                            setExpenseItemRevenues,
                            expenseItemRevenues,
                            undefined,
                            'revenues'
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              {sbShow}
            </Modal.Body>

            <Modal.Footer>
              <Col></Col>
              <Col className='px-3 text-right'>
                <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                  <Trans code='F0057' />
                </Button>
                <Button
                  variant='primary'
                  onClick={(e) => (isConfirmed ? handleEditStatus(e) : setShowConfirmModal(true))}
                  className='ml-3'>
                  <Trans code='F00046' />
                </Button>
              </Col>
            </Modal.Footer>
          </Tab>

          {/* Bridge Matrix */}
          <Tab eventKey='bridgeMatrix' title={propTrans({ prop: 'A0514' })}>
            <Modal.Body className='mb-4 px-5'>{buildTableBM()}</Modal.Body>
            {bmSbShow}
          </Tab>

          {/* Scenari associati a classe selezionata */}
          {/* {associatedScenarios && (
            <Tab eventKey='scenariByClass' title={propTrans({ prop: 'A0468' })}>
              <Modal.Body className='mb-4 px-5'>
                {<AssociatedScenarioTable associatedScenarios={data.associatedScenarios} />}
              </Modal.Body>
            </Tab>
          )} */}
          <Tab eventKey='documents' title={'Documenti'}>
            {activeKey === 'documents' && <DocumentsWrapper entityType={'OeClass'} entityId={id} />}
            {/* <DocumentsWrapper entityType={'class'} entityId={id} /> */}
          </Tab>
          <Tab eventKey='questions' title={'Questionario'}>
            {activeKey === 'questions' && <QuestionsWrapper classId={id} />}
          </Tab>
          <Tab
            disabled={!associatedScenarios}
            eventKey='associatedScenarios'
            title={propTrans({ prop: 'A0468' })}>
            <Modal.Body className='mb-4 px-5'>
              {<AssociatedScenarioTable associatedScenarios={data.associatedScenarios} />}
            </Modal.Body>
          </Tab>
        </Tabs>
      </Modal>

      {/* Modal Delete */}
      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0517' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>

      {/*MODAL WARNING*/}
      <Modal show={showConfirmModal} onHide={() => handleCloseModal(setShowConfirmModal)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <Trans code='A0701' />
            <span className='d-block'>
              <Trans code='A0572' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirmModal(false)}>
            <Trans code='F0057' />
          </Button>
          <Button
            variant='primary'
            onClick={(e) => {
              handleEditStatus(e);
              setShowConfirmModal(false);
            }}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRenderer;
