import React, { useEffect } from 'react';
import CorporateEsgKpiComponent from './CorporateEsgKpisComponents/CorporateEsgKpiComponent';
import StatusBar from 'components/wizard/StatusBar';
import { useParams } from 'react-router-dom';
import { Trans } from '@lib/i18n/index';
const CorporateEsgKpis = ({ setRefresh, kpis, error, isLoading }) => {
  const { companyId } = useParams();
  // const userCompanyId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const userRole = JSON.parse(localStorage.getItem('user')).role || null;
  useEffect(() => {
    setRefresh(true);
  }, []);

  // if (isLoading) {
  //   return (
  //     <div className='container-fluid'>
  //       <CustomSpinner style={{ minHeight: '100px' }} />
  //     </div>
  //   );
  // }

  return (
    <div>
      <h1 className='mb-0 pb-0 mt-5'>KPIs</h1>
      <div
        className='simple-box d-flex justify-content-center align-items-center'
        style={{ marginTop: '40px' }}>
        <p className='m-0 p-0 font-weight-bold'>
          <Trans code='A0874' />
        </p>
      </div>
      {kpis.map((el, i) => {
        return (
          <CorporateEsgKpiComponent
            key={`${el.kpiId}-${i}`}
            kpi={el}
            companyId={companyId}
            setRefresh={setRefresh}
            userRole={userRole}
          />
        );
      })}
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default CorporateEsgKpis;
