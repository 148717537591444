import React from 'react';
import { Card } from 'react-bootstrap';
import { Ico42 } from '@icons/new';
import BillboardChart from 'react-billboardjs';
import { bar } from 'billboard.js';
import { Trans, propTrans } from '@lib/i18n';
import ChartLegend from '@components/ChartLegend';
import { chartColors as colors } from '@lib/helpers';
import { rounder } from '@lib/helpers';

const InvestmentChart = ({ rows, year, scenario }) => {
  // const { year, row } = investment;

  const keys = ['OPEX', 'LOCALCAPEX'];
  const chartColors = keys.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {});
  const colorFunction = (c, d) => chartColors[d.id];

  const chartData = {
    columns: rows,
    type: bar(),
    groups: [keys],
    color: colorFunction,
  };
  console.log('CHDATA', rows);
  const axis = {
    x: {
      categories: year?.map((y) => `${y}`),
      type: 'category',
    },
  };

  // console.log('evector', scenario.vettorespesa);
  return (
    <div className='col-lg-12 col-md-12 col-sm-12 layout-charts d-flex container-card mb-5'>
      <Card style={{ width: '100%' }} className='cards-utente d-none d-md-block'>
        <Card.Body>
          <Ico42 className='logo-card ico-box-blue' />
          {/* {scenario.vettorespesa && scenario.vettorespesa == 1 ? (
          <Card.Title>
            <Trans code='A0458' />{' '}
          </Card.Title>
        ) : (
          <Card.Title>
            <Trans code='A0166' />{' '}
          </Card.Title>
        )} */}
          <Card.Title>
            <Trans code='A0458' />
          </Card.Title>
          <BillboardChart
            data={chartData}
            axis={axis}
            legend={{ show: false }}
            transition={{ duration: 800 }}
            /* bar={{
          width: 'auto' }} only numbers, no string  */
            grid={{ focus: { show: false } }}
            tooltip={{
              grouped: false,
              format: {
                title: () => '',
                value: (d) => rounder(d),
                name: (n) => propTrans({ prop: n, scope: 'investment' }),
              },
            }}
          />
          <ChartLegend data={keys} colors={colors} scope='investment' />
          <p className='va-desc'>
            <Trans code='A0117' />
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InvestmentChart;
