import React, { Component } from "react"

class Ico94 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-94" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
         <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
         <g transform="translate(15,30) scale(0.017)" fill="#000000" stroke="none">
            <path className="ico" d="M2441 4814 c-80 -21 -162 -69 -219 -127 -62 -64 -783 -1229 -820
            -1327 -34 -88 -35 -226 -3 -314 56 -156 180 -270 336 -311 87 -22 1561 -23
            1648 0 159 41 282 154 338 311 32 89 31 227 -3 314 -37 98 -758 1263 -820
            1327 -117 119 -299 170 -457 127z m185 -323 c23 -10 50 -32 61 -47 62 -89 719
            -1165 730 -1198 25 -69 -10 -152 -78 -186 -37 -19 -62 -20 -779 -20 -726 0
            -742 0 -781 20 -69 36 -104 124 -76 191 30 72 721 1185 754 1216 51 46 103 54
            169 24z"/>
            <path className="ico" d="M681 2384 c-169 -45 -301 -180 -346 -351 -22 -85 -22 -1261 0 -1346
            45 -173 178 -307 350 -352 88 -23 1262 -23 1350 0 172 45 305 179 350 352 22
            85 22 1261 0 1346 -45 173 -178 307 -350 352 -85 22 -1271 21 -1354 -1z m1325
            -330 c15 -11 37 -33 48 -48 21 -27 21 -37 21 -646 0 -609 0 -619 -21 -646 -11
            -15 -33 -37 -48 -48 -27 -21 -37 -21 -646 -21 -609 0 -619 0 -646 21 -15 11
            -37 33 -48 48 -21 27 -21 39 -24 619 -2 326 0 606 3 624 7 40 49 91 90 109 24
            11 148 13 637 11 595 -2 607 -2 634 -23z"/>
            <path className="ico" d="M3588 2385 c-370 -62 -688 -330 -809 -683 -75 -216 -76 -460 -4 -671
            118 -349 412 -610 770 -687 104 -22 248 -28 349 -15 461 62 826 424 895 888
            37 244 -29 524 -173 733 -46 67 -183 207 -251 255 -221 158 -511 225 -777 180z
            m357 -329 c33 -9 98 -35 145 -58 121 -60 249 -188 308 -308 62 -128 77 -193
            76 -340 -1 -107 -5 -137 -27 -205 -38 -115 -90 -198 -182 -290 -92 -92 -175
            -144 -290 -182 -68 -22 -98 -26 -205 -27 -147 -1 -213 14 -337 75 -145 72
            -287 228 -343 377 -77 204 -59 439 47 622 47 80 178 213 256 259 67 40 169 78
            241 91 70 12 238 5 311 -14z"/>
         </g>
      </svg>
    );
  }
}
export default Ico94