import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Ico107 } from '@icons/new';
import { IcoHandshake, IcoSocialNetworking, IcoSustainable } from '@icons';
import { Trans } from '@lib/i18n/index';

const tresholds = [
  { className: 'green-1', value: '60 e 100' },
  { className: 'green-2', value: '50 e 60' },
  { className: 'green-3', value: '30 e 50' },
  { className: 'grey', value: '0 e 30' },
];

const MaterialityDetails = ({ esgMaterialResults }) => {
  const [materiality, setMateriality] = useState([]);
  const [showCells, setShowCells] = useState({
    Environmental: false,
    Social: false,
    Governance: false,
  });

  useEffect(() => {
    if (esgMaterialResults) {
      setMateriality(esgMaterialResults);
    }
  }, [esgMaterialResults]);

  const handleToggleShowCells = (category) => {
    setShowCells((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const getStyle = (filteredMat, index, showCells) => {
    const colorMap = { green: 'white', yellow: 'white', red: 'black', default: 'inherit' };
    const backgroundColorMap = {
      green: '#689689',
      yellow: '#82B58F',
      red: '#9CD394',
      default: '#cdcdcd',
    };
    return {
      color: colorMap[filteredMat.outlook] || colorMap.default,
      backgroundColor: backgroundColorMap[filteredMat.outlook] || backgroundColorMap.default,
      display: index >= 8 && !showCells ? 'none' : 'block',
    };
  };

  const MaterialityCategory = ({ category, icon: Icon, label }) => (
    <Row className='px-4 align-items-center mb-5 gap-title'>
      <Col md={3} className='padding-media'>
        <Row className='align-items-center'>
          <Icon className='my-5 svg-materiality' />
          <span className='f-600 no-to-head'>{label}</span>
        </Row>
      </Col>
      <Col>
        <Row id='row-materiality-results'>
          {materiality
            .filter((mat) => mat.esgcategory === category)
            .sort((a, b) => b.miPerc - a.miPerc)
            .map((mat, index) => (
              <Col
                key={`${mat.materialIssueCode}_${mat.category}-${index}`}
                className='border border-2 m-2 p-3 fade-in-div materiality-block'
                style={getStyle(mat, index, showCells[label])}>
                <span style={{ fontSize: '1.1rem' }}>{mat.text}</span>
              </Col>
            ))}
        </Row>
        {materiality.filter((mat) => mat.esgcategory === category).length > 8 && (
          <Button
            id='show-more'
            variant='link'
            className='f-500 no-to-head'
            onClick={() => handleToggleShowCells(label)}>
            <Trans code={showCells[label] ? 'A0904' : 'A0905'} />
            ...
          </Button>
        )}
      </Col>
    </Row>
  );

  return (
    <div className='simple-box impact d-lg-block mb-5'>
      <Card.Body>
        <Ico107 className='logo-card ico-box-blue' />
        <Card.Title className='margin-title-box'>
          <Trans code='A0903' />
        </Card.Title>

        <Row className='mb-3'>
          <Col id='materiality-legend' className='align-self-start'>
            {tresholds.map((el) => (
              <Row key={el.className} className='min-label-width align-items-center mb-1 mx-0'>
                <div className={`box-materiality ${el.className}`}></div>
                <span>score {`tra ${el.value}`}</span>
              </Row>
            ))}
          </Col>
        </Row>

        <MaterialityCategory category='E' icon={IcoSustainable} label='Environmental' />
        <MaterialityCategory category='S' icon={IcoSocialNetworking} label='Social' />
        <MaterialityCategory category='G' icon={IcoHandshake} label='Governance' />
      </Card.Body>
    </div>
  );
};

export default MaterialityDetails;
