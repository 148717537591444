import React from 'react';
import { useQuery, gql } from '@apollo/client';

const QUERY = gql`
  {
    externalytics(where: { slug_it: "analisi-costi-benefici" }) {
      slug_it
      title_it
      subtitle_it
      text_it
    }
  }
`;

const AnalisiImpatto = (props) => {
  const { loading, error, data } = useQuery(QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const page = data.externalytics[0];
  return (
    <div className='analisi-impatto'>
      <h1 className='font-weight-light'>{page.title_it}</h1>
      <h3 className='font-weight-light'>{page.subtitle_it}</h3>
      <div dangerouslySetInnerHTML={{ __html: page.text_it }} />
    </div>
  );
};

export default AnalisiImpatto;
