import {
  getOERegions,
  getOrganization,
  deleteCompaniesLocations,
  getCompaniesLocations,
} from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import { Trans } from '@lib/i18n/index';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import CorporateEsgGeneralForm from './CorporateEsgGeneralComponents/CorporateEsgGeneralForm';
import { useParams } from 'react-router-dom';
import StatusBar from 'components/wizard/StatusBar';
import CustomSpinner from 'components/CustomSpinner';

const CorporateEsgGeneral = () => {
  const { companyId } = useParams();
  // const userOrgId = JSON.parse(localStorage.getItem('user')).idOrganization || null;
  const userRole = JSON.parse(localStorage.getItem('user')).role || null;
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [corporationData, setCorporationData] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [refreshState, setRefreshState] = useState([]);
  const [originalState, setOriginalState] = useState([]);
  const initialState = useMemo(() => {
    return {
      companyId: companyId,
      corporationName: organizationName,
      region: '',
      province: '',
      city: '',
      address: '',
      postalCode: '',
      surface: '',
      headquarter: false,
    };
  }, [organizationName, companyId]);

  const handleAddCorporation = (e) => {
    e.preventDefault();
    setCorporationData((prevData) => [...prevData, initialState]);
  };

  const handleRemoveCorporation = async (e, data, index) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    if (data?.id) {
      const response = await deleteCompaniesLocations(companyId, data.id);

      if (response.status) {
        // setCorporationData((prevData) => prevData.filter((_, i) => i !== index));
        setRefresh(true);
      } else {
        response?.message ? setError(response.message) : setError('Errore eliminazione indirizzo');
      }
    } else {
      setCorporationData((prevData) => prevData.filter((_, i) => i !== index));
    }
    setIsLoading(false);
  };

  const setBothStates = (data, setState) => {
    setState(data);
    setOriginalState(data);
    setCorporationData(data);
  };

  useEffect(() => {
    fetchData(
      getCompaniesLocations,
      setCorporationData,
      setBothStates,
      [companyId],
      null,
      setError,
      setIsLoading
    );
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData(
        getCompaniesLocations,
        setRefreshState,
        setBothStates,
        [companyId],
        null,
        setError,
        setIsLoading
      );
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (refreshState.length) {
      setCorporationData([...refreshState, ...corporationData.filter((el) => !el.id)]);
    }
  }, [refreshState]);

  const transformRegionsData = (data) => {
    const italianRegions = data.filter(
      (region) => region.country === 'ITA' && !region.province && !!region.region
    );
    const italianProvinces = data.filter((region) => region.country === 'ITA' && !!region.province);
    setRegions(italianRegions.map((region) => ({ value: region.code, label: region.name })));
    setProvinces(italianProvinces.map((region) => ({ value: region.code, label: region.name })));
  };

  const transformOrganizationsData = (data, setState) => {
    setState(data?.name || '');
  };

  useEffect(() => {
    fetchData(getOERegions, null, transformRegionsData, [], null, setError, setIsLoading);
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchData(
        getOrganization,
        setOrganizationName,
        transformOrganizationsData,
        [companyId],
        null,
        setError,
        setIsLoading
      );
    }
  }, [companyId]);

  const onChange = (e, index, fieldname, setSelect, setDependingState = false) => {
    const updateCorporationData = (prevData) => {
      return prevData.map((item, i) => {
        if (index !== i) return item;

        const updatedItem = {
          ...item,
          [fieldname]:
            // fieldname === 'postalCode' ||
            fieldname === 'surface'
              ? +e.target.value
              : e?.target?.type === 'checkbox'
              ? e.target.checked
              : setSelect
              ? e.value
              : e.target.value,
        };

        if (fieldname === 'region') {
          updatedItem.province = '';
          setDependingState(null);
        }

        return updatedItem;
      });
    };

    setCorporationData(updateCorporationData);

    if (setSelect) {
      setSelect(e ?? []);
    }
  };

  if (isLoading) {
    return (
      <div className='container-fluid'>
        <CustomSpinner style={{ minHeight: '100px' }} />
      </div>
    );
  }
  return (
    <div>
      <h1 className='mb-0 pb-0 mt-5'>{organizationName}</h1>
      {!corporationData.length && (
        <div className='d-flex justify-content-center align-items-center mt-3'>
          <Button onClick={handleAddCorporation}>
            <Trans code='A0858' />
          </Button>
        </div>
      )}

      {corporationData.map((corporation, index) => (
        <CorporateEsgGeneralForm
          key={index}
          onChange={onChange}
          index={index}
          regionOptions={regions}
          provinceOptions={provinces}
          corporation={corporation}
          handleAddCorporation={handleAddCorporation}
          handleRemoveCorporation={handleRemoveCorporation}
          isLastItem={index === corporationData.length - 1}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          companyId={companyId}
          hasHeadquarter={corporationData.some((corporation) => corporation.headquarter)}
          setCorporationData={setCorporationData}
          originalState={originalState}
          setOriginalState={setOriginalState}
          userRole={userRole}
        />
      ))}
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </div>
  );
};

export default CorporateEsgGeneral;
