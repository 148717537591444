import { ArrowDownUp, IcoEdit, IcoCanc } from 'icone/index';
import { useEffect, useState } from 'react';
import { Accordion, Card, Row, Col, Button } from 'react-bootstrap';
import { Trans } from '@lib/i18n/index';
import { date } from '@lib/helpers/index';
import { getScenarioVariants } from '@lib/api/index';
import VariantItemsWrapper from './variantItemsWrapper';
import { useParams } from 'react-router-dom';
import StatusBar from '../StatusBar';
import { fetchData } from '@lib/helpers/index';
import TruncatedValue from 'components/RenderTruncatedValue';

const VariantSelector = ({ isLoading, setIsLoading, variantsRefresh, setVariantsRefresh }) => {
  const { idScenario = null, idVariante = null } = useParams();
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [error, setError] = useState(null);
  // const [variantRefresh, setVariantRefresh] = useState(false);
  // const handleStatusBar = (status, message, timeout) => {
  //   setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  // };

  useEffect(() => {
    setError(null);
    if (idScenario) {
      fetchData(getScenarioVariants, setItems, null, [idScenario], null, setError, setIsLoading);
    }
  }, [idScenario]);

  useEffect(() => {
    setError(null);
    if (variantsRefresh) {
      fetchData(getScenarioVariants, setItems, null, [idScenario], null, setError, setIsLoading);
    }
    setVariantsRefresh(false);
  }, [variantsRefresh]);
  console.log('idVariante', isOpen);
  useEffect(() => {
    if (idVariante !== null && items.length > 0) {
      const selected = items.find((item) => item.id === +idVariante);
      setSelectedVariant(selected);
    }
  }, [idVariante, items]);
  return (
    <>
      <Accordion activeKey={isOpen ? '0' : ''}>
        <Card
          className='p-0'
          // eventKey='0'
        >
          <Accordion.Toggle
            as={Card.Header}
            className='accordion-title-variant-selector'
            variant='link'
            // eventKey={isOpen ? '0' : ''}
            onClick={() => setIsOpen((prevState) => !prevState)}>
            <Row className=''>
              <Col>
                {selectedVariant ? (
                  <div className='d-flex align-items-end'>
                    <TruncatedValue
                      value={selectedVariant?.name}
                      checkingValue={45}
                      substringValues={[0, 42]}
                      htmlTag='h2'
                      classNames='p-0 m-0 mr-1'
                    />
                    <h2 className='p-0 m-0'>{` - ${date(selectedVariant?.createdAt)}`}</h2>
                    <span className='ml-2' style={{ fontSize: '.9rem' }}>
                      <Trans code='A0687' />: {selectedVariant?.status}
                    </span>
                  </div>
                ) : (
                  <h2 className='p-0 m-0'>
                    <Trans code='A0804' />
                  </h2>
                )}
              </Col>
              <Col className='text-right pl-0 col-auto'>
                <ArrowDownUp
                  style={{
                    width: '14px',
                    heigth: '14px',
                    transition: 'transform .1s',
                    transform: `rotate(${isOpen ? -180 : 0}deg)`,
                  }}
                />
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <VariantItemsWrapper
                variants={items}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                setItems={setItems}
                setError={setError}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setVariantRefresh={setVariantsRefresh}
                setIsOpen={setIsOpen}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {error && <StatusBar status={'error'} message={error} timeout={false} />}
    </>
  );
};

export default VariantSelector;
