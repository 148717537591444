import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TooltipInfoIcon } from 'icone/index';
const TooltipComponent = ({ data }) => {
  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {data}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement='top' delay={{ show: 200, hide: 400 }} overlay={renderTooltip}>
      <TooltipInfoIcon className='tooltip-ico m-0 ml-2' style={{ width: '1rem' }} />
    </OverlayTrigger>
  );
};

export default TooltipComponent;
