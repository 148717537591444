import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n'
import { rounder } from '@lib/helpers/index'
import BillboardChart from 'react-billboardjs'
import { donut } from 'billboard.js'
import ChartLegend from '@components/ChartLegend'
import { chartColors as colors } from '@lib/helpers'
import {Ico99} from "@icons/new";

const GdpTypesChart = ({ gdp }) => {
  const keys = Object.keys(gdp.type).filter(k => k !== 'total')  
  const chartColors = keys.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {})
  const colorFunction = (c, d) => chartColors[d.id]
  const [chartData, setChartData] = useState({
    columns: keys.map(k => [k, 0]), 
    type: donut(), 
    color: colorFunction
  })

  useEffect(() => {
    setChartData({
      columns: keys.map(k => [k, parseFloat(gdp.type[k])/gdp.type['total']*100])
    })    
    // eslint-disable-next-line
  }, [])

  const values = Object.keys(gdp.type).filter(prop => prop !== 'total').map(prop => gdp.type[prop]);

  return <>
   <Card className="cards-utente d-md-block" style={{paddingBottom: '6rem'}}>
      <Card.Body>
         <Ico99 className="logo-card ico-box-blue" />
         <Card.Title><Trans code="A0478" /></Card.Title>
         <BillboardChart
            className="centered-donut"
            data={chartData}
            legend={{ show: false }}
            donut={{ padAngle: 0.015, width: 35, label: { show: false } }}
            tooltip={{ format: { value: d => rounder(d)+' %', name: n => propTrans({ prop: n, scope: 'gdp' }) } }}
            transition={{ duration: 800 }}
         />
         <div className="my-3"><ChartLegend data={keys} colors={colors} scope="gdp" values={values} allData={gdp.type} /></div>
      </Card.Body>
   </Card>
  </>
}

export default GdpTypesChart