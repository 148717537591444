import React from 'react';
import { Radar } from 'react-chartjs-2';

const data = {
  labels: [["GDP"],["DBI"],["HDI"],["HCI"],["EPI"],["CCPI"]],
  datasets: [
    {
        label: "area",
        borderColor: "rgba(220, 0, 0, 1)",
        pointBorderColor: "rgba(220, 0, 0, 1)",
        pointBackgroundColor: "rgba(220, 0, 0, 1)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        data: [96,69,85,88,89,54]
    },
  ],
};

const options = {
    scale: {
      pointLabels: {
        fontSize: 12,
        fontFamily: 'Barlow Medium'
      },
      ticks: {
        beginAtZero: true,
        max: 100
       }
    },
    plugins: {
      legend: {
          display: false
      }
     },
    animation: {
      duration: 1000,
      onComplete: function(animation) {

      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 30
      }
    },
    responsive: true,
    maintainAspectRatio: true
};

const RadarChartKey = () => (
  <>
    <Radar data={data} options={options} />
  </>
);

export default RadarChartKey;