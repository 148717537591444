import { scaleThreshold, geoPath, select, geoIdentity, geoMercator } from 'd3';
import { useEffect, useRef, useState } from 'react';
import style from './ChoroplethMap.module.css';
import chroma from 'chroma-js';
import italy from './IT_regions.json';
import italyHex from './hexagonal_regions.json';

const ChoropletMap = (props) => {
  const { metrics, dataSource, isHexGrid, colors, id } = props;
  const svgRef = useRef(null);
  const [data, min, max] = metrics[dataSource];
  const [isMounted, setIsMounted] = useState(false);

  const mybins = [0, 0.05, 0.15, 0.25, 0.4, 1];

  const color = scaleThreshold()
    .domain(mybins.map((value) => value * max))
    .range(chroma.scale(colors[dataSource]).colors(6));

  const convertToMillion = (value, type = 'Investimento') => {
    const sanitized = value.replace('€', '').replaceAll('.', '').replace(',', '.');
    const number = parseFloat(sanitized);
    const million = number / (type === 'Investimento' ? 1000000 : 1);
    // currency formatting with 2 decimal places, comma separator decimal
    const rounded = million.toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return type === 'Occupazione' ? `${rounded} ETP` : `${rounded} M€`;
  };

  const svg = select(svgRef.current);

  useEffect(() => {
    setIsMounted(true);

    const handleMouseover = (_e, d) => {
      let centroid = pathGenerator.centroid(d);

      // Rimuovi il tooltip esistente
      svg.selectAll(`.tooltipWrapper_${id} foreignObject`).remove();

      const fo = select(`.tooltipWrapper_${id}`)
        .append('foreignObject')
        // Usa le coordinate del centroid come posizione iniziale
        .attr('x', centroid[0])
        .attr('y', centroid[1])
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('class', style.svgTootip);

      const tooltipContent = fo.append('xhtml:div');

      tooltipContent.attr('class', style.tooltip).html(getTooltipHtml(d));

      const svgHeight = svgRef.current.getBoundingClientRect().height;
      const svgWidth = svgRef.current.getBoundingClientRect().width;

      if (centroid[1] + 100 > svgHeight) {
        const newY = svgHeight - 100 - 10;
        fo.attr('y', newY);
      }

      if (centroid[0] + 100 > svgWidth) {
        const newX = svgWidth - 100 - 10;
        fo.attr('x', newX);
      }
    };

    const getTooltipHtml = (d) => {
      var tooltip = `<div class="${style.row}"><div class="${style.label}">REGION:</div><div class="${style.value}"><span>${d?.properties?.reg_name}</span></div></div>`;
      if (data[d.properties.reg_istat_code]) {
        data[d.properties.reg_istat_code]?.textValues.forEach((value) => {
          if (
            value[0] === 'TOTAL' ||
            value[0] === 'REGION TOTAL' ||
            value[0] === 'CAPEX' ||
            value[0] === 'OPEX' ||
            value[0] === 'REGIONAL VALUE' ||
            value[0] === 'TOTAL VALUE'
          ) {
            tooltip = tooltip.concat(
              `<hr/><div class="${style.row}"><div class="${style.label}">${
                value[0]
              }</div><div class="${style.value}"><span>${convertToMillion(
                value[1],
                dataSource
              )}</span></div></div>`
            );
          } else {
            tooltip = tooltip.concat(
              `<hr/><div class="${style.row}"><div class="${style.label}">${value[0]}</div><div class="${style.value}"><span>${value[1]}</span></div></div>`
            );
          }
        });
      } else {
        data['none']?.textValues.forEach((value) => {
          if (
            value[0] === 'TOTAL' ||
            value[0] === 'REGION TOTAL' ||
            value[0] === 'CAPEX' ||
            value[0] === 'OPEX' ||
            value[0] === 'REGIONAL VALUE' ||
            value[0] === 'TOTAL VALUE'
          ) {
            tooltip = tooltip.concat(
              `<hr/><div class="${style.row}"><div class="${style.label}">${
                value[0]
              }</div><div class="${style.value}"><span>${convertToMillion(
                value[1],
                dataSource
              )}</span></div></div>`
            );
          } else {
            tooltip = tooltip.concat(
              `<hr/><div class="${style.row}"><div class="${style.label}">${value[0]}</div><div class="${style.value}"><span>${value[1]}</span></div></div>`
            );
          }
        });
      }
      return tooltip;
    };

    const projection = isHexGrid ? geoIdentity().reflectY(true) : geoMercator();
    const map = isHexGrid ? italyHex : italy;
    const pathGenerator = geoPath().projection(projection.fitSize([800, 800], map));

    svg
      .select('.map')
      .selectAll('path')
      .data(/* regions.features/*  */ map.features)
      .join('path')
      .attr('fill', (d) =>
        data[d.properties.reg_istat_code]
          ? color(data[d.properties.reg_istat_code].numValue)
          : 'lightgrey'
      )
      .attr('stroke', 'white')
      .attr('d', pathGenerator)
      .append('foreignObject')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', style.tooltip)
      .html((d) => getTooltipHtml(d));

    svg
      .selectAll('path')
      .on('mouseover', handleMouseover)
      .on('mouseout', function () {
        svg.selectAll(`.tooltipWrapper_${id} foreignObject`).remove();
      });

    return () => {
      setIsMounted(false);
      svg.select('.map').selectAll('path').remove();
      svg.selectAll(`.tooltipWrapper_${id} foreignObject`).remove();
    };
  }, [data, svg, isMounted]);

  return (
    <>
      <svg
        style={{
          height: '100%', // Set height to 100% of its container
          width: '100%',
          maxHeight: '600px',
          maxWidth: '600px', // Set width to 100% for responsiveness
        }}
        className='d-flex justify-content-around flex-wrap align-items-center'
        preserveAspectRatio='xMinYMin meet'
        ref={svgRef}
        viewBox={[0, 0, 905, 910]}>
        <g className='map'></g>
        <g className={`tooltipWrapper_${id}`} style={{ pointerEvents: 'none' }}></g>
      </svg>
    </>
  );
};

export default ChoropletMap;
