import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { InputGroup, FormControl, ButtonToolbar, Spinner, Col } from 'react-bootstrap';
import { Lens /* IcoPlus */ } from '@icons';
import { Trans, propTrans } from '@lib/i18n/index';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';

const OESimpleTable = ({
  columnDefs,
  rowData,
  buttonToolbarVisible = false,
  pageSizeId,
  buttonTitle,
  showModal,
  setShowModal,
  handleDelete,
  reloadData,
  //onCellClicked,
  buttonChildren,
  sortable = false,
  pinnedBottomRow = false,
  bottomColumns = [],
}) => {
  const [optionsPagination, setOptionsPagination] = useState([]);
  const gridRef = useRef();
  const localeText = AG_GRID_LOCALE_IT;

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
    sortable: sortable,
  };

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('custom-table-pagination').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onGridReady = useCallback((params, gridRef) => {
    if (reloadData) reloadData();

    console.log('onGridReady', params, gridRef);

    // params.api.sizeColumnsToFit();

    // window.addEventListener('resize', function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });

    //gridRef.current.api.sizeColumnsToFit();

    // if (window.screen.width >= 1600) {
    //   //&& window.screen.height >= 768
    //   // Resolution is 1600 //x768 or above
    //   gridRef.current.api.sizeColumnsToFit();
    // } else {
    //   const allColumnIds = [];
    //   gridRef.current.columnApi.getAllColumns().forEach((column) => {
    //     allColumnIds.push(column.getId());
    //   });
    //   gridRef.current.columnApi.autoSizeColumns(allColumnIds, true);
    // }
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const onFirstDataRendered = (params) => {
    autoSizeAll(false);
  };

  // const createData = useCallback((columns) => {
  //  let result = {};

  //  columns.forEach(col => {
  //     result[col] = rowData.reduce((prev, curr) => prev + curr[col], 0);;
  //  });

  //  return [result];
  // }, [rowData]);

  // const pinnedBottomRowDataFunction = useMemo(() => {
  //  return createData(bottomColumns);
  // }, [bottomColumns, createData]);

  // const getRowStyle = useCallback((params) => {
  //  if (params.node.rowPinned === 'bottom') {
  //    return { fontWeight: 'bold' };
  //  }
  // }, []);

  return (
    <>
      {buttonToolbarVisible && (
        <Col className='d-flex justify-content-end text-right px-0 py-1 mb-3'>
          <ButtonToolbar className='justify-content-end'>
            {buttonChildren}
            {/* <Button variant='success' onClick={() => showModal(setShowModal)}>
              <IcoPlus className='light-color ico-small plus new-button' />
              <span className='d-md-inline'>{buttonTitle}</span>
            </Button> */}
            <InputGroup className='search-table align-items-center mt-3 mt-md-0'>
              <FormControl
                type='text'
                className='filter-text-box'
                id='filter-text-box-tenders'
                placeholder='Cerca...'
                onInput={(e) => onFilterTextBoxChanged(e)}
              />
              <Lens />
            </InputGroup>
          </ButtonToolbar>
        </Col>
      )}
      <div className='col-12' id='grid-wrapper'>
        {Array.isArray(rowData) ? (
          <div className='ag-theme-alpine list-table'>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              domLayout='autoHeight'
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              tooltipMouseTrack={true}
              //onCellClicked={onCellClicked}
              onGridReady={(e) => onGridReady(e, gridRef)}
              onFirstDataRendered={onFirstDataRendered}
              onModelUpdated={onFirstDataRendered}
              pagination={true}
              paginationPageSize={20}
              headerHeight={50}
              rowHeight={50}
              localeText={localeText}
              ariaDescription='ag-grid-table'
              suppressCellFocus={true}
              animateRows={true}
            />
            <div className='example-header'>
              <Trans code='A0776' />
              :&nbsp;
              <select onChange={onPageSizeChanged} id='custom-table-pagination'>
                {optionsPagination}
              </select>
            </div>
          </div>
        ) : (
          <div className='text-center my-4'>
            <Spinner animation='border' role='status'>
              <span style={{ visibility: 'hidden' }}>Caricamento...</span>
            </Spinner>
          </div>
        )}
      </div>
    </>
  );
};

export default OESimpleTable;
