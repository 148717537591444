import React from 'react'

const ShareBar = ({ max, width = 100, value }) => {
  const renderWidth = Math.round((parseFloat(value) * width) / max)

  const styles = {
    outer: {
      display: 'block',
      width: `${width}px`
    },
    inner: {
      display: 'inline-block',
      width: `${renderWidth}px`,
      backgroundColor: '#08A2AA',
      height: '1em'
    }
  }

  return <span style={styles.outer}>
    <span style={styles.inner}></span>
  </span>
}

export default ShareBar