import React, { Component } from "react"

class LogoDol extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }
    return (
      <svg viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-5px' }} id="logo-dol" className={className} height={this.props.height} width={this.props.width}>
        <path d="M7.70703 0.628906H9.85156V17H5.29297C3.69922 17 2.42578 16.4961 1.47266 15.4883C0.527344 14.4805 0.0546875 13.1289 0.0546875 11.4336C0.0546875 9.84766 0.550781 8.54297 1.54297 7.51953C2.54297 6.49609 3.81641 5.98438 5.36328 5.98438C6.08203 5.98438 6.86328 6.13672 7.70703 6.44141V0.628906ZM7.70703 15.1719V8.33984C7.04297 8.00391 6.37109 7.83594 5.69141 7.83594C4.62891 7.83594 3.78125 8.18359 3.14844 8.87891C2.52344 9.57422 2.21094 10.5117 2.21094 11.6914C2.21094 12.8008 2.48438 13.6562 3.03125 14.2578C3.35938 14.6172 3.70703 14.8594 4.07422 14.9844C4.44141 15.1094 5.10156 15.1719 6.05469 15.1719H7.70703ZM17.9023 6.19531C19.543 6.19531 20.9062 6.72656 21.9922 7.78906C23.0781 8.84375 23.6211 10.1719 23.6211 11.7734C23.6211 13.3281 23.0703 14.6211 21.9688 15.6523C20.8672 16.6758 19.4805 17.1875 17.8086 17.1875C16.1914 17.1875 14.8398 16.668 13.7539 15.6289C12.668 14.582 12.125 13.2773 12.125 11.7148C12.125 10.1367 12.6719 8.82422 13.7656 7.77734C14.8672 6.72266 16.2461 6.19531 17.9023 6.19531ZM17.7852 8.11719C16.7617 8.11719 15.9219 8.45312 15.2656 9.125C14.6094 9.79688 14.2812 10.6523 14.2812 11.6914C14.2812 12.7227 14.6172 13.5664 15.2891 14.2227C15.9609 14.8711 16.8242 15.1953 17.8789 15.1953C18.9258 15.1953 19.7812 14.8672 20.4453 14.2109C21.1172 13.5469 21.4531 12.6992 21.4531 11.668C21.4531 10.6445 21.1055 9.79688 20.4102 9.125C19.7148 8.45312 18.8398 8.11719 17.7852 8.11719ZM26.0352 0.628906H28.168V17H26.0352V0.628906Z" className="symbol" />
      </svg>
    );
  }
}

export default LogoDol