import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Select from 'react-select'
import { Trans } from '@lib/i18n'
import { 
   getAdminOrganization, 
   getAdminRegion, 
   getAdminSam, 
   getAdminClasses, 
   getBaseUrl, 
   getDefaultHeaders, 
   getAuthUser, 
   getUsers, 
   getCurrencies,
} from '@lib/api';
import StatusBar from "components/wizard/StatusBar";
import { propTrans } from "@lib/i18n/index";

let currentYear = new Date().getFullYear();

const initialState = {
   scenario_name: '',
   owner: '', //
   organization: '', //
   access: '', //
   scenario_type: '', //tipologia //
   currency: '', //
   classe: '',
   country: '', //paese
   region: '',
   sam: '', //dataset
   constructionStart: currentYear, //Investimento - Da anno //
   constructionEnd: currentYear, //ad anno //
   operationStart: currentYear, //Esercizio - Da anno //
   operationEnd: currentYear, //ad anno //
   dataValutazione: currentYear, //
   tassoSconto: 3, //
   verificato: false, //
};

const optionsAccess = [
   {
      value: '3', 
      label: 'Organizzazione'
   },
   {
      value: '2', 
      label: 'Private'
   },
   {
      value: '1', 
      label: 'Open'
   }
];

const optionsTypology = [
   {
      value: '1', 
      label: 'Investimento'
   },
   {
      value: '2', 
      label: 'Politica'
   },
];

const StepGeneral = ({ props, scen }) => {
   //console.log('scen:', scen);
   const scenarioData = scen;

   const [scenario, setScenario] = useState({});
   // const ownerList = (props.user)
   // const classesList = (props.classes)

   const [scenario_name, setScenario_name] = useState('')
   const [salario, setSalario] = useState('')
   const [tassoSconto, setTassoSconto] = useState(3)
   const [dataValutazione, setDataValutazione] = useState(currentYear)

   const [originalTassoSconto, setOriginalTassoSconto] = useState(3)
   const [originalDataValutazione, setOriginalDataValutazione] = useState(currentYear)

   const [constructionStart, setConstructionStart] = useState(currentYear)
   const [constructionEnd, setConstructionEnd] = useState(currentYear)
   const [operationStart, setOperationStart] = useState(currentYear)
   const [operationEnd, setOperationEnd] = useState(currentYear)

   const [originalConstructionStart, setOriginalConstructionStart] = useState(currentYear)
   const [originalConstructionEnd, setOriginalConstructionEnd] = useState(currentYear)
   const [originalOperationStart, setOriginalOperationStart] = useState(currentYear)
   const [originalOperationEnd, setOriginalOperationEnd] = useState(currentYear)

   const [owner, setOwner] = useState([])
   const [currency, setCurrency] = useState('2')
   const [optionsCurrency, setOptionsCurrency] = useState([]);
   const [selectedCurrency, setSelectedCurrency] = useState([]);

  const [organization, setOrganization] = useState([])
  //const [ownerList, setOwnerList] = useState(props.user)
  const [optionsOwners, setOptionsOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [scenario_type, setScenario_type] = useState(1)
  const [selectedScenarioType, setSelectedScenarioType] = useState([]);
  const [verificato, setVerificato] = useState(false)
  const [access, setAccess] = useState(3)
  const [selectedAccess, setSelectedAccess] = useState([])
 // const [sector, setSector] = useState([])
  const [country, setCountry] = useState([])
  const [region, setRegion] = useState([])
  const [regionList, setRegionList] = useState([])
  const [regionDisabled, setRegionDisabled] = useState([])
  const [classe, setClasse] = useState([])
  // const [classesList, setClassesList] = useState([])
  const [sam, setSam] = useState([])
  const [originalSam, setOriginalSam] = useState([])

  const [samList, setSamList] = useState([])
  const form = useRef(null)

  const history = useHistory()
  const [idScenario, setIdScenario] = useState(null)

  const [isSaved, setIsSaved] = useState(false)
  const [error, setError] = useState(null)

  const[status,setStatus]=useState('')

  const[user, setUser] = useState(null)

  const [show, setShow] = useState(false);
  const [showResetVSModal, setShowResetVSModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleResetVSModalClose = () => setShowResetVSModal(false);

  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  const[modalResetVSTitle, setModalResetVSTitle] = useState('')
  const[modalResetVSMessage, setModalResetVSMessage] = useState('')

   
  const[sbShowSuccess, setSbShowSuccess] = useState(false);
  const[sbShowError, setSbShowError] = useState(false);
  //console.log('owner:', owner);

   useEffect(() => {
      getUsers().then(retrievedUsers => {
         setOptionsOwners(retrievedUsers.map(user => ({
            value: user.idUser,
            label: `${user.username} - ${user.organization}`,
            username: user.username,
         })));
      });

      if (Object.keys(scen).length > 0) {
         //edit

      } else {
         setScenario(initialState);

         //const authUser = getAuthUser().then(({user}) => user);

         /* getAuthUser()
         .then(function ({user}) {            
           //console.log('user:', user)
           setOwner(getValueMeta(user.idUser,props.user ))
           setSelectedOwner(
              tempOptionsUsers.filter(optionUser => optionUser.value === user.idUser)
              .map((filteredUser) => ({
                 value: filteredUser.value,
                 label: filteredUser.label,
                 username: filteredUser.username,
              }))
          );
           setOrganization(getValueMeta(user.idOrganization,props.organization ))
           // refreshOwner([{value: user.idUSer, label: props.user}])
         }); */

      }
   }, [scen]);

  //Funzione di Submit del form : raccoglie l'evento e valida l'input
  const submit = e => {
    e.preventDefault()
    let letSave = true
    let changeStatus = false;   
    setError(false)
    setIsSaved(false)

    if(!sam || sam.value === undefined){
      setModalTitle("Campo obbligatorio mancante");
      setModalMessage("Il campo SAM è un campo obbligatorio");
      setShow(true);
      letSave = false;
     
    }
    if(parseInt(constructionEnd) < parseInt(constructionStart)){ //parseInt necessari perchè il valore entra a string
      setModalTitle("Date non valide");
      setModalMessage("Non è possibile inserire un anno di investimento finale antecedente a quello iniziale");
      setShow(true);
      letSave = false;
    }
    if(parseInt(operationEnd) < parseInt(operationStart)){
      setModalTitle("Date non valide");
      setModalMessage("Non è possibile inserire un anno di esercizio finale antecedente a quello iniziale");
      setShow(true);
      letSave = false;
    }
    if(parseInt(operationEnd) < parseInt(constructionStart)){
      setModalTitle("Date non valide");
      setModalMessage("Non è possibile inserire un anno di esercizio finale antecedente a quello di inizio di investimento");
      setShow(true);
      letSave = false;
    }


    if (originalTassoSconto && ( tassoSconto !== originalTassoSconto) )  changeStatus = true;
    if (originalDataValutazione && ( dataValutazione !== originalDataValutazione) )  changeStatus = true;

    if(idScenario) {
      if(letSave) { //Non mostriamo l'alert se tanto non possiamo salvare per via dei controlli presenti
        if( parseInt(originalConstructionStart) !== parseInt(constructionStart) || 
            parseInt(originalConstructionEnd) !== parseInt(constructionEnd) || 
            parseInt(originalOperationStart) !== parseInt(operationStart) || 
            parseInt(originalOperationEnd) !== parseInt(operationEnd) || 
            ( originalSam && ( sam.value !== originalSam) ) 
            ){
              letSave = false
              setModalResetVSTitle("Attenzione");
              setModalResetVSMessage("Se salvi i vettori spesa correnti verranno rimossi, vuoi continuare?");
              setShowResetVSModal(true);
              //console.log(originalConstructionStart, constructionStart, originalConstructionEnd, constructionEnd, originalOperationStart, operationStart, originalOperationEnd, operationEnd, originalSam,sam.value)
        }
      }
      
    }

    if(letSave){
    
      if (changeStatus) setStatus("Draft");
      save()
    }

  }


  const handleResetVSModalSave = () => {
    setShowResetVSModal(false);
    setStatus("Draft") // il cambio di una delle date deve riportare lo status in DRAFT
    save();
  }

  const save = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        ...getDefaultHeaders()
      },
      body: JSON.stringify({ scenario_name, owner, currency, organization, scenario_type, country, region, sam, access, classe, salario, tassoSconto, dataValutazione, constructionStart, constructionEnd, operationStart, operationEnd, verificato, idScenario,status }),
    };

    fetch(`${getBaseUrl()}/setupscenario_step1`, requestOptions)
      .then(response => response.text())
      .then(text => {          
        setIsSaved(true)
        
        setSbShowSuccess(true);
        try {
         //if result.idScenario
          const data = JSON.parse(text);
          setTimeout(
            function() {
              history.push(`/scenariosetup/${data.idScenario}`)            
            },
            1000
          )
          
          // Do your JSON handling here
        } catch (err) {
          setError(true)
          setSbShowError(false);
          
          // It is text, do you text handling here
        }
      });


  }

  const refreshOwner = value => {
    if (!value) return;

    setOwner({value: value.value, label: value.username})
    setSelectedOwner(value);
    //console.log('refresh', value, ownerList)
    //alert('refresh'+value.value)
    //console.log('refreshOwner', value)
    getAdminOrganization(value.value)
      .then(function (myJson) {
        //console.log('myJson', myJson)
        setOrganization(myJson[0] )
      });
  }

  const refreshCurrency = value => {
   if (!value) return;
   //console.log('value', value);

   setCurrency(value.value)
   setSelectedCurrency(value);
 }

  const refreshCountry = async (country) => {
    //console.log('refreshcountry', country)
    if (!country) return;
    setRegion([])
    setRegionList([])
    setSam([])
    setSamList([])
    setCountry(country)
    getAdminRegion(country.value)
      .then(function (myJson) {
        //console.log('myjson:', myJson)
        let regionId = null
        if (myJson) {
          setRegionList(myJson)
          //console.log('region', myJson)
          if (scenarioData && Object.keys(scenarioData).length > 0) {
            regionId = scenarioData.general.sub_area
            //setRegion(getValueMeta(regionId, myJson))
          } else {

          }
          setRegionDisabled(false)
        } else {
          setRegionDisabled(true)
        }        
        
        getAdminSam(country.value, regionId)
        .then(function (myJson) {    
          //console.log('getAdminSam', myJson)                
          setSamList([])
          if (myJson == null) {
            //alert('ERRORE: nessun SAM associato a questa organizzazione');
          } else {
            setSamList(myJson)        
            if (scenarioData && Object.keys(scenarioData).length > 0) {                                        
              setSam(getValueMeta(scenarioData.general.idSam, myJson))
            }
          }                  
        });

      });
    
  }

  const refreshRegion = value => {    
    //console.log('in refreshRegion', value)
    setSam([])
    setSamList([])

    if(value){
      setRegion(value)      
      getAdminSam(country.value, value.value)
        .then(function (myJson) {
           setSamList([])
           setSamList(myJson)        
           //console.log('myJson: ',myJson) 
           let sam = [];  
           if (scenarioData && Object.keys(scenarioData).length > 0) {                        
             sam = getValueMeta(scenarioData.general.idSam, myJson)
           } else {
              //...
              
           }
           /* setTimeout(function() {
              setSam(sam)
              refreshSam(sam)
           }, 1000)  */          
        });
    }
  }
  
  const refreshSam = value => {    
    setSam([]) 
    setSam(value)
    setStatus('Draft')    // ERRORE, lo stato dovrebbe essere settato al salvataggio controllando che il valore "al momento del salvataggio" non sia cambiato 
  }
  
  const refreshClasses = value => {
    setClasse(value)
    setStatus('Draft') // ERRORE, lo stato dovrebbe essere settato al salvataggio controllando che il valore "al momento del salvataggio" non sia cambiato 
  }

  //Funzione che estra un oggetto da un array dato il valore della proprietà value
  const getValueMeta = (id, arr = []) => {
    if (arr)
      return (arr.filter(s => (s.value) === (id)).pop())
    
  }

  useEffect(() => {
    //console.log('props: ', props)
    let tempOptionsCurrencies = [];

    if (props.sector) {      
      const url = window.location.href
      let url_slice = url.split('/')

      const usr = JSON.parse(localStorage.getItem('user'))
      setUser(usr)
      //console.log('user', usr)
      //setOwnerList(props.user)

      /* getUsers().then((retrievedUsers) => {
         tempOptionsUsers = retrievedUsers.map(user => ({
            value: user.idUser,
            label: `${user.username} - ${user.organization}`,
            username: user.username,
         }));

         //setOptionsOwners(tempOptionsUsers);
      }); */
      
      getCurrencies().then((retrievedCurrencies) => {
         //console.log('retrievedCurrencies', retrievedCurrencies)
         tempOptionsCurrencies = retrievedCurrencies.filter(curr => curr.currency === 'EUR' || curr.currency === 'USD')
            .map(currency => {
               let el = document.createElement('span');
               el.innerHTML = `${currency.symbol} - ${currency.currency}`;

               return {value: currency.id, label: el.innerText, currency: currency.currency}
         });

         setOptionsCurrency(tempOptionsCurrencies);
      });

      if (scenarioData && scenarioData.idScenario) {
         //console.log('scenarioData:', scenarioData)
        setIdScenario(scenarioData.idScenario)
        setScenario_name(scenarioData.general.scenario_name)
        setStatus(scenarioData.scenario.status)
        setVerificato(parseInt(scenarioData.general.verificato))
        setClasse(getValueMeta(scenarioData.general.subsectorId,props.classes ))
        setOwner(getValueMeta(scenarioData.general.idUserOwner, props.user ))   
        setCurrency(scenarioData.general.currency); 
        
        setSelectedOwner(
            optionsOwners.filter(optionUser => optionUser.value === scenarioData.general.idUserOwner)
            .map((filteredUser) => ({
               value: filteredUser.value,
               label: filteredUser.label,
               username: filteredUser.username,
            }))
        );

        getAdminOrganization(scenarioData.general.idUserOwner)
        .then(function (myJson) {
          //console.log('getAdminOrganization', scenarioData.general, myJson, getValueMeta(scenarioData.general.idOrganization,myJson ))
          setOrganization(getValueMeta(scenarioData.general.idOrganization,myJson ))
        });
        //console.log('prima di refreshowner', scenarioData.general.idUserOwner, props.user)
        
        refreshOwner(getValueMeta(scenarioData.general.idUserOwner, optionsOwners))
        //refreshCurrency(getValueMeta(scenarioData.general.idUserOwner, props.user)) /////////
        // setOrganization(getValueMeta(scenarioData.general.idOrganization,props.organization ))
        setScenario_type(scenarioData.general.scenario_type)
        setSelectedScenarioType(optionsTypology.filter(el => el.value === scenarioData.general.scenario_type));

        //  refreshSector(getValueMeta(scenarioData.general.cod_nace,props.sector ))
        refreshCountry(getValueMeta(scenarioData.general.idCountry, props.country))
         // console.log(getValueMeta(scenarioData.general.sub_area,props.region ))
        if(scenarioData.general.sub_area) {
          refreshRegion(getValueMeta(scenarioData.general.sub_area,props.region ))
        }          
        setTassoSconto(scenarioData.general.discountRate)
        setOriginalTassoSconto(scenarioData.general.discountRate)

        setConstructionStart(new Date(scenarioData.tech.construction_start).getFullYear())
        setConstructionEnd(new Date(scenarioData.tech.construction_end).getFullYear())
        setOperationStart(new Date(scenarioData.tech.operation_start).getFullYear())
        setOperationEnd(new Date(scenarioData.tech.operation_end).getFullYear())

        setOriginalConstructionStart(new Date(scenarioData.tech.construction_start).getFullYear())
        setOriginalConstructionEnd(new Date(scenarioData.tech.construction_end).getFullYear())
        setOriginalOperationStart(new Date(scenarioData.tech.operation_start).getFullYear())
        setOriginalOperationEnd(new Date(scenarioData.tech.operation_end).getFullYear())
        
        setDataValutazione(new Date(scenarioData.tech.dataValutazione).getFullYear())
        setOriginalDataValutazione(new Date(scenarioData.tech.dataValutazione).getFullYear())

        setSalario(scenarioData.other.yearly_salary)
        setAccess(scenarioData.general.access)
        setSelectedAccess(optionsAccess.filter(optionAccess => optionAccess.value === scenarioData.general.access));
        setSam(scenarioData.general.idSam);
        setOriginalSam(scenarioData.general.idSam);
      }
      //se è nuovo scenario
      if ( url_slice.at(-1) === 'scenariosetup') {     
        getAuthUser()
          .then(function ({user}) {            
            //console.log('user:', user)
            setOwner(getValueMeta(user.idUser, props.user))
            setSelectedOwner(
               optionsOwners.filter(optionUser => optionUser.value === user.idUser)
               .map((filteredUser) => ({
                  value: filteredUser.value,
                  label: filteredUser.label,
                  username: filteredUser.username,
               }))
           );
            setOrganization(getValueMeta(user.idOrganization,props.organization ))
            // refreshOwner([{value: user.idUSer, label: props.user}])
          });

          //setSelectedCurrency(tempOptionsCurrencies.filter(curr => curr.currency === 'EUR'));
          setSelectedAccess(optionsAccess.filter(el => el.value === '3'))
          setSelectedScenarioType(optionsTypology.filter(el => el.value === '1'));
      }
    }

  }, [scenarioData.idScenario, optionsOwners, props.sector])

   useEffect(() => {
      if (scenarioData.idScenario) {
         //edit
         if (optionsCurrency.length > 0) {
            setSelectedCurrency(optionsCurrency.filter(optionCurr => optionCurr.value === scenarioData.general.currency))
         };

      } else {
         //new
         if (optionsCurrency.length > 0) {
            setSelectedCurrency(optionsCurrency.filter(optionCurr => optionCurr.currency === 'EUR'))
         };
      }
   }, [scenarioData.idScenario, scenarioData.general, optionsCurrency]);

  const Checkbox=  () => {    
    return (
      <Form.Check type="checkbox" className="mt-2 text-center" name="verificato" label={propTrans({prop: 'A0457'})} defaultChecked={verificato} onClick={() => setVerificato(!verificato)} />
    );
  }

  if (!scenarioData) return null

  return (

    <>
    {sbShowSuccess 
      ? <StatusBar status="success" timeout={true} />
      : ( sbShowError ? <StatusBar status="error" timeout={false}/> : null )
    }
    
    
    <Form onSubmit={submit} id="form-general">

      {/* BOX 1 - INIZIO */}
      <div className="simple-box" style={{marginTop : '40px'}}>

        <Row>
          {/*<Col>
            {error && <Form.Text className="text-muted">
              <Alert variant="danger" className="mt-3">
                <h5>There was an error when saving information!</h5>
              </Alert>
            </Form.Text>}
            {isSaved && <Form.Text className="text-muted">

              <Alert variant="success" className="mt-3">
                <h5>  Data saved succesfully!</h5>
              </Alert>
            </Form.Text>}
          </Col>
          <Col>
          <p className="font-weight-bold">
          Step 1 : Creazione  Scenario {idScenario}
          </p>
        </Col>*/}
          <Col lg={12} className="form-login">

            <h5 className="title-simple-box margin-title-box"><Trans code="A0493"/></h5>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="scenario_name">
                <Form.Label><Trans code="A0080" /></Form.Label>
                <Form.Control required type="text" placeholder="" name="scenario_name" value={scenario_name} onChange={({ target: { value } }) => setScenario_name(value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="projectOwner">
                <Form.Label><Trans code="A0069"/></Form.Label>
                <Select
                  required
                  name="projectOwner"
                  value={selectedOwner} //owner
                  options={optionsOwners} //ownerList
                  onChange={refreshOwner}
                  isDisabled={user && user.role != 1} />
              </Form.Group>
              <Form.Group as={Col} controlId="access">
                <Form.Label><Trans code="A0373"/></Form.Label>
                <Select
                  required
                  name="access"
                  value={selectedAccess}
                  options={user && Number(user.role) !== 1 ? optionsAccess.filter(el => Number(el.value) !== 1) : optionsAccess} //se non sei admin l'accesso Open non deve essere visualizzato
                  onChange={(e) => {setAccess(e.value); setSelectedAccess(e)}}
                  //isDisabled={user && Number(user.role) !== 1} 
                />

                {/* <Form.Control required as="select" _defaultvaluea="Accesso" name="access" value={access} onChange={({ target: { value } }) => setAccess(value)}>
                  <option value="3">Organizzazione</option>
                  <option value="1">Private</option>
                  <option value="2">Open</option>
                </Form.Control> */}
              </Form.Group>
              {/* <Form.Group as={Col} controlId="company">
                <Form.Label>Organizzazione</Form.Label>
                {organization &&
                  <Form.Control required type="text" placeholder={organization.label} name="company" value={organization.label} disabled />}
                <Select //commented
                  required
                  name="company"
                  value={organization}
                  options={organization}
                  getOptionValue={(option) => `${option['value']}`}
                  isDisabled={user && user.role != 1}
               />
              </Form.Group> */}
            </Row>
            <Row className="mb-3">

              <Form.Group as={Col} controlId="scenario_type">
                <Form.Label><Trans code="A0333"/></Form.Label>
                <Select
                  required
                  name="scenario_type"
                  value={selectedScenarioType}
                  options={optionsTypology}
                  onChange={(e) => {setScenario_type(e.value); setSelectedScenarioType(e)}}
                />

                {/* <Form.Control required as="select" _defaultvaluea="scenario_type" name="scenario_type" value={scenario_type} onChange={({ target: { value } }) => setScenario_type(value)}>
                  <option value="1">Investimento</option>
                  <option value="2">Politica</option>
                </Form.Control> */}
              </Form.Group>
              {/*<Form.Group as={Col} controlId="cod_nace">
            <Form.Label>Settore</Form.Label>
            <Select
                    name="cod_nace"
                    value={sector}
                    options={props.sector}
                    getOptionValue={(option) => `${option['value']}`}
                    onChange={refreshSector}
                  />
          </Form.Group> */}
              
               <Form.Group as={Col} controlId="currency">
                  <Form.Label><Trans code="GEN001" replacementText="Currency"/></Form.Label>
                  <Select
                     required
                     name="currency"
                     value={selectedCurrency}
                     options={optionsCurrency}
                     getOptionValue={(option) => `${option['value']}`}
                     onChange={refreshCurrency}
                  />
               </Form.Group>
              <Form.Group as={Col} controlId="subsectorId">
                <Form.Label><Trans code="A0498"/></Form.Label>
                <Select
                  required
                  name="classes"
                  value={classe}
                  options={props.classes}
                  getOptionValue={(option) => `${option['value']}`}
                  onChange={refreshClasses} />
              </Form.Group>
            </Row>
          </Col>

        </Row>
      </div>
      {/* BOX 1 - FINE */}
      {/* BOX 2 - INIZIO */}
      <div className="simple-box" style={{marginTop : '40px'}}>

        <Row>

          <Col lg={12} className="form-login">
            <h5 className="title-simple-box margin-title-box"><Trans code="A0528"/></h5>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="idCountry">
                <Form.Label><Trans code="A0067"/></Form.Label>
                <Select
                  required
                  name="idCountry"
                  value={country}
                  options={props.country}
                  getOptionValue={(option) => `${option['value']}`}
                  onChange={refreshCountry} />
              </Form.Group>
              <Form.Group as={Col} controlId="sub_area">

                <Form.Label><Trans code="A0084"/></Form.Label>
                <Select
                  required
                  name="sub_area"
                  value={region}
                  options={regionList}
                  getOptionValue={(option) => `${option['value']}`}
                  onChange={refreshRegion}
                  isDisabled={regionDisabled} />
              </Form.Group>


              <>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{modalMessage}</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      <Trans code="A0529"/>
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>

              <Form.Group as={Col} controlId="idSam">
                <Form.Label><Trans code="A0387"/></Form.Label>
                <Select
                  required
                  name="idSam"
                  value={sam}
                  options={samList}
                  getOptionValue={(option) => `${option['value']}`}
                  onChange={refreshSam}
                  isDisabled={samList && samList.length == 0} />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </div>
      {/* BOX 2 - FINE */}
      {/* BOX 3 - INIZIO */}
      <div className="simple-box" style={{marginTop : '40px'}}>

        <Row>

          <Col lg={12} className="form-login">
            <h5 className="title-simple-box margin-title-box"><Trans code="A0393"/></h5>



            <>
              <Modal show={showResetVSModal} onHide={handleResetVSModalClose}>
                <Modal.Header closeButton>
                  <Modal.Title>{modalResetVSTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalResetVSMessage}</Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleResetVSModalSave}>
                    <Trans code="F00046"/>
                  </Button>
                  <Button variant="secondary" onClick={handleResetVSModalClose}>
                     <Trans code="A0529"/>
                  </Button>
                </Modal.Footer>
              </Modal>
            </>


            <Row className="mb-3">
              <Form.Group as={Col} controlId="construction_start">
                <Row>
                  <Col>
                    <Form.Label><Trans code="OEC0047"/> - <Trans code="A0530"/></Form.Label>
                    <Form.Control required type='number' min={1990} max={2100} placeholder={currentYear} name="construction_start" value={constructionStart} onChange={({ target: { value } }) => { setConstructionStart(value); setStatus('Draft'); } } />
                  </Col>
                  <Col>
                    <Form.Label><Trans code="A0531"/></Form.Label>
                    <Form.Control required type='number' min={1990} max={2100} placeholder={currentYear} name="construction_end" value={constructionEnd} onChange={({ target: { value } }) => { setConstructionEnd(value); setStatus('Draft'); } } />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} controlId="operation_start">

                <Row>
                  <Col>
                    <Form.Label><Trans code="A0330"/> - <Trans code="A0530"/></Form.Label>
                    <Form.Control required type='number' min={1990} max={2100} placeholder={currentYear} name="operation_start" value={operationStart} onChange={({ target: { value } }) => { setOperationStart(value); setStatus('Draft'); } } />
                  </Col>
                  <Col>
                    <Form.Label><Trans code="A0531"/></Form.Label>
                    <Form.Control required type='number' min={1990} max={2100} placeholder={currentYear} name="operation_end" value={operationEnd} onChange={({ target: { value } }) => { setOperationEnd(value); setStatus('Draft'); } } />
                  </Col>
                </Row>
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <Col>
                <div>

                </div>
              </Col>
            </Row>
            <Row className="mb-3">

              <Form.Group as={Col} controlId="dataValutazione">
                <Row>

                  <Col>
                    <Form.Label><Trans code="C00491"/></Form.Label>
                    <Form.Control required type='number' min={1990} max={2100} placeholder={currentYear} name="dataValutazione" value={dataValutazione} onChange={({ target: { value } }) => { setDataValutazione(value); setStatus('Draft'); } } />
                  </Col>
                  <Col>

                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} controlId="discountRate">
                <Row>

                  <Col>
                    <Form.Label><Trans code="A0362"/> %</Form.Label>
                    <Form.Control required type="text" placeholder="Tasso di sconto" name="discountRate" value={tassoSconto} onChange={({ target: { value } }) => { setTassoSconto(value); setStatus('Draft'); } } />
                  </Col>
                  <Col>

                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </div>
      {/* BOX 3 - FINE */}
      
      {(user && user.idOrganization == "1" && user.role == "1") && (
        <Row>

          <Col lg={12} className="form-login">

            <Row>
              <Col>
                <div className="simple-box">
                  <Form.Group as={Col} controlId="verificato" className="text-center">
                    <Row>
                      <Checkbox />

                    </Row>
                  </Form.Group>

                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Button form="form-general" type="submit" className="submit-button sticky-footer-button"><Trans code="F00046"/></Button>
    </Form></>
  );

}

export default StepGeneral;
