import React, { useEffect, useRef } from 'react';
// import styled from 'styled-components';
import maximizeIcon from './maximize.svg';
import styleMod from './ModalOverlay.module.css';

const ModalOverlay = ({
  style,
  children,
  modalHeight,
  modalWidth,
  forceUnmount = false,
  onModal,
}) => {
  const [isModal, setIsModal] = React.useState(false);
  const [showChildren, setShowChildren] = React.useState(true);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsModal(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  useEffect(() => {
    if (forceUnmount) {
      setShowChildren(false);
      setTimeout(() => {
        setShowChildren(true); //to force re-render of the child component
      }, 100);
    }
  }, [forceUnmount, isModal]);

  const clickHanlder = () => {
    if (typeof onModal === 'function') onModal(!isModal);
    setIsModal(!isModal);
  };
  const tableStyles = { fontSize: 'clamp(10.467px, 1vw, 15px)' };
  return (
    <>
      <div className={styleMod.overlayWrapper}>
        <div
          className={styleMod.wrapper}
          style={{
            ...style,
            width: style.width || 'auto',
            height: style.height || 'auto',
          }}
          ref={ref}>
          <button className={styleMod.overlayButton} onClick={clickHanlder}>
            <img src={maximizeIcon} alt='maximize icon' />
          </button>
          {showChildren && children}
        </div>
      </div>
      {isModal && (
        <div
          className={styleMod.overlayWrapper}
          style={{
            position: 'fixed',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '99',
          }}>
          <div
            className={styleMod.wrapper}
            style={{
              ...style,
              width: modalWidth,
              height: modalHeight,
            }}
            ref={ref}>
            <button className={styleMod.overlayButton} onClick={clickHanlder}>
              <img src={maximizeIcon} alt='maximize icon' />
            </button>
            {showChildren && React.cloneElement(children, { styles: tableStyles })}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalOverlay;
