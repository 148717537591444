import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Accordion, ListGroup, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Trans, propTrans } from '@lib/i18n';
import { date } from '@lib/helpers';
import { ChevronRight } from '@icons';
import { formatNumberWithLocale } from '@lib/helpers/index';
import {
  getOEScenario,
  getGeoExtensions,
  getGeoPrecisions,
  getDatasetTypes,
  getNaceSectors,
  getInterventTypes,
  getOrganizations,
  getDatasetRegions,
  getOECurrencies,
  putRunScenario,
  getRunScenarioStatus,
} from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
// import ExecutionResults from './executionResultsImpact';
import SroiExecuteSummary from './SroiExecuteSummary';
import DashboardImpact from 'components/scenariodashboard/dashboard/DashboardImpact';
// import { getScenarioVariant } from '@lib/api/index';
import EsgRatingComponent from 'components/scenariodashboard/esgRating/esgRatingComponent';
import EsgExecuteSummary from './ESGExecuteSummary';
import ExecutionIndicator from './ExecutionIndicator';

const StepExecute = ({ scen, setScen, variant, setScenarioVariant, setVariantsRefresh }) => {
  const { idScenario = null, idVariante = null } = useParams();
  const [scenario, setScenario] = useState({});
  const [variantEx, setVariantEx] = useState({});
  const [infoClass, setInfoClass] = useState({});
  const [infoDataset, setInfoDataset] = useState({});
  const [infoLocalization, setInfoLocalization] = useState({});
  const [naceSectors, setNaceSectors] = useState([]);
  const [interventTypes, setInterventTypes] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [geoExtensions, setGeoExtensions] = useState([]);
  const [geoPrecisions, setGeoPrecisions] = useState([]);
  const [datasetTypes, setDatasetTypes] = useState([]);
  const [datasetRegions, setDatasetRegions] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [firstTempRow, setFirstTempRow] = useState(0);
  const [firstRowHeight, setFirstRowHeight] = useState('inherit');
  const [secondTempRow, setSecondTempRow] = useState(0);
  const [secondRowHeight, setSecondRowHeight] = useState('inherit');
  const [thirdTempRow, setThirdTempRow] = useState(0);
  const [thirdRowHeight, setThirdRowHeight] = useState('inherit');
  const [fourthTempRow, setFourthTempRow] = useState(0);
  const [fourthRowHeight, setFourthRowHeight] = useState('inherit');
  const [isRunRunning, setIsRunRunning] = useState(false);
  const [esgResults, setEsgResults] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const tablesRow = useRef();
  const history = useHistory();

  const resizeObserver = new ResizeObserver((entries) => {
    const testFirst = entries.filter((entry) => entry.target.className.includes('firstRow'));
    setFirstTempRow(Math.max(...[...testFirst].map((entry) => entry.target.offsetHeight)));

    const testSecond = entries.filter((entry) => entry.target.className.includes('secondRow'));
    setSecondTempRow(Math.max(...[...testSecond].map((entry) => entry.target.offsetHeight)));

    const testThird = entries.filter((entry) => entry.target.className.includes('thirdRow'));
    setThirdTempRow(Math.max(...[...testThird].map((entry) => entry.target.offsetHeight)));

    const testFourth = entries.filter((entry) => entry.target.className.includes('fourthRow'));
    setFourthTempRow(Math.max(...[...testFourth].map((entry) => entry.target.offsetHeight)));
  });

  useEffect(() => {
    if (isRunRunning === true) {
      let intervalId;
      let timeoutId;
      const fetchData = async () => {
        try {
          const response = await getRunScenarioStatus(idScenario, idVariante);
          if (response.data.status === 'Error' || response.data.status === 'Executed') {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            // await getScenarioData();
            handleStatusBar('success', propTrans({ prop: 'A0656' }), true);
            setIsRunRunning(false);
            window.location.reload();
          }
        } catch (error) {
          handleStatusBar('error', propTrans({ prop: 'A0655' }), false);
          setIsRunRunning(false);
        }
      };

      // const getScenarioData = async () => {
      //   try {
      //     const [scenarioResponse, variantResponse] = await Promise.all([
      //       getOEScenario(idScenario),
      //       getScenarioVariant(idScenario, idVariante),
      //     ]);

      //     setScenario(scenarioResponse.data);
      //     // setScenarioVariant(variantResponse.data);
      //     setScen(variantResponse.data);
      //     setVariantsRefresh(true);
      //   } catch (error) {
      //     handleStatusBar('error', propTrans({ prop: 'A0655' }), false);
      //   }
      // };

      intervalId = setInterval(fetchData, 3000);
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setIsRunRunning(false);
        handleStatusBar('error', propTrans({ prop: 'A0727' }), false);
      }, 20000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [isRunRunning]);

  useEffect(() => {
    if (firstTempRow > 0) {
      setFirstRowHeight(firstTempRow);
    }
    if (secondTempRow > 0) {
      setSecondRowHeight(secondTempRow);
    }
    if (thirdTempRow > 0) {
      setThirdRowHeight(thirdTempRow);
    }
    if (fourthTempRow > 0) {
      setFourthRowHeight(fourthTempRow);
    }
  }, [firstTempRow, secondTempRow, thirdTempRow, fourthTempRow]);

  useEffect(() => {
    if (tablesRow.current) {
      setTimeout(() => {
        let elements = [...document.getElementsByClassName('execute-row')];
        elements.forEach((el) => resizeObserver.observe(el));
      }, 1000);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOEScenario(idScenario).then((retrievedScenario) => {
      let tempScenario = retrievedScenario.data;
      setScenario(tempScenario);

      getGeoExtensions(tempScenario.userOrganizationId).then((retrievedGeoExtention) => {
        setGeoExtensions(retrievedGeoExtention.data);
      });

      getGeoPrecisions(tempScenario.userOrganizationId).then((retrievedGeoPrecision) => {
        setGeoPrecisions(retrievedGeoPrecision.data);
      });

      getDatasetTypes(tempScenario.userOrganizationId).then((retrievedDatasetTypes) => {
        setDatasetTypes(retrievedDatasetTypes.data);
      });
    });
  }, [idScenario]);

  useEffect(() => {
    if (variant) {
      setVariantEx(variant);
    }
  }, [variant]);

  useEffect(() => {
    if (variantEx.id) {
      getDatasetRegions(variantEx.datasetId).then((retrievedRegions) => {
        setDatasetRegions(retrievedRegions.data);
      });
    }
  }, [variantEx]);

  useEffect(() => {
    getNaceSectors().then((retrievedNaceSectors) => {
      setNaceSectors(retrievedNaceSectors.data);
    });

    getInterventTypes().then((retrievedInterventTypes) => {
      setInterventTypes(retrievedInterventTypes.data);
    });

    getOrganizations().then((retrievedOrganizations) => {
      setOrganizations(retrievedOrganizations.data);
    });

    getOECurrencies().then((retrievedCurrencies) => {
      setCurrencies(retrievedCurrencies.data);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(scenario).length > 0) {
      setInfoClass(() => ({
        naceSector: naceSectors
          .filter((naceSector) => naceSector.code === scenario?.class?.sectorCode)
          .map((sector) => sector.name)
          .pop(),
        interventType: interventTypes
          .filter((interventType) => interventType.code === scenario?.class?.investmentType)
          .map((interventType) => interventType.name)
          .pop(),
        organization: organizations
          ?.filter((organization) => Number(organization.id) === Number(scenario?.class?.companyId))
          .map((organization) => organization.name)
          .pop(),
        version: scenario?.class?.version,
        localization: Number(scenario?.class?.localization) ? (
          <Trans code='A0490' />
        ) : (
          <Trans code='A0491' />
        ),
        kpi: scenario.class.kpi,
        expenseitems: scenario?.class?.expenseItemsList?.[0]?.expenseItems,
      }));
    }
  }, [scenario, naceSectors, interventTypes, organizations]);
  console.log('INFO CLASS', infoClass);
  useEffect(() => {
    if (variantEx.id) {
      setInfoDataset(() => ({
        name: variantEx?.dataset?.name,
        geoExt: geoExtensions
          ?.filter((precision) => precision.code === variantEx?.dataset?.geoExtCode)
          .map((precision) => precision.name)
          .pop(),
        precision: geoPrecisions
          .filter((precision) => precision.code === variantEx?.dataset?.precisionCode)
          .map((precision) => precision.name)
          .pop(),
        model: datasetTypes
          .filter((datasetType) => datasetType.code === variantEx?.dataset?.typeCode)
          .map((datasetType) => datasetType.name)
          .pop(),
        salaryCurrency: currencies
          .filter((currency) => currency.code === variantEx?.dataset?.salaryCurrency)
          .map((sector) => sector.symbol)
          .pop(),
        localSalary: variantEx?.dataset?.localSalary.toLocaleString('it-IT', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
        year: variantEx?.dataset?.year,
      }));
      setInfoLocalization(() => ({
        totalCapex: variantEx?.economicData.reduce((tot, elem) => {
          return tot + Number(elem.totalCapex);
        }, 0),
        totalOpex: variantEx?.economicData.reduce((tot, elem) => {
          return tot + Number(elem.totalOpex);
        }, 0),
        totalRevenues: variantEx?.economicData.reduce((tot, elem) => {
          return tot + Number(elem.totalRevenues);
        }, 0),
        economicData: variantEx?.economicData,
        economicDataPefEv:
          variantEx[
            'economicData' +
              variantEx?.inputTypeCode.charAt(0).toUpperCase() +
              variantEx?.inputTypeCode.slice(1).toLowerCase()
          ],
      }));
    }
  }, [variantEx, currencies, datasetTypes, geoExtensions, geoPrecisions]);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const buildKpiTable = () => {
    const colHeaderNames = ['name', 'udm', 'formula', 'indicators'];

    return (
      <table className='my-2'>
        <thead>
          <tr>
            {colHeaderNames.map((colName) => {
              return (
                <th
                  key={colName}
                  className={`cell-width-economic-data ${colName === 'udm' ? 'pl-4' : ''}`}>
                  <Trans
                    code={
                      colName === 'name'
                        ? 'A0636'
                        : colName === 'udm'
                        ? 'A0136'
                        : colName === 'formula'
                        ? 'A0638'
                        : colName === 'indicators'
                        ? 'A0637'
                        : ''
                    }
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className=''>
          {infoClass.kpi
            .filter((kpi) => kpi.visible)
            .map((kpi) => {
              return (
                <tr key={kpi.id}>
                  <td className='pl-2 w-space-wrap'>
                    <Trans code={kpi.langTag} replacementText={kpi.name} />
                  </td>
                  <td className='pl-4'>{kpi.udm}</td>
                  <td className='pl-2'>{kpi.formula}</td>
                  <td className='pl-2 text-left'>
                    <ListGroup variant='flush'>
                      {kpi.indicators.map((ind) => {
                        return (
                          <ListGroup.Item key={ind.id} className='w-space-wrap'>
                            <Trans code={ind.langTag} replacementText={ind.indicator} />
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  const buildExpenseItemsTable = () => {
    const colHeaderNames = ['name', 'type'];

    return (
      <table className='my-2'>
        <thead>
          <tr>
            {colHeaderNames.map((colName) => {
              return (
                <th
                  key={colName}
                  className={`cell-width-economic-data ${colName === 'type' ? 'pl-3' : ''}`}>
                  <Trans code={colName === 'name' ? 'A0636' : colName === 'type' ? 'A0639' : ''} />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className=''>
          {infoClass.expenseitems.map((exp) => {
            return (
              <tr key={exp.id}>
                <td className='pl-2 w-space-wrap'>
                  <Trans code={exp.langTag} replacementText={exp.name} />
                </td>
                <td className='pl-2 text-left pl-3'>{exp.expenseType}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const buildEconomicDataTable = (type = 'financialData') => {
    const typeData = type === 'financialData' ? 'economicData' : 'economicDataPefEv';
    const filteredEconData = infoLocalization[typeData].filter((econData) => {
      return datasetRegions.some((region) => {
        return econData.regionCode === region.code;
      });
    });

    const colHeaderNames = ['totalCapex', 'totalOpex', 'totalRevenues'];

    return (
      <table className='my-2'>
        <thead>
          <tr>
            <th key='th-empty'></th>
            {colHeaderNames.map((colName) => {
              return (
                <th key={colName} className='text-right cell-width-economic-data'>
                  <Trans
                    code={
                      colName === 'totalCapex'
                        ? 'A0633'
                        : colName === 'totalOpex'
                        ? 'A0634'
                        : colName === 'totalRevenues'
                        ? 'A0635'
                        : ''
                    }
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className='text-right'>
          {filteredEconData.map((econData) => {
            return (
              <tr key={econData.regionCode}>
                <td className='pl-2 w-space-wrap'>
                  {datasetRegions
                    .filter((region) => region.code === econData.regionCode)
                    .map((region) => region.name)}
                </td>
                {colHeaderNames.map((colName) => (
                  <td key={colName + '-td'}>
                    {filteredEconData
                      .filter((el) => el.regionCode === econData.regionCode)
                      .map((el) => (
                        <Fragment key={el.regionCode}>
                          {Number(el[colName]).toLocaleString('it-IT', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                        </Fragment>
                      ))}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const handleRun = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    setIsRunning(true);
    const runResult = await putRunScenario(scenario.id, idVariante);

    if (runResult.status === true) {
      setIsRunning(false);
      setIsRunRunning(true);
    } else {
      setIsRunning(false);
      handleStatusBar('error', runResult.message || propTrans({ prop: 'A0655' }), false);
      // setScenario(runResult.data);
      // setScen(runResult.data); //per subtabs
    }
  };

  const getStyle = (el) => {
    return {
      bullet: {
        backgroundColor:
          el.status === 'Executed' ? '#689689' : el.status === 'Error' ? '#C94723' : '#FFCB47',
      },
      text: {
        color: el.status === 'Executed' ? '#689689' : el.status === 'Error' ? '#C94723' : '#FFCB47',
      },
    };
  };

  if (!scen) return null;

  return (
    <>
      {/* <Row className='mt-5 text-center'>
        <Col className=''>
          <span className='dot-legend mb-0'>
            <i className='bullet' style={{ ...getStyle(scenario).bullet }} />
            <span className='text' style={{ ...getStyle(scenario).text }}>
              <Trans
                code={
                  scenario.status === 'Executed'
                    ? 'A0614'
                    : scenario.status === 'Error'
                    ? 'A0613'
                    : 'A0612'
                }
              />
            </span>
          </span>
        </Col>
      </Row> */}

      <Row className='mt-4 mb-2'>
        <Col className='col-12'>
          <h5 className='mb-0 f-600'>
            <Trans code='A0611' />
          </h5>
        </Col>
      </Row>

      <Row ref={tablesRow} className='gap-1'>
        {/* Class */}
        <Col className='col-4'>
          <Card className='h-95'>
            <Card.Body>
              <Card.Title className='mb-4 text-uppercase pt-3'>
                <Trans code='A0498' />
              </Card.Title>

              <Row className='mb-0 execute-class'>
                <Col>
                  {/* settore */}
                  <Row
                    className='border-bottom execute-row firstRow'
                    style={{ height: firstRowHeight }}>
                    <Col className='col-4 f-600 pr-0'>
                      <Trans code='A0616' />
                    </Col>
                    <Col className='text-right pl-0'>{infoClass.naceSector}</Col>
                  </Row>

                  {/* tipo intervento */}
                  <Row
                    className='border-bottom execute-row secondRow'
                    style={{ height: secondRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0617' />
                    </Col>
                    <Col className='text-right pl-0'>{infoClass.interventType}</Col>
                  </Row>

                  {/* organizzazione */}
                  <Row
                    className='border-bottom execute-row thirdRow'
                    style={{ height: thirdRowHeight }}>
                    <Col className='f-600  pr-0'>
                      <Trans code='A0058' />
                    </Col>
                    <Col className='text-right pl-0'>{infoClass.organization}</Col>
                  </Row>

                  {/* versione */}
                  <Row
                    className='border-bottom execute-row fourthRow'
                    style={{ height: fourthRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0502' />
                    </Col>
                    <Col className='text-right pl-0'>{infoClass.version}</Col>
                  </Row>

                  {/* localizzazione */}
                  <Row className='border-bottom execute-row'>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0618' />
                    </Col>
                    <Col className='text-right pl-0'>{infoClass.localization}</Col>
                  </Row>

                  {/* kpi */}
                  <Accordion as={Row} className='border-bottom py-0 px-3'>
                    <Accordion.Collapse eventKey='kpi'>
                      <>{infoClass.kpi?.length > 0 && buildKpiTable()}</>
                    </Accordion.Collapse>
                    <Accordion.Toggle
                      as='span'
                      className='accordion-title'
                      variant='link'
                      eventKey='kpi'>
                      <Row className=''>
                        <Col className='f-600 pr-0'>
                          <Trans code='A0619' />
                        </Col>
                        <Col className='text-right pl-0 col-auto'>
                          <ChevronRight className='accordion-arrow' />
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                  </Accordion>

                  {/* voci di spesa */}
                  <Accordion as={Row} className='py-0 px-3'>
                    <Accordion.Collapse eventKey='expenseitems'>
                      <>{infoClass.expenseitems?.length > 0 && buildExpenseItemsTable()}</>
                    </Accordion.Collapse>
                    <Accordion.Toggle
                      as='span'
                      className='accordion-title'
                      variant='link'
                      eventKey='expenseitems'>
                      <Row className=''>
                        <Col className='f-600 pr-0'>
                          <Trans code='A0620' />
                        </Col>
                        <Col className='text-right pl-0 col-auto'>
                          <ChevronRight className='accordion-arrow' />
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                  </Accordion>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Dataset */}
        <Col className='col-4'>
          <Card className='h-95'>
            <Card.Body>
              <Card.Title className='mb-4 text-uppercase pt-3'>
                <Trans code='A0543' />
              </Card.Title>

              <Row className='mb-0 execute-dataset'>
                <Col>
                  {/* nome */}
                  <Row
                    className='border-bottom execute-row firstRow'
                    style={{ height: firstRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0173' />
                    </Col>
                    <Col className='text-right pl-0'>{infoDataset.name}</Col>
                  </Row>

                  {/* estensione */}
                  <Row
                    className='border-bottom execute-row secondRow'
                    style={{ height: secondRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0624' />
                    </Col>
                    <Col className='text-right pl-0 col-auto'>{infoDataset.geoExt}</Col>
                  </Row>

                  {/* precisione */}
                  <Row
                    className='border-bottom execute-row thirdRow'
                    style={{ height: thirdRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0622' />
                    </Col>
                    <Col className='text-right pl-0'>{infoDataset.precision}</Col>
                  </Row>

                  {/* modello */}
                  <Row
                    className='border-bottom execute-row fourthRow'
                    style={{ height: fourthRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0623' />
                    </Col>
                    <Col className='text-right pl-0'>{infoDataset.model}</Col>
                  </Row>

                  {/* valuta salariale */}
                  <Row className='border-bottom execute-row'>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0625' />
                    </Col>
                    <Col className='text-right pl-0'>{infoDataset.salaryCurrency}</Col>
                  </Row>

                  {/* salario locale */}
                  <Row className='border-bottom'>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0626' />
                    </Col>
                    <Col className='text-right pl-0'>{infoDataset.localSalary}</Col>
                  </Row>

                  {/* anno */}
                  <Row className=''>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0627' />
                    </Col>
                    <Col className='text-right pl-0'>{infoDataset.year}</Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Localizzazione */}
        <Col className='col-4'>
          <Card className='h-95'>
            <Card.Body>
              <Card.Title className='mb-4 text-uppercase pt-3'>
                <Trans code='A0632' />
              </Card.Title>

              <Row className='mb-0 execute-localization'>
                <Col>
                  {/* totale capex */}
                  <Row
                    className='border-bottom execute-row firstRow'
                    style={{ height: firstRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0628' />
                    </Col>
                    <Col className='text-right pl-0'>
                      {formatNumberWithLocale(infoLocalization.totalCapex, true)}
                    </Col>
                  </Row>

                  {/* totale opex */}
                  <Row
                    className='border-bottom execute-row secondRow'
                    style={{ height: secondRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0629' />
                    </Col>
                    <Col className='text-right pl-0'>
                      {formatNumberWithLocale(infoLocalization.totalOpex, true)}
                    </Col>
                  </Row>

                  {/* totale revenues */}
                  <Row
                    className='border-bottom execute-row thirdRow'
                    style={{ height: thirdRowHeight }}>
                    <Col className='f-600 pr-0'>
                      <Trans code='A0630' />
                    </Col>
                    <Col className='text-right pl-0'>
                      {formatNumberWithLocale(infoLocalization.totalRevenues, true)}
                    </Col>
                  </Row>

                  {/* dati economici */}
                  <Accordion as={Row} className='border-bottom  py-0 px-3'>
                    <Accordion.Collapse eventKey='economic'>
                      <>{infoLocalization.economicData?.length > 0 && buildEconomicDataTable()}</>
                    </Accordion.Collapse>
                    <Accordion.Toggle
                      as='span'
                      className='accordion-title'
                      variant='link'
                      eventKey='economic'>
                      <Row className=''>
                        <Col className='f-600 pr-0'>
                          <Trans code='A0767' />
                        </Col>
                        <Col className='text-right pl-0 col-auto'>
                          <ChevronRight className='accordion-arrow' />
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                  </Accordion>

                  {infoLocalization.economicDataPefEv?.length > 0 && (
                    <Accordion as={Row} className='py-0 px-3'>
                      <Accordion.Collapse eventKey='economicData'>
                        <>{buildEconomicDataTable('economicDataPef')}</>
                      </Accordion.Collapse>
                      <Accordion.Toggle
                        as='span'
                        className='accordion-title'
                        variant='link'
                        eventKey='economicData'>
                        <Row className=''>
                          <Col className='f-600 pr-0'>
                            <Trans code='A0631' />
                          </Col>
                          <Col className='text-right pl-0 col-auto'>
                            <ChevronRight className='accordion-arrow' />
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Accordion>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Button
        disabled={isRunning || isRunRunning}
        type='submit'
        className='submit-button mt-5 mb-5 d-flex justify-content-center align-items-center'
        onClick={() => handleRun()}>
        <Trans code='A0532' />
        <Spinner
          animation='border'
          role='status'
          size='sm'
          className={`ml-2 ${isRunning || isRunRunning ? 'd-inline-block run-button' : 'd-none'}`}
        />
      </Button>
      <div style={{ maxWidth: 'fit-content', margin: 'auto' }}>{sbShow}</div>

      <div className='fade-in-div'>
        <Row className='mt-4 mb-2'>
          <Col className='col-12'>
            <h5 className='mb-0 f-600'>
              <Trans code='A0641' />
            </h5>
          </Col>
        </Row>

        <div className='simple-box py-0'>
          <Row>
            <Col lg={12} className=''>
              {variantEx.analyses &&
                variantEx.analyses
                  .filter((filteredIA) => filteredIA.type === 'IA')
                  .map((analysis) => {
                    return (
                      <Row className='d-flex flex-column' key={analysis.id}>
                        <ExecutionIndicator
                          analysis={analysis}
                          analysisUrl={'/impactanalysis'}
                          height='50px'
                        />
                        {analysis.status === 'Executed' &&
                          variantEx?.analysisResults?.IA?.syntheticResults && (
                            <Col className='mb-4'>
                              {/* <ExecutionResults
                                  filterForRegion='total'
                                  syntheticResults={scenario?.analysisResults?.IA?.syntheticResults}
                                  typologyCode={scenario.typologyCode}
                                  scenarioId={idScenario}
                                /> */}
                              <DashboardImpact
                                filterForRegion={'total'}
                                syntheticResults={variantEx?.analysisResults?.IA?.syntheticResults}
                                typologyCode={variantEx.typologyCode}
                                regions={datasetRegions}
                                isInExecution={true}
                              />
                            </Col>
                          )}
                      </Row>
                    );
                  })}
            </Col>
          </Row>
        </div>
        {variantEx?.analysisResults?.SROI?.syntheticResults && (
          <div className='simple-box py-0 '>
            <Row>
              <SroiExecuteSummary
                syntheticResults={variantEx?.analysisResults?.SROI?.syntheticResults}
                currencyToUse={currencies}
                type={'SROI'}
                analysis={variantEx.analyses
                  .filter((filteredIA) => filteredIA.type === 'SROI')
                  .pop()}
                idScenario={idVariante}
              />
            </Row>
          </div>
        )}
        {variantEx?.analyses?.some((el) => el.type === 'ESG') && (
          <div className='simple-box py-0 '>
            <Row>
              <EsgExecuteSummary
                analysis={variantEx.analyses
                  .filter((filteredIA) => filteredIA.type === 'ESG')
                  .pop()}
              />
            </Row>
          </div>
        )}
      </div>
    </>
  );
};
export default StepExecute;
