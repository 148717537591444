import i18n from 'i18next';
import Backend from './backend';
import { initReactI18next, useTranslation } from 'react-i18next';
import Tooltip from '@lib/tooltip';
// import { getTranslations } from '@lib/api'
// import translations from './translations/dict.json'
import apiKeys from './translations/apiKeysMapper.json';
import { getTranslationsUrl } from '@lib/api';

const loadTranslations = (data, url = null, language = null, namespace = null) => {
  const translations = JSON.parse(data);
  const mappedTranslations = {
    en: { translation: {} },
    es: { translation: {} },
    it: { translation: {} },
  };
  Object.keys(translations).forEach((k) => {
    const t = translations[k];
    if (t.eng) {
      if (t.eng.hover.length > 0) {
        mappedTranslations.en.translation[k] = JSON.stringify({
          label: t.eng.text,
          tooltip: t.eng.hover,
        });
      } else mappedTranslations.en.translation[k] = JSON.stringify({ label: t.eng.text });
    }

    if (t.esp) {
      if (t.esp.hover.length > 0) {
        mappedTranslations.es.translation[k] = JSON.stringify({
          label: t.esp.text,
          tooltip: t.esp.hover,
        });
      } else mappedTranslations.es.translation[k] = JSON.stringify({ label: t.esp.text });
    }

    if (t.ita) {
      if (t.ita.hover.length > 0) {
        mappedTranslations.it.translation[k] = JSON.stringify({
          label: t.ita.text,
          tooltip: t.ita.hover,
        });
      } else mappedTranslations.it.translation[k] = JSON.stringify({ label: t.ita.text });
    }
  });

  if (language && namespace) return mappedTranslations[language][namespace];

  return mappedTranslations;
};

export const Trans = ({ code, replacementText }) => {
  const { t } = useTranslation();

  const trad = t(code) === code ? replacementText ?? code : t(code);

  return <Tooltip trad={trad} code={code} />;
};

export const ApiTrans = ({ prop, scope = null }) => {
  const { t } = useTranslation();
  const domain = scope ? apiKeys[scope] || apiKeys : apiKeys;
  const code = domain[prop] ? domain[prop] : apiKeys[prop] || prop;
  return <Tooltip trad={t(code)} code={code} />;
};

export const propTrans = ({ prop, scope = null }) => {
  const t = i18n.t.bind(i18n);
  const domain = scope ? apiKeys[scope] || apiKeys : apiKeys;
  //console.log('domain', domain)
  const code = domain[prop] ? domain[prop] : apiKeys[prop] || prop;
  //console.log('code', code)
  const trad = t(code);
  try {
    const content = JSON.parse(trad);
    return content.label;
  } catch (e) {
    return trad;
  }
};

export const propTransTooltip = ({ prop, scope = null }) => {
  const t = i18n.t.bind(i18n);
  const domain = scope ? apiKeys[scope] || apiKeys : apiKeys;

  const code = domain[prop] ? domain[prop] : apiKeys[prop] || prop;

  const trad = t(code);
  //console.log('trad', trad)
  try {
    const content = JSON.parse(trad);
    return content.tooltip;
  } catch (e) {
    //console.log('error:', e)
    return trad;
  }
};
console.log('transURL', getTranslationsUrl());
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    // resources: loadTranslations(),
    backend: {
      loadPath: `${getTranslationsUrl()}`,
      parse: loadTranslations,
    },
    fallbackLng: 'en',
    preload: ['en', 'it', 'es'],
    lng: localStorage.getItem('externalyticsLanguage') || 'it',
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      //useSuspense: false
    },
  });

export default i18n;
