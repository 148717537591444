import React from 'react';
import { postCorporateEsgAnalysis } from '@lib/api/index';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const CorporateEsgAnalysesClassSelector = ({ companyId, classe, setError, setIsLoading }) => {
  const history = useHistory();
  const createNewAnalysis = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    const response = await postCorporateEsgAnalysis(companyId, classe.id);
    if (response.status) {
      setIsLoading(false);
      history.push(
        `/corporateesg/company/${response.data.companyId}/analysis/${response.data.id}/general`
      );
    } else {
      response?.message
        ? setError(response.message)
        : setError(`Errore nella creazione dell'analisi`);
    }
    setIsLoading(false);
  };
  return (
    <div style={{ flexGrow: '1 0' }}>
      <Button onClick={createNewAnalysis}>{classe.name}</Button>
    </div>
  );
};

export default CorporateEsgAnalysesClassSelector;
