import React from "react";
import {
  ScenariTabopen
} from "@components/scenari";

const Opengallery = () => {
  return (
    <div id="scenari" className="megaContent">
      <div className="container-fluid">
        <ScenariTabopen />
      </div>
    </div>
  );
}

export default Opengallery;