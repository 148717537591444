import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n/index';

const DownloadButton = ({ data, downloadProgress, disabled, handleDownload }) => {
  const renderTooltip = (props) => {
    let message = '';

    //Sometimes, props.popper.state is undefined.
    //It runs this function enough times that state gets a value
    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };

  return (
    <div className='d-flex mr-0 py-10 rounded align-items-center'>
      <span className='font-weight-bold text-uppercase' style={{ color: '#898989' }}>
        {downloadProgress ? <Trans code='A0782' /> : 'Download'}
      </span>
      {downloadProgress ? (
        <>
          {
            <span style={{ color: '#898989' }} className='d-flex align-items-center ml-2'>
              <Spinner animation='border' size='sm' />
            </span>
          }
        </>
      ) : (
        data.map((item, i) => {
          return (
            <OverlayTrigger
              placement='top'
              delay={{ show: 200, hide: 100 }}
              overlay={renderTooltip}
              popperConfig={{ testObj: propTrans({ prop: item.translationCode }) }}
              key={`${item.fileName}__${i}`}>
              <Button
                variant='link'
                className='d-flex mr-0 py-10 rounded'
                onClick={() => handleDownload(item.downloadUrl, item.fileName, item.fileExtension)}
                disabled={disabled}>
                <span className=' d-flex align-items-center ml-2'>{item.icon}</span>
              </Button>
            </OverlayTrigger>
          );
        })
      )}
      {/* <Button
        variant='link'
        className='d-flex mr-0 py-10 rounded'
        onClick={() => handleDownload()}
        disabled={disabled}>
        {downloadProgress ? (
          <>
            {
              <span className=' d-flex align-items-center ml-2'>
                <Spinner animation='border' size='sm' />
              </span>
            }
          </>
        ) : (
          <>
            {
              <span className=' d-flex align-items-center ml-2'>
                <ExcelIcon style={{ fill: '#08a2aa' }} className='ico-small cancel' />
              </span>
            }
          </>
        )}
      </Button> */}
    </div>
  );
};

export default DownloadButton;
