import React, { useEffect } from "react";
import useConfirm from "./useConfirm";
import { Button, Modal } from 'react-bootstrap';
import { Trans } from '@lib/i18n';

const ConfirmModal = () => {
  const { prompt = "", isOpen = false, proceed, cancel } = useConfirm();

  useEffect(() => {
    const handleKeydown = (e) => {
      if (proceed && isOpen && e.key === "Enter") {
        proceed();
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [proceed, isOpen]);

  return (
   <>
      <Modal show={isOpen} onHide={cancel} centered>
         <Modal.Header closeButton>
            <Modal.Title><Trans code="A0515"/></Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {prompt}
            {/* <div className='my-3'>
               <Trans code="A0577"/>
            </div> */}
         </Modal.Body>
         <Modal.Footer>
            <Button variant="secondary" onClick={cancel}>
               <Trans code="F0057"/>
            </Button>
            <Button variant="success" onClick={proceed}>
               <Trans code="A0561"/>
            </Button>
         </Modal.Footer>
      </Modal>
   </>
  );
};

export default ConfirmModal;