import React from 'react'
import { Row, Col} from 'react-bootstrap';
import { useQuery} from '@apollo/client'







const GenericMap = props => {

  const NamePropsQuery = props.query
  const NamePropsType = props.type


  const { loading, error, data } = useQuery(NamePropsQuery)

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error</p>

  return (
    <>
      {data[NamePropsType].map((item, key) => {
        return (
          <>
            <Row className="index-item" key={key}>
              <Col md={12} key={key}>
                <h3>{item.title_it}</h3>
                <p>{item.author_it}</p>
                <p>{item.editor_it}</p>
                {item.link_it && <a href={item.link_it}>{item.link_it}</a>}
              </Col>
            </Row>
            <hr />
          </>
      )})}
    </>
  )
}

export default GenericMap;