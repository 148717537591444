import { propTrans } from '@lib/i18n/index';
import { IcoCanc, IcoDownloadFile, IcoEye } from 'icone/index';
import React, { useState } from 'react';
import { OverlayTrigger, Button, Tooltip, Spinner } from 'react-bootstrap';
const DocumentsActionBtn = ({
  show,
  setShow,
  entityId,
  handleDownload,
  isQuestions,
  handleStatusBar,
  data,
  isCorporate = false,
}) => {
  const [loadingDownlod, setLoadingDownlod] = useState(false);

  // const handleDelete = () => {
  //   console.log('Delete', entityId, entityType);
  // };

  const handleShow = () => {
    setShow(!show);
  };

  const downloadTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'A0817' })}</Tooltip>;
  const showTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'A0818' })}</Tooltip>;
  // const deleteTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'F00056' })}</Tooltip>;

  return (
    <>
      <OverlayTrigger placement='top' overlay={downloadTooltip}>
        <Button
          variant='link'
          className='mr-1'
          onClick={() =>
            isQuestions
              ? handleDownload(entityId, true, setLoadingDownlod, handleStatusBar, isCorporate)
              : handleDownload(entityId, true, setLoadingDownlod, handleStatusBar, data)
          }
          disabled={loadingDownlod}>
          {loadingDownlod ? (
            <span style={{ color: '#898989' }} className='d-flex align-items-center ml-2'>
              <Spinner animation='border' size='sm' />
            </span>
          ) : (
            <IcoDownloadFile className='second-color ico-small edit' />
          )}
        </Button>
      </OverlayTrigger>
      {!isQuestions && (
        <OverlayTrigger placement='top' overlay={showTooltip}>
          <Button variant='link' className='mr-1' onClick={() => handleShow()}>
            <IcoEye className='second-color ico-small edit color-icon' />
          </Button>
        </OverlayTrigger>
      )}

      {/* <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button variant='link' className='mr-0' onClick={() => handleDelete()}>
          <IcoCanc className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger> */}
    </>
  );
};

export default DocumentsActionBtn;
