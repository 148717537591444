const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const validatePassword = (password) => {
  return password.length >= 5;
};

const validateOtpCode = (otpCode) => {
  return otpCode.length === 6;
};

export { validateEmail, validatePassword, validateOtpCode };
