import React, { useState, useEffect } from 'react';
import { Trans, propTrans } from '@lib/i18n';
// import { Button } from 'react-bootstrap';
import AnimatedDonutChartDash from '@components/scenariodashboard/AnimatedDonutChartDash';
import { useParams, Link } from 'react-router-dom';
const DashboardSummaryWheels = ({
  syntheticResults: { gdpByFactor, employmentBySector, incomesByType, taxesByType },
}) => {
  const [gdpData, setGdpData] = useState([]);
  const [employmentData, setEmploymentData] = useState([]);
  const [incomesData, setIncomesData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  //console.log('investmentData', investmentData);
  //console.log('filterForRegion comp:', filterForRegion);
  const { idScenario = null, idVariante = null } = useParams();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);

  useEffect(() => {
    let filter = '';
    setGdpData(gdpByFactor.filter((el) => el.region === filter));
    setEmploymentData(employmentBySector.filter((el) => el.region === filter));
    setIncomesData(incomesByType.filter((el) => el.region === filter));
    setTaxesData(taxesByType.filter((el) => el.region === filter));
  }, [gdpByFactor, employmentBySector, incomesByType, taxesByType]);

  return (
    <div className='simple-box impact h-100'>
      <div className='row'>
        <div className='col'>
          <h5 className='title-simple-box margin-title-box'>
            {`${propTrans({ prop: 'A0732' })}: ${propTrans({ prop: 'A0737' })}`}
          </h5>
        </div>
      </div>
      <div className='d-flex justify-content-around flex-wrap align-items-center maximizeHeight'>
        <div className='chartBox impactChart smallImpactChart'>
          <AnimatedDonutChartDash
            chartId='gdp'
            data={gdpData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Link
                className='ico-absolute'
                to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/gdp`}>
                <Trans code='A0263' />
              </Link>
            </h5>
          </div>
        </div>
        <div className='chartBox impactChart smallImpactChart'>
          <AnimatedDonutChartDash
            chartId='employment'
            data={employmentData}
            codeLangTag='A0054'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Link
                className='ico-absolute'
                to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/jobs`}>
                <Trans code='A0328' />
              </Link>
            </h5>
          </div>
        </div>
        <div className='chartBox impactChart smallImpactChart smallImpactChart'>
          <AnimatedDonutChartDash
            chartId='taxes'
            data={taxesData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Link
                className='ico-absolute'
                to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/taxes`}>
                <Trans code='A0326' />
              </Link>
            </h5>
          </div>
        </div>
        <div className='chartBox impactChart smallImpactChart smallImpactChart'>
          <AnimatedDonutChartDash
            chartId='incomes'
            data={incomesData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <h5 className='ico-absolute'>
              <Link
                className='ico-absolute'
                to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/incomes`}>
                <Trans code='A0327' />
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSummaryWheels;
