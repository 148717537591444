import React, { Component } from "react"

class Ico96 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-96" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
         <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
         <g transform="translate(17,29) scale(0.016)" fill="#000000" stroke="white" stroke-width="90">
            <path className="ico" d="M2345 4935 l-25 -24 0 -1186 0 -1186 25 -24 c13 -14 36 -25 51 -25
            28 0 2321 713 2366 737 60 30 49 81 -62 308 -129 263 -296 491 -516 705 -300
            291 -640 491 -1049 615 -201 61 -490 105 -688 105 -69 0 -81 -3 -102 -25z
            m425 -162 c767 -111 1453 -617 1796 -1326 26 -53 45 -99 43 -102 -3 -3 -1969
            -618 -2111 -661 -17 -5 -18 50 -18 1057 l0 1062 93 -7 c50 -4 139 -14 197 -23z"/>
            <path className="ico" d="M1955 4565 c-427 -99 -783 -290 -1089 -585 -328 -317 -543 -694 -650
            -1140 -87 -359 -69 -811 45 -1155 91 -274 303 -600 559 -855 250 -250 519
            -427 802 -527 536 -191 1102 -188 1611 9 68 26 81 35 93 64 8 18 11 41 7 51
            -4 10 -269 438 -590 951 l-583 934 0 1111 c-1 1177 1 1130 -47 1155 -29 16
            -40 15 -158 -13z m45 -1227 l0 -1071 560 -898 c308 -493 562 -903 565 -911 12
            -30 -306 -108 -520 -128 -371 -35 -769 35 -1100 194 -459 220 -937 749 -1089
            1208 -41 123 -71 263 -87 413 -14 133 -6 392 16 525 122 735 631 1366 1323
            1641 75 30 294 98 320 99 9 0 12 -221 12 -1072z"/>
            <path className="ico" d="M3610 2674 c-1261 -393 -1220 -378 -1207 -443 3 -15 286 -476 629
            -1025 512 -821 628 -1001 650 -1008 39 -13 91 15 246 131 755 567 1139 1499
            1002 2430 -35 238 -54 282 -119 280 -20 0 -560 -165 -1201 -365z m1166 51 c25
            -165 25 -506 1 -666 -61 -409 -218 -789 -463 -1119 -99 -133 -338 -373 -473
            -474 -95 -71 -106 -77 -117 -61 -34 46 -1116 1786 -1113 1789 2 2 184 60 404
            129 828 259 1563 490 1645 516 60 19 87 24 92 16 4 -6 15 -65 24 -130z"/>
         </g>
      </svg>
    );
  }
}
export default Ico96