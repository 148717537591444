import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button, Card, CardDeck, Nav, ButtonGroup, ToggleButton, Table } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { Trans } from '@lib/i18n'
import bsCustomFileInput from 'bs-custom-file-input';
import { getScenario, getAdminOrganization, getAdminRegion, getAdminSam, getAdminClasses, getBaseUrl, getDefaultHeaders, getUploadHeaders } from '@lib/api'
import StatusBar from "components/wizard/StatusBar";
import { Ico35, Ico36 } from "@icons/new";

const StepEconomics = ({ props, scen }) => {
   const [scenarioData, setScenarioData] = useState(scen)
   const [switchValue, setSwitchValue] = useState(2);
   const [valoriForm, setValoriForm] = useState(scenarioData.vettoriSpesa)
   const form = useRef(null)
   bsCustomFileInput.init();
   const [isSaved, setIsSaved] = useState(false)
   const [error, setError] = useState(null)
   const history = useHistory()
   const [idScenario, setIdScenario] = useState(null)
   const [status,setStatus]=useState(scenarioData.scenario.status)
   const [isEditing,setIsEditing]=useState(false)
   const [sbShowSuccess, setSbShowSuccess] = useState(false);
   const [sbShowError, setSbShowError] = useState(false);
   const [showWhat, setShowWhat] = useState("CAPEX");

   useEffect(() => {
      if (props.sector) {
         if (scenarioData.idScenario) {
            setIdScenario(scenarioData.idScenario)
            setStatus(scenarioData.scenario.status)
         }
      }
   }, [scenarioData.idScenario, props.sector]);

  const setSuccess = (bool) => {
    setSbShowSuccess(bool);
    if(bool) {
      const timer = setTimeout(() => {setSbShowSuccess(false) }, 5000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    }
  }
  
  //const[totals,setTotals] = useState({});
  let totals = {}

  const refreshValue = (value, oecode, y, val) => {       
    //if(!isEditing) {
      console.log(value, oecode, y, val)

      const valuetmp =  value
      const re = /^\d+\.?\d*$/;
      if (valuetmp === '' || re.test(valuetmp)) {
        valoriForm[val][oecode][y] = valuetmp //parseFloat(valuetmp) //.toFixed(2) || 0.0
      
        setValoriForm({ ...valoriForm })
      }
    //}    
  }

  const refreshValue2 = (value, oecode, y, val) => {       
    //if(!isEditing) {
      console.log(value, oecode, y, val)

      const valuetmp =  value
      valoriForm[val][oecode][y] = parseFloat(valuetmp).toFixed(2) || 0.0
      
        setValoriForm({ ...valoriForm })
      
    //}    
  }

  const setScenario=()=>{
   getScenario(idScenario)
     .then(data=>{
       setValoriForm({ ...data.vettoriSpesa })
    //   setValoriForm(data.vettoriSpesa)
      })
  }

  const submit = e => {
    e.preventDefault()
    setError(false)
    setIsSaved(false)
    setStatus('Draft')

    let capex=valoriForm.CAPEX
    let opex=valoriForm.OPEX
    let localshare=valoriForm.LOCALSHARE
    let revenues=valoriForm.REVENUES

    var i = 0 ;
    let mod=false
    for (const [key, value] of Object.entries(capex)) {
      for (const [key2, value2] of Object.entries(value)) {
        if(value2!=0){
          mod=true
        }

      }
    }
    for (const [key, value] of Object.entries(opex)) {
      for (const [key2, value2] of Object.entries(value)) {
        if(value2!=0){
          mod=true
        }

      }
    }
    for (const [key, value] of Object.entries(revenues)) {
      for (const [key2, value2] of Object.entries(value)) {
        if(value2!=0){
          mod=true
        }

      }
    }
    for (const [key, value] of Object.entries(localshare)) {
      for (const [key2, value2] of Object.entries(value)) {
        if(value2!=1){
          mod=true
        }

      }
    }
    if(!mod){
      alert('Attenzione: nessun dato inserito!')
      return false
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        ...getDefaultHeaders()
      },
      body: JSON.stringify({ idScenario, valoriForm, status }),
    };




    fetch(`${getBaseUrl()}/setupscenario_step3`, requestOptions)
    .then(response => response.json())
    .then(data => {
    //  setValoriForm({})
      setScenario()

      setIsSaved(true)
      setSuccess(true);


    })
    .catch(error => {
      console.error(error)
      setSbShowError(true);
    })
  }

  const submit1 = e => {

  };

  // document.querySelector('#fileUpload').addEventListener('change', event => {
  //   submitVettoreSpesa(event)
  //  })

  const submitVettoreSpesa = event => {
    console.log("event submitVettoreSpesa",event)
    event.preventDefault()
    console.log("event submitVettoreSpesa after",event)
    const files = document.querySelector('#fileUpload').files;
    const formData = new FormData()
    formData.append('myFile', files[0]) //myFile corrisponde lato backend
    console.log("files[0]",files[0])

    fetch(`${getBaseUrl()}/uploadVettoreSpesa/${idScenario}`, {
      method: 'POST',
      headers: {
        ...getUploadHeaders(),
      },
      body: formData
    })
    .then(response => response.json())
    .then(data => {

  //    setValoriForm({})
      console.log("success setScenarion")
      setScenario()
      console.log("success setisSaved")
      setIsSaved(true)
      setSuccess(true);



    })
    .catch(error => {
      console.log("upload error",error)
      setSbShowError(true);

    })

  }


  const getValueMeta = (id, arr = []) => {

    return (arr.filter(s => (s.value) === (id)).pop())
    //return (r => r.filter(s => parseInt(s.idScenario) === parseInt(value)).pop())
    //return r
  }
  
  const toggleEditing = () => {
    console.log(isEditing)
    setIsEditing(true)
    console.log(isEditing)
  }

  const CreaTabella = ({ val }) => {

    if (scenarioData.idScenario) {
      var constructionStartY = new Date(scenarioData.tech.construction_start).getFullYear()
      var operationEndY = new Date(scenarioData.tech.operation_end).getFullYear()


      var colsHeader = [];
      for (var y = constructionStartY; y <= operationEndY; y++) {
        const stileHeaderTabella = {
          width: "0px",
          maxWidth: "100px"
        };
        colsHeader.push(<th style={stileHeaderTabella} key={y}>{y}</th>);
      }


      const ColsBody = ({ oecode }) => {
        //console.log(oecode)
        var colsBody = [];
        //console.log(scenarioData.vettoriSpesa.CAPEX[oecode]['2021'])
        for (var y = constructionStartY; y <= operationEndY; y++) {
          // note: we are adding a key prop here to allow react to uniquely identify each
          // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
          var valName = 'valore[' + val + '][' + oecode + '][' + y + ']'
          const year = y
          //const value=0
          let valueP=valoriForm[val][oecode][y] || 0.0
              valueP=valueP.toLocaleString('it-IT')
          
          //valueP = parseFloat(valueP).toFixed(2)
          valoriForm[val][oecode][y] = valueP || 0.0
          let n;
          
          totals[val] === undefined ? totals[val] = {} : n=false;
          totals[val][y] === undefined ? totals[val][y] = 0 : n=false;
          totals[val][y] = totals[val][y] + parseFloat(valueP)
          
          

          const oecode2=oecode.replace("_","");

          colsBody.push(
            <td key={y} className="text-right" style={{maxWidth:80}}>
            <Form.Control type="text"  style={{width: "50px", textAlign: "right"}} placeholder="" name={valName} value={valueP} onBlur={({ target: { value } }) => refreshValue2(value, oecode, year, val)} onFocus={toggleEditing.bind(this)} onChange={({ target: { value } }) => refreshValue(value, oecode, year, val)} />
               
            </td>);
        }

        //setTotals(totals)
        return colsBody;
      }

      if (val == 'REVENUES') {
        var rowsTable = []
        var oecode = '_0'
        var capex = scenarioData.vettoriSpesa[val]

        rowsTable.push(<tr key={y}>
          <td className="value-label">Revenues</td>
          {ColsBody({oecode})}
        </tr>)

      } else {
        var rowsTable = []
        console.log(scenarioData)
        var capex = scenarioData.vettoriSpesa[val]
        Object.keys(capex)
          .map((oecode, i) => (
            (oecode!=='_399485')?
              (rowsTable.push(<tr key={i}>

                <td className="value-label">{getValueMeta((oecode.replace("_","")), props.oecodes).label}</td>
                 {ColsBody({oecode})}
              </tr>))
            : null

          )
          )

      }

      console.log(totals)
      return (
        <table className="investment-table">
   
          <tbody>
          <tr>
              <th className="value-label value-capex"><Trans code="WS008" /></th>
              {colsHeader}
            </tr>
            {val!=="LOCALSHARE" &&
           <tr>
            <td className="value-label"><strong>Totals</strong> </td>
            {Object.keys(totals[val]).map((year, i) => { return (
              <td id={y + '-' + i} key={y + '-' + i} className="text-right" style={{maxWidth:80}}><strong>{totals[val][year].toFixed(2)}</strong>  </td>
            )})} 
            </tr> 
            }
            {rowsTable}
          </tbody>
        </table>
      )
    }
  }

  if (!scenarioData) return null

  return (
   <>
      {sbShowSuccess 
         ? ( <StatusBar status="success" timeout={true} /> )
         : ( sbShowError ? <StatusBar status="error" timeout={false}/> : null )
      }

      {/* da abilitare dopo il funzionamento corretto di foglio eco */}
      {/* <Row className="p-3">
         <ButtonGroup style={{accentColor: '#08A2AA'}}>
            {window.location.href.includes("externalytics.com") &&
               <ToggleButton
               id="tbg-btn-1"
               type="radio"
               variant="secondary"
               name="radio"
               value={1}
               checked={switchValue === 1}
               onChange={(e) => setSwitchValue(Number(e.target.value))}
            >
               &nbsp;Foglio Eco
            </ToggleButton>
            }
            <ToggleButton
               id="tbg-btn-2"
               type="radio"
               variant="secondary"
               name="radio"
               value={2}
               checked={switchValue === 2}
               onChange={(e) => setSwitchValue(Number(e.target.value))}
            >
               &nbsp;Vettore di spesa
            </ToggleButton>
         </ButtonGroup>
      </Row> */}
    
      {switchValue === 1 ?
         <>  
            <div className="simple-box scenaritab" /* style={{width: '40%', marginLeft: 0}} */>
               <Table responsive className="foglio-eco-table" style={{display: 'table'}}>
                  <thead>
                     <tr>
                        <th></th>
                        <th className="text-right pr-0">anno1</th>
                        <th className="text-right pr-0">anno2</th>
                        <th className="text-right pr-0">anno3</th>
                     </tr>
                  </thead>
                  <tbody style={{display: 'table-row-group'}}>
                     <tr>
                        <th style={{backgroundColor: '#1c2743', color: 'white', padding: '0.5rem'}}>CAPEX</th>
                        <td></td>
                        <td></td>
                        <td></td>
                     </tr>
                     <tr>
                        <td>Attrezzature</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>
                     <tr>
                        <td className="pb-4" >Opere civili</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>

                     <tr>
                        <th style={{backgroundColor: '#1c2743', color: 'white', padding: '0.5rem'}}>LOCAL CAPEX</th>
                        <td></td>
                        <td></td>
                        <td></td>
                     </tr>
                     <tr>
                        <td>Attrezzature</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>
                     <tr>
                        <td className="pb-4" >Opere civili</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>

                     <tr>
                        <th style={{backgroundColor: '#1c2743', color: 'white', padding: '0.5rem'}}>OPEX</th>
                        <td></td>
                        <td></td>
                        <td></td>
                     </tr>
                     <tr>
                        <td>Personale</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>
                     <tr>
                        <td className="pb-4" >Manutenzione</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>

                     <tr>
                        <th style={{backgroundColor: '#1c2743', color: 'white', padding: '0.5rem'}}>REVENUES</th>
                        <td></td>
                        <td></td>
                        <td></td>
                     </tr>
                     <tr>
                        <td className="pb-4" >Personale</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>
                     
                     <tr>
                        <th style={{backgroundColor: '#1c2743', color: 'white', padding: '0.5rem'}}>RESIDUAL VALUE</th>
                        <td></td>
                        <td></td>
                        <td></td>
                     </tr>
                     <tr>
                        <td className="pb-4">Valore residuo</td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore1" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore2" /></td>
                        <td><Form.Control type="text" style={{width: "auto", textAlign: "right", marginLeft: 'auto'}} placeholder="valore3" /></td>
                     </tr>
                  </tbody>
               </Table>
            </div>
         </> :
         <div className="row">
            <CardDeck>
               {/**TEMPLATE BAR*/}
               <div className="col-12">
                  <Card className="cards-utente d-none d-md-block">
                     <Card.Body>
                        {/*<Ico36 className="logo-card ico-box-blue" />*/}
                        <Row>
                           <div className="col-4">       
                              <a href={getBaseUrl(true) + '/export_vettore_spesa/' + idScenario} target="_blank" rel="noreferrer"> 
                                 <h5>Download Template</h5> 
                              </a> 
                           </div>
                           <div className="col-8">
                              <Form id="template-upload-form">
                                 <Form.Group as={Col} controlId="image1">
                                    {/*<Form.Control type="file" placeholder="" name="image[image1]" id="fileUpload" />*/}
                                    <div className="input-group">
                                       <div className="custom-file">
                                          <input type="file" className="custom-file-input" accept="application/vnd.ms-excel, .xlsx"  name="image[image1]" id="fileUpload" />
                                          <label className="custom-file-label" htmlFor="fileUpload"></label>
                                       </div>
                                       <div className="input-group-append">
                                          <button className="btn btn-outline-info upload" onClick={submitVettoreSpesa} >Upload & Save</button>
                                       </div>
                                    </div>
                                 </Form.Group>
                              </Form>
                           </div>
                        </Row>
                     </Card.Body>
                  </Card>
               </div>
               <Form onSubmit={submit} id="form-economics">
                  <div className="col-12">
                     <Card className="cards-utente d-none d-md-block card-table">
                        <Card.Body>
                           {/*<Ico35 className="logo-card ico-box-blue" />*/}
                           <Card.Title>
                              <Nav variant="tabs" defaultActiveKey="/home">
                                 <Nav.Item>
                                    <Nav.Link eventKey="CAPEX" className={showWhat === "CAPEX"? 'nav active' : 'nav'} onClick={() => setShowWhat("CAPEX") }>Capex</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item>
                                    <Nav.Link eventKey="LOCALSHARE" className={showWhat === "LOCALSHARE"? 'nav active' : 'nav'} onClick={() => setShowWhat("LOCALSHARE") }>Local Share</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item>
                                    <Nav.Link eventKey="OPEX" className={showWhat === "OPEX"? 'nav active' : 'nav'} onClick={() => setShowWhat("OPEX") }>Opex</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item>
                                    <Nav.Link eventKey="REVENUES" className={showWhat === "REVENUES"? 'nav active' : 'nav'} onClick={() => setShowWhat("REVENUES") } >Revenues</Nav.Link>
                                 </Nav.Item>
                              </Nav>              
                           </Card.Title>
                           <div className="main-wrapper">
                              {showWhat === "CAPEX" && CreaTabella({ val: 'CAPEX' }) }
                              {showWhat === "LOCALSHARE" && CreaTabella({ val: 'LOCALSHARE' }) }
                              {showWhat === "OPEX" && CreaTabella({ val: 'OPEX' }) }
                              {showWhat === "REVENUES" && CreaTabella({ val: 'REVENUES' }) }
                           </div>
                        </Card.Body>
                     </Card>
                  </div>
                  
                  <Button form="form-economics" type="submit" className="submit-button sticky-footer-button"><Trans code="F00046"/></Button>
               </Form>
            </CardDeck>
         </div>
      }
      
   </>
  );
}
export default StepEconomics;
