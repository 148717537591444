import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n'
import { getBaseUrl, getDefaultHeaders, getScenario } from '@lib/api'
//import { useHistory } from "react-router-dom";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash } from '@fortawesome/free-solid-svg-icons';
import StatusBar from "components/wizard/StatusBar";

const initialState = {
   main_conclusion: '',
   economic_analysis: '',
   financial_analysis: '',
   comment_results: '',
   investment: '',
   gdp: '',
   production: '',
   taxes: '',
   incomes: '',
   employment: '',
   sdg: '',
   kpi: '',
};

const StepComment = ({ props, scen }) => {
   const [scenarioData, setScenarioData] = useState(scen)
   const [scenarioImmagine, setScenarioImmagine] = useState((scenarioData.additionalInfo ? (scenarioData.additionalInfo.immagine ?? []) : []));
   //const [scenarioImmagineText, setScenarioImmagineText] = useState((scenarioData.additionalInfo ? (scenarioData.additionalInfo.immagine_text ?? [] ) :  [] ));
   //const scenarioImmagine=(scenarioData.additionalInfo.immagine??[])
   const [isSaved, setIsSaved] = useState(false)
   const [error, setError] = useState(null)
   const [form, setForm] = useState(initialState)
   //const [files, setFiles] = useState([])
   //const history = useHistory()
   const formData = new FormData()
   const [idScenario, setIdScenario] = useState(null)
   const [sbShowSuccess, setSbShowSuccess] = useState(false);
   const [sbShowError, setSbShowError] = useState(false);
   console.log('form:', form);

   /* const setUploadFile = (file) => {
      formData.append(file.name, file.files[0])
   } */

   const setScenario = () => {
      getScenario(idScenario)
         .then(setScenarioData)
         .then(data => {
            setScenarioImmagine(scenarioData.additionalInfo.immagine ?? [])
         })
   }

   /* const eliminaImmagine = (immagine) => {
      fetch(`${getBaseUrl()}/deletefileinfoimg/${immagine}`, {
         method: 'GET',
         headers: {
            ...getDefaultHeaders()
         },
         //body: JSON.stringify({ form }),

      }).then(response => response.json())
         .then(data => {
            setScenario()
            setIsSaved(true)
         })
         .catch(error => {
            console.error(error)
         })
   } */

   /* const delSource = (idInfo) => {
      fetch(`${getBaseUrl()}/deleteSource/${idInfo}`, {
         method: 'GET',
         headers: {
            ...getDefaultHeaders()
         },
         //body: JSON.stringify({ form }),
      })
         .then(response => response.json())
         .then(data => {
            setScenario()
            setIsSaved(true)
         })
         .catch(error => {
            console.error(error)
         })
   } */


   /* const ImgPreview = (imm) => {
      if (imm) {

         return <Row><Col><img className="m-auto img-card w-25" src={`${getBaseUrl()}/fileimg/${imm}`} alt="" /></Col><Col><Button onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) eliminaImmagine(imm) }}>Elimina</Button></Col></Row>
      } else {
         return false
      }
   } */

   /* const DeleteSource = ({ idSource }) => {
      if (idSource) {
         return <Col><FontAwesomeIcon icon={faTrash} className="bluicon ml-2 float-right" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) delSource(idSource) }} /></Col>
      } else {
         return false
      }
   } */

   const submitSixthStep = event => {
      event.preventDefault()
      setIsSaved(false)

      /* Object.keys(form).map(function (i) {
         if (!form[i].immagine && i != 'link_attach' && i != 'files')
            //  console.log(form[i])
            formData.append(i, form[i])
      }) */

      //console.log('form create:', form)

      fetch(`${getBaseUrl()}/setupscenario_stepcomments/${idScenario}`, {
         method: 'POST',
         headers: {
            ...getDefaultHeaders()
         },
         //body: formData  //se con files
         body: JSON.stringify(form),

      })
         .then(response => response.json())
         .then(text => {
            setScenario()
            setSbShowSuccess(true);
            setIsSaved(true)
         })
         .catch(error => {
            setError(true)
            setSbShowError(true);

            // It is text, do you text handling here
         });
   }




   const refreshForm = ({ name, value }) => {
      const newArr = form;
      // const newData={name:value}

      //    if (typeof newArr[name] !== 'undefined') {

      newArr[name] = value

      setForm({ ...newArr })

      ///  console.log(newArr)
   }

   /* const refreshFormImm = ({ name, value }) => {
      const newArr = form;
      // const newData={name:value}

      //    if (typeof newArr[name] !== 'undefined') {

      newArr['immagine_text'][name] = value
      newArr['immagine_text[' + name + ']'] = value

      setForm({ ...newArr })

   } */

   useEffect(() => {

      if (scenarioData.idScenario) {
         console.log('scenarioData.additionalInfo', scenarioData.additionalInfo);
         setForm(() => ({
            ...form,
            main_conclusion: scenarioData.additionalInfo.main_conclusion ?? '',
            economic_analysis: scenarioData.additionalInfo.economic_analysis ?? '',
            financial_analysis: scenarioData.additionalInfo.financial_analysis ?? '',
            comment_results: scenarioData.additionalInfo.comment_results ?? '',
            investment: scenarioData.additionalInfo.investment ?? '',
            gdp: scenarioData.additionalInfo.gdp ?? '',
            production: scenarioData.additionalInfo.production ?? '',
            taxes: scenarioData.additionalInfo.taxes ?? '',
            incomes: scenarioData.additionalInfo.incomes ?? '',
            employment: scenarioData.additionalInfo.employment ?? '',
            sdg: scenarioData.additionalInfo.sdg ?? '',
            kpi: scenarioData.additionalInfo.kpi ?? '',
         }));

         setIdScenario(scenarioData.idScenario)
         if (scenarioData.additionalInfo.immagine) {
            setScenarioImmagine(scenarioData.additionalInfo.immagine ?? [])
            //        setScenarioImmagineText(scenarioData.additionalInfo.immagine_text??[])
            //console.log(form)

         }
      }

   }, [scenarioData.idScenario, /* refreshForm */])

   //(`idscenario`, `topic`='purpose', `lang`=1, `type`='text', `name`='', `ref`='', `text`=value, `display`=null)


   if (!scenarioData) return null


   return (
      <>
         {sbShowSuccess
            ? (<StatusBar status="success" timeout={true} />)
            : (sbShowError ? <StatusBar status="error" timeout={false} /> : null)
         }



         <Form onSubmit={submitSixthStep} id="form-comment">


            <div className="simple-box" style={{ marginTop: '40px' }}>
               <Row>
                  <Col lg={12} className="form-login">
                     <h5 className="title-simple-box margin-title-box"><Trans code="A0533"/></h5>
                     <Row className="mb-3">
                        <Form.Group as={Col} controlId="main_conclusion">
                           <Form.Label><Trans code="A0352" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="main_conclusion"
                              placeholder={propTrans({prop: "A0535"})}
                              value={form['main_conclusion']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                        <Form.Group as={Col} controlId="economic_analysis">
                           <Form.Label><Trans code="A0394" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="economic_analysis"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['economic_analysis']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                        <Form.Group as={Col} controlId="financial_analysis">
                           <Form.Label><Trans code="A0395" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="financial_analysis"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['financial_analysis']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                     </Row>
                  </Col>
               </Row>
            </div>
            <div className="simple-box" style={{ marginTop: '40px' }}>
               <Row>
                  <Col lg={12} className="form-login">
                     <h5 className="title-simple-box margin-title-box"><Trans code="A0534"/></h5>


                     <Row className="mb-3">
                        <Form.Group as={Col} controlId="comment_results">
                           <Form.Label><Trans code="A0352" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="comment_results"
                              placeholder={propTrans({prop: "A0535"})}
                              value={form['comment_results']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                     </Row>
                     <Row className="mb-3">
                        <Form.Group as={Col} controlId="investment">
                           <Form.Label><Trans code="A0329" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="investment"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['investment']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                        <Form.Group as={Col} controlId="gdp">
                           <Form.Label><Trans code="A0324" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="gdp"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['gdp']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                     </Row>
                     {/* <Row className="mb-3">
                    <Form.Group as={Col} controlId="image_inv_1">
                      {(scenarioImmagine.investment && scenarioImmagine.investment[1]) && ImgPreview(scenarioImmagine.investment[1])}
                      <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[investment_1]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_inv_2">
                      {(scenarioImmagine.investment && scenarioImmagine.investment[2]) && ImgPreview(scenarioImmagine.investment[2])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[investment_2]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_gdp_1">
                      {(scenarioImmagine.gdp && scenarioImmagine.gdp[1]) && ImgPreview(scenarioImmagine.gdp[1])}
                     <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[gdp_1]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_gdp_2">
                        {(scenarioImmagine.gdp && scenarioImmagine.gdp[2]) && ImgPreview(scenarioImmagine.gdp[2])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[gdp_2]" />
                    </Form.Group>

                  </Row>*
                  <Row className="mb-3">
                  <Form.Group as={Col} controlId="immagine_text[investment_1]">
                        <Form.Control
                          as="textarea"
                          name="investment_1"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.investment_1:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[investment_2]">
                        <Form.Control
                          as="textarea"
                          name="investment_2"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.investment_2:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[gdp_1]">
                        <Form.Control
                          as="textarea"
                          name="gdp_1"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.gdp_1:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[gdp_2]">
                        <Form.Control
                          as="textarea"
                          name="gdp_2"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.gdp_2:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                  </Row>*/}
                     <Row className="mb-3">
                        <Form.Group as={Col} controlId="production">
                           <Form.Label><Trans code="A0049" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="production"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['production']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                        <Form.Group as={Col} controlId="taxes">
                           <Form.Label><Trans code="A0286" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="taxes"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['taxes']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                     </Row>
                     {/*<Row className="mb-3">
                    <Form.Group as={Col} controlId="image_prod_1">
                        {(scenarioImmagine.production && scenarioImmagine.production[1]) && ImgPreview(scenarioImmagine.production[1])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[production_1]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_prod_2">
                        {(scenarioImmagine.production && scenarioImmagine.production[2]) && ImgPreview(scenarioImmagine.production[2])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[production_2]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_tax_1">
                        {(scenarioImmagine.taxes && scenarioImmagine.taxes[1]) && ImgPreview(scenarioImmagine.taxes[1])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[taxes_1]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_tax_2">
                        {(scenarioImmagine.taxes && scenarioImmagine.taxes[2]) && ImgPreview(scenarioImmagine.taxes[2])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[taxes_2]" />
                    </Form.Group>

                  </Row> 
                  <Row className="mb-3">
                  <Form.Group as={Col} controlId="immagine_text[production_1]">
                        <Form.Control
                          as="textarea"
                          name="production_1"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.production_1:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[production_2]">
                        <Form.Control
                          as="textarea"
                          name="production_2"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.production_2:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[taxes_1]">
                        <Form.Control
                          as="textarea"
                          name="taxes_1"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.taxes_1:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[taxes_2]">
                        <Form.Control
                          as="textarea"
                          name="taxes_2"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.taxes_2:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                  </Row>*/}
                     <Row className="mb-3">
                        <Form.Group as={Col} controlId="incomes">
                           <Form.Label><Trans code="A0051" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="incomes"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['incomes']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                        <Form.Group as={Col} controlId="employment">
                           <Form.Label><Trans code="A0052" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="employment"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['employment']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                     </Row>
                     {/* <Row className="mb-3">
                    <Form.Group as={Col} controlId="image_red_1">
                        {(scenarioImmagine.incomes && scenarioImmagine.incomes[2]) && ImgPreview(scenarioImmagine.incomes[1])}
                       <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[incomes_1]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_red_2">
                        {(scenarioImmagine.incomes && scenarioImmagine.incomes[2]) && ImgPreview(scenarioImmagine.incomes[2])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[incomes_2]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_occ_1">
                        {(scenarioImmagine.employment && scenarioImmagine.employment[2]) && ImgPreview(scenarioImmagine.employment[1])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[employment_1]" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="image_tax_2">
                        {(scenarioImmagine.employment && scenarioImmagine.employment[2]) && ImgPreview(scenarioImmagine.employment[2])}
                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[employment_2]" />
                    </Form.Group>

                  </Row>
                  <Row className="mb-3">
                  <Form.Group as={Col} controlId="immagine_text[incomes_1]">
                        <Form.Control
                          as="textarea"
                          name="immagine_text[incomes_1]"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.incomes_1:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[incomes_2]">
                        <Form.Control
                          as="textarea"
                          name="immagine_text[incomes_2]"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.incomes_2:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[employment_1]">
                        <Form.Control
                          as="textarea"
                          name="immagine_text[employment_1]"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.employment_1:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[employment_2]">
                        <Form.Control
                          as="textarea"
                          name="immagine_text[employment_2]"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.employment_2:''}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                  </Row>*/}
                     <Row className="mb-3">
                        <Form.Group as={Col} controlId="sdg">
                           <Form.Label><Trans code="A0133" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="sdg"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['sdg']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                        <Form.Group as={Col} controlId="kpi">
                           <Form.Label><Trans code="C00501" /></Form.Label>
                           <Form.Control
                              as="textarea"
                              name="kpi"
                              placeholder={propTrans({prop: 'A0536'})}
                              value={form['kpi']}
                              style={{ height: '100px' }}
                              onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                           />
                        </Form.Group>
                     </Row>
                  </Col>
               </Row>
            </div>

            <Button form="form-comment" type="submit" className="submit-button sticky-footer-button"><Trans code="F00046"/></Button>
         </Form>
      </>

   );

}

export default StepComment;
