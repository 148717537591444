import { useState, useEffect } from 'react';
import { rounder, percent, isNumeric } from '@lib/helpers';
import { Card } from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import ShareBar from '@components/scenario/ShareBar';

import { Ico35 } from '@icons/new';

const SectorTable = ({ data, filterForRegion, tableLegendCode = '', shareBarVisible = false }) => {
  const [newData, setNewData] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState(1);

  console.log('SECTOR', data);
  const max = parseFloat(
    Math.max.apply(
      Math,
      data.map((o) => o?.progress)
    )
  );
  useEffect(() => {
    let filter = filterForRegion === 'total' ? '' : filterForRegion;
    setNewData(
      data.filter((el) => {
        return el.region === filter;
      })
    ); //to filter
  }, [data, filterForRegion]);
  const handleOrderBy = (key) => () => {
    if (orderBy === key) setOrder(order * -1);
    else {
      setOrder(-1);
      setOrderBy(key);
    }
  };
  return (
    <Card className='cards-utente d-none d-md-block card-table'>
      <Card.Body>
        <Ico35 className='logo-card ico-box-blue' />
        <Card.Title>
          <Trans code='A0177' />
        </Card.Title>
        <table className='investment-table'>
          <thead>
            <tr>
              {/* <th className='value-label' onClick={handleOrderBy('code')}>
                <Trans code='A0191' />
              </th> */}
              <th className='value-label' onClick={handleOrderBy('propertyName')}>
                <Trans code='A0068' />
              </th>
              <th onClick={handleOrderBy('propertyValue')}>
                <Trans code='A0137' />
              </th>
              {!!shareBarVisible && (
                <>
                  <th onClick={handleOrderBy('share')}>
                    <Trans code='A0193' />
                  </th>
                  <th></th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {newData
              .sort((a, b) => {
                if (!orderBy) return 0;
                if (isNumeric(a[orderBy]) && isNumeric(b[orderBy])) {
                  a[orderBy] = parseFloat(a[orderBy]);
                  b[orderBy] = parseFloat(b[orderBy]);
                }
                if (order === -1) {
                  if (a[orderBy] < b[orderBy]) return -1;
                  if (a[orderBy] > b[orderBy]) return 1;
                  return 0;
                } else {
                  if (a[orderBy] < b[orderBy]) return 1;
                  if (a[orderBy] > b[orderBy]) return -1;
                  return 0;
                }
              })
              .map((row, index) => (
                <tr key={index}>
                  <td className='value-label'>{row.propertyName}</td>
                  <td>{rounder(row.propertyValue)}</td>
                  {!!shareBarVisible && (
                    <>
                      <td>{percent(row?.progress * 100)}</td>
                      <td className='value-label'>
                        <ShareBar max={max * 100} value={row?.progress * 100} width={50} />
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        <p>
          <Trans code={tableLegendCode} className='va-desc' />
        </p>
      </Card.Body>
    </Card>
  );
};

export default SectorTable;
