import React from 'react';
import { Button } from 'react-bootstrap';
const ExternalLinkButton = ({ url, buttonText, icon }) => {
  const openLink = () => {
    window.open(url, '_blank');
  };

  return (
    <Button className='font-weight-normal text-capitalize' variant='link' onClick={openLink}>
      <div className='d-flex justify-content-center align-items-center'>
        <span>{icon}</span>
        <span>{buttonText}</span>
      </div>
    </Button>
  );
};

export default ExternalLinkButton;
