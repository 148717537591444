import React, { useEffect, useState } from 'react';

const ImageBase64Converter = ({ base64Image, className }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (base64Image) {
      const image = new Image();
      image.src = base64Image;
      image.onload = () => setImageSrc(image.src);
    }
  }, [base64Image]);

  if (!imageSrc) {
    return null; // Or display a placeholder while the image is loading
  }

  return (
    <>
      <img className={className} src={imageSrc} alt='Organization-logo' />
    </>
  );
};

export default ImageBase64Converter;
