import ReactDOM from 'react-dom';

const containerStyle = {
  display: 'inline-block',
  position: 'absolute',
  visibility: 'hidden',
  zIndex: -1,
};

const measureElement = (element) => {
  const container = document.createElement('div');
  container.style = containerStyle;
  document.body.appendChild(container);

  ReactDOM.render(element || null, container);

  // Use querySelector without ":first-child" to get the first child element
  const firstChild = container.querySelector(':first-child');

  if (firstChild) {
    const { height, width } = firstChild.getBoundingClientRect();

    ReactDOM.unmountComponentAtNode(container);
    container.parentNode.removeChild(container);

    return { height, width };
  } else {
    return { height: 0, width: 0 };
  }
};

export default measureElement;
