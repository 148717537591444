import { useEffect, useState } from 'react';
import InvestmentChart from '@components/scenariodashboard/investment/InvestmentChart';
import InvestmentImpact from '@components/scenariodashboard/investment/InvestmentImpact';
import InvestmentTable from '@components/scenariodashboard/investment/InvestmentTable';
// {
//   "LOCALCAPEX": {
//     "total": 0,
//     "va": 0,
//     "val": {
//       "2026": 0,
//       "2027": 0,
//       "2028": 0,
//       "2029": 0,
//       "2030": 0
//     },
//     "localperc": 100,
//     "local": 0
//   },
//   "OPEX": {
//     "total": 417.12,
//     "va": 359.28,
//     "val": {
//       "2026": 73.39,
//       "2027": 83.19,
//       "2028": 85.63,
//       "2029": 86.85,
//       "2030": 88.06
//     },
//     "localperc": 100,
//     "local": 417.12
//   },
//   "total": {
//     "total": 417.12,
//     "va": 359.28,
//     "val": {
//       "2026": 73.39,
//       "2027": 83.19,
//       "2028": 85.63,
//       "2029": 86.85,
//       "2030": 88.06
//     },
//     "localperc": 200,
//     "local": 417.12
//   },
//   "TOTALCAPEX": "0"
// }

const Investment = ({ invest, filterForRegion }) => {
  const [investmentFiltered, setInvestmentFiltered] = useState([]);
  const [passToChart, setPassToChart] = useState([]);
  const [rows, setRows] = useState([]);
  // console.log('INVBYTYPE', invest.expByType);
  useEffect(() => {
    setInvestmentFiltered(
      invest.expByType.filter((el) => {
        return el.region === '';
      })
    ); //to filter
  }, [invest, filterForRegion]);

  useEffect(() => {
    let year = [];
    let tempYear;
    investmentFiltered.forEach((el) => {
      if (el.propertyName === 'Total' || el.propertyName === 'PV') return;
      year.push(el.propertyName);
    });
    const final = Array.from(new Set(year));
    setPassToChart(final);
  }, [investmentFiltered]);

  // useEffect(() => {
  //   let row = {};
  //   let tempTotal;
  //   let tempVal = {};
  //   investmentFiltered.forEach((el) => {
  //     if (el.propertyName === 'Total' || el.propertyName === 'PV') return;
  //     year.push(el.propertyName);
  //   });
  //   const final = Array.from(new Set(year));
  //   setPassToChart({ year: final });
  // }, [passToChart, investmentFiltered]);

  const keys = ['LOCALCAPEX', 'OPEX'];
  useEffect(() => {
    let arr = [];
    let str = '';
    let mis = [];
    keys.forEach((k) => {
      investmentFiltered
        .filter((el) => el.category === k)
        ?.forEach((el) => {
          if (k === el.category && el.propertyName !== 'Total' && el.propertyName !== 'PV') {
            str = k;
            mis.push(el.propertyValue);
          }
        });
      // .forEach((inv) => {
      //   console.log('ololo', [
      //     k,
      //     ...passToChart.year.map((y) => (inv.propertyName === y ? inv.propertyValue : 0)),
      //     arr.push(inv.propertyValue),
      //   ]);
      //   // return [k, passToChart?.map((y) => (inv.propertyName === y ? inv.propertyValue : 0))];
      // });
      arr.push([str, ...mis]);
      str = '';
      mis = [];
    });
    // setRows(arr);
    console.log('ARR', arr);
    setRows(arr);
  }, [passToChart, investmentFiltered]);

  return (
    <div>
      <InvestmentImpact invest={invest} />
      <div className='row'>
        {rows.length > 0 && passToChart.length > 0 && (
          <InvestmentChart rows={rows} year={passToChart} />
        )}
      </div>
      <div className='row'>
        <InvestmentTable invest={investmentFiltered} years={passToChart} />
      </div>
    </div>
  );
};

export default Investment;
