import React, { useState, useEffect } from "react";
import { Table } from 'react-bootstrap';
import { CloudArrowDown, Link } from '@icons';
import { Ico19, Ico8, Ico29, Ico42 } from "@icons/new";
import { Trans } from '@lib/i18n';
/* eslint-disable jsx-a11y/anchor-is-valid */

const initialState = {
   context: '',                      
   institutional: '',                       
   shock: '',                           
   model: '',                          
   modelass: '',                      
   files: [],                      
   link_attach: [],    
   
   image1: '',
   image1_text: '',
   image2: '',
   image2_text: '',
   base_url: '',
};

const DashboardMeta = ({ dashboard, additionalInfo }) => {
  const [dashboardElements, setDashboardElements] = useState(initialState);
  const [economicDivLength, setEconomicDivLength] = useState(null);
  const [politicalDivLength, setPoliticalDivLength] = useState(null);
  const [shockDivLength, setShockDivLength] = useState(null);
  const [modelDivLength, setModelDivLength] = useState(null);
  const boxHeight = '6.25rem';

  const firstDiv = document.getElementById('context');
  const secondDiv = document.getElementById('institutional');
  const thirdDiv = document.getElementById('shock');
  const fourthDiv = document.getElementById('model');

  //console.log('economicDivLength', economicDivLength);

  useEffect(() => {
    setDashboardElements(() => ({
      ...dashboardElements,
      context: additionalInfo.context ?? dashboard.economicFramework,                      
      institutional: additionalInfo.institutional ?? dashboard.politicalInstitutionalLegalFramework,                  
      shock: additionalInfo.shock ?? dashboard.shockDescriptionSizingTiming,                       
      model: additionalInfo.model ?? dashboard.selectedModelDescription,                       
      modelass: additionalInfo.modelass ?? dashboard.finetuneMainAssumptions,                   
      files: additionalInfo.files ?? dashboard.attach,                     
      link_attach: additionalInfo.link_attach ?? dashboard.links,
      image1: additionalInfo.image1 ?? dashboard.image1,
      image1_text: additionalInfo.image1_text ?? dashboard.image1_text,
      image2: additionalInfo.image2 ?? dashboard.image2,
      image2_text: additionalInfo.image2_text ?? dashboard.image2_text,
      base_url: additionalInfo.base_url ?? dashboard.base_url,
   }));
   //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, additionalInfo]);

  useEffect(() => {
    if (firstDiv) { setEconomicDivLength(firstDiv.innerHTML.length) };
    if (secondDiv) { setPoliticalDivLength(secondDiv.innerHTML.length) };
    if (thirdDiv) { setShockDivLength(thirdDiv.innerHTML.length) };
    if (fourthDiv) { setModelDivLength(fourthDiv.innerHTML.length) };
  }, [firstDiv, secondDiv, thirdDiv, fourthDiv]);

  const hideCol = (colId, text, rowId) => {
    const parent = document.getElementById(colId + 'Parent');

    const childrenOfRowId = [...document.getElementById(rowId).children];

    const visibleChildren = childrenOfRowId.filter((el) => !el.className.includes('d-none'));

    if (!Array.isArray(text) && text.length === 0) {
      parent.style.display = 'none';

      visibleChildren.forEach(el => {
        el.classList.remove("col-xl-6");
        el.classList.add("col-xl-12");
      })
    } else if (Array.isArray(text) && text.reduce((prev, curr) => prev + curr, 0) === 0) {
      parent.style.display = 'none';

      visibleChildren.forEach(el => {
        el.classList.remove("col-xl-6");
        el.classList.add("col-xl-12");
      })
    };
  };

  const showHideBox = (boxId, buttonId) => {
    const element = document.getElementById(boxId);
    const button = document.getElementById(buttonId);

    //show
    if (element.style.height === boxHeight) {
      element.style.height = 'inherit';
      button.innerHTML = 'Show Less...';
      element.classList.add('fade-in-div');
      element.classList.remove('fade-out-div');
      element.classList.remove('showhide-box');
    } else { //hide
      element.style.height = boxHeight;
      button.innerHTML = 'Show More...';
      element.classList.remove('fade-in-div');
      element.classList.add('fade-out-div');
      element.classList.add('showhide-box');
    }
  };

  return (
    <>
      <div className={(dashboardElements.image1 && dashboardElements.image2) ? 'row d-flex' : 'd-none'}>
        <div className="col-12 col-xl-6 container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{dashboardElements.image1_text}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
              <img className="m-auto img-card" src={`${dashboardElements.base_url}${dashboardElements.image1}`} alt="" />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{dashboardElements.image2_text}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
              <img className="m-auto img-card" src={`${dashboardElements.base_url}${dashboardElements.image2}`} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex" id="first-row">
        <div className="col-12 col-xl-6 container-card" id="contextParent">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico8 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box"><Trans code="A0350" /></h5>
              </div>
            </div>

            <div className="row">
              <div className="col showhide-box" id='context' style={{ height: boxHeight, overflow : 'hidden'}} dangerouslySetInnerHTML={{ __html: ( dashboardElements.context ?? '').replace(/\n/g, '<br />') }} />
            </div>

            {economicDivLength !== null && economicDivLength > 200 ?
              <div className="row">
                <div className="col mt-2" >
                  <a className="showhide-button" id='contextButton' onClick={() => showHideBox('context','contextButton')}>Show More...</a>
                </div>
              </div> : <></>
            }
          </div>
          {Object.keys(dashboardElements).length > 0 && document.getElementById('context') ? hideCol('context', dashboardElements.context, "first-row") : null}
        </div>
        <div className="col-12 col-xl-6 container-card" id="institutionalParent">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico8 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box"><Trans code="A0167" /></h5>
              </div>
            </div>
            <div className="row">
              <div className="col showhide-box" id ='institutional' style={{ height: boxHeight, overflow : 'hidden'}} dangerouslySetInnerHTML={{ __html: ( dashboardElements.institutional || '').replace(/\n/g, '<br />') }} />
            </div>

            {politicalDivLength !== null && politicalDivLength > 200 ?
              <div className="row">
                <div className="col mt-2" >
                  <a className="showhide-button" id='institutionalButton' onClick={() => showHideBox('institutional','institutionalButton')}>Show More...</a>
                </div>
              </div> : <></>
            }
          </div>
          {Object.keys(dashboardElements).length > 0 && document.getElementById('institutional') ? hideCol('institutional', dashboardElements.institutional, "first-row") : null}
        </div>
      </div>
      <div className="row d-flex" id="second-row">
      <div className="col-12 col-xl-6 container-card" id="shockParent">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico19 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box"><Trans code="A0351" /></h5>
              </div>
            </div>

            <div className="row">
              <div className="col showhide-box" id ='shock' style={{ height: boxHeight, overflow : 'hidden'}} dangerouslySetInnerHTML={{ __html: ( dashboardElements.shock || '').replace(/\n/g, '<br />') }} />
            </div>

            {shockDivLength !== null && shockDivLength > 200 ?
              <div className="row">
                <div className="col mt-2" >
                  <a className="showhide-button" id='shockButton' onClick={() => showHideBox('shock','shockButton')}>Show More...</a>
                </div>
              </div> : <></>
            }
          </div>
          {Object.keys(dashboardElements).length > 0 && document.getElementById('shock') ? hideCol('shock', dashboardElements.shock, "second-row") : null}
        </div>
        <div className="col-12 col-xl-6 container-card" id="modelParent">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico19 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box"><Trans code="A0097" /></h5>
              </div>
            </div>

            <div className="row">
              <div className="col showhide-box" id='model' style={{ height: boxHeight, overflow : 'hidden'}}>
                {dashboardElements.model ? <div dangerouslySetInnerHTML={{ __html: ( dashboardElements.model || '').replace(/\n/g, '<br />') }} /> : null}
                {dashboardElements.modelass ? <div dangerouslySetInnerHTML={{ __html: ( dashboardElements.modelass || '').replace(/\n/g, '<br />') }} /> : null}
              </div>
            </div>

            {modelDivLength !== null && modelDivLength > 200 ? 
            <div className="row">
              <div className="col mt-2" >
                <a className="showhide-button" id='modelButton' onClick={() => showHideBox('model','modelButton')}>Show More...</a>
              </div>
            </div> : <></>
            }
          </div>
          {Object.keys(dashboardElements).length > 0 && document.getElementById('model') ? hideCol('model', [dashboardElements.model.length, dashboardElements.modelass.length], "second-row") : null}
        </div>
      </div>
      {dashboardElements.files.length === 0 && dashboardElements.link_attach.length === 0 ? null : 
      <>
         <div className="simple-box">
            <div className="row">
               <div className="col">
                  <Ico29 className="logo-card ico-box-blue" />
                  <h5 className="title-simple-box margin-title-box"><Trans code="F0071" /></h5>
               </div>
            </div>
            <div className="row">
               <div className="col">
                  <Table>
                  <tbody>
                     {dashboardElements.files.map(a => (
                        <tr key={a.id}>
                        <td><a style={{ color: 'black' }} href={`${window.location.href.includes("externalytics.com") ? dashboardElements.base_url.replace('stage.', '') : dashboardElements.base_url}${a.filename}`} target="_blank" rel="noreferrer">{a.description}</a></td>
                        <td><a style={{ color: 'black' }} href={`${window.location.href.includes("externalytics.com") ? dashboardElements.base_url.replace('stage.', '') : dashboardElements.base_url}${a.filename}`} target="_blank" rel="noreferrer"><CloudArrowDown /></a></td>
                        </tr>
                     ))}
                     {dashboardElements.link_attach.map(l => (
                        <tr key={l.id}>
                        <td><a style={{ color: 'black' }} href={l.link.match(/https?/gm) ? l.link : `//${l.link}`} rel="noreferrer" target="_blank">{l.description}</a></td>
                        <td><a style={{ color: 'black' }} href={l.link.match(/https?/gm) ? l.link : `//${l.link}`} rel="noreferrer" target="_blank"><Link /></a></td>
                        </tr>
                     ))}
                  </tbody>
                  </Table>
               </div>
            </div>
         </div>
      </>
      }
    </>
  );
}

export default DashboardMeta;