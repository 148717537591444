import MonitoringCard from './monitoringCard';

const MonitoringCardsWrapper = ({ title, charts, style }) => {
  const stylingOptions = { flex: '1 1 0', minWidth: '400px' };
  return (
    <div className='simple-box' style={style ? stylingOptions : null}>
      <h2 className='title-simple-box margin-title-box'>{title}</h2>
      <div className='d-flex flex-row flex-wrap flex-grow mb-5' style={{ gap: '10px' }}>
        {charts.map((chart, index) => (
          <MonitoringCard
            title={chart.title}
            children={chart.children}
            key={`${chart.title}_${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default MonitoringCardsWrapper;
