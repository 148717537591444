import React from 'react';
import { Button } from 'react-bootstrap';
const VariantButton = ({ id, isSelected, className, label, onClick }) => {
  return (
    // <Button
    //   id={id}
    //   className={`mb-1 ${
    //     isSelected ? 'selection-button' : 'selection-button-outlined'
    //   } ${className}`}
    //   style={{ scale: isSelected ? '1.2' : '1', transition: 'all 0.5s' }}
    //   onClick={onClick}>
    //   {label}
    // </Button>
    <Button
      id={id}
      variant='link'
      className={`${isSelected ? 'active' : ''} dashSubMenu-buttons`}
      onClick={onClick}
      style={{ scale: isSelected ? '1.2' : '1', transition: 'all 0.5s' }}>
      <div className='d-flex align-items-center'>
        <span className='submenu-i'>{label}</span>
      </div>
    </Button>
  );
};

export default VariantButton;
