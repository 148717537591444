import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '@lib/i18n';
import { ListGroup, Col, Row, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { getESGResults } from '@lib/api';

import ExecutionIndicator from './ExecutionIndicator';
import EsgRatingComponent from 'components/scenariodashboard/esgRating/esgRatingComponent';
import { fetchData } from '@lib/helpers/index';

const EsgExecuteSummary = ({ analysis }) => {
  const [esgResults, setEsgResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { idScenario = null, idVariante = null } = useParams();

  useEffect(() => {
    fetchData(
      getESGResults,
      setEsgResults,
      null,
      [idScenario, idVariante],
      null,
      setError,
      setIsLoading
    );
  }, [idScenario, idVariante]);
  console.log('EsgExecuteSummary', analysis);
  return (
    <Col lg={12} className=''>
      <ExecutionIndicator analysis={analysis} analysisUrl={'esgrating'} height='50px' />
      {analysis.status === 'Executed' ? (
        <EsgRatingComponent data={esgResults} isSummary={true} />
      ) : null}
    </Col>
  );
};

export default EsgExecuteSummary;
