import React from 'react';
import GenericDownloadUploadItems from 'components/DownloadUploadView/GenericDownloadUploadView';
import { TooltipInfoIcon } from 'icone/index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const QuestionText = ({ question, children }) => {
  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {question.description}
    </Tooltip>
  );

  return (
    <div className='mx-3 my-5'>
      <hr />
      <div className='row justify-content-around'>
        <div className='col-2'>
          <span className='question-code px-3 py-1'>{question.code}</span>
        </div>

        <div className='col-10 mb-3 d-flex flex-column'>
          <div>
            {question.text}
            {question.description ? (
              <OverlayTrigger
                placement='top'
                delay={{ show: 200, hide: 400 }}
                overlay={renderTooltip}>
                <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
              </OverlayTrigger>
            ) : null}
          </div>
        </div>
      </div>
      <div className='row justify-content-around'>
        <div className='col-2'></div>

        <div className='col-10'>{children}</div>
      </div>
    </div>
  );
};

export default QuestionText;
