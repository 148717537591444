import React, { Component } from "react"

class Ico93 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-93" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(17.8,38)">
          <path d="M80.4723 33.1813C78.7925 31.6208 76.1732 31.7229 74.408 33.1813L61.2546 43.9583C59.646 45.2854 57.6388 46 55.5605 46H38.7201C37.4674 46 36.4424 44.95 36.4424 43.6667C36.4424 42.3833 37.4674 41.3333 38.7201 41.3333H49.8663C52.1298 41.3333 54.2366 39.7437 54.6067 37.4542C55.0765 34.5375 52.8842 32 50.1083 32H27.3318C23.4883 32 19.7729 33.3562 16.7834 35.8354L10.164 41.3333H2.27765C1.02494 41.3333 0 42.3833 0 43.6667V57.6667C0 58.95 1.02494 60 2.27765 60H53.0693C55.1334 60 57.1406 59.2854 58.7634 57.9583L80.2872 40.3125C82.451 38.5479 82.6218 35.1646 80.4723 33.1813Z" className="ico" />
          <path d="M62 14.5V18.125C62 24.1316 52.8216 29 41.5 29C30.3797 29 21.328 24.3038 21.0093 18.4458L21 18.125V14.5C21 20.5066 30.1784 25.375 41.5 25.375C52.8216 25.375 62 20.5066 62 14.5ZM41.5 0C52.8216 0 62 4.86838 62 10.875C62 16.8816 52.8216 21.75 41.5 21.75C30.1784 21.75 21 16.8816 21 10.875C21 4.86838 30.1784 0 41.5 0Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico93