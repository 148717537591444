import React from 'react';
import { Container, Row, Col, Card, Button} from 'react-bootstrap';

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import IcoBase from '@icons/ico-base'
import IcoPlus from '@icons/ico-plus'


const Method = props => {

    const options = {
        buttons: {
          showNextButton: false,
          showPrevButton: false,
          showAutoplayButton: false,
        },
        thumbnails: {
          showThumbnails: false,
        },
        caption: {
          showCaption:false,
        }
      };

  return (
    <div id="metodologia">
      <Container>
        <Row>
          <Col>
            <h2 className="title-box">La metodologia</h2>
            <p>
            Il tool è attualmente disponibile in tre lingue (inglese, italiano e spagnolo) ed in tutte le valute censite dal Forex.

            La libreria di modelli è sempre aggiornata all’anno in corso, include modelli stimati per oltre 60 paesi (per l’Italia anche tutte le regioni) e comprende versioni specializzate per specifiche classi di investimento a partire dal comparto dell’energia (reti di distribuzione, produzione da fonti fossili e rinnovabili, produzione di idrogeno, stoccaggio elettrochimico, reti di ricarica per veicoli elettrici, efficienza energetica, upstream oil&gas, …), a quello dei trasporti (strade, autostrade, porti, aeroporti, trasporto urbano, …), come anche per ambiti e classi di intervento normativo (tax credit e altre categorie di imposte e sussidi, carbon market design, politiche sanitarie e ambientali, …).
            </p>
          </Col>
          <Col>
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <a href="https://storage.googleapis.com/dol-openeconomics/48e7668b_7f1d_41b8_9048_aa7b465a6fd0_58d20b8c3b/48e7668b_7f1d_41b8_9048_aa7b465a6fd0_58d20b8c3b.jpg" className="light-box-link">
                    <img src="https://storage.googleapis.com/dol-openeconomics/48e7668b_7f1d_41b8_9048_aa7b465a6fd0_58d20b8c3b/48e7668b_7f1d_41b8_9048_aa7b465a6fd0_58d20b8c3b.jpg" alt="metodologia" className="img-fluid" />
                    <img src="/img/ico-zoom.svg" alt="" className="ico-absolute" width="57px" />
                  </a>
                </SRLWrapper>
            </SimpleReactLightbox>
          </Col>
        </Row>
      </Container>
      <Container className="area-card">
        <Row>
          <Col lg={4} xs={12} className="card-container">
            <Card>
              <Card.Body>
                <IcoBase className="logo-card ico-box-base" text="sam" />
                <Card.Title>MATRICE DI CONTABILITÀ SOCIALE</Card.Title>
                <div className="card-text">
                  <div className="text">
                    <p>
                      La Matrice di Contabilità Sociale (SAM) è sia un database completo relativo alla contabilità nazionale, che registra tutti i flussi di scambio che intercorrono all'interno di un'economia durante uno specifico arco temporale, sia, nella sua forma di matrice dei coefficienti, la base di una serie di modelli di equilibrio generale computabile (CGE).
                    </p>
                  </div>
                </div>
                <Button variant="link" href="https://www.openeconomics.eu/externalytics/matrice-di-contabilita-sociale" target="_blank"><IcoPlus className="ico-light" width="20px" />leggi tutto</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xs={12} className="card-container">
            <Card>
              <Card.Body>
                <IcoBase className="logo-card ico-box-base" text="cba" />
                <Card.Title>ANALISI COSTI-BENEFICI</Card.Title>
                <div className="card-text">
                  <div className="text">
                    <p>
                      Si potrebbe fare riferimento ai modelli SAM, CGE e DSGE come gli standard d’eccellenza per le analisi di impatto, poiché consentono di misurare gli effetti diretti, indiretti e indotti di un progetto o di una politica, comprese una serie di esternalità, e tenendo anche conto di ciò che sarebbe accaduto se il progetto o la politica non venissero implementati (cioè, lo scenario controfattuale).
                    </p>
                  </div>
                </div>
                <Button variant="link" href="https://www.openeconomics.eu/externalytics/analisi-costi-benefici" target="_blank"><IcoPlus className="ico-light" width="20px" />leggi tutto</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xs={12} className="card-container">
            <Card>
              <Card.Body>
                <IcoBase className="logo-card ico-box-base" text="cge" />
                <Card.Title>MODELLI DI EQUILIBRIO ECONOMICO COMPUTAZIONALE</Card.Title>
                <div className="card-text">
                  <div className="text">
                    <p>
                    I modelli di equilibrio economico computazionale (CGE) rientrano tra i principali strumenti per l'analisi d'impatto. Negli ultimi anni, sono stati ampiamente riconosciuti dalle istituzioni multilaterali e sovranazionali come rigorosi ed affidabili strumenti di valutazione dei programmi.
                    </p>
                  </div>
                </div>
                <Button variant="link" href="https://www.openeconomics.eu/externalytics/modelli-di-equilibrio-economico-computazionale" target="_blank"><IcoPlus className="ico-light" width="20px" />leggi tutto</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xs={12} className="card-container">
            <Card>
              <Card.Body>
                <IcoBase className="logo-card ico-box-base" text="dsge" />
                <Card.Title>MODELLI DI EQUILIBRIO ECONOMICO DINAMICO STOCASTICO</Card.Title>
                <div className="card-text">
                  <div className="text">
                    <p>
                    Analogamente ai modelli CGE, i DSGE vengono utilizzati come strumenti per simulare risultati economici e per formulare consigli pratici sulle politiche. Tuttavia, i DSGE sono diversi dai modelli CGE perché sono rappresentazioni macroeconomiche dell'economia che tentano di raggiungere micro-fondazioni simulando il comportamento dei decisori attraverso l'ottimizzazione intertemporale ed utilizzando diverse ipotesi di aspettative in un contesto di incertezza stocastica.
                    </p>
                  </div>
                </div>
                <Button variant="link" href="https://www.openeconomics.eu/externalytics/modelli-di-equilibrio-economico-dinamico-stocastico" target="_blank"><IcoPlus className="ico-light" width="20px" />leggi tutto</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xs={12} className="card-container">
            <Card>
              <Card.Body>
                <IcoBase className="logo-card ico-box-base" text="roa" />
                <Card.Title>ANALISI DELLE OPZIONI REALI</Card.Title>
                <div className="card-text">
                  <div className="text">
                    <p>
                    L'analisi delle opzioni reali è un'innovazione relativamente recente all'interno dell'insieme delle tecniche di valutazione economica che sta ricoprendo sempre più attenzione, poiché ha rivoluzionato il processo decisionale imprenditoriale ed ha suggerito un approccio completamente nuovo alle scelte di investimento.
                    </p>
                  </div>
                </div>
                <Button variant="link" href="https://www.openeconomics.eu/externalytics/analisi-delle-opzioni-reali" target="_blank"><IcoPlus className="ico-light" width="20px" />leggi tutto</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xs={12} className="card-container">
            <Card>
              <Card.Body>
                <IcoBase className="logo-card ico-box-base" text="ra" />
                <Card.Title>ANALISI DEI RISCHI</Card.Title>
                <div className="card-text">
                  <div className="text">
                    <p>
                    Tutti i modelli impiegati nella presente piattaforma sono testati con solidi controlli di robustezza che vengono costantemente aggiornati dai nostri analisti al fine di garantire risultati di valutazione affidabili e coerenti. I risultati vengono verificati sia implicitamente (ovvero integrando i controlli di robustezza nella costruzione e programmazione dei nostri modelli) che in modo esplicito (ovvero l'analisi di coerenza viene eseguita direttamente sui risultati dei modelli).
                    </p>
                  </div>
                </div>
                <Button variant="link" href="https://www.openeconomics.eu/externalytics/analisi-dei-rischi" target="_blank"><IcoPlus className="ico-light" width="20px" />leggi tutto</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Method