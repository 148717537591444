import React from 'react';
import CountryHeader from '@components/country/CountryHeader'


const Footprint = () => {
    return (
        <div id="scenari" className="megaContent">
            <div className="container-fluid">
            <CountryHeader/>
                <div className="row">
                test
                </div>
            </div>
        </div>
    );
  }
  
  export default Footprint;