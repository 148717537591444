import React, { useState, useEffect } from 'react';
import { Trans } from '@lib/i18n';
import { ListGroup } from 'react-bootstrap';
import { listResizer } from '@lib/helpers';
import { getOrganization } from '@lib/api/index';
import ListItem from '@components/scenariodashboard/DashboardListItem';
import ModalListItem from '@components/scenariodashboard/DashboardModalListItem';

import { Ico42 } from '@icons/new';
function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
const DashboardSroiImpact = ({ scenario, variant }) => {
  // const [translations, setTranslations] = useState([]);
  // const [currentLang, setCurrentLang] = useState('');
  const [info, setInfo] = useState([]);
  const [organization, setOrganization] = useState('');
  const [dimensions, setDimensions] = useState(window.innerWidth);
  // const containerIDs = ['firstGroup', 'secondGroup', 'thirdGroup', 'fourthGroup'];
  console.log('SCENARIOSROI', scenario);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions(window.innerWidth);
    }, 300);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     // Perform localStorage action
  //     setTranslations(JSON.parse(localStorage.getItem('translations')));

  //     let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
  //     setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
  //   }
  // }, []);

  useEffect(() => {
    setInfo(() => ({
      simulationPeriod: variant.operationEnd - variant.investmentStart + 1,
      investmentPeriod: variant.investmentEnd - variant.investmentStart + 1,
      operatingPeriod: variant.operationEnd - variant.operationStart + 1,
      start: variant.investmentStart,
      // start: variant.operationStart,
      discountRate: variant.socialDiscountRate.toLocaleString('it-IT', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    }));
  }, [variant]);

  useEffect(() => {
    if (scenario) {
      // console.log('ORGANO', organization, scenario.userOrganizationId);
      getOrganization(scenario.userOrganizationId).then((retrievedOrganization) => {
        setOrganization(retrievedOrganization?.data?.name);
      });
    }
  }, [scenario]);

  // const adjustListItemsHeight = () => {
  //   const containerIDIS = ['firstGroup', 'secondGroup', 'thirdGroup', 'fourthGroup'];
  //   const allListItems = [];
  //   containerIDIS.forEach((containerId) => {
  //     const containerSelector = `#${containerId}`;
  //     const listItems = Array.from(
  //       document.querySelectorAll(`${containerSelector} .list-group-item`)
  //     );
  //     allListItems.push(...listItems);
  //   });
  //   let maxHeight = 0;
  //   let elapsedTime = 0; // Tempo trascorso in millisecondi
  //   console.log('allListr', allListItems);
  //   allListItems.forEach((listItem) => {
  //     listItem.style.height = 'auto';
  //     if (listItem.offsetHeight > maxHeight) {
  //       maxHeight = listItem.offsetHeight;
  //     }
  //   });

  //   if (maxHeight === 0) {
  //     const interval = setInterval(() => {
  //       elapsedTime += 100; // Qui aumento il tempo trascorso di 100 millisecondi
  //       if (elapsedTime >= 10000) {
  //         // 10 secondi (10000 millisecondi)
  //         clearInterval(interval); // Abort della funzione
  //         console.log('Timeout raggiunto. Funzione dismessa.');
  //         return;
  //       }

  //       const allListItems = Array.from(document.getElementsByClassName('list-group-item'));
  //       let newMaxHeight = 0;

  //       allListItems.forEach((listItem) => {
  //         listItem.style.height = 'auto';
  //         if (listItem.offsetHeight > newMaxHeight) {
  //           newMaxHeight = listItem.offsetHeight;
  //         }
  //       });

  //       if (newMaxHeight !== 0) {
  //         clearInterval(interval); // Fermo l'intervallo se maxHeight è maggiore di 0
  //         maxHeight = newMaxHeight;
  //         adjustListItemsHeight();
  //       }
  //     }, 100);
  //   } else {
  //     const listGroups = {};
  //     let isSameHeight = true; // Flag per indicare che ogni container abbia lo stesso numero di listItems

  //     // Raggruppo tutti i listItems in base al loro container
  //     allListItems.forEach((listItem) => {
  //       const containerId = listItem.parentNode.id;
  //       if (!listGroups[containerId]) {
  //         listGroups[containerId] = [];
  //       }
  //       listGroups[containerId].push(listItem);
  //     });

  //     // Trovo il container con l'altezza più ampia.
  //     let maxContainerHeight = 0;
  //     let maxContainerId = null;
  //     for (const containerId in listGroups) {
  //       const containerHeight = listGroups[containerId].reduce((totalHeight, item) => {
  //         return totalHeight + item.offsetHeight;
  //       }, 0);
  //       if (containerHeight > maxContainerHeight) {
  //         maxContainerHeight = containerHeight;
  //         maxContainerId = containerId;
  //       }
  //     }

  //     // Check se tutti i container hanno la stessa quantità di listItems
  //     const numItemsInFirstContainer = listGroups[Object.keys(listGroups)[0]].length;
  //     for (const containerId in listGroups) {
  //       if (listGroups[containerId].length !== numItemsInFirstContainer) {
  //         isSameHeight = false;
  //         break;
  //       }
  //     }

  //     for (const containerId in listGroups) {
  //       if (!containerId) return;
  //       const container = document.getElementById(containerId);

  //       if (isSameHeight) {
  //         // Tutti i container hanno pari quantità di listItems
  //         const listItemHeight = maxContainerHeight / numItemsInFirstContainer;
  //         listGroups[containerId].forEach((listItem) => {
  //           listItem.style.height = `${listItemHeight}px`;
  //         });
  //         container.style.height = `${maxContainerHeight}px`;
  //       } else {
  //         // Almeno un container ha una quantità di listItems maggiore
  //         if (containerId !== maxContainerId) {
  //           const listItemHeight = maxContainerHeight / listGroups[containerId].length;
  //           listGroups[containerId].forEach((listItem) => {
  //             listItem.style.height = `${listItem.offsetHeight + listItemHeight}px`;
  //           });
  //         }
  //         container.style.height = `${maxContainerHeight}px`;
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    listResizer(['firstGroup', 'secondGroup', 'thirdGroup', 'fourthGroup']);
    // adjustListItemsHeight();
  }, [dimensions]);

  const getCodeByValue = (value) => {
    let code = '';
    switch (value) {
      case 'purpose':
        code = 'A0489';
        break;
      case 'context':
        code = 'A0350';
        break;
      case 'shock':
        code = 'A0736';
        break;
      case 'institutional':
        code = 'A0167';
        break;
      default:
        break;
    }
    return code;
  };

  return (
    <div className='simple-box impact'>
      <Ico42 className='logo-card ico-box-blue' />
      <div className='row'>
        <div className='col'>
          <h5 className='title-simple-box margin-title-box'>
            <Trans code='A0036' />: {scenario.name}
          </h5>
        </div>
      </div>
      <div className='d-flex flex-wrap justify-content-around align-items-center'>
        <div className='d-flex flex-wrap justify-content-around flex-grow-1'>
          {scenario?.address && (
            <div className='chartBox impactChartTab pt-0 flex-grow-1'>
              <div className='impactCard-title'>
                <h5>
                  <Trans code='A0632' />
                </h5>
              </div>
              <ListGroup id='firstGroup' variant='flush' className='pt-0'>
                <ListItem listTitle={'A0758'} listValue={scenario?.address?.country} />
                <ListItem listTitle={'A0668'} listValue={scenario?.address?.region} />
                <ListItem listTitle={'A0757'} listValue={scenario?.address?.city} />
                <ListItem listTitle={'A0745'} listValue={scenario?.address?.address} />
              </ListGroup>
            </div>
          )}

          <div className='chartBox impactChartTab pt-0 flex-grow-1'>
            <div className='impactCard-title'>
              <h5>
                <Trans code='A0733' />
              </h5>
            </div>
            <ListGroup id='secondGroup' variant='flush' className='pt-0'>
              <ListItem listTitle={'A0058'} listValue={organization} />
              <ListItem listTitle={'A0498'} listValue={scenario?.class?.name} />
              <ListItem listTitle={'A0528'} listValue={variant?.dataset?.name} />
              <ListItem listTitle={'A0073'} listValue={info?.start} />
            </ListGroup>
          </div>
          <div className='chartBox impactChartTab pt-0 flex-grow-1'>
            <div className='impactCard-title'>
              <h5>
                <Trans code='A0734' />
              </h5>
            </div>
            <ListGroup id='thirdGroup' variant='flush' className='pt-0'>
              <ListItem
                listTitle={'F0067'}
                listValue={info.simulationPeriod}
                sign='&nbsp;'
                conditionalPlural={
                  info.simulationPeriod === 1 ? <Trans code='A0385' /> : <Trans code='A0402' />
                }
              />
              <ListItem
                listTitle={'F0068'}
                listValue={info.investmentPeriod}
                sign='&nbsp;'
                conditionalPlural={
                  info.investmentPeriod === 1 ? <Trans code='A0385' /> : <Trans code='A0402' />
                }
              />
              <ListItem
                listTitle={'F0069'}
                listValue={info.operatingPeriod}
                sign='&nbsp;'
                conditionalPlural={
                  info.operatingPeriod === 1 ? <Trans code='A0385' /> : <Trans code='A0402' />
                }
              />
              <ListItem
                listTitle={'A0112'}
                listValue={parseFloat(info.discountRate).toFixed(2)}
                sign='&#37;'
              />
            </ListGroup>
          </div>
          {/*scenario.additionalInfo.length > 0 && (
            <div className='chartBox impactChartTab pt-0 flex-grow-1'>
              <div className='impactCard-title'>
                <h5>
                  <Trans code='A0735' />
                </h5>
              </div>
              <ListGroup id='fourthGroup' variant='flush' className='pt-0'>
                {scenario.additionalInfo
                  .filter((el) => el.topic !== 'model' && el.topic !== 'modelass' && el.text !== '')
                  .map((elementsToUse) => {
                    return (
                      <ModalListItem
                        key={elementsToUse.topic}
                        listTitle={getCodeByValue(elementsToUse.topic)}
                        modalContent={elementsToUse.text}
                      />
                    );
                  })}
              </ListGroup>
            </div>
                )*/}
        </div>
      </div>
    </div>
  );
};

export default DashboardSroiImpact;
