import React from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


const Platform = props => {

    const options = {
        buttons: {
          showNextButton: false,
          showPrevButton: false,
          showAutoplayButton: false,
        },
        thumbnails: {
          showThumbnails: false,
        },
        caption: {
          showCaption:false,
        }
      };

  return (
    <div id="externalytics-text">
      <Container>
        <Row>
          <Col>
            <h2 className="title-box">La piattaforma</h2>
            <p>
            La piattaforma consente di analizzare in tempo reale ed in maniera rapida ed intuitiva gli impatti economici, sociali e ambientali di politiche e investimenti.

Grazie al suo potente motore di calcolo, basato su una famiglia di modelli all’avanguardia, e alla sofisticata dashboard interattiva, Externalytics riduce drasticamente la complessità, lo sforzo e le tempistiche della valutazione d’impatto estesa.

L’uso della piattaforma riduce i costi e la complessità delle analisi e consente stime coerenti, aggiornate e robuste di una vasta gamma di esternalità cui si affiancano efficaci tool di rappresentazione basati su sofisticate librerie grafiche interattive.

La procedura end-to-end per la valutazione d'impatto estesa si basa su statistiche ufficiali e dataset aggiornati da fonti istituzionali, su un solido motore di modellazione in linea con le prassi valutative degli organismi multilaterali (tra cui la Commissione europea, la BEI e la Banca Mondiale) per fornire valutazioni d'impatto rigorose e affidabili di programmi e progetti. e risponde agli standard di rappresentazione riconosciuti a livello internazionale.
            </p>
          </Col>
          <Col>
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <a href="https://storage.googleapis.com/dol-openeconomics/ffed1bf8_0ed3_4153_b57d_74fe1ae83082_8372f0a7d4/ffed1bf8_0ed3_4153_b57d_74fe1ae83082_8372f0a7d4.jpg" className="light-box-link">
                    <img src="https://storage.googleapis.com/dol-openeconomics/ffed1bf8_0ed3_4153_b57d_74fe1ae83082_8372f0a7d4/ffed1bf8_0ed3_4153_b57d_74fe1ae83082_8372f0a7d4.jpg" alt="piattaforma" className="img-fluid" />
                    <img src="/img/ico-zoom.svg" alt="" className="ico-absolute" width="57px" />
                  </a>
                </SRLWrapper>
            </SimpleReactLightbox>
          </Col>
        </Row>
      </Container>
      <Container className="area-card simple-card">
        <Row>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>State of art based</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Based on state-of-the-art economic methodologies in line with the international best practices with the aim to provide rigorous and reliable analysis.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>User friendly</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Multiple scenery can be easily simulated, and the results can be explored through a intuitive dashboards and graphs.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>Open and interactive</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Results can be explored through a dashboard based on interactive graphs and mouse over handbook, and all data cab be exported in multiple formats.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>Effective and time saving</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Avoids taking care of researching bulky data set and designing, building and maintain complex socieoeconomic models.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>Always updated</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Models, datasets, metrics and protocols are updated on a regular basis, relieving users from risk and effort related to data assurance.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>Global scope of work</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Incorporates a library of models and dataset aligned to official national accounts of over 200 countries, continents and regions.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>Fully integrated</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Provides a set of collateral tools to monitor fund form multilaterals, analyse economies’ structures and explore additional insights.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} className="card-container">
            <Card>
              <Card.Body>
                <Card.Title>Standard compliant</Card.Title>
                <div className="card-text">
                  <div className="text">
                    Expressing outcomes according to standard metrics, suitable for multiple internal and external uses.
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Platform