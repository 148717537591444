import React from 'react'
import { Trans } from '@lib/i18n'
import { date } from '@lib/helpers'
import { OpeneconomicsLogoSmall } from 'loghi/index';

const ScenarioHeader = ({ scenario, dataset }) => {
   const url = window.location.href;

   return <div className="row">
      <div className="col">
         <h2>
         {Number(scenario.verificato) === 1 ? <span className="pr-2 position-relative" style={{bottom: '3px'}}><OpeneconomicsLogoSmall style={{maxWidth: 20}} /></span> : null}
         {scenario.scenario_name}
         <span className="scenario-details">Status: {scenario.status}</span>
         <span className="scenario-details"><Trans code="A0069" />: {scenario.projectOwner} - {scenario.company}</span>
         <span className="scenario-details"><Trans code="A0070" />: {date(scenario.dataCreazione)}</span>
         <span className={url.includes('dashboard') ? "scenario-details" : "scenario-details no-pipe"}><Trans code="A0071" />: {scenario.dataModifica ? date(scenario.dataModifica) : 'n.d.'}</span>
         {url.includes('dashboard') ? <span className="scenario-details no-pipe"><Trans code="A0387" />: {dataset}</span>: null}
         </h2>
      </div>
   </div>
}
export default ScenarioHeader