import React, { useMemo } from 'react';
import InputPercentage from './InputPercentage';
import InputText from './InputText';
import InputNumber from './InputNumber';
import InputRadio from './InputRadio';
import InputCheckbox from './InputCheckbox';
import FadeInOutComponent from './FadeInComponent';

const componentsMap = {
  'input-perc': InputPercentage,
  'input-text': InputText,
  'input-num': InputNumber,
  radio: InputRadio,
  checkbox: InputCheckbox,
};

const QuestionItem = ({
  question,
  handleAssessmentChange,
  isVisible,
  handleValue,
  handleChecked,
  userRole,
}) => {
  const Component = componentsMap[question.type];
  const isPremium = useMemo(() => userRole === 4, [userRole]);
  if (!Component) return null;

  return (
    <FadeInOutComponent isVisible={isVisible}>
      <Component
        question={question}
        handleAssessmentChange={handleAssessmentChange}
        handleValue={handleValue}
        handleChecked={handleChecked}
        isPremium={isPremium}
      />
    </FadeInOutComponent>
  );
};

export default QuestionItem;
