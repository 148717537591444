import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { Button, FormControl, ButtonToolbar, InputGroup } from 'react-bootstrap';
import { Lens, IcoUploadFile } from '@icons';
import { Trans, propTrans } from '@lib/i18n/index';
import {
  getOERegions,
  getUnemployment,
  getUnemploymentDownload,
  uploadUnemployment,
} from '@lib/api/index';

import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import StatusBar from 'components/wizard/StatusBar';
import normalizeIncomeTaxes from 'utils/normalizeIncomeTaxes';
import base64ToBlob from 'utils/base64ToBlob';
import { IcoDownloadFile } from 'icone/index';

const Unemployment = () => {
  const [dataset, setDataset] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [regionsDatasetTypes, setRegionsDatasetTypes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadingDownlod, setLoadingDownlod] = useState(false);
  const [loadingUploading, setLoadingUploading] = useState(false);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  const fileInputRef = useRef(null);

  // const handleDownloadFile = (e) => {
  //   handleStatusBar("elaborating", false, false);
  //   getUnemploymentDownload()
  //     .then((json) => {
  //       if (json.success) {
  //         handleStatusBar("success", <Trans code="A0763" />, true);
  //         const base64File = json.file;
  //         const blob = base64ToBlob(
  //           base64File,
  //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //         );
  //         const link = document.createElement("a");

  //         link.href = window.URL.createObjectURL(blob);
  //         link.download = "unemployment.xlsx";
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       }
  //     })
  //     .catch((error) => {
  //       handleStatusBar("error", <Trans code="A0764" />, true);
  //       setLoadingDownlod(false);
  //     })
  //     .finally(() => {
  //       setLoadingDownlod(false);
  //     });
  // };

  const handleDownloadFile = async () => {
    handleStatusBar('elaborating', false, true);
    setLoadingDownlod(true);
    const downloadResponse = await getUnemploymentDownload();
    console.log('downloadResponse', downloadResponse);
    if (downloadResponse.ok === true) {
      handleStatusBar('success', <Trans code='A0657' />, true);
      const blob = await downloadResponse.blob();

      const file = new File([blob], 'unemployment.xlsx');

      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `unemployment.xlsx`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      // setTimeout(() => {
      // handleStatusBar('error', <Trans code='A0764' />, true);
      setLoadingDownlod(false);
      // }, 5000);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      // const res = await downloadResponse.json();
      // console.log(res);
      handleStatusBar('error', 'Error', false);
      setLoadingDownlod(false);
    }
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar key={Math.random()} status={status} message={message} timeout={timeout} />
    );
  };

  useEffect(() => {
    if (refresh) {
      getUnemployment().then((retrieveData) => {
        setDataset(normalizeIncomeTaxes(retrieveData));
      });
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const myAbortController = new AbortController();
    const fetchData = async () => {
      const [regionsResponse] = await Promise.all([getOERegions()]);

      if (myAbortController.signal.aborted === false) {
        setRegionsDatasetTypes(
          regionsResponse.data.map((region) => ({
            value: region.code,
            label: region.name,
          }))
        );
      }
    };

    fetchData();

    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    const uniqueKeys = Array.from(
      new Set(dataset.flatMap((obj) => Object.keys(obj).filter((key) => key !== 'region')))
    );

    const dynamicColumns = uniqueKeys.map((year) => {
      return {
        headerName: year,
        field: year,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data[year];
        },
      };
    });

    const staticColumns = [
      {
        headerValueGetter: () => propTrans({ prop: 'A0668' }),
        field: 'region',
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0528' }),
        field: 'regionCode',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          const region = params.data.region;
          const matchingRegion = regionsDatasetTypes.find((el) => el.value === region);
          return matchingRegion ? matchingRegion.label : '';
        },
      },
    ];

    const columnDefs = [...staticColumns, ...dynamicColumns];
    setColumnDefs(columnDefs);
  }, [regionsDatasetTypes, dataset]);

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  const onGridReady = useCallback(() => {
    getUnemployment().then((retrieveData) => {
      setDataset(normalizeIncomeTaxes(retrieveData));
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop();

      if (fileType === 'xlsx' || fileType === 'xls') {
        const formData = new FormData();
        formData.append('file', file);
        handleStatusBar('elaborating', false, false);
        setLoadingUploading(true);
        uploadUnemployment(formData)
          .then((res) => {
            if (res.status) {
              handleStatusBar('success', <Trans code='A0654' />, true);
              setRefresh(true);
            } else {
              handleStatusBar('error', res.message, true);
            }
            setLoadingUploading(false);
          })
          .catch((error) => {
            handleStatusBar('error', <Trans code='A0580' />, true);
            setLoadingUploading(false);
          })
          .finally(() => {
            setLoadingUploading(false);
          });
      }
    }
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='dataset' className='megaContent'>
      <div className='container-fluid'>
        {sbShow}
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-4'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0772' />
            </h5>
          </div>
          <div className='col-8 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <Button
                disabled={loadingUploading}
                style={{ marginRight: '10px' }}
                variant='info add-user'
                onClick={() => fileInputRef.current.click()}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoUploadFile className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    {loadingUploading ? <Trans code='A0028' /> : <Trans code='A0579' />}
                  </span>
                </div>
              </Button>
              <Button
                disabled={loadingDownlod}
                variant='info add-user'
                onClick={() => handleDownloadFile()}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoDownloadFile className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    {loadingDownlod ? <Trans code='A0028' /> : <Trans code='A0728' />}
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={dataset}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                <Trans code='A0762' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
          <input
            type='file'
            accept='.xlsx,.xls'
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleUploadFile}
          />
        </div>
      </div>
    </div>
  );
};

export default Unemployment;
