import React, { useState } from 'react';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import { ArrowDownUp } from 'icone/index';

const AccordionItem = ({ eventKey, headerText, isChild, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Card className={`p-0 ${isChild ? 'child-accordion' : 'parent-accordion'}`}>
      <Accordion.Toggle
        as={Card.Header}
        className='accordion-title-variant-selector'
        variant='link'
        eventKey={eventKey}
        onClick={handleToggle}>
        <Row>
          <Col>
            {!isChild ? (
              <h2 className='m-0 p-0'>{headerText}</h2>
            ) : (
              <p className='m-0 p-0 font-weight-bold'>{headerText}</p>
            )}
          </Col>
          <Col className='text-right pl-0 col-auto'>
            <ArrowDownUp
              style={{
                width: '14px',
                height: '14px',
                rotate: '-90deg',
                transition: 'transform .1s',
                transform: `rotate(${isOpen ? 90 : 0}deg)`,
              }}
            />
          </Col>
        </Row>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AccordionItem;
