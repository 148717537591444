import { Card } from 'react-bootstrap'
import { Ico35 } from "@icons/new"
//import { Trans } from '@lib/i18n'

const KeyindicatorsTable2 = () => {
  

  return (
  <Card className="cards-utente d-none d-md-block card-table w-100">
    <Card.Body>
      <Ico35 className="logo-card ico-box-blue" />
     {/* <Card.Title><Trans code="A0183" /></Card.Title>*/}
      <div style={{ overflowX: 'scroll'}} className="w-100">
        <table className="investment-table mt-3">
          <thead>
            <tr>
              <th className="">Economia</th>
              <th></th>
              <th>Value</th>
              <th className="">Theme</th>
              <th className="">R</th>
              <th>Scale</th>
              <th className="">Sources</th>
              <th className="">Update</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>PIL</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Crescita del PIL</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Inflazione</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Import</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Export</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Agricultura, Valore Aggiunto</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Industria, Valore Aggiunto</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Servizi, Valore Aggiunto</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
            <tr>
              <td>Entrate fiscali</td>
              <td>2.52</td>
              <td>M EUR</td>
              <td>2343</td>
              <td>4224</td>
              <td>erferf</td>
              <td>Openeconomics</td>
              <td>14/05/2021</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="va-desc"></p>
    </Card.Body>
  </Card>
  );
}

export default KeyindicatorsTable2;