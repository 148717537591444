import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Trans } from '@lib/i18n';
import { getAuthUser, getScenarioMeta, getOEScenario, getScenarioVariant } from '@lib/api';
import { Button, Accordion, Card } from 'react-bootstrap';
import { ArrowDownUp, IcoBalance, IcoESG } from '@icons';
import {
  Ico89,
  Ico91,
  Ico33,
  Ico42,
  Ico47,
  Ico100,
  Ico101,
  Ico102,
  Ico103,
  Ico19,
} from '@icons/new';
import { IcoMonitoring } from 'icone/index';
import { getScenarioVariants } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import StatusBar from 'components/wizard/StatusBar';

const ImpactAnalysis = () => {
  const location = useLocation();
  const { pathname } = location;
  const { idScenario = null, idVariante = null } = useParams();
  const [scenario, setScenario] = useState(null);
  const [subactiveId, subSetActiveId] = useState('ia-0');
  const [haveExpenseVector, setHaveExpenseVector] = useState(0);
  const [user, setUser] = useState(null);
  const [userOwner, setUserOwner] = useState(null);
  const [scenarioVariantsAnalyses, setScenarioVariantsAnalyses] = useState(null);
  const { state = null } = location;
  const [classNameSetup, setClassNameSetup] = useState('');
  const [sbShow, setSbShow] = useState(null);

  const [navItems, setNavItems] = useState([]);

  const pathSegments = location.pathname.split('/');
  const isScenariV2 =
    pathSegments.length > 1 &&
    (pathSegments[1] === 'scenario' || pathSegments[1] === 'scenariowizard');

  // Scenari V2
  const navItemsData = [
    {
      to: `/scenario/${idScenario}/dashboard/variant/${
        idVariante ? idVariante : scenarioVariantsAnalyses
      }`,
      icon: <Ico100 className='submenu-ico' />,
      labelTextCode: 'F0060',
      disabled: true,
    },
    {
      to: `/scenario/${idScenario}/dashboard/variant/${
        idVariante ? idVariante : scenarioVariantsAnalyses
      }/impactanalysis`,
      icon: <Ico33 className='submenu-ico' />,
      labelTextCode: 'A0732',
      disabled: true,
      subRoutes: ['investment', 'gdp', 'jobs', 'incomes', 'taxes'],
    },
    {
      to: `/scenario/${idScenario}/dashboard/variant/${
        idVariante ? idVariante : scenarioVariantsAnalyses
      }/sroianalyses`,
      icon: <Ico101 className='submenu-ico' />,
      labelTextCode: 'A0902',
      disabled: true,
    },
    {
      to: `/scenario/${idScenario}/dashboard/variant/${
        idVariante ? idVariante : scenarioVariantsAnalyses
      }/taxonomy`,
      icon: <Ico102 className='submenu-ico' />,
      labelTextCode: 'A0759',
      disabled: true,
    },
    {
      to: `/scenario/${idScenario}/dashboard/variant/${
        idVariante ? idVariante : scenarioVariantsAnalyses
      }/esgrating`,
      icon: <Ico103 className='submenu-ico' />,
      labelTextCode: 'A0760',
      disabled: true,
    },
  ];
  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    if (idScenario) {
      setSbShow(null);
      fetchData(getScenarioVariants, setScenario, null, [idScenario], handleStatusBar, null);
    }
  }, [idScenario]);

  useEffect(() => {
    if (!idVariante && scenario) {
      const filteredAnalyses = scenario
        ?.map((el) => el.analyses)
        ?.flatMap((el) => el)
        .filter((el) => el.status === 'Executed');
      const lastElement = filteredAnalyses[filteredAnalyses.length - 1]?.variantId;
      setScenarioVariantsAnalyses(lastElement);
    }
  }, [scenario, idVariante]);

  useLayoutEffect(() => {
    if (idScenario && isScenariV2 === true && scenario) {
      // setNavItems(navItemsData);
      // getOEScenario(scenario).then((retrievedScenario) => {
      const analyses = scenario?.map((el) => el.analyses)?.flatMap((el) => el);
      const updatedNavItems = navItemsData.map((item) => {
        let isDisabled = true;
        if (item.labelTextCode === 'A0732') {
          // Impact Analysis
          isDisabled = !analyses?.some(
            // (el) => el.type === 'IA' && (el.status === 'Executed' || el.status === 'Draft')
            (el) => el.type === 'IA' && el.status === 'Executed'
          );
        } else if (item.labelTextCode === 'A0902') {
          // SROI Analyses
          isDisabled = !analyses?.some(
            // (el) => el.type === 'SROI' && (el.status === 'Executed' || el.status === 'Draft')
            (el) => el.type === 'SROI' && el.status === 'Executed'
          );
        } else if (item.labelTextCode === 'A0760') {
          // SROI Analyses
          isDisabled = !analyses?.some(
            // (el) => el.type === 'SROI' && (el.status === 'Executed' || el.status === 'Draft')
            (el) => el.type === 'ESG' && el.status === 'Executed'
          );
        } else if (item.labelTextCode === 'F0060') {
          // Summary
          isDisabled = !analyses?.some(
            (el) =>
              (el.type === 'IA' && el.status === 'Executed') ||
              (el.type === 'SROI' && el.status === 'Executed') ||
              (el.type === 'ESG' && el.status === 'Executed')
          );
        }

        return {
          ...item,
          disabled: isDisabled,
        };
      });
      setNavItems(updatedNavItems);
    } else {
      setNavItems([]);
    }
  }, [idScenario, scenario, idVariante, scenarioVariantsAnalyses]);

  useEffect(() => {
    if (state?.scenario?.idUserOwner) setUserOwner(+state?.scenario?.idUserOwner);
    else if (state?.scenario?.userIdNumber) setUserOwner(state?.scenario?.userIdNumber);
    else return;
  }, [state]);

  const isUserOwner = userOwner && +userOwner !== +user?.id && user?.role !== 1;

  const isUserIdDifferent = userOwner && +userOwner !== +user?.id && user?.role !== 1;

  const isDisabledLink = isUserOwner || isUserIdDifferent;

  const isDashboardScenario = pathname.includes(`/scenari/${idScenario}/dashboard`);
  const isDashboardScenarioWizard =
    pathname.includes(`/scenario/${idScenario}/dashboard/variant/${idVariante}`) ||
    pathname.includes(`/monitoring`);

  const linkPath = isDashboardScenario
    ? { pathname: `/scenariosetup/${idScenario}`, state: { state } }
    : isDashboardScenarioWizard && idVariante
    ? {
        pathname: `/scenariowizard/${idScenario}/${idVariante}/?step=build&sub=general`,
        state: { state },
      }
    : isDashboardScenarioWizard && !idVariante
    ? {
        pathname: `/scenariowizard/${idScenario}`,
        state: { state },
      }
    : '#';

  useEffect(() => {
    // RIATTIVARE PER VECCHIA LOGIN
    // const abortController = new AbortController();

    // const fetchData = async () => {
    //   const authResult = await getAuthUser();
    //   if (abortController.signal.aborted === false) {
    //     setUser(authResult.user);
    //   }
    // };
    // fetchData();
    // return () => {
    //   abortController.abort();
    // };
    setUser(JSON.parse(localStorage.getItem('user')));
  }, []);

  useEffect(() => {
    if (idScenario && pathname.includes(`scenariosetup`)) {
      getScenarioMeta(idScenario).then((retrievedScenarioMeta) => {
        setHaveExpenseVector(Number(retrievedScenarioMeta?.vettorespesa));
      });
    }
  }, [idScenario, pathname]);

  useEffect(() => {
    if (idScenario) {
      let classname = 'menu-link';

      if (
        pathname.includes(`/scenariosetup/${idScenario}`) ||
        pathname.includes(`/scenariowizard/${idScenario}`)
      ) {
        //NEL WIZARD/SETUP
        classname = classname + ' active';
      } else if (pathname.includes(`/scenari/${idScenario}/dashboard`)) {
        //NELLA DASH VECCHIA
        if (!haveExpenseVector) {
          classname = classname + ' disabled-link';
        }
      }

      setClassNameSetup(classname);
    }
  }, [haveExpenseVector, idScenario, pathname]);

  const subToggleActive = (id) => {
    if (subactiveId === id) {
      subSetActiveId(null);
    } else {
      subSetActiveId(id);
    }
  };

  return (
    <ul className='menu-container'>
      <li className='menu-item'>
        {/* {user &&
          ((parseInt(user.role) <= 3 &&
            (parseInt(user.idOrganization) === 1 || parseInt(user.idOrganization) === 14)) ||
            parseInt(user.role) === 1) && (
            <Link
              to='/scenari'
              className={pathname.match(/.+scenari\/?$/gm) ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <Ico89 />
              </div>
              <div className='nav-text'>
                <Trans code='A0468' />
              </div>
            </Link>
          )} */}

        {user &&
          (parseInt(user.idOrganization) === 1 ||
            parseInt(user.idOrganization) !== 14 ||
            parseInt(user.role) === 1) && (
            <Link
              to='/scenari/newscenariosimpact'
              className={
                pathname.match(/.+newscenariosimpact\/?$/gm) ? 'menu-link active' : 'menu-link'
              }>
              <div className='nav-ico'>
                <Ico89 />
              </div>
              <div className='nav-text'>
                <Trans code='A0724' />
              </div>
            </Link>
          )}
        {/* {user &&
          ((parseInt(user.role) <= 3 &&
            (parseInt(user.idOrganization) === 1 || parseInt(user.idOrganization) === 14)) ||
            parseInt(user.role) === 1) && (
            <Link
              to='/scenari/comparators'
              className={pathname.match(/.+comparators\/?$/gm) ? 'menu-link active' : 'menu-link'}>
              <div className='nav-ico'>
                <Ico91 />
              </div>
              <div className='nav-text'>
                <Trans code='A0222' />
              </div>
            </Link>
          )} */}
      </li>
      {idScenario && (
        <li
          className='divider'
          style={{
            margin: '10px 0 10px 0',
            position: 'relative',
            height: '1px',
            background: 'transparent',
          }}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              borderTop: '1px solid #D9D9D9',
            }}></div>
        </li>
      )}

      <li className='menu-item'>
        <Accordion className='sub-accordion' defaultActiveKey='ia-1'>
          {idScenario && (
            <Card className={subactiveId === 'ia-1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant='link'
                  eventKey='ia-1'
                  onClick={() => subToggleActive('ia-1')}
                  className='panel-toggle'
                  block>
                  <div className='nav-ico'>
                    <Ico42 />
                  </div>
                  <div className='nav-text'>
                    <Trans code='C00498' />
                  </div>
                  <div className='arrow-ico'>
                    <ArrowDownUp />
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey='ia-1'>
                <Card.Body>
                  <ul className='menu-container'>
                    {false && !window.location.href.includes('externalytics.com') && (
                      <li className='menu-item'>
                        <Link
                          to='#'
                          //to={{pathname: `/scenari/${idScenario}/cba`, state: { state }}}
                          className={
                            pathname.match(/.+cba\/?$/gm) ? 'menu-link active' : 'menu-link'
                          }>
                          <div className='nav-ico'>
                            <IcoBalance style={{ width: '25px' }} />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0467' />
                          </div>
                        </Link>
                      </li>
                    )}
                    {idScenario && user && parseInt(user.role) <= 3 && (
                      <li className='menu-item'>
                        <Link
                          to={linkPath}
                          className={`${classNameSetup} ${isDisabledLink ? 'disabled-link' : ''}`}>
                          <div className='nav-ico'>
                            <Ico19 />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0474' />
                          </div>
                        </Link>
                      </li>
                    )}
                    {idScenario && user && parseInt(user.role) <= 3 && (
                      <li className='menu-item'>
                        <Link
                          to={`/scenario/${idScenario}/monitoring`}
                          className={
                            pathname.includes('/monitoring') ? 'menu-link active' : 'menu-link'
                          }>
                          <div className='nav-ico'>
                            <IcoMonitoring />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0807' />
                          </div>
                        </Link>
                      </li>
                    )}
                    {navItems &&
                      navItems.map((item, index) => (
                        <li className='menu-item' key={index}>
                          <Link
                            to={item.disabled ? '#' : item.to}
                            className={
                              (
                                item.subRoutes
                                  ? pathname === item.to ||
                                    item.subRoutes.some((el) => pathname.includes(el))
                                  : pathname === item.to
                              )
                                ? 'menu-link active'
                                : 'menu-link' + (item.disabled ? ' disabled-link' : '')
                            }>
                            <div className='nav-ico'>{item.icon}</div>
                            <div className='nav-text'>
                              <Trans code={item.labelTextCode} />
                            </div>
                          </Link>
                        </li>
                      ))}
                    {false && !window.location.href.includes('externalytics.com') && (
                      <li className='menu-item'>
                        <Link
                          to='/scenari'
                          className={
                            pathname.match(/.+esg\/?$/gm)
                              ? 'menu-link active disabled-link'
                              : 'menu-link disabled-link'
                          }>
                          <div className='nav-ico'>
                            <IcoESG className='disabled-svg' />
                          </div>
                          <div className='nav-text'>
                            <Trans code='A0469' />
                          </div>
                        </Link>
                      </li>
                    )}
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </li>
      {sbShow}
    </ul>
  );
};
export default ImpactAnalysis;
