import React, { useState, useEffect } from 'react';
import { Trans } from '@lib/i18n';
import { date } from '@lib/helpers';
import { getUsers, downloadOEScenarioItem, downloadOEScenarioAdditionalInfo } from '@lib/api';
import { OpeneconomicsLogoSmall } from 'loghi/index';
import DownloadButton from './downloadButton';
import StatusBar from 'components/wizard/StatusBar';
import { ExcelIcon, ZipIcon } from '@icons';
import Select from 'react-select';
import { getScenarioVariants } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import { useParams, useHistory } from 'react-router-dom';
const ScenarioHeaderCompact = ({ scenario, visible = true, variant = null }) => {
  const { idScenario = null, idVariante = null } = useParams();
  const [owner, setOwner] = useState({});
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [variantsOptions, setVariantsOptions] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [sbCloneDownloadShow, setSbCloneDownloadShow] = useState(<></>);
  const history = useHistory();
  //console.log('scenario:', scenario);

  const handleCloneDownloadStatusBar = (status, message, timeout) => {
    setSbCloneDownloadShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleDownload = async (downloadFunction, name, extension) => {
    setDownloadProgress(true);

    try {
      const downloadResponse = await downloadFunction(scenario.id, idVariante);
      console.log('DOWNLOAD:', downloadResponse);

      if (downloadResponse.ok === true) {
        const blob = await downloadResponse.blob();
        const file = new File([blob], scenario.id);
        let fileUrl = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = fileUrl;
        a.download = `Externalytics - ${name}.${extension}`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again

        setDownloadProgress(false);
      } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
        setDownloadProgress(false);
        handleCloneDownloadStatusBar('error', `${downloadResponse.statusText}`, false);

        const res = await downloadResponse.json();
        if (res.message) handleCloneDownloadStatusBar('error', `${res.message}`, false);
      }
    } catch (error) {
      console.error('Error during download:', error);
      setDownloadProgress(false);
    }
  };

  useEffect(() => {
    if (scenario) {
      const checkUser = (data) => {
        const filteredUser = data
          .filter((user) => Number(user.id) === Number(scenario.userId))
          .pop();
        if (filteredUser) {
          setOwner(filteredUser);
        }
      };

      fetchData(getUsers, null, checkUser, [], handleCloneDownloadStatusBar);
      // getUsers().then((retrievedUsers) => {
      //   console.log('getuser', retrievedUsers);
      //   setOwner(
      //     retrievedUsers.data.filter((user) => Number(user.id) === Number(scenario.userId)).pop()
      //   );
      // });
    }
  }, [scenario]);
  console.log('owner', owner);
  const transformVariantSelect = (data) => {
    setVariantsOptions(
      data.map((item) => ({
        value: item.id,
        label: item.name,
        isDisabled: item?.analyses?.[0].status !== 'Executed',
      }))
    );
  };

  useEffect(() => {
    if (variant?.id) {
      fetchData(
        getScenarioVariants,
        null,
        transformVariantSelect,
        [variant?.scenarioId],
        handleCloneDownloadStatusBar
      );
    }
  }, [variant]);

  const data = [
    {
      fileName: 'Risultati',
      fileExtension: 'xlsx',
      downloadUrl: downloadOEScenarioItem,
      icon: <ExcelIcon style={{ fill: '#08a2aa' }} className='ico-small subMenu-ico' />,
      translationCode: 'A0641',
    },
    variant &&
      variant?.additionalInfo?.some((item) => item.type === 'file' || item.type === 'image') && {
        fileName: 'Allegati',
        fileExtension: 'zip',
        downloadUrl: downloadOEScenarioAdditionalInfo,
        icon: <ZipIcon style={{ fill: '#08a2aa' }} className='ico-small subMenu-ico' />,
        translationCode: 'C00465',
      },
  ];

  return (
    <>
      <div className={`container-fluid d-flex justify-content-between mb-5 ${!variant && 'pl-0'}`}>
        <div className='flex-grow-1'>
          <div className='d-flex align-items-center flex-wrap'>
            {Number(scenario.verified) === 1 && (
              <div className='mr-1' style={{ width: '20px', maxWidth: '20px' }}>
                <OpeneconomicsLogoSmall />
              </div>
            )}
            <div className='d-flex justify-content-center align-items-center mr-4'>
              <h2 className='mb-0'>{scenario.name}</h2>
            </div>
            {variant && (
              <div className='d-flex justify-content-start pr-2 align-items-center mr-4'>
                <div className='d-flex justify-content-center align-items-center mt-2 pr-2'>
                  <label
                    htmlFor='variantDashSelector'
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <Trans code='A0359' />:
                  </label>
                </div>
                <div style={{ minWidth: '200px' }}>
                  <Select
                    name='variantDashSelector'
                    value={variantsOptions.find((item) => item.value === +idVariante)}
                    options={variantsOptions}
                    onChange={(e) => {
                      if (e.value !== +idVariante)
                        history.push(`/scenario/${idScenario}/dashboard/variant/${e.value}`);
                    }}
                  />
                </div>
              </div>
            )}
            <div className='d-flex align-items-center justify-content-center'>
              <h2 className='mb-0'>
                <span className='scenario-details pl-0'>
                  {/* <Trans code='A0069' />: {owner.username} - {owner.organization} */}
                  <Trans code='A0069' />: {owner?.username} - {owner.companies?.[0]?.name}
                </span>
                <span className='scenario-details'>
                  <Trans code='A0070' />: {scenario.createdAt ? date(scenario.createdAt) : 'n.d.'}
                </span>
                <span
                  // className={
                  //   variant && !variant?.dataset ? 'scenario-details no-pipe' : 'scenario-details'
                  // }>
                  className={'scenario-details no-pipe'}>
                  <Trans code='A0071' />: {scenario.updatedAt ? date(scenario.updatedAt) : 'n.d.'}
                </span>
                {/* {variant && variant?.dataset.id ? (
                <span className='scenario-details no-pipe'>
                  <Trans code='A0543' />: {variant && variant?.dataset.name}
                </span>
              ) : null} */}
              </h2>
            </div>
          </div>

          {/* <span className='scenario-details'>Status: {scenario.status}</span> */}
        </div>
        {variant && visible && (
          <div className='mt-1'>
            <DownloadButton
              disabled={variant?.publish === 0}
              data={data}
              downloadProgress={downloadProgress}
              handleDownload={handleDownload}
            />
          </div>
        )}
      </div>
      <div className='row d-flex justify-content-center'>{sbCloneDownloadShow}</div>
    </>
  );
};
export default ScenarioHeaderCompact;
