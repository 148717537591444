import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  InputGroup,
  Spinner /* Accordion */,
} from 'react-bootstrap';
import Select from 'react-select';
import { Trans, propTrans } from '@lib/i18n';
import {
  getDatasetRegions,
  getSectors,
  getExpenseItems,
  postFinancialData,
  deleteFinancialData,
  getOEScenGeoExt,
  getOEScenGeoPrecision,
  getOEScenGeoDatasetType,
  getOEScenGeoDataset,
  getScenarioVariant,
  putScenarioVariant,
} from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import RendererSwitch from './datasetEconomicData.js/RendererSwitch';
import EconomicDataSummary from './economicData/EconomicDataSummary';
import { fetchData } from '@lib/helpers';

const transformOptions = (data, setState) => {
  const options = data.map((el) => ({
    value: el.code,
    label: propTrans({ prop: el.code }),
    isDisabled: !el.employable,
  }));
  setState(options);
};

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function calculateSumOfPercentage(payload) {
  const number = payload.reduce((tot, elem) => {
    return tot + Number(elem.percentage);
  }, 0);
  if (number <= 100 && number >= 99.9999999999) {
    const roundedNumber = Number.parseFloat(number).toFixed(1);
    const sumOfPercentage = +String(roundedNumber).split('.')[0];
    return Math.ceil(sumOfPercentage);
  } else {
    return Number.parseFloat(number).toFixed(2);
  }
}

const StepSubDataset = ({ scen, setScen, elaborationResultsHasError, originScenario }) => {
  const { idScenario = null, idVariante = null } = useParams();
  const [scenario, setScenario] = useState({});
  const [sectors, setSectors] = useState([]);
  const [expenseItems, setExpenseItems] = useState([]);
  const [originDatasetId, setOriginDatasetId] = useState(null);
  const [optionsGeoExtentions, setOptionsGeoExtentions] = useState([]);
  const [selectedGeoExtention, setSelectedGeoExtention] = useState([]);
  const [optionsPrecisions, setOptionsPrecisions] = useState([]);
  const [selectedPrecision, setSelectedPrecision] = useState([]);
  const [optionsDatasetTypes, setOptionsDatasetTypes] = useState([]);
  const [selectedDatasetType, setSelectedDatasetType] = useState([]);
  const [optionsDatasets, setOptionsDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState([]);
  const [datasetRegions, setDatasetRegions] = useState([]);
  const [showModalChangeDataset, setShowModalChangeDataset] = useState(false);
  const [showModalDeleteRegion, setShowModalDeleteRegion] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [switchElement, setSwitchElement] = useState(null);
  const [sbShow, setSbShow] = useState(<></>);
  const [regionPayload, setRegionPayload] = useState([]);
  const [datasetForRegions, setDatasetForRegions] = useState(null);
  const [sumOfPercentage, setSumOfPercentage] = useState(100);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [showRegions, setShowRegions] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [statusBarMessage, setStatusBarMessage] = useState('');
  const [statusBarType, setStatusBarType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const classId = originScenario?.classId;
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions(window.innerWidth);
    }, 300);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    const transformScenarioVariant = (data) => {
      let tempScenario = { ...data };
      setScenario(() => ({
        ...tempScenario,
        dataset: {
          ...tempScenario.dataset,
          ...(!tempScenario.dataset && {
            geoExtCode: '',
            precisionCode: '',
            typeCode: '',
          }), //conditionally add/change properties
        },
      }));
      setDatasetForRegions(scen.datasetId);
      setOriginDatasetId(scen.datasetId);
    };
    fetchData(
      getScenarioVariant,
      transformScenarioVariant,
      null,
      [idScenario, idVariante],
      handleStatusBar
    );
  }, [idVariante, scen.datasetId]);

  useEffect(() => {
    fetchData(getSectors, setSectors, null, [], handleStatusBar);
    fetchData(getExpenseItems, setExpenseItems, null, [], handleStatusBar);
  }, []);

  useEffect(() => {
    if (originDatasetId || datasetForRegions) {
      fetchData(
        getDatasetRegions,
        setDatasetRegions,
        null,
        [datasetForRegions ? datasetForRegions : originDatasetId],
        handleStatusBar
      );
    }
  }, [originDatasetId, datasetForRegions]);

  //PRIMA CHIAMATA
  useEffect(() => {
    if (originScenario?.classId) {
      fetchData(
        getOEScenGeoExt,
        setOptionsGeoExtentions,
        transformOptions,
        [originScenario?.classId],
        handleStatusBar
      );
    }
  }, [originScenario?.classId]);

  useEffect(() => {
    if (scenario?.dataset?.geoExtCode && originScenario?.classId) {
      fetchData(
        getOEScenGeoPrecision,
        setOptionsPrecisions,
        transformOptions,
        [originScenario?.classId, scenario.dataset.geoExtCode],
        handleStatusBar
      );
    }
  }, [originScenario?.classId, scenario]);

  useEffect(() => {
    if (scenario?.dataset?.precisionCode && originScenario?.classId) {
      fetchData(
        getOEScenGeoDatasetType,
        setOptionsDatasetTypes,
        transformOptions,
        [originScenario?.classId, scenario.dataset.geoExtCode, scenario.dataset.precisionCode],
        handleStatusBar
      );
    }
  }, [scenario, originScenario?.classId]);

  useEffect(() => {
    if (scenario?.economicData?.length > 0 && scenario.datasetId === +scen?.dataset?.id) {
      let tempObj = [];
      let tempRegion;
      let tempPercentage;
      scenario.economicData.forEach((el) => {
        tempRegion = el.regionCode;
        // tempPercentage = el.regionCapexShare ? el.regionCapexShare * 100 : 0;
        tempPercentage = el.regionCapexShare ? el.regionCapexShare : 0;
        tempObj.push({ regionCode: tempRegion, percentage: tempPercentage });
      });
      setRegionPayload(tempObj);
    } else {
      setRegionPayload([]);
    }
  }, [scenario.economicData, scenario.datasetId, scen?.dataset?.id]);

  useEffect(() => {
    if (
      scenario.dataset &&
      scenario.dataset.geoExtCode &&
      scenario.dataset.precisionCode &&
      scenario.dataset.typeCode &&
      originScenario?.classId
    ) {
      const geoExtCode = scenario.dataset.geoExtCode;
      const precisionCode = scenario.dataset.precisionCode;
      const typeCode = scenario.dataset.typeCode;

      fetchData(
        getOEScenGeoDataset,
        setOptionsDatasets,
        (data, setState) => {
          const options = data.map((dataset) => ({
            value: dataset.id,
            label: dataset.name,
          }));
          setState(options);
        },
        [originScenario?.classId, geoExtCode, precisionCode, typeCode],
        handleStatusBar
      );
    }
  }, [scenario.dataset]);

  useEffect(() => {
    setSelectedGeoExtention(
      ...optionsGeoExtentions.filter(
        (optionGeoExt) => optionGeoExt.value === scenario.dataset?.geoExtCode
      )
    );

    setSelectedPrecision(
      !scenario.dataset?.precisionCode
        ? ''
        : optionsPrecisions.filter(
            (optionsPrecision) => optionsPrecision.value === scenario.dataset?.precisionCode
          )
    );

    setSelectedDatasetType(
      optionsDatasetTypes.filter(
        (optionsDatasetType) => optionsDatasetType.value === scenario.dataset?.typeCode
      )
    );

    setSelectedDataset(
      optionsDatasets.filter((optionDataset) => Number(optionDataset.value) === scenario.datasetId)
    );
  }, [scenario, optionsGeoExtentions, optionsPrecisions, optionsDatasetTypes, optionsDatasets]);

  useEffect(() => {
    if (isConfirmed) {
      handleEditScenario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  useEffect(() => {
    setSumOfPercentage(calculateSumOfPercentage(regionPayload));
  }, [regionPayload, scenario]);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const onRegionPercChange = (e, regionCode) => {
    setRegionPayload((prevRegionPayload) => {
      const updatedRegions = prevRegionPayload.map((region) => {
        if (region.regionCode === regionCode) {
          return {
            ...region,
            percentage: isNaN(e.target.valueAsNumber) ? 0 : e.target.valueAsNumber,
          };
        }
        return region;
      });
      return updatedRegions;
    });
  };
  const onChange = async (e, fieldName, setSelect, index) => {
    switch (fieldName) {
      case 'geoExtCode':
        setSelect(e ?? []);
        setScenario(() => ({
          ...scenario,
          datasetId: '',
          dataset: {
            ...scenario.dataset,
            [fieldName]: e.value,
            precisionCode: '',
            typeCode: '',
          },
        }));

        break;
      case 'precisionCode':
        setSelect(e ?? []);
        setScenario(() => ({
          ...scenario,
          datasetId: '',
          dataset: {
            ...scenario.dataset,
            [fieldName]: e.value,
            typeCode: '',
          },
        }));

        break;
      case 'typeCode':
        setSelect(e ?? []);
        setScenario(() => ({
          ...scenario,
          datasetId: '',
          dataset: {
            ...scenario.dataset,
            [fieldName]: e.value,
          },
        }));

        break;
      case 'datasetId':
      case 'address':
        setScenario(() => ({
          ...scenario,
          [fieldName]: fieldName === 'datasetId' ? Number(e.value) : e.target.value,
        }));

        break;
      case 'switch':
        const element = document.getElementById(`action-${index}`); //pulsanti actions

        if (e.target.checked) {
          //show
          setRegionPayload([...regionPayload, { regionCode: e.target.name, percentage: 0 }]);
        } else {
          //hide
          e.preventDefault();
          setSwitchElement(document.getElementById(e.target.id));
          handleShowModal(setShowModalDeleteRegion);
        }
        break;
      default:
        break;
    }
  };

  const disableRegion = (e) => {
    setRegionPayload(regionPayload.filter((el) => el.regionCode !== e.target.name));
  };

  const checkConfirmation = async (boolean, section, setShowModal) => {
    if (section === 'dataset') {
      setIsConfirmed(boolean);

      if (boolean) {
        handleEditScenario();
      } else {
        if (scenario.datasetId !== scenario.dataset.id) {
          setSelectedDataset(
            optionsDatasets.filter(
              (optionDataset) => Number(optionDataset.value) === scenario.dataset.id
            )
          );
        }

        setShowModal(false);
      }
    } else if (section === 'region') {
      if (boolean) {
        const result = await deleteFinancialData(scenario.id, idVariante, switchElement.name);

        if (result.status) {
          // const resultUpdate = await getOEScenario(scenario.id);
          const resultUpdate = await getScenarioVariant(scenario.scenarioId, idVariante);

          if (resultUpdate.status) {
            setScenario(resultUpdate.data);
            switchElement.checked = false;
          }
        }
      } else {
        switchElement.checked = true;
      }

      setShowModal(false);
    }
  };

  const handleEditScenario = async (e) => {
    e?.preventDefault();

    setShowModalChangeDataset(false);
    setSbShow(<></>);

    const restScenario = {
      datasetId: scenario.datasetId,
      geoExtCode: scenario.dataset.geoExtCode,
      precisionCode: scenario.dataset.geoExtCode,
      typeCode: scenario.dataset.typeCode,
    };

    const isScenarioFilled = Object.values(restScenario).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;

    if (!isScenarioFilled) {
      handleStatusBar('error', propTrans({ prop: 'A0568' }), false);
      return;
    }

    if (!isConfirmed && originDatasetId && scenario.datasetId !== originDatasetId) {
      handleShowModal(setShowModalChangeDataset);
      return;
    }

    const { analysisResults, ...scenarioToPut } = scenario;
    setIsLoading(true);
    // const editResult = await putScenario(scenarioToPut);
    const editResult = await putScenarioVariant(idScenario, idVariante, scenarioToPut);

    if (editResult.status === true) {
      // const editResult = await putScenario(scenario);
      const regionEconomic = await postFinancialData(
        scenario.scenarioId,
        idVariante,
        regionPayload
      );
      if (regionEconomic.status === true) {
        // const scenarioRes = await getOEScenario(scenario.id);
        const scenarioRes = await getScenarioVariant(scenario.scenarioId, idVariante);

        if (scenarioRes.status === true) {
          handleStatusBar('success', propTrans({ prop: 'A0560' }), true);
          setIsConfirmed(false);
          setIsLoading(false);
          setScenario(scenarioRes.data);
          setScen(scenarioRes.data); //per subtabs
          setShowRegions(false);
        }
      } else {
        handleStatusBar('error', regionEconomic.message || propTrans({ prop: 'A0521' }), false);
        setIsLoading(false);
      }
    } else {
      handleStatusBar('error', propTrans({ prop: 'A0521' }), false);
      setIsLoading(false);
    }
  };

  const totalPerColumn = (filteredRegions, colName, withSyle = true) => {
    const dataFilteredByCol = filteredRegions.map((el) => Number(el[colName]));

    const total = dataFilteredByCol.reduce((prev, curr) => Number(prev) + Number(curr));

    if (!withSyle) {
      return total.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return (
        <span>
          <span
            style={{
              color: `${
                colName === 'totalCapex'
                  ? +total.toFixed(2) === +scenario?.investment.toFixed(2)
                    ? 'green'
                    : 'red'
                  : ''
              }`,
            }}>
            {total.toLocaleString('it-IT', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </span>
      );
    }
  };

  const buildSummaryTable = () => {
    const filteredEconData = scenario?.economicData?.filter((econData) => {
      return datasetRegions.some((region) => {
        return econData.regionCode === region.code;
      });
    });

    const colHeaderNames = ['totalCapex', 'totalOpex', 'totalRevenues'];

    return (
      <table className='economic-data-summary mb-4'>
        <thead>
          <tr>
            <th key='th-empty'></th>
            {colHeaderNames.map((colName) => {
              return (
                <th key={colName} className='text-right cell-width-economic-data'>
                  <Trans
                    code={
                      colName === 'totalCapex'
                        ? 'A0589'
                        : colName === 'totalOpex'
                        ? 'A0590'
                        : colName === 'totalRevenues'
                        ? 'A0591'
                        : ''
                    }
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className='text-right'>
          <tr className='totals-row'>
            <td className='text-left'>
              <strong>
                <Trans code='A0111' />
              </strong>
            </td>
            {colHeaderNames.map((colName) => (
              <td key={colName + '-total'} className='text-right pr-0'>
                <strong>
                  {Object.keys(filteredEconData).length > 0 &&
                    filteredEconData.length > 0 &&
                    totalPerColumn(filteredEconData, colName)}
                </strong>
              </td>
            ))}
          </tr>

          {filteredEconData.map((econData) => {
            return (
              <tr key={econData.regionCode}>
                <td className='pl-2'>
                  {datasetRegions
                    .filter((region) => region.code === econData.regionCode)
                    .map((region, i) => {
                      return (
                        <div
                          key={region.code}
                          style={{ minWidth: '250px' }}
                          className='gap-3 cell-display-options'>
                          {region.name}
                          <Fragment>
                            <RendererSwitch
                              index={i}
                              analysisMode={scenario.inputTypeCode}
                              regionCode={region.code}
                              regionName={region.name}
                              sectors={sectors}
                              expenseItems={expenseItems}
                              isVisible={
                                scenario.economicData &&
                                scenario.economicData.some(
                                  (el) => el.regionCode === econData.regionCode
                                )
                              }
                              setScenario={setScenario}
                              setShowStatusBar={setShowStatusBar}
                              setStatusBarType={setStatusBarType}
                              setStatusBarMessage={setStatusBarMessage}
                              idScenario={idScenario}
                              idVariante={idVariante}
                            />
                          </Fragment>
                        </div>
                      );
                    })}
                </td>

                {colHeaderNames.map((colName) => (
                  <td className='pr-0' key={colName + '-td'}>
                    {filteredEconData
                      .filter((el) => el.regionCode === econData.regionCode)
                      .map((el, i) => {
                        return (
                          <Fragment key={el.regionCode}>
                            {Number(el[colName]).toLocaleString('it-IT', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Fragment>
                        );
                      })}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {sbShow}

      {/* GEOGRAPHY */}
      <Form onSubmit={handleEditScenario} id='form-subdataset'>
        <div className='simple-box'>
          <Row>
            <Col lg={12} className='form-login'>
              <h5 className='title-simple-box margin-title-box'>
                <Trans code='A0528' />
              </h5>
              <Row className='mb-3 gap-2'>
                <Form.Group as={Col} controlId='geoExtCode'>
                  <Form.Label>
                    <Trans code='A0565' />
                  </Form.Label>
                  <Select
                    name='geoExtCode'
                    value={selectedGeoExtention}
                    options={optionsGeoExtentions}
                    onChange={(e) => onChange(e, 'geoExtCode', setSelectedGeoExtention)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='precisionCode'>
                  <Form.Label>
                    <Trans code='A0566' />
                  </Form.Label>
                  <Select
                    name='precisionCode'
                    value={selectedPrecision}
                    options={optionsPrecisions}
                    onChange={(e) => onChange(e, 'precisionCode', setSelectedPrecision)}
                    isDisabled={!selectedGeoExtention}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-3 gap-2'>
                <Form.Group as={Col} controlId='typeCode'>
                  <Form.Label>
                    <Trans code='A0567' />
                  </Form.Label>
                  <Select
                    name='typeCode'
                    value={selectedDatasetType}
                    options={optionsDatasetTypes}
                    onChange={(e) => onChange(e, 'typeCode', setSelectedDatasetType)}
                    isDisabled={!selectedPrecision}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='datasetId'>
                  <Form.Label>
                    <Trans code='A0387' />
                  </Form.Label>
                  <Select
                    name='datasetId'
                    value={selectedDataset}
                    options={optionsDatasets}
                    onChange={(e) => {
                      setDatasetForRegions(+e.value);
                      setShowRegions(true);
                      onChange(e, 'datasetId', setSelectedDataset);
                    }}
                    isDisabled={
                      scenario.dataset &&
                      scenario.dataset?.geoExtCode &&
                      scenario.dataset?.precisionCode &&
                      scenario.dataset?.typeCode
                        ? false
                        : true
                    }
                  />
                </Form.Group>
              </Row>

              {scenario.class && scenario.class.localization && scenario.address ? (
                <Row className='mb-3 gap-2'>
                  <Form.Group as={Col} controlId='address'>
                    <Form.Label>
                      <Trans code='A0569' />
                      &nbsp;
                      <span className='optional-field'>
                        (<Trans code='A0570' />)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='address'
                      value={scenario.address === false ? '' : scenario.address}
                      onChange={(e) => onChange(e, 'address')}
                    />
                  </Form.Group>
                </Row>
              ) : null}
            </Col>
          </Row>
        </div>
        {scen.datasetId && scen.datasetId === scenario.datasetId && (
          <Button
            // disabled={sumOfPercentage !== 100}
            onClick={() => {
              setShowRegions((prevState) => !prevState);
            }}
            className='submit-button mt-5 mb-5'>
            <Trans code='A0707' />
          </Button>
        )}
        {/* RIATTIVARE QUESTO CHECK */}
        {datasetRegions.length > 0 && showRegions && scenario.datasetId ? (
          <>
            <div id='expenses-localization' className='simple-box fade-in-div'>
              <Row className='mb-3'>
                <Col lg={12} className='form-login'>
                  <h5 className='title-simple-box margin-title-box'>
                    <Trans code='A0581' />
                  </h5>
                </Col>
              </Row>
              <Row id='row-regions' className='mb-4'>
                {Object.keys(datasetRegions).length > 0 &&
                  datasetRegions
                    .sort((a, b) => Number(a.position) - Number(b.position))
                    .map((region, i) => (
                      <Col className='' key={region.code}>
                        <Row className='align-items-center'>
                          <Col className='col-auto' style={{ minWidth: '11rem' }}>
                            {region.name}
                          </Col>
                          <Col className='col-2 text-center'>
                            <Form.Check
                              inline
                              type='switch'
                              id={`switch-${i}`}
                              name={region.code}
                              aria-label={region.code}
                              className='custom-switch-md m-auto'
                              checked={
                                regionPayload &&
                                regionPayload.some((el) => el.regionCode === region.code)
                                // scenario.economicData &&
                                // scenario.economicData.some((el) => el.regionCode === region.code)
                              }
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  onChange(e, 'switch', undefined, i);
                                } else {
                                  disableRegion(e);
                                }
                              }}
                            />
                          </Col>
                          <Col xs={5} xxl={6} className='pl-4'>
                            <InputGroup
                              style={{
                                visibility:
                                  regionPayload &&
                                  regionPayload.some((el) => el.regionCode === region.code)
                                    ? 'visible'
                                    : 'hidden',
                              }}
                              className='inputgroup regionInputPercentage'>
                              <Form.Control
                                step='.01'
                                className='text-right'
                                type='number'
                                name='regionPercentage'
                                min={0}
                                max={100}
                                value={
                                  regionPayload.some((el) => el.regionCode === region.code)
                                    ? regionPayload
                                        .filter((el) => el.regionCode === region.code)
                                        .map((el) => el.percentage)
                                    : 0
                                }
                                onChange={(e) => onRegionPercChange(e, region.code)}
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                        {dimensions < 1280 ? i === datasetRegions.length - 1 ? null : <hr /> : null}
                        {dimensions >= 1280 && dimensions < 2048 ? (
                          Number.isInteger(datasetRegions.length / 2) &&
                          i >= datasetRegions.length - 2 ? null : !Number.isInteger(
                              datasetRegions.length / 2
                            ) && i > datasetRegions.length - 2 ? null : (
                            <hr />
                          )
                        ) : null}
                        {dimensions >= 2048 ? (
                          datasetRegions.length % 3 === 0 &&
                          i >= datasetRegions.length - 3 ? null : datasetRegions.length % 3 !== 0 &&
                            i >= datasetRegions.length - (datasetRegions.length % 3) ? null : (
                            <hr />
                          )
                        ) : null}
                      </Col>
                    ))}
              </Row>
              <Row className='mt-4 d-flex justify-content-end align-items-center'>
                <Col style={{ minWidth: '300px' }} className='col-3'>
                  {regionPayload.length > 0 && (
                    <div
                      className={`total-capex d-flex justify-content-between alert ${
                        sumOfPercentage === 0
                          ? 'alert-warning'
                          : sumOfPercentage === 100
                          ? 'alert-success'
                          : 'alert-danger'
                      }`}>
                      <span className='font-weight-bold'>
                        {/* <Trans code='A0111' /> */}
                        Totale percentuale
                      </span>
                      <span className='font-weight-bold text-right'>{sumOfPercentage}/100</span>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <Button
              disabled={sumOfPercentage !== 100 || isLoading}
              form='form-subdataset'
              type='submit'
              className='submit-button mt-5 mb-5'>
              {!isLoading ? (
                <Trans code='A0561' />
              ) : (
                <span className='d-flex align-items-center ml-2'>
                  <Trans code='A0782' />
                  <Spinner animation='border' className='ml-2' size='sm' />
                </span>
              )}
            </Button>
          </>
        ) : null}
        {scen.datasetId &&
        scenario.economicData?.length > 0 &&
        scenario.datasetId === +scen?.dataset?.id ? (
          <div id='expenses-localization-summary' className='simple-box mb-5 fade-in-div'>
            <Row className='mb-1'>
              <Col lg={12} className='form-login'>
                <h5 className='title-simple-box margin-title-box'>
                  <Trans code='A0594' />
                </h5>
              </Col>
              <Col>
                {showStatusBar && <StatusBar status={statusBarType} message={statusBarMessage} />}
              </Col>
            </Row>
            {scenario.economicData?.length > 0 && datasetRegions?.length > 0 && buildSummaryTable()}
          </div>
        ) : null}

        {/* ECONOMIC DATA */}
        {!elaborationResultsHasError &&
        scen.datasetId &&
        scenario[
          'economicData' +
            scenario?.inputTypeCode?.charAt(0).toUpperCase() +
            scenario?.inputTypeCode?.slice(1).toLowerCase()
        ]?.length > 0 &&
        scenario.datasetId === +scen?.dataset?.id &&
        scen[
          'economicData' +
            scen?.inputTypeCode?.charAt(0).toUpperCase() +
            scen?.inputTypeCode?.slice(1).toLowerCase()
        ]?.length > 0 &&
        datasetRegions?.length > 0 &&
        scenario?.analyses.some((el) => el.type === 'SROI') ? (
          <EconomicDataSummary
            scenario={scenario}
            datasetRegions={datasetRegions}
            totalPerColumn={totalPerColumn}
            sectors={sectors}
            expenseItems={expenseItems}
            setScenario={setScenario}
            idScenario={idScenario}
            idVariante={idVariante}
          />
        ) : null}
      </Form>
      <Modal
        show={showModalChangeDataset}
        onHide={() => handleCloseModal(setShowModalChangeDataset)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <Trans code='A0571' />
            <span className='d-block'>
              <Trans code='A0572' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => checkConfirmation(false, 'dataset', setShowModalChangeDataset)}>
            <Trans code='F0057' />
          </Button>
          <Button
            variant='success'
            onClick={() => checkConfirmation(true, 'dataset', setShowModalChangeDataset)}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalDeleteRegion}
        onHide={() => handleCloseModal(setShowModalDeleteRegion)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <Trans code='A0582' />
            <span className='d-block'>
              <Trans code='A0572' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => checkConfirmation(false, 'region', setShowModalDeleteRegion)}>
            <Trans code='F0057' />
          </Button>
          <Button
            variant='success'
            onClick={() => checkConfirmation(true, 'region', setShowModalDeleteRegion)}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default StepSubDataset;
