import { useState } from 'react';
import { Trans } from '@lib/i18n';
import Select from 'react-select';

import { mapData } from 'utils/mapData';
import ChoropletMap from 'components/ChoroplethMap/ChoroplethMap';
// import CustomSelect from 'components/CustomSelect/CustomSelect';
const DashboardHeatMap = (props) => {
  const { syntheticResults, dataSource } = props;

  const colors = {
    Investimento: ['#9DC4E1', '#193C56'],
    PIL: ['#E9ACB8', '#BA314D'],
    Occupazione: ['#BFE1E5', '#499FA8'],
    Tasse: ['#EBEFA1', '#C3C949'],
    Redditi: ['#FDDFAA', '#F4B644'],
  };

  const [isHexGrid, toggleIsHexGrid] = useState(false);
  // const [dataSource, setDataSource] = useState(Object.keys(colors)[1]);
  const [selectOptions, setSelectOptions] = useState([
    { value: 'Investimento', label: 'Investment' },
    // { value: 'Tasse', label: 'Taxes' },
    { value: 'PIL', label: 'GDP' },
    { value: 'Redditi', label: 'Income' },
    { value: 'Occupazione', label: 'Employment' },
  ]);

  // const dataChangeHandler = (e) => {
  //   setDataSource(e.value);
  // };
  // const dataChangeHandler = (e) => {
  //   setDataSource(e.target.value);
  // };

  return (
    // <div className='simple-box impact' id={dataSource}>
    //   <div className='row'>
    //     <div className='col'>
    //       <h5 className='title-simple-box margin-title-box'>
    //         <Trans code='A0773' />
    //         {/* <CustomSelect
    //           value={dataSource}
    //           onChange={dataChangeHandler}
    //           dataList={Object.keys(colors)}
    //           colors={colors[dataSource]}
    //         /> */}
    //       </h5>
    //     </div>
    //     {/* <div className='col-3'>
    //       <Select
    //         name='datasourceSelect'
    //         value={selectOptions?.find((el) => el.value === dataSource)}
    //         options={selectOptions}
    //         onChange={(e) => dataChangeHandler(e)}
    //       />
    //     </div> */}
    //   </div>
    //   <div className='d-flex flex-wrap justify-content-around align-items-center'>
    <>
      {syntheticResults && (
        <ChoropletMap
          metrics={mapData(syntheticResults)}
          dataSource={dataSource}
          dataList={Object.keys(colors)}
          isHexGrid={isHexGrid}
          colors={colors}
          id={dataSource}
        />
      )}
    </>
    //   </div>
    // </div>
  );
};

export default DashboardHeatMap;
