import React from 'react';
import style from './Breadcrumbs.module.css';
function Breadcrumbs({ paths, setState, setClickState, world }) {
  return (
    <div className='mt-4'>
      {paths.map((path, index) => (
        <span
          className={style.link}
          key={`breadcrumb${path.title}${index}`}
          onClick={() => {
            setClickState(false);
            setState(world);
          }}
          style={{
            textDecoration: index === paths.length - 1 || index > 0 ? 'underline' : 'none',
            fontWeight: index === paths.length - 1 || index > 0 ? '600' : '100',
          }}>
          {path.title}
          {index === paths.length - 1 || !path ? '' : ' > '}
        </span>
      ))}
    </div>
  );
}
export default Breadcrumbs;
