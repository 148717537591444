import React from 'react';
import Select from 'react-select';
import style from './CustomSelectNew.module.css';

function CustomSelectNew({ value, onChange, dataList, colors }) {
  return (
    <div className='d-flex justify-content-end'>
      <Select
        value={{ value: value, label: value }}
        options={dataList.map((d) => ({ label: d, value: d }))}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        isSearchable={true}
        className={style.inputSelect}
      />
    </div>
  );
}

export default CustomSelectNew;
