import React from 'react';
import { Trans } from '@lib/i18n/index';
const EmptyDataDisplay = ({ isCard, transCode }) => {
  return (
    <div className={`${isCard && 'simple-box'} d-flex justify-content-center align-items-center`}>
      <h2 className='title-simple-box margin-title-box'>
        <Trans code={transCode} />
      </h2>
    </div>
  );
};

export default EmptyDataDisplay;
