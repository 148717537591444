import React, { useState } from "react";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { IcoCanc } from "@icons";
import { Trans } from "@lib/i18n/index";

const KpiFormUpload = ({ onFileSelected, formErrors }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    onFileSelected(selectedFile);
  };

  const handleFileRemove = () => {
    setFile(null);
    onFileSelected(null);
  };

  return (
    <Form>
      <Row className="mb-3">
        <Col className="px-0">
          <Form.Group>
            <Form.Label>
              <Trans code={"A0584"} />
            </Form.Label>
            <InputGroup
              {...(formErrors && { style: { border: "1px solid red" } })}
              className="mb-3"
            >
              <div className="custom-file">
                {file ? (
                  <Col className="d-flex justify-content-start align-items-center w-100 h-100 p-3 border rounded ">
                    <p className="mb-0 mr-1">{file.name}</p>
                    <Button
                      variant="primary-outline"
                      onClick={handleFileRemove}
                    >
                      <IcoCanc className="second-color ico-small cancel" />
                    </Button>
                  </Col>
                ) : (
                  <>
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={handleFileChange}
                    />
                    <label className="custom-file-label border rounded">
                      <Trans code="A0574" />
                    </label>
                  </>
                )}
              </div>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default KpiFormUpload;
