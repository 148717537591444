import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Trans, propTrans } from '@lib/i18n';
import { IcoCanc, IcoCopy, IcoEdit, IcoPlus, IcoMinusCircled } from '@icons';
import { putEditOEKpi, copyOEKpi, deleteOEKpi } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import AssociatedScenarioTable from '../associatedScenario/associatedScenarioTable';
import FormulaTester from 'components/FormulaTester/FormulaTester';

const ActionBtnRenderer = ({ data, setRefresh, optionsVar }) => {
  const id = data.id;
  const [kpi, setKpi] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalCopy, setShowModalCopy] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [associatedScenarios, setAssociatedScenarios] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  console.log('selected kpi', kpi);

  useEffect(() => {
    setKpi(data);
    setAssociatedScenarios(data?.associatedScenarios?.length > 0 ? true : false);
  }, [data]);

  useEffect(() => {
    indicatorsRows();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi.indicators]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(null);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const onChange = (e, fieldname, setSelect, indicatorIndex, section) => {
    if (section === 'indicators') {
      let tempIndicators = [...kpi.indicators];

      let tempElement = {
        ...tempIndicators[indicatorIndex],
        [e.target ? e.target.name : fieldname]: e.target
          ? e.target.name === 'imposedValue'
            ? Number(e.target.value)
            : e.target.value
          : e.value,
      };

      tempIndicators[indicatorIndex] = tempElement;

      setKpi(() => ({
        ...kpi,
        indicators: tempIndicators,
      }));
    } else if (setSelect) {
      setSelect(e ?? []);

      setKpi(() => ({
        ...kpi,
        [fieldname]: e ? e.value ?? e : [],
      }));
    } else {
      setKpi(() => ({
        ...kpi,
        [e.target.name]:
          e.target.name === 'formula' ? e.target.value.toUpperCase() : e.target.value,
      }));
    }
  };

  const addIndicator = () => {
    let tempIndicators = [...kpi.indicators];

    tempIndicators.push({ var: '', indicator: '', udm: '' });

    setKpi(() => ({
      ...kpi,
      indicators: tempIndicators,
    }));
  };

  const removeIndicator = (index) => {
    let tempIndicators = [...kpi.indicators];

    tempIndicators.splice(index, 1);

    setKpi(() => ({
      ...kpi,
      indicators: tempIndicators,
    }));
  };

  const handleEditStatus = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { description, indicators, ...restKpi } = kpi;

    const isKpiFilled = Object.values(restKpi).every((prop) => prop !== '' && prop != null)
      ? true
      : false;
    const areIndicatorsFilled = indicators.every(
      (prop) => prop.var !== '' && prop.indicator !== '' && prop.udm !== ''
    )
      ? true
      : false;
    console.log('indicators', isKpiFilled, areIndicatorsFilled);

    if (!isKpiFilled || !areIndicatorsFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }
    handleStatusBar('elaborating', false, false);
    const editResult = await putEditOEKpi(kpi, id);

    if (editResult.status) {
      handleStatusBar('success', <Trans code='A0524' />, true);

      setTimeout(() => {
        setSbShow(null);
        setShowModalEdit(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', editResult.message ?? <Trans code='A0521' />, false);
    }
  };

  const handleDelete = async () => {
    const deleteResult = await deleteOEKpi(id);

    if (deleteResult.status) {
      handleStatusBar('success', <Trans code='A0526' />, true);

      setTimeout(() => {
        setSbShow(null);
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', deleteResult.message ?? <Trans code='A0527' />, false);
    }
  };

  const handleCopy = async () => {
    const copyResult = await copyOEKpi(kpi, id);

    if (copyResult.status) {
      handleStatusBar('success', <Trans code='A0683' />, true);

      setTimeout(() => {
        setShowModalCopy(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', copyResult.message ?? <Trans code='A0684' />, false);
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00055' />
    </Tooltip>
  );
  const cloneTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00057' />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00056' />
    </Tooltip>
  );

  const indicatorsRows = (kpiIndicators) => {
    let rows = [];

    if (kpiIndicators) {
      let lastIndex = kpiIndicators.length - 1;

      rows = kpiIndicators.map((el, i) => {
        const { imposedValue, ...restKpiIndicators } = kpiIndicators[i];
        const isThisIndicatorFilled = Object.values(restKpiIndicators).every((prop) => prop !== '')
          ? true
          : false;

        return (
          <Row className='mb-3' key={i}>
            <Col>
              <Row className='pr-2'>
                <Col className='col-3'>
                  <Select
                    isDisabled={associatedScenarios}
                    name='var'
                    placeholder='var'
                    defaultValue={el.var === '' ? '' : [{ value: el.var, label: el.var }]}
                    options={optionsVar}
                    onChange={(e) => onChange(e, 'var', undefined, i, 'indicators')}
                  />
                </Col>
                <Col className='col-9'>
                  <Form.Control
                    className='custom-disabled'
                    disabled={associatedScenarios}
                    type='text'
                    name='indicator'
                    placeholder='default name'
                    defaultValue={el.indicator}
                    onChange={(e) => onChange(e, 'indicator', undefined, i, 'indicators')}
                  />
                  <Link to={`/administration/texts/${el.langTag}`} className='link-langtag'>
                    <Trans code='A0473' replacementText='Manage translation' />
                  </Link>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className='pl-2 align-items-center'>
                <Col className='col-4'>
                  <Form.Control
                    className='custom-disabled'
                    disabled={associatedScenarios}
                    type='text'
                    name='udm'
                    maxLength='10'
                    placeholder='udm'
                    defaultValue={el.udm}
                    onChange={(e) => onChange(e, 'udm', undefined, i, 'indicators')}
                  />
                </Col>
                <Col className='col-5'>
                  <Form.Control
                    className='custom-disabled'
                    disabled={associatedScenarios}
                    type='number'
                    name='imposedValue'
                    placeholder='imposed value'
                    step={0.01}
                    defaultValue={el.imposedValue}
                    onChange={(e) => onChange(e, 'imposedValue', undefined, i, 'indicators')}
                  />
                </Col>
                <Col>
                  {isThisIndicatorFilled && i === lastIndex ? (
                    <Button
                      disabled={associatedScenarios}
                      variant='link'
                      className='mr-3'
                      onClick={() => addIndicator()}>
                      <IcoPlus className='second-color ico-small indicators' />
                    </Button>
                  ) : null}
                  {kpiIndicators.length !== 1 ? (
                    <Button
                      disabled={associatedScenarios}
                      variant='link'
                      onClick={() => removeIndicator(i)}>
                      <IcoMinusCircled className='red-color ico-small indicators' />
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      });
    }

    return rows;
  };

  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={cloneTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalCopy)}>
          <IcoCopy className='second-color ico-small copy' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button
          disabled={associatedScenarios}
          variant='link'
          className='mr-0'
          onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      <Modal
        size='xl'
        id='kpi-modal'
        show={showModalEdit}
        onHide={() => handleCloseModal(setShowModalEdit)}
        centered>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className='px-2'>
            <Trans code='A0679' />
          </Modal.Title>
        </Modal.Header>
        <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} justify>
          {/* <Tab eventKey='general' title={associatedScenarios ? propTrans({ prop: 'A0041' }) : null}> */}
          <Tab eventKey='general' title={propTrans({ prop: 'A0041' })}>
            <Modal.Body>
              <Form onSubmit={handleEditStatus} id='form-admin-kpi'>
                <Row className='mb-3'>
                  <Col className='px-0'>
                    <Form.Group className='pl-4 pr-2' controlId='name'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0670' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        className='custom-disabled'
                        disabled={associatedScenarios}
                        type='text'
                        name='name'
                        value={kpi.name}
                        onChange={(e) => onChange(e)}
                      />
                      <Link to={`/administration/texts/${kpi.langTag}`} className='link-langtag'>
                        <Trans code='A0473' replacementText='Manage translation' />
                      </Link>
                    </Form.Group>
                    <Form.Group className='pl-4 pr-2' controlId='udm'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0671' />
                        &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        className='custom-disabled'
                        disabled={associatedScenarios}
                        type='text'
                        name='udm'
                        placeholder='es. cm'
                        maxLength='10'
                        value={kpi.udm}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='px-4' controlId='description'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0621' />
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={6}
                        name='description'
                        value={kpi.description ?? ''}
                        onChange={(e) => onChange(e)}
                      />
                      <Link
                        to={`/administration/texts/${kpi.langTagDesc}`}
                        className='link-langtag'>
                        <Trans code='A0473' replacementText='Manage translation' />
                      </Link>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} className='px-4 mb-0' controlId='indicators'>
                    <Form.Label className='mb-3 mr-4'>
                      <Trans code='A0678' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    {kpi.indicators ? indicatorsRows(kpi.indicators) : null}
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} className='col-6 px-4' controlId='formula'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0466' replacementText='Formula' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Control
                      className='custom-disabled'
                      disabled={associatedScenarios}
                      type='text'
                      name='formula'
                      placeholder='es. (A+B)*C'
                      value={kpi.formula}
                      onChange={(e) => onChange(e)}
                    />
                  </Form.Group>
                </Row>
              </Form>
              <FormulaTester />
              {sbShow}

              <Row>
                <Col className='col-4 px-3'>
                  <span className='px-3'>
                    <span className='required-field'>*</span>&nbsp;
                    <Trans code='A0503' />
                  </span>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col className='px-3'>
                <span>
                  <Trans code='A0680' />
                </span>
              </Col>
              <Col className='px-3 text-right'>
                <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                  <Trans code='F0057' />
                </Button>
                <Button variant='primary' onClick={(e) => handleEditStatus(e)} className='ml-3'>
                  <Trans code='A0561' />
                </Button>
              </Col>
            </Modal.Footer>
          </Tab>
          {/* {associatedScenarios && (
            <Tab eventKey='associatedScenarios' title={propTrans({ prop: 'A0468' })}>
              <Modal.Body className='mb-4 px-5'>
                {<AssociatedScenarioTable associatedScenarios={data?.associatedScenarios} />}
              </Modal.Body>
            </Tab>
          )} */}
          <Tab
            disabled={!associatedScenarios}
            eventKey='associatedScenarios'
            title={propTrans({ prop: 'A0468' })}>
            <Modal.Body className='mb-4 px-5'>
              {<AssociatedScenarioTable associatedScenarios={data.associatedScenarios} />}
            </Modal.Body>
          </Tab>
        </Tabs>
      </Modal>

      <Modal show={showModalCopy} onHide={() => handleCloseModal(setShowModalCopy)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0681' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalCopy)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={() => handleCopy()}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0682' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRenderer;
