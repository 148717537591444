import React, { useState, useEffect } from 'react';
import { Trans } from '@lib/i18n/index';
import { Spinner, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { parse } from 'mathjs';
import { TooltipInfoIcon } from '@icons';

const variableColors = {};

const KpiTable = ({ kpiData, years }) => {
  // State
  const [highlightedCells, setHighlightedCells] = useState({});
  const [popoverContent, setPopoverContent] = useState(null);
  const [activePopover, setActivePopover] = useState(null);

  // Handlers
  const handlePopoverClose = () => {
    setHighlightedCells({});
    setActivePopover(null);
  };

  const handleCellClick = (formula, kpiId, year) => {
    let newHighlightedCells = { ...highlightedCells };
    newHighlightedCells[kpiId] = newHighlightedCells[kpiId] || {};
    let replacedFormula = formula;

    try {
      const parsedFormula = parse(formula);
      const variables = parsedFormula.filter((node) => node.isSymbolNode).map((node) => node.name);

      const variableValueMap = {};
      variables.forEach((variable) => {
        newHighlightedCells[kpiId][variable] = newHighlightedCells[kpiId][variable] || [];
        if (!newHighlightedCells[kpiId][variable].includes(year)) {
          newHighlightedCells[kpiId][variable].push(year);
        }

        const indicator = Object.values(kpiData.kpi[kpiId].indicators).find(
          (indicator) => indicator.var === variable
        );
        const value = indicator ? indicator.values[year] : '?';

        variableValueMap[variable] = value;
      });

      Object.keys(variableValueMap).forEach((variable) => {
        const valueSpan = `<span style="color: ${getColorForVariable(variable)};">${
          variableValueMap[variable] !== '?'
            ? Number(variableValueMap[variable]).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '?'
        }</span>`;
        replacedFormula = replacedFormula.replace(new RegExp(`\\b${variable}\\b`, 'g'), valueSpan);
      });

      const popoverData = {
        variables: variables.map((variable) => {
          return {
            name: variable,
            value: variableValueMap[variable],
            color: getColorForVariable(variable),
          };
        }),
        formula: formula,
        formulaWithValues: replacedFormula,
      };

      setPopoverContent(popoverData);
      setHighlightedCells(newHighlightedCells);
      setActivePopover(`${kpiId}-${year}`);
    } catch (e) {
      console.error('Error parsing formula: ', e);
    }
  };

  // Effects
  const popoverTop = popoverContent ? (
    <Popover id='popover-basic'>
      <Popover.Title as='h3'>
        {popoverContent.formula ? 'Calcolo KPI:' + popoverContent.formula : 'Calcolo KPI'}
      </Popover.Title>
      <Popover.Content>
        {popoverContent.formula ? (
          <>
            <Table className='mt-0' responsive='md'>
              <tbody>
                {popoverContent.variables.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        borderTop: 0,
                        color: item.color,
                        fontWeight: 'bold',
                      }}>
                      {item.name}:
                    </td>
                    <td
                      style={{
                        borderTop: 0,
                        color: item.color,
                        textAlign: 'right',
                      }}>
                      {item.value !== '?'
                        ? Number(item.value).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : item.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <span>Formula:</span>
              <strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: popoverContent.formulaWithValues,
                  }}
                  style={{ paddingLeft: '10px', textAlign: 'right' }}
                />
              </strong>
            </div>
          </>
        ) : (
          <div style={{ marginTop: '10px', display: 'block' }}>
            <strong>Nessuna formula disponibile</strong>
          </div>
        )}
      </Popover.Content>
    </Popover>
  ) : (
    <></>
  );

  // Functions
  const isCellHighlighted = (kpiId, variable, year) => {
    return (
      highlightedCells[kpiId] &&
      highlightedCells[kpiId][variable] &&
      highlightedCells[kpiId][variable].includes(year)
    );
  };

  const getRandomColor = () => {
    // Externalytics palette colors
    const baseColors = [
      { r: 28, g: 39, b: 67 }, // #1C2743
      { r: 8, g: 162, b: 170 }, // #08A2AA
      { r: 194, g: 201, b: 35 }, // #C2C923
    ];

    // Search for a random base color
    const base = baseColors[Math.floor(Math.random() * baseColors.length)];

    // Generate a random offset for each color
    const offset = () => Math.floor(Math.random() * 41) - 20;

    // Validate the offset
    const validateRGB = (value) => Math.min(255, Math.max(0, value));

    // Calculate the final color
    const newR = validateRGB(base.r + offset());
    const newG = validateRGB(base.g + offset());
    const newB = validateRGB(base.b + offset());

    // Convert the color to HEX
    const rgbToHex = (r, g, b) =>
      '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    return rgbToHex(newR, newG, newB);
  };

  const getColorForVariable = (variable) => {
    if (!variableColors[variable]) {
      variableColors[variable] = getRandomColor();
    }
    return variableColors[variable];
  };

  const formatNumber = (number) => {
    return Number(number).toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Hooks and variables
  useEffect(() => {
    if (!activePopover) {
      handlePopoverClose();
      setPopoverContent(null);
    }
  }, [activePopover]);

  const kpis = kpiData.kpi;
  if (!kpiData || !kpiData.kpi) {
    return <Spinner animation='border' />;
  }

  return (
    <table className='show-economic-data'>
      <thead>
        <tr>
          <th key='th-empty'>
            <Trans code='A0079' />
          </th>
          {years.map((year, index) => (
            <th className='text-right cell-width-economic-data' key={index}>
              {year}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='text-right'>
        {/* <tr className="totals-row">
          <td className="text-left">
            <strong>
              <Trans code="A0111" />
            </strong>
          </td>
          {kpiData.values &&
            years.map((year, index) => (
              <td
                style={{
                  fontWeight: "700",
                }}
                className="text-right"
                key={index}
              >
                {formatNumber(kpiData.values[year] || 0)}
              </td>
            ))}
        </tr> */}

        {Object.values(kpis).map((kpi, index) => (
          <React.Fragment key={index}>
            <tr style={{ backgroundColor: '#F3F3F3' }}>
              <td>{kpi.name}</td>
              {years.map((year, index) => (
                <td
                  onClick={() => handleCellClick(kpi.formula, kpi.id, year)}
                  key={index}
                  style={{
                    fontWeight: '550',
                  }}>
                  <OverlayTrigger
                    trigger='click'
                    placement='top'
                    show={activePopover === `${kpi.id}-${year}`}
                    overlay={popoverTop}
                    rootClose
                    onToggle={(visible) => {
                      if (!visible) {
                        handlePopoverClose();
                        setPopoverContent(null);
                      }
                    }}>
                    <span className='tooltipSpan' style={{ position: 'relative' }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1.5rem' }} />
                    </span>
                  </OverlayTrigger>
                  {formatNumber(kpi.values[year] || 0)}
                </td>
              ))}
            </tr>
            {kpi.indicators &&
              Object.values(kpi.indicators).map((indicator, index2) => (
                <tr style={{ backgroundColor: '#FFFFFF' }} key={index2}>
                  <td>
                    <span className='mx-5'>{indicator.name}</span>
                  </td>
                  {years.map((year, index3) => (
                    <td
                      style={{
                        boxShadow: isCellHighlighted(kpi.id, indicator.var, year)
                          ? `0 0 0 2px ${getColorForVariable(indicator.var)} inset`
                          : 'none',
                      }}
                      key={index3}>
                      {formatNumber(indicator.values[year] || 0)}
                    </td>
                  ))}
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default KpiTable;
