import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const StatusBar = ({ status, message, timeout, show, style = {}, showStatus = true }) => {
  if (show === undefined || show === null) {
    show = true;
  }

  const [display, setDisplay] = useState(show);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setDisplay(false);
      }, 5000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    }
  }, [timeout]);

  if (!display) return null;

  if (display) {
    return (
      <div id='statusbar' style={style}>
        {status === 'success' && (
          <div className='simple-box success'>
            {showStatus && <h5 className='d-inline mr-2'>SUCCESS</h5>}{' '}
            {message ? <span>{message}</span> : null}
          </div>
        )}

        {status === 'error' && (
          <div className='simple-box error'>
            {showStatus && <h5 className='d-inline mr-2'>ERROR</h5>}{' '}
            {message ? <span>{message}</span> : null}
          </div>
        )}

        {status === 'elaborating' && (
          <div className='simple-box elaborating'>
            {showStatus && (
              <h5>
                <Spinner animation='border' role='status'></Spinner> ELABORATING
              </h5>
            )}
          </div>
        )}
      </div>
    );
  }
};
export default StatusBar;
