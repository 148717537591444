import React, { useState } from 'react';
import CompaniesCard from './CompaniesCard';

const CompaniesWrapper = ({
  data,
  setShow,
  setNewOrganization,
  title,
  userOrganization = null,
  isSelectable = true,
  setShowModalDelete,
  setDeleteId,
  isUserCompany = false,
  isOe = false,
  userCompanyId,
  isThirdLevel = false,
}) => {
  const [selected, setSelected] = useState(0);

  const filterCompanies = (isChild) => {
    return data.filter((company) => {
      if (isChild) {
        return company.dependsOn === selected;
      } else {
        return isUserCompany
          ? company
          : company.dependsOn && company.dependsOn !== 1
          ? company.dependsOn === userCompanyId
          : company;
      }
    });
  };

  const renderCompanies = (isChild = false) => (
    <div className={`companies-container ${isChild ? 'child-companies' : ''}`}>
      {filterCompanies(isChild).map(({ id, ...rest }) => {
        console.log('CompaniesWrapper.js: ', id, rest);
        return (
          <CompaniesCard
            key={isChild ? `sub_${id}` : id}
            data={{ id, ...rest }}
            selected={selected}
            setSelected={setSelected}
            setShow={setShow}
            setNewOrganization={setNewOrganization}
            isSelectable={isSelectable && !isChild}
            isChild={isChild}
            setShowModalDelete={setShowModalDelete}
            setDeleteId={setDeleteId}
            isUserCompany={isUserCompany}
            isOe={isOe}
            shouldBeAbleToDomain={isThirdLevel && !isOe}
          />
        );
      })}
    </div>
  );
  console.log('isOe');
  return (
    <>
      {filterCompanies(false).length > 0 && (
        <div>
          <h2 className='mb-0 pb-0'>
            {title}
            {userOrganization && ` da: ${userOrganization}`}
          </h2>
          {renderCompanies()}
        </div>
      )}

      {selected > 0 && isOe && (
        <div>
          {filterCompanies(true).length > 0 ? (
            <>
              <h2 className='mb-0 pb-0'>
                Organizzazioni contrattualizzate da:{' '}
                {data.find((el) => el.id === selected)?.name ?? ''}
              </h2>

              {renderCompanies(true)}
            </>
          ) : (
            <h2 className='mb-0 pb-0'>
              Non è stata trovata nessuna organizzazione contrattualizzata da:{' '}
              {data.find((el) => el.id === selected)?.name ?? ''}
            </h2>
          )}
        </div>
      )}
    </>
  );
};

export default CompaniesWrapper;
