import React, { Component } from "react"

class IcoBase extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg" id="ico-user" className={className} height={this.props.height} width={this.props.width}>
        <path d="M21.6214 0L43.2429 12.5V37.5L21.6214 50L0 37.5V12.5L21.6214 0Z" className="box" />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="ico-text">{this.props.text}</text>
      </svg>

    );
  }
}
export default IcoBase