import { rounder, percent } from '@lib/helpers';
import { Card } from 'react-bootstrap';
import { Ico35 } from '@icons/new';
import { Trans, ApiTrans } from '@lib/i18n';

const InvestmentTable = ({ investment }) => {
  const tableData = Object.keys(investment.row)
    //.filter(k => investment.row[k].total)
    .filter((k) => k !== 'TOTALCAPEX')
    .map((k) => ({
      name: k,
      ...investment.row[k],
    }));
  const { year } = investment;

  return (
    <Card className='cards-utente d-none d-md-block card-table'>
      <Card.Body>
        <Ico35 className='logo-card ico-box-blue' />
        <Card.Title>
          <Trans code='A0183' />
        </Card.Title>
        <div style={{ overflowX: 'auto' }}>
          <table className='investment-table'>
            <thead>
              <tr>
                <th className='value-label value-capex'>
                  <Trans code='A0459' />
                </th>
                {year.map((y) => (
                  <th key={y}>{y}</th>
                ))}
                <th>
                  <Trans code='A0111' />
                </th>
                {/* <th>% Locale</th>
              <th className="value-locale"><Trans code="A0115" /></th> */}
                <th className='value-vs'>
                  <Trans code='A0175' />
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((r) => (
                <tr key={r.name} className={r.name}>
                  <td className='value-label'>
                    <ApiTrans scope='investment' prop={r.name} />
                  </td>
                  {year.map((y) => (
                    <td key={`${y}${r.name}`}>{rounder(r.val[y])}</td>
                  ))}
                  <td>{rounder(r.total)}</td>
                  {/* <td>{(r.localperc === "-") ? r.localperc : percent(r.localperc)}</td>
              <td>{rounder(r.local)}</td> */}
                  <td>{rounder(r.va)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className='va-desc'>
          <Trans code='A0117' />
        </p>
      </Card.Body>
    </Card>
  );
};

export default InvestmentTable;
