import React from 'react';
import CorporateEsgAnalysesCompanySelector from './CorporateEsgAnalysesCompanySelector';

const CorporateEsgAnalysesCompanySelectorWrapper = ({
  data,
  setSelectedCompany,
  selectedCompany,
  companiesDef,
}) => {
  return (
    <div className='mb-5 corporate-esg-company-selector-wrapper'>
      {data.map((company) => {
        const companyObj = companiesDef.find((el) => el.id === company);
        return (
          <CorporateEsgAnalysesCompanySelector
            key={`company_selector_${company}`}
            companyId={company}
            companyName={companyObj?.name || 'Senza Nome'}
            setSelectedCompany={setSelectedCompany}
            selectedCompany={selectedCompany}
            image={companyObj?.image}
          />
        );
      })}
    </div>
  );
};

export default CorporateEsgAnalysesCompanySelectorWrapper;
