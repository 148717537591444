import React, { useState } from 'react';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
  Modal,
  Row,
  Col,
  InputGroup,
} from 'react-bootstrap';
import { Trans } from '@lib/i18n';
import { IcoDownloadFile, IcoUploadFile, Lens, IcoCanc } from '@icons';
import {
  getEconomicData,
  getFinancialData,
  downloadOEExcelPefEv,
  uploadOEExcelPefEv,
  getOEScenario,
} from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';
import PEF from './Pef';
import EV from './Ev';
import { getEconomicDataDownload, getScenarioVariant } from '@lib/api/index';
import base64ToBlob from 'utils/base64ToBlob';
import { fetchData } from '@lib/helpers/index';

const RendererSwitch = ({
  index,
  analysisMode,
  regionCode,
  regionName,
  sectors,
  expenseItems,
  isVisible,
  setScenario,
  setShowStatusBar,
  setStatusBarType,
  setStatusBarMessage,
  type = 'financialData',
  idScenario,
  idVariante,
}) => {
  const [economicData, setEconomicData] = useState([]);
  const [showModalEconomicsData, setShowModalEconomicsData] = useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [showStatusBarModal, setShowStatusBarModal] = useState(false);
  const [statusBarMessageModal, setStatusBarMessageModal] = useState('');
  const [statusBarTypeModal, setStatusBarTypeModal] = useState('');

  const haveEconomicData = () => {
    if (type === 'financialData') {
      const transformEconomicData = (data) => {
        setEconomicData(...data.map((el) => el?.[analysisMode]));
      };

      fetchData(
        getFinancialData,
        null,
        transformEconomicData,
        [idScenario, idVariante, analysisMode, regionCode],
        handleStatusBar
      );
      // getFinancialData(idScenario, idVariante, analysisMode, regionCode).then((retrievedData) => {
      //   // convert analysisMode to UcFirst
      //   setEconomicData(...retrievedData.data.map((el) => el?.[analysisMode]));
      // });
    } else {
      getEconomicData(idScenario, idVariante, analysisMode, regionCode).then((retrievedData) => {
        // convert analysisMode to UcFirst
        const analysisModeUcFirst =
          'economic' + analysisMode.charAt(0).toUpperCase() + analysisMode.slice(1).toLowerCase();
        // setEconomicData(retrievedData.data[analysisModeUcFirst]);
        setEconomicData(...retrievedData.data.map((el) => el?.[analysisModeUcFirst]));
      });
    }

    handleShowModal(setShowModalEconomicsData);
  };

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleStatusBar = (status, message, autoHide = false) => {
    setShowStatusBar(true);
    setStatusBarType(status);
    setStatusBarMessage(message);
    if (autoHide) {
      setTimeout(() => {
        setShowStatusBar(false);
      }, 5000); // o qualunque sia il tempo che vuoi
    }
  };

  const handleStatusBarModal = (status, message, autoHide = false) => {
    setShowStatusBarModal(true);
    setStatusBarTypeModal(status);
    setStatusBarMessageModal(message);
    if (autoHide) {
      setTimeout(() => {
        setShowStatusBarModal(false);
      }, 5000); // o qualunque sia il tempo che vuoi
    }
  };

  const handleDownload = async () => {
    handleStatusBar('elaborating', false, false);

    if (type === 'financialData') {
      const downloadResponse = await downloadOEExcelPefEv(
        idScenario,
        idVariante,
        analysisMode.toUpperCase(),
        regionCode
      );

      if (downloadResponse?.ok === true) {
        handleStatusBar('success', <Trans code='A0657' />, true);
        const blob = await downloadResponse.blob();

        const file = new File([blob], regionCode);

        var fileUrl = window.URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileUrl;
        a.download = `Scenario-${idScenario}-Variante-${idVariante}-${analysisMode}-${regionCode}.xlsx`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      } else if (!downloadResponse?.ok || downloadResponse?.status !== 200) {
        // try {
        //   const res = await downloadResponse.json();
        //   console.log('RES', res);
        //   // if (res.status) throw new Error(res.message);
        // } catch (error) {
        handleStatusBar('error', `${downloadResponse}`, false);
        // }
      }
    } else {
      getEconomicDataDownload(idScenario, idVariante, analysisMode, regionCode)
        .then((downloadResponse) => {
          if (downloadResponse.success) {
            handleStatusBar('success', <Trans code='A0657' />, true);
            const blob = base64ToBlob(
              downloadResponse.file,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            );
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Scenario-${idScenario}-Variante-${idVariante}-${analysisMode}-${regionCode}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((error) => {
          handleStatusBar('error', `${error.message}`, true);
        });
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file, file.name);
    handleStatusBarModal('elaborating', true);
    const uploadResult = await uploadOEExcelPefEv(
      idScenario,
      idVariante,
      analysisMode.toUpperCase(),
      regionCode,
      formData
    );

    if (uploadResult.status === true) {
      getScenarioVariant(idScenario, idVariante).then((updatedScenario) => {
        setScenario(updatedScenario.data);
      });
      // getOEScenario(idScenario).then((updatedScenario) => {
      //   setScenario(updatedScenario.data);
      // });

      handleStatusBarModal('success', <Trans code='A0654' />, true);

      setTimeout(() => {
        setShowModalUpload(false);
      }, 2000);
    } else if (uploadResult.message || uploadResult.error) {
      handleStatusBarModal('error', uploadResult.message, false);
    }
  };

  const downloadTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0583' />
    </Tooltip>
  );
  const uploadTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0584' />
    </Tooltip>
  );
  const viewTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0585' />
    </Tooltip>
  );

  return (
    <>
      <div
        id={`action-${index}`}
        style={{
          marginTop: '0.3rem',
          visibility: isVisible ? 'visible' : 'hidden',
        }}>
        <OverlayTrigger placement='top' overlay={downloadTooltip}>
          <Button
            onClick={handleDownload}
            variant='link'
            className='mr-3 economic-data-action-buttons'>
            <IcoDownloadFile className='second-color ico-small download-upload' />
          </Button>
        </OverlayTrigger>
        {type === 'financialData' && (
          <OverlayTrigger placement='top' overlay={uploadTooltip}>
            <Button
              onClick={() => {
                handleShowModal(setShowModalUpload);
              }}
              variant='link'
              className='mr-3 economic-data-action-buttons'>
              <Form.Label htmlFor='file-input' className='mb-0'>
                <IcoUploadFile role='button' className='second-color ico-small download-upload' />
              </Form.Label>
            </Button>
          </OverlayTrigger>
        )}
        <OverlayTrigger placement='top' overlay={viewTooltip}>
          <Button
            variant='link'
            className='economic-data-action-buttons'
            onClick={() => haveEconomicData()}>
            <Lens className='ico-small lens-bm green-lens' />
          </Button>
        </OverlayTrigger>

        <Modal
          size='md'
          show={showModalUpload}
          onHide={() => handleCloseModal(setShowModalUpload)}
          centered>
          <Modal.Header closeButton>
            <Modal.Title className='px-2'>
              {/* <Trans code='A0646' /> */}
              Upload Template
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form id='pefEv-template-upload'>
              <Row className='mb-3'>
                <Col className='px-0'>
                  <Form.Group className='pl-4 pr-4' controlId='datasetUpload'>
                    <Form.Label>Upload Template</Form.Label>
                    <InputGroup className='mb-3'>
                      <div className='custom-file'>
                        {file ? (
                          <Col className='d-flex justify-content-start align-items-center w-100 h-100 p-3 border rounded '>
                            <p className='mb-0 mr-1'>{file.name}</p>
                            <Button
                              variant='primary-outline'
                              onClick={() => {
                                setFile(null);
                              }}>
                              <IcoCanc className='second-color ico-small cancel' />
                            </Button>
                          </Col>
                        ) : (
                          <>
                            <input
                              type='file'
                              className='custom-file-input'
                              id='inputGroupFile02'
                              onChange={handleFileChange}
                            />
                            <label className='custom-file-label border rounded'>
                              <Trans code='A0574' />
                            </label>
                          </>
                        )}
                      </div>
                      <div className='input-group-append'>
                        <Button variant='success' id='' onClick={(e) => handleUpload(e)}>
                          Upload{' '}
                          <span>
                            <IcoUploadFile className='light-color ico-small cancel' />
                          </span>
                        </Button>
                      </div>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            {showStatusBarModal && (
              <StatusBar status={statusBarTypeModal} message={statusBarMessageModal} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row className='px-3'></Row>
            <Row className='px-3 text-right'>
              <Button variant='secondary' onClick={() => handleCloseModal(setShowModalUpload)}>
                <Trans code='A0529' />
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>

        {analysisMode === 'PEF' ? (
          <PEF
            type={type}
            data={economicData}
            showModalData={showModalEconomicsData}
            setShowModalData={setShowModalEconomicsData}
            expenseItems={expenseItems}
            regionName={regionName}
            showStatusBarModal={showStatusBarModal}
            statusBarMessageModal={statusBarMessageModal}
            statusBarTypeModal={statusBarTypeModal}
          />
        ) : (
          <EV
            type={type}
            data={economicData}
            showModalData={showModalEconomicsData}
            setShowModalData={setShowModalEconomicsData}
            sectors={sectors}
            regionName={regionName}
            showStatusBarModal={showStatusBarModal}
            statusBarMessageModal={statusBarMessageModal}
            statusBarTypeModal={statusBarTypeModal}
          />
        )}
      </div>
    </>
  );
};

export default RendererSwitch;
