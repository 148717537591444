import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const Countdown = ({ counterSeconds, setState }) => {
  const [time, setTime] = useState(counterSeconds); // 5 minutes in seconds

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setState(false);
    }
  }, [time, setState]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <h1 style={styles.timer}>{formatTime(time)}</h1>
      {/* {time === 0 && <h2>Time's up!</h2>} */}
    </div>
  );
};

const styles = {
  timer: {
    fontSize: '2rem',
    marginTop: '20px',
    fontWeight: '600',
    lineHeight: '28.8px',
    fontFamily: 'Barlow',
    color: 'rgba(8, 162, 170, 1)',
  },
};

export default Countdown;
