import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { Button, Modal, Form, Col, Row, Tabs, Tab, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import { Trans, propTrans } from '@lib/i18n/index';

import { IcoCanc, IcoEdit, IcoCopy } from '@icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressArrowsAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';

import {
  putOEDataset,
  deleteOEDatasetItem,
  downloadOEExcelDatasetItem,
  uploadOEExcelDatasetItem,
  getShadowPricesDownload,
  cloneOEDataset,
} from '@lib/api/index';

import StatusBar from 'components/wizard/StatusBar';
import TreeView from 'components/TreeView/TreeView';
import WorldMapSVG from 'components/WorldMapSVG/WorldMapSVG';
import AssociatedScenarioTable from 'components/adminManagement/associatedScenario/associatedScenarioTable';
import DownloadUploadItems from 'components/DownloadUploadView/DownloadUploadView';

const ActionBtnRendererNewDataset = ({
  data,
  geoExtCodes,
  geoDatasetTypes,
  regionsDatasetTypes,
  regionsForMap,
  oeCurrencies,
  oeSecDisags,
  oeGeoPrecisions,
  setRefresh,
}) => {
  const [dataset, setDataset] = useState([]);
  const [modifiedDataset, setModifiedDataset] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [selectedGeoExtCodes, setSelectedGeoExtCodes] = useState([]);
  const [selectedGeoDatasetTypes, setSelectedGeoDatasetTypes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedOeCurrencies, setSelectedOeCurrencies] = useState([]);
  const [selectedOeSecDisags, setSelectedOeSecDisags] = useState([]);
  const [associatedScenarios, setAssociatedScenarios] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedOeGeoPrecisions, setSelectedOeGeoPrecisions] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [activateProfile, setActivateProfile] = useState(false);
  const [modalFullscreen, setModalFullscreen] = useState(false);
  const [activeKey, setActiveKey] = useState('home');
  const [file, setFile] = useState(null);
  const [showModalCopy, setShowModalCopy] = useState(false);
  // For maps
  const [checkedExistingDatasetRegions, setCheckedExistingDatasetRegions] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const handleCountrySelection = (checkedItems) => {
    const selected = Object.keys(checkedItems).filter((key) => checkedItems[key]);

    setSelectedCountries(selected);
  };

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleDownload = async (isShadow = false) => {
    handleStatusBar('elaborating', false, false); //Il terzo argomento se lasciato in true, fa scomparire la statusbar dopo 5s, con false, solo se c'è altro che la sostituisce.
    const downloadResponse = isShadow
      ? await getShadowPricesDownload(dataset.id)
      : await downloadOEExcelDatasetItem(dataset.id);

    if (downloadResponse.ok) {
      handleStatusBar('success', <Trans code='A0657' />, true);
      const blob = await downloadResponse.blob();
      const file = new File([blob], dataset.id);
      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `${isShadow ? `Prezzi Ombra` : `Dataset Values`} - ${dataset.geoExtCode} ${
        dataset.typeCode
      } ${regionsDatasetTypes.find((el) => el.value === dataset.regionCode).label} ${
        dataset.year
      }.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      //Se la get ha avuto successo
      setTimeout(() => {
        setSbShow(<></>);
      }, 5000);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      handleStatusBar('error', `${downloadResponse.statusText}`, false);
      const res = await downloadResponse.json();
      if (res.message) handleStatusBar('error', `${res.message}`, false);
    }
  };

  const handleFile = (file) => {
    setFile(file);
  };
  const handleUpload = async () => {
    // e.preventDefault();
    if (!file) return;
    handleStatusBar('elaborating', false, false);
    const formData = new FormData();

    formData.append('file', file, file.name);

    const uploadResult = await uploadOEExcelDatasetItem(dataset.id, formData);

    if (uploadResult.status === true) {
      handleStatusBar('success', <Trans code='A0654' />, true);
      setFile(null);
      setTimeout(() => {
        setSbShow(<></>);
        // setRefresh(true);
      }, 5000);
    } else if (uploadResult.message || uploadResult.error) {
      handleStatusBar('error', uploadResult.message ?? propTrans({ prop: 'A0652' }), false);
    }
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  useEffect(() => {
    setDataset((prevState) => {
      return { ...prevState, regions: selectedCountries };
    });
  }, [selectedCountries]);

  const resetFormState = () => {
    // setDataset(data);
    // setCheckedExistingDatasetRegions(data?.regions);
    // setActiveKey('home');
    setRefresh(true);
  };

  useEffect(() => {
    setDataset(data);
    setModifiedDataset(data);
    setAssociatedScenarios(data?.associatedScenarios?.length > 0 ? true : false);
    setCheckedExistingDatasetRegions(data?.regions);

    setActivateProfile(data?.regions?.length > 0 ? true : false);
  }, []);

  useEffect(() => {
    setSelectedGeoExtCodes(
      geoExtCodes.filter((geoExtCode) => geoExtCode.value === dataset.geoExtCode)
    );
    setSelectedRegion(
      regionsDatasetTypes.filter((regions) => regions.value === dataset.regionCode)
    );
    setSelectedGeoDatasetTypes(
      geoDatasetTypes.filter((geoDatasetType) => geoDatasetType.value === dataset.typeCode)
    );
    setSelectedOeCurrencies(
      oeCurrencies.filter((oeCurrency) => oeCurrency.value === dataset.salaryCurrency)
    );
    setSelectedOeSecDisags(
      oeSecDisags.filter((oeSecDisag) => oeSecDisag.value === dataset.secDisagCode)
    );
    setSelectedOeGeoPrecisions(
      oeGeoPrecisions.filter((orGeoPrecision) => orGeoPrecision.value === dataset.precisionCode)
    );
  }, [
    dataset,
    geoExtCodes,
    geoDatasetTypes,
    oeCurrencies,
    oeSecDisags,
    regionsDatasetTypes,
    oeGeoPrecisions,
  ]);

  const onChange = (e, fieldname, setSelect) => {
    // console.log('onChange e', e, fieldname);

    if (setSelect) {
      setSelect(e ?? []);
      if (fieldname === 'secDisagCode' || fieldname === 'typeCode' || fieldname === 'geoExtCode') {
        setSelectedCountries([]);
        setCheckedExistingDatasetRegions([]);
      }
      setDataset(() => ({
        ...dataset,
        [fieldname]: e ? e.value ?? e : [],
      }));
    } else {
      setDataset(() => ({
        ...dataset,
        [e.target.name]:
          e.target.type === 'number'
            ? e.target.value
              ? Number(e.target.value)
              : ''
            : e.target.value,
      }));
    }
  };
  useEffect(() => {
    const isFinancialDataChanged =
      dataset?.typeCode !== modifiedDataset?.typeCode ||
      dataset?.geoExtCode !== modifiedDataset?.geoExtCode ||
      dataset?.secDisagCode !== modifiedDataset?.secDisagCode;
    if (isFinancialDataChanged) setIsConfirmed(true);
    else setIsConfirmed(false);
  }, [dataset, modifiedDataset]);

  const putDatasetItem = async (e) => {
    e.preventDefault();
    setSbShow(<></>);

    const {
      name,
      description,
      file,
      updatedAt,
      createdAt,
      shadowPriceStatus,
      datasetValueStatus,
      associatedScenarios,
      localSalary,
      salaryCurrency,
      version,
      ...restEditDataset
    } = dataset;

    if (activateProfile) {
      const isSalaryAndCUrrencyValid =
        salaryCurrency !== '' &&
        salaryCurrency != null &&
        localSalary !== '' &&
        localSalary != null;
      if (!isSalaryAndCUrrencyValid) {
        handleStatusBar('error', <Trans code='A0522' />, false);
        return;
      }
    }

    const areRegionsSelected = restEditDataset?.regions?.length > 0 ? true : false;
    const isFilled = Object.values(restEditDataset).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    if (!areRegionsSelected) {
      handleStatusBar('error', 'Selezionare almeno una regione', false);
      return;
    }
    if (!isFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }
    handleStatusBar('elaborating', false, false);

    const createPutResult = await putOEDataset(dataset, dataset.id);
    if (createPutResult.status === true) {
      handleStatusBar('success', <Trans code='A0653' />, true);
      setDataset(createPutResult.data);
      setModifiedDataset(createPutResult.data);
      if (createPutResult.data?.regions?.length > 0 && activateProfile === false) {
        setActivateProfile(true);
        setActiveKey('profile');
      }
      const timer = setTimeout(() => {
        setSbShow(<></>);
      }, 3000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    } else {
      handleStatusBar('error', createPutResult.message ?? <Trans code='A0652' />, false);
    }
  };

  const handleCopy = async () => {
    const copyResult = await cloneOEDataset(dataset.id);

    if (copyResult.status) {
      handleStatusBar('success', <Trans code='A0801' />, true);

      setTimeout(() => {
        setShowModalCopy(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', copyResult.message ?? <Trans code='A0802' />, false);
    }
  };

  const handleDelete = async () => {
    const deleteResult = await deleteOEDatasetItem(dataset.id);

    if (deleteResult.status) {
      handleStatusBar('success', propTrans({ prop: 'A0526' }), true);

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', deleteResult.message ?? propTrans({ prop: 'A0527' }), false);
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00055' />
    </Tooltip>
  );
  const fullScreenTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code={modalFullscreen ? 'A0778' : 'A0777'} />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00056' />
    </Tooltip>
  );

  const cloneTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00057' />
    </Tooltip>
  );

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={cloneTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalCopy)}>
          <IcoCopy className='second-color ico-small copy' />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button
          disabled={associatedScenarios}
          variant='link'
          className='mr-0'
          onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      <Modal
        id='dataset-modal'
        size={modalFullscreen ? 'fullscreen' : 'xl'}
        show={showModalEdit}
        onHide={() => {
          resetFormState();
          handleCloseModal(setShowModalEdit);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2 d-flex align-items-center'>
            <OverlayTrigger placement='right' overlay={fullScreenTooltip}>
              <Button
                variant='link'
                className='mr-3'
                onClick={() => setModalFullscreen((prevState) => !prevState)}>
                {modalFullscreen ? (
                  <FontAwesomeIcon icon={faCompressArrowsAlt} />
                ) : (
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                )}
              </Button>
            </OverlayTrigger>
            <Trans code='A0646' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
            id='fill-tab-example'
            className='mb-0'
            fill>
            <Tab className='py-5' eventKey='home' title={<Trans code='A0493' />}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                id='form-admin-dataset'>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} controlId='typeCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0567' /> &nbsp;
                        <span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='typeCode'
                        value={selectedGeoDatasetTypes}
                        options={geoDatasetTypes}
                        onChange={(e) => onChange(e, 'typeCode', setSelectedGeoDatasetTypes)}
                        isDisabled={associatedScenarios}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId='secDisagCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0640' /> &nbsp;
                        <span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='secDisagCode'
                        value={selectedOeSecDisags}
                        options={oeSecDisags}
                        onChange={(e) => onChange(e, 'secDisagCode', setSelectedOeSecDisags)}
                        isDisabled={associatedScenarios}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId='geoExtCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0565' /> &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='geoExtCode'
                        value={selectedGeoExtCodes}
                        options={geoExtCodes}
                        onChange={(e) => onChange(e, 'geoExtCode', setSelectedGeoExtCodes)}
                        isDisabled={associatedScenarios}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} controlId='regionCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0084' /> &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='regionCode'
                        value={selectedRegion}
                        options={regionsDatasetTypes}
                        onChange={(e) => onChange(e, 'regionCode', setSelectedRegion)}
                        isDisabled={associatedScenarios}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className='pl-4 pr-2' controlId='precisionCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0650' /> &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        isDisabled={associatedScenarios}
                        name='precisionCode'
                        value={selectedOeGeoPrecisions}
                        options={oeGeoPrecisions}
                        onChange={(e) => onChange(e, 'precisionCode', setSelectedOeGeoPrecisions)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='px-3' controlId='year'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0627' /> &nbsp;
                        <span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        className='custom-disabled'
                        type='number'
                        name='year'
                        min={2010}
                        step={1}
                        value={dataset?.year ?? ''}
                        onChange={(e) => onChange(e, 'year', undefined)}
                        disabled={associatedScenarios}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group controlId='description'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0138' />
                      </Form.Label>
                      <Form.Control
                        className='custom-disabled'
                        as='textarea'
                        rows={5}
                        name='description'
                        value={dataset?.description ?? ''}
                        onChange={(e) => onChange(e, 'description', undefined)}
                        disabled={associatedScenarios}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab
              className='py-5'
              eventKey='geography'
              title={<Trans code='A0780' />}
              disabled={!dataset?.id}>
              <Row className='mb-3'>
                <Col
                  style={{
                    maxHeight: '668px',
                    overflowY: 'scroll',
                    height: '668px',
                    minHeight: '668px',
                  }}
                  className='col-3 mt-5'>
                  <TreeView
                    regionData={regionsForMap}
                    onCheckedItemsChange={handleCountrySelection}
                    alreadyChecked={checkedExistingDatasetRegions}
                    disabled={associatedScenarios}
                    extension={dataset?.geoExtCode}
                  />
                </Col>
                <Col className='col-9'>
                  <WorldMapSVG countryIds={selectedCountries} regionData={regionsDatasetTypes} />
                </Col>
              </Row>
            </Tab>

            <Tab
              className='py-5'
              eventKey='profile'
              title={<Trans code='A0108' />}
              disabled={!data?.regions?.length > 0 && !activateProfile}>
              <>
                <Row className='mb-3'>
                  <DownloadUploadItems
                    title={'Dataset'}
                    disabled={associatedScenarios}
                    handleDownload={handleDownload}
                    handleUpload={handleFile}
                    status={dataset?.datasetValueStatus ?? 0}
                    isShadow={false}
                  />
                </Row>
                <Row className='mb-5'>
                  <DownloadUploadItems
                    title={<Trans code='A0779' />}
                    handleDownload={handleDownload}
                    // handleUpload={handleFile}
                    status={dataset?.shadowPriceStatus ?? 0}
                    hasDetails={true}
                    regionsDatasetTypes={regionsDatasetTypes}
                    datasetId={dataset?.id}
                    isShadow={true}
                  />
                </Row>
                <hr className='divider' />
              </>

              <Row className='mb-3'>
                <Col className='px-0'>
                  <Form.Group className='pl-4 pr-2' controlId='udm'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0626' /> &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Control
                      className='custom-disabled'
                      type='number'
                      name='localSalary'
                      placeholder='es. 33000'
                      maxLength='10'
                      min={0}
                      step={1000}
                      value={dataset?.localSalary ?? 0}
                      onChange={(e) => onChange(e, 'localSalary', undefined)}
                      disabled={associatedScenarios}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} className='pl-4 pr-2' controlId='salaryCurrency'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0625' /> &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Select
                      name='salaryCurrency'
                      value={selectedOeCurrencies}
                      options={oeCurrencies}
                      onChange={(e) => onChange(e, 'salaryCurrency', setSelectedOeCurrencies)}
                      isDisabled={associatedScenarios}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>

            <Tab
              disabled={!associatedScenarios}
              eventKey='associatedScenarios'
              title={<Trans code='A0468' />}
              className='py-5'>
              <AssociatedScenarioTable associatedScenarios={dataset?.associatedScenarios} />
            </Tab>
          </Tabs>

          {sbShow}

          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;
            <Trans code='A0503' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              resetFormState();
              handleCloseModal(setShowModalEdit);
            }}>
            <Trans code='A0529' />
          </Button>
          <Button
            variant='primary'
            disabled={associatedScenarios}
            onClick={(e) => (!isConfirmed ? putDatasetItem(e) : setShowConfirmModal(true))}>
            <Trans code='F00055' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalCopy} onHide={() => handleCloseModal(setShowModalCopy)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0800' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalCopy)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={() => handleCopy()}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0642' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => handleCloseModal(setShowConfirmModal)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <Trans code='A0774' />
            <span className='d-block'>
              <Trans code='A0572' />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirmModal(false)}>
            <Trans code='F0057' />
          </Button>
          <Button
            variant='success'
            onClick={(e) => {
              putDatasetItem(e);
              setShowConfirmModal(false);
            }}>
            <Trans code='A0561' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRendererNewDataset;
