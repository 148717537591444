import { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n';
import NewLegend from '@components/scenariodashboard/NewChartLegend';
import { chartColors as colors } from '@lib/helpers';
import { Ico94 } from '@icons/new';
import AnimatedDonutChartDash from '@components/scenariodashboard/AnimatedDonutChartDash';

import { rounder } from '@lib/helpers/index';

const GdpCard = ({ gdp, filterForRegion, chartId, langTitleCode, icon }) => {
  const [gdpData, setGdpData] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  console.log('CARD:', gdp);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);

  useEffect(() => {
    let filter = filterForRegion === 'total' ? '' : filterForRegion;
    setGdpData(
      gdp.filter((el) => {
        // console.log('GPDS', el, filter);
        return el.region === filter;
      })
    ); //to filter
  }, [gdp, filterForRegion]);
  return (
    <div className='col-lg-6 col-md-12 col-sm-12 layout-charts d-flex container-card mb-5'>
      <Card className='cards-utente d-md-block ' style={{ paddingBottom: '6rem' }}>
        <Card.Body>
          {icon}
          <Card.Title>
            <Trans code={langTitleCode} />
          </Card.Title>
          <div className='d-flex justify-content-center align-items-center'>
            <div style={{ width: '300px' }}>
              <AnimatedDonutChartDash
                valueInDonut={false}
                chartId={chartId}
                data={gdpData}
                codeLangTag='A0053'
                translations={translations}
                currentLang={currentLang}
              />
            </div>
          </div>

          <div className='my-3'>
            <NewLegend
              data={gdpData}
              scope={'gdp'}
              colors={colors}
              className={chartId}
              values={gdpData}
              allData={gdpData}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GdpCard;
