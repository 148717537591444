import React from 'react';
import DashboardSummaryImpact from 'components/scenariodashboard/dashboardSummary/dashboardSummaryImpact';
import DashboardSummaryWheels from 'components/scenariodashboard/dashboardSummary/dashboardSummaryWheels';
import DashboardSummarySroi from 'components/scenariodashboard/dashboardSummary/dashboardSummarySroi';
import DashboardHeatMap from 'components/scenariodashboard/dashboardSummary/dashboardHeatmap';
import AttachmentsCard from 'components/scenariodashboard/attachmentsDownload/AttachmentsCard';
import AttachmentsViewCard from 'components/scenariodashboard/attachmentsView/AttachmentsViewCard';
import { Trans } from '@lib/i18n/index';
import AttachmentsImageCarousel from 'components/scenariodashboard/attachmentsImageCarousel/AttachmentsImageCarousel';
import SummaryHeatmap from 'components/scenariodashboard/summaryHeatmap/summaryHeatmap';
import AttachmentsCardWrapper from 'components/scenariodashboard/attachmentsDownload/AttachmentsCardWrapper';
import CustomSpinner from 'components/CustomSpinner';
import DashboardSummaryESG from 'components/scenariodashboard/dashboardSummary/dashboardSummaryESG';

function isAnalysisExecutedOrDraft(variant, types = []) {
  // Helper function to check if any analysis of a given type is in 'Executed' or 'Draft' status
  function checkStatusForType(analyses, type) {
    return (
      analyses.some((el) => el.type === type) &&
      (analyses.find((el) => el.type === type)?.status === 'Executed' ||
        analyses.find((el) => el.type === type)?.status === 'Draft')
    );
  }

  const analyses = variant?.analyses || [];

  // If no types are provided, use default types 'IA' and 'SROI'
  const typesToCheck = types.length > 0 ? types : ['IA', 'SROI'];

  // Check each type in the array
  return typesToCheck.some((type) => checkStatusForType(analyses, type));
}
const SummaryPage = ({ scenario, regions, currencyToUse, variant, isLoading, handleStatusBar }) => {
  // const getFirstInfographic = (additionalInfo) => {
  //   const imageInfo = scenario.additionalInfo.find((el) => el.type === 'image' && el.display === 1);

  //   return imageInfo ? (
  //     <img
  //       key={'infographic' + imageInfo.id}
  //       src={imageInfo.ref}
  //       alt={imageInfo.name || ''}
  //       className='img-fluid align-middle m-auto d-block'
  //     />
  //   ) : null;
  // };
  if (isLoading)
    return (
      <div id='summaryDashboard' className='pt-0'>
        <div className='container-fluid'>
          <CustomSpinner style={{ height: '300px' }} />
        </div>
      </div>
    );
  console.log(
    'SummaryPage',
    variant?.analyses?.some((el) => el.type === 'IA'),
    variant
  );
  return (
    <div id='summaryDashboard' className='pt-0'>
      <div className='container-fluid'>
        <DashboardSummaryImpact
          scenario={scenario}
          typologyCode={scenario.typologyCode}
          regions={regions}
          variant={variant}
          handleStatusBar={handleStatusBar}
          isIA={isAnalysisExecutedOrDraft(variant, ['IA'])}
        />
        {isAnalysisExecutedOrDraft(variant) && (
          <div className='row d-flex dashboardBreaks' style={{ paddingBottom: '40px' }}>
            {isAnalysisExecutedOrDraft(variant, ['IA']) && (
              <div className='col dashboardBreaksMargin'>
                <DashboardSummaryWheels
                  syntheticResults={variant?.analysisResults?.IA?.syntheticResults}
                />
              </div>
            )}

            {isAnalysisExecutedOrDraft(variant, ['SROI']) && (
              <div className='col'>
                <DashboardSummarySroi
                  syntheticResults={variant?.analysisResults?.SROI?.syntheticResults}
                  currencyToUse={currencyToUse}
                />
              </div>
            )}
          </div>
        )}
        {isAnalysisExecutedOrDraft(variant, ['IA']) && (
          <>{variant.dataset.geoExtCode === 'MR' ? <SummaryHeatmap scenario={variant} /> : ''}</>
        )}
        {isAnalysisExecutedOrDraft(variant, ['ESG']) && (
          <div className={`row d-flex dashboardBreaks`}>
            <div className='col'>
              {' '}
              <DashboardSummaryESG variant={variant} />
            </div>
          </div>
        )}

        {variant.additionalInfo &&
          variant.additionalInfo.some((el) => el.type === 'image' && el.display === 1) && (
            <div className={`row d-flex dashboardBreaks`}>
              <div className='col'>
                <AttachmentsImageCarousel
                  data={variant.additionalInfo.filter(
                    (el) => el.type === 'image' && el.display === 1
                  )}
                />
              </div>
            </div>
          )}

        {variant?.additionalInfo.some((el) => el.type === 'file') && (
          <div className={`row d-flex dashboardBreaks`}>
            <div className='col'>
              <AttachmentsViewCard
                data={variant?.additionalInfo?.filter((firstEl) => firstEl.type === 'file')}
              />
            </div>
          </div>
        )}

        {/* {scenario.additionalInfo &&
          scenario.additionalInfo.filter(
            (el) =>
              (el.type === 'file' || el.type === 'link' || el.type === 'image') && el.display === 1
          ).length > 0 && (
            <AttachmentsCard
              attachments={scenario.additionalInfo.filter(
                (el) =>
                  (el.type === 'file' || el.type === 'link' || el.type === 'image') &&
                  el.display === 1
              )}
            />
          )} */}
        {variant.additionalInfo &&
          variant.additionalInfo.filter(
            (el) =>
              (el.type === 'file' || el.type === 'link' || el.type === 'image') && el.display === 1
          ).length > 0 && (
            <AttachmentsCardWrapper scenarioAdditionalInfo={variant?.additionalInfo} />
          )}
      </div>
    </div>
  );
};

export default SummaryPage;
