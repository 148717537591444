import React, { useState, useEffect } from 'react';
import Select, { defaultTheme } from 'react-select';
import { Button } from 'react-bootstrap';
import { IcoGeoMap } from '@icons';
import { propTrans } from '@lib/i18n';
import { useParams } from 'react-router-dom';
const { colors } = defaultTheme;

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ /* position: 'relative',  */ display: 'flex', justifyContent: 'right' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Svg = (p) => (
  <svg width='24' height='24' viewBox='0 0 24 24' focusable='false' role='presentation' {...p} />
);

const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </Svg>
  </div>
);

const ChevronDown = () => (
  <Svg style={{ marginRight: -6, bottom: '0.1rem', position: 'relative' }}>
    <path
      d='M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z'
      fill='currentColor'
      fillRule='evenodd'
    />
  </Svg>
);

const SelectRegion = ({
  activeTab,
  optionsDatasetRegions,
  filterForRegion,
  setFilterForRegion,
}) => {
  const [updatedOptionsDatasetReg, setUpdatedOptionsDatasetReg] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { idScenario, idVariante } = useParams();

  useEffect(() => {
    setUpdatedOptionsDatasetReg(optionsDatasetRegions);
  }, [optionsDatasetRegions]);

  useEffect(() => {
    const isPathTotal = ['gdp', 'jobs', 'incomes', 'taxes'];
    setSelectedRegion(
      updatedOptionsDatasetReg
        .filter(
          // (opt) =>
          //   opt.value ===
          //   (activeTab === 'general' || activeTab === 'investment' ? 'total' : filterForRegion)
          (opt) =>
            opt.value ===
            (isPathTotal.some((tab) => activeTab.includes(tab)) ? filterForRegion : 'total')
        )
        .pop()
    );
  }, [activeTab, filterForRegion, updatedOptionsDatasetReg]);
  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          style={{ maxHeight: '39px', width: 'max-content' }}
          onClick={() => setIsOpen((prev) => !prev)}
          disabled={
            activeTab ===
              `/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis` ||
            activeTab.includes('investment')
          }
          // disabled={activeTab === 'general' || activeTab === 'investment' ? true : false}
        >
          <IcoGeoMap className='white-color ico-small edit mr-1 mb-1' />
          {selectedRegion ? `${selectedRegion.label}` : propTrans({ prop: 'A0690' })}
          <ChevronDown />
        </Button>
      }>
      <Select
        components={{ DropdownIndicator }}
        isClearable={false}
        menuIsOpen
        onChange={(newValue) => {
          setSelectedRegion(newValue);
          setFilterForRegion(newValue.value);
          setIsOpen(false);
        }}
        options={optionsDatasetRegions}
        styles={selectStyles}
        value={selectedRegion}
      />
    </Dropdown>
  );
};
export default SelectRegion;
