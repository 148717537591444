import React, { useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n/index';
import { IcoEdit, IcoSave } from 'icone/index';
import MarkdownEditorComponent from './MarkdownEditorComponent';
import DOMPurify from 'dompurify';
import CustomModal from 'components/CustomModal/CustomModal';
import ResizableDiv from 'components/ResizableDiv/ResizableDiv';

const MarkdownEditComponent = ({
  titleCode,
  placeholder,
  onChange,
  name,
  type = 'description',
  existingContent,
  editState,
  setEditState,
}) => {
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode((prevState) => !prevState);
    setEditState((prevState) => !prevState);
  };

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  return (
    <Form.Group as={Col} controlId={name} id='markdown-edit'>
      <Form.Label className='d-flex align-items-center'>
        <Trans code={titleCode} />
        <Button
          className='d-flex align-items-center justify-content-center ml-2'
          variant='link'
          disabled={!editMode && editState}
          onClick={(e) => {
            e.preventDefault();
            toggleEditMode();
          }}>
          <IcoEdit className='second-color ico-small edit' />
          {/* <span className='ml-1'>{<Trans code='F00055' />}</span> */}
          {/* {!editMode ? (
            <IcoEdit className='second-color ico-small edit' />
          ) : (
            <IcoSave className='second-color' />
          )}
          <span className='ml-1'>{!editMode ? 'EDIT' : 'SAVE'}</span> */}
        </Button>
      </Form.Label>
      <ResizableDiv resize='vertical'>
        {!existingContent || existingContent === '<p></p>' ? (
          <div className='previewContainerMdEditor'>{placeholder}</div>
        ) : (
          <div
            className='previewContainerMdEditor'
            dangerouslySetInnerHTML={createMarkup(existingContent)}></div>
        )}
      </ResizableDiv>

      {editMode && (
        <CustomModal
          show={editMode}
          handleClose={toggleEditMode}
          title={propTrans({ prop: titleCode })}
          content={
            <MarkdownEditorComponent
              onChange={onChange}
              name={name}
              type={type}
              existingContent={existingContent}
            />
          }
          modalSize='lg'
          buttonText={propTrans({ prop: 'A0529' })}
        />
      )}
    </Form.Group>
  );
};

export default MarkdownEditComponent;
