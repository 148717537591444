import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { IcoPlus, Lens, IcoMinusCircled } from '@icons';
import { getOEKpis, postCreateOEKpi } from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererKpi from '@components/adminManagement/kpiManagement/ActionBtnRendererKpi';
import {
  ShowIndicatorsNameRenderer,
  ShowIndicatorsUdmRenderer,
} from '@components/adminManagement/ShowRenderer';
import StatusBar from 'components/wizard/StatusBar';
import { Trans, propTrans } from '@lib/i18n/index';
import FormulaTester from 'components/FormulaTester/FormulaTester';

const initialState = {
  name: '',
  udm: '',
  description: '',
  formula: '',
  indicators: [{ var: '', indicator: '', udm: '', imposedValue: '' }],
};

const optionsVar = [
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'B',
    label: 'B',
  },
  {
    value: 'C',
    label: 'C',
  },
  {
    value: 'D',
    label: 'D',
  },
  {
    value: 'E',
    label: 'E',
  },
  {
    value: 'F',
    label: 'F',
  },
  {
    value: 'G',
    label: 'G',
  },
  {
    value: 'H',
    label: 'H',
  },
  {
    value: 'I',
    label: 'I',
  },
  {
    value: 'J',
    label: 'J',
  },
  {
    value: 'K',
    label: 'K',
  },
  {
    value: 'L',
    label: 'L',
  },
  {
    value: 'M',
    label: 'M',
  },
  {
    value: 'N',
    label: 'N',
  },
  {
    value: 'O',
    label: 'O',
  },
  {
    value: 'P',
    label: 'P',
  },
  {
    value: 'Q',
    label: 'Q',
  },
  {
    value: 'R',
    label: 'R',
  },
  {
    value: 'S',
    label: 'S',
  },
  {
    value: 'T',
    label: 'T',
  },
  {
    value: 'U',
    label: 'U',
  },
  {
    value: 'V',
    label: 'V',
  },
  {
    value: 'W',
    label: 'W',
  },
  {
    value: 'Y',
    label: 'Y',
  },
  {
    value: 'X',
    label: 'X',
  },
  {
    value: 'Z',
    label: 'Z',
  },
];

const Kpi = () => {
  const [kpis, setKpis] = useState([]);
  const [newKpi, setNewKpi] = useState(initialState);
  const [formulaTest, setFormulaTest] = useState({ formula: '' });
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();
  console.log('kpis', kpis);
  console.log('newKpi', newKpi);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const resetForm = () => {
    setNewKpi(initialState);
    setSbShow(<></>);
  };
  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  useEffect(() => {
    if (refresh) {
      getOEKpis().then((retrievedKpis) => {
        setKpis(retrievedKpis.data);
      });

      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    indicatorsRows();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newKpi.indicators]);

  const addIndicator = () => {
    let tempIndicators = [...newKpi.indicators];

    tempIndicators.push({ var: '', indicator: '', udm: '', imposedValue: '' });

    setNewKpi(() => ({
      ...newKpi,
      indicators: tempIndicators,
    }));
  };

  const removeIndicator = (index) => {
    let tempIndicators = [...newKpi.indicators];

    tempIndicators.splice(index, 1);

    setNewKpi(() => ({
      ...newKpi,
      indicators: tempIndicators,
    }));
  };

  const onChange = (e, fieldname, setSelect, indicatorIndex, section) => {
    //console.log('onChange e', e);

    if (section === 'indicators') {
      let tempIndicators = [...newKpi.indicators];

      let tempElement = {
        ...tempIndicators[indicatorIndex],
        [e.target ? e.target.name : fieldname]: e.target ? e.target.value : e.value,
      };

      tempIndicators[indicatorIndex] = tempElement;

      setNewKpi(() => ({
        ...newKpi,
        indicators: tempIndicators,
      }));
    } else if (setSelect) {
      setSelect(e ?? []);

      setNewKpi(() => ({
        ...newKpi,
        [fieldname]: e ? e.value ?? e : [],
      }));
    } else {
      setNewKpi(() => ({
        ...newKpi,
        [e.target.name]:
          e.target.name === 'formula' ? e.target.value.toUpperCase() : e.target.value,
      }));
    }
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  /* const onFirstDataRendered = useCallback((params) => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  /* const onFirstDataRendered = useCallback((params) => {
      gridRef.current.api.sizeColumnsToFit();
   }, []); */

  const onGridReady = useCallback(() => {
    getOEKpis().then((retrievedKpis) => {
      setKpis(retrievedKpis.data);
    });

    //sort the column in order
    gridRef.current.columnApi.applyColumnState({
      state: [
        {
          colId: 'id',
          sort: 'desc',
        },
      ],
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  /* const onVirtualRowRemoved = useCallback(e => {
      if (window.screen.width >= 2236) { //&& window.screen.height >= 768
         // Resolution is 2236 //x768 or above
         gridRef.current.api.sizeColumnsToFit();
      } else {
         const allColumnIds = [];
         gridRef.current.columnApi.getAllColumns().forEach((column) => {
         allColumnIds.push(column.getId());
         });
         gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
      }
   }, []); */

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerValueGetter: () => propTrans({ prop: 'A0669' }),
        children: [
          {
            headerName: 'ID',
            field: 'id',
            maxWidth: 100,
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              return params.data.id;
            },
          },
          {
            headerValueGetter: () => propTrans({ prop: 'A0670' }),
            field: 'name',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              return params.data.name;
            },
          },
          {
            headerValueGetter: () => propTrans({ prop: 'A0671' }),
            field: 'udm',
            maxWidth: 140,
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              return params.data.udm;
            },
          },
        ],
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0637' }),
        flex: 2,
        children: [
          {
            headerValueGetter: () => propTrans({ prop: 'A0638' }),
            columnGroupShow: '',
            field: 'formula',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
              return params.data.formula;
            },
          },
          {
            headerValueGetter: () => propTrans({ prop: 'A0672' }),
            columnGroupShow: 'open',
            flex: 2,
            field: 'kpi',
            filter: 'agTextColumnFilter',
            cellRenderer: ShowIndicatorsNameRenderer,
          },
          {
            headerValueGetter: () => propTrans({ prop: 'A0671' }),
            columnGroupShow: 'open',
            field: 'kpi',
            maxWidth: 140,
            filter: 'agTextColumnFilter',
            cellRenderer: ShowIndicatorsUdmRenderer,
          },
        ],
      },
      {
        //   headerName: 'Actions',
        pinned: 'right',
        maxWidth: 130,
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionBtnRendererKpi,
        cellRendererParams: {
          setRefresh: setRefresh,
          optionsVar: optionsVar,
        },
      },
    ]);
  }, []);

  const handleNewKpi = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { description, indicators, ...restKpi } = newKpi;

    const isKpiFilled = Object.values(restKpi).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    const areIndicatorsFilled = newKpi.indicators.every(
      (prop) => prop.var !== '' && prop.indicator !== '' && prop.udm !== ''
    )
      ? true
      : false;

    if (!isKpiFilled || !areIndicatorsFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }

    newKpi.indicators = newKpi.indicators.filter(
      (ind) => !(!ind.var && !ind.indicator && !ind.udm && !ind.imposedValue)
    );
    handleStatusBar('elaborating', false, false);
    const createResult = await postCreateOEKpi(newKpi);
    console.log('createResult', createResult);

    if (createResult.status) {
      handleStatusBar('success', <Trans code='A0674' />, true);

      setTimeout(() => {
        resetForm();
        setShowModalNew(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', createResult.message ?? <Trans code='A0521' />, false);
    }
  };

  const indicatorsRows = (kpiIndicators) => {
    let rows = [];

    if (kpiIndicators) {
      let lastIndex = kpiIndicators.length - 1;

      rows = kpiIndicators.map((el, i) => {
        const { imposedValue, ...restKpiIndicators } = kpiIndicators[i];
        const isThisIndicatorFilled = Object.values(restKpiIndicators).every((prop) => prop !== '')
          ? true
          : false;

        return (
          <Row className='align-items-center mb-3' key={i}>
            <Col>
              <Row className='pr-2'>
                <Col className='col-3'>
                  <Select
                    name='var'
                    placeholder={propTrans({ prop: 'A0699' })}
                    value={el.var === '' ? '' : [{ value: el.var, label: el.var }]}
                    options={optionsVar}
                    onChange={(e) => onChange(e, 'var', undefined, i, 'indicators')}
                  />
                </Col>
                <Col className='col-9'>
                  <Form.Control
                    type='text'
                    name='indicator'
                    placeholder={propTrans({ prop: 'A0697' })}
                    value={el.indicator}
                    onChange={(e) => onChange(e, 'indicator', undefined, i, 'indicators')}
                  />
                </Col>
              </Row>
            </Col>

            <Col>
              <Row className='pl-2 align-items-center'>
                <Col className='col-4'>
                  <Form.Control
                    type='text'
                    name='udm'
                    maxLength='10'
                    placeholder={propTrans({ prop: 'A0696' })}
                    value={el.udm}
                    onChange={(e) => onChange(e, 'udm', undefined, i, 'indicators')}
                  />
                </Col>
                <Col className='col-5'>
                  <Form.Control
                    type='number'
                    name='imposedValue'
                    placeholder={`${propTrans({ prop: 'A0693' })} (${propTrans({
                      prop: 'A0570',
                    })})`}
                    step={0.01}
                    value={el.imposedValue}
                    onChange={(e) => onChange(e, 'imposedValue', undefined, i, 'indicators')}
                  />
                </Col>
                <Col>
                  {isThisIndicatorFilled && i === lastIndex ? (
                    <Button variant='link' className='mr-3' onClick={() => addIndicator()}>
                      <IcoPlus className='second-color ico-small indicators' />
                    </Button>
                  ) : null}
                  {kpiIndicators.length !== 1 ? (
                    <Button variant='link' onClick={() => removeIndicator(i)}>
                      <IcoMinusCircled className='red-color ico-small indicators' />
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      });
    }

    return rows;
  };

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='kpis' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-6'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0675' />
            </h5>
          </div>
          <div className='col-6 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    <Trans code='A0676' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder={propTrans({ prop: 'A0035' }) + '...'}
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>

        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={kpis}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                //onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                <Trans code='A0685' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size='xl'
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2'>
            <Trans code='A0677' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewKpi} id='form-admin-kpi'>
            <Row className='mb-3'>
              <Col className='px-0'>
                <Form.Group className='pl-4 pr-2' controlId='name'>
                  <Form.Label className='mb-3'>
                    <Trans code='A0670' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='name'
                    value={newKpi.name}
                    onChange={(e) => onChange(e)}
                  />
                </Form.Group>
                <Form.Group className='pl-4 pr-2' controlId='udm'>
                  <Form.Label className='mb-3'>
                    <Trans code='A0671' />
                    &nbsp;<span className='required-field'>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='udm'
                    placeholder='es. cm'
                    maxLength='10'
                    value={newKpi.udm}
                    onChange={(e) => onChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='px-4' controlId='description'>
                  <Form.Label className='mb-3'>
                    <Trans code='A0621' />
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    name='description'
                    value={newKpi.description ?? ''}
                    onChange={(e) => onChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='px-4' controlId='indicators'>
                <Form.Label className='mb-3 mr-4'>
                  <Trans code='A0678' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                {newKpi.indicators ? indicatorsRows(newKpi.indicators) : null}
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} className='col-6 px-4' controlId='formula'>
                <Form.Label className='mb-3'>
                  <Trans code='A0466' replacementText='Formula' />
                  &nbsp;<span className='required-field'>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  name='formula'
                  placeholder='es. (A+B)*C'
                  value={newKpi.formula}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            </Row>
          </Form>
          <FormulaTester />
          {sbShow}

          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;
            <Trans code='A0503' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='primary' onClick={(e) => handleNewKpi(e)}>
            <Trans code='A0644' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Kpi;
