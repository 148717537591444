import { Trans } from '@lib/i18n/index';
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { date } from '@lib/helpers/index';
import { useHistory, useParams } from 'react-router-dom';
const ExecutionIndicator = ({ analysis, analysisUrl, height = 'auto' }) => {
  const { idScenario, idVariante } = useParams();
  const history = useHistory();
  const getStyle = (el) => {
    return {
      bullet: {
        backgroundColor:
          el.status === 'Executed' ? '#689689' : el.status === 'Error' ? '#C94723' : '#FFCB47',
      },
      text: {
        color: el.status === 'Executed' ? '#689689' : el.status === 'Error' ? '#C94723' : '#FFCB47',
      },
    };
  };
  return (
    <Row className='my-4 d-flex flex-column' style={{ height: height }}>
      <Col className='d-flex justify-content-between '>
        <Col className='pl-0'>
          {analysis.status !== 'Executed' ? (
            <h5 className='mb-0'>
              <Trans code={analysis.type} />
            </h5>
          ) : (
            <Button
              variant='link'
              onClick={() => {
                history.push(
                  `/scenario/${idScenario}/dashboard/variant/${idVariante}/${analysisUrl}`
                );
              }}>
              <h5>
                <Trans code={analysis.type} />
              </h5>
            </Button>
          )}
        </Col>
        <Col className='ml-3'>
          <Row className='flex-column'>
            <span className='dot-legend mb-2'>
              <i className='bullet' style={{ ...getStyle(analysis).bullet }} />
              <span className='text' style={{ ...getStyle(analysis).text }}>
                <Trans
                  code={
                    analysis.status === 'Executed'
                      ? 'A0614'
                      : analysis.status === 'Error'
                      ? 'A0613'
                      : analysis.status === 'Draft'
                      ? 'A0612'
                      : analysis.status === 'New'
                      ? 'A0648'
                      : analysis.status === 'Deleted'
                      ? 'A0649'
                      : ''
                  }
                />
              </span>
            </span>

            <span className='dot-legend'>
              <i className='bullet invisible' style={{ ...getStyle(analysis).bullet }} />
              {analysis.executedAt ? date(analysis.executedAt) : ''}
            </span>

            <span className='dot-legend'>
              <i className='bullet invisible' style={{ ...getStyle(analysis).bullet }} />
              {analysis.status === 'Error' ? (
                <span style={{ ...getStyle(analysis).text }}>{analysis.log}</span>
              ) : null}
            </span>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default ExecutionIndicator;
