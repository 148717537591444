import {
  getESGVariantQuestionnaire,
  postESGVariantQuestionnaireAnswers,
  getESGVariantAnswers,
} from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import EsgComponent from 'components/QuestionnaireComponents/esgComponent';
import StatusBar from 'components/wizard/StatusBar';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const StepSubEsg = () => {
  const { idScenario = null, idVariante = null } = useParams();

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sbShow, setSbShow] = useState(null);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  useEffect(() => {
    if (!idScenario || !idVariante) return;
    fetchData(
      getESGVariantQuestionnaire,
      setQuestions,
      null,
      [idScenario, idVariante],
      null,
      setError
    );
  }, [idScenario, idVariante]);

  const shapeAnswers = (data) => {
    const answers = data ? data : [];
    setAnswers(answers.questions);
  };
  useEffect(() => {
    fetchData(getESGVariantAnswers, null, shapeAnswers, [idScenario, idVariante], null, setError);
  }, []);

  const handleEditQuestionnaire = async (e) => {
    e.preventDefault();
    console.log('answers', answers);
    setSbShow(null);
    setIsLoading(true);
    setError(null);
    const answersToSend = { questions: [...answers] };
    console.log('answersToSend', answersToSend);
    const response = await postESGVariantQuestionnaireAnswers(
      idScenario,
      idVariante,
      answersToSend
    );
    if (response.status) {
      handleStatusBar('success', 'Questionnaire saved successfully', true);
      // setVariantRefresh(true);
    } else {
      response?.message
        ? setError(response.message)
        : setError('Errore nel salvataggio del questionario');
    }
    setIsLoading(false);
  };
  console.log('questions', questions, 'answers', answers);
  return (
    <>
      {questions && answers && (
        <EsgComponent
          questionsData={questions}
          answersData={answers}
          setAnswersData={setAnswers}
          postFunction={handleEditQuestionnaire}
          isLoading={isLoading}
          sbShow={sbShow}
        />
      )}
      {error && <StatusBar status='error' message={error} timeout={false} />}
    </>
  );
};

export default StepSubEsg;
