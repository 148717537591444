import React, { useState, useEffect, useCallback } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col, Tabs, Tab } from 'react-bootstrap';

// import { Link } from 'react-router-dom';
import { Trans, propTrans } from '@lib/i18n';
import { IcoEdit, IcoPlus, IcoMinusCircled, TooltipInfoIcon } from '@icons';
import { putOEInterventionModules, deleteOEInterventionModules } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';

const ActionBtnRenderer = ({ data, setRefresh }) => {
  const code = data.code;
  const [interventionType, setInterventionType] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  // const [associatedScenarios, setAssociatedScenarios] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const [isLoading, setIsLoading] = useState(false);
  // console.log('selected interventionType', interventionType);

  useEffect(() => {
    setInterventionType(data);
  }, [data]);

  useEffect(() => {
    interventionRows(interventionType.invTypes);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interventionType.invTypes]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const onChange = (e, fieldname, setSelect, invTypeIndex, section) => {
    if (section === 'invTypes') {
      let tempInterventions = [...interventionType.invTypes];

      let tempElement = {
        ...tempInterventions[invTypeIndex],
        [e.target ? e.target.name : fieldname]: e.target ? e.target.value : e.value,
      };

      tempInterventions[invTypeIndex] = tempElement;

      setInterventionType(() => ({
        ...interventionType,
        invTypes: tempInterventions,
      }));
    } else if (setSelect) {
      setSelect(e ?? []);

      setInterventionType(() => ({
        ...interventionType,
        [fieldname]: e ? e.value ?? e : [],
      }));
    } else {
      setInterventionType(() => ({
        ...interventionType,
        [e.target.name]:
          e.target.name === 'formula' ? e.target.value.toUpperCase() : e.target.value,
      }));
    }
  };

  const addIndicator = () => {
    let tempIndicators = [...interventionType.invTypes];

    tempIndicators.push({ name: '', description: '' });

    setInterventionType({
      ...interventionType,
      invTypes: tempIndicators,
    });
  };

  const removeIndicator = async (index) => {
    setSbShow(<></>);
    setIsLoading(true);
    let tempIndicators = [...interventionType.invTypes];

    // Remove the indicator from the list if it's a new one and not saved yet
    if (!tempIndicators[index]?.code) {
      tempIndicators.splice(index, 1);
      setInterventionType({
        ...interventionType,
        invTypes: tempIndicators,
      });
      setIsLoading(false);
      return;
    }
    // Remove the indicator from the list if it's a new one and not saved yet
    if (!tempIndicators[index]?.code) {
      tempIndicators.splice(index, 1);
      setInterventionType({
        ...interventionType,
        invTypes: tempIndicators,
      });
      setIsLoading(false);
      return;
    }
    // tempIndicators.splice(index, 1);
    // setInterventionType({
    //   ...interventionType,
    //   invTypes: tempIndicators,
    // });

    const deleteResult = await deleteOEInterventionModules(tempIndicators[index]?.code);

    if (deleteResult.status) {
      handleStatusBar('success', <Trans code='A0657' />, true);

      tempIndicators.splice(index, 1);
      setInterventionType({
        ...interventionType,
        invTypes: tempIndicators,
      });
      setIsLoading(false);
      setTimeout(() => {
        setSbShow(<></>);
      }, 2000);
    } else {
      handleStatusBar('error', deleteResult.message || <Trans code='A0527' />, false);
      setIsLoading(false);
    }
  };

  const handleEditStatus = async (e) => {
    e?.preventDefault();
    setSbShow(<></>);
    setIsLoading(true);
    const { description, invTypes } = interventionType;
    // const isKpiFilled = Object.values(restKpi).every((prop) => prop !== '' && prop != null)
    //   ? true
    //   : false;
    const areDetailsFilled = invTypes.every((prop) => prop.name !== '' && prop.description !== '')
      ? true
      : false;
    if (!areDetailsFilled) {
      handleStatusBar('error', <Trans code='A0522' />, true);
      setIsLoading(false);
      return;
    }
    handleStatusBar('elaborating', false, false);
    // console.log(interventionType);
    const editResult = await putOEInterventionModules(interventionType, code);
    if (editResult.status) {
      setIsLoading(false);
      handleStatusBar('success', <Trans code='A0524' />, true);
      setTimeout(() => {
        setShowModalEdit(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', editResult.message ?? <Trans code='A0521' />, false);
      setIsLoading(false);
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00055' />
    </Tooltip>
  );

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <Trans code='A0721' />
    </Tooltip>
  );

  const interventionRows = (invTypes) => {
    let rows = [];

    if (invTypes) {
      // let tempInvTypes = invTypes.length > 0 ? invTypes : newInvType;

      let lastIndex = invTypes.length - 1;

      rows = invTypes.map((el, i) => {
        console.log('elemento', el);
        const { code, associatedClass, description, ...restInvestmentType } = invTypes[i];
        const isThisInvestmentFilled = Object.values(restInvestmentType).every(
          (prop) => prop !== ''
        )
          ? true
          : false;

        return (
          <Row
            className='mb-3 d-flex justify-content-center align-items-center'
            key={`${i}${el.code ? el.code : 'name'}}`}>
            <Col>
              <Row>
                <Col className='col-5 d-flex'>
                  <Form.Control
                    className='custom-disabled'
                    disabled={el?.associatedClass?.length > 0 || isLoading}
                    type='text'
                    name='name'
                    placeholder={propTrans({ prop: 'A0714' })}
                    defaultValue={el.name}
                    onChange={(e) => onChange(e, 'name', undefined, i, 'invTypes')}
                  />
                  &nbsp;<span className='required-field'>*</span>
                </Col>
                <Col className='col-6 d-flex'>
                  <Form.Control
                    className='custom-disabled'
                    disabled={el?.associatedClass?.length > 0 || isLoading}
                    type='text'
                    name='description'
                    placeholder={propTrans({ prop: 'A0621' })}
                    defaultValue={el.description}
                    onChange={(e) => onChange(e, 'description', undefined, i, 'invTypes')}
                  />
                  &nbsp;<span className='required-field'>*</span>
                  {el?.associatedClass?.length > 0 && (
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  )}
                </Col>
              </Row>
            </Col>

            <Col className='col-1'>
              <Row className='align-items-center'>
                <Col>
                  {isThisInvestmentFilled && i === lastIndex ? (
                    <Button
                      // disabled={el?.associatedScenarios?.length > 0}
                      disabled={isLoading}
                      variant='link'
                      // className='mr-3'
                      onClick={() => addIndicator()}>
                      <IcoPlus className='second-color ico-small indicators' />
                    </Button>
                  ) : null}

                  <Button
                    disabled={el?.associatedClass?.length > 0 || isLoading}
                    variant='link'
                    onClick={() => {
                      console.log('questo', i, interventionType);
                      removeIndicator(i, el);
                    }}>
                    <IcoMinusCircled className='red-color ico-small indicators' />
                  </Button>

                  {/* {invTypes.length !== 1 ? (
                    <Button
                      disabled={el?.associatedScenarios?.length > 0}
                      variant='link'
                      onClick={() => {
                        console.log('questo', i, interventionType);
                        removeIndicator(i, el);
                      }}>
                      <IcoMinusCircled className='red-color ico-small indicators' />
                    </Button>
                  ) : null} */}
                </Col>
              </Row>
            </Col>
          </Row>
        );
      });
    }

    return rows;
  };

  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' onClick={() => handleShowModal(setShowModalEdit)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>

      <Modal
        size='xl'
        id='kpi-modal'
        show={showModalEdit}
        onHide={() => handleCloseModal(setShowModalEdit)}
        centered>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title className='px-2'>
            <Trans code='A0716' />
          </Modal.Title>
        </Modal.Header>
        <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} justify>
          <Tab eventKey='general'>
            <Modal.Body>
              <Form onSubmit={handleEditStatus} id='form-admin-kpi'>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='px-3' controlId='name'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0715' />
                        {/* &nbsp;<span className='required-field'>*</span> */}
                      </Form.Label>
                      <Form.Control
                        className='custom-disabled'
                        disabled={true}
                        type='text'
                        name='name'
                        value={interventionType.name}
                        // onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {interventionType?.invTypes?.length === 0 && (
                  <Row>
                    <Col className='d-flex justify-content-center align-items-center'>
                      <Button disabled={isLoading} onClick={() => addIndicator()}>
                        <Trans code='A0718' />
                      </Button>
                    </Col>
                  </Row>
                )}
                <Row className='px-3 mb-3'>
                  <Form.Group as={Col} className=' mb-0' controlId='indicators'>
                    <Form.Label className='mb-3 mr-4'>
                      <Trans code='A0717' />
                      &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    {interventionType.invTypes ? interventionRows(interventionType.invTypes) : null}
                  </Form.Group>
                </Row>
              </Form>
              {sbShow}

              <Row>
                <Col className='col-4 px-3'>
                  <span className='px-3'>
                    <span className='required-field'>*</span>&nbsp;
                    <Trans code='A0503' />
                  </span>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col className='px-3 text-right'>
                <Button
                  disabled={isLoading}
                  variant='secondary'
                  onClick={() => handleCloseModal(setShowModalEdit)}>
                  <Trans code='F0057' />
                </Button>
                <Button
                  disabled={isLoading}
                  variant='primary'
                  onClick={(e) => handleEditStatus(e)}
                  className='ml-3'>
                  <Trans code='A0561' />
                </Button>
              </Col>
            </Modal.Footer>
          </Tab>
        </Tabs>
      </Modal>
    </div>
  );
};

export default ActionBtnRenderer;
