import EsgMainComponent from 'components/scenariodashboard/esgRating/esgMainComponent';
import React, { useEffect, useState } from 'react';
import { getESGResults, getESGSubCategories } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
import StatusBar from 'components/wizard/StatusBar';
import CustomSpinner from 'components/CustomSpinner';
const EsgRatingPage = ({ idScenario, idVariante }) => {
  const [esgResults, setEsgResults] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (idScenario && idVariante) {
      fetchData(
        getESGResults,
        setEsgResults,
        null,
        [idScenario, idVariante],
        null,
        setError,
        setIsLoading
      );
      fetchData(getESGSubCategories, setSubcategories, null, [], null, setError, setIsLoading);
    }
  }, [idScenario, idVariante]);

  if (isLoading) {
    return (
      <div className='container-fluid'>
        <CustomSpinner style={{ minHeight: '100px' }} />
      </div>
    );
  }
  return (
    <div id='summaryDashboard' className='pt-0'>
      <div className='container-fluid'>
        {!esgResults.length ? (
          <h1>Nessun Dato da visualizzare</h1>
        ) : (
          !!esgResults.length &&
          !!subcategories.length && (
            <EsgMainComponent esgResults={esgResults} subcategories={subcategories} />
          )
        )}

        {error && <StatusBar status={'error'} message={error} timeout={false} />}
      </div>
    </div>
  );
};

export default EsgRatingPage;
