import { getESGResults } from '@lib/api/index';
import { fetchData, formatNumberWithLocale } from '@lib/helpers/index';
import React, { Fragment, useEffect, useState } from 'react';
import AnimatedDonutChartDash from '../AnimatedDonutChartDash';
import DashboardESGSummaryRatingScores from './dashboardESGSummary/dashboardESGSummaryScores';
import { Trans } from '@lib/i18n/index';

const DashboardSummaryESG = ({ variant }) => {
  const [esgResults, setEsgResults] = useState([]);
  const [dataForChart, setDataForChart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');

  const ratingColor = [
    { category: 'A+, AA, AA+, AAA', score: '60 - 100', color: '#c2c923' },
    {
      category: 'BB, BBB, A',
      score: '30 - 60',
      color: '#08a2aa',
    },
    { category: 'C, B', score: '0 - 30', color: '#cb1b4a' },
  ];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);
  useEffect(() => {
    if (variant?.id && variant?.scenarioId) {
      fetchData(
        getESGResults,
        setEsgResults,
        null,
        [variant.scenarioId, variant.id],
        null,
        setError,
        setIsLoading
      );
    }
  }, [variant]);
  console.log('esgResults', esgResults);
  useEffect(() => {
    if (!esgResults.length > 0) return;

    const data = esgResults.filter((el) => el.type === 'category' || el.type === 'total');
    const dataToUse = data.map((el) => {
      return el.type === 'total'
        ? { propertyName: 'total', propertyValue: el.scorePerc }
        : {
            propertyName: el.category,
            propertyValue: el.scorePerc,
          };
    });
    setDataForChart(
      dataToUse.sort((a, b) => {
        const order = ['E', 'S', 'G'];
        return order.indexOf(a.propertyName) - order.indexOf(b.propertyName);
      })
    );
  }, [esgResults]);
  console.log('dataForChart', esgResults);
  return (
    <div className='simple-box impact'>
      <div className='row'>
        <div className='col'>
          <h5 className='title-simple-box margin-title-box'>ESG</h5>
        </div>
      </div>
      <div className=' d-flex justify-content-around align-items-center pb-5'>
        <div className='chartBox impactChart smallImpactChart' style={{ minHeight: '0' }}>
          <AnimatedDonutChartDash
            chartId='esgSummary'
            data={dataForChart}
            codeLangTag='A0848'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
            maximumFractionDigits={2}
          />
        </div>
        <div
          className='d-flex flex-column justify-content-between align-items-center h-100 chartBox impactChart'
          style={{ minHeight: '200px' }}>
          {esgResults
            ?.filter((el) => el.type !== 'total' && el.type === 'category')
            .sort((a, b) => {
              const order = ['E', 'S', 'G'];
              return order.indexOf(a.category) - order.indexOf(b.category);
            })
            ?.map((el, i) => (
              <DashboardESGSummaryRatingScores
                key={`${el.category}_${i}`}
                category={el.category}
                value={el.scorePerc}
                rating={el.rating}
              />
            ))}
        </div>
        <div
          className='d-flex flex-column justify-content-center align-items-center h-100 chartBox impactChart'
          style={{ minHeight: '200px' }}>
          {esgResults
            ?.filter((el) => el.type === 'total')
            ?.map((el, i) => (
              <Fragment key={`${el.type}_scoreEsg_${i}`}>
                <h1 className='m-0 p-0'>{el.rating}</h1>
                <span>
                  <Trans code='A0848' />
                </span>
              </Fragment>
            ))}
        </div>
      </div>

      <div className='d-flex flex-row justify-content-center align-items-center'>
        <p className='m-0 p-0 mr-4'>
          <Trans code='A0849' />:
        </p>
        {ratingColor.map((el) => (
          <DashboardESGSummaryRatingScores
            key={el.category}
            category={el.category}
            value={el.score}
            color={el.color}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardSummaryESG;
