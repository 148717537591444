import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { IcoCanc, IcoEdit } from '@icons';
import { putDataset, deleteDataset } from '@lib/api';
import StatusBar from "components/wizard/StatusBar";

const ActionBtnRenderer = ({ data, setRefresh, optionsStatus, optionsCountries, optionsTypology }) => {
   const id = data.id;
   const [dataset, setDataset] = useState({});
   const [selectedStatus, setSelectedStatus] = useState([]);
   const [selectedCountry, setSelectedCountry] = useState([]);
   const [selectedTypology, setSelectedTypology] = useState([]);
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   //console.log('dataset:', dataset);

   useEffect(() => {
      setDataset(data);

      setSelectedStatus(optionsStatus.filter(optionStatus => optionStatus.value === data.status));

      setSelectedCountry(optionsCountries.filter(optionCountry => optionCountry.label === data.countryName));

      setSelectedTypology(optionsTypology.filter(optionTypology => optionTypology.value === data.type))
   }, [data, optionsStatus, optionsCountries, optionsTypology]);

   const handleCloseModal = (setShowModal) => setShowModal(false);
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout}/>);
   };

   const onChange = (e, fieldname, setSelect) => { 
      if (setSelect) {
         setSelect(e);

         setDataset(() => ({
            ...dataset,
            [fieldname]: e.value,
         }));
      } else {
         setDataset(() => ({
            ...dataset,
            [e.target.name]: e.target.value,
         }));
      }
   };

   const handleEditStatus = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const { description, region, fileName, status, modeller, family, sources, version, 
         author, createdAt, createdBy, idCountry, projects, updatedAt, updatedBy, ...restDataset } = dataset;
      console.log('restDataset', restDataset);

      const isEverythingVerified = Object.values(restDataset).every(prop => prop !== '' && prop != null) ? true : false;

      if (!isEverythingVerified) {
         handleStatusBar('error', 'Compila i campi obbligatori.*', false);
         return;
      }

      /* const editResult = await putDataset(dataset, id);
      console.log('editResult', editResult);

      if (editResult === true) {
         handleStatusBar('success', 'Informazioni modificate con successo*', true);

         setTimeout(() => {
            setShowModalEdit(false);
            setRefresh(true);
         }, 2000);         
      } else if (editResult.message || editResult.error) {
         handleStatusBar('error', 'There was an error when saving information.*', false);
      } */
   };

   const handleDelete = async () => {
      const deleteResult = await deleteDataset(id);
      //console.log('deleteResult', deleteResult);

      if (deleteResult === true) {
         handleStatusBar('success', 'Dataset eliminato correttamente*', true);

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000); 
      } else {
         handleStatusBar('error', 'Errore nell\'eliminazione del dataset.*', false);
      }
   };

   const editTooltip = (props) => <Tooltip {...props}>Modifica</Tooltip>;
   const deleteTooltip = (props) => <Tooltip {...props}>Elimina</Tooltip>; 

   return (
      <div>
         <OverlayTrigger placement="top" overlay={editTooltip}>
            <Button variant="link" className="mr-3" onClick={() => handleShowModal(setShowModalEdit)}>
               <IcoEdit className="second-color ico-small edit" />
            </Button>
         </OverlayTrigger>
         <OverlayTrigger placement="top" overlay={deleteTooltip}>
            <Button variant="link" className="mr-0" onClick={() => handleShowModal(setShowModalDelete)}>
               <IcoCanc className="second-color ico-small cancel" />
            </Button>
         </OverlayTrigger>

         <Modal size="xl" show={showModalEdit} onHide={() => handleCloseModal(setShowModalEdit)} centered>
            <Modal.Header closeButton>
               <Modal.Title className="px-2">Modifica dataset*</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
               <Form onSubmit={handleEditStatus} id="form-admin-user">
                  <Row className="mb-3">
                     <Col className="col-8">
                        <Row>
                           <Form.Group as={Col} className="px-4" controlId="title">
                              <Form.Label className="mb-3">
                                 Title&nbsp;<span className="required-field">*</span>
                              </Form.Label>
                              <Form.Control
                                 type="text"
                                 name="title"
                                 defaultValue={dataset.title}
                                 onChange={e => onChange(e)}
                              />
                           </Form.Group>
                        </Row>
                        <Row>
                           <Col className="px-4">
                              <Form.Group as={Col} className="px-0" controlId="title">
                                 <Form.Label className="mb-3">
                                    Typology&nbsp;<span className="required-field">*</span>
                                 </Form.Label>
                                 <Select
                                    name="type"
                                    value={selectedTypology}
                                    options={optionsTypology}
                                    onChange={e => onChange(e, 'type', setSelectedTypology)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col} className="px-0" controlId="fileName" /* style={{maxWidth: '33%'}} */>
                                 <Form.Label className="mb-3">
                                    File
                                 </Form.Label>
                                 <Form.Control
                                    type="file"
                                    name="fileName"
                                    //defaultValue={dataset.fileName}
                                    onChange={e => onChange(e)}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>
                     </Col>
                     <Col className="col-4">
                        <Row>
                           <Form.Group as={Col} className="px-4" controlId="description">
                              <Form.Label className="mb-3">
                                 Description
                              </Form.Label>
                              <Form.Control
                                 as="textarea"
                                 rows={4}
                                 name="description"
                                 defaultValue={dataset.description ?? ''}
                                 onChange={e => onChange(e)}
                              />
                           </Form.Group>
                        </Row>
                     </Col>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="countryName">
                        <Form.Label className="mb-3">
                           Country&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                           name="countryName"
                           value={selectedCountry}
                           options={optionsCountries}
                           onChange={e => onChange(e, 'countryName', setSelectedCountry)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="region">
                        <Form.Label className="mb-3">
                           Region
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="region"
                           defaultValue={dataset.region}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="localsalary">
                        <Form.Label className="mb-3">
                           Local salary&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="number"
                           step={0.01}
                           name="localsalary"
                           defaultValue={dataset.localsalary}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>

                  <Row className="mb-3">     
                     <Form.Group as={Col} className="px-4" controlId="year">
                        <Form.Label className="mb-3">
                           Year&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="number"
                           name="year"
                           min={1700}
                           defaultValue={dataset.year}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="status">
                        <Form.Label className="mb-3">
                           Status
                        </Form.Label>
                        <Select
                           name="status"
                           value={selectedStatus}
                           options={optionsStatus}
                           onChange={e => onChange(e, 'status', setSelectedStatus)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="modeller">
                        <Form.Label className="mb-3">
                           Family
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="family"
                           defaultValue={dataset.family}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="modeller">
                        <Form.Label className="mb-3">
                           Modeller
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="modeller"
                           defaultValue={dataset.modeller}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="sources">
                        <Form.Label className="mb-3">
                           Sources
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="sources"
                           defaultValue={dataset.sources}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="version">
                        <Form.Label className="mb-3">
                           Version
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="version"
                           defaultValue={dataset.version}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>  
               </Form>
               {sbShow}

               <Row>
                  <Col className='col-4 px-3'>
                     <span className='px-3'><span className="required-field">*</span>&nbsp;Campi obbligatori</span>
                  </Col>
               </Row>
            </Modal.Body>
            <Modal.Footer>  
               <Col className='px-3'>
                  <span>Sei sicuro di voler modificare le informazioni?*</span>
               </Col>
               <Col className='px-3 text-right'>
                  <Button variant="danger" onClick={() => handleCloseModal(setShowModalEdit)}>
                     Annulla
                  </Button>
                  <Button variant="primary" onClick={(e) => handleEditStatus(e)} className='ml-3'>
                     Conferma
                  </Button>
               </Col> 
            </Modal.Footer>
         </Modal>

         <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
            <Modal.Header closeButton>
               <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>Sei sicuro di voler eliminare l'utente?*</span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={() => handleCloseModal(setShowModalDelete)}>
                  Annulla
               </Button>
               <Button variant="danger" onClick={() => handleDelete()}>
                  Elimina
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default ActionBtnRenderer;
