import React, { Component } from "react"

class Ico89 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-89" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(21.4,39.5)">
          <path d="M73.5829 24.4364C72.2936 22.5331 70.1754 21.3973 67.8731 21.3973H66.6452V19.9238C66.6145 18.8493 66.3996 17.7749 65.9698 16.7619C65.5401 15.7796 64.8954 14.8586 64.1279 14.1219C63.3298 13.3544 62.4089 12.7712 61.3958 12.3721C60.3828 11.973 59.3084 11.7888 58.2033 11.8195H34.8728L26.216 1.22874C25.909 0.860365 25.51 0.522688 25.0802 0.3385C24.6504 0.123615 24.1593 0.000823142 23.6681 0.000823142H8.31914C6.13958 -0.0298748 4.05213 0.798969 2.48653 2.33386C0.920938 3.83806 0.0306979 5.92552 0 8.10507V48.5649C0.0306979 50.7445 0.920938 52.8319 2.48653 54.3361C4.05213 55.8403 6.13958 56.6999 8.31914 56.6692H58.326C60.5056 56.6999 62.5931 55.871 64.1586 54.3361C64.4349 54.0599 64.527 53.9371 64.7112 53.7222C64.8954 53.5073 64.9875 53.3538 64.9875 53.3538L74.2276 30.8829C75.0871 28.7648 74.8415 26.3703 73.5829 24.4364ZM6.66145 8.10507C6.66145 7.89019 6.72284 7.706 6.81494 7.52181C6.90703 7.33763 7.02982 7.18414 7.18331 7.03065C7.3368 6.90785 7.52099 6.78506 7.70518 6.72367C7.88937 6.66227 8.10425 6.63157 8.31914 6.66227H22.1025L30.7593 17.2531C31.0663 17.6214 31.4654 17.9591 31.8951 18.1433C32.3249 18.3582 32.8161 18.481 33.3072 18.481H58.326C58.5409 18.4503 58.7251 18.481 58.94 18.5424C59.1242 18.6038 59.3084 18.6959 59.4619 18.8493C59.6154 18.9721 59.7381 19.1563 59.8302 19.3405C59.9223 19.5247 59.9837 19.7089 59.9837 19.9238V21.3973H20.9667C18.1732 21.3973 15.6866 23.055 14.6122 25.6643L6.66145 44.9733V34.1676V8.10507ZM68.2722 28.4271L59.6154 49.4245C59.554 49.4859 59.4926 49.5473 59.4312 49.6087C59.2777 49.7314 59.0935 49.8542 58.9093 49.9156C58.7251 49.977 58.5102 50.0077 58.2953 49.977H11.5731L20.5676 28.1201C20.629 27.9666 20.7825 27.8438 20.9667 27.8438H67.8731C67.9345 27.8438 68.088 27.8438 68.2415 28.028C68.395 28.2122 68.2722 28.3964 68.2722 28.4271Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico89