import { useState, useRef } from 'react';
import { IcoDownloadFile, IcoUploadFile, Lens } from '@icons';
import { Button, Col } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n/index';
import ShowDetailsModal from '@components/adminManagement/newDatasetManagement/showShadowPrices';
const DownloadUploadItems = ({
  title,
  handleDownload,
  handleUpload,
  status,
  associatedScenarios,
  hasDetails = false,
  regionsDatasetTypes = [],
  disabled,
  datasetId,
  isShadow,
}) => {
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const legendStyles = {
    bullet: {
      display: 'inline-block',
      width: '1em',
      height: '1em',
      borderRadius: '0.5em',
      top: '0.15em',
      position: 'relative',
    },
  };

  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleUpload(fileUploaded);
  };

  const openFileUpload = () => {
    inputRef.current.click();
  };

  return (
    <Col className='d-flex justify-content-between align-items-center mx-4 col-4'>
      <p className='mb-0 mr-4'>{title}</p>

      <div className='d-flex justify-content-between align-items-center w-50'>
        <>
          <Button
            variant='link'
            disabled={disabled || hasDetails}
            title={propTrans({ prop: 'A0579' })}
            onClick={() => openFileUpload()}>
            <IcoUploadFile className='second-color ico-small download-upload' />
          </Button>
          <input
            type='file'
            id='file-input-dat'
            ref={inputRef}
            onChange={handleFileChange}
            disabled={associatedScenarios}
            style={{ display: 'none' }}
          />
          <label
            style={{ display: 'none' }}
            className='file-input-dat'
            htmlFor='file-input-dat'></label>
        </>

        <Button variant='link' title='Download Excel file' onClick={() => handleDownload(isShadow)}>
          <IcoDownloadFile className='second-color ico-small download-upload' />
        </Button>

        <Button
          variant='link'
          title={'Visualizza Prezzi Ombra'}
          onClick={() => {
            setShowModal(true);
          }}
          disabled={!hasDetails}>
          <Lens className='ico-small lens-bm green-lens' />
        </Button>

        <span>
          <i
            style={{
              ...legendStyles.bullet,
              backgroundColor:
                status === 1 ? '#689689' : status === 2 ? '#C94723' : status === 0 ? '#C4C4C4' : '',
            }}
          />
        </span>
      </div>
      <ShowDetailsModal
        showModal={showModal}
        setShowModal={setShowModal}
        regionsDatasetTypes={regionsDatasetTypes}
        datasetId={datasetId}
      />
    </Col>
  );
};

export default DownloadUploadItems;
