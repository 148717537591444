import React, { useEffect, useState } from 'react';
import DocumentsUploadItem from './DocumentUploadItems';
import DocumentDownloadItem from './DocumentDownloadItems';
import StatusBar from 'components/wizard/StatusBar';
import { getEntityDocuments } from '@lib/api/index';
import { fetchData } from '@lib/helpers/index';
const DocumentsWrapper = ({ entityType, entityId }) => {
  const [sbShow, setSbShow] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar key={Math.random()} status={status} message={message} timeout={timeout} />
    );
  };
  const transformClassData = (data, setState) => {
    const dataToStore = data;
    setState(dataToStore || []);
  };

  useEffect(() => {
    if (entityType && entityId) {
      fetchData(
        getEntityDocuments,
        setDocuments,
        transformClassData,
        [entityType, entityId],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
  }, [entityType, entityId]);

  useEffect(() => {
    if (refresh) {
      fetchData(
        getEntityDocuments,
        setDocuments,
        transformClassData,
        [entityType, entityId],
        handleStatusBar,
        null,
        setIsLoading
      );
    }
    setRefresh(false);
  }, [refresh]);

  return (
    <div className='px-5'>
      <DocumentsUploadItem
        title='Upload Documenti'
        subtitleTransCode={`A0826`}
        entityType={entityType}
        entityId={entityId}
        handleStatusBar={handleStatusBar}
        setRefresh={setRefresh}
      />
      <hr className='mb-5' style={{ maxWidth: '80%' }} />
      <DocumentDownloadItem
        title='Documenti'
        subtitleTransCode={`A0825`}
        sectionTitleTransCode={`A0827`}
        documents={documents}
        isLoading={isLoading}
        handleStatusBar={handleStatusBar}
      />
      {sbShow}
    </div>
  );
};

export default DocumentsWrapper;
