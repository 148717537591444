import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n'
import { rounder } from '@lib/helpers/index'
import BillboardChart from 'react-billboardjs'
import { bar } from 'billboard.js'
import ChartLegend from '@components/ChartLegend'
import { chartColors as colors } from '@lib/helpers'
import { Ico21 } from "@icons/new";

const GdpTipologiesChart = ({ gdp }) => {
  const keys = Object.keys(gdp.tipologies).filter(k => k !== 'total')
  const chartColors = keys.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {})
  const colorFunction = (c, d) => chartColors[d.id]
  const [chartData, setChartData] = useState({
      columns: keys.map(k => [k, gdp.tipologies[k]]),
      type: bar(), 
      color: colorFunction
   })

  /* const chartData = {
    columns: keys.map(k => [k, gdp.tipologies[k]]),
    type: bar(),
    color: colorFunction
  } */

  const axis = {
    x: {
      show: false
    }
  }

  useEffect(() => {
   setChartData({
     columns: keys.map(k => [k, parseFloat(gdp.tipologies[k])/gdp.tipologies['total']*100])
   })    
   // eslint-disable-next-line
 }, [])

  const values = Object.keys(gdp.tipologies).filter(prop => prop !== 'total').map(prop => gdp.tipologies[prop]);

  return <>
   <Card className="cards-utente d-md-block" style={{paddingBottom: '6rem'}}>
      <Card.Body>
         <Ico21 className="logo-card ico-box-blue" />
         <Card.Title><Trans code="A0182" /></Card.Title>
         <BillboardChart
         className="centered-donut"
         data={chartData}
         legend={{ show: false }}
         tooltip={{
            grouped: false,
            format: {
               title: () => '',
               value: d => rounder(d),
               name: n => propTrans({ prop: n, scope: 'gdp' })
            }
         }}
         transition={{ duration: 800 }}
         axis={axis}
         bar={{
            width: 35,
            padding: 10
         }}
         grid={{ focus: { show: false } }}
         />  
         <div className="my-3"><ChartLegend data={keys} colors={colors} scope="investment" values={values} allData={gdp.tipologies}/></div>
      </Card.Body>
   </Card>
  </>
}

export default GdpTipologiesChart