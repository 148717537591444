import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { Trans } from '@lib/i18n';

const PEF = ({
  data,
  showModalData,
  setShowModalData,
  expenseItems,
  regionName,
  type = 'financialData',
}) => {
  const [allPefData, setAllPefData] = useState({});

  useEffect(() => {
    setAllPefData(data);
  }, [data]);

  const handleCloseModal = (setShowModal) => setShowModal(false);

  const totalPerColumn = (pefData, colH) => {
    const dataFilteredByCol = pefData.filter((el) => el.year === colH).map((el) => el.value);
    const total = dataFilteredByCol.reduce((prev, curr) => Number(prev) + Number(curr));

    return (
      <span>
        <span>
          {total.toLocaleString('it-IT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </span>
    );
  };

  const buildPEF = (typeCode) => {
    const pefData = allPefData.filter((data) => data.type === typeCode);
    if (pefData.length === 0)
      return (
        <div className='text-center'>
          <span className='text-info'>
            <Trans code='A0765' />
          </span>
        </div>
      );

    const colHeader = [
      <React.Fragment key='initial-headers'>
        <th key='th-empty'>
          <Trans code='A0593' />
        </th>
        {type !== 'financialData' && (
          <th className='text-right' key='th-conv'>
            <Trans code='A0764' />
          </th>
        )}
      </React.Fragment>,
    ];

    let colHeaderX = pefData.map((el) => el.year);
    colHeaderX = colHeaderX.filter((item, index) => colHeaderX.indexOf(item) === index);

    colHeader.push(
      colHeaderX.map((colH) => (
        <th key={colH} className='text-right cell-width-economic-data'>
          {colH}
        </th>
      ))
    );

    let rowHeaderX = pefData.map((el) => el.expenseitemId);
    rowHeaderX = rowHeaderX.filter((item, index) => rowHeaderX.indexOf(item) === index);

    return (
      <table key={'table-for-' + typeCode} className='show-economic-data'>
        <thead key={'thead-for-' + typeCode}>
          <tr>{colHeader}</tr>
        </thead>
        <tbody key={'tbody-for-' + typeCode} className='text-right'>
          <tr className='totals-row'>
            <td className='text-left'>
              <strong>
                <Trans code='A0111' />
              </strong>
            </td>
            {type !== 'financialData' && <td className='text-right'>-</td>}
            {colHeaderX.map((colH) => (
              <td key={colH + '-total'} style={{ minWidth: '120px' }} className='text-right'>
                <strong>
                  {Object.keys(pefData).length > 0 &&
                    pefData.length > 0 &&
                    totalPerColumn(pefData, colH)}
                </strong>
              </td>
            ))}
          </tr>

          {rowHeaderX.map((rowH) => {
            return (
              <tr key={rowH}>
                <td className='pl-2'>
                  {expenseItems
                    .filter((exp) => Number(exp.id) === Number(rowH))
                    .map((exp) => exp.name)}
                </td>
                {type !== 'financialData' && (
                  <td>
                    {Number(
                      pefData.find((el) => el.expenseitemId === rowH)?.conversionFactor
                    ).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                )}
                {colHeaderX.map((colH) => (
                  <td key={colH + '-td'}>
                    {pefData
                      .filter((el) => el.year === colH && el.expenseitemId === rowH)
                      .map((el) => (
                        <Fragment key={el.id}>
                          {Number(el.value).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Fragment>
                      ))}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      dialogClassName='modal-fullscreen'
      show={showModalData}
      onHide={() => handleCloseModal(setShowModalData)}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans code={type === 'financialData' ? 'A0586' : 'A0768'} /> - {regionName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5'>
        <Tabs defaultActiveKey='capex' id='tab-expense-vector' className='mb-3 tab-economic-data'>
          <Tab eventKey='capex' title='CAPEX'>
            {Object.keys(allPefData).length > 0 ? buildPEF('capex') : null}
          </Tab>
          <Tab eventKey='opex' title='OPEX'>
            {Object.keys(allPefData).length > 0 ? buildPEF('opex') : null}
          </Tab>
          <Tab eventKey='revenues' title='REVENUES'>
            {Object.keys(allPefData).length > 0 ? buildPEF('revenues') : null}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleCloseModal(setShowModalData)}>
          <Trans code='A0529' />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PEF;
