import React from 'react'
import { rounder } from '@lib/helpers'
import { Trans } from '@lib/i18n'
import ProductionChart from '@components/scenario/production/ProductionChart'
import ProductionTable from '@components/scenario/production/ProductionTable'
import ProductionSectorsTable from '@components/scenario/production/ProductionSectorsTable'

const Production = ({ production: { agricolture, industry, services, total, settori, description } }) => (
  <>
    <div className="simple-box">
      <div className="row">
        <div className="col-3 investment-infobox va-number">
          <div>
            <h2>{rounder(total)}</h2>
            <span className="va-desc"><Trans code="A0053" /></span>
          </div>
        </div>
        <div className="col-9 investment-infobox">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-6 d-flex container-card">
        <ProductionChart productionData={{ agricolture, industry, services }} />
      </div>
      <div className="col-6 d-flex container-card">
        <ProductionTable productionData={{ agricolture, industry, services, total }} />
      </div>
    </div>
    <div className="row">
      <div className="col">
        <ProductionSectorsTable settori={settori} />
      </div>
    </div>
  </>
)

export default Production