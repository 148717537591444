import React, { useState, useEffect } from 'react';

const FadeInOutComponent = ({ children, isVisible }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (shouldRender) {
      setIsAnimating(true);
    }
  }, [shouldRender]);

  useEffect(() => {
    setShouldRender(isVisible);
  }, [isVisible]);

  const handleAnimationEnd = () => {
    if (!shouldRender) {
      setIsAnimating(false);
    }
  };

  return isAnimating ? (
    <div className={`${shouldRender ? 'fade-in' : 'fade-out'}`} onAnimationEnd={handleAnimationEnd}>
      {children}
    </div>
  ) : null;
};

export default FadeInOutComponent;
