import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';

import { Trans, propTrans } from '@lib/i18n';
import { IcoCanc, IcoEdit, IcoCopy } from '@icons';
import { deleteCorporateClass } from '@lib/api';
import StatusBar from 'components/wizard/StatusBar';

const ActionBtnRendererCorporateClass = ({
  data,
  setRefresh,
  optionsKpi,
  setSelectedKpi,
  setShowModalNew,
  setNewClass,
  setSelectedOrganization,
  optionsOrganizations,
}) => {
  const id = data.id;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setNewClass({});
    setSelectedOrganization([]);
    setRefresh(true);
    setShowModal(false);
  };
  console.log('setSelectedOrganization');
  const handleShowModal = (setShowModal) => {
    setNewClass(data);
    if (Object.keys(data).length > 0) {
      setSelectedKpi(
        optionsKpi.filter((optionKpi, i) => {
          return data?.kpis?.some((kpi) => {
            return Number(optionKpi.value) === Number(kpi.kpiId);
          });
        })
      );
      setSelectedOrganization(
        optionsOrganizations.filter((optionOrg, i) => {
          return data?.companies?.some((org) => {
            return Number(optionOrg.value) === Number(org.id);
          });
        })
      );
    }
    setShowModal(true);
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleDelete = async () => {
    if (!id) return;
    const deleteResult = await deleteCorporateClass(id);

    if (deleteResult.status) {
      handleStatusBar('success', propTrans({ prop: 'A0526' }), true);

      setTimeout(() => {
        setShowModalDelete(false);

        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar('error', deleteResult.message ?? propTrans({ prop: 'A0527' }), false);
    }
  };

  // const handleCopy = async () => {
  //   console.log('KPITEST', thisClass);
  //   const copyResult = await copyFullClass(thisClass, id);
  //   // const copyResult = withKPI
  //   //   ? await copyFullClass(thisClass, id)
  //   //   : await copyClass(thisClass, id);
  //   //console.log('copyResult', copyResult);

  //   if (copyResult.status === true) {
  //     handleTableStatusBar('success', propTrans({ prop: 'A0695' }), true);

  //     setTimeout(() => {
  //       setRefresh(true);
  //     }, 2000);
  //   } else {
  //     handleTableStatusBar('error', copyResult.message ?? propTrans({ prop: 'A0694' }), false);
  //   }
  // };

  const editTooltip = (props) => {
    return <Tooltip {...props}>{propTrans({ prop: 'F00055' })}</Tooltip>;
  };
  // const cloneTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'F00057' })}</Tooltip>;
  const deleteTooltip = (props) => <Tooltip {...props}>{propTrans({ prop: 'F00056' })}</Tooltip>;

  return (
    <div>
      <OverlayTrigger placement='top' overlay={editTooltip}>
        <Button variant='link' className='mr-3' onClick={() => handleShowModal(setShowModalNew)}>
          <IcoEdit className='second-color ico-small edit' />
        </Button>
      </OverlayTrigger>
      {/* <OverlayTrigger placement='top' overlay={cloneTooltip}>
       
        <Button variant='link' className='mr-3' onClick={() => handleCopy()}>
          <IcoCopy className='second-color ico-small copy' />
        </Button>
      </OverlayTrigger> */}
      <OverlayTrigger placement='top' overlay={deleteTooltip}>
        <Button variant='link' className='mr-0' onClick={() => handleShowModal(setShowModalDelete)}>
          <IcoCanc className='second-color ico-small cancel' />
        </Button>
      </OverlayTrigger>

      {/* Modal Delete */}
      <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans code='A0515' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3'>
            <span>
              <Trans code='A0517' />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
            <Trans code='F0057' />
          </Button>
          <Button variant='danger' onClick={() => handleDelete()}>
            <Trans code='F00056' />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRendererCorporateClass;
