import React, { useState } from 'react';
import VariantItem from './variantItem';
import { Button } from 'react-bootstrap';
import { Trans } from '@lib/i18n/index';
import CustomSpinner from 'components/CustomSpinner';

const initialState = {
  name: '',
};
const VariantItemsWrapper = ({
  variants,
  selectedVariant,
  setSelectedVariant,
  setItems,
  setError,
  isLoading,
  setIsLoading,
  setVariantRefresh,
  setIsOpen,
}) => {
  const [isOneEditing, setIsOneEditing] = useState(null);
  return (
    <div className='d-flex flex-column p-2'>
      <div className='p-3'>
        <div className='d-flex justify-content-between font-weight-bold'>
          <div className='d-flex flex-grow-1 p-2 variant-header-wrapper'>
            <div className='flex-grow-1 align-self-center'>
              <Trans code='A0636' />
            </div>
            <div className='text-right mr-5 flex-grow-1 align-self-center'>
              <Trans code='A0386' />
            </div>
            <div style={{ minWidth: '75px' }}></div>
          </div>
        </div>
        {isLoading ? (
          <CustomSpinner style={{ minHeight: '100px' }} />
        ) : (
          <div id='variants-wrapper'>
            {variants?.map((variant, index) => {
              return (
                <VariantItem
                  key={`${variant.name}${variant.id}`}
                  item={variant}
                  index={index}
                  selectedVariant={selectedVariant}
                  setSelectedVariant={setSelectedVariant}
                  items={variants}
                  setItems={setItems}
                  isOneEditing={isOneEditing}
                  setIsOneEditing={setIsOneEditing}
                  setError={setError}
                  setIsLoading={setIsLoading}
                  setVariantRefresh={setVariantRefresh}
                  setIsOpen={setIsOpen}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className='d-flex justify-content-end my-2 mx-3'>
        <Button
          disabled={isOneEditing}
          onClick={() => setItems([...variants, initialState])}
          // style={{ color: '#2c3d68', textShadow: 'none' }}
        >
          <Trans code='A0803' />
        </Button>
      </div>
    </div>
  );
};

export default VariantItemsWrapper;
