import React, { useEffect, useMemo, useState } from 'react';
import AttachmentsCard from './AttachmentsCard';

const AttachmentsCardWrapper = ({ scenarioAdditionalInfo }) => {
  const [additionalInfos, setAdditionalInfos] = useState([]);

  useEffect(() => {
    if (scenarioAdditionalInfo) {
      setAdditionalInfos(
        scenarioAdditionalInfo.filter((el) => ['link', 'image', 'file'].includes(el.topic))
      );
    }
  }, [scenarioAdditionalInfo]);

  const attachmentTypes = useMemo(() => {
    return Array.from(
      new Set(additionalInfos?.filter((el) => el.type !== 'text')?.map((el) => el.type))
    );
  }, [additionalInfos]);

  return (
    <div className='row d-flex flex-wrap'>
      {attachmentTypes?.map((el, i) => {
        return (
          <div key={`${i}_${el}`} className='col attachmentMainCol'>
            <AttachmentsCard attachments={scenarioAdditionalInfo} attachmentType={el} />
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentsCardWrapper;
