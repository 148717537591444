import { rounder } from '@lib/helpers'
import { Card } from 'react-bootstrap';
import { Trans, ApiTrans } from '@lib/i18n'
import { Ico35 } from "@icons/new";

const ProductionTable = ({ productionData }) => {
  return <Card className="cards-utente d-none d-md-block card-table">
    <Card.Body>
      <Ico35 className="logo-card ico-box-blue" />
      <Card.Title><Trans code="A0178" /></Card.Title>
      <table className="investment-table">
        <thead>
          <tr>
            <th className="value-label"><Trans code="A0068" /></th>
            <th><Trans code="A0117" /></th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(productionData).filter(k => k !== 'total').map(k => {
            const value = productionData[k]
            return (
              <tr key={k}>
                <td className="value-label"><ApiTrans scope="production" prop={k} /></td>
                <td>{rounder(value)}</td>
              </tr>
            )
          })}
          {Object.keys(productionData).filter(k => k === 'total').map(k => {
            const value = productionData[k]
            return (
              <tr key={k}>
                <th className="value-label"><ApiTrans scope="production" prop={k} /></th>
                <th>{rounder(value)}</th>
              </tr>
            )
          })}
        </tbody>
      </table>
      <p className="va-desc"><Trans code="A0053" /></p>
    </Card.Body>
  </Card>
}

export default ProductionTable