import React, { useState } from 'react'
import { TooltipInfoIcon } from '@icons'

const TooltipInfo = ({content}) => {
  const [visible, setVisible] = useState(false)

  return <span className="tooltipSpan" style={{position: 'relative'}}>
    <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} />
    <div className="tooltipInfoBox" style={{display: visible ? 'block' : 'none'}} dangerouslySetInnerHTML={{__html: content}} />
  </span>
}

const Tooltip = ({ trad, code }) => {
  if (window.location.search.match(/notrad=1/gm)) return <span data-trans={code}>{code}</span>
  
  try {
    const content = JSON.parse(trad)
    if (content.tooltip) {
      return <span data-trans={code}><span dangerouslySetInnerHTML={{__html: content.label}} /><TooltipInfo content={content.tooltip} /></span>
    }
    return <span data-trans={code} dangerouslySetInnerHTML={{__html: content.label}} />
  } catch(e) {
    return <span data-trans={code}>{trad}</span>
  }
}

export default Tooltip