import React from 'react';
import { Row, Col } from 'react-bootstrap';

function WorldMapLegendElement({ color, value }) {
  return (
    <>
      <Col
        className='color-legend'
        style={{
          backgroundColor: color,
        }}></Col>
      <Col className='text-end'>
        <span className='fw-bold' style={{ fontSize: '.8rem' }}>
          Selezione
        </span>
        <p className='mb-0'>{value.toUpperCase()}</p>
      </Col>
    </>
  );
}

function WorldMapLegend({ data }) {
  return (
    <Row className='py-3 px-2'>
      {data.map((el) => (
        <WorldMapLegendElement key={el.color} color={el.color} value={el.value} />
      ))}
    </Row>
  );
}

export default WorldMapLegend;
