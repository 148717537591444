import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n';
import { rounder } from '@lib/helpers/index';
import BillboardChart from 'react-billboardjs';
import { donut } from 'billboard.js';
import ChartLegend from '@components/ChartLegend';
import { chartColors as colors } from '@lib/helpers';
import { Ico96 } from '@icons/new';

const GdpSectorsChart = ({ gdp }) => {
  const keys = Object.keys(gdp.sector).filter((k) => k !== 'total');
  const chartColors = keys.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {});
  const colorFunction = (c, d) => chartColors[d.id];
  const [chartData, setChartData] = useState({
    columns: keys.map((k) => [k, 0]),
    type: donut(),
    color: colorFunction,
  });

  useEffect(() => {
    setChartData({
      columns: keys.map((k) => [k, (parseFloat(gdp.sector[k]) / gdp.sector['total']) * 100]),
    });
    // eslint-disable-next-line
  }, []);
  console.log('CHARTDATA,', chartData, gdp);
  const values = Object.keys(gdp.sector)
    .filter((prop) => prop !== 'total')
    .map((prop) => gdp.sector[prop]);

  return (
    <>
      <Card className='cards-utente d-md-block' style={{ paddingBottom: '6rem' }}>
        <Card.Body>
          <Ico96 className='logo-card ico-box-blue' />
          <Card.Title>
            <Trans code='A0461' />
          </Card.Title>
          <BillboardChart
            className='centered-donut'
            data={chartData}
            legend={{ show: false }}
            donut={{ padAngle: 0.015, width: 35, label: { show: false } }}
            tooltip={{
              format: {
                value: (d) => rounder(d) + ' %',
                name: (n) => propTrans({ prop: n, scope: 'gdp' }),
              },
            }}
            transition={{ duration: 800 }}
          />
          <div className='my-3'>
            <ChartLegend
              data={keys}
              colors={colors}
              scope='gdp'
              values={values}
              allData={gdp.sector}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default GdpSectorsChart;
