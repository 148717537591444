import { formatNumberWithLocale } from '@lib/helpers/index';
import React from 'react';

const DashboardESGSummaryRatingScores = ({ category, value, rating = '', color = '' }) => {
  // const backgroundColorGenerator = (score) => {
  //   if (score >= 60) {
  //     return '#c2c923';
  //   } else if (score >= 30 && score < 60) {
  //     return '#08a2aa';
  //   } else {
  //     return '#cb1b4a';
  //   }
  // };
  const backgroundColorGenerator = (category) => {
    if (category === 'E') {
      return '#c2c923';
    } else if (category === 'S') {
      return '#08a2aa';
    } else {
      return '#cb1b4a';
    }
  };

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${!!color ? 'mr-4' : 'mb-3'}`}>
      {/* <div
        className={`${!!color ? 'square-before-small' : 'square-before'} mr-3`}
        style={{ backgroundColor: !color ? backgroundColorGenerator(value) : color }}></div> */}
      {rating ? (
        <div className='d-flex flex-column'>
          <h2 className='m-0 p-0' style={{ color: backgroundColorGenerator(category) }}>
            {category}
          </h2>
          <span>
            Score: <span className='font-weight-bold'>{formatNumberWithLocale(value, true)}</span>
          </span>
          <span>
            Rating: <span className='font-weight-bold'>{rating}</span>
          </span>
        </div>
      ) : (
        <p className='m-0 p-0'>
          {category}: {value}
        </p>
      )}
    </div>
  );
};

export default DashboardESGSummaryRatingScores;
