import { useEffect, useState } from 'react';
import EmploymentImpact from '@components/scenariodashboard/employment/EmploymentImpact';
import EmploymentDonutCard from '@components/scenariodashboard/employment/EmploymentDonutCard';
import EmploymentTypologiesTable from '@components/scenariodashboard/employment/EmploymentTypologiesTable';

// import EmploymentSectorTable from '@components/scenariodashboard/employment/EmploymentSectorTable';
import SectorTable from '@components/scenariodashboard/SectorTable';

import { getOEMacrosectors } from '@lib/api/index';
import { Ico45 } from '@icons/new';
const Employment = ({ jobs, filterForRegion }) => {
  const [macrosector, setMacrosectors] = useState([]);
  const [jobsMacrosectors, setJobsMacrosectors] = useState([]);
  useEffect(() => {
    getOEMacrosectors().then((result) =>
      setMacrosectors(result.data.filter((el) => el.type === 'macroaccount'))
    );
  }, []);
  console.log('REGIOFIL', filterForRegion);
  useEffect(() => {
    let tempArray = [];
    let tempRegion;
    let tempPropertyName;
    let tempPropertyValue;
    jobs.empByMacrosector?.forEach((scenarioMacro) => {
      macrosector.forEach((macro) => {
        if (scenarioMacro.propertyName === macro.code) {
          tempRegion = scenarioMacro.region;
          tempPropertyName = macro.description;

          tempPropertyValue = scenarioMacro.propertyValue;
        }
      });
      tempArray.push({
        region: tempRegion,

        propertyName: tempPropertyName,

        propertyValue: tempPropertyValue,
      });
    });

    setJobsMacrosectors(tempArray);
  }, [macrosector, jobs]);

  return (
    <>
      <EmploymentImpact jobs={jobs} filterForRegion={filterForRegion} />
      <div className='row'>
        <EmploymentDonutCard
          jobs={jobsMacrosectors}
          filterForRegion={filterForRegion}
          chartId={'chart-by-empMacrosector'}
          langTitleCode={'A0195'}
          icon={<Ico45 className='logo-card ico-box-blue' />}
        />
        <EmploymentTypologiesTable jobs={jobsMacrosectors} filterForRegion={filterForRegion} />
      </div>
      <SectorTable
        data={jobs.empBySector}
        filterForRegion={filterForRegion}
        tableLegendCode={'A0054'}
        shareBarVisible={true}
      />
    </>
  );
};

export default Employment;
