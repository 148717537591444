import React from 'react'
import { rounder } from '@lib/helpers'
import IncomesChart from '@components/scenario/incomes/IncomesChart'
import IncomesTables from '@components/scenario/incomes/IncomesTable'
import { Trans } from '@lib/i18n'
import {Ico42 } from "@icons/new"


const Incomes = ({ incomes: { incomesArrayFull, totalIncomes, description, base_url, image_red_text_1, image_red_text_2,image_red_1, image_red_2} }) => (
  <>
    <div className="simple-box">
      <div className="row">
        <div className="col-3 investment-infobox va-number">
          <div>
            <h2>{rounder(totalIncomes)}</h2>
            <span className="va-desc"><Trans code="A0053" /></span>
          </div>
        </div>
        <div className="col-9 investment-infobox">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-6 d-flex container-card">
        <IncomesChart incomesData={incomesArrayFull.reduce((a, v) => ({ ...a, [v.label]: v.value }), {})} />
      </div>
      <div className="col-6 d-flex container-card">
        <IncomesTables incomesData={ { ...incomesArrayFull.reduce((a, v) => ({ ...a, [v.label]: v.value }), {}), total: totalIncomes } } />
      </div>
    </div>
    { image_red_1 && image_red_1.indexOf("empty") < 0 && image_red_2 && image_red_2.indexOf("empty") < 0 && 
    <div className='row'>
      <div className="col-12 col-xl-6 d-flex container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{image_red_text_1}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
               <img className="m-auto img-card" src={`${base_url}${image_red_1}`} alt="" onerror="this.style.display='none'"/>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 d-flex container-card">
          <div className="simple-box">
            <div className="row">
              <div className="col">
                <Ico42 className="logo-card ico-box-blue" />
                <h5 className="title-simple-box margin-title-box">{image_red_text_2}</h5>
              </div>
            </div>
            <div className="row">
              <div className="text-center" />
              <img className="m-auto img-card" src={`${base_url}${image_red_2}`} alt="" onerror="this.style.display='none'"/>
            </div>
          </div>
        </div>
        </div>
    }
  </>
)

export default Incomes