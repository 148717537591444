import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { Ico45 } from "@icons/new";
import { Trans, propTrans } from '@lib/i18n'
import { rounder } from '@lib/helpers/index'
import BillboardChart from 'react-billboardjs'
import { donut } from 'billboard.js'
import ChartLegend from '@components/ChartLegend'
import { chartColors as colors } from '@lib/helpers'

const IncomesChart = ({ incomesData }) => {
  const keys = Object.keys(incomesData).filter(k => k !== 'total')
  const chartColors = keys.reduce((a, k, i) => ({ ...a, [k]: colors[i] }), {})
  const colorFunction = (c, d) => chartColors[d.id]
  const [chartData, setChartData] = useState({
    columns: keys.map(k => [k, 0]), 
    type: donut(), 
    color: colorFunction
  })

  useEffect(() => {
    let total = incomesData['total']    
    if (total === undefined) {
      total = Object.values(incomesData).reduce((prev, cur) => parseFloat(prev) + parseFloat(cur))
    }    
    setChartData({
      columns: keys.map(k => [k, incomesData[k]/total*100])
    })
    // eslint-disable-next-line
  }, [])

  return <Card className="cards-utente d-none d-md-block">
    <Card.Body>
      <Ico45 className="logo-card ico-box-blue" />
      <Card.Title><Trans code="A0204" /></Card.Title>
      <BillboardChart
        data={chartData}
        legend={{ show: false }}
        donut={{ padAngle: 0.015, width: 35, label: { show: false } }}
        tooltip={{ format: { value: d => rounder(d) + ' %', name: n => propTrans({ prop: n, scope: 'incomes' }) } }}
        transition={{ duration: 800 }}
      />
      <ChartLegend data={keys} colors={colors} scope="incomes" />
      <p className="va-desc"><Trans code="A0053" /></p>
    </Card.Body>
  </Card>
}

export default IncomesChart