import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Alert, ListGroup } from 'react-bootstrap';
import Select from 'react-select'
import { Trans, propTrans } from '@lib/i18n'
import { getBaseUrl, getDefaultHeaders, getScenario, getUploadHeaders } from '@lib/api'
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import StatusBar from "components/wizard/StatusBar";


const StepDescription = ({ props, scen }) => {
  const [scenarioData, setScenarioData] = useState(scen)
  console.log('scenarioData test:', scenarioData)
  const [scenarioImmagine, setScenarioImmagine] = useState((scenarioData.additionalInfo ? (scenarioData.additionalInfo.immagine ?? []) : []));
  //  const [scenarioImmagineText, setScenarioImmagineText] = useState((scenarioData.additionalInfo ? (scenarioData.additionalInfo.immagine_text ?? [] ) :  [] ));
  //  const scenarioImmagine=(scenarioData.additionalInfo.immagine??[])

  const [isSaved, setIsSaved] = useState(false)
  const [error, setError] = useState(null)
  const [form, setForm] = useState({})
  const [files, setFiles] = useState([])
  const history = useHistory()
  console.log('form test:', form)
  // Crea un ref per il file input
  const inputRef = useRef(null);

  var formData = new FormData()
  const idScenario = scen.scenario.id;

  const[sbShowSuccess, setSbShowSuccess] = useState(false);
  const[sbShowError, setSbShowError] = useState(false);

  //console.log("second component idSceanrio", idScenario)

  const setUploadFile = (file) => {
    console.log('in setUploadFile', file.name, file.files)
    formData.append(file.name, file.files[0])
  }
  const setUploadFileLink = (target) => {    
    let name = target.name
    let file = target
    console.log('in setUploadFileLink', name, file)
    let lungh = 0
    if ('file' in form) {
      lungh = form['file'].length
    }
    //formData.append('file[file_' + lungh + ']', file.files[0])
    //formData.append('file_attach[file_' + lungh + ']', file.files[0])
    //refreshForm({name: 'file_attach[file_' + lungh + ']', value: file.files[0]})    
    refreshForm({name: name, value: file.files[0]})    
    console.log('in form:', form)
  }

  const setScenario = () => {
    getScenario(idScenario)
      .then(setScenarioData)
      .then(data => {
        setScenarioImmagine(scenarioData.additionalInfo.immagine ?? [])
      })

  }
  const eliminaImmagine = (immagine) => {
    fetch(`${getBaseUrl()}/deletefileinfoimg/${immagine}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders()
      },
      //body: JSON.stringify({ form }),

    })
      .then(response => response.json())
      .then(data => {
        setScenario()

        setIsSaved(true)


      })
      .catch(error => {
        console.error(error)
      })
  }

  const delSource = (idInfo) => {
    fetch(`${getBaseUrl()}/deleteSource/${idInfo}`, {
      method: 'GET',
      headers: {
        ...getDefaultHeaders()
      },
      //body: JSON.stringify({ form }),

    })
      .then(response => response.json())
      .then(data => {
        setScenario()

        setIsSaved(true)


      })
      .catch(error => {
        console.error(error)
      })
  }


  const ImgPreview = (imm) => {
    if (imm) {

      return <Row><Col><img className="m-auto img-card w-25" src={`${getBaseUrl()}/fileimg/${imm}`} alt="" /></Col><Col><Button onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) eliminaImmagine(imm) }}>Elimina</Button></Col></Row>
    } else {
      return false
    }
  }

  const DeleteSource = ({ idSource }) => {
    if (idSource) {
      return <Col><FontAwesomeIcon icon={faTrash} className="bluicon ml-2 float-right" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) delSource(idSource) }} /></Col>
    } else {
      return false
    }
  }



  const submitSecondStep = event => {
    event.preventDefault()
    setIsSaved(false)

    let link = []
    let file = []
    console.log('SUBMIT:', form)
    Object.keys(form).map(function (i) {
      console.log('key:', i)
      //if (!form[i].immagine && i != 'link_attach' && i != 'files') {
      if (i != 'link_attach' && i != 'files') {
        console.log(i,form[i])
        if (i.indexOf('link[') === 0) {     
          console.log(form[i])     
          if (form[i] !== '') {
            link.push({name: i, value: form[i]})
          }
        } else if (i.indexOf('file_attach[') === 0) {
          file.push({name: i, value: form[i]})
          formData.append(i, form[i])
        } else {
          formData.append(i, form[i])
        }        
      }        
    })
    
    if (link.length > 0 && link.length < 2) {
      alert('Errore, trovato un campo link non valorizzato!')
      return
    } 
    console.log('file', file)
    if (file.length > 0 && file.length < 1) {
      alert('Errore, trovato un campo file non valorizzato!')
      return
    }

    for(const x of link) {
      //console.log(x['name'])
      formData.append(x['name'], x['value'])
    }
    
    console.log(form)
    //return
    fetch(`${getBaseUrl()}/setupscenario_step2/${idScenario}`, {
      method: 'POST',
      headers: {
        //...getDefaultHeaders()
        ...getUploadHeaders()
      },
      body: formData
      //body: JSON.stringify({ form }),

    })
      .then(response => response.json())
      .then(text => {
        setScenario()
        setSbShowSuccess(true);
        setIsSaved(true)
        refreshForm({name: 'link[description]', value: ''})
        refreshForm({name: 'link[link]', value: ''})
        refreshForm({name: 'file_attach[description]', value: ''})
        refreshForm({name: 'file_attach[file]', value: ''})
        inputRef.current.value = null

      })
      .catch(error => {
        setError(true)
        setSbShowError(true);
        // It is text, do you text handling here

      });
  }




  const refreshForm = ({ name, value }) => {
    const newArr = form;
    // const newData={name:value}

    //    if (typeof newArr[name] !== 'undefined') {

    newArr[name] = value

    setForm({ ...newArr })

    ///  console.log(newArr)
  }

  const refreshFormImm = ({ name, value }) => {
    const newArr = form;
    // const newData={name:value}

    //    if (typeof newArr[name] !== 'undefined') {

    newArr['immagine_text'][name] = value
    newArr['immagine_text[' + name + ']'] = value

    setForm({ ...newArr })

  }

  useEffect(() => {

    if (scenarioData.scenario && scenarioData.scenario.id) {
      console.log('form', scenarioData.additionalInfo)
      setForm(scenarioData.additionalInfo)
      //setIdScenario(scenarioData.scenario.id)
      if (scenarioData.additionalInfo.immagine) {
        setScenarioImmagine(scenarioData.additionalInfo.immagine ?? [])
        //        setScenarioImmagineText(scenarioData.additionalInfo.immagine_text??[])
        //console.log(form)

      }
    }

  }, [scenarioData, formData])

  //(`idscenario`, `topic`='purpose', `lang`=1, `type`='text', `name`='', `ref`='', `text`=value, `display`=null)


  if (!scenarioData) return null


  return (
<>
    {sbShowSuccess 
      ? ( <StatusBar status="success" timeout={true} /> )
      : ( sbShowError ? <StatusBar status="error" timeout={false}/> : null )
    }
    
    <Form onSubmit={submitSecondStep} id="form-framework">

      <div className="simple-box" style={{marginTop : '40px'}}>
        <Row>

          <Col lg={12} className="form-login">
           {/*  {error && <Form.Text className="text-muted">
              <Alert variant="danger" className="mt-3">

                <h5>There was an error when saving information!</h5>
              </Alert>
            </Form.Text>}
            {isSaved && <Form.Text className="text-muted">

              <Alert variant="success" className="mt-3">
                <h5>  Data saved succesfully!</h5>
              </Alert>
            </Form.Text>} */}

            <h5 className="title-simple-box margin-title-box"><Trans code="A0172"/></h5>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="purpose">
                <Form.Label><Trans code="A0086" /></Form.Label>
                <Form.Control
                  as="textarea"
                  name="purpose"
                  placeholder={propTrans({prop: 'A0537'})}
                  value={form.purpose}
                  style={{ height: '100px' }}
                  onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="context">
                <Form.Label><Trans code="A0350" /></Form.Label>
                <Form.Control
                  as="textarea"
                  name="context"
                  placeholder={propTrans({prop: 'A0538'})}
                  value={form['context']}
                  style={{ height: '100px' }}
                  onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="shock">
                <Form.Label><Trans code="A0351" /></Form.Label>
                <Form.Control
                  as="textarea"
                  name="shock"
                  placeholder={propTrans({prop: 'A0539'})}
                  value={form['shock']}
                  style={{ height: '100px' }}
                  onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="institutional">
                <Form.Label><Trans code="A0167" /></Form.Label>
                <Form.Control
                  as="textarea"
                  name="institutional"
                  placeholder={propTrans({prop: 'A0540'})}
                  value={form['institutional']}
                  style={{ height: '100px' }}
                  onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                />
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="model">
                <Form.Label><Trans code="A0097" /></Form.Label>
                <Form.Control
                  as="textarea"
                  name="model"
                  placeholder={propTrans({prop: "A0535"})}
                  value={form['model']}
                  style={{ height: '100px' }}
                  onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="modelass">
                <Form.Label><Trans code="A0349" /></Form.Label>
                <Form.Control
                  as="textarea"
                  name="modelass"
                  placeholder={propTrans({prop: "A0535"})}
                  value={form['modelass']}
                  style={{ height: '100px' }}
                  onChange={({ target: { name, value } }) => refreshForm({ name, value })}
                />
              </Form.Group>
            </Row>
            {/*  <Row className="mb-3">
                    <Form.Group as={Col} controlId="image1">
                      {(scenarioImmagine.immagine && scenarioImmagine.immagine[1]) && ImgPreview(scenarioImmagine.immagine[1])}

                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[immagine_1]" />
                      </Form.Group>
                    <Form.Group as={Col} controlId="image2">
                      {(scenarioImmagine.immagine && scenarioImmagine.immagine[2]) && ImgPreview(scenarioImmagine.immagine[2])}

                        <Form.Control type="file"  onChange={(e) => setUploadFile(e.target)} placeholder="" name="immagine[immagine_2]" />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                  <Form.Group as={Col} controlId="immagine_text['immagine_1']">
                        <Form.Control
                          as="textarea"
                          name="immagine_1"
                          placeholder={propTrans({prop: "A0345"})}
                          value={form.immagine_text?form.immagine_text.immagine_1:''}

                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="immagine_text[immagine_2]">
                        <Form.Control
                          as="textarea"
                          name="immagine_2"
                          value={form.immagine_text?form.immagine_text.immagine_2:''}
                          placeholder={propTrans({prop: "A0345"})}
                          style={{ height: '100px' }}
                          onChange={({target: { name ,value}}) => refreshFormImm({name,value})}
                      />
                    </Form.Group>
                  </Row> */}
          </Col>
        </Row>
      </div>

      <div className="simple-box" style={{marginTop : '40px'}}>
        <Row>
          <Col lg={12} className="form-login">
            <h5 className="title-simple-box margin-title-box"><Trans code="C00466" /></h5>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="link">
              <Form.Label   ><Trans code="A0345" /></Form.Label>
                <Form.Control type="text" placeholder={propTrans({prop: "A0345"})} name={`link[description_${('link_attach' in form) ? form['link_attach'].length : 0}]`} value={form['link[description]']} onChange={({ target: { name, value } }) => refreshForm({ name, value })} />
               
              </Form.Group>
              <Form.Group as={Col} controlId="link">
              <Form.Label >Http://</Form.Label>
                <Form.Control type="text" placeholder="www.example.com" name={`link[link_${('link_attach' in form) ? form['link_attach'].length : 0}]`} value={form['link[link]']} onChange={({ target: { name, value } }) => refreshForm({ name, value })} />
               
              </Form.Group>

            </Row>
            <Row className="mb-3">
                <ListGroup className="col-4">
                  {form['link_attach'] && form['link_attach'].map((linkElement, index) => {
                    return (<ListGroup.Item key={index} className="p-3 ml-3"><DeleteSource idSource={linkElement.id} className="bluicon m-5 float-left" /><a href={linkElement.ref.match(/https?/gm) ? linkElement.ref : `//${linkElement.ref}`} target="_blank">{linkElement.description}</a></ListGroup.Item>)
                  })}
                </ListGroup>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="simple-box" style={{marginTop : '40px'}}>
        <Row>
          <Col lg={12} className="form-login">
            <h5 className="title-simple-box margin-title-box"><Trans code="A0465" /></h5>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="sources">
              <Form.Label  ><Trans code="A0345" /></Form.Label>
                <Form.Control type="text" placeholder={propTrans({prop: "A0345"})} name={`file_attach[description_${('files' in form) ? form['files'].length : 0}]`} value={form['file_attach[description]']} onChange={({ target: { name, value } }) => refreshForm({ name, value })} />
                 
              </Form.Group>
              <Form.Group as={Col} controlId="sources">
              <Form.Label >File</Form.Label>
                <Form.Control ref={inputRef} type="file" onChange={(e) => setUploadFileLink(e.target)} placeholder="" name={`file_attach[file_${('files' in form) ? form['files'].length : 0}]`} />
  
              </Form.Group>

            </Row>
            <Row className="mb-3">
            <ListGroup className="col-4">

            {form['files'] && form['files'].map((fileElement, index) => {
              return (<ListGroup.Item key={index} className="p-3 ml-3"><DeleteSource idSource={fileElement.id} className="bluicon m-5 float-left" /><a href={getBaseUrl()+"/fileimg/"+fileElement.ref} target="_blank">{fileElement.description}</a>           
              </ListGroup.Item>)
            })}
            </ListGroup>
            </Row>
            </Col>
            </Row>
            </div>





      {/*<Row>
            <Col>
              <div class="simple-box">
                  <Button  variant="info" type="submit">
                    Save All
                  </Button>
                  {error && <Form.Text className="text-muted">
                    <Alert  variant="danger" className="mt-3">

                    <h5>There was an error when saving information!</h5>
                    </Alert>
                  </Form.Text>}
                  {isSaved && <Form.Text className="text-muted">

                    <Alert  variant="success" className="mt-3">
                    <h5>  Data saved succesfully!</h5>
                    </Alert>
                    </Form.Text>}
                    </div>
                    </Col>
</Row>                    */}

      <Button form="form-framework" type="submit" className="submit-button sticky-footer-button"><Trans code="F00046"/></Button>
    </Form>

    </>
  );

}

export default StepDescription;
