import React from 'react';
import TaxesImpact from '@components/scenariodashboard/taxes/TaxesImpact';
import TaxesDonutCard from '@components/scenariodashboard/taxes/TaxesDonutCard';
import TaxesTypologiesTable from '@components/scenariodashboard/taxes/TaxesTypologiesTable';
import { Ico45 } from '@icons/new';
import { filter } from 'd3';
const Taxes = ({ taxes, filterForRegion }) => {
  return (
    <>
      <TaxesImpact taxes={taxes} filterForRegion={filterForRegion} />
      <div className='row'>
        <TaxesDonutCard
          taxes={taxes.taxesByType}
          filterForRegion={filterForRegion}
          chartId={'chart-by-empMacrosector'}
          langTitleCode={'A0202'}
          icon={<Ico45 className='logo-card ico-box-blue' />}
        />
        <TaxesTypologiesTable taxes={taxes.taxesByType} filterForRegion={filterForRegion} />
      </div>
    </>
  );
};

export default Taxes;
