import React, { useState, useEffect } from 'react';
import { Trans } from '@lib/i18n';

import { Link, useParams, useLocation } from 'react-router-dom';
import AnimatedDonutChartDash from '@components/scenariodashboard/AnimatedDonutChartDash';

const DashboardImpact = ({
  filterForRegion,
  syntheticResults: {
    investmentByType,
    gdpByFactor,
    employmentBySector,
    incomesByType,
    taxesByType,
  },
  typologyCode,
  // navigateCallback = null,
  regions,
  isInExecution,
}) => {
  const [investmentData, setInvestmentData] = useState([]);
  const [gdpData, setGdpData] = useState([]);
  const [employmentData, setEmploymentData] = useState([]);
  const [incomesData, setIncomesData] = useState([]);
  const [taxesData, setTaxesData] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [currentLang, setCurrentLang] = useState('');
  console.log('investmentData', investmentData, regions);
  //console.log('filterForRegion comp:', filterForRegion);
  const { idScenario = null, idVariante = null } = useParams();

  function filterAndReplaceProperty(firstArray, secondArray) {
    const filterByPropertyName = (array, propertyName) =>
      array.filter((obj) => obj.propertyName === propertyName && obj.region !== '');

    const getRegionCodes = (array) => array.map((obj) => obj.region);

    const replacePropertyName = (firstArray, secondArray) =>
      firstArray.map((obj) => {
        const matchingObject = secondArray.find((secObj) => secObj.code === obj.region);
        if (matchingObject) {
          return { ...obj, propertyName: matchingObject.name };
        }
        return obj;
      });

    const filteredFirstArray = filterByPropertyName(firstArray, 'CAPEX');
    const regionCodes = getRegionCodes(filteredFirstArray);
    const filteredSecondArray = secondArray.filter((obj) => regionCodes.includes(obj.code));
    const replacedArray = replacePropertyName(filteredFirstArray, filteredSecondArray);

    return replacedArray;
  }

  // const navigate = (target) => () => {
  //   if (navigateCallback) navigateCallback(target);
  // };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Perform localStorage action
      setTranslations(JSON.parse(localStorage.getItem('translations')));

      let tempCurrentLang = localStorage.getItem('externalyticsLanguage');
      setCurrentLang(tempCurrentLang ? tempCurrentLang.toUpperCase() : 'IT');
    }
  }, []);

  useEffect(() => {
    let filter = filterForRegion === 'total' ? '' : filterForRegion;
    // setInvestmentData(investmentByType.filter(el => el.region === filter)); //to filter
    setInvestmentData(filterAndReplaceProperty(investmentByType, regions)); //to filter
    setGdpData(gdpByFactor.filter((el) => el.region === filter));
    setEmploymentData(employmentBySector.filter((el) => el.region === filter));
    setIncomesData(incomesByType.filter((el) => el.region === filter));
    setTaxesData(taxesByType.filter((el) => el.region === filter));
  }, [
    investmentByType,
    gdpByFactor,
    employmentBySector,
    incomesByType,
    taxesByType,
    filterForRegion,
    regions,
  ]);
  console.log('employmentBySector', employmentBySector);
  return (
    <div className={`${!isInExecution && 'simple-box'} impact`}>
      {!isInExecution && (
        <div className='row'>
          <div className='col'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0463' />
            </h5>
          </div>
        </div>
      )}
      <div
        className={`d-flex flex-wrap ${
          !isInExecution ? 'justify-content-center' : 'justify-content-around'
        }`}>
        <div className={typologyCode === 'POL' ? 'd-none' : 'chartBox impactChart'}>
          <AnimatedDonutChartDash
            chartId='investment'
            data={investmentData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <Link
              to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/investment`}>
              <h5 className='ico-absolute'>
                <Trans code='A0047' />
              </h5>
            </Link>
          </div>
        </div>
        <div className='chartBox impactChart'>
          <AnimatedDonutChartDash
            chartId='gdp'
            data={gdpData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <Link to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/gdp`}>
              <h5 className='ico-absolute'>
                <Trans code='A0263' />
              </h5>
            </Link>
          </div>
        </div>
        <div className='chartBox impactChart'>
          <AnimatedDonutChartDash
            chartId='employment'
            data={employmentData}
            codeLangTag='A0054'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />

          <div className='donut-title'>
            <Link
              to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/jobs`}>
              <h5 className='ico-absolute'>
                <Trans code='A0328' />
              </h5>
            </Link>
          </div>
        </div>
        {/* <div className='chartBox impactChart d-none'>
          <AnimatedDonutChartDash
                  chartId="production"
                  data={productionData} //chart.production
                  codeLangTag='A0054'
                  translations={translations}
                  currentLang={currentLang}
               />
          <div className='donut-title'>
            <Button variant='link' onClick={navigate('production')}>
              <div className='va-desc'>
                <Trans code='A0053' />
              </div>
              <h5 className='ico-absolute'>
                <Trans code='F0078' />
              </h5>
            </Button>
          </div>
        </div> */}
        <div className='chartBox impactChart'>
          <AnimatedDonutChartDash
            chartId='incomes'
            data={incomesData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />

          <div className='donut-title'>
            <Link
              to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/incomes`}>
              <h5 className='ico-absolute'>
                <Trans code='A0327' />
              </h5>
            </Link>
          </div>
        </div>
        <div className='chartBox impactChart'>
          <AnimatedDonutChartDash
            chartId='taxes'
            data={taxesData}
            codeLangTag='A0053'
            translations={translations}
            currentLang={currentLang}
            labelOnHover={true}
          />
          <div className='donut-title'>
            <Link
              to={`/scenario/${idScenario}/dashboard/variant/${idVariante}/impactanalysis/taxes`}>
              <h5 className='ico-absolute'>
                <Trans code='A0326' />
              </h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardImpact;
