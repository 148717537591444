import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Button, Tooltip, Form } from 'react-bootstrap';
import { Trans, propTrans } from '@lib/i18n/index';
import { date, fetchData } from '@lib/helpers/index';
import { IcoEdit, IcoCanc, IcoSave, IcoEditLine, IcoClose } from 'icone/index';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteScenarioVariant,
  getScenarioVariants,
  postScenarioVariant,
  putScenarioVariant,
} from '@lib/api/index';
import TruncatedValue from 'components/RenderTruncatedValue';
import CustomModal from 'components/CustomModal/CustomModal';
const VariantItem = ({
  item,
  index,
  selectedVariant,
  setSelectedVariant,
  items,
  setItems,
  isOneEditing,
  setIsOneEditing,
  setError,
  setIsLoading,
  setVariantRefresh,
  setIsOpen,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [inputValue, setInputValue] = useState(item.name);
  const [deleteContext, setDeleteContext] = useState(false);
  const history = useHistory();
  const { idScenario = null } = useParams();
  useEffect(() => {
    if (item) {
      setNewItem(item);
    }
  }, [item]);

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0812' />
    </Tooltip>
  );
  const saveTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0814' />
    </Tooltip>
  );
  const selectTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0815' />
    </Tooltip>
  );
  const closeTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0816' />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='F00056' />
    </Tooltip>
  );

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveEdit = async () => {
    setError(null);

    const isUpdate = Boolean(item.id);

    try {
      let response;
      if (isUpdate) {
        response = await putScenarioVariant(idScenario, item.id, {
          ...item,
          name: inputValue,
        });
        if (response.status) {
          setVariantRefresh(true);
          setIsLoading(false);
          setIsEditing(false);
        } else {
          throw new Error(response);
        }
      } else {
        setIsLoading(true);
        response = await postScenarioVariant(idScenario, { name: inputValue });

        if (response.status) {
          const updatedResponse = await fetchData(
            getScenarioVariants,
            null,
            null,
            [idScenario],
            null,
            setError
          );

          setItems(updatedResponse);
          setSelectedVariant(updatedResponse[updatedResponse.length - 1]);
          setIsOpen((prevState) => !prevState);
          setIsLoading(false);
          history.push(
            `/scenariowizard/${idScenario}/${
              updatedResponse[updatedResponse.length - 1]?.id
            }/?step=build&sub=general`
          );
        } else {
          throw new Error(response);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }

    // setIsEditing(false);
  };

  const handleCloseEdit = () => {
    setInputValue(item.name);
    setIsEditing(false);
  };

  const handleDelete = async () => {
    setError(null);
    const isUpdate = Boolean(newItem.id);
    try {
      let response;
      if (isUpdate) {
        setIsLoading(true);
        response = await deleteScenarioVariant(idScenario, newItem.id);
        if (response.status) {
          setVariantRefresh(true);
          // setItems(updatedResponse.data);
          // setIsLoading(false);
        } else {
          throw new Error(response);
        }
      } else {
        const newArray = [...items];
        newArray.pop();

        setItems(newArray);
        setIsOneEditing(false);
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const toggleDeleteMode = () => {
    setDeleteContext((prevState) => !prevState);
  };

  useEffect(() => {
    if (newItem.name === '') {
      setIsEditing(true);
    }
  }, [newItem]);

  useEffect(() => {
    setIsOneEditing(isEditing);
  }, [isEditing]);

  return (
    <div
      key={`${newItem.name}${newItem.createdAt}${index}`}
      id='variant-item'
      className='d-flex justify-content-between align-items-center p-2'>
      <div
        className='d-flex justify-content-between align-items-center flex-grow-1 variant-item-wrapper'
        // onClick={() => {
        //   setSelectedVariant(item);
        //   setIsOpen((prevState) => !prevState);
        //   history.push(`/scenariowizard/${idScenario}/${newItem.id}/?step=build&sub=general`);
        //   // history.push(`/scenariowizard/${idScenario}/${newItem.id}/?${urlParams.toString()}`);
        // }}
      >
        <div>
          {isEditing ? (
            <div className='d-flex justfy-content-center align-items-center'>
              <Form.Group controlId='name' className='m-0 mr-2'>
                <Form.Control
                  type='text'
                  name='name'
                  value={inputValue ?? ''}
                  onChange={onChange}
                  onClick={(e) => e.stopPropagation()}
                  className='variant-name-form-control'
                />
              </Form.Group>
              <OverlayTrigger placement='top' overlay={saveTooltip}>
                <Button variant='link' className='mr-2' onClick={handleSaveEdit}>
                  <IcoSave className='second-color ico-small edit' />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement='top' overlay={closeTooltip}>
                <Button variant='link' className='mr-2' onClick={handleCloseEdit}>
                  <IcoClose className='second-color ico-small edit' />
                </Button>
              </OverlayTrigger>
            </div>
          ) : (
            <div className='variant-name-wrapper d-flex justfy-content-center align-items-center'>
              {newItem.name && (
                <TruncatedValue
                  value={newItem?.name}
                  htmlTag='p'
                  classNames='p-0 m-0 mr-2'
                  style={{ minWidth: '234px' }}
                />
              )}
              {/* <p className='p-0 m-0 mr-2'>{newItem.name}</p> */}
              <OverlayTrigger placement='top' overlay={editTooltip}>
                <Button
                  disabled={isOneEditing}
                  variant='link'
                  className='mr-2'
                  onClick={() => setIsEditing(true)}>
                  <IcoEditLine className='second-color ico-small edit-line' />
                </Button>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className='px-3 py-1 variant-date-wrapper'>
          <p className='p-0 m-0'>{date(newItem.createdAt)}</p>
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <div className='ml-3'>
          <OverlayTrigger placement='top' overlay={selectTooltip}>
            <Button
              disabled={isOneEditing || selectedVariant?.id === item.id}
              variant='link'
              className='mr-2'
              onClick={() => {
                setSelectedVariant(item);
                setIsOpen((prevState) => !prevState);
                history.push(`/scenariowizard/${idScenario}/${newItem.id}/?step=build&sub=general`);
                // history.push(`/scenariowizard/${idScenario}/${newItem.id}/?${urlParams.toString()}`);
              }}>
              <IcoEdit className='second-color ico-small edit' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement='top' overlay={deleteTooltip}>
            <Button
              disabled={isOneEditing || isEditing || selectedVariant?.id === item.id}
              variant='link'
              className='mr-2'
              onClick={() => setDeleteContext(true)}>
              {/* onClick={() => handleDelete(item)}> */}
              <IcoCanc className='second-color ico-small cancel' />
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <CustomModal
        show={deleteContext}
        handleClose={toggleDeleteMode}
        title={propTrans({ prop: 'A0515' })}
        content={
          <div className='my-3'>
            <span>
              <Trans code='A0813' /> <span className='font-weight-bold'>{item.name}</span>
            </span>
          </div>
        }
        modalSize='md'
        buttonText={propTrans({ prop: 'A0529' })}
        actionButton={true}
        actionButtonVariant='danger'
        actionButtonText={propTrans({ prop: 'F00056' })}
        handleAction={handleDelete}
      />
    </div>
  );
};

export default VariantItem;
