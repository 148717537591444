import React, { useState, useEffect } from 'react';
import { Table, Button, FormControl, ButtonToolbar, InputGroup, Row, Col } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { getOEScenarios, getUsers, getOrganizations, getMacroimpactScenari } from '@lib/api';
import { date } from '@lib/helpers';
import { Trans, propTrans } from '@lib/i18n';
import { Link } from 'react-router-dom';
import { Lens, IcoPlus } from '@icons';
import { Ico9 } from '@icons/new';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faStop, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { TooltipInfoIcon } from '@icons';
import Tooltip from 'react-bootstrap/Tooltip';
import ActionBtnRendererNewScenarioTable from '@components/adminManagement/newScenariosManagement/ActionBtnRendererNewScenarioTable';
import PaginationItems from 'components/adminManagement/newScenariosManagement/paginationItems';
import SortArrows from 'components/adminManagement/newScenariosManagement/sortingArrows';
import MacroEconomicImpact from 'components/adminManagement/newScenariosManagement/macroEconomicImpact';
import StatusBar from 'components/wizard/StatusBar';

const NewScenarioImpact = () => {
  const [newScenarios, setNewScenarios] = useState([]);
  const [macroImpact, setMacroImpact] = useState({});
  const [filteredScenarios, setFilteredScenarios] = useState([]);
  // const [users, setUsers] = useState([]);
  // const [organizations, setOrganizations] = useState([]);
  const [search, setSearch] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [ordering, setOrdering] = useState({ key: '', direction: 'ASC' });
  const [sbShow, setSbShow] = useState(<></>);
  const [sbCloneDownloadShow, setSbCloneDownloadShow] = useState(<></>);
  const numberPagination = [
    { value: 20, label: '20' },
    { value: 10, label: '10' },
    { value: 50, label: '50' },
  ];
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    if (!user.role) return;
    setIsRegisteredUser(user.role === 5);
  }, []);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  // async function loadData() {
  //   try {
  //     const retrievedMacroImpacts = await getMacroimpactScenari();
  //     const retrievedNewScenarios = await getOEScenarios();
  //     console.log(retrievedNewScenarios);
  //     const retrievedUsers = await getUsers();
  //     const retrievedOrganizations = await getOrganizations();
  //     setMacroImpact(retrievedMacroImpacts.data.macroImpact);
  //     const scenariWithUser = retrievedNewScenarios.data.map((scen) => {
  //       const user = retrievedUsers.find((el) => +el.idUser === scen.userId);
  //       const organization = retrievedOrganizations.find(
  //         (el) => +el.idOrganization === scen.userOrganizationId
  //       );
  //       const transformedString =
  //         scen.status.charAt(0).toUpperCase() + scen.status.slice(1).toLowerCase();
  //       return {
  //         ...scen,
  //         id: Number(scen.id),
  //         userId: `${user.nome} ${user.cognome}`,
  //         userIdNumber: +user.idUser,
  //         userOrganizationId: `${organization.organization}`,
  //         userOrganizationIdNumber: +organization.idOrganization,
  //         status: transformedString,
  //       };
  //     });

  //     setFilteredScenarios(scenariWithUser);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   loadData();
  // }, []);

  // useEffect(() => {
  //   if (refresh) {
  //     loadData();
  //     setRefresh(false);
  //   }
  // }, [refresh]);

  const fetchScenarios = async (abortController) => {
    try {
      const [retrievedMacroImpacts, retrievedNewScenarios, retrievedUsers, retrievedOrganizations] =
        await Promise.all([
          getMacroimpactScenari(),
          getOEScenarios(),
          getUsers(),
          getOrganizations(),
        ]);

      if (abortController && abortController.signal.aborted) {
        return;
      }

      setMacroImpact(retrievedMacroImpacts.data.macroImpact);

      const scenariWithUser = retrievedNewScenarios.data.map((scen) => {
        // console.log('RETUSER', retrievedUsers);
        const user = retrievedUsers?.data?.find((el) => el.id === scen.userId);
        const organization = retrievedOrganizations?.data.find(
          (el) => el.id === scen.userOrganizationId
        );
        // const transformedString =
        //   scen.status.charAt(0).toUpperCase() + scen.status.slice(1).toLowerCase();
        return {
          ...scen,
          id: Number(scen?.id),
          // userId: `${user?.nome} ${user?.cognome}`,
          userId: `${user?.firstName} ${user?.lastName}`,
          // userIdNumber: +user?.idUser,
          userIdNumber: user?.id,
          userOrganizationId: organization?.name,
          userOrganizationIdNumber: organization?.id,
          // status: transformedString,
        };
      });

      setFilteredScenarios(scenariWithUser);
    } catch (error) {
      handleStatusBar('error', error.message, false);
    }
  };

  useEffect(() => {
    const myAbortController = new AbortController();
    fetchScenarios(myAbortController);

    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      fetchScenarios();
    }
  }, [refresh]);

  // useEffect(() => {
  //   getMacroimpactScenari().then((retrievedMacroImpacts) =>
  //     setMacroImpact(retrievedMacroImpacts.data.macroImpact)
  //   );
  // }, []);

  useEffect(() => {
    if (filteredScenarios.length !== 0) {
      // setNewScenarios(filteredScenarios);
      if (ordering.key === '') {
        return sortScenari('updatedAt', 'ASC', filteredScenarios);
      } else {
        return sortScenari(ordering.key, ordering.direction, filteredScenarios);
      }
    } else if (filteredScenarios.length === 0) {
      setNewScenarios([]);
    }
  }, [filteredScenarios, recordsPerPage]);

  // useEffect(() => {
  //   if (filteredScenarios.length > 0) {
  //     filterAndPaginate(filteredScenarios);
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [recordsPerPage, filteredScenarios]);
  // console.log(macroImpact.length);

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
    filterAndPaginate(filteredScenarios, value);
  };

  const filterAndPaginate = (scenarios, value = search) => {
    setPage(1);
    const filteredScenarios = scenarios.filter(({ name = '' }) => {
      if (!value) {
        return true;
      }
      return name.toLowerCase().includes(value.toLowerCase());
    });

    const pageCount = Math.ceil(filteredScenarios?.length / recordsPerPage);
    setPages(pageCount);
    setNewScenarios(filteredScenarios);
  };

  const sortScenari = (attribute, direction, filteredScenarios) => {
    const ord = direction === 'ASC' ? 'ASC' : 'DESC';
    setOrdering({ key: attribute, direction: ord });

    const orderedScenari = filteredScenarios.sort((a, b) => {
      if (attribute === 'updatedAt') {
        let aFixed = a[attribute] ?? a.createdAt;
        let bFixed = b[attribute] ?? b.createdAt;

        return direction === 'ASC'
          ? Date.parse(bFixed) - Date.parse(aFixed)
          : Date.parse(aFixed) - Date.parse(bFixed);
      } else {
        return direction === 'ASC'
          ? a[attribute].localeCompare(b[attribute])
          : b[attribute].localeCompare(a[attribute]);
      }
    });

    setNewScenarios([...orderedScenari]);
    filterAndPaginate(orderedScenari);
  };

  const renderTooltip = (props) => {
    let message = '';

    //Sometimes, props.popper.state is undefined.
    //It runs this function enough times that state gets a value
    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };

  const investimentoTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code='A0723' />
    </Tooltip>
  );
  const singleTooltip = (props) => <Tooltip {...props}>Single-Region</Tooltip>;
  const multiTooltip = (props) => <Tooltip {...props}>Multiregional</Tooltip>;

  /*if (
    parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 3 &&
    parseInt(JSON.parse(localStorage.getItem('user'))['idOrganization']) !== 1
  ) {
    return (
      <div id='nuoviscenariimpact' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }*/

  return (
    <div id='nuoviscenariimpact' className='megaContent'>
      <div className='container-fluid'>
        <div className='simple-box impactMacroNew mt-0' id='newdashboard-scenari'>
          <Row>
            <Col>
              <h5 className='title-simple-box margin-title-box'>
                <Trans code='A0463' />
              </h5>
            </Col>
          </Row>
          {Object.keys(macroImpact).length > 0 && <MacroEconomicImpact macroImpact={macroImpact} />}
        </div>

        <div className='simple-box scenaritab'>
          <div className='row d-flex align-items-center mb-2'>
            <div className='col-4'>
              <h5 className='title-simple-box mb-0'>
                <Trans code='A0563' />
              </h5>
            </div>
            <div className='col-8 text-right'>
              <ButtonToolbar className='justify-content-end align-items-center'>
                {!isRegisteredUser && (
                  <Button variant='info' href='/scenariowizard' className='ml-2'>
                    <div className='row mx-0 align-items-center' style={{ padding: '2px 0' }}>
                      <IcoPlus className='light-color ico-small plus mr-1' />
                      <span className='d-none d-md-inline'>
                        &nbsp;
                        <Trans code='A0037' />
                      </span>
                    </div>
                  </Button>
                )}
                <InputGroup
                  style={{ paddingBottom: '2.5px', paddingTop: '2.5px' }}
                  className='search-table align-items-center bg-white'>
                  <FormControl
                    type='text'
                    id='filter-text-box'
                    placeholder='Search...'
                    className='d-none d-md-block'
                    onInput={(e) => handleSearchChange(e)}
                  />
                  <Lens />
                </InputGroup>
              </ButtonToolbar>
            </div>
          </div>
          <Row>
            <Col className='col-12'>
              {sbCloneDownloadShow}
              {sbShow}
              <Table className='new-scenarios-table' responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th className='w-25'>
                      <div className='d-flex align-items-center'>
                        <Trans code='A0348' />
                        <SortArrows
                          activeAttribute='name'
                          sortScenari={sortScenari}
                          ordering={ordering}
                          filteredScenarios={filteredScenarios}
                        />
                      </div>
                    </th>
                    <th className='d-none d-lg-table-cell text-center'>
                      <Trans code='A0770' />
                    </th>
                    <th className='d-none d-lg-table-cell text-center'>
                      <Trans code='A0201' />
                    </th>
                    {/* <th className='d-none d-lg-table-cell text-center'>Status</th> */}
                    <th className='d-none d-lg-table-cell text-center'>
                      {/* <Trans code='A0081' /> */}
                      <div className='d-flex align-items-center justify-content-center'>
                        <Trans code='A0081' />
                        <SortArrows
                          activeAttribute='userId'
                          sortScenari={sortScenari}
                          ordering={ordering}
                          filteredScenarios={filteredScenarios}
                        />
                      </div>
                    </th>
                    <th className='d-none d-md-table-cell text-center'>
                      {/* <Trans code='A0059' /> */}
                      <div className='d-flex align-items-center justify-content-center'>
                        <Trans code='A0059' />
                        <SortArrows
                          activeAttribute='userOrganizationId'
                          sortScenari={sortScenari}
                          ordering={ordering}
                          filteredScenarios={filteredScenarios}
                        />
                      </div>
                    </th>
                    <th className='d-md-table-cell text-center'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Trans code='A0071' />
                        <SortArrows
                          activeAttribute='updatedAt'
                          sortScenari={sortScenari}
                          ordering={ordering}
                          filteredScenarios={filteredScenarios}
                        />
                      </div>
                    </th>
                    <th className='d-table-cell d-md-none'>
                      <Trans code='A0059' />
                    </th>
                    {/* <th className="d-none d-lg-table-cell"><Trans code="A0075"/></th>*/}
                  </tr>
                </thead>
                <tbody>
                  {newScenarios
                    .slice(page * recordsPerPage - recordsPerPage, page * recordsPerPage)
                    .map((scenario) => {
                      return (
                        <tr key={scenario.id}>
                          <td>
                            {scenario.verified === 1 ? (
                              <img
                                src='../../../img/logosmall.png'
                                alt='Openeconomics'
                                width='20px'
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {/* {parseInt(scenario.publish) === 0 ? ( */}
                            {!scenario?.lastExecutedVariantId ||
                            scenario?.lastExecutedVariantId === null ? (
                              scenario.name
                            ) : (
                              <Link
                                className='scenario-link'
                                to={{
                                  // pathname: `/scenario/${scenario.id}/dashboard`,
                                  pathname: `/scenario/${scenario.id}/dashboard/variant/${scenario.lastExecutedVariantId}`,
                                  state: { scenario },
                                }}>
                                {scenario.name.length > 30 ? (
                                  <>
                                    <OverlayTrigger
                                      placement='top'
                                      delay={{ show: 200, hide: 400 }}
                                      overlay={renderTooltip}
                                      popperConfig={{ testObj: scenario.name }}>
                                      <span> {scenario.name.substring(0, 35)}...</span>
                                    </OverlayTrigger>
                                  </>
                                ) : (
                                  scenario.name
                                )}
                              </Link>
                            )}
                          </td>
                          <td className='d-none d-lg-table-cell text-center'>
                            {scenario?.analyses.map((item) => item.type).join(', ')}
                          </td>
                          <td className='d-none d-lg-table-cell text-center'>
                            {/* {scenario.extension === 'SR' ? (
                              <OverlayTrigger placement='top' overlay={singleTooltip}>
                                <FontAwesomeIcon
                                  icon={faStop}
                                  style={{ color: '#898989' }}
                                  className='mr-3'
                                />
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger placement='top' overlay={multiTooltip}>
                                <FontAwesomeIcon
                                  className='mr-3'
                                  style={{ color: '#898989' }}
                                  icon={faThLarge}
                                />
                              </OverlayTrigger>
                            )} */}
                            {scenario.typologyCode === 'INV' ? (
                              <OverlayTrigger placement='top' overlay={investimentoTooltip}>
                                <FontAwesomeIcon icon={faDollarSign} style={{ color: '#898989' }} />
                              </OverlayTrigger>
                            ) : (
                              ''
                            )}
                          </td>
                          {/* <td className='d-none d-md-table-cell text-center'>{scenario.status}</td> */}
                          {/* <td className="d-none d-md-table-cell">{scenario.cod_nace_description}</td> */}
                          <td className='d-none d-lg-table-cell text-center'>
                            {/* {users
                            .filter((el) => +el.idUser === scenario.userId)
                            .map((user) => `${user.nome} ${user.cognome}`)} */}
                            {scenario.userId}
                          </td>
                          <td className='d-none d-md-table-cell text-center'>
                            {/* {organizations
                            .filter((el) => +el.idOrganization === scenario.userOrganizationId)
                            .map((organization) => `${organization.organization}`)} */}
                            {scenario.userOrganizationId}
                          </td>
                          <td className='text-center'>
                            {date(scenario.updatedAt || scenario.createdAt)}
                          </td>
                          <td className='d-none d-lg-table-cell text-center'>
                            <ActionBtnRendererNewScenarioTable
                              data={scenario}
                              setRefresh={setRefresh}
                              setSbCloneDownloadShow={setSbCloneDownloadShow}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <div className='d-flex align-items-center justify-content-between'>
            <PaginationItems page={page} pages={pages} maxVisiblePages={5} setPage={setPage} />
            <div>
              <Trans code='A0725' />
              :&nbsp;
              <select
                onChange={(e) => setRecordsPerPage(+e.target.value)}
                defaultValue={numberPagination[0].value}
                id='page-size'>
                {numberPagination.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewScenarioImpact;
