import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import {
  Button,
  FormControl,
  ButtonToolbar,
  InputGroup,
  Modal,
  Form,
  Col,
  Row,
  Tabs,
  Tab,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import Select from 'react-select';
import { Trans, propTrans } from '@lib/i18n/index';
import { IcoPlus, Lens, IcoMinusCircled } from '@icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressArrowsAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import {
  getOEDatasets,
  getOEGeoExts,
  getOEDatasetTypes,
  getOERegions,
  getOECurrencies,
  getOESecDisags,
  getOEGeoPrecisions,
  postOEDatasets,
  putOEDataset,
  downloadOEExcelDatasetItem,
  uploadOEExcelDatasetItem,
  getShadowPricesDownload,
} from '@lib/api/index';

import DownloadUploadItems from 'components/DownloadUploadView/DownloadUploadView';

import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import StatusBar from 'components/wizard/StatusBar';
import TreeView from 'components/TreeView/TreeView';
import WorldMapSVG from 'components/WorldMapSVG/WorldMapSVG';
import AssociatedScenarioTable from 'components/adminManagement/associatedScenario/associatedScenarioTable';
import ActionBtnRendererNewDataset from 'components/adminManagement/newDatasetManagement/newDatasetManagement';

const initialState = {
  name: '',
  description: '',
  typeCode: '',
  regionCode: '',
  geoExtCode: '',
  secDisagCode: '',
  precisionCode: '',
  year: new Date().getFullYear(),
  file: null,
  updatedAt: '',
  createdAt: '',
};

const Dataset = () => {
  const [newDataset, setNewDataset] = useState(initialState);
  const [datasets, setDatasets] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [optionsPagination, setOptionsPagination] = useState([]);
  const [sbShow, setSbShow] = useState(<></>);
  const [geoExtCodes, setGeoExtCodes] = useState([]);
  const [geoDatasetTypes, setGeoDatasetTypes] = useState([]);
  const [regionsDatasetTypes, setRegionsDatasetTypes] = useState([]);
  const [regionsForMap, setRegionsForMap] = useState([]);
  const [oeCurrencies, setOeCurrencies] = useState([]);
  const [oeSecDisags, setOeSecDisags] = useState([]);
  const [oeGeoPrecisions, setOeGeoPrecisions] = useState([]);
  const [selectedGeoExtCodes, setSelectedGeoExtCodes] = useState([]);
  const [selectedGeoDatasetTypes, setSelectedGeoDatasetTypes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedOeCurrencies, setSelectedOeCurrencies] = useState([]);
  const [selectedOeSecDisags, setSelectedOeSecDisags] = useState([]);
  const [selectedOeGeoPrecisions, setSelectedOeGeoPrecisions] = useState([]);
  const [showModalNew, setShowModalNew] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalFullscreen, setModalFullscreen] = useState(false);
  const [activeKey, setActiveKey] = useState('home');
  const [file, setFile] = useState(null);
  const [activateProfile, setActivateProfile] = useState(false);
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();

  // For maps
  const [checkedExistingDatasetRegions, setCheckedExistingDatasetRegions] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleCountrySelection = (checkedItems) => {
    const selected = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    setSelectedCountries(selected);
  };

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    resetFormState();
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const handleDownload = async (isShadow = false) => {
    handleStatusBar('elaborating', false, false); //Il terzo argomento se lasciato in true, fa scomparire la statusbar dopo 5s, con false, solo se c'è altro che la sostituisce.
    const downloadResponse = isShadow
      ? await getShadowPricesDownload(newDataset.id)
      : await downloadOEExcelDatasetItem(newDataset.id);

    if (downloadResponse.ok) {
      handleStatusBar('success', <Trans code='A0657' />, true);
      const blob = await downloadResponse.blob();
      const file = new File([blob], newDataset.id);
      var fileUrl = window.URL.createObjectURL(file);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = `${isShadow ? `Prezzi Ombra` : `Dataset Values`} - ${newDataset.geoExtCode} ${
        newDataset.typeCode
      } ${regionsDatasetTypes.find((el) => el.value === newDataset?.regionCode).label} ${
        newDataset.year
      }.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      //Se la get ha avuto successo
      setTimeout(() => {
        setSbShow(<></>);
      }, 5000);
    } else if (!downloadResponse.ok || downloadResponse.status !== 200) {
      handleStatusBar('error', `${downloadResponse.statusText}`, false);
      const res = await downloadResponse.json();
      if (res.message) handleStatusBar('error', `${res.message}`, false);
    }
  };

  const handleFile = (file) => {
    setFile(file);
  };
  const handleUpload = async () => {
    // e.preventDefault();
    if (!file) return;
    handleStatusBar('elaborating', false, false);
    const formData = new FormData();

    formData.append('file', file, file.name);

    const uploadResult = await uploadOEExcelDatasetItem(newDataset.id, formData);

    if (uploadResult.status === true) {
      handleStatusBar('success', <Trans code='A0654' />, true);
      setFile(null);
      setTimeout(() => {
        setSbShow(<></>);
        // setRefresh(true);
      }, 5000);
    } else if (uploadResult.message || uploadResult.error) {
      handleStatusBar('error', uploadResult.message ?? propTrans({ prop: 'A0652' }), false);
    }
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const resetFormState = useCallback(() => {
    setSelectedGeoExtCodes([]);
    setSelectedGeoDatasetTypes([]);
    setSelectedRegion([]);
    setSelectedOeCurrencies([]);
    setSelectedOeSecDisags([]);
    setSelectedOeGeoPrecisions([]);
    setActiveKey('home');
    setActivateProfile(false);
    setNewDataset(initialState);
    setRefresh(true);
  }, []);

  // function createNestedArray(inputArray) {
  //   const result = [];

  //   inputArray.forEach((item) => {
  //     if (item.continent && !item.country && !item.region) {
  //       const continentObject = {
  //         ...item,
  //       };

  //       const countryChildren = inputArray.filter((subItem) => {
  //         return subItem.continent === item.continent && subItem.country && !subItem.region;
  //       });

  //       if (countryChildren.length > 0) {
  //         continentObject.children = countryChildren.map((countryItem) => {
  //           const countryObject = {
  //             ...countryItem,
  //           };

  //           const regionChildren = inputArray.filter((regionItem) => {
  //             return (
  //               regionItem.continent === item.continent &&
  //               regionItem.country === countryItem.country &&
  //               regionItem.region
  //             );
  //           });

  //           if (regionChildren.length > 0) {
  //             countryObject.children = regionChildren;
  //           }

  //           return countryObject;
  //         });
  //       }

  //       result.push(continentObject);
  //     }
  //   });

  //   return result;
  // }

  function createNestedArray(inputArray) {
    const result = [];

    const rootItem = inputArray.find((item) => item.code === 'WD');
    if (rootItem) {
      const rootObject = {
        ...rootItem,
        children: [],
      };

      inputArray.forEach((item) => {
        if (item.continent && item.continent !== 'WD' && !item.country && !item.region) {
          const continentObject = {
            ...item,
          };

          const countryChildren = inputArray.filter((subItem) => {
            return subItem.continent === item.continent && subItem.country && !subItem.region;
          });

          if (countryChildren.length > 0) {
            continentObject.children = countryChildren.map((countryItem) => {
              const countryObject = {
                ...countryItem,
              };

              const regionChildren = inputArray.filter((regionItem) => {
                return (
                  regionItem.continent === item.continent &&
                  regionItem.country === countryItem.country &&
                  regionItem.region
                );
              });

              if (regionChildren.length > 0) {
                countryObject.children = regionChildren;
              }

              return countryObject;
            });
          }

          rootObject.children.push(continentObject);
        }
      });
      result.push(rootObject);
    }

    return result;
  }

  useEffect(() => {
    if (refresh) {
      getOEDatasets().then((retrievedDatasets) => {
        setDatasets(retrievedDatasets);
      });

      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const myAbortController = new AbortController();
    const fetchData = async () => {
      const [
        geoExtResponse,
        datasetTypesResponse,
        regionsResponse,
        currenciesResponse,
        precisionsResponse,
        secDisagsResponse,
      ] = await Promise.all([
        getOEGeoExts(),
        getOEDatasetTypes(),
        getOERegions(),
        getOECurrencies(),
        getOEGeoPrecisions(),
        getOESecDisags(),
      ]);

      if (myAbortController.signal.aborted === false) {
        setGeoExtCodes(
          geoExtResponse.data.map((type) => ({
            value: type.code,
            label: type.name,
          }))
        );
        setGeoDatasetTypes(
          datasetTypesResponse.data.map((type) => ({
            value: type.code,
            label: type.name,
          }))
        );
        setRegionsDatasetTypes(
          regionsResponse.data.map((region) => ({
            value: region.code,
            label: region.name,
          }))
        );
        setRegionsForMap(createNestedArray(regionsResponse.data));
        setOeCurrencies(
          currenciesResponse.data.map((currency) => ({
            value: currency.code,
            label: currency.name,
          }))
        );

        setOeGeoPrecisions(
          precisionsResponse.data.map((geoPrecision) => ({
            value: geoPrecision.code,
            label: geoPrecision.name,
          }))
        );

        setOeSecDisags(
          secDisagsResponse.data.map((secDisag) => ({
            value: secDisag.code,
            label: secDisag.name,
          }))
        );
      }
    };

    fetchData();

    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    setOptionsPagination([
      <Fragment key='options'>
        <option defaultValue='20' key='20'>
          20
        </option>
        <option value='50' key='50'>
          50
        </option>
        <option value='100' key='100'>
          100
        </option>
      </Fragment>,
    ]);
  }, []);

  // useEffect(() => {
  //   getOEDatasets().then((res) => {
  //     const responseToSave = res.data.filter((el) => el.id === '54');
  //     setNewDataset(...responseToSave);
  //     setAssociatedScenarios(responseToSave?.[0]?.associatedScenarios.length > 0 ? true : false);
  //     setCheckedExistingDatasetRegions([responseToSave?.[0]?.regionCode]);
  //   });
  // }, []);

  useEffect(() => {
    setSelectedGeoExtCodes(
      geoExtCodes.filter((geoExtCode) => geoExtCode.value === newDataset.geoExtCode)
    );
    setSelectedRegion(
      regionsDatasetTypes.filter((regions) => regions.value === newDataset.regionCode)
    );
    setSelectedGeoDatasetTypes(
      geoDatasetTypes.filter((geoDatasetType) => geoDatasetType.value === newDataset.typeCode)
    );
    setSelectedOeCurrencies(
      oeCurrencies.filter((oeCurrency) => oeCurrency.value === newDataset.salaryCurrency)
    );
    setSelectedOeSecDisags(
      oeSecDisags.filter((oeSecDisag) => oeSecDisag.value === newDataset.secDisagCode)
    );
    setSelectedOeGeoPrecisions(
      oeGeoPrecisions.filter((oeGeoPrecision) => oeGeoPrecision.value === newDataset.precisionCode)
    );
  }, [
    newDataset,
    geoExtCodes,
    geoDatasetTypes,
    oeCurrencies,
    oeSecDisags,
    regionsDatasetTypes,
    oeGeoPrecisions,
  ]);

  const defaultColDef = {
    resizable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    suppressMovable: true,
    cellStyle: { padding: '0 1rem' },
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: 'ID',
        field: 'id',
        maxWidth: 100,
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.id;
        },
      },

      {
        headerValueGetter: () => propTrans({ prop: 'A0702' }),
        field: 'typeCode',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return geoDatasetTypes
            ?.filter((el) => el.value === params.data.typeCode)
            .map((el) => el.label);
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0703' }),
        field: 'geoExtCode',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return geoExtCodes
            ?.filter((el) => el.value === params.data.geoExtCode)
            .map((el) => el.label);
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0528' }),
        field: 'regionCode',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return regionsDatasetTypes
            .filter((el) => el.value === params.data.regionCode)
            .map((el) => el.label);
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0627' }),
        field: 'year',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return params.data.year;
        },
      },
      {
        headerValueGetter: () => propTrans({ prop: 'A0704' }),
        field: 'year',
        filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          return oeSecDisags
            .filter((el) => el.value === params.data.secDisagCode)
            .map((el) => el.label);
        },
      },

      {
        pinned: 'right',
        maxWidth: 130,
        cellStyle: { textAlign: 'center' },
        cellRenderer: ActionBtnRendererNewDataset,
        cellRendererParams: {
          setRefresh: setRefresh,
          geoExtCodes: geoExtCodes,
          geoDatasetTypes: geoDatasetTypes,
          regionsDatasetTypes: regionsDatasetTypes,
          oeCurrencies: oeCurrencies,
          oeSecDisags: oeSecDisags,
          regionsForMap: regionsForMap,
          oeGeoPrecisions: oeGeoPrecisions,
        },
      },
    ]);
  }, [
    geoExtCodes,
    geoDatasetTypes,
    regionsDatasetTypes,
    oeCurrencies,
    oeSecDisags,
    oeGeoPrecisions,
    datasets,
    regionsForMap,
  ]);

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []);

  // const onFirstDataRendered = useCallback(() => {
  //   if (!gridRef) return;
  //   setTimeout(() => {
  //     gridRef.current.api.sizeColumnsToFit();
  //   }, 200);
  //   //gridRef.current.api.sizeColumnsToFit();
  // }, []);

  const onGridReady = useCallback(() => {
    getOEDatasets().then((retrievedDatasets) => {
      setDatasets(retrievedDatasets);
    });
  }, []);

  const onPageSizeChanged = useCallback(() => {
    let value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onChange = (e, fieldname, setSelect) => {
    console.log('onChange e', e);

    if (setSelect) {
      setSelect(e ?? []);
      if (
        newDataset?.id &&
        (fieldname === 'secDisagCode' || fieldname === 'typeCode' || fieldname === 'geoExtCode')
      ) {
        setSelectedCountries([]);
        setCheckedExistingDatasetRegions([]);
      }
      setNewDataset(() => ({
        ...newDataset,
        [fieldname]: e ? e.value ?? e : [],
      }));
    } else {
      setNewDataset(() => ({
        ...newDataset,
        [e.target.name]:
          e.target.type === 'number'
            ? e.target.value
              ? Number(e.target.value)
              : ''
            : e.target.value,
      }));
    }
  };

  useEffect(() => {
    setNewDataset((prevState) => {
      return { ...prevState, regions: selectedCountries };
    });
  }, [selectedCountries]);

  const postNewDatasetItem = async (e) => {
    e.preventDefault();
    setSbShow(<></>);

    const {
      name,
      description,
      file,
      updatedAt,
      createdAt,
      salaryCurrency,
      localSalary,
      ...restNewDataset
    } = newDataset;

    const isFilled = Object.values(restNewDataset).every((prop) => prop !== '' && prop != null)
      ? true
      : false;

    if (!isFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }
    const finalDataset = {
      ...newDataset,
      name: `${newDataset.geoExtCode} ${newDataset.typeCode} ${
        regionsDatasetTypes.find((el) => el.value === newDataset.regionCode).label
      } ${newDataset.year}`,
    };
    handleStatusBar('elaborating', false, false);

    const createResult = await postOEDatasets(finalDataset);
    if (createResult.status === true) {
      handleStatusBar('success', <Trans code='A0651' />, true);
      setNewDataset(createResult.data);
      setActiveKey('geography');
      const timer = setTimeout(() => {
        setSbShow(<></>);
      }, 3000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    } else {
      handleStatusBar('error', createResult.message ?? <Trans code='A0652' />, false);
    }
  };

  const putDatasetItem = async (e) => {
    e.preventDefault();
    const {
      name,
      description,
      file,
      updatedAt,
      createdAt,
      shadowPriceStatus,
      datasetValueStatus,
      associatedScenarios,
      localSalary,
      salaryCurrency,
      activeKey,
      version,
      ...restEditDataset
    } = newDataset;

    // Check the value of activeKey and conditionally update localSalary and salaryCurrency
    if (activateProfile) {
      const isSalaryAndCUrrencyValid =
        salaryCurrency !== '' &&
        salaryCurrency !== null &&
        localSalary !== '' &&
        localSalary !== null;
      if (!isSalaryAndCUrrencyValid) {
        handleStatusBar('error', <Trans code='A0522' />, false);
        return;
      }
    }

    const areRegionsSelected = restEditDataset?.regions?.length > 0 ? true : false;

    const isFilled = Object.values(restEditDataset).every((prop) => prop !== '' && prop !== null)
      ? true
      : false;

    if (!areRegionsSelected) {
      handleStatusBar('error', 'Selezionare almeno una regione', false);
      return;
    }
    if (!isFilled) {
      handleStatusBar('error', <Trans code='A0522' />, false);
      return;
    }
    handleStatusBar('elaborating', false, false);

    const createPutResult = await putOEDataset(newDataset, newDataset.id);
    if (createPutResult.status === true) {
      handleStatusBar('success', <Trans code='A0651' />, true);
      setNewDataset(createPutResult.data);
      setActiveKey('profile');
      if (createPutResult?.data?.regions.length > 0 && activateProfile === false) {
        setActivateProfile((prevState) => !prevState);
      }
      const timer = setTimeout(() => {
        setSbShow(<></>);
      }, 3000); //in ogni caso viene messa a false
      return () => clearTimeout(timer);
    } else {
      handleStatusBar('error', createPutResult.message ?? <Trans code='A0652' />, false);
    }
  };

  const fullScreenTooltip = (props) => (
    <Tooltip {...props}>
      <Trans code={modalFullscreen ? 'A0778' : 'A0777'} />
    </Tooltip>
  );

  if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
    return (
      <div id='scenari' className='megaContent'>
        <div className='container-fluid'>
          <div className='row'>
            <Trans code='A0597' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='dataset' className='megaContent'>
      <div className='container-fluid'>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-6'>
            <h5 className='title-simple-box mb-0'>
              <Trans code='A0483' />
            </h5>
          </div>
          <div className='col-6 text-right'>
            <ButtonToolbar className='justify-content-end my-2'>
              <Button variant='info add-user' onClick={() => handleShowModal(setShowModalNew)}>
                <div className='row align-items-center py-1 px-3'>
                  <IcoPlus className='light-color ico-small plus mr-2' />
                  <span className='d-md-inline'>
                    <Trans code='A0615' />
                  </span>
                </div>
              </Button>
              <InputGroup className='search-table align-items-center bg-white'>
                <FormControl
                  type='text'
                  id='filter-text-box'
                  placeholder='Search...'
                  className='d-none d-md-block '
                  onInput={(e) => onFilterTextBoxChanged(e)}
                />
                <Lens />
              </InputGroup>
            </ButtonToolbar>
          </div>
        </div>
        <div className='row'>
          <div className='col-12' id='grid-wrapper'>
            <div className='ag-theme-alpine projects-list-table'>
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={datasets.data}
                defaultColDef={defaultColDef}
                domLayout='autoHeight'
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                // onFirstDataRendered={onFirstDataRendered}
                //onVirtualRowRemoved={onVirtualRowRemoved}
                //onColumnResized={onColumnResized}
                //onGridSizeChanged={onGridSizeChanged}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={20}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                animateRows={true}
              />
              <div className='example-header'>
                <Trans code='A0647' />
                :&nbsp;
                <select onChange={onPageSizeChanged} id='page-size'>
                  {optionsPagination}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        id='dataset-modal'
        size={modalFullscreen ? 'fullscreen' : 'xl'}
        show={showModalNew}
        onHide={() => handleCloseModal(setShowModalNew)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title className='px-2 d-flex align-items-center'>
            <OverlayTrigger placement='right' overlay={fullScreenTooltip}>
              <Button
                variant='link'
                className='mr-3'
                onClick={() => setModalFullscreen((prevState) => !prevState)}>
                {modalFullscreen ? (
                  <FontAwesomeIcon icon={faCompressArrowsAlt} />
                ) : (
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                )}
              </Button>
            </OverlayTrigger>
            <Trans code='A0645' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
            id='fill-tab-example'
            className='mb-0'
            fill>
            <Tab className='py-5' eventKey='home' title={<Trans code='A0493' />}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                id='form-admin-dataset'>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} controlId='typeCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0567' /> &nbsp;
                        <span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='typeCode'
                        value={selectedGeoDatasetTypes}
                        options={geoDatasetTypes}
                        onChange={(e) => onChange(e, 'typeCode', setSelectedGeoDatasetTypes)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId='secDisagCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0640' /> &nbsp;
                        <span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='secDisagCode'
                        value={selectedOeSecDisags}
                        options={oeSecDisags}
                        onChange={(e) => onChange(e, 'secDisagCode', setSelectedOeSecDisags)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} controlId='geoExtCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0565' /> &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='geoExtCode'
                        value={selectedGeoExtCodes}
                        options={geoExtCodes}
                        onChange={(e) => onChange(e, 'geoExtCode', setSelectedGeoExtCodes)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group as={Col} controlId='regionCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0084' /> &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='regionCode'
                        value={selectedRegion}
                        options={regionsDatasetTypes}
                        onChange={(e) => onChange(e, 'regionCode', setSelectedRegion)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Col} className=' pr-2' controlId='precisionCode'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0650' /> &nbsp;<span className='required-field'>*</span>
                      </Form.Label>
                      <Select
                        name='precisionCode'
                        value={selectedOeGeoPrecisions}
                        options={oeGeoPrecisions}
                        onChange={(e) => onChange(e, 'precisionCode', setSelectedOeGeoPrecisions)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='px-3' controlId='year'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0627' /> &nbsp;
                        <span className='required-field'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='number'
                        name='year'
                        min={2010}
                        step={1}
                        value={newDataset.year ?? ''}
                        onChange={(e) => onChange(e, 'year', undefined)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Form.Group controlId='description'>
                      <Form.Label className='mb-3'>
                        <Trans code='A0138' />
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={5}
                        name='description'
                        value={newDataset.description ?? ''}
                        onChange={(e) => onChange(e, 'description', undefined)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>

            <Tab
              className='py-5'
              eventKey='geography'
              title={<Trans code='A0780' />}
              disabled={!newDataset?.id}>
              <Row className='mb-3'>
                <Col
                  style={{
                    maxHeight: '668px',
                    overflowY: 'scroll',
                    height: '668px',
                    minHeight: '668px',
                  }}
                  className='col-3 mt-5'>
                  <TreeView
                    regionData={regionsForMap}
                    onCheckedItemsChange={handleCountrySelection}
                    alreadyChecked={checkedExistingDatasetRegions}
                    extension={newDataset?.geoExtCode}
                  />
                </Col>
                <Col className='col-9'>
                  <WorldMapSVG countryIds={selectedCountries} regionData={regionsDatasetTypes} />
                </Col>
              </Row>
            </Tab>
            <Tab
              className='py-5'
              eventKey='profile'
              title={<Trans code='A0108' />}
              disabled={newDataset?.regions?.length === 0 || !activateProfile}>
              <>
                <Row className='mb-3'>
                  <DownloadUploadItems
                    title={'Dataset'}
                    handleDownload={handleDownload}
                    handleUpload={handleFile}
                    status={newDataset?.datasetValueStatus ?? 0}
                  />
                </Row>
                <Row className='mb-5'>
                  <DownloadUploadItems
                    title={<Trans code='A0779' />}
                    handleDownload={handleDownload}
                    // handleUpload={handleFile}
                    status={newDataset?.shadowPriceStatus ?? 0}
                    hasDetails={true}
                    regionsDatasetTypes={regionsDatasetTypes}
                    datasetId={newDataset?.id}
                    isShadow={true}
                  />
                </Row>
                <hr className='divider' />
              </>

              <Row className='mb-3'>
                <Col className='px-0'>
                  <Form.Group className='pl-4 pr-2' controlId='udm'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0626' /> &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Form.Control
                      type='number'
                      name='localSalary'
                      placeholder='es. 33000'
                      maxLength='10'
                      min={0}
                      step={1000}
                      value={newDataset?.localSalary ?? 0}
                      onChange={(e) => onChange(e, 'localSalary', undefined)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} className='pl-4 pr-2' controlId='salaryCurrency'>
                    <Form.Label className='mb-3'>
                      <Trans code='A0625' /> &nbsp;<span className='required-field'>*</span>
                    </Form.Label>
                    <Select
                      name='salaryCurrency'
                      value={selectedOeCurrencies}
                      options={oeCurrencies}
                      onChange={(e) => onChange(e, 'salaryCurrency', setSelectedOeCurrencies)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>

            <Tab
              disabled={!newDataset?.associatedScenarios?.length > 0}
              eventKey='associatedScenarios'
              // title={propTrans({ prop: 'A0468' })}
              title={<Trans code='A0468' />}
              className='py-5'>
              {activeKey === 'associatedScenarios' && (
                <AssociatedScenarioTable associatedScenarios={newDataset?.associatedScenarios} />
              )}
            </Tab>
          </Tabs>

          {sbShow}
          <span className='mt-4 px-3'>
            <span className='required-field'>*</span>&nbsp;
            <Trans code='A0503' />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
            <Trans code='A0529' />
          </Button>
          {!newDataset?.id ? (
            <Button variant='primary' onClick={(e) => postNewDatasetItem(e)}>
              <Trans code='A0644' />
            </Button>
          ) : (
            <Button
              variant='primary'
              onClick={(e) => {
                setSbShow(<></>);
                putDatasetItem(e);
              }}>
              <Trans code='F00055' />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dataset;
