const base64ToBlob = (code, mimeType='') => {
    const byteString = atob(code);
    const ab = new ArrayBuffer(byteString.length);

    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeType});
};

export default base64ToBlob;