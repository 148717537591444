import React, { Component } from "react"

class Ico97 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-97" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
         <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
         <g transform="scale(1.2) translate(15,25)">
            <path className="ico" d="M2,28h24c1.104,0,2-0.896,2-2V2c0-1.104-0.896-2-2-2H2C0.896,0,0,0.896,0,2v24C0,27.104,0.896,28,2,28z M4,4h20v20H4V4z"/>
            <path className="ico" d="M64,2c0-1.104-0.896-2-2-2H38c-1.104,0-2,0.896-2,2v24c0,1.104,0.896,2,2,2h24c1.104,0,2-0.896,2-2V2z M60,24H40V4h20V24z"
               />
            <path className="ico" d="M2,64h25c1.104,0,2-0.896,2-2V37c0-1.104-0.896-2-2-2H2c-1.104,0-2,0.896-2,2v25C0,63.104,0.896,64,2,64z M4,39h21v21H4V39
               z"/>
            <path className="ico" d="M62,36H38c-1.104,0-2,0.896-2,2v24c0,1.104,0.896,2,2,2h24c1.104,0,2-0.896,2-2V38C64,36.896,63.104,36,62,36z M60,60H40
               V40h20V60z"/>
         </g>
      </svg>
    );
  }
}
export default Ico97