import React from 'react';
import { ArrowDownUp } from 'icone/index';
const SortArrows = ({ activeAttribute, sortScenari, ordering, filteredScenarios }) => {
  const isActive = (attribute) => attribute === activeAttribute;
  const isAscending = ordering.key === activeAttribute && ordering.direction === 'ASC';
  const isDescending = ordering.key === activeAttribute && ordering.direction === 'DESC';
  return (
    <div className='d-flex flex-column'>
      {isActive(activeAttribute) && (
        <ArrowDownUp
          style={{
            transform: 'rotate(180deg)',
            visibility: `${isDescending ? 'hidden' : 'visible'}`,
          }}
          className='ico-small new-scenari-table-sort-btn'
          onClick={() => sortScenari(activeAttribute, 'DESC', filteredScenarios)}
        />
      )}
      {isActive(activeAttribute) && (
        <ArrowDownUp
          style={{
            visibility: `${isAscending ? 'hidden' : 'visible'}`,
          }}
          className='ico-small new-scenari-table-sort-btn'
          onClick={() => sortScenari(activeAttribute, 'ASC', filteredScenarios)}
        />
      )}
    </div>
  );
};

export default SortArrows;
