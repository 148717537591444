import React, { Component } from 'react';

class Ico104 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg
        id='ico-94'
        viewBox='0 0 117.62 136'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
        height={this.props.height}
        width={this.props.width}>
        <path d='M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z' className='esagono' />
        <g transform='scale(0.45)' style={{ transformOrigin: 'center' }}>
          <path
            d='M98.89 36.51c1.23 0 2.24-1 2.24-2.24V17.55c0-1.24-1.01-2.24-2.24-2.24H29.1c-1.24 0-2.24 1-2.24 2.24v100.97c0 1.24 1 2.24 2.24 2.24h69.8c1.23 0 2.24-1 2.24-2.24V101.8c0-1.24-1.01-2.24-2.24-2.24H53.24V77.51h35.12c1.23 0 2.24-1 2.24-2.24V58.55c0-1.24-1.01-2.24-2.24-2.24H53.24v-19.8h45.65z'
            className='ico'
          />
        </g>
      </svg>
    );
  }
}
export default Ico104;
