import React, { Component } from "react"

class Ico33 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-45" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
        <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
        <g transform="translate(24.3,33.5)">
          <path d="M55.9585 52.6466C59.4686 48.5058 61.8107 43.3434 62.4757 37.6685H67.5054C66.8051 44.7215 63.9065 51.1278 59.5045 56.1926L55.9585 52.6466Z" className="ico" />
          <path d="M52.4031 56.1626C47.4987 60.2268 41.2022 62.6697 34.335 62.6697C27.6752 62.6697 21.5521 60.3721 16.715 56.5263L13.1596 60.0816C18.9177 64.8228 26.2939 67.67 34.335 67.67C42.5833 67.67 50.1318 64.6743 55.952 59.7115L52.4031 56.1626Z" className="ico" />
          <path d="M34.335 1C52.1865 1 66.7606 15.0322 67.6291 32.6683H62.6215C61.7588 17.7952 49.4243 6.00025 34.335 6.00025C18.6861 6.00025 6.00025 18.6861 6.00025 34.335C6.00025 41.5232 8.67697 48.0863 13.088 53.0818L9.5465 56.6234C4.23331 50.7181 1 42.9041 1 34.335C1 15.9246 15.9246 1 34.335 1Z" className="ico" />
          <path fillRule="evenodd" clipRule="evenodd" d="M61.5861 36.6685H68.6096L68.5005 37.7673C67.7791 45.0333 64.7926 51.6327 60.2592 56.8486L59.5557 57.658L54.6002 52.7025L55.1957 52C58.5826 48.0045 60.8411 43.0253 61.4825 37.5521L61.5861 36.6685ZM52.4663 54.8115L57.4247 59.77L56.6009 60.4725C50.6065 65.5837 42.8299 68.67 34.335 68.67C26.0535 68.67 18.4545 65.7368 12.524 60.8536L11.6736 60.1534L16.6388 55.1882L17.3373 55.7436C22.0038 59.4538 27.9093 61.6697 34.335 61.6697C40.9607 61.6697 47.0334 59.3137 51.765 55.3926L52.4663 54.8115ZM68.6795 33.6683H61.6779L61.6232 32.7262C60.791 18.3789 48.8914 7.00025 34.335 7.00025C19.2384 7.00025 7.00025 19.2384 7.00025 34.335C7.00025 41.2702 9.5817 47.6001 13.8376 52.4199L14.4597 53.1244L9.50819 58.0759L8.8031 57.2922C3.33115 51.2105 0 43.1606 0 34.335C0 15.3723 15.3723 0 34.335 0C52.7223 0 67.7333 14.4531 68.6278 32.6191L68.6795 33.6683ZM55.9585 52.6466C59.4686 48.5058 61.8107 43.3434 62.4757 37.6685H67.5054C67.4722 38.0033 67.434 38.3367 67.3909 38.6685C66.5718 44.9783 63.9883 50.7343 60.1514 55.4253C59.9396 55.6843 59.7239 55.9401 59.5045 56.1926L55.9585 52.6466ZM16.715 56.5263C21.5521 60.3721 27.6752 62.6697 34.335 62.6697C41.2022 62.6697 47.4987 60.2268 52.4031 56.1626L55.952 59.7115C55.6973 59.9287 55.4393 60.1422 55.178 60.3517C49.4698 64.9308 42.2223 67.67 34.335 67.67C26.6554 67.67 19.5823 65.0731 13.946 60.7095C13.6807 60.5041 13.4185 60.2948 13.1596 60.0816L16.715 56.5263ZM8.88732 55.8683C3.9671 50.0595 1 42.5438 1 34.335C1 15.9246 15.9246 1 34.335 1C51.8478 1 66.2063 14.5047 67.5649 31.6682C67.5912 32.0002 67.6126 32.3336 67.6291 32.6683H62.6215C61.7588 17.7952 49.4243 6.00025 34.335 6.00025C18.6861 6.00025 6.00025 18.6861 6.00025 34.335C6.00025 41.5232 8.67697 48.0863 13.088 53.0818L9.5465 56.6234C9.32306 56.375 9.10331 56.1233 8.88732 55.8683Z" className="ico" />
        </g>
      </svg>
    );
  }
}
export default Ico33