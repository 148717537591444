import React, { Component } from "react"

class Ico99 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-99" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
         <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
         <g transform="translate(18,29) scale(0.015)" fill="#000000" stroke="white" stroke-width="100">
            <path className="ico" d="M4470 5113 c-111 -19 -233 -85 -316 -174 -103 -110 -147 -221 -147
               -374 0 -95 15 -163 56 -255 5 -11 -124 -146 -450 -473 l-458 -457 -50 33
               c-243 163 -568 163 -810 1 l-50 -34 -356 356 -357 357 21 46 c16 36 21 67 21
               141 1 83 -2 102 -26 152 -68 146 -200 225 -360 216 -290 -16 -447 -344 -278
               -581 99 -138 286 -192 439 -127 l53 23 355 -356 356 -355 -34 -53 c-84 -131
               -119 -251 -119 -407 0 -155 40 -291 121 -408 l32 -46 -591 -591 -590 -590 -39
               30 c-215 170 -530 148 -727 -51 -221 -222 -221 -561 -1 -781 113 -113 236
               -165 392 -165 154 0 285 54 393 160 112 112 164 239 163 400 0 89 -17 162 -56
               250 -5 11 162 184 588 610 l595 595 27 -18 c90 -57 196 -103 283 -121 l55 -12
               3 -471 2 -471 -67 -21 c-275 -84 -447 -366 -389 -639 92 -436 617 -600 932
               -292 142 137 200 338 155 530 -44 184 -197 344 -384 401 l-67 21 2 470 3 470
               75 19 c70 18 200 75 263 116 l27 18 355 -355 355 -355 -26 -64 c-23 -56 -26
               -76 -22 -146 7 -144 84 -256 216 -318 59 -27 78 -31 152 -31 74 0 93 4 150 30
               82 39 145 101 184 184 28 58 31 75 31 155 0 81 -3 97 -32 155 -55 113 -158
               190 -275 208 -68 11 -157 -1 -210 -27 l-36 -18 -357 357 -358 358 32 46 c77
               112 121 260 121 411 0 151 -38 277 -122 408 l-31 49 448 448 449 449 45 -33
               c107 -78 208 -110 341 -109 151 1 262 46 372 151 182 173 227 421 117 649
               -104 216 -353 346 -589 306z m245 -216 c217 -101 284 -378 136 -565 -188 -238
               -567 -159 -647 135 -49 181 44 364 223 439 82 35 202 31 288 -9z m-3450 -438
               c55 -15 110 -75 123 -134 27 -127 -65 -234 -193 -223 -86 7 -146 60 -165 144
               -19 82 36 181 115 209 49 17 69 18 120 4z m1564 -1124 c152 -36 295 -149 365
               -287 84 -168 85 -341 2 -506 -95 -189 -282 -304 -496 -304 -498 0 -745 602
               -390 951 135 134 334 189 519 146z m1437 -1864 c43 -19 86 -68 99 -110 12 -38
               7 -108 -9 -139 -89 -173 -352 -110 -350 83 2 136 137 222 260 166z m-3568
               -383 c75 -28 161 -111 198 -190 25 -55 29 -75 29 -153 0 -78 -4 -98 -29 -152
               -33 -73 -113 -154 -181 -184 -188 -83 -401 -5 -492 179 -25 51 -28 68 -28 157
               0 89 3 106 28 157 87 178 286 255 475 186z m2103 -174 c76 -23 153 -77 199
               -141 136 -190 65 -451 -148 -551 -49 -23 -70 -27 -152 -27 -82 0 -103 4 -152
               27 -255 119 -295 460 -72 631 91 70 216 93 325 61z"
            />
         </g>
      </svg>
    );
  }
}
export default Ico99