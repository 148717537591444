import React from 'react'
import { CardDeck } from 'react-bootstrap'
import DashboardImpact from '@components/scenario/dashboard/DashboardImpact'
import DashboardKpi from '@components/scenario/dashboard/DashboardKpi'
import DashboardAssumptions from '@components/scenario/dashboard/DashboardAssumptions'
import DashboardMeta from '@components/scenario/dashboard/DashboardMeta'

const Dashboard = ({ dashboard, additionalInfo, navigateCallback = null }) => (
  <>
    <DashboardImpact dashboard={dashboard} navigateCallback={navigateCallback} />
    <div className="row">
      <div className="col-12">
        <CardDeck>
          <DashboardKpi dashboard={dashboard} />
          <DashboardAssumptions dashboard={dashboard} />
        </CardDeck>
      </div>
    </div>
    <DashboardMeta dashboard={dashboard} additionalInfo={additionalInfo} />
  </>
)
export default Dashboard
