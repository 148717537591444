import React, { useState, useEffect } from "react";
import { Card } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form'
import { ButtonToolbar, Button } from 'react-bootstrap';
import { IcoCircleBox } from '@icons';
import IcoSqHunger from '@icons/ico-sq-2-hunger.svg'
import IcoSqPoverty from '@icons/ico-sq-1-poverty.svg'
import IcoSqGoodhealth from '@icons/ico-sq-3-goodhealth.svg'
import IcoSqEducation from '@icons/ico-sq-4-education.svg'
import IcoSqGender from '@icons/ico-sq-5-gender.svg'
import IcoSqWater from '@icons/ico-sq-6-water.svg'
import IcoSqEnergy from '@icons/ico-sq-7-energy.svg'
import IcoSqWork from '@icons/ico-sq-8-work.svg'
import IcoSqIndustry from '@icons/ico-sq-9-industry.svg'
import IcoSqInequality from '@icons/ico-sq-10-inequality.svg'
import IcoSqCity from '@icons/ico-sq-11-city.svg'
import IcoSqProduction from '@icons/ico-sq-12-production.svg'
import IcoSqClimate from '@icons/ico-sq-13-climate.svg'
import IcoSqFish from '@icons/ico-sq-14-fish.svg'
import IcoSqLand from '@icons/ico-sq-15-land.svg'
import IcoSqPeace from '@icons/ico-sq-16-peace.svg'
import IcoSqPartnership from '@icons/ico-sq-17-partnership.svg'
import { useParams, Redirect } from 'react-router-dom'
import i18n from '@lib/i18n'
import Currency from '@lib/currency'
import { getScenario, getScenarioMeta } from '@lib/api'
import { Trans } from '@lib/i18n'
import { rounder } from '@lib/helpers'
import ScenarioHeader from '@components/scenario/ScenarioHeader'

const sdgs = [
  { id: 1, ico: IcoSqPoverty },
  { id: 2, ico: IcoSqHunger },
  { id: 3, ico: IcoSqGoodhealth },
  { id: 4, ico: IcoSqEducation },
  { id: 5, ico: IcoSqGender },
  { id: 6, ico: IcoSqWater },
  { id: 7, ico: IcoSqEnergy },
  { id: 8, ico: IcoSqWork },
  { id: 9, ico: IcoSqIndustry },
  { id: 10, ico: IcoSqInequality },
  { id: 11, ico: IcoSqCity },
  { id: 12, ico: IcoSqProduction },
  { id: 13, ico: IcoSqClimate },
  { id: 14, ico: IcoSqFish },
  { id: 15, ico: IcoSqLand },
  { id: 16, ico: IcoSqPeace },
  { id: 17, ico: IcoSqPartnership }
]

const SdgTile = ({ id, ico, data, showPaused = true }) => {
  const value = parseFloat(data.val)
  const paused = !(value && !isNaN(value))
  if(paused && !showPaused) return null
  return (
    <div className={paused ? 'content-card paused' : 'content-card'}>
      <Card>
        <Card.Img variant="top" src={ico} />
        <Card.Body>
          <Card.Title>
            <span>{data.title.length ? <>+ <Trans code={data.title} /></> : ''}</span>
            {!paused ? <>{rounder(value)} <Trans code={data.udm} /></> : 'N/A'}
          </Card.Title>
          <Card.Text>
            {/* <h6>details</h6> */}
            <span className="link-list">
              {/* <a href={data.link2} rel="noreferrer" target="_blank">Country update</a>  */}
              <a href={data.link3} rel="noreferrer" target="_blank">{`SDG${id}`} details</a>
            </span>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>)
}

const SDG = () => {
  const { idScenario = null } = useParams()
  const [scenario, setScenarioMeta] = useState(null)
  const [sdg, setSdg] = useState(null)
  // const [gri, setGri] = useState(null)
  const [showPaused, setShowPaused] = useState(true)

  const setScenario = () => {
    getScenario(idScenario)
      // .then(({ sdg: { SDGS, GRI } }) => {
      .then(({ sdg: { SDGS } }) => {
        setSdg(SDGS)
        // setGri(GRI)
      })
    getScenarioMeta(idScenario)
      .then(setScenarioMeta)
  }

  useEffect(() => {
    if (idScenario) {
      setScenario()
      i18n.on('languageChanged', setScenario)
      Currency.on('currencyChanged', setScenario)
      return function clenup() {
        i18n.off('languageChanged', setScenario)
        Currency.off('currencyChanged', setScenario)
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!idScenario) return <Redirect to='/scenari' />

  if (!scenario || !sdg) return null

  return (
    <div id="standard-kpi">
      <div className="container-fluid">
        {/* <ScenarioHeader scenario={scenario} /> */}
        <div className="row">
          <div className="col">
            <Card className="m-0">
              <Card.Body>
                {/* <IcoCircleBox className="logo-card ico-box-blue" /> */}
                {/* <div className="row">
                  <div className="col-md-10 px-0">
                    <Form.Group>
                      <Form.Control as="select">
                        <option> Sustainable development goals (SDGs) </option>
                        <option> Option 2 </option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col d-flex align-items-center">
                    <strong><Trans code="A0485"/></strong>
                    <ButtonToolbar aria-label="Submenu dashboard" className="d-flex align-items-center">
                      <Button variant="link" className={showPaused ? 'active' : ''} onClick={() => setShowPaused(true)}>
                        <Trans code="A0486" className="text-uppercase" />
                      </Button>
                      <Button variant="link" className={showPaused ? '' : 'active'} onClick={() => setShowPaused(false)}>
                        <Trans code="A0487" className="text-uppercase" />
                      </Button>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className="row list">
                  { sdgs.map(s => <SdgTile key={s.id} {...s} data={sdg[s.id]} showPaused={showPaused} />) }
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SDG;