import React, { useState, useEffect } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Ico36 } from '@icons/new';
import { Trans } from '@lib/i18n';

const DashboardAssumptions = ({ scenario, variant }) => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    setInfo(() => ({
      simulationPeriod: variant.operationEnd - variant.investmentStart + 1,
      investmentPeriod: variant.investmentEnd - variant.investmentStart + 1,
      operatingPeriod: variant.operationEnd - variant.operationStart + 1,
      start: variant.investmentStart,
      // start: variant.operationStart,
      discountRate: variant.socialDiscountRate.toLocaleString('it-IT', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    }));
  }, [variant]);

  return (
    <Card className='cards-utente d-none d-lg-block'>
      <Card.Body>
        <Ico36 className='logo-card ico-box-blue' />
        <Card.Title>
          <Trans code='F0066' />
        </Card.Title>

        <ListGroup variant='flush'>
          <ListGroup.Item className='d-flex justify-content-between align-items-center px-3'>
            <strong>
              <Trans code='F0067' />
            </strong>
            <span className='text-right'>
              {info.simulationPeriod}&nbsp;
              {info.simulationPeriod === 1 ? <Trans code='A0385' /> : <Trans code='A0402' />}
            </span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between align-items-center px-3'>
            <strong>
              <Trans code='F0068' />
            </strong>
            <span className='text-right'>
              {info.investmentPeriod}&nbsp;
              {info.investmentPeriod === 1 ? <Trans code='A0385' /> : <Trans code='A0402' />}
            </span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between align-items-center px-3'>
            <strong>
              <Trans code='F0069' />
            </strong>
            <span className='text-right'>
              {info.operatingPeriod}&nbsp;
              {info.operatingPeriod === 1 ? <Trans code='A0385' /> : <Trans code='A0402' />}
            </span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between align-items-center px-3'>
            <strong>
              <Trans code='A0073' />
            </strong>
            <span className='text-right'>{info.start}</span>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between align-items-center px-3'>
            <strong>
              <Trans code='A0112' />
            </strong>
            <span className='text-right'>{info.discountRate}&#37;</span>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
export default DashboardAssumptions;
