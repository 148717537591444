import React from 'react';

const ShowEmployable = ({ data }) => {
  const legendStyles = {
    bullet: {
      display: 'inline-block',
      width: '1em',
      height: '1em',
      borderRadius: '0.5em',
      top: '0.15em',
      position: 'relative',
    },
  };
  console.log('DATA', data);
  return (
    <>
      <span>
        <i
          style={{
            ...legendStyles.bullet,
            backgroundColor: data.employable ? '#689689' : '#C94723',
          }}
        />
      </span>
    </>
  );
};

export default ShowEmployable;
