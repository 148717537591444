import React, { Component } from "react"

class Ico95 extends Component {
  render() {
    let className = 'extsvg ';
    if (this.props.className !== undefined) {
      className += this.props.className;
    }

    return (
      <svg id="ico-95" viewBox="0 0 117.62 136" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} height={this.props.height} width={this.props.width}>
         <path d="M58.8103 0L117.621 34V102L58.8103 136L0 102V34L58.8103 0Z" className="esagono" />
         <g transform="translate(14,16) scale(0.0185)" fill="#000000" stroke="white" stroke-width="90">
            <path className="ico" d="M724 4770 c-198 -39 -330 -252 -280 -450 29 -116 132 -228 238 -259
               l38 -12 0 -259 0 -260 -335 0 -336 0 -24 -25 c-14 -13 -25 -35 -25 -48 0 -13
               124 -221 294 -492 l294 -468 -26 -41 c-211 -333 -538 -858 -550 -882 -22 -46
               -9 -82 34 -100 29 -12 78 -14 255 -12 210 3 219 4 240 25 27 27 29 75 4 103
               -16 18 -33 20 -176 22 l-158 3 270 429 c157 251 269 439 269 453 0 22 -63 127
               -421 696 l-118 187 254 0 254 0 3 -150 c3 -138 5 -151 25 -171 27 -27 75 -29
               103 -4 18 17 20 30 20 172 l0 153 311 -2 312 -3 273 -435 c151 -239 274 -438
               274 -442 0 -4 -124 -205 -276 -447 l-277 -441 -343 0 -343 0 -20 -26 c-28 -36
               -26 -60 8 -95 l29 -29 761 0 761 0 0 -194 0 -194 -46 -16 c-199 -68 -297 -306
               -205 -501 111 -236 418 -284 597 -93 70 75 98 145 98 248 1 75 -3 93 -31 152
               -47 99 -112 158 -225 202 l-38 15 0 190 0 191 990 2 990 3 24 25 c33 34 619
               970 624 996 2 12 -2 32 -9 45 -39 74 -604 967 -621 982 -18 15 -48 17 -309 17
               l-289 0 0 258 0 257 50 18 c279 99 326 481 80 649 -181 124 -424 63 -531 -134
               -33 -60 -34 -67 -34 -168 0 -96 2 -110 29 -163 16 -32 51 -78 78 -103 45 -42
               136 -94 166 -94 9 0 12 -59 12 -260 l0 -260 -675 0 c-662 0 -675 0 -695 -20
               -30 -30 -27 -83 6 -109 26 -20 39 -21 298 -21 l272 0 277 -441 277 -442 -267
               -426 c-146 -234 -273 -434 -281 -444 -14 -16 -35 -17 -236 -15 l-221 3 -3 216
               c-2 201 -4 217 -22 234 -28 25 -76 23 -103 -4 -21 -21 -22 -31 -25 -236 l-3
               -215 -335 0 c-183 0 -334 2 -334 4 0 3 115 188 256 413 267 426 284 454 284
               475 0 7 -121 207 -270 443 l-270 430 221 5 c217 5 221 5 240 29 26 32 24 67
               -6 96 l-24 25 -616 0 -615 0 0 256 0 255 38 15 c81 34 105 48 148 91 26 25 59
               72 77 111 28 59 32 77 31 152 0 110 -33 187 -108 259 -99 92 -207 125 -332
               101z m134 -152 c33 -8 60 -25 93 -58 91 -91 89 -221 -5 -308 -162 -149 -414 4
               -356 217 16 60 80 127 139 145 58 18 71 19 129 4z m3068 -23 c25 -14 57 -45
               72 -67 24 -36 27 -51 27 -119 0 -70 -3 -82 -30 -121 -35 -50 -120 -98 -173
               -98 -135 0 -230 103 -220 239 12 162 182 249 324 166z m-186 -1361 c0 -80 4
               -153 10 -163 5 -10 22 -24 39 -30 24 -10 34 -10 59 3 16 8 32 23 36 31 3 9 6
               82 6 161 l0 144 258 0 257 -1 270 -431 c149 -238 271 -439 272 -447 2 -9 -120
               -212 -271 -451 l-273 -435 -638 -3 c-574 -2 -637 -1 -631 13 3 9 123 202 266
               430 176 281 260 423 260 442 0 18 -85 163 -265 450 -146 232 -265 425 -265
               428 0 3 137 5 305 5 l305 0 0 -146z m-1235 -2323 c72 -33 135 -122 135 -194 0
               -95 -77 -195 -168 -217 -79 -18 -142 0 -202 59 -55 52 -76 109 -66 179 19 143
               173 232 301 173z"
            />
         </g>
      </svg>
    );
  }
}
export default Ico95