import React, { useMemo } from 'react';
import MonitoringCardsWrapper from './monitoringCardsWrapper';
import FlourishComponent from 'components/FlourishComponent/FlourishComponent';
import { Trans } from '@lib/i18n/index';

const IndicatorsMonitoring = ({ title, data }) => {
  const dataChart = useMemo(() => {
    if (!data) return [];
    return data.indicators.map(({ title, ...rest }) => rest);
  }, [data]);
  console.log('dataChart', dataChart);
  return (
    <div>
      <h1>{data.name}</h1>
      <p>
        <Trans code='A0864' /> - <span className='font-weight-bold'>{data?.value}</span>
      </p>
      <hr />
      <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
        {dataChart.map((el, i) => {
          const extractedData = Object.values(el);
          return (
            <MonitoringCardsWrapper
              key={`${title}_${i}`}
              title={data?.indicators[i].title}
              charts={[
                {
                  title: '',
                  children: (
                    <FlourishComponent
                      visualizationId='17435519'
                      chartId={`lineChartContainer_${title}_${i}`}
                      style={{ width: '100%' }}
                      data={{
                        bindings: {
                          data: {
                            label: 0,
                            metadata: [],
                            value: [1],
                          },
                        },
                        data: {
                          data: extractedData.flat(),
                        },
                        metadata: {
                          data: {
                            0: { type_id: 'string$arbitrary_string', type: 'string' },
                            1: {
                              type_id: 'number$none_point',
                              type: 'number',
                              output_format_id: 'number$comma_point',
                            },
                          },
                        },
                      }}
                      dataToRespond={[...extractedData.flat()]}
                    />
                  ),
                },
              ]}
              style
            />
          );
        })}
      </div>
    </div>
  );
};

export default IndicatorsMonitoring;
