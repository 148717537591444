import React, { useState, useRef } from 'react';

const ResizableDiv = ({ children, resize = 'both' }) => {
  // const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(150);
  const [isResizing, setIsResizing] = useState(false);
  const startY = useRef(0);
  const handleMouseDown = (e) => {
    setIsResizing(true);
    startY.current = e.clientY;
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      // setWidth(e.clientX);
      const newHeight = height + e.clientY - startY.current;
      setHeight(Math.max(150, newHeight));
      startY.current = e.clientY;
    }
  };

  return (
    <div
      style={{
        // minWidth: '100%',
        minHeight: '148px',
        width: `100%`,
        height: `${height}px`,
        border: '1px solid #ccc',
        borderRadius: '.25rem',
        // resize: 'both',
        resize: resize,
        overflow: 'auto',
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}>
      {children}
    </div>
  );
};

export default ResizableDiv;
