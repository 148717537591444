import React from 'react';
import { CardDeck } from 'react-bootstrap';
import DashboardImpact from '@components/scenariodashboard/dashboard/DashboardImpact';
import DashboardKpi from '@components/scenariodashboard/dashboard/DashboardKpi';
import DashboardAssumptions from '@components/scenariodashboard/dashboard/DashboardAssumptions';
import DashboardMeta from '@components/scenariodashboard/dashboard/DashboardMeta';

const Dashboard = ({ scenario, filterForRegion, navigateCallback = null, regions, variant }) => (
  <>
    <DashboardImpact
      filterForRegion={filterForRegion}
      syntheticResults={variant?.analysisResults?.IA?.syntheticResults}
      typologyCode={scenario.typologyCode}
      navigateCallback={navigateCallback}
      regions={regions}
    />
    <div className='row'>
      <div className='col-12'>
        <CardDeck>
          <DashboardKpi syntheticResults={variant?.analysisResults?.IA?.syntheticResults} />
          <DashboardAssumptions scenario={scenario} variant={variant} />
        </CardDeck>
      </div>
    </div>
    <DashboardMeta additionalInfo={variant?.additionalInfo} />
  </>
);
export default Dashboard;
