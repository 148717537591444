import React from 'react';
import MonitoringCardsWrapper from './monitoringCardsWrapper';
import FlourishComponent from 'components/FlourishComponent/FlourishComponent';
import { Trans } from '@lib/i18n/index';

const MonitoringSection = ({ title, data }) => {
  // const transformData = (data) => {
  //   if (!data) return [];
  //   const labels = ['', 'Ex-Ante', 'Ongoing', 'Ex-Post'];
  //   return data.map((element, index) => [labels[index] || element[0], element[1]]);
  // };

  return (
    <>
      <h1>{title}</h1>
      <hr />
      {data && (
        <MonitoringCardsWrapper
          title={<Trans code='A0809' />}
          charts={[
            {
              title: '',
              children: (
                <FlourishComponent
                  visualizationId='17435519'
                  chartId={'lineChartContainer'}
                  style={{
                    width: '100%',
                    // height: 'clamp(400px, 40vh, 500px)',
                    // backgroundColor: 'grey',
                  }}
                  data={{
                    bindings: {
                      data: {
                        label: 0,
                        metadata: [],
                        value: [1],
                      },
                    },
                    data: {
                      // data: [
                      //   ['Istanza', 'SROI'],
                      //   ['Ex-Ante', '3'],
                      //   ['Ongoing', '2.6'],
                      //   ['Ex-Post', '3.5'],
                      // ],
                      data: data,
                    },
                    metadata: {
                      data: {
                        0: {
                          type_id: 'string$arbitrary_string',
                          type: 'string',
                        },
                        1: {
                          type_id: 'number$none_point',
                          type: 'number',
                          output_format_id: 'number$comma_point',
                        },
                      },
                    },
                  }}
                  dataToRespond={[
                    // ...[
                    //   ['Istanza', 'SROI'],
                    //   ['Ex-Ante', '3'],
                    //   ['Ongoing', '2.6'],
                    //   ['Ex-Post', '3.5'],
                    // ],
                    ...data,
                  ]}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default MonitoringSection;
