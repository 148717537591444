import React, { useEffect } from 'react';
import * as d3 from 'd3'
//import * as InvestmentChartIcon from '@icons/charts/investment.svg'
// import colorbrewer from 'colorbrewer'
import { Trans, propTrans, propTransTooltip } from '@lib/i18n'
import { rounder } from '@lib/helpers'

const colors = ['#C2C923', '#08A2AA', '#CB1B4A', '#FFB317', '#053D58', '#378288', '#5BCFD4']

const colorbrewer = length => colors.slice(0, length)

/* const AnimatedDonutChart = ({chartId, data}) => {  
   const plotData = []
   Object.keys(data).forEach(key => {
     if(key !== 'total' && data[key] !== 0) {      
       const perc = parseFloat(data[key])/data['total']*100
       plotData.push(perc)
     }
   })  
 
   useEffect(() => {
     let colors = colorbrewer(plotData.length);
 
     let sizes = {
       innerRadius: 75,
       outerRadius: 100
     };
 
     let durations = {
       entryAnimation: 2000
     };
 
     d3.select(`#${chartId}`).html("");
 
     let generator = d3.pie()
       .padAngle(.04)
       .sort(null);
 
     let chart = generator(plotData);
 
     let arcs = d3.select(`#${chartId}`)
       .append("g")
       .attr("transform", "translate(100, 100)")
       .selectAll("path")
       .data(chart)
       .enter()
       .append("path")
       .style("fill", (d, i) => colors[i])
       .attr('data-value', (d, i) => d.data)
       .attr('data-label', (d, i) => {
         const label = Object.keys(data).find(key => parseFloat(data[key])/data['total']*100 === d.data)
         return label
       })
 
     let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());
 
     let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
     let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);
 
     let arc = d3.arc();
 
     arcs.transition()
       .duration(durations.entryAnimation)
       .attrTween("d", d => {
         let originalEnd = d.endAngle;
         return t => {
           let currentAngle = angleInterpolation(t);
           if (currentAngle < d.startAngle) {
             return "";
           }
 
           d.endAngle = Math.min(currentAngle, originalEnd);
 
           return arc(d);
         };
       });
 
     d3.select(`#${chartId}`)
       .transition()
       .duration(durations.entryAnimation)
       .tween("arcRadii", () => {
         return t => arc
           .innerRadius(innerRadiusInterpolation(t))
           .outerRadius(outerRadiusInterpolation(t));
       })
       .on('end', () => {
         //console.log(InvestmentChartIcon)
         //d3.select(`#${chartId}`).node().append()
       })
 
     const tooltip = d3.select('body')
       .append('div')
       .attr('class', 'svg-tooltip')
       .style('position', 'absolute')
       .style('visibility', 'hidden')
 
     const mouseEnterCallback = d => {      
       d3.select(d.target)
         .attr('stroke-width', '2')
         .attr('stroke', d.target.style.fill)
       tooltip
         .style('visibility', 'visible')
         .text(`${propTrans({ prop: d.target.attributes['data-label'].value, scope: 'dashboard' })} ${rounder(d.target.attributes['data-value'].value)} %`)
     }
 
     const mouseLeaveCallback = d => {
       d3.select(d.target).attr('stroke-width', '0')
       tooltip.style('visibility', 'hidden')
     }
 
     d3.select(`#${chartId}`)
       .selectAll('path')
       .on('mouseenter', mouseEnterCallback)
       .on('mousemove', event => {
         tooltip
           .style('top', `${event.pageY}px`)
           .style('left', `${event.pageX + 10}px`)
       })
       .on('mouseleave', mouseLeaveCallback);
   //eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
 
   return (
     <div>
       <svg id={chartId} preserveAspectRatio="xMinYMin meet" viewBox="0 0 200 200"></svg>
     </div>
   )
 } */

const AnimatedDonutChartDashImpact = ({chartId, data, valueInCircle, codeLangTag}) => {
   const plotData = [];

   if (Object.values(data).every(num => Number(num) === 0)) {
     plotData.push(1);
     data = {empty: 1};
   } else {
     Object.keys(data).forEach(key => {
       if(key !== 'total' && data[key] !== 0) {
         plotData.push(data[key])
       }
     })
   }
 
   useEffect(() => {
     let colors = colorbrewer(plotData.length);
 
     let sizes = {
       innerRadius: 75,
       outerRadius: 100
     };
 
     let durations = {
       entryAnimation: 2000
     };
 
     d3.select(`#${chartId}`).html("");
 
     let generator = d3.pie()
       .padAngle(.04)
       .sort(null);
 
     let chart = generator(plotData);
 
     let arcs = d3.select(`#${chartId}`)
       .append("g")
       .attr("transform", "translate(100, 100)")
       .selectAll("path")
       .data(chart)
       .enter()
       .append("path")
       .attr('class', 'arcs')
       .style("fill", (d, i) => colors[i])
       .attr('data-value', (d, i) => d.data)
       .attr('data-label', (d, i) => {
         const label = Object.keys(data).find(key => key !== 'total' && data[key] === d.data)
         return label
       })
 
      const parseHTML = (html) => {
         const parser = new DOMParser();
         const parsedDocument = parser.parseFromString(html, 'text/html');
         const textContent = parsedDocument.body.textContent;
 
         return textContent;
      }

     let g = d3.select(`#${chartId}`).selectAll('g');
 
      g.append('text')
       .attr('text-anchor', 'middle')
       .attr('font-size', '1.7em')
       .attr('y', 0)
       .attr('font-weight', 'bold')
       .text(valueInCircle); //number in circle

       g.append("foreignObject")
       .attr("width", '8rem')
       .attr('text-anchor', 'middle')
       .attr("height", 50)
       .attr('y', 10)
       .attr('x', -63)
       .append("xhtml:div")
       .style('text-align', 'center')
       //.style('white-space', 'nowrap')
       .html(`<text>${propTrans({prop: codeLangTag})}</text>&nbsp;<svg id="info-tooltip" width="14" height="14" viewBox="0 0 14 14" fill="none" style="vertical-align: inherit" xmlns="http://www.w3.org/2000/svg"><path d="M7.00036 0C3.13379 0 3.79829e-08 3.1338 3.79829e-08 6.99964C-9.57303e-05 7.91896 0.180909 8.82931 0.532676 9.67867C0.884443 10.528 1.40008 11.2998 2.05015 11.9499C2.70021 12.5999 3.47196 13.1156 4.32133 13.4673C5.17069 13.8191 6.08104 14.0001 7.00036 14C10.8655 14 14 10.8662 14 6.99964C14 3.1338 10.8655 0 7.00036 0ZM7.65366 2.52716C8.33613 2.52716 8.53664 2.92308 8.53664 3.37587C8.53664 3.94094 8.08458 4.46373 7.31243 4.46373C6.66642 4.46373 6.35873 4.13926 6.37769 3.6019C6.37769 3.14911 6.75611 2.52716 7.65366 2.52716ZM5.90521 11.1921C5.43857 11.1921 5.09807 10.9085 5.42399 9.66533L5.95844 7.45972C6.05104 7.10682 6.06635 6.96537 5.95844 6.96537C5.81918 6.96537 5.21327 7.2089 4.856 7.44951L4.6234 7.06817C5.7572 6.12104 7.06088 5.56544 7.61866 5.56544C8.08531 5.56544 8.16259 6.11666 7.93 6.96537L7.31754 9.284C7.2089 9.69377 7.25556 9.83522 7.3642 9.83522C7.50419 9.83522 7.96209 9.66606 8.41269 9.31097L8.67663 9.66533C7.57346 10.7678 6.37113 11.1921 5.90521 11.1921Z" fill="#C4C4C4"/></svg>`);
        //i icon in circle
 
     let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());
 
     let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
     let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);
 
     let arc = d3.arc();
 
     arcs.transition()
       .duration(durations.entryAnimation)
       .attrTween("d", d => {
         let originalEnd = d.endAngle;
         return t => {
           let currentAngle = angleInterpolation(t);
           if (currentAngle < d.startAngle) {
             return "";
           }
 
           d.endAngle = Math.min(currentAngle, originalEnd);
 
           return arc(d);
         };
       });
 
     d3.select(`#${chartId}`)
       .transition()
       .duration(durations.entryAnimation)
       .tween("arcRadii", () => {
         return t => arc
           .innerRadius(innerRadiusInterpolation(t))
           .outerRadius(outerRadiusInterpolation(t));
       })
       .on('end', () => {
         //console.log(InvestmentChartIcon)
         //d3.select(`#${chartId}`).node().append()
       })
 
     const tooltip = d3.select('body')
       .append('div')
       .attr('class', 'svg-tooltip')
       .style('position', 'absolute')
       .style('visibility', 'hidden')
 
     const mouseEnterCallback = d => {
      console.log('d.target.attributes', d.target.attributes)
       d3.select(d.target)
         .attr('stroke-width', '2')
         .attr('stroke', d.target.style.fill)
 
         tooltip.style('visibility', 'visible')
         .text(
           data.empty ? `${propTrans({prop: 'A0659'})}` : `${propTrans({ prop: d.target.attributes['data-label'].value, scope: 'dashboard' })} ${rounder(parseFloat(d.target.attributes['data-value'].value)/data['total']*100)}%`
         );
     }
 
     const mouseLeaveCallback = d => {
       d3.select(d.target).attr('stroke-width', '0')
       tooltip.style('visibility', 'hidden')
     }
 
     d3.select(`#${chartId}`)
       .selectAll('.arcs')
       .on('mouseenter', mouseEnterCallback)
       .on('mousemove', event => {
         tooltip
           .style('top', `${event.pageY}px`)
           .style('left', `${event.pageX + 10}px`)
       })
       .on('mouseleave', mouseLeaveCallback);

   //tooltip info icon
       const tooltipInfo = d3.select('body')
       .append('div')
       .attr('class', 'svg-tooltip')
       .style('position', 'absolute')
       .style('visibility', 'hidden')
 
     const mouseEnterCallbackInfo = d => {
       d3.select(d.target)
         .attr('stroke-width', '2')
         .attr('stroke', d.target.style.fill)
 
         tooltipInfo.style('visibility', 'visible')
         .text(parseHTML(propTransTooltip({prop: codeLangTag}))); //text tooltip
     }
 
     const mouseLeaveCallbackInfo = d => {
       d3.select(d.target).attr('stroke-width', '0')
       tooltipInfo.style('visibility', 'hidden')
     }
 
     d3.select(`#${chartId}`)
       .select('#info-tooltip')
       .on('mouseenter', mouseEnterCallbackInfo)
       .on('mousemove', event => {
         tooltipInfo
           .style('top', `${event.pageY}px`)
           .style('left', `${event.pageX + 10}px`)
       })
       .on('mouseleave', mouseLeaveCallbackInfo);
   //eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, valueInCircle, codeLangTag])
 
   return (
     <div>
       <svg id={chartId} preserveAspectRatio="xMinYMin meet" viewBox="0 0 200 200"></svg>
     </div>
   )
 }

const DashboardImpact = ({dashboard: { mainConclusions, scenario_type, chart }, navigateCallback=null}) => {
  const navigate = target => () => {
    if(navigateCallback) navigateCallback(target)
  }
  console.log('chart', chart);

  return (
    <div className="simple-box impact">
      <div className="row">
        <div className="col">
          <h5 className="title-simple-box margin-title-box"><Trans code="A0463" /></h5>
        </div>
      </div>
      <div className="d-flex flex-wrap justify-content-center">
        <div className={scenario_type === "2" ? 'd-none' : 'chartBox impactChart'}>
          <AnimatedDonutChartDashImpact 
            chartId="investment" 
            data={chart.investment} 
            valueInCircle={Number(chart.investment.total).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            codeLangTag="A0053" 
         />
          <div className='mt-4'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={navigate('investment')} rel="noreferrer">
               <h5 className='ico-absolute'><Trans code="A0047" /></h5>
            </a>
          </div>
        </div>
        <div className="chartBox impactChart">
         <AnimatedDonutChartDashImpact 
            chartId="gdp" 
            data={chart.gdp} 
            valueInCircle={Number(chart.gdp.total).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            codeLangTag="A0053" 
         />
          <div className='mt-4'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={navigate('gdp')} rel="noreferrer">
               <h5 className='ico-absolute'><Trans code="A0263" /></h5>
            </a>
          </div>
        </div>
        <div className="chartBox impactChart">
         <AnimatedDonutChartDashImpact 
            chartId="employment" 
            data={chart.employment} 
            valueInCircle={Number(chart.employment.total).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            codeLangTag="A0054"
         />
          <div className='mt-4'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={navigate('jobs')} rel="noreferrer">
               <h5 className='ico-absolute'><Trans code="A0328" /></h5>
            </a>
          </div>
        </div>
        <div className="chartBox impactChart d-none">
         <AnimatedDonutChartDashImpact 
            chartId="production" 
            data={chart.production} 
            valueInCircle={Number(chart.production.total).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            codeLangTag="A0053" 
         />
         <div className='mt-4'>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={navigate('production')}>
            <h5 className="impact-total">{rounder(chart.production.total)}</h5>
            <div className="va-desc"><Trans code="A0053" /></div>
            <h5 className='ico-absolute'><Trans code="F0078" /></h5>
          </a>
          </div>
        </div>
        <div className="chartBox impactChart">
         <AnimatedDonutChartDashImpact 
            chartId="incomes" 
            data={chart.incomes} 
            valueInCircle={Number(chart.incomes.total).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            codeLangTag="A0053" 
         />
         <div className='mt-4'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={navigate('incomes')}>
               <h5 className='ico-absolute'><Trans code="A0327" /></h5>
            </a>
         </div>
        </div>
        <div className="chartBox impactChart">
         <AnimatedDonutChartDashImpact 
            chartId="taxes" 
            data={chart.taxes} 
            valueInCircle={Number(chart.taxes.total).toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 0})}
            codeLangTag="A0053"
         />
         <div className='mt-4'>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={navigate('taxes')}>
            <h5 className='ico-absolute'><Trans code="A0326" /></h5>
          </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {mainConclusions}
        </div>
      </div>
    </div>
  );
}

export default DashboardImpact;