import { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Trans } from '@lib/i18n';

const AttachmentsImageCarousel = ({ data }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className='simple-box impact pb-5'>
        <div className='row'>
          <div className='col'>
            <h5 className='title-simple-box margin-title-box'>
              <Trans code='A0785' />
            </h5>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <Carousel
              id='dashboardCarousel'
              interval={null}
              activeIndex={index}
              onSelect={handleSelect}>
              {data?.map((el, i) => {
                return (
                  <Carousel.Item key={`${el.text}_${i}`}>
                    <img
                      className='d-block w-100 mb-4'
                      style={{
                        objectFit: 'contain',
                        aspectRatio: '16/9',
                        // maxHeight: '720px',
                        // minHeight: '720px',
                        // width: '1280px',
                      }}
                      src={el.ref}
                      alt={el.text}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttachmentsImageCarousel;
