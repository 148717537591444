import React, { useState, useEffect } from 'react';
import CustomSpinner from 'components/CustomSpinner';
import { Spinner } from 'react-bootstrap';
const OEIframePreview = ({
  blob,
  mimeType = 'application/octet-stream',
  style = false,
  isLoading,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!blob) {
    return <CustomSpinner />;
  }

  const blobUrl = URL.createObjectURL(blob);

  return (
    <div className='position-relative'>
      {!isLoaded && <CustomSpinner />}
      <iframe
        title='FILE VIEWER'
        src={blobUrl}
        onLoad={() => {
          handleLoad();
        }}
        style={
          !style ? { display: isLoaded ? 'block' : 'none', width: '100%', height: '500px' } : style
        }
        className='fileViewerTransition'
      />
      {isLoading && (
        <div
          className='position-absolute glass-background d-flex justify-content-center align-items-center'
          style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}>
          <Spinner animation='border' variant='dark' />
        </div>
      )}
    </div>
  );
};

export default OEIframePreview;
