import React from 'react';
import {Card} from 'react-bootstrap';
import { IcoCircleBox} from "@icons";
import CountryHeader from '@components/country/CountryHeader';
import KeyindicatorsTable from '@components/country/key-indicators/KeyindicatorsTable';
import KeyindicatorsTable2 from '@components/country/key-indicators/KeyindicatorsTable2';
import KeyindicatorsTable3 from '@components/country/key-indicators/KeyindicatorsTable3';
import KeyindicatorsTable4 from '@components/country/key-indicators/KeyindicatorsTable4';
import KeyindicatorsTable5 from '@components/country/key-indicators/KeyindicatorsTable5';
import RadarChartKey from '@components/country/key-indicators/ChartRadarKey';
import ScatterChart1 from '@components/country/key-indicators/ChartScatter1';


const Keyindicators = () => {
    return (
        <div id="scenari" className="megaContent">
            <div className="container-fluid">
            <CountryHeader/>
            <div className="row">
                    <div className="col-12 col-lg-8 d-flex container-card">
                            <KeyindicatorsTable />
                    </div>
                    <div className="col-12 col-lg-4 d-flex container-card">
                    <Card className="cards-utente d-none d-md-block w-100">
                        <Card.Body>
                            <IcoCircleBox className="logo-card ico-box-blue" />
                            <p><RadarChartKey /></p>
                        </Card.Body>
                    </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-8 d-flex container-card">
                            <KeyindicatorsTable2 />
                    </div>
                    <div className="col-12 col-lg-4 d-flex container-card">
                    <Card className="cards-utente d-none d-md-block w-100">
                        <Card.Body>
                            <IcoCircleBox className="logo-card ico-box-blue" />
                            <p><div id="container"><ScatterChart1 /></div></p>
                        </Card.Body>
                    </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-8 d-flex container-card">
                            <KeyindicatorsTable3 />
                    </div>
                    <div className="col-12 col-lg-4 d-flex container-card">
                    <Card className="cards-utente d-none d-md-block">
                        <Card.Body>
                            <IcoCircleBox className="logo-card ico-box-blue" />
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                        </Card.Body>
                    </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-8 d-flex container-card">
                            <KeyindicatorsTable4 />
                    </div>
                    <div className="col-12 col-lg-4 d-flex container-card">
                    <Card className="cards-utente d-none d-md-block">
                        <Card.Body>
                            <IcoCircleBox className="logo-card ico-box-blue" />
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                        </Card.Body>
                    </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-8 d-flex container-card">
                            <KeyindicatorsTable5 />
                    </div>
                    <div className="col-12 col-lg-4 d-flex container-card">
                    <Card className="cards-utente d-none d-md-block">
                        <Card.Body>
                            <IcoCircleBox className="logo-card ico-box-blue" />
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                        </Card.Body>
                    </Card>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Keyindicators;