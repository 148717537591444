import React, { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { TooltipInfoIcon } from '@icons';
import { Trans } from '@lib/i18n'
import { getScenariKpi, postScenariKpi } from '@lib/api'
import StatusBar from "components/wizard/StatusBar";

const StepFinancials = ({ props, scen }) => {
  const idScenario = scen.scenario.id;
  const [scenarioKpi, setScenarioKpi] = useState([]);
  const [sbShowSuccess, setSbShowSuccess] = useState(false);
  const [sbShowError, setSbShowError] = useState(false);
  //console.log("testKpi", testKpi)
  //console.log("scen:", scen);
  console.log('scenarioKpi', scenarioKpi);

  useEffect(() => {
    getScenariKpi(idScenario).then(retrievedKpi => {
      setScenarioKpi(retrievedKpi);
    })
  }, [idScenario]);

  /* const applyFormula = (formula, variables) => {
    formula = formula.replaceAll('$', '');

    let formulaWithValues = formula;

    variables.forEach(el => {
      formulaWithValues = formulaWithValues.replace(el.var, el.value)
    });

    const result = eval(formulaWithValues);

    return ( //bg-secondary 
      <span className="d-block mx-auto text-center py-2 px-5 font-weight-bold rounded-top text-white" style={{width: 'fit-content', marginBottom: '-1px', backgroundColor: '#08A2AA'}}>
        *Risultato*<br/>
        {result}
      </span>
    )
  }; */

  const result = (formula, variables) => {
   formula = formula.replaceAll('$', '');

   let formulaWithValues = formula;

   variables.forEach(el => {
     formulaWithValues = formulaWithValues.replace(el.var, el.value)
   });

   const result = eval(formulaWithValues);

   return ( <>
      <Row className="align-items-center justify-content-end mt-3 px-3">
         <div className="d-flex align-items-center position-absolute mr-3 px-2 bg-white">
            <h5 className="p-0">
               <Trans code="A0472" replacementText="Risultato"/>:&nbsp;{result}
            </h5>
         </div>
      </Row>
   </>
   )
 };

  const onChange = (e, kpiId) => {
    //console.log('e.target.value', e.target.value);
    /* let tempStateKpi = [...formSroi.kpi];

    if (formSroi.kpi.some(kpi => kpi.id === kpiId)) {

      formSroi.kpi.forEach((kpi, index) => {  
        if (kpi.id === kpiId) {
          tempStateKpi[index].indicatori[e.target.name] = Number(e.target.value);
        }
      });

    } else {
      tempStateKpi.push({id: kpiId, indicatori: {[e.target.name]: Number(e.target.value)}});
    }

    setFormSroi(() => ({ 
      ...formSroi,
      kpi: tempStateKpi
    })); */

    let tempScenarioKpi = [...scenarioKpi];

    tempScenarioKpi.filter(kpi => kpi.id === kpiId).map(filteredKpi => {
      filteredKpi.indicatori.filter(ind => ind.id === e.target.name).map(filteredInd => {
        filteredInd.value = Number(e.target.value)
      })
    })

    setScenarioKpi(tempScenarioKpi);
  };

  const submit = async (e) => {
    e.preventDefault();

    let request = scenarioKpi.map(kpi => {
      let tempIndicatori = {};

      kpi.indicatori.forEach(ind => {
        tempIndicatori[ind.id] = ind.value;
      })

      return {id: kpi.id, indicatori: tempIndicatori}
    });

    request = {kpi: request}
    //console.log('request submit:', request);

    const result = await postScenariKpi(request, idScenario);
    //console.log('result submit:', result);

    if (result) {
      setSbShowSuccess(true);

    } else {
      setSbShowError(true);
    }
  };
  
  const renderTooltip = (props) => {
    let message = "";

    if (props.popper.state) {
      message = props.popper.state.options.testObj
    };

    return (
      <Tooltip id="button-tooltip" {...props}>
        <Trans code={message} />
      </Tooltip>
    )
  };

  if (!scen) return null

  return (
<>
    {sbShowSuccess 
      ? ( <StatusBar status="success" timeout={true} /> )
      : ( sbShowError ? <StatusBar status="error" message="*There was an error when saving information!*" timeout={false}/> : null )
    }
    
    { scenarioKpi.length > 0 ?
    <Form onSubmit={submit} id="form-financials">
      <div className="simple-box" style={{marginTop : '40px'}}>
        <Row>
          <Col className="form-login">
            <h5 className="title-simple-box text-uppercase mb-5">*Form Parametri tecnici*</h5>

            {scenarioKpi.map((kpi, i) => {
              let variables = [];

              return <div key={"kpi-" + i} className="mb-5 rounded" style={{border: '2px solid #C2C923'}}>
              <Row className="mb-4 align-items-center px-3">
                <div className="d-flex align-items-center mt-n3 ml-3 px-2 bg-white">
                  <h5 className="p-0">
                    <Trans code={kpi.langTag} replacementText={kpi.name} />
                  </h5>
                
                  {kpi.langTagDesc ? <OverlayTrigger
                  placement="top"
                  delay={{ show: 200, hide: 400 }}
                  overlay={renderTooltip}
                  popperConfig={{testObj: kpi.langTagDesc}}
                  >
                    <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                  </OverlayTrigger> : null
                  }
                  {/* {kpi.formula} */}
                </div>
              </Row>
              <Form.Group as={Row} className="mb-0 align-items-end px-3" controlId={kpi.id}>
                {kpi.indicatori.map((ind, y) => {
                  variables.push({var: ind.var, value: ind.value});

                  return <Col key={"ind-" + y} lg={4} className="mb-5 px-4">
                    <Form.Label className="mb-3">
                      <Trans code={ind.langTag} replacementText={ind.name} />
                      {ind.langTagDesc ? <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{testObj: ind.langTagDesc}}
                        >
                          <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
                        </OverlayTrigger> : null
                      }
                    </Form.Label>
                    <InputGroup style={{paddingRight: '5rem'}}>
                      <Form.Control
                        type="number" 
                        step={0.01}
                        name={ind.id}
                        defaultValue={ind.value}
                        style={{ textAlign: 'right'}}
                        onChange={e => onChange(e, kpi.id)}
                      />
                      {ind.udm ? <InputGroup.Text className="addon-for-number">{ind.udm}</InputGroup.Text> : null}
                    </InputGroup>
                  </Col>
                })}
              </Form.Group>

              {/* {applyFormula(kpi.formula, variables)} */}
              {result(kpi.formula, variables)}
            </div>
            }) }
        </Col>
        </Row>
      </div>

      <Button form="form-financials" type="submit" className="submit-button sticky-footer-button">SAVE</Button>
    </Form> : <div className="simple-box" style={{marginTop : '40px'}}>
      <span>*Requisiti non soddisfatti*</span>
      </div>}
    </>
  );

}

export default StepFinancials;
