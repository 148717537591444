import React from 'react';
import style from './Table.module.css';

export default function Checkbox({ checked, onChange, label }) {
  return (
    <label className={style.styledCheckbox} checked={checked}>
      <input type='checkbox' checked={checked} onChange={onChange} />
      {checked ? <Checked /> : <Unchecked />}
      {label}
    </label>
  );
}

function Unchecked() {
  return (
    <svg width='10' height='10' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.197917'
        y='-0.197917'
        width='3.5625'
        height='3.5625'
        rx='0.395833'
        transform='matrix(1 0 0 -1 0.416672 4.18751)'
        fill='white'
      />
      <rect
        x='0.197917'
        y='-0.197917'
        width='3.5625'
        height='3.5625'
        rx='0.395833'
        transform='matrix(1 0 0 -1 0.416672 4.18751)'
        stroke='#C7C7C7'
        strokeWidth='0.395833'
      />
    </svg>
  );
}

function Checked() {
  return (
    <svg width='10' height='10' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.197917'
        y='-0.197917'
        width='3.5625'
        height='3.5625'
        rx='0.395833'
        transform='matrix(1 0 0 -1 0.416672 4.18748)'
        fill='#1C2743'
      />
      <rect
        x='0.197917'
        y='-0.197917'
        width='3.5625'
        height='3.5625'
        rx='0.395833'
        transform='matrix(1 0 0 -1 0.416672 4.18748)'
        stroke='white'
        strokeWidth='0.395833'
      />
      <path
        d='M2.28116 2.74901L3.56212 1.46783L3.84698 1.75249L2.28116 3.31833L1.24748 2.28466L1.53214 2L2.28116 2.74901Z'
        fill='white'
      />
    </svg>
  );
}
