import React, { useState, useEffect, useCallback, useRef, Fragment } from "react";
import Select from 'react-select';
import { Button, FormControl, ButtonToolbar, InputGroup, Modal, Form, Col, Row, Card } from 'react-bootstrap';
import { getCountry, getDatasets, postDataset } from '@lib/api';
import { Lens, IcoPlus } from '@icons';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid-translate/locale-it';
import ActionBtnRendererDataset from '@components/adminManagement/datasetsManagement/ActionBtnRendererDataset';
import StatusBar from "components/wizard/StatusBar";
//import { Trans } from '@lib/i18n';

const initialState = {
   title: '',
   description: '',
   type: '',
   countryName: '',
   region: '',
   year: '', //new Date().getFullYear()
   fileName: '',
   localsalary: '',
   status: '',
   modeller: '',
   family: '',
   sources: '',
   version: '',
};

const optionsStatus = [
   {
      value: '0', 
      label: 'Draft',
   },
   {
      value: '1', 
      label: 'Submitted',
   },
   {
      value: '2', 
      label: 'Active',
   },
   {
      value: '3', 
      label: 'Stand by',
   },
];

const optionsTypology = [
   {
      value: 'standard', 
      label: 'Standard',
   },
   {
      value: 'nonstandard', 
      label: 'Non standard',
   },
];

const Datasets = () => {
   const [datasets, setDatasets] = useState([]);
   const [newDataset, setNewDataset] = useState(initialState);
   const [selectedStatus, setSelectedStatus] = useState([]);
   const [selectedTypology, setSelectedTypology] = useState([]);
   const [countries, setCountries] = useState([]);
   const [optionsCountries, setOptionsCountries] = useState([]);
   const [selectedCountry, setSelectedCountry] = useState([]);
   const [showModalNew, setShowModalNew] = useState(false);
   const [optionsPagination, setOptionsPagination] = useState([]);
   const [sbShow, setSbShow] = useState(<></>);
   const [refresh, setRefresh] = useState(false);
   const localeText = AG_GRID_LOCALE_IT;
   const gridRef = useRef();
   console.log('datasets', datasets);
   console.log('newDataset', newDataset);

   const handleCloseModal = (setShowModal) => setShowModal(false);
   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout}/>);
   };

   useEffect(() => {
      getCountry().then((retrievedCountries) => {
         setCountries(retrievedCountries);
      });
            
      setOptionsPagination([
         <Fragment key="options">
            <option defaultValue="20" key="20">20</option>
            <option value="50" key="50">50</option>
            <option value="100" key="100">100</option>
         </Fragment>
      ]);
   }, []);

   useEffect(() => {
      setOptionsCountries(countries.filter(country => country !== '-')
         .map(filteredCountry => ({
            value: filteredCountry,
            label: filteredCountry,
         })).sort((a, b) => a.label.localeCompare(b.label))
      );
   }, [countries]);

   useEffect(() => {
      if (refresh) {
         getDatasets().then((retrievedDatasets) => {
            setDatasets(retrievedDatasets);
         });
  
         setRefresh(false);
      }
   }, [refresh]);

   const onChange = (e, fieldname, setSelect) => { 
      //console.log('onChange e', e);

      if (setSelect) {
         setSelect(e ?? []);

         setNewDataset(() => ({
            ...newDataset,
            [fieldname]: e ? e.value ?? e : [],
         }));
      } else {
         setNewDataset(() => ({
            ...newDataset,
            [e.target.name]: e.target.value,
         }));
      }
   };

   const onFilterTextBoxChanged = useCallback((e) => {
      gridRef.current.api.setQuickFilter(e.target.value);
      //gridRef.current.api.sizeColumnsToFit(); ??
   }, []);

   const onGridReady = useCallback(() => {
      getDatasets().then((retrievedDatasets) => {
         setDatasets(retrievedDatasets);
      });
   }, []);

   const onPageSizeChanged = useCallback(() => {
      let value = document.getElementById('page-size').value;
      gridRef.current.api.paginationSetPageSize(Number(value));
   }, []);

   const defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: {padding: '0 1rem'},
   };

   const columnDefs = [
      {
         headerName: 'ID*',
         field: 'id',
         filter: 'agTextColumnFilter',
         maxWidth: 100,
         valueGetter: (params) => {
            return params.data.id;
         },
      },
      {
         headerName: 'Title',
         field: 'title',
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            return params.data.title;
         },
      },
      {
         headerName: 'Country',
         field: 'countryName',
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            return params.data.countryName;
         },
      },
      {
         headerName: 'Region',
         field: 'region',
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            return params.data.region;
         },
      },
      {
         headerName: 'Year',
         field: 'year',
         maxWidth: 100,
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            return params.data.year;
         },
      },
      {
         headerName: 'Modeller',
         field: 'modeller',
         filter: 'agTextColumnFilter',
         valueGetter: (params) => {
            return params.data.modeller;
         },
      },
      {
         pinned: 'right',
         maxWidth: 120,
         cellStyle: {textAlign: 'center'},
         cellRenderer: ActionBtnRendererDataset,
         cellRendererParams: {
           setRefresh: setRefresh,
           optionsStatus: optionsStatus,
           optionsCountries: optionsCountries,
           optionsTypology: optionsTypology,
         },
      },
   ];

   const handleNewDataset = async (e) => {
      e?.preventDefault();
      
      setSbShow(<></>);

      const { description, region, fileName, status, modeller, family, sources, version, ...restDataset } = newDataset;
      console.log('restDataset', restDataset);

      const isDatasetFilled = Object.values(restDataset).every(prop => prop !== '' && prop != null) ? true : false;

      if (!isDatasetFilled) {
         handleStatusBar('error', 'Compila i campi obbligatori.*', false);
         return;
      }

      /* const createResult = await postDataset(newDataset);
      console.log('createResult', createResult);

      if (createResult === true) {
         handleStatusBar('success', 'Dataset creato con successo*', true);

         setTimeout(() => {
            setShowModalNew(false);
            setRefresh(true);
         }, 2000);
      } else if (createResult.message || createResult.error) {
         handleStatusBar('error', 'There was an error when saving information.*', false);
      } */
   }

   if (parseInt(JSON.parse(localStorage.getItem('user'))['role']) > 2) {
      return (
         <div id="scenari" className="megaContent">
               <div className="container-fluid">
                  <div className="row">
                     USER NOT AUTHORIZED
                  </div>
               </div>
         </div>
      );
   };

   return (
      <>
         <div id="datasets" className="megaContent">
            <div className="container-fluid">       
               <div className="row d-flex align-items-center mb-5">
                  <div className="col-5">
                     <h5 className="title-simple-box mb-0">
                        Datasets
                     </h5>
                  </div>
                  <div className="col-7 text-right">
                     <ButtonToolbar className="justify-content-end my-2">
                        <Button variant="info add-organization" onClick={() => handleShowModal(setShowModalNew)}>
                           <div className="row align-items-center py-1 px-3">
                              <IcoPlus className="light-color ico-small plus mr-2" />
                              <span className="d-md-inline">Nuovo dataset</span>
                           </div>
                        </Button>
                        <InputGroup className="search-table align-items-center bg-white">
                           <FormControl
                              type="text"
                              id="filter-text-box"
                              placeholder="Search..."
                              className="d-none d-md-block "
                              onInput={(e) => onFilterTextBoxChanged(e)}
                           />
                           <Lens />
                        </InputGroup>
                     </ButtonToolbar>
                  </div>
               </div>

               <div className="row">
                  <div className="col-12" id="grid-wrapper">
                     <div className="ag-theme-alpine projects-list-table">
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={datasets}
                           defaultColDef={defaultColDef}
                           domLayout="autoHeight"
                           tooltipShowDelay={0}
                           tooltipHideDelay={2000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           pagination={true}
                           paginationPageSize={20}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           animateRows={true}
                        />
                        <div className="example-header">
                           Datasets per pagina:&nbsp;
                           <select onChange={onPageSizeChanged} id="page-size">
                              {optionsPagination}
                           </select>
                        </div>
                     </div>
                  </div> 
               </div>
            </div>
         </div>

         <Modal size="xl" show={showModalNew} onHide={() => handleCloseModal(setShowModalNew)} centered>
            <Modal.Header closeButton>
               <Modal.Title className="px-2">Crea un nuovo dataset*</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form onSubmit={handleNewDataset} id="form-admin-dataset">
                  <Row className="mb-3">
                     <Col className="col-8">
                        <Row>
                           <Form.Group as={Col} className="px-4" controlId="title">
                              <Form.Label className="mb-3">
                                 Title&nbsp;<span className="required-field">*</span>
                              </Form.Label>
                              <Form.Control
                                 type="text"
                                 name="title"
                                 defaultValue={newDataset.title}
                                 onChange={e => onChange(e)}
                              />
                           </Form.Group>
                        </Row>
                        <Row>
                           <Col className="px-4">
                              <Form.Group as={Col} className="px-0" controlId="title">
                                 <Form.Label className="mb-3">
                                    Typology&nbsp;<span className="required-field">*</span>
                                 </Form.Label>
                                 <Select
                                    name="type"
                                    value={selectedTypology}
                                    options={optionsTypology}
                                    onChange={e => onChange(e, 'type', setSelectedTypology)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col} className="px-0" controlId="fileName" /* style={{maxWidth: '33%'}} */>
                                 <Form.Label className="mb-3">
                                    File
                                 </Form.Label>
                                 <Form.Control
                                    type="file"
                                    name="fileName"
                                    //defaultValue={newDataset.fileName}
                                    onChange={e => onChange(e)}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>
                     </Col>

                     <Col className="col-4">
                        <Row>
                           <Form.Group as={Col} className="px-4" controlId="description">
                              <Form.Label className="mb-3">
                                 Description
                              </Form.Label>
                              <Form.Control
                                 as="textarea"
                                 rows={5}
                                 name="description"
                                 defaultValue={newDataset.description ?? ''}
                                 onChange={e => onChange(e)}
                              />
                           </Form.Group>
                        </Row>
                     </Col>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="countryName">
                        <Form.Label className="mb-3">
                           Country&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                           name="countryName"
                           value={selectedCountry}
                           options={optionsCountries}
                           onChange={e => onChange(e, 'countryName', setSelectedCountry)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="region">
                        <Form.Label className="mb-3">
                           Region
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="region"
                           defaultValue={newDataset.region}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="localsalary">
                        <Form.Label className="mb-3">
                           Local salary&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="number"
                           step={0.01}
                           name="localsalary"
                           defaultValue={newDataset.localsalary}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="year">
                        <Form.Label className="mb-3">
                           Year&nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="number"
                           name="year"
                           min={1700}
                           defaultValue={newDataset.year}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="status">
                        <Form.Label className="mb-3">
                           Status
                        </Form.Label>
                        <Select
                           name="status"
                           value={selectedStatus}
                           options={optionsStatus}
                           onChange={e => onChange(e, 'status', setSelectedStatus)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="family">
                        <Form.Label className="mb-3">
                           Family
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="family"
                           defaultValue={newDataset.family}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>

                  <Row className="mb-3">
                     <Form.Group as={Col} className="px-4" controlId="modeller">
                        <Form.Label className="mb-3">
                           Modeller
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="modeller"
                           defaultValue={newDataset.modeller}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="sources">
                        <Form.Label className="mb-3">
                           Sources
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="sources"
                           defaultValue={newDataset.sources}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                     <Form.Group as={Col} className="px-4" controlId="version">
                        <Form.Label className="mb-3">
                           Version
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="version"
                           defaultValue={newDataset.version}
                           onChange={e => onChange(e)}
                        />
                     </Form.Group>
                  </Row>
               </Form>
               {sbShow}

               <span className='mt-4 px-3'><span className="required-field">*</span>&nbsp;Campi obbligatori</span>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="danger" onClick={() => handleCloseModal(setShowModalNew)}>
                  Annulla
               </Button>
               <Button variant="primary" onClick={e => handleNewDataset(e)}>
                  Crea
               </Button>
            </Modal.Footer>
         </Modal>
      </>
    );
  }
  export default Datasets;