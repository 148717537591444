const MonitoringCard = ({ title, children }) => {
  return (
    <div style={{ flex: '1' }}>
      {title && <h5 className='mb-1'>{title}</h5>}
      <div className='d-flex justify-content-center align-items-center'>{children}</div>
    </div>
  );
};

export default MonitoringCard;
